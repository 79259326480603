import * as React from "react";

function SvgTvLove(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="tvLove_svg__f75753ab-88c6-4f9f-b165-473e1ee27a57"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.tvLove_svg__st0{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        id="tvLove_svg___x36_6660c34-4658-4918-9a0e-3ab52c8fcb7f"
        className="tvLove_svg__st0"
        d="M128 124.4c-3.5-23.4-17.7-34.3-31.8-34.3S67.5 101 64 124.4"
      />
      <circle
        id="tvLove_svg__d0fc1d0f-e6f0-4d56-b044-0e2051fd6d6b"
        className="tvLove_svg__st0"
        cx={96}
        cy={70.9}
        r={19.2}
      />
      <path
        className="tvLove_svg__st0"
        d="M48 72.6s11.4-5 12-13.4c.2-3.6-2.5-7.2-6.1-7.2-2.1 0-4.1 1-5.3 2.7-.3.5-.6 0-.6.6-.1-.6-.3-.1-.6-.6-1.2-1.7-3.2-2.7-5.3-2.7-3.6 0-6.3 3.6-6.1 7.2.6 8.5 12 13.5 12 13.4"
      />
      <path
        className="tvLove_svg__st0"
        d="M28 36h136c4.4 0 8 3.6 8 8v88c0 4.4-3.6 8-8 8H28c-4.4 0-8-3.6-8-8V44c0-4.4 3.6-8 8-8zM60 156h72M96 144v10"
      />
      <path fill="none" d="M0 0h192v192H0z" />
    </svg>
  );
}

export default SvgTvLove;
