import * as React from "react";

function SvgUnlimitedColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <path
        d="M261.7 225.7s55.1-97 121.7-97S492.5 183 492.5 250s-42.1 121.2-109.1 121.2c-70.7 0-122.3-96.8-122.3-96.8l-24.7-48.9s-44.4-96.8-119.7-96.8C49.7 128.8 7.5 183 7.5 250s42.2 121.2 109.2 121.2c76.3 0 120.8-96.5 120.8-96.5l24.2-49z"
        fill="none"
        stroke="#e60000"
        strokeWidth={24.173}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgUnlimitedColor;
