import React from "react";
import styled from "styled-components";
import { ZIndex } from "../../constants/zIndex";
import { ImageSet } from "../../images";
import ProgressiveImage from "../../images/ProgressiveImage";
import { SVGComponent } from "../../types/default";
import SVGIcon from "../svgIcon/SVGIcon";

interface ImageWithIconsProps {
  className?: string;
  imageSet: ImageSet;
  icons?: SVGComponent[];
}

const ImageWithIcons: React.FC<ImageWithIconsProps> = ({
  className,
  imageSet,
  icons,
  children,
}) => (
  <div className={className + " image-with-icon"}>
    <div className={"image-with-icon-main-image-container"}>
      <ProgressiveImage
        src={imageSet.image}
        preview={imageSet.preview}
        render={(src, style) => (
          <img
            className={"image-with-icon-main-image"}
            style={style}
            src={src}
            alt=""
          />
        )}
      />
      <div className={"image-with-icon-main-image-shadow"} />
    </div>
    <div className={"image-with-icon-icon-container"}>
      {icons &&
        icons.map((icon, i) => {
          return (
            <span key={i}>
              <SVGIcon src={icon} alt="" />
            </span>
          );
        })}
    </div>
    {children}
  </div>
);

export default styled(ImageWithIcons)`
  position: relative;
  width: 100%;
  z-index: ${ZIndex.NORMAL};
  .image-with-icon-main-image-container {
    position: relative;
    width: 100%;
  }
  .image-with-icon-main-image {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;

    width: 100%;
  }

  .image-with-icon-main-image-shadow {
    display: block;
    position: absolute;

    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 30%,
      rgba(0, 0, 0, 0) 100%
    );
    top: 0;
    left: 0;
  }

  .image-with-icon-icon-container {
    position: absolute;
    margin: 0 auto;
    bottom: 20px;

    left: 0;
    right: 0;
    width: 100%;
    max-width: 150px;
    text-align: center;

    span {
      width: 75px;
      display: inline-block;
      margin: 0 auto;

      img,
      svg {
        width: 75px;
        max-height: 90px;
      }
    }
  }
`;
