import { AvailabilityState } from "../../state/definitions/AvailabilityState";
import { FormState } from "../../state/definitions/FormState";
import {
  SlideCategoryTypes,
  SlidePage,
  SlidePages,
} from "../../constants/SlidePages";
import { getInterestObject } from "../interests/getInterestObject";
import { getPrioritizedInterests } from "./getPrioritizedInterests";
import { getCategorizedSlidePages } from "../../constants/CategorizedSlidePages";
import { extractId } from "./extractId";
import { Location } from "history";
import { getCategoryFromAllSlides } from "./getCategoryFromAllSlides";
import { Interest } from "../../types/application";
import { ENABLE_RECOMMENDATION_WITH_NO_INTEREST } from "../../constants/default";

interface NavigationCalculationConfig {
  interests: Interest[];
  hasAnyFormData: boolean;
  availability: AvailabilityState;
  oldSlideIndex: number;
  oldSlides: SlidePage[];
  form: FormState;
  location: Location;
}

export interface calculatedNavigation {
  pageArray: SlidePage[];
  idArrayString: string;
  count: number;
  disableNext: boolean;
  currentSlideIndex: number;
}

const CategorizedSlidePages = getCategorizedSlidePages();

export function calculateNavigation({
  interests,
  hasAnyFormData,
  availability: availabilityState,
  form,
  oldSlideIndex,
  oldSlides,
  location,
}: NavigationCalculationConfig): calculatedNavigation {
  const interestObject = getInterestObject(interests);
  const currentId = extractId(location.pathname);
  const currentCategory = getCategoryFromAllSlides(currentId);

  const prioritizedInterests = getPrioritizedInterests(
    interestObject,
    form,
    currentCategory
  );

  const slides: SlidePage[] = [...CategorizedSlidePages.start];
  if (interestObject.fixed || interestObject.tv) {
    if (
      !availabilityState.someIsAvailable ||
      oldSlides[oldSlideIndex]?.category === SlideCategoryTypes.availability
    ) {
      slides.push(...CategorizedSlidePages.availability);
    }
  }
  // Attach all interesting slides, in the order of prioritisation
  prioritizedInterests.forEach((interest: Interest) => {
    if (interest === Interest.MOBILE) {
      slides.push(...CategorizedSlidePages.mobile);
    }
    if (interest === Interest.FIXED && availabilityState.someIsAvailable) {
      const filteredFixedSlides = CategorizedSlidePages.fixed.filter(
        (slide) => {
          return !(
            slide.id === SlidePages.fixedCallDestination.id &&
            form.fixedDevicesPhone === 0
          );
        }
      );

      slides.push(...filteredFixedSlides);
    }
    if (interest === Interest.TV && availabilityState.someIsAvailable) {
      const filteredTvSlides = CategorizedSlidePages.tv.filter((slide) => {
        return !(
          slide.id === SlidePages.tvInvoice.id &&
          !availabilityState.availability.cable.available
        );
      });

      slides.push(...filteredTvSlides);
    }
    if (interest === Interest.IOT) {
      const filteredIotSlides = CategorizedSlidePages.iot.filter((slide) => {
        return !(
          slide.id === SlidePages.iotDevices.id &&
          form.iotInterests.length === 0
        );
      });

      slides.push(...filteredIotSlides);
    }
  });

  let endSlideCount = 0;
  if (
    (interests.length > 0 && hasAnyFormData) ||
    ENABLE_RECOMMENDATION_WITH_NO_INTEREST
  ) {
    // Attach switch slide(s) when not all interests are selected
    if (interests.length < 4) {
      slides.push(...CategorizedSlidePages.switch);
    }
    slides.push(...CategorizedSlidePages.profile);
    slides.push(...CategorizedSlidePages.recommendation);
    slides.push(...CategorizedSlidePages.offer);
  } else {
    endSlideCount =
      CategorizedSlidePages.switch.length +
      CategorizedSlidePages.profile.length +
      CategorizedSlidePages.recommendation.length +
      CategorizedSlidePages.offer.length;
  }
  const slideCount = endSlideCount + slides.length - 1;

  const currentSlideIndex = slides.findIndex((v) => v.id === currentId);
  const disableNext = currentSlideIndex === slides.length - 1;

  slides.push(...CategorizedSlidePages.help);
  const idArrayString = slides.map((v) => v.id).join("");
  return {
    pageArray: slides,
    count: slideCount,
    idArrayString,
    disableNext,
    currentSlideIndex,
  };
}
