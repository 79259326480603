import {
  AdditionalTableDataObject,
  CountUnits,
  PriceUnits,
  TableCellType,
} from "../../types/table";

export function getGigaKombiAdditionalTableObject(
  labels: string[],
  prices: number[],
  labelId: number = 0
): AdditionalTableDataObject {
  if (!labels[labelId]) {
    labels[labelId] = "GigaKombi";
  }
  return {
    labelId: labelId,
    countId: 0,
    priceId: undefined,
    startId: undefined,
    labels: labels,
    //
    counts: [1],
    countUnit: CountUnits.normal,
    prices: prices,
    starts: [0],
    labelType: TableCellType.SELECTION,
    countType: TableCellType.NONE,
    startType: TableCellType.NONE,
    priceType: TableCellType.EXTERNAL,
    priceUnit: PriceUnits.euro,
  };
}
