export const AdditionalTableHeadlines = [
  "Bezeichnung",
  "Startmonat",
  "Anzahl/Dauer",
  "Betrag",
  "",
];
export const FurtherTableHeadlines = [
  "Produkt",
  "Anzahl/Dauer",
  "Monatlicher Betrag",
];
export const OnceTableHeadlines = ["Bezeichnung", "Betrag"];
