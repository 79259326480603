import * as React from "react";

function SvgSocialColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <g
        fill="none"
        stroke="#00b1ca"
        strokeWidth={24.173}
        strokeLinecap="round"
      >
        <path
          d="M378.02 125.17c39.41 0 71.35 31.04 71.35 69.33s-31.94 69.33-71.35 69.33-71.35-31.04-71.35-69.33 31.95-69.33 71.35-69.33zM163.97 69.71c55.17 0 99.89 43.46 99.89 97.07 0 53.61-44.72 97.06-99.89 97.06s-99.89-43.46-99.89-97.06c0-53.62 44.72-97.07 99.89-97.07z"
          strokeLinejoin="round"
        />
        <path
          d="M492.1 379.17c-12.7-71.58-57.95-115.34-114.12-115.34-40.8 0-75.85 23.05-96.82 63.09l-.39.79M7.1 430.23c17.4-103.2 79.67-166.19 156.91-166.19s139.47 63.06 156.91 166.19"
          strokeMiterlimit={10}
        />
      </g>
    </svg>
  );
}

export default SvgSocialColor;
