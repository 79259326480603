import * as React from "react";

function SvgGigaKombiGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="giga_kombi_grey_svg__ICON"
      x={0}
      y={0}
      viewBox="0 0 192 192"
    >
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="8"
        d="M64.86 148h59.67M42.34 132H140M96 148v-16M156 151.98h8"
      />
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-width="8"
        d="M152 96h28v60a12 12 0 0 1-12 12h-16a12 12 0 0 1-12-12v-48a12 12 0 0 1 12-12z"
      />
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="8"
        d="M105.5 99.88a13.47 13.47 0 0 0-19 0M115 87.93h0a26.94 26.94 0 0 0-38.07 0M124.53 75.89a40.41 40.41 0 0 0-57.06 0M96 112h0z"
      />
      <path
        fill="none"
        stroke={color}
        stroke-width="8"
        d="M28 92.76V61a17 17 0 0 1 17-17h119v50.45M140 131.98H42.34"
      />
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="8"
        d="M51.21 145.9l6.8-2.33c1.69-.27 3.24 0 4 .87 1.09 1.26 10 12.48 10.79 13.46s.57 3-1.38 4.43-11.64 7.57-14.16 6.67S44 164.27 30.4 137.78s-10.32-37.08-9.55-39.62 11.32-6.9 13.65-7.59 4.09.43 4.5 1.61 4.19 14.95 4.59 16.48c.27 1.06-.43 2.45-1.66 3.58s-6.16 5.49-6.16 5.49 1 4.41 6 14.19c2.33 4.72 9.44 13.98 9.44 13.98z"
      />
      <circle cx="96" cy="112" r=".15" fill="none" className="cls-2" />
    </svg>
  );
}

export default SvgGigaKombiGrey;
