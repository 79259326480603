import * as React from "react";

function SvgSecurityCam(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="ICON"
      viewBox="0 0 192 192"
      {...props}
    >
      <path fill="none" d="M0 0h192v192H0z" />
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        d="M28 116v48m100.9-36.27L40.54 76.8a6 6 0 01-2.21-8.2l22-38.12a6 6 0 018.19-2.2l98.77 56.93z"
      />
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        d="M159.2 94.09l12.47 7.51-15.98 27.72-18.23-11.06"
      />
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={8}
        d="M88 104l-24 36H28"
      />
    </svg>
  );
}

export default SvgSecurityCam;
