import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import { useLocation } from "react-router-dom";
import { useNavigation } from "../../hooks/useNavigation";
import Pagination from "../pagination/Pagination";
import RecommendationPagination from "../pagination/RecommendationPagination";
import KittButton from "../elements/KittButton";
import { SlidePages } from "../../constants/SlidePages";
import { StyledComponentProps } from "../../types/default";
import SummaryRecommendationPagination from "../pagination/SummaryRecommendationPagination";
import MoreRecommendationPagination from "../pagination/MoreRecommendationPagination";
import OfferPagination from "../pagination/OfferPagination";

const Footer: React.FC<StyledComponentProps> = ({ className }) => {
  const location = useLocation();
  const { prevSlide, nextSlide, disableNext, currentSlideIndex, slideCount } =
    useNavigation();

  let pagination = (
    <Pagination
      toPrev={"/slides/" + prevSlide}
      toNext={"/slides/" + nextSlide}
      totalPages={slideCount}
      currentPage={currentSlideIndex}
      disableNext={disableNext}
    />
  );

  if (location.pathname === "/slides/" + SlidePages.recommendation.id) {
    pagination = <RecommendationPagination />;
  }
  if (location.pathname === "/slides/" + SlidePages.moreRecommendation.id) {
    pagination = <MoreRecommendationPagination />;
  }
  if (location.pathname === "/slides/" + SlidePages.summaryRecommendation.id) {
    pagination = <SummaryRecommendationPagination />;
  }
  if (location.pathname === "/slides/" + SlidePages.offer.id) {
    pagination = <OfferPagination />;
  }
  // Hide Pager on GuaranteePage
  if (location.pathname === "/slides/" + SlidePages.changeGuarantee.id) {
    return null;
  }

  return (
    <>
      <div className={className}>
        {location.pathname === "/slides/" + SlidePages.soho.id && (
          <KittButton invisible />
        )}
        {pagination}
        {location.pathname === "/slides/" + SlidePages.soho.id && <KittButton />}
        <div className="data-sec-link">
          <span><a target="_blank" href="https://www.vodafone.de/unternehmen/soziale-verantwortung/datenschutz-privatsphaere.html">Datenschutzhinweise</a></span>
        </div>
      </div>
    </>
  );
};

export default styled(Footer)`
  display: block;
  text-align: center;
  position: fixed;
  bottom: 0;
  padding: 5px 0 5px 0;
  background-color: ${Color.GREY_LIGHT8};
  box-sizing: border-box;
  width: calc(100% - 90px);

  .data-sec-link {
    padding-right: 7px;
    padding-top: 5px;
    color: black;
    text-align: right;
  }

  a:visited {
    color: black;
  }

`;
