import { useFormState } from "../useForm";
import { FormOption } from "../../state/definitions/FormConfigState";
import { useContext, useMemo } from "react";
import { AppContext } from "../../state/AppContext";
import { useRefEffect } from "../common/useRefHook";
import {
  Interest,
  RecommendationMode,
  RecommendationModeArray,
  WithsObject,
} from "../../types/application";
import { getModeKeyFromInterest } from "../../helper/recommendation/mapping";

function getRecommendationModeOfProductLine(
  interest: Interest,
  providerId: number,
  interests: Interest[],
  providers: FormOption[]
): RecommendationMode {
  let providerInternalId = providers.findIndex((v) => v.value === providerId);
  let providerFO: FormOption | undefined = providers[providerInternalId];

  let provider: string | undefined =
    providerFO !== undefined ? providerFO.label : "Keine Angabe";

  if (interests.includes(interest)) {
    return RecommendationMode.SELECTABLE;
  }
  if (provider && provider.toLocaleLowerCase() === "vodafone") {
    return RecommendationMode.CURRENT;
  }
  return RecommendationMode.INTERESTING;
}

export const useInterestValue = () => {
  const [state] = useContext(AppContext);

  const getRecommendationMode = (interest: Interest) => {
    switch (interest) {
      case Interest.MOBILE:
        return state.recommendation.modes.mobileMode;
      case Interest.IOT:
        return state.recommendation.modes.iotMode;
      case Interest.FIXED:
        return state.recommendation.modes.fixedMode;
      case Interest.TV:
        return state.recommendation.modes.tvMode;
    }
  };
  const isInterestSelected = (interest: Interest) => {
    return (
      state.recommendation.modes[getModeKeyFromInterest(interest)] ===
      RecommendationMode.SELECTABLE
    );
  };
  return { getRecommendationMode, isInterestSelected };
};

export const useInterestsValues = (): RecommendationModeArray => {
  const [state] = useContext(AppContext);
  const { mobileMode, fixedMode, tvMode, iotMode } = state.recommendation.modes;
  return useMemo(() => {
    return state.recommendation.modes;
  }, [mobileMode, fixedMode, tvMode, iotMode]);
};

export type InterestsValues = ReturnType<typeof useInterestsValues>;

type RecommendationModeSetter = (
  prevModes: RecommendationModeArray
) => RecommendationModeArray;

export const useInterests = () => {
  const [state, setState] = useContext(AppContext);
  const [providerMobileId = -1] = useFormState("providerMobileId");
  const [providerIotId = -1] = useFormState("providerIotId");
  const [providerFixedId = -1] = useFormState("providerFixedId");
  const [providerTvId = -1] = useFormState("providerTvId");
  const [interests] = useFormState("interests");
  const { loading } = state.bootstrap;
  const { mobileProviders, iotProviders, fixedProviders, tvProviders } =
    state.bootstrap.formConfig;

  const setModeState = (getNewModes: RecommendationModeSetter) => {
    setState((prev) => {
      return {
        ...prev,
        recommendation: {
          ...prev.recommendation,
          modes: getNewModes(prev.recommendation.modes),
        },
      };
    });
    return {
      modes: state.recommendation.modes,
    };
  };
  const setMode =
    (modeName: keyof RecommendationModeArray) =>
    (newMode: RecommendationMode) => {
      const getNewMode: RecommendationModeSetter = (prevModes) => ({
        ...prevModes,
        [modeName]: newMode,
      });
      const newModes = setModeState(getNewMode).modes;
      return newModes[modeName];
    };

  const isInterest = (interest: Interest) => {
    return interests.includes(interest);
  };

  const getSimpleWithsObject = (): WithsObject => {
    return {
      mobile: isInterest(Interest.MOBILE),
      iot: isInterest(Interest.IOT),
      fixed: isInterest(Interest.FIXED),
      tv: isInterest(Interest.TV),
    };
  };

  useRefEffect(() => {
    setMode("mobileMode")(
      getRecommendationModeOfProductLine(
        Interest.MOBILE,
        providerMobileId,
        interests,
        mobileProviders
      )
    );
  }, [providerMobileId, loading, interests]);
  useRefEffect(() => {
    setMode("iotMode")(
      getRecommendationModeOfProductLine(
        Interest.IOT,
        providerIotId,
        interests,
        iotProviders
      )
    );
  }, [providerIotId, loading, interests]);
  useRefEffect(() => {
    setMode("fixedMode")(
      getRecommendationModeOfProductLine(
        Interest.FIXED,
        providerFixedId,
        interests,
        fixedProviders
      )
    );
  }, [providerFixedId, loading, interests]);
  useRefEffect(() => {
    setMode("tvMode")(
      getRecommendationModeOfProductLine(
        Interest.TV,
        providerTvId,
        interests,
        tvProviders
      )
    );
  }, [providerTvId, loading, interests]);

  return {
    ...state.recommendation.modes,
    interests: interests,
    isInterest,
    getSimpleWithsObject,
  };
};
