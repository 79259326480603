import { MonthlyPriceArray } from "../../types/application";

export function unifyMonthlyPriceArrayValues(priceArray: MonthlyPriceArray) {
  const months = Object.keys(priceArray).map((v) =>
    v === undefined ? 0 : Number(v)
  );
  const prices = Object.values(priceArray) as number[];
  const unifiedPriceArray: MonthlyPriceArray = {};
  prices.forEach((v, i) => {
    const prevPrice = prices[i - 1];
    if (prevPrice !== v) {
      unifiedPriceArray[months[i]] = v;
    }
  });
  return unifiedPriceArray;
}
