import * as React from "react";

function SvgCalendar30(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="calendar30_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 100 100"
      xmlSpace="preserve"
      {...props}
    >
      <style>{`.calendar30_svg__st0,.calendar30_svg__st1{fill: ${color} }`}</style>
      <g id="calendar30_svg__i-lrg_i-sml">
        <path
          className="calendar30_svg__st0"
          d="M78.3 17.2H66.4v-5.9c0-1.3-1-2.3-2.3-2.3-1.3 0-2.3 1-2.3 2.3v5.9H38.1v-5.9c0-1.3-1-2.3-2.3-2.3-1.3 0-2.3 1-2.3 2.3v5.9H21.7c-4.6 0-8.3 3.8-8.3 8.4v56.9c0 4.6 3.7 8.4 8.3 8.4h56.6c4.6 0 8.3-3.8 8.3-8.4V25.6c0-4.6-3.7-8.4-8.3-8.4zm-56.6 4.6h11.9v1.8c0 1.3 1 2.3 2.3 2.3 1.3 0 2.3-1 2.3-2.3v-1.8H62v1.8c0 1.3 1 2.3 2.3 2.3 1.3 0 2.3-1 2.3-2.3v-1.8h11.9c2.1 0 3.8 1.7 3.8 3.8v7.9H17.9v-7.9c0-2.1 1.7-3.8 3.8-3.8zm56.6 64.6H21.7c-2.1 0-3.8-1.7-3.8-3.8V38h64.2v44.5c0 2.1-1.7 3.9-3.8 3.9z"
        />
        <path
          className="calendar30_svg__st0"
          d="M40.9 57.8l6.2-6.2c.6-.7.8-1.6.5-2.5s-1.2-1.4-2.1-1.4H30.3c-1.3 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3H40l-5.9 6c-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7h.4c.5-.1 1-.1 1.5-.1 3.6 0 6.6 2.4 6.6 5.4 0 3.4-3.5 5.9-6.6 5.9h-.1c-2.2.1-4.2-1.1-5.3-2.9-.6-1.1-2-1.4-3.1-.8-1.1.6-1.4 2-.8 3.1 1.9 3.2 5.4 5.2 9.1 5.2h.3c6 0 11.1-4.8 11.1-10.4-.1-4.8-3.4-8.5-7.9-9.8zM67.4 49.2c-1.6-1.3-3.3-1.9-5.1-1.9-1.8 0-3.5.5-4.8 1.6-1.2.9-2.2 2.2-3 3.6-.7 1.4-1.2 2.9-1.5 4.6-.3 1.6-.5 3.3-.5 5s.2 3.4.5 5c.3 1.7.9 3.3 1.6 4.7.8 1.5 1.8 2.7 3 3.7 1.4 1.1 3 1.6 4.8 1.6 2.5 0 4.7-1.1 6.3-3 1.4-1.7 2.3-3.8 2.8-6.2.4-2.2.7-4.1.7-5.8 0-2.7-.4-5.2-1.2-7.5-.9-2.3-2.1-4.2-3.6-5.4zM67 67c-.3 1.7-1 3.1-1.9 4.3-.7.9-1.6 1.3-2.7 1.3-.8 0-1.5-.2-2-.6-.7-.5-1.3-1.3-1.7-2.2-.5-1-.9-2.2-1.2-3.5-.3-1.3-.5-2.7-.5-4.2 0-1.4.1-2.8.4-4.2.2-1.3.6-2.4 1.1-3.4.4-.9 1-1.6 1.7-2.1.6-.4 1.2-.6 2.1-.6.7 0 1.5.3 2.2.9.8.7 1.5 1.8 2.1 3.4.6 1.8.9 3.8.9 6 0 1.4-.1 3.1-.5 4.9z"
        />
      </g>
      <g id="calendar30_svg__i-xxsml_i-xsml">
        <path
          className="calendar30_svg__st1"
          d="M78.3 17.2H66.4v-5.9c0-1.3-1-2.3-2.3-2.3-1.3 0-2.3 1-2.3 2.3v5.9H38.1v-5.9c0-1.3-1-2.3-2.3-2.3-1.3 0-2.3 1-2.3 2.3v5.9H21.7c-4.6 0-8.3 3.8-8.3 8.4v56.9c0 4.6 3.7 8.4 8.3 8.4h56.6c4.6 0 8.3-3.8 8.3-8.4V25.6c0-4.6-3.7-8.4-8.3-8.4zm-56.6 4.6h11.9v1.8c0 1.3 1 2.3 2.3 2.3 1.3 0 2.3-1 2.3-2.3v-1.8H62v1.8c0 1.3 1 2.3 2.3 2.3 1.3 0 2.3-1 2.3-2.3v-1.8h11.9c2.1 0 3.8 1.7 3.8 3.8v7.9H17.9v-7.9c0-2.1 1.7-3.8 3.8-3.8zm56.6 64.6H21.7c-2.1 0-3.8-1.7-3.8-3.8V38h64.2v44.5c0 2.1-1.7 3.9-3.8 3.9z"
        />
        <path
          className="calendar30_svg__st1"
          d="M40.9 57.8l6.2-6.2c.6-.7.8-1.6.5-2.5s-1.2-1.4-2.1-1.4H30.3c-1.3 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3H40l-5.9 6c-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7h.4c.5-.1 1-.1 1.5-.1 3.6 0 6.6 2.4 6.6 5.4 0 3.4-3.5 5.9-6.6 5.9h-.1c-2.2.1-4.2-1.1-5.3-2.9-.6-1.1-2-1.4-3.1-.8-1.1.6-1.4 2-.8 3.1 1.9 3.2 5.4 5.2 9.1 5.2h.3c6 0 11.1-4.8 11.1-10.4-.1-4.8-3.4-8.5-7.9-9.8zM67.4 49.2c-1.6-1.3-3.3-1.9-5.1-1.9-1.8 0-3.5.5-4.8 1.6-1.2.9-2.2 2.2-3 3.6-.7 1.4-1.2 2.9-1.5 4.6-.3 1.6-.5 3.3-.5 5s.2 3.4.5 5c.3 1.7.9 3.3 1.6 4.7.8 1.5 1.8 2.7 3 3.7 1.4 1.1 3 1.6 4.8 1.6 2.5 0 4.7-1.1 6.3-3 1.4-1.7 2.3-3.8 2.8-6.2.4-2.2.7-4.1.7-5.8 0-2.7-.4-5.2-1.2-7.5-.9-2.3-2.1-4.2-3.6-5.4zM67 67c-.3 1.7-1 3.1-1.9 4.3-.7.9-1.6 1.3-2.7 1.3-.8 0-1.5-.2-2-.6-.7-.5-1.3-1.3-1.7-2.2-.5-1-.9-2.2-1.2-3.5-.3-1.3-.5-2.7-.5-4.2 0-1.4.1-2.8.4-4.2.2-1.3.6-2.4 1.1-3.4.4-.9 1-1.6 1.7-2.1.6-.4 1.2-.6 2.1-.6.7 0 1.5.3 2.2.9.8.7 1.5 1.8 2.1 3.4.6 1.8.9 3.8.9 6 0 1.4-.1 3.1-.5 4.9z"
        />
      </g>
    </svg>
  );
}

export default SvgCalendar30;
