import { ProductLineInfoForRecommendationConfig } from "./definitions/InformationForRecommendationConfig";
import { RecommendationProductLineConfig } from "../../state/definitions/RecommendationState";
import { getProductOptionFromInformation } from "./getValuesFromInformation";

export function getProductLineConfig(
  info: ProductLineInfoForRecommendationConfig
): RecommendationProductLineConfig {
  return {
    productId: getProductOptionFromInformation("id", info.productInformation),
    productPreLabel: getProductOptionFromInformation(
      "preLabel",
      info.productInformation
    ),
    productLabel: getProductOptionFromInformation(
      "label",
      info.productInformation
    ),
    productPostLabel: getProductOptionFromInformation(
      "postLabel",
      info.productInformation
    ),
    productPreDescription: getProductOptionFromInformation(
      "preDescription",
      info.productInformation
    ),
    productDescription: getProductOptionFromInformation(
      "description",
      info.productInformation
    ),
    productPostDescription: getProductOptionFromInformation(
      "postDescription",
      info.productInformation
    ),
    productPrices: getProductOptionFromInformation(
      "prices",
      info.productInformation
    ),
    productAdvantages: getProductOptionFromInformation(
      "advantages",
      info.productInformation
    ),

    productDataVolume: getProductOptionFromInformation(
      "dataVolume",
      info.productInformation
    ),

    dataVolumeMaximum: info.dataVolumeMaximum,
    passes: getProductOptionFromInformation("title", info.passInformation),
    passPrices: getProductOptionFromInformation("price", info.passInformation),
    passPriceSelected: getProductOptionFromInformation(
      "selected",
      info.passInformation
    ),

    selectedProduct: info.selectedId,
  };
}
