export enum DatabaseProductTypes {
  mobile = "mobile",
  iot = "iot",
  fixed = "fixed",
  tv = "tv",
  unknown = "unknown",
}

export enum DatabaseProductTypesFalseIds {
  mobile = "-1",
  iot = "-2",
  fixed = "-3",
  tv = "-4",
  unknown = "-5",
}
