import * as React from "react";

function SvgVod(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="vod_svg___x35_bcb7378-99a6-4cec-88d9-15c2bfe9c6fe"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.vod_svg__st0,.vod_svg__st1{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linejoin:round}.vod_svg__st1{stroke-linecap:round}`}
      </style>
      <path
        className="vod_svg__st0"
        d="M246.8 173.4c13.2 0 23.9 10.7 23.9 23.9v54.2c0 13.2-10.7 23.9-23.9 23.9s-23.9-10.7-23.9-23.9v-54.2c0-13.2 10.7-23.9 23.9-23.9zM340.6 173.4h-26.8v101.7h24.9c14.3.1 26-11.3 26.1-25.6V197.6c.1-13.4-10.7-24.2-24.2-24.2.1 0 .1 0 0 0z"
      />
      <path
        className="vod_svg__st1"
        d="M160.8 275.5l26.8-102.1M160.8 275.5l-25.7-102.1M33 58.6h434c14.1 0 25.5 11.4 25.5 25.5v280.8c0 14.1-11.4 25.5-25.5 25.5H33c-14.1 0-25.5-11.4-25.5-25.5V84.1C7.5 70 18.9 58.6 33 58.6zM135.1 441.4h229.8M250 403.2v31.9"
      />
    </svg>
  );
}

export default SvgVod;
