import * as React from "react";
import { getIcon } from "../../../../icons/Icons";
import { FormConfigLabelIds } from "../../../../data/definitions/FormConfigLabelIds";
import SVGIcon from "../../../svgIcon/SVGIcon";
import { WithsObject } from "../../../../types/application";

export interface GigaKombiSVGProps {
  withsObject: WithsObject;
  size?: number;
}

const GigaKombiSVG: React.FC<GigaKombiSVGProps> = ({
  withsObject,
  size = 250,
}) => {
  const { mobile, iot, fixed, tv } = withsObject;

  let gigaKombiIcon = getIcon(FormConfigLabelIds.GIGAKOMBI_111);

  if (mobile && fixed && !tv) {
    gigaKombiIcon = getIcon(FormConfigLabelIds.GIGAKOMBI_110);
  }
  if (mobile && !fixed && tv) {
    gigaKombiIcon = getIcon(FormConfigLabelIds.GIGAKOMBI_101);
  }
  if (!mobile && fixed && tv) {
    gigaKombiIcon = getIcon(FormConfigLabelIds.GIGAKOMBI_011);
  }

  return (
    <SVGIcon
      useMaxSizes
      noPadding
      src={gigaKombiIcon}
      size={size}
      alt={"GigaKombi-Icons"}
    />
  );
};

export default GigaKombiSVG;
