import * as React from "react";

function SvgMobileColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="mobile_color_svg___x30_1d074ad-3419-40d8-a4a2-3a686c94f98c"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".mobile_color_svg__st0{fill:none;stroke:#e60000;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <g id="mobile_color_svg___x33_9bbb6be-0d7a-4bfa-8252-1f40bf874658">
        <path
          className="mobile_color_svg__st0"
          d="M211.7 441.4h76.6M147.9 7.5h204.2c21.1 0 38.3 17.1 38.3 38.3v408.4c0 21.1-17.1 38.3-38.3 38.3H147.9c-21.1 0-38.3-17.1-38.3-38.3V45.8c0-21.2 17.1-38.3 38.3-38.3z"
        />
      </g>
    </svg>
  );
}

export default SvgMobileColor;
