import { MONTH_MAX_LENGTH } from "../constants/default";
import { LabelValuePair, MonthlyPriceArray } from "../types/application";
import getEffectivePrice from "./getEffectivePrice";

export default function getMonthlyPriceArrayStringified(
  monthlyPriceArray: MonthlyPriceArray,
  oncePrice?: number
): LabelValuePair[] {
  const columnPriceMonths = Object.keys(monthlyPriceArray);
  const columnPriceObjects: LabelValuePair[] = Object.values(monthlyPriceArray)
    .map((v: number, i: number) => {
      if (i === 0) {
        const effectivePrice = getEffectivePrice(
          monthlyPriceArray,
          oncePrice ?? 0
        );
        return {
          label: "Effektivpreis",
          value: effectivePrice.toFixed(2) + " €",
        };
      }
      const monthsNumber = Number(columnPriceMonths[i]);
      const monthsNumberExtended = isNaN(monthsNumber) ? 2 : monthsNumber + 1;
      return {
        label: "Ab dem " + monthsNumberExtended + ". Monat",
        value: v.toFixed(2) + " €",
      };
    })
    .filter((v, i) => Number(columnPriceMonths[i]) < MONTH_MAX_LENGTH);
  if (oncePrice !== undefined) {
    columnPriceObjects.push({
      label: "Deine Einmalzahlung",
      value: oncePrice.toFixed(2) + " €",
    });
  }
  return columnPriceObjects;
}
