import { RecommendationData } from "../../data/definitions/RecommendationData";
import testProductJson from "./testProductJson";
import testAdvantageJson from "./testAdvantageJson";

export default (
  unsureRecommendationData: RecommendationData<any>
): [RecommendationData, boolean] => {
  let result: boolean;
  if (unsureRecommendationData !== undefined) {
    const hasProducts = unsureRecommendationData.hasOwnProperty("products");
    const hasAdvantages = unsureRecommendationData.hasOwnProperty("advantages");
    if (hasProducts && hasAdvantages) {
      const [, productResult] = testProductJson(
        unsureRecommendationData.products
      );
      const [, advantageResult] = testAdvantageJson(
        unsureRecommendationData.advantages
      );
      result = productResult && advantageResult;
    } else {
      console.error(
        "Received recommendationData has a false structure:",
        "No '" + (hasProducts ? "advantages" : "products") + "'-property."
      );
      result = false;
    }
  } else {
    console.error("Received recommendationData is undefined.");
    result = false;
  }

  return [unsureRecommendationData as RecommendationData, result];
};
