import { useContext } from "react";
import { AppContext } from "../state/AppContext";
import { DatabaseProductTypes } from "../data/definitions/DatabaseProductTypes";
import { useNewOffer } from "./useNewOffer";
import { DatabaseProduct } from "../data/definitions/DatabaseProduct";
import { IndexStructureObject } from "../types/util";

const useDatabaseProducts = () => {
  const [state] = useContext(AppContext);

  const getProducts = (ids: number[]) => {
    const usedIds: IndexStructureObject = {};
    return (
      state.products
        .filter((v) => {
          const isIncluded = ids.includes(v.productId);
          const isUnused = !usedIds[v.productId];
          if (isIncluded && isUnused) {
            usedIds[v.productId] = true;
            return true;
          }
          return false;
        })
        // TODO: Check der Notwendigkeit und Erweiterung wegen IOT
        .sort((a, b) => {
          if (
            a.productType === DatabaseProductTypes.mobile &&
            (b.productType === DatabaseProductTypes.fixed ||
              b.productType === DatabaseProductTypes.tv)
          ) {
            return -1;
          }
          if (
            a.productType === DatabaseProductTypes.fixed &&
            b.productType === DatabaseProductTypes.tv
          ) {
            return -1;
          }
          if (
            a.productType === DatabaseProductTypes.fixed &&
            b.productType === DatabaseProductTypes.mobile
          ) {
            return 1;
          }
          if (
            a.productType === DatabaseProductTypes.tv &&
            (b.productType === DatabaseProductTypes.mobile ||
              b.productType === DatabaseProductTypes.fixed)
          ) {
            return 1;
          }
          return 0;
        })
    );
  };

  return { getProducts };
};
export default useDatabaseProducts;

export const useCurrentProducts = () => {
  const { getSelectedEntryProperty } = useNewOffer();
  const { getProducts } = useDatabaseProducts();

  const getCurrentIds = () => {
    // TODO: Optimieren
    return getSelectedEntryProperty("productId", (v) => v.mainRow);
  };

  const getSelectedProducts = (ids: number[] = []): DatabaseProduct[] => {
    const selectedProducts = getProducts(ids);
    const mobileProducts = selectedProducts.filter(
      (v) => v.productType === DatabaseProductTypes.mobile
    );
    const iotProducts = selectedProducts.filter(
      (v) => v.productType === DatabaseProductTypes.iot
    );
    const fixedProducts = selectedProducts.filter(
      (v) => v.productType === DatabaseProductTypes.fixed
    );
    const tvProducts = selectedProducts.filter(
      (v) => v.productType === DatabaseProductTypes.tv
    );

    return [...mobileProducts, ...iotProducts, ...fixedProducts, ...tvProducts];
  };
  const getCurrentProducts = () => {
    const selectedIds = getCurrentIds();
    const products = getSelectedProducts(selectedIds);
    return [products, selectedIds];
  };

  return { getCurrentIds, getSelectedProducts, getCurrentProducts };
};
