import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import { StyledComponentProps } from "../../types/default";

const RedPanelValue: React.FC<StyledComponentProps> = ({
  className,
  children,
}) => <span className={className + " red-panel-value"}>{children}</span>;

export default styled(RedPanelValue)`
  color: ${Color.WHITE};
  font-weight: bold;
`;
