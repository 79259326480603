import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";

interface TextDisplayProps {
  className?: string;
  label: string;
  value?: string;
}

const TextDisplay: React.FC<TextDisplayProps> = ({
  className,
  label,
  value = 0,
}) => (
  <div className={className}>
    <div className={"table-container"}>
      <span className={"label"}>{label}</span>
      <span className={"value"}>{value}</span>
    </div>
  </div>
);

export default styled(TextDisplay)`
  display: inline-block;

  .table-container {
    background: ${Color.WHITE};

    min-width: 100px;
    width: 100%;
    max-width: 200px;
    height: 100%;
    min-height: 75px;
    display: table;

    .label {
      position: relative;
      display: table-cell;
      width: 50%;
      height: 100%;
      padding: 5px 0 5px 10px;

      background: ${Color.WHITE};
      color: ${Color.BLACK};

      text-align: left;
      vertical-align: middle;
      font-size: 12px;
    }

    .value {
      position: relative;
      display: table-cell;
      width: 45%;
      padding: 5px;

      color: ${Color.BLACK};
      font-weight: bold;

      text-align: center;
      vertical-align: middle;
    }
  }
`;
