import { SubProductsTableConfig } from "./definitions/SubProductsTableConfig";
import standardSubProductsTableConfig from "../../data/standardSubProductsTableConfig.json";
import testSubproductsTableConfigJson from "../dataTester/testSubproductsTableConfigJson";
import { ProductCountArray } from "../../types/application";

export function getStandardSubProductsTableConfig(
  selectedSubProducts: ProductCountArray = {}
): [SubProductsTableConfig, boolean] {
  const standardSubProductsTableConfigObject: any = JSON.parse(
    JSON.stringify(standardSubProductsTableConfig)
  );
  standardSubProductsTableConfigObject.furtherOneProductLabel =
    standardSubProductsTableConfigObject.furtherOneProductLabel === null
      ? undefined
      : standardSubProductsTableConfigObject.furtherOneProductLabel;

  const [testedConfig, result] = testSubproductsTableConfigJson(
    standardSubProductsTableConfigObject
  );
  if (result) {
    testedConfig.furtherProductId = Object.keys(selectedSubProducts).map(
      (v) => Number(v) + 1
    ); // +1 for the "Kein Produkt" label
    testedConfig.furtherProductCountId = Object.values(selectedSubProducts).map(
      (selectedCount) => {
        const foundIndex =
          standardSubProductsTableConfigObject.furtherCounts.findIndex(
            (count: number) => selectedCount === count
          );
        if (foundIndex > -1) {
          return foundIndex + 1; // +1 for the "Kein Produkt" label
        }
        return 0;
      }
    );
  }
  return [testedConfig, result];
}
