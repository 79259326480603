import { DatabaseProduct } from "../../data/definitions/DatabaseProduct";
import { RecommendationMode } from "../../types/application";

export function calculateWithValue(
  selectedProduct: DatabaseProduct,
  mode: RecommendationMode
): boolean {
  return (
    mode !== RecommendationMode.INTERESTING &&
    (selectedProduct.combiRebate || selectedProduct.combiPush)
  );
}
