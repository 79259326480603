import styled from "styled-components";
import TextTile from "./TextTile";
import { Color } from "../../../constants/color";

export const PriceTextTile = styled(TextTile)`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : Color.PURPLE};
  min-height: 85px;
  color: ${({ fontColor }) => (fontColor ? fontColor : Color.WHITE)};
`;
