import React from "react";
import styled from "styled-components";
import { SimpleState } from "../../../../types/default";
import { Color } from "../../../../constants/color";
import InterestButton from "../InterestButton";
import PriceInput from "../PriceInput";
import { Interest } from "../../../../types/application";
import { getInterestFromType } from "../../../../helper/recommendation/mapping";

interface CurrentRecommendationColumnProps {
  className?: string;
  type: Interest;
  currentPriceState: SimpleState<number>;
}

const CurrentRecommendationColumn: React.FC<CurrentRecommendationColumnProps> =
  ({ className, type, currentPriceState: [currentPrice, setCurrentPrice] }) => {
    return (
      <div className={className}>
        <div className={"interest-button-container"}>
          <InterestButton type={type}>
            Interesse an {getInterestFromType(type)}
          </InterestButton>
        </div>
        <PriceInput
          mainLabel={"Dein Preis pro Monat:"}
          changeValue={currentPrice}
          onChange={setCurrentPrice}
        />
      </div>
    );
  };

export default styled(CurrentRecommendationColumn)`
  .price-display {
    color: ${Color.WHITE};
  }
  .absolute-container {
    .tile-button {
      width: unset;
      background-color: ${Color.RED};
      margin: 8px;
      line-height: 0;
      box-shadow: 3px 3px 5px 0 ${Color.GREY4};
      padding: 10px;
    }
  }
  .interest-button-container {
    padding: 5px;
    justify-content: center;
    display: flex;
  }
`;
