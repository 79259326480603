import {
  CountUnits,
  TableDataObject,
  UnknownTableDataObject,
} from "../../types/table";
import { getPriceFromTableCell } from "./default";
import { MONTH_MAX_LENGTH, MONTH_MIN_LENGTH } from "../../constants/default";
import { calculateMonthsPrices } from "./calculateMonthsPrices";
import { addValueToMonthlyPriceArray } from "./addValueToMonthlyPriceArray";
import { unifyMonthlyPriceArrayValues } from "./unifyMonthlyPriceArrayValues";
import { MonthlyPriceArray } from "../../types/application";

export function sumTablePrices(
  tableData: UnknownTableDataObject[][],
  prePriceArray?: MonthlyPriceArray
): [MonthlyPriceArray, number[], number] {
  let newPriceArray: MonthlyPriceArray = prePriceArray ? prePriceArray : {};
  let oncePrice = 0;
  const priceList = tableData.map(
    (arr: TableDataObject[], i: number): number => {
      return arr.reduce((acc: number, d: TableDataObject, j): number => {
        // get price
        const price = getPriceFromTableCell(d);
        if (price === 0) {
          return acc;
        }

        /**
         *  For calculation debugging:
         *             console.log("--(" + i + "/" + j + ")--");
         *             console.log("Start Price Array: ", newPriceArray);
         */

        // this value will used for summarizing
        let priceToAdd: number = 0;

        // get all used months
        // to add the price of this entry in each used runtime
        // const usedMonths = Object.keys(newPriceArray);
        let monthlyAddedPrice = 0;

        let startMonth = MONTH_MIN_LENGTH; // start month of rebate
        let endMonth = MONTH_MAX_LENGTH; // end month of rebate

        switch (d.countUnit) {
          case CountUnits.months: // add to 0 and remove add COUNT
            const calculated = calculateMonthsPrices(
              d,
              price,
              newPriceArray,
              startMonth,
              endMonth
            );
            priceToAdd = calculated.priceToAdd;
            newPriceArray = calculated.priceArray;
            monthlyAddedPrice = calculated.monthlyAddedPrice;
            startMonth = calculated.newStartMonth;
            endMonth = calculated.newEndMonth;
            break;
          case CountUnits.normal: // add to 0 and multipliers
            const multiplier =
              d.counts[d.countId] !== undefined ? d.counts[d.countId] : 1;
            monthlyAddedPrice = price * multiplier;
            priceToAdd = price * multiplier;

            break;
          case CountUnits.nothing:
          default:
            oncePrice += price;
            priceToAdd = price;
        }

        addValueToMonthlyPriceArray(
          newPriceArray,
          monthlyAddedPrice,
          startMonth,
          endMonth
        );

        /**
         *  For calculation debugging:
         *             console.log("END Price Array: ", newPriceArray);
         *             console.log("----");
         */

        return acc + priceToAdd;
      }, 0);
    }
  );
  const unifiedPriceArray = unifyMonthlyPriceArrayValues(newPriceArray);

  return [unifiedPriceArray, priceList, oncePrice];
}
