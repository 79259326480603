import { MONTH_MAX_LENGTH } from "../constants/default";
import { unifyMonthlyPriceArrayValues } from "./priceCalculation/unifyMonthlyPriceArrayValues";
import { LabelValuePair, MonthlyPriceArray } from "../types/application";

export default function getColumnPriceObjects(
  columnPrices: MonthlyPriceArray,
  oncePrice?: number
): LabelValuePair[] {
  const unifiedPriceArray = unifyMonthlyPriceArrayValues(columnPrices);
  const columnPriceMonths = Object.keys(unifiedPriceArray);
  const columnPriceObjects: LabelValuePair[] = Object.values(unifiedPriceArray)
    .map((v: number, i: number) => {
      if (i === 0) {
        return {
          label: "Dein Preis pro Monat",
          value: v.toFixed(2) + " €",
        };
      }
      const monthsNumber = Number(columnPriceMonths[i]);
      const monthsNumberExtended = isNaN(monthsNumber) ? 2 : monthsNumber + 1;
      return {
        label: "Ab dem " + monthsNumberExtended + ". Monat",
        value: v.toFixed(2) + " €",
      };
    })
    .filter((v, i) => Number(columnPriceMonths[i]) < MONTH_MAX_LENGTH);
  if (oncePrice !== undefined) {
    columnPriceObjects.push({
      label: "Deine Einmalzahlung",
      value: oncePrice.toFixed(2) + " €",
    });
  }
  return columnPriceObjects;
}
