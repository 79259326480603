import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import { TechnologyAvailability } from "../../../state/definitions/AvailabilityState";

interface AvailabilityValueProps {
  className?: string;
  data: TechnologyAvailability;
  withoutNumber: boolean;
}

function formatBandwidth(value: number): string {
  // if (value >= 1000000) {
  //   return (value / 1000000).toFixed(0) + " Gbit/s";
  // }
  if (value >= 1000) {
    return `${(value / 1000).toFixed(0)} Mbit/s`;
  }
  return value.toFixed(0) + " kbit/s";
}

const AvailabilityValue: React.FC<AvailabilityValueProps> = ({
  className,
  data,
  withoutNumber,
}) => (
  <div className={className}>
    <div className={"availability-value-container"}>
      <div className={"availability-value"}>
        <span>
          {withoutNumber && data.available && "Verfügbar"}
          {!withoutNumber && data.available && (
            <React.Fragment>
              {formatBandwidth(data.downstream)} Download
              <br />
              {formatBandwidth(data.upstream)} Upload
            </React.Fragment>
          )}
        </span>
      </div>
    </div>
  </div>
);

export default styled(AvailabilityValue)`
  width: 100%;
  height: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 10px;

  .availability-value-container {
    width: 100%;
    height: 100%;
    /* background: ${({ data }) =>
      data.available ? Color.RED : Color.GREY}; */
    /* background:${Color.WHITE}; */
    display: flex;
    align-items: center;
    justify-content: center;
    .availability-value {
      width: calc(100% - 20px);
      height: 100%;
      /* height: calc(100% - 15px); */
      border-radius: 5px;

      background: ${({ data }) => (data.available ? Color.RED : Color.GREY)};
      color: ${Color.WHITE};

      display: flex;
      align-items: center;
      justify-content: center;

      span {
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
`;
