import * as React from "react";

function SvgDownload(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;

  return (
    <svg
      id="download_svg___x36_ab3c5c6-76c6-43a2-a827-c2552913ff3f"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.download_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <g id="download_svg___x31_18234c5-5db7-42f9-ba5b-031dc5a4bf0f">
        <path
          className="download_svg__st0"
          d="M492.5 329.8v63.8c0 35.2-28.6 63.8-63.8 63.8H71.3c-35.2 0-63.8-28.6-63.8-63.8v-63.8"
          id="download_svg__f09060fa-d247-4468-ba0a-90486d2c47dd"
        />
        <g id="download_svg__e3e23168-9778-4769-8f22-8605c34d7d2a">
          <path
            className="download_svg__st0"
            d="M250 380.9V74.6M333 291.6l-83 89.3-83-89.3"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgDownload;
