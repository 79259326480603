import * as React from "react";
import { useContext } from "react";
import { AppContext } from "../../../../state/AppContext";
import styled from "styled-components";
import { StyledComponentProps } from "../../../../types/default";
import { prettyDate } from "../../../../helper/getPrettyStrings";

export interface Props extends StyledComponentProps {}

const PrintTopLine = ({ className }: Props) => {
  const [state] = useContext(AppContext);

  return (
    <div className={className}>
      <div className={"print-page-topline"}>
        <p className={"print-page-topleftline"}>
          {prettyDate(new Date())} | {state.vauoid + "-" + state.storageId}
        </p>
        <p className={"print-page-toprightline"}>
          {!!state.contact.name ? state.contact.name + " | " : ""}
          {!!state.contact.addressExtra ? state.contact.addressExtra + " " : ""}
          {!!state.contact.street ? state.contact.street + ", " : ""}
          {!!state.contact.zip ? state.contact.zip + " " : ""}
          {!!state.contact.city ? state.contact.city : ""}
        </p>
      </div>
    </div>
  );
};

export default styled(PrintTopLine)`
  position: relative;
  width: 100%;
  height: 20px;
  top: 4px;

  .print-page-topline {
    position: absolute;
    font-size: 12px;
    font-style: italic;
    width: calc(100% - 60px);
    left: 30px;
    p {
      display: inline-block;
      vertical-align: middle;
      width: 50%;
    }
    .print-page-topleftline {
      text-align: left;
    }
    .print-page-toprightline {
      text-align: right;
    }
  }
`;
