import * as React from "react";
import { SVGComponent } from "../../types/default";
import { v4 as uuid } from "uuid";

const SvgSuperWifi2: SVGComponent = (props) => {
  const { color = "#ffffff" } = props;
  const rndClsId = uuid();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
    >
      <style>
        {`.superwifi2_svg__st0${rndClsId}{
        fill:none;
        stroke: ${color} ;
        stroke-width:8;
        stroke-linecap:round;
        stroke-linejoin:round}`}
      </style>
      <path
        className={`superwifi2_svg__st0${rndClsId}`}
        d="M49.44 43.11L20.25 74a7.79 7.79 0 0 0 0 11l70.2 68.73a7.8 7.8 0 0 0 11 .08l.08-.08L171.75 85a7.79 7.79 0 0 0 0-11l-29.64-31.35a7.68 7.68 0 0 0-5.54-2.36H55.43a7.68 7.68 0 0 0-5.54 2.36l-.45.46"
      />
      <path
        className={`superwifi2_svg__st0${rndClsId}`}
        d="M44.52 81.76a72.77 72.77 0 0 1 102.91-.05h0v.05"
      />
      <path
        className={`superwifi2_svg__st0${rndClsId}`}
        d="M59.23 97.34a52 52 0 0 1 73.53 0h0M74.63 113.05a30.23 30.23 0 0 1 42.74 0h0"
      />
      <circle
        className={`superwifi2_svg__st0${rndClsId}`}
        cx="96"
        cy="132.38"
        r="2.71"
      />
      <circle
        className={`superwifi2_svg__st0${rndClsId}`}
        cx="96"
        cy="132.38"
        r="1.69"
      />
      <circle
        className={`superwifi2_svg__st0${rndClsId}`}
        cx="96"
        cy="132.38"
        r=".68"
      />
    </svg>
  );
};

export default SvgSuperWifi2;
