import * as React from "react";
import OfferEntryPriceSummary from "../summary/OfferEntryPriceSummary";
import styled from "styled-components";
import { Color } from "../../../../constants/color";
import { OfferEntryProps } from "../summary/OfferEntry";

const PrintOfferEntry = ({
  className,
  title = "",
  monthlyPriceArray = {},
  oncePrice = 0,
  productTitle = "",
  main,
}: OfferEntryProps) => {
  return (
    <div className={className}>
      <div className={"offer-entry-main-container"}>
        <span className={"offer-entry-main-label"}>{title}</span>
        <span className={"offer-entry-main-monthly-price"}>
          {monthlyPriceArray[0] !== undefined
            ? monthlyPriceArray[0].toFixed(2)
            : "0.00"}
          €
        </span>
        <span className={"offer-entry-main-once-price"}>
          {oncePrice.toFixed(2)} €
        </span>
        <span className={"offer-entry-product-title"}>{productTitle}</span>
      </div>
      {main && (
        <OfferEntryPriceSummary isOpen monthlyPriceArray={monthlyPriceArray} />
      )}
    </div>
  );
};

export default styled(PrintOfferEntry)`
  display: block;
  min-width: 150px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
  border: 1px black solid;
  .offer-entry-main-container {
    display: block;
    //min-height: 60px;
    padding-top: 5px;
    text-align: left;
    background-color: ${Color.WHITE};
    > span {
      display: inline-block;
      vertical-align: top;
      padding: 5px 10px;
    }

    .offer-entry-main-label {
      color: ${({ main }) => (main ? Color.RED : Color.BLACK)};
      font-size: 16px;
      font-weight: bold;
      width: 40%;
    }

    .offer-entry-main-monthly-price {
      text-align: right;
      width: 40%;

      font-size: 16px;
      font-weight: ${({ main }) => (main ? "bold" : "normal")};
    }

    .offer-entry-main-once-price {
      text-align: right;
      width: 20%;
      font-size: 16px;
      font-weight: ${({ main }) => (main ? "bold" : "normal")};
    }

    .offer-entry-product-title {
      color: ${({ main }) => (main ? Color.RED : Color.BLACK)};
      font-style: italic;
      width: 40%;
    }

    .offer-entry-product-price {
      color: ${({ main }) => (main ? Color.RED : Color.BLACK)};
      font-style: italic;
      width: 40%;
    }
  }
`;
