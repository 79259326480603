import { useOfferState } from "../useOffer";
import { useNewOfferState } from "../useOfferHelper/useNewOfferState";
import { useNewOfferStateArray } from "../useOfferHelper/useNewOfferStateArray";

export interface SubProductStateArrayProperty<T = string> {
  value: T[] | undefined;
  setter: (value: T, index: number) => void;
}

export interface SubProductStatePass {
  value: number[] | undefined;
  setter: (value: number) => void;
}

export interface SubProductStateSimpleProperty<T = string> {
  value: T;
  setter: (value: T) => void;
}

export interface RecommendationTableState {
  passes: {
    selected: SubProductStatePass;
  };
  additional: {
    labels: SubProductStateArrayProperty;
    countIds: SubProductStateArrayProperty<number>;
    prices: SubProductStateArrayProperty<number>;
    starts: SubProductStateArrayProperty<number>;
    gigaKombiLabelId: SubProductStateSimpleProperty<number>;
    regioDiscountLabelId: SubProductStateSimpleProperty<number>;
  };
  further: {
    labelIds: SubProductStateArrayProperty<number>;
    countIds: SubProductStateArrayProperty<number>;
    lastLabel: SubProductStateSimpleProperty;
    lastCount: SubProductStateSimpleProperty<number>;
    lastPrice: SubProductStateSimpleProperty<number>;
  };
  singlePayment: {
    labels: SubProductStateArrayProperty;
    prices: SubProductStateArrayProperty<number>;
  };
}

export interface SubProductStateObjects {
  mobile?: RecommendationTableState;
  iot?: RecommendationTableState;
  fixed?: RecommendationTableState;
  tv?: RecommendationTableState;
}

const useRecommendationTablesOfferState = (
  offerEntryId: string
): RecommendationTableState => {
  const [gigaKombiLabelId, setGigaKombiLabelId] =
    useOfferState("gigaKombiLabelId");
  const [regioDiscountLabelId, setRegioDiscountLabelId] = useOfferState(
    "regioDiscountLabelId"
  );

  const [passesSelected, setPassesSelected] = useNewOfferState(
    "passesSelected",
    offerEntryId
  );
  const toggleValue = (value: number) => {
    const newArray: number[] = Object.assign([], passesSelected);
    if (passesSelected.includes(value)) {
      setPassesSelected(newArray.filter((item) => item !== value));
    } else {
      setPassesSelected([...newArray, value]);
    }
  };

  const [additionalLabels, setAdditionalLabels] = useNewOfferStateArray(
    "additionalLabels",
    offerEntryId
  );
  const [additionalCountIds, setAdditionalCountIds] = useNewOfferStateArray(
    "additionalCountIds",
    offerEntryId
  );
  const [additionalPrices, setAdditionalPrices] = useNewOfferStateArray(
    "additionalPrices",
    offerEntryId
  );
  const [additionalStarts, setAdditionalStarts] = useNewOfferStateArray(
    "additionalStarts",
    offerEntryId
  );
  const [furtherLabelIds, setFurtherLabelIds] = useNewOfferStateArray(
    "furtherLabelIds",
    offerEntryId
  );
  const [furtherCountIds, setFurtherCountIds] = useNewOfferStateArray(
    "furtherCountIds",
    offerEntryId
  );
  const [furtherLastLabel, setFurtherLastLabel] = useNewOfferState(
    "furtherLastLabel",
    offerEntryId
  );
  const [furtherLastCount, setFurtherLastCount] = useNewOfferState(
    "furtherLastCount",
    offerEntryId
  );
  const [furtherLastPrice, setFurtherLastPrice] = useNewOfferState(
    "furtherLastPrice",
    offerEntryId
  );
  const [singlePaymentLabels, setSinglePaymentLabels] = useNewOfferStateArray(
    "singlePaymentLabels",
    offerEntryId
  );
  const [singlePaymentPrices, setSinglePaymentPrices] = useNewOfferStateArray(
    "singlePaymentPrices",
    offerEntryId
  );

  return {
    passes: {
      selected: { value: passesSelected, setter: toggleValue },
    },
    additional: {
      labels: { value: additionalLabels, setter: setAdditionalLabels },
      countIds: { value: additionalCountIds, setter: setAdditionalCountIds },
      prices: { value: additionalPrices, setter: setAdditionalPrices },
      starts: { value: additionalStarts, setter: setAdditionalStarts },
      gigaKombiLabelId: {
        value: gigaKombiLabelId,
        setter: setGigaKombiLabelId,
      },
      regioDiscountLabelId: {
        value: regioDiscountLabelId,
        setter: setRegioDiscountLabelId,
      },
    },
    further: {
      labelIds: { value: furtherLabelIds, setter: setFurtherLabelIds },
      countIds: { value: furtherCountIds, setter: setFurtherCountIds },
      lastLabel: { value: furtherLastLabel, setter: setFurtherLastLabel },
      lastCount: { value: furtherLastCount, setter: setFurtherLastCount },
      lastPrice: { value: furtherLastPrice, setter: setFurtherLastPrice },
    },
    singlePayment: {
      labels: { value: singlePaymentLabels, setter: setSinglePaymentLabels },
      prices: { value: singlePaymentPrices, setter: setSinglePaymentPrices },
    },
  };
};

export default useRecommendationTablesOfferState;
