import React, { useState } from "react";
import styled from "styled-components";
import { SlidePages } from "../../constants/SlidePages";
import LinkButton from "../form/LinkButton";
import { StyledComponentProps } from "../../types/default";
import JustifiedColumnsContainer from "../grid/JustifiedColumnsContainer";
import { Euro } from "../../icons/svgComponents";
import Print from "../../icons/svgComponents/Print";
import CancelModal from "../elements/cancelModal/CancelModal";
import { useHistory } from "react-router-dom";
import NewsPanelItemInner from "../elements/newsPanel/NewsPanelItemInner";
import Icons from "../../icons/Icons";
import { Color } from "../../constants/color";

interface SummaryRecommendationPaginationProps extends StyledComponentProps {}

const SummaryRecommendationPagination: React.FC<SummaryRecommendationPaginationProps> =
  ({ className }) => {
    const history = useHistory();
    const [showEndQuestion, setShowEndQuestion] = useState(false);
    const openEndQuestion = (shouldClose: boolean) => {
      setShowEndQuestion(!shouldClose);
    };
    return (
      <JustifiedColumnsContainer
        extraClassName={className}
        justifyContent={"space-between"}
      >
        <LinkButton
          startIcon={<Euro />}
          slideId={SlidePages.recommendation.id}
          title={"zur Produktempfehlung"}
          textAlign={"left"}
        />
        <LinkButton
          endIcon={<Print />}
          title={"Empfehlung drucken"}
          onChange={openEndQuestion}
          value
        />
        <CancelModal
          isOpen={showEndQuestion}
          setIsOpen={setShowEndQuestion}
          yesText={"Empfehlung jetzt erstellen"}
          yesAction={() => {
            history.push("/slides/" + SlidePages.offer.id);
          }}
          buttonStyle={{ height: "80px" }}
          headlineText={""}
          description={
            "Möchtest Du diese Produkte jetzt für Deinen Kunden buchen?"
          }
        >
          <NewsPanelItemInner
            titleIcon={Icons.gigaGreen}
            title={"GigaGreen"}
            style={{ margin: "10px 0" }}
            background={"transparent"}
            h2Style={{ color: Color.GREEN, marginBottom: "0px" }}
            text={
              "Möchtest Du dein altes Handy umweltfreundlich entsorgen und weißt nicht wohin damit?" +
              " Wirf Dein altes Smartphone einfach in unsere Recyclingbox" +
              " und wir von Vodafone übernehmen die fachgerechte Entsorgung für Dich."
            }
          />
        </CancelModal>
      </JustifiedColumnsContainer>
    );
  };

export default styled(SummaryRecommendationPagination)`
  margin: 0 5px;
`;
