export enum ProductLine {
  MOBILE = "mobile",
  FIXED = "fixed",
  TV = "tv",
  ALL = "all",
}

export interface RecommendationRequest {
  mobileInterest: boolean;
  iotInterest: boolean;
  fixedInterest: boolean;
  tvInterest: boolean;
  availabilityCable: number;
  availabilityDSL: number;
  additionalCostInvoice: boolean; // fixedInvoice
  neededDataVolume: number; // device Count * 50 Mbit/s
  neededBandwidth: number; // device Count * 50 Mbit/s
  under28: boolean;
  parentBetween10And17: boolean;
  cardData: number;
  cardTariff: number;
  cardTel: number;
  cardDataGo: number;
  personCount: number; // (0-5)
  mobileUseMusic: boolean;
  mobileUseSocial: boolean;
  mobileUseChat: boolean;
  mobileUseGaming: boolean;
  mobileUseVideo: boolean;
  productLine: ProductLine;
}
