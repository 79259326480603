import styled from "styled-components";
import TileButton, { TileButtonProps } from "../../form/TileButton";
import { Color } from "../../../constants/color";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormState } from "../../../hooks/useForm";
import useBootstrap from "../../../hooks/useBootstrap";
import { SlidePages } from "../../../constants/SlidePages";
import { useNavigation } from "../../../hooks/useNavigation";
import {
  getProviderKeyOfType,
  getProvidersOfType,
} from "../../../helper/recommendation/mapping";
import { Interest } from "../../../types/application";

export interface InterestButtonProps extends TileButtonProps {
  type: Interest;
  providerShouldSet?: boolean;
  noInterest?: boolean;
}

const InterestButton: React.FC<InterestButtonProps> = ({
  type,
  onChange,
  providerShouldSet = false,
  noInterest = false,
  ...props
}) => {
  const { pushInterestSlides } = useNavigation();

  let history = useHistory();

  const providerIdKey = getProviderKeyOfType(type);
  const providersKey = getProvidersOfType(type);
  const providers = useBootstrap().formConfig[providersKey];
  const [providerId, setProviderId] = useFormState(providerIdKey);
  const [provider, setProvider] = useState("Keine Angabe");
  const [vFProviderPos, setVFProviderPos] = useState(-1);

  // get value of current provider position
  useEffect(() => {
    if (providers[providerId] !== undefined) {
      setProvider(providers[providerId].label);
    }
  }, [providerId, providers]);
  // get "vodafone" position in given provider list
  useEffect(() => {
    const foundedVF = providers.find(
      (v) => v.label.toLowerCase() === "vodafone"
    );
    if (foundedVF !== undefined) {
      setVFProviderPos(foundedVF.value);
    }
  }, [providers]);

  const onButtonClick = (value: boolean) => {
    let isVodafoneProvider = provider === "Vodafone";
    if (isVodafoneProvider !== providerShouldSet) {
      setProviderId(isVodafoneProvider ? -1 : vFProviderPos);
    }
    pushInterestSlides(type, !noInterest);
    history.push("/slides/" + SlidePages.initialInterest.id);
    if (onChange) {
      onChange(value);
    }
  };

  // ----------

  return <TileButton onChange={onButtonClick} {...props} />;
};
export default styled(InterestButton)`
  background-color: ${Color.RED};

  @media (min-width: 1023px) {
    width: calc(50% - 6px);
    width: -moz-calc(50% - 6px);
    width: -webkit-calc(50% - 6px);
    width: -o-calc(50% - 6px);
    padding: 5px;
  }
  @media (max-width: 1023px) {
    width: calc(100% - 6px);
    width: -moz-calc(100% - 6px);
    width: -webkit-calc(100% - 6px);
    width: -o-calc(100% - 6px);
    padding: 5px 1px;
  }

  display: flex;
  justify-content: center;
  flex-direction: column;

  min-height: 70px;
  margin: 8px 3px;
  line-height: 0;
  vertical-align: top;
  box-shadow: 3px 3px 5px 0 ${Color.GREY4};

  span {
    vertical-align: middle;
  }
`;
