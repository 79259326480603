import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import { ZIndex } from "../../constants/zIndex";
import IESlider from "./IESlider";
import prepareSliderData from "../../helper/prepareSliderData";

export interface SliderProps {
  min?: number;
  max?: number;
  value: number;
  step?: number;
  disabled?: boolean;
  afterValue?: string;
  description?: string;
  onChange: (newValue: number) => void;
  className?: string;
  color?: string;
  maxString?: string;
  list?: number[] | string[];
  padding?: string;
}

function isIEBrowser() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");
  return msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);
}

const Slider: React.FC<SliderProps> = ({ className, ...props }) => {
  const { value, description, afterValue, disabled } = props;
  if (isIEBrowser()) {
    return <IESlider {...props} />;
  }
  const { valueString, minValue, maxValue, stepValue, changeValue } =
    prepareSliderData(props);

  return (
    <div className={className + " slider"}>
      <div className="slider-container">
        <div className="slider-content-white" />
        <div className="slider-content-black" />
        <div className="slider-content" />
        <div className="text-container">
          <span className="slider-value">
            {valueString}
            {afterValue ? " " + afterValue : ""}
          </span>
          {description && (
            <span className="slider-description">{description}</span>
          )}
        </div>
        <div className="slider-shadow" />

        <input
          type="range"
          value={value}
          min={minValue}
          max={maxValue}
          step={stepValue}
          onChange={changeValue}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
const calculatedCurrentProgress = ({
  value,
  max,
  list,
}: SliderProps): string => {
  let realValue = value;
  let maxValue = max ? max : value;
  if (list !== undefined && list.length > 0 && list[value]) {
    maxValue = list.length - 1;
  }
  return (realValue / maxValue) * 100 + "%";
};
const getPadding = (props: SliderProps): string =>
  props.padding ? props.padding : "10px 0 60px 0";
const getMainColor = (props: SliderProps): string =>
  props.color ? props.color : props.disabled ? Color.GREY : Color.RED;
const getDarkColor = (props: SliderProps): string =>
  props.color ? props.color : props.disabled ? Color.GREY : Color.RED;
const getCursor = (props: SliderProps): string =>
  props.disabled ? "not-allowed" : "pointer";
const getThumbCSS = (props: SliderProps): string => `
            -webkit-appearance: none;
            width: 60px;
            height: 60px;
            cursor: ${getCursor(props)};
            border: 4px solid ${Color.WHITE};
            background: ${getMainColor(props)};
            border-radius: 50%;
            position: relative;
            appearance: none;
            box-shadow: 2px 2px 4px 1px ${Color.BLACK};`;

export default styled(Slider)`

    position: relative;
    margin: 15px 50px 15px 50px;
    width: 100%;
    max-width: calc(100% - 100px);
    height: 40px;
    padding:${getPadding};

    input {
        -webkit-appearance: none;
        width: 100%;
        outline: none;
        z-index: ${ZIndex.SLIDER_INPUT};
        position: absolute;
        background: transparent;
        margin-top: 10px;


        // Chrome
        ::-webkit-slider-thumb {
            ${getThumbCSS}
        }

        // CHROME
        ::-webkit-slider-thumb {
            // fix the thumb position
            margin-top: -20px;
        }

        ::-webkit-slider-runnable-track {
            // hide track
            height: 0;
        }


        // FIREFOX
        ::-moz-range-thumb {
            ${getThumbCSS}
        }

        ::-moz-range-progress {
            // hide track
            height: 0;
        }

        ::-moz-focus-outer {
            // to disable the greyed dotted line on thumb-moving
            border: 0;
        }
        
        ::-moz-range-track {
            visibility: hidden;
        }

        // IE / Edge
        // for the slider thumb itself.
        ::-ms-thumb {
            ${getThumbCSS};
            // fix the thumb position
            margin-top: 0;
        }

        @supports (-ms-ime-align:auto) {
            // fix the thumb position
            margin-top: -10px;
        }

        //styles the sliders individual tracks.
        ::-ms-track {
            // fix the size of the thumb
            height: 60px;
        }

    }
}

// the whole replacement-slider
.slider-container {
    width: 100%;
    height: 40px;
    background: ${Color.WHITE};
    box-shadow: inset -4px 6px 13px -4px ${Color.BLACK};
    margin-top: 5px;

    // this white container is required for color changing
    .slider-content-white {
        width: 100%;
        height: 40px;
        position: absolute;
        z-index: ${ZIndex.SLIDER_WHITE_CONTAINER};

        background: ${Color.WHITE};
    }

    // this black container is required for color changing
    .slider-content-black {
        width: ${calculatedCurrentProgress};
        height: 40px;
        position: absolute;
        z-index: ${ZIndex.SLIDER_BLACK_CONTAINER};

        background: ${Color.BLACK};

        // in IE / Edge is no color changing functionality, so this container is the colored "progress" bar
        @supports (-ms-ime-align:auto) {
            background: linear-gradient(135deg, ${getMainColor}, ${getDarkColor});
        }
    }

    // this container is the real bar
    .slider-content {
        width: 100%;
        height: 40px;
        position: absolute;
        z-index: ${ZIndex.SLIDER_CONTENT};

        background: linear-gradient(135deg, ${getMainColor}, ${getDarkColor});
        mix-blend-mode: screen;

        // in IE / Edge is no color changing functionality, so this container transparent and not visible
        @supports (-ms-ime-align:auto) {
            background: transparent;
        }
    }

    // this container contains the two text values
    .text-container {
        width: 100%;
        height: 40px;
        position: absolute;
        z-index: ${ZIndex.SLIDER_TEXT};
        font-weight: lighter;

        word-break: break-word;
        text-overflow: ellipsis;

        // color
        color: ${Color.WHITE};
        mix-blend-mode: difference;
        @supports (-ms-ime-align:auto) {
            color: ${Color.BLACK};
        }


        .slider-description,
        .slider-value {
            position: absolute;
            display: inline;
            font-size: 24px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 8px;
        }

        // first value, on start of the bar
        .slider-value {
            max-width: 50%;
            font-weight: normal;
        }

        // second value, on the middle of the bar
        .slider-description {
            left: 50%;
            max-width: 50%;
        }
    }

    // this is a extra container for the shadow effect
    .slider-shadow {
        width: 100%;
        height: 40px;
        position: absolute;
        z-index: ${ZIndex.SLIDER_SHADOW};

        background: transparent;
        box-shadow: inset -4px 2px 20px -10px ${Color.BLACK};
    }
}

`;
