import React from "react";
import useVauoid from "../../../hooks/useVauoid";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import useAuth from "../../../hooks/useAuth";
import { startsWith } from "lodash";
import {
  loadConfigData,
  LoadableResources,
} from "../../../helper/loadConfigData";

interface VauoidStatusProps {
  className?: string;
  onClick: () => void;
}

const prodUrl = "https://des.vodafone.de";

const redirectUrl: string | undefined =
  process.env.NODE_ENV === "production" &&
  startsWith(window.location.href, prodUrl)
    ? process.env.REACT_APP_REDIRECT_PROD
    : process.env.REACT_APP_REDIRECT_TEST;
const redirectBase: string = redirectUrl === undefined ? "/" : redirectUrl;

const VauoidStatus: React.FC<VauoidStatusProps> = ({ className, onClick }) => {
  const [vauoid] = useVauoid();
  const { user, logout } = useAuth();

  const onLogoutClick = async () => {
    logout(1000);
    // Clear session on server, but dont wait for the result
    loadConfigData(
      (p: any) => [[], true],
      LoadableResources.LOGOUT,
      () => {} // No update check on Logout
    )().catch((e) => {
      // if (e.code && e.code === 401) {
      //   logout();
      // }
    });
    window.location.href = `${redirectBase}/logout.aspx`;
  };

  if (!vauoid) {
    return null;
  }
  return (
    <div className={className}>
      Deine aktuelle VoId: <span className="vauoid">{vauoid}</span>.{" "}
      <span onClick={onClick} className="link-button">
        Ändern?
      </span>
      {user && (
        <span>
          {" "}
          | Angemeldet als {user.email}{" "}
          <span onClick={onLogoutClick} className="link-button">
            Abmelden?
          </span>
        </span>
      )}
      {" "}|{" "}<a className="link-button" target="_blank" href="https://www.vodafone.de/unternehmen/soziale-verantwortung/datenschutz-privatsphaere.html">Datenschutzhinweise</a>
    </div>
  );
};

export default styled(VauoidStatus)`
  /* height: 30px; */
  position: absolute;
  bottom: 0;
  background-color: ${Color.GREY_DARK};
  width: 100vw;
  color: ${Color.WHITE};
  text-align: center;
  font-size: 20px;
  padding: 10px 0;

  .vauoid {
    font-weight: bold;
  }

  a {
    color: ${Color.WHITE}
  }

  a:visited {
    color: ${Color.WHITE}
  }

  .link-button {
    cursor: pointer;
    text-decoration: underline;
  }
`;
