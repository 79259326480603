import { InterestsValues } from "../../hooks/useFormHelper/useInterests";
import { DatabaseProduct } from "../../data/definitions/DatabaseProduct";
import { DatabaseProductTypes } from "../../data/definitions/DatabaseProductTypes";
import {
  FormStateCategoryHasChanged,
  FormStateCategoryHasData,
  FormStateNumbers,
} from "../../state/definitions/FormState";
import {
  FormConfigState,
  FormConfigStateProviders,
} from "../../state/definitions/FormConfigState";
import {
  ContractSummary,
  Interest,
  InterestObject,
  RecommendationModeArray,
  RecommendationType,
} from "../../types/application";

export function getModeOfInterest(
  type: Interest,
  interestValues: InterestsValues
) {
  switch (type) {
    case Interest.MOBILE:
      return interestValues.mobileMode;
    case Interest.IOT:
      return interestValues.iotMode;
    case Interest.FIXED:
      return interestValues.fixedMode;
    case Interest.TV:
      return interestValues.tvMode;
  }
}

export function getInterestFromModeKey(
  mode: keyof RecommendationModeArray
): Interest {
  switch (mode) {
    case "mobileMode":
      return Interest.MOBILE;
    case "iotMode":
      return Interest.IOT;
    case "fixedMode":
      return Interest.FIXED;
    case "tvMode":
      return Interest.TV;
  }
}

export function getModeKeyFromInterest(
  mode: Interest
): keyof RecommendationModeArray {
  switch (mode) {
    case Interest.MOBILE:
      return "mobileMode";
    case Interest.IOT:
      return "iotMode";
    case Interest.FIXED:
      return "fixedMode";
    case Interest.TV:
      return "tvMode";
  }
}

export function getProductOfInterest(
  type: Interest,
  products: DatabaseProduct[]
) {
  return products.find((product) => {
    switch (product.productType) {
      case DatabaseProductTypes.mobile:
        return type === Interest.MOBILE;
      case DatabaseProductTypes.iot:
        return type === Interest.IOT;
      case DatabaseProductTypes.fixed:
        return type === Interest.FIXED;
      case DatabaseProductTypes.tv:
        return type === Interest.TV;
    }
    return false;
  });
}

export function getProviderKeyOfType(type: Interest): keyof FormStateNumbers {
  switch (type) {
    case Interest.MOBILE:
      return "providerMobileId";
    case Interest.IOT:
      return "providerIotId";
    case Interest.FIXED:
      return "providerFixedId";
    case Interest.TV:
      return "providerTvId";
  }
}

export function getProvidersOfType(
  type: Interest
): keyof FormConfigStateProviders {
  switch (type) {
    case Interest.MOBILE:
      return "mobileProviders";
    case Interest.IOT:
      return "iotProviders";
    case Interest.FIXED:
      return "fixedProviders";
    case Interest.TV:
      return "tvProviders";
  }
}

export function getInterestFromType(type?: Interest): string {
  switch (type) {
    case Interest.MOBILE:
      return RecommendationType.MOBILE;
    case Interest.IOT:
      return RecommendationType.IOT;
    case Interest.FIXED:
      return RecommendationType.FIXED;
    case Interest.TV:
      return RecommendationType.TV;
    default:
      return "Weitere Produkte:";
  }
}

export function getModeFromProductType(
  type: DatabaseProductTypes
): keyof RecommendationModeArray {
  switch (type) {
    case DatabaseProductTypes.mobile:
    case DatabaseProductTypes.unknown:
      return "mobileMode";
    case DatabaseProductTypes.iot:
      return "iotMode";
    case DatabaseProductTypes.fixed:
      return "fixedMode";
    case DatabaseProductTypes.tv:
      return "tvMode";
  }
}

export function getRecommendationTable(type: Interest) {
  switch (type) {
    case Interest.MOBILE:
      return "mobileTable";
    case Interest.IOT:
      return "iotTable";
    case Interest.FIXED:
      return "fixedTable";
    case Interest.TV:
      return "tvTable";
  }
}

export function getHasChangedFromInterest(
  key: Interest
): keyof FormStateCategoryHasChanged {
  switch (key) {
    case Interest.MOBILE:
      return "mobileHasChanged";
    case Interest.IOT:
      return "iotHasChanged";
    case Interest.FIXED:
      return "fixedHasChanged";
    case Interest.TV:
      return "tvHasChanged";
  }
}

export function getInterestFromHasChanged(
  key: keyof FormStateCategoryHasChanged
): Interest {
  switch (key) {
    case "mobileHasChanged":
      return Interest.MOBILE;
    case "iotHasChanged":
      return Interest.IOT;
    case "fixedHasChanged":
      return Interest.FIXED;
    case "tvHasChanged":
      return Interest.TV;
  }
}

export function getHasDataFromHasChanged(
  key: keyof FormStateCategoryHasChanged
): keyof FormStateCategoryHasData {
  switch (key) {
    case "mobileHasChanged":
      return "mobileHasData";
    case "iotHasChanged":
      return "iotHasData";
    case "fixedHasChanged":
      return "fixedHasData";
    case "tvHasChanged":
      return "tvHasData";
  }
}

export function getInterestFromInterestObjectKey(
  key: keyof InterestObject
): Interest {
  switch (key) {
    case "mobile":
      return Interest.MOBILE;
    case "iot":
      return Interest.IOT;
    case "fixed":
      return Interest.FIXED;
    case "tv":
      return Interest.TV;
  }
}

export function getInterestFromGKSegment(key: string): Interest | undefined {
  switch (key) {
    case "gk-mobile":
      return Interest.MOBILE;
    case "gk-iot":
      return Interest.IOT;
    case "gk-fixed":
      return Interest.FIXED;
    case "gk-tv":
      return Interest.TV;
  }
}

export function getMobileCardLabel(
  key: keyof ContractSummary,
  formConfig: FormConfigState
) {
  switch (key) {
    case "cardData":
      return formConfig.mobileCardDataLabel[0].label;
    case "cardTariff":
      return formConfig.mobileCardTariffLabel[0].label;
    case "cardDataGo":
      return formConfig.mobileCardDataGoLabel[0].label;
  }
}

export function getTempIdKey(
  type: Interest
): "mobileTempId" | "fixedTempId" | "tvTempId" | "iotTempId" {
  switch (type) {
    case Interest.MOBILE:
      return "mobileTempId";
    case Interest.IOT:
      return "fixedTempId";
    case Interest.FIXED:
      return "tvTempId";
    case Interest.TV:
      return "iotTempId";
  }
}
