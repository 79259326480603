import * as React from "react";

function SvgCable(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;

  return (
    <svg
      id="cable_svg__ICON"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.cable_svg__st1{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-miterlimit:10}`}
      </style>
      <path
        d="M449.7 492.5H50.3c-23.6 0-42.8-19.2-42.8-42.8V50.3c0-23.6 19.2-42.8 42.8-42.8h399.4c23.6 0 42.8 19.2 42.8 42.8v399.4c0 23.6-19.2 42.8-42.8 42.8z"
        fill="none"
        stroke={color}
        strokeWidth={24.173}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle className="cable_svg__st1" cx={250} cy={150.1} r={57.1} />
      <path className="cable_svg__st1" d="M250 150.1" />
      <circle className="cable_svg__st1" cx={150.1} cy={349.9} r={57.1} />
      <path className="cable_svg__st1" d="M150.1 349.9" />
      <circle className="cable_svg__st1" cx={349.9} cy={349.9} r={57.1} />
      <path className="cable_svg__st1" d="M349.9 349.9" />
    </svg>
  );
}

export default SvgCable;
