import {
  ExtractArrayKeysFromObject,
  ExtractArrayValueFromObject,
} from "../../types/util";
import { AppState } from "../../state/definitions/AppState";
import { SimpleStateArray } from "../../types/default";
import { useContext } from "react";
import { AppContext } from "../../state/AppContext";
import { AppStatePropObjects } from "./useAppState";

/**
 * A key from a state object in AppState, which has an Array as value
 */
export type AppStateArrayKey<StateKey extends keyof AppStatePropObjects> =
  ExtractArrayKeysFromObject<AppStatePropObjects, StateKey>;

/**
 * An array from a state object in AppState
 */
type AppStateArray<
  StateKey extends keyof AppStatePropObjects,
  Key extends keyof AppStateArrayKey<StateKey>
> = ExtractArrayValueFromObject<AppStatePropObjects, StateKey, Key>;

/**
 * StateKey is property of AppState
 * AppState[StateKey] is type of the property of AppState
 * Key is property of AppStateArrayKey<StateKey>
 * AppState[StateKey][Key] is type of the property of AppStateArray<StateKey, Key>
 *
 * AppState[StateKey][Key] have to be an array,
 * because StateKey can only be a key from AppState[StateKey] with the type Array.
 *
 * The param withChangeFct allows to change or
 * overwrite some specific values in the specific state.
 *
 * @param stateKey
 * @param key
 * @param withChangeFct
 */
export const useAppStateArray = <
  StateKey extends keyof AppStatePropObjects,
  Key extends keyof AppStateArrayKey<StateKey>
>(
  stateKey: StateKey,
  key: Key,
  withChangeFct?: (keys: Key) => Partial<AppState[StateKey]>
): SimpleStateArray<AppStateArray<StateKey, Key>[number]> => {
  const [state, setState] = useContext(AppContext);
  const setValue = (
    value: AppStateArray<StateKey, Key>[number],
    index: number
  ) => {
    let changedObject = {};
    if (withChangeFct) {
      changedObject = withChangeFct(key);
    }
    setState((prev: AppState) => {
      const arrayValue: AppStateArray<StateKey, Key> = prev[stateKey][key];
      arrayValue[index] = value;
      return {
        ...prev,
        [stateKey]: Object.assign(
          {},
          {
            ...prev[stateKey],
            [key]: arrayValue,
            ...changedObject,
          }
        ),
      };
    });
  };

  return [state[stateKey][key], setValue];
};
