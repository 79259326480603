import styled from "styled-components";
import React from "react";
import CancelModal from "../cancelModal/CancelModal";
import { useHistory } from "react-router-dom";
import { useNavigation } from "../../../hooks/useNavigation";
import useAppReload from "../../../hooks/useAppHelper/useAppReload";
import { SlidePages } from "../../../constants/SlidePages";
import TileButton from "../../form/TileButton";
import { StyledComponentProps } from "../../../types/default";

interface AppModalsProps extends StyledComponentProps {}

const AppModals: React.FC<AppModalsProps> = ({ className }) => {
  const history = useHistory();
  const {
    isCancelShowing,
    setIsCancelShowing,
    isInterestShowing,
    setIsInterestShowing,
    toggleInterest,
    currentSlideIndex,
  } = useNavigation();

  const [reloadRequested] = useAppReload();

  return (
    <div className={className}>
      <CancelModal
        isOpen={isInterestShowing !== null}
        setIsOpen={() => setIsInterestShowing(null)}
        yesAction={() => {
          if (isInterestShowing !== null) {
            toggleInterest(isInterestShowing);
          }
          setIsInterestShowing(null);
          if (currentSlideIndex !== SlidePages.initialInterest.id) {
            history.push("/slides/" + SlidePages.initialInterest.id);
          }
        }}
        noText={"Nein"}
        noCloseButton
        description={""}
        headlineText={"Hast Du Interesse an diesem Produkt?"}
      />
      <CancelModal
        isOpen={isCancelShowing}
        setIsOpen={setIsCancelShowing}
        yesAction={() => {
          history.push("/");
        }}
        noText={"Nein"}
        noCloseButton
        description={
          "Alle bisher von Dir eingegebenen Daten gehen hierbei verloren."
        }
      >
        {currentSlideIndex !== SlidePages.initialInterest.id && (
          <TileButton
            value={true}
            onChange={() => {
              history.push("/slides/" + SlidePages.initialInterest.id);
              setIsCancelShowing(false);
            }}
          >
            Zur Startseite
          </TileButton>
        )}
      </CancelModal>
      <CancelModal
        noCloseButton
        isOpen={reloadRequested}
        yesText="Jetzt aktualisieren"
        yesAction={() => window.location.reload()}
        setIsOpen={() => {}}
        description="Der Server benötigt eine neuere Version dieser Webseite, damit die
          Anwendung ordnungsgemäß funktionieren kann. Lade daher bitte die Seite
          neu."
        headlineText="Update verfügbar"
      />
    </div>
  );
};

export default styled(AppModals)``;
