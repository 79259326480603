import * as React from "react";

function SvgGigaKombiOverview(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="ICON"
      viewBox="0 0 192 192"
      {...props}
    >
      <path fill="none" d="M0 0h192v192H0z" />
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        d="M83.58 148H124"
      />
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="bevel"
        strokeWidth={8}
        d="M69 132h71"
      />
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        d="M164 84V56a12.49 12.49 0 00-12-12H40a12.49 12.49 0 00-12 12v22M96 148v-16m60 8h8"
      />
      <rect
        width={40}
        height={72}
        x={140}
        y={84}
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        rx={6}
        ry={6}
      />
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        d="M105.5 99.88a13.47 13.47 0 00-19 0M115 87.93h0a26.94 26.94 0 00-38.07 0m47.6-12.04a40.41 40.41 0 00-57.06-.05"
      />
      <path
        fill={color}
        d="M30.46 82a3.14 3.14 0 012.85 1.44c.63 1.28 6.7 15 7.35 16.54.45 1.07-.07 2.63-1.18 4s-5.61 6.7-5.61 6.7 1.7 4.47 8.48 13.93c3.21 4.57 12.08 13.17 12.08 13.17l6.76-3.49a7.17 7.17 0 012.12-.35 3.27 3.27 0 012.15.65c1.34 1.15 12.44 11.51 13.38 12.42s1.06 3.07-.76 4.85S68.75 160 65.65 160a1.81 1.81 0 01-.36 0c-2.83-.58-14.59-2.87-32.93-28.5s-16.52-37.22-16.11-40 9.8-7.92 12.13-9a5 5 0 012.08-.5m0-8a13 13 0 00-5.38 1.19 67.79 67.79 0 00-7.87 4.44c-4 2.62-8.18 5.94-8.87 10.67-1.75 11.7 4.15 27.16 17.51 45.82s26 29.26 37.79 31.67a9.83 9.83 0 002 .2c3.23 0 6.65-1.48 11.42-4.95a68 68 0 006.59-5.45 12.27 12.27 0 003.83-8.77 10.54 10.54 0 00-3.06-7.51c-.9-.88-12.14-11.38-13.75-12.77a11.1 11.1 0 00-7.36-2.54 15.08 15.08 0 00-4.53.72 8 8 0 00-1.26.52l-1.57.81a84.91 84.91 0 01-7.06-8v-.07c-2.5-3.49-4.24-6.22-5.41-8.2 1-1.15 1.88-2.25 2.33-2.82 3-3.76 3.86-8.29 2.27-12.07-.52-1.23-6.76-15.38-7.56-17a11.12 11.12 0 00-10-5.89z"
      />
    </svg>
  );
}

export default SvgGigaKombiOverview;
