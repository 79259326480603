import React, { useMemo } from "react";
import useRecommendation from "../../hooks/useRecommendation";
import styled from "styled-components";
import SpinningLogo from "../logo/SpinningLogo";
import { useOfferState } from "../../hooks/useOffer";
import ErrorModal from "../error/ErrorModal";
import { useRecommendationLoading } from "../../hooks/useRecommendationHelper/useRecommendationLoading";
import MiniGigaKombiContainer from "../elements/recommendationPage/gigakombi/MiniGigaKombiContainer";
import { useErrorModal } from "../../hooks/helper/useErrorModal";
import GigaKombiModalsContainer from "../elements/recommendationPage/gigakombi/GigaKombiModalsContainer";
import { StyledComponentProps } from "../../types/default";
import RecommendationTableContainer from "../elements/recommendationPage/RecommendationTableContainer";
import { useNewOffer } from "../../hooks/useNewOffer";
import { useNavigation } from "../../hooks/useNavigation";
import useEndPrice from "../../hooks/useOfferHelper/useEndPrice";
import PriceSummaryContainer from "../elements/recommendationPage/PriceSummaryContainer";

const RecommendationPage: React.FC<StyledComponentProps> = ({ className }) => {
  const { loading, errorMessage, errorCode } = useRecommendation();
  useRecommendationLoading();

  const [showLoadErrorMessage, setShowLoadErrorMessage] =
    useErrorModal(errorMessage);

  const [offerEntryIds] = useOfferState("offerEntryIds");
  const { getEntryProperty } = useNewOffer();
  const mainOfferEntryIds = useMemo(() => {
    return getEntryProperty("rowId", (v) => v.mainRow);
  }, [offerEntryIds]);

  const { showLastSlide } = useNavigation();
  const [wholeEndPrices, wholeOncePrice] = useEndPrice();

  return (
    <div className={className}>
      {loading && <SpinningLogo />}
      {!loading && (
        <>
          <RecommendationTableContainer offerEntryIds={mainOfferEntryIds} />
          <PriceSummaryContainer
            monthlyPriceArray={wholeEndPrices ? wholeEndPrices : {}}
            oncePrice={wholeOncePrice ? wholeOncePrice : 0}
          >
            <MiniGigaKombiContainer />
          </PriceSummaryContainer>
          <GigaKombiModalsContainer />
          <ErrorModal
            isOpen={showLoadErrorMessage}
            setIsOpen={(nV) => {
              setShowLoadErrorMessage(nV);
              if (!nV) {
                showLastSlide();
              }
            }}
            textKey={errorMessage}
            code={errorCode}
          />
        </>
      )}
    </div>
  );
};

export default styled(RecommendationPage)`
  height: 100%;
  overflow: auto;
`;
