import * as React from "react";

function SvgMoneyColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="money_color_svg__Icons"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".money_color_svg__st0,.money_color_svg__st1{fill:none;stroke:#e60000;stroke-width:8}.money_color_svg__st0{stroke-miterlimit:10}.money_color_svg__st1{stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <path
        className="money_color_svg__st0"
        d="M60.5 105.2c-.4.8-.5 1.6-.5 2.4 0 8.4 19.7 16.2 44 16.2s44-7.8 44-16.2c0-4.7-6.3-9.3-16.2-12.4"
      />
      <path
        className="money_color_svg__st1"
        d="M148 108v28.3c-5.7 6.8-23.2 11.7-44 11.7s-38.3-3.6-44-10.3v-29.1"
      />
      <path
        className="money_color_svg__st0"
        d="M116.4 56c9.5 3 15.6 7.4 15.6 12 0 8.3-19.7 16-44 16s-44-7.7-44-16 19.7-16 44-16c10.8 0 20.7 1.5 28.4 4z"
      />
      <path
        className="money_color_svg__st1"
        d="M132 67.8v28.3c-5.7 6.8-23.2 11.7-44 11.7s-38.3-3.6-44-10.3v-29"
      />
      <path
        d="M60 132c-8 .6-8 4.9-8 4.9v25c5.7 6.9 23.2 10 44 10s38.3-4.4 44-11.3v-19"
        fill="none"
        stroke="#e60000"
        strokeWidth={8}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgMoneyColor;
