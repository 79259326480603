import image11 from "./application/1_1.png";
import placeholder from "./application/placeholder.png";
import image12 from "./application/1_2.png";
import image13 from "./application/1_3.png";
import image14 from "./application/1_4.png";
import image31 from "./application/3_1.png";
import image32 from "./application/3_2.png";
import image33 from "./application/3_3.png";
import image51 from "../images/application/5_1.png";
import image52 from "../images/application/5_2.png";
import image53 from "../images/application/5_3.png";
import image54 from "../images/application/5_4.png";
import image101 from "./application/10_1.png";
import image102 from "./application/10_2.png";
import image103 from "./application/10_3.png";
import image104 from "./application/10_4.png";
import image121 from "./application/12_1.png";
import image122 from "./application/12_2.png";
import image123 from "./application/12_3.png";
import image71 from "./application/7_1.png";
import image72 from "./application/7_2.png";
import image74 from "./application/7_4.png";
import image91 from "./application/9_1.jpg";
import image92 from "./application/9_2.jpg";
import image93 from "./application/9_3.jpg";
import image131 from "./application/13_1.png";
import image132 from "./application/13_2.png";
import image181 from "./application/18_1.png";
import image182 from "./application/18_2.png";
import image221 from "./application/22_1.png";

import previewImage11 from "./application/preview/1_1.jpg";
import previewPlaceholder from "./application/preview/1_1.jpg";
import previewImage12 from "./application/preview/1_2.jpg";
import previewImage13 from "./application/preview/1_3.jpg";
import previewImage14 from "./application/preview/1_4.jpg";
import previewImage31 from "./application/preview/3_1.png";
import previewImage32 from "./application/preview/3_2.png";
import previewImage33 from "./application/preview/3_3.png";
import previewImage51 from "../images/application/preview/5_1.png";
import previewImage52 from "../images/application/preview/5_2.png";
import previewImage53 from "../images/application/preview/5_3.png";
import previewImage54 from "../images/application/preview/5_4.png";
import previewImage101 from "./application/preview/10_1.png";
import previewImage102 from "./application/preview/10_2.png";
import previewImage103 from "./application/preview/10_3.png";
import previewImage104 from "./application/preview/10_4.png";
import previewImage121 from "./application/preview/12_1.png";
import previewImage122 from "./application/preview/12_2.png";
import previewImage123 from "./application/preview/12_3.png";
import previewImage71 from "./application/preview/7_1.png";
import previewImage72 from "./application/preview/7_2.png";
import previewImage74 from "./application/preview/7_4.png";
import previewImage91 from "./application/preview/9_1.jpg";
import previewImage92 from "./application/preview/9_2.jpg";
import previewImage93 from "./application/preview/9_3.jpg";
import previewImage131 from "./application/preview/13_1.png";
import previewImage132 from "./application/preview/13_2.png";
import previewImage181 from "./application/preview/18_1.png";
import previewImage182 from "./application/preview/18_2.png";
import previewImage221 from "./application/preview/22_1.png";
import { FormConfigLabelIds } from "../data/definitions/FormConfigLabelIds";

export interface ImageSet {
  image: string;
  preview: string;
}

export interface ImageMapInterface {
  [id: number]: ImageSet;
}

const ImageMap: ImageMapInterface = {
  0: { image: placeholder, preview: previewPlaceholder },
  [FormConfigLabelIds.MOBILE]: { image: image11, preview: previewImage11 },
  [FormConfigLabelIds.FIXED]: { image: image12, preview: previewImage12 },
  [FormConfigLabelIds.TV]: { image: image13, preview: previewImage13 },
  [FormConfigLabelIds.IOT]: { image: image14, preview: previewImage14 },
  213: { image: image31, preview: previewImage31 },
  214: { image: image32, preview: previewImage32 },
  215: { image: image33, preview: previewImage33 },
  209: { image: image71, preview: previewImage71 },
  210: { image: image72, preview: previewImage72 },
  211: { image: image33, preview: previewImage33 },
  2013: { image: image32, preview: previewImage32 },
  246: { image: image51, preview: previewImage51 },
  247: { image: image52, preview: previewImage52 },
  248: { image: image53, preview: previewImage53 },
  249: { image: image54, preview: previewImage54 },
  265: { image: image91, preview: previewImage91 },
  266: { image: image92, preview: previewImage92 },
  267: { image: image93, preview: previewImage93 },
  301: { image: image101, preview: previewImage101 },
  302: { image: image102, preview: previewImage102 },
  303: { image: image103, preview: previewImage103 },
  3004: { image: image104, preview: previewImage104 },
  304: { image: image121, preview: previewImage121 },
  305: { image: image122, preview: previewImage122 },
  306: { image: image123, preview: previewImage123 },
  401: { image: image181, preview: previewImage181 },
  402: { image: image182, preview: previewImage182 },
  [FormConfigLabelIds.ADDITIONAL_INVOICE_COSTS]: {
    image: image131,
    preview: previewImage131,
  },
  [FormConfigLabelIds.ADDITIONAL_INVOICE_PERSONAL]: {
    image: image132,
    preview: previewImage132,
  },
  999: { image: image221, preview: previewImage221 },
};
ImageMap[FormConfigLabelIds.MOBILE_COOPERATED] = {
  image: image74,
  preview: previewImage74,
};

export const getImageSet = (id: number): ImageSet => {
  return ImageMap[id] !== undefined ? ImageMap[id] : ImageMap[0];
};
