import * as React from "react";

function SvgEu(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="eu_svg__ICON"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.eu_svg__st1{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-miterlimit:10}`}
      </style>
      <path
        d="M492.5 250c0 133.9-108.6 242.5-242.5 242.5S7.5 383.9 7.5 250 116.1 7.5 250 7.5 492.5 116.1 492.5 250"
        fill="none"
        stroke={color}
        strokeWidth={24.173}
        strokeMiterlimit={10}
      />
      <path
        className="eu_svg__st1"
        d="M211.7 173.4h-77.6M211.7 250h-77.6M211.7 326.6h-77.6M134.1 173.4v153.2M377.6 289.4s0 40.4-44.7 40.4-44.7-40.4-44.7-40.4M288.3 176.6v107.7M377.6 176.6v107.7"
      />
    </svg>
  );
}

export default SvgEu;
