import React, { useMemo } from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import { ZIndex } from "../../constants/zIndex";

interface ProgressProps {
  className?: string;
  value: number;
}

interface BarProps {
  width: number;
}

const Bar = styled.span<BarProps>`
  width: ${(props) => `${props.width ?? 0}%`};
  display: inline-block;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: ${Color.RED};
  background-image: linear-gradient(
    center bottom,
    rgb(43, 194, 83) 37%,
    rgb(84, 240, 84) 69%
  );
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;

  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      -90deg,
      rgba(255, 255, 255, 1) 5%,
      transparent 5%,
      transparent 50%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 55%,
      transparent 55%,
      transparent
    );
    z-index: ${ZIndex.FRONT_1};
    background-size: 30px 20px;
    border-radius: 8px;
    overflow: hidden;
  }
`;

const Progress: React.FC<ProgressProps> = ({ className, value }) => {
  const progress = useMemo(
    () => Math.min(Math.max(Math.round(value * 100), 0), 100),
    [value]
  );
  return (
    <span className={className}>
      <span className="bar">
        <Bar width={progress} />
      </span>
    </span>
  );
};

export default styled(Progress)`
  display: inline-block;
  background: ${Color.GREY_LIGHT9};
  height: 40px;
  padding: 13px;
  width: 320px;
  vertical-align: top;
  text-align: left;

  .bar {
    vertical-align: top;
    width: 100%;
    display: inline-block;
    height: 14px;
    /* position: relative; */
    background: ${Color.WHITE};
    border-radius: 8px;
    box-shadow: inset -5px 5px 14px -8px rgba(0, 0, 0, 0.8);
  }
`;
