import { TransferDatabaseProduct } from "../../data/definitions/TransferDatabaseProduct";
import { DatabaseProduct } from "../../data/definitions/DatabaseProduct";
import { getProductCategoryFromString } from "./getProductCategoryFromString";
import { getProductTypeFromString } from "./getProductTypeFromString";

export function getDatabaseProduct(
  transferObject: TransferDatabaseProduct
): DatabaseProduct {
  return {
    productId: Number(transferObject.product_id), //number;
    productName: transferObject.product_name, //string;
    productCategory: getProductCategoryFromString(
      transferObject.product_category
    ), //string;
    productType: getProductTypeFromString(transferObject.product_type), //string;
    combiRebate: transferObject.combi_rebate === 1, //boolean;
    combiPush: transferObject.combi_push === 1, //boolean;
    productPriceId: transferObject.product_price_id, //number;
    productBasicPrice: transferObject.product_basic_price, //number;
    productPriceUnit: transferObject.product_price_unit, //string;
    productPriceType: transferObject.product_price_type, //number;
    productProperty01:
      Number(transferObject.product_property_01) === 0
        ? -1
        : 1000 + Number(transferObject.product_property_01), //number;
    productProperty02:
      Number(transferObject.product_property_02) === 0
        ? -1
        : 1000 + Number(transferObject.product_property_02), //number;
    productProperty03:
      Number(transferObject.product_property_03) === 0
        ? -1
        : 1000 + Number(transferObject.product_property_03), //number;
    productProperty04:
      Number(transferObject.product_property_04) === 0
        ? -1
        : 1000 + Number(transferObject.product_property_04), //number;
    productProperty05:
      Number(transferObject.product_property_05) === 0
        ? -1
        : 1000 + Number(transferObject.product_property_05), //number;
    minContractTerm: Number(transferObject.min_contract_term), //number;
    productDataVolumeIncluded: Number(transferObject.datavol_incl),
    ncRebate: transferObject.product_rebate, //number;
    ncRebateDuration: transferObject.product_rebate_duration, //0;
    productOffered: transferObject.product_offered === 1,
  };
}
