import * as React from "react";

function SvgLocation(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      data-name="ICON"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 192 192"
      {...props}
    >
      <g fill="none">
        <circle
          cx={96}
          cy={72}
          r={28}
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={8}
        />
        <path
          d="M111.26 154.08C126.71 135 148 102.54 148 74.76 148 45 126.08 20 96 20S44 44.12 44 73.87C44 115.38 96 172 96 172s11.56-13.35 15.26-17.92z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={8}
        />
        <path d="M0 0h192v192H0z" />
      </g>
    </svg>
  );
}

export default SvgLocation;
