import React from "react";
import styled from "styled-components";
import Content from "../../grid/Content";
import Headline from "../../headline/Headline";
import { SlidePage, SlidePages } from "../../../constants/SlidePages";

interface SlideProps {
  className?: string;
  slide: SlidePage;
  footerHeight: number;
  fullHeight?: boolean;
}

const SlidePageValues = Object.values(SlidePages);

const Slide: React.FC<SlideProps> = ({ className, slide }) => {
  const currentSlide: SlidePage = SlidePageValues.filter(
    (v) => v.id === slide.id
  )[0];

  return (
    <div className={className}>
      <Content>
        <Headline> {slide.title}</Headline>
        {currentSlide.page}
      </Content>
    </div>
  );
};

export default styled(Slide)`
  position: absolute;
  padding-bottom: ${({ footerHeight }) => footerHeight + "px"};

  @media (max-width: 1094px) {
    padding-bottom: ${({ slide, footerHeight }) =>
      slide.id === 105 ? "175px" : footerHeight + "px"};
  }

  width: 100%;
  height: ${({ fullHeight }) => (fullHeight ? "100%" : "unset")};
`;
