import * as React from "react";
import styled from "styled-components";
import LinkButtonInner, { LinkButtonInnerProps } from "./LinkButtonInner";
import LinkButtonContainer, {
  LinkButtonContainerProps,
} from "./LinkButtonContainer";

export interface Props extends LinkButtonInnerProps, LinkButtonContainerProps {}

const LinkButton = ({ textAlign, ...props }: Props) => {
  return (
    <LinkButtonContainer textAlign={textAlign}>
      <LinkButtonInner {...props} />
    </LinkButtonContainer>
  );
};

export default styled(LinkButton)``;
