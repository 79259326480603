import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import IconTileButton from "./IconTileButton";
import { SVGComponent } from "../../types/default";

interface BigIconTileButtonProps {
  className?: string;
  onChange: (value: boolean) => void;
  value?: boolean;
  icons?: SVGComponent[];
}

const BigIconTileButton: React.FC<BigIconTileButtonProps> = ({
  className,
  children,
  onChange,
  value = false,
  icons,
}) => {
  return (
    <IconTileButton
      className={className}
      onChange={onChange}
      value={value}
      icons={icons}
      imageSize={80}
      shadowed={true}
    >
      {children}
    </IconTileButton>
  );
};

export default styled(BigIconTileButton)`
  max-height: 150px !important;
  width: 180px !important;
  box-shadow: 2px 2px 6px 0 ${Color.GREY_DARK2} !important;
  background-color: ${({ value }) =>
    value ? Color.RED : Color.GREY2} !important;
  .icon {
    margin: 5px auto !important;
  }
  .tile-button-icons-container {
    height: 100px;
  }
  .tile-button-content-container {
    height: calc(100% - (80px * 1.25)) !important;
  }
`;
