import { useContext } from "react";
import { AppContext } from "../../state/AppContext";
import { useRefEffect } from "../common/useRefHook";
import { LoadableResources, loadConfigData } from "../../helper/loadConfigData";
import testRecommendation from "../../helper/dataTester/testRecommendation";
import useAppReload from "../useAppHelper/useAppReload";
import { checkAppStateForRecommendationLoading } from "../../helper/recommendation/checkAppStateForRecommendationLoading";
import { getRecommendationRequestData } from "../../helper/recommendation/getRecommendationRequestData";
import { getLoadingStateForRecommendationState } from "../../helper/recommendation/getLoadingStateForRecommendationState";
import { processRecommendation } from "../../helper/recommendation/processRecommendation";
import { getErrorStateForRecommendationState } from "../../helper/recommendation/getErrorStateForRecommendationState";
import { ErrorCodes } from "../../constants/ErrorCodes";
import useAuth from "../useAuth";

export const useRecommendationLoading = () => {
  const [state, setState] = useContext(AppContext);
  const [, reload] = useAppReload();
  const { logout } = useAuth();

  useRefEffect(() => {
    const isReadyForLoading = checkAppStateForRecommendationLoading(state);
    if (isReadyForLoading) {
      (async () => {
        // collect data for the request
        const recommendationRequestData = getRecommendationRequestData(state);

        setState(getLoadingStateForRecommendationState);

        let succeedRequest = true;
        const recommendationData = await loadConfigData(
          testRecommendation,
          LoadableResources.RECOMMENDATION,
          reload,
          recommendationRequestData
        )().catch((e) => {
          if (e.code && e.code === 401) {
            logout();
          }
          succeedRequest = false;
          return { products: [], advantages: [] };
        });

        if (succeedRequest) {
          setState((prevState) =>
            processRecommendation(
              prevState,
              recommendationRequestData,
              recommendationData
            )
          );
        } else {
          setState(
            getErrorStateForRecommendationState(
              ErrorCodes.falseServerRecommendation
            )
          );
        }
      })();
    }
    if (!isReadyForLoading && !state.recommendation.isLoadedOnce) {
      setState(
        getErrorStateForRecommendationState(
          ErrorCodes.notReadyForRecommendation
        )
      );
    }
  }, []);
};
