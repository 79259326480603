import { RecommendationConfig } from "../state/definitions/RecommendationState";
import { ProductLineOfferState } from "../state/definitions/ProductLineOfferState";

export function isGigaKombiWithOfferEntriesPossible(
  recommendationConfig: RecommendationConfig,
  offerEntry: ProductLineOfferState,
  direction: boolean
): boolean {
  if (direction) {
    if (
      offerEntry.additionalLabels.length === 5 ||
      offerEntry.additionalCountIds.length === 5 ||
      offerEntry.additionalPrices.length === 5 ||
      offerEntry.additionalStarts.length === 5
    ) {
      let oneRowIsFree = false;
      for (let index = 0; index < 5; index++) {
        const label = offerEntry.additionalLabels[index];
        const countId = offerEntry.additionalCountIds[index];
        const price = offerEntry.additionalPrices[index];
        const start = offerEntry.additionalStarts[index];

        const isFreeRow =
          (label === undefined ||
            label === recommendationConfig.defaultTable.additionalTableLabel) &&
          (countId === undefined ||
            countId ===
              recommendationConfig.defaultTable.additionalTableCount) &&
          (price === undefined ||
            price === recommendationConfig.defaultTable.additionalTablePrice) &&
          (start === undefined ||
            start === recommendationConfig.defaultTable.additionalTableStart);

        if (isFreeRow) {
          oneRowIsFree = true;
          index = 5;
        }
      }
      return oneRowIsFree;
    }

    return true;
  }
  return true;
}
