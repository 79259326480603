import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import { StyledComponentProps } from "../../types/default";

const PanelPrintItemValue: React.FC<StyledComponentProps> = ({
  className,
  children,
}) => <span className={className}>{children}</span>;

export default styled(PanelPrintItemValue)`
  vertical-align: middle;
  font-size: 12px;
  display: inline-block;
  float: right;
  background: ${Color.WHITE};
  padding-left: 5px;
`;
