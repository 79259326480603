import * as React from "react";

function SvgSmartHome(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="smartHome_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.smartHome_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <g id="smartHome_svg___x37_1e81df8-83ed-49d4-bf15-4bc1fe1613bc">
        <g id="smartHome_svg___x31_c26e050-1412-4dd2-9b06-fd39cd234524">
          <path
            id="smartHome_svg__fd1ba51f-c3bb-4729-bc5d-7382b4371f11"
            className="smartHome_svg__st0"
            d="M441.4 237.2h51.1l-51.1-51.4V33h-76.6v76.6l-86.3-91.4c-13.7-14.5-44-14-57.1 0l-214 219h51.1V467c0 14.1 11.4 25.5 25.5 25.5h325.8c14.1 0 31.5-14.6 31.5-28.7V237.2"
          />
          <path
            className="smartHome_svg__st0"
            d="M215.1 279.3c-21.8 21.8-21.9 57.1-.1 79l.1.1M182.8 251.1c-37.4 37.3-37.5 98-.1 135.4l.1.1"
          />
          <path
            className="smartHome_svg__st0"
            d="M150.5 222.8c-53 52.9-53.1 138.8-.2 191.8l.2.2M284.9 279.3c21.8 21.8 21.9 57.1.1 79l-.1.1M317.2 251.1c37.4 37.3 37.5 98 .1 135.4l-.1.1"
          />
          <path
            className="smartHome_svg__st0"
            d="M349.5 222.8c53 52.9 53.1 138.8.2 191.8l-.2.2"
          />
          <circle className="smartHome_svg__st0" cx={250} cy={318.5} r={11} />
          <circle className="smartHome_svg__st0" cx={250} cy={318.5} r={7.3} />
        </g>
      </g>
    </svg>
  );
}

export default SvgSmartHome;
