import * as React from "react";

function SvgUpload(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="upload_svg___x36_ab3c5c6-76c6-43a2-a827-c2552913ff3f"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.upload_svg__st1{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <g id="upload_svg___x31_18234c5-5db7-42f9-ba5b-031dc5a4bf0f">
        <path fill="none" d="M0 0h192v192H0z" />
        <path
          className="upload_svg__st1"
          d="M172 132v20c0 11-9 20-20 20H40c-11 0-20-9-20-20v-20"
          id="upload_svg__f09060fa-d247-4468-ba0a-90486d2c47dd"
        />
        <g id="upload_svg__e3e23168-9778-4769-8f22-8605c34d7d2a">
          <path className="upload_svg__st1" d="M96 148V52M122 80L96 52 70 80" />
        </g>
      </g>
    </svg>
  );
}

export default SvgUpload;
