import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import { Technology } from "../../../state/definitions/AvailabilityState";
import { TechnologyNames } from "../../../types/application";

interface AvailabilityLabelProps {
  className?: string;
  label: Technology;
}

const AvailabilityLabel: React.FC<AvailabilityLabelProps> = ({
  className,
  label,
}) => (
  <div className={className}>
    <div className={"availability-label-container"}>
      <span>{TechnologyNames[label]}</span>
    </div>
  </div>
);

export default styled(AvailabilityLabel)`
  width: 100%;
  height: 50%;
  display: inline-block;
  vertical-align: middle;

  .availability-label-container {
    width: 100%;
    height: 100%;
    background: ${Color.WHITE};
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      padding: 5px;
      width: 100%;
      color: ${Color.BLACK};
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;
