import * as React from "react";

function SvgGigaDepotGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="giga_depot_grey_svg__ICON"
      x={0}
      y={0}
      viewBox="0 0 192 192"
    >
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="8"
        d="M108 84V64c0-2.219 1.781-4 4-4h28v24h-32zM140 85.5v51c0 2.219-1.781 4-4 4h-24c-2.219 0-4-1.781-4-4v-51M83 124H56c-2.219 0-4-1.781-4-4v-16c0-2.219 1.781-4 4-4h27"
      />
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="8"
        d="M112 156h24"
      />
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="1.25"
        stroke-width="8"
        d="M149.844 171.438l-51.688.438c-7.906 0-14.312-6.844-14.312-14.75V50.438c0-7.906 6.406-14.312 14.312-14.312h66v121c0 7.905-6.406 14.312-14.312 14.312z"
      />
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="8"
        d="M64.227 140.254h15.625"
      />
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="1.25"
        stroke-width="8"
        d="M83.796 156.012l-41.584.312c-7.906 0-14.313-6.809-14.313-14.674l-.047-107.087c-.031-3.762 1.5-7.399 4.188-10.073 2.656-2.674 6.312-4.166 10.094-4.166h66v15.618"
      />
    </svg>
  );
}

export default SvgGigaDepotGrey;
