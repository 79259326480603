import { WithsObject } from "../../types/application";
import { SimpleGigaKombiValues } from "./calculateGigaKombiValues";
import { DatabaseProduct } from "../../data/definitions/DatabaseProduct";
import { DatabaseProductTypes } from "../../data/definitions/DatabaseProductTypes";
import { DISABLE_CYBER_ADVANTAGE } from "../../constants/default";

export function getGigaSpecialValues(
  withsObject: WithsObject,
  values: SimpleGigaKombiValues,
  products: DatabaseProduct[] = []
): {
  withFlatrate: boolean;
  withCyber: boolean;
} {
  let withFlatrate = false;
  let withCyber = false;

  const { mobile, iot, fixed, tv } = withsObject;
  let withsCount = 0;
  withsCount += mobile ? 1 : 0;
  // withsCount += iot ? 1 : 0;
  withsCount += fixed ? 1 : 0;
  withsCount += tv ? 1 : 0;

  if (mobile && fixed) {
    withFlatrate = true;
  }

  if (mobile && withsCount > 1) {
    const hasGigaCubeProduct =
      products.findIndex(
        (v) =>
          v.productType === DatabaseProductTypes.fixed &&
          v.productName.toLowerCase().includes("cube")
      ) >= 0;
    if (!hasGigaCubeProduct && !DISABLE_CYBER_ADVANTAGE) {
      withCyber = true;
    }
  }
  return { withFlatrate, withCyber };
}
