import React, { useState } from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import getColumnPriceObjects from "../../../helper/getColumnPriceObjects";
import { useCalcEffectivePrice } from "../../../hooks/helper/useCalcEffectivePrice";
import { LabelValuePair, MonthlyPriceArray } from "../../../types/application";

interface PriceSummaryProps {
  className?: string;
  backgroundColor?: string;
  mainLabel?: string;
  monthlyPriceArray: MonthlyPriceArray;
  oncePrice: number;
}

const PriceSummary: React.FC<PriceSummaryProps> = ({
  className,
  monthlyPriceArray,
  oncePrice,
  mainLabel,
}) => {
  const [showEffectivePrice, setShowEffectivePrice] = useState(false);
  const [effectivePrice] = useCalcEffectivePrice(monthlyPriceArray, oncePrice);
  const items: LabelValuePair[] = getColumnPriceObjects(
    monthlyPriceArray ? monthlyPriceArray : {},
    oncePrice
  );
  const mainLabelString = mainLabel ? mainLabel : items[0]?.label;

  return (
    <div
      className={className + " price-summary"}
      onClick={() => setShowEffectivePrice((v) => !v)}
    >
      {!showEffectivePrice && (
        <ul>
          <li key={-1} className={"main-label-value-item"}>
            <div className={"label-side main-list-label"}>
              {mainLabelString}
            </div>
            <div className={"value-side main-list-value"}>
              {items[0]?.value}
            </div>
          </li>
          <>
            {items.map((lvPair, i) => {
              if (i === 0) {
                return null;
              }
              return (
                <li key={i} className={"normal-label-value-item"}>
                  <div className={"label-side"} key={1}>
                    {lvPair.label}
                  </div>
                  <div className={"value-side"} key={2}>
                    {lvPair.value}
                  </div>
                </li>
              );
            })}
          </>
        </ul>
      )}
      {showEffectivePrice && (
        <ul>
          <li key={-1} className={"main-label-value-item"}>
            <div className={"label-side main-list-label effective-price-item"}>
              {"Effektivpreis"}
            </div>
            <div className={"value-side main-list-value effective-price-item"}>
              {effectivePrice.toFixed(2) + " €"}
            </div>
          </li>
        </ul>
      )}
    </div>
  );
};

export default styled(PriceSummary)`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : Color.WHITE};
  min-width: 150px;
  min-height: 60px;
  text-align: center;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-top: 5px;

  @media (min-width: 1023px) {
    li {
      margin-right: 15px;
    }
    .main-list-label {
      font-size: 20px !important;
    }
    .main-list-value {
      font-size: 20px !important;
    }
  }
  @media (max-width: 1023px) {
    li {
      margin-right: 5px;
    }
    .main-list-label {
      font-size: 20px !important;
    }
  }

  .label-side {
    text-align: right;
    display: inline-block;
    width: 70%;
    border-right: 2px ${Color.GREY_LIGHT8} solid;
    padding-right: 5px;
  }
  .value-side {
    text-align: right;
    display: inline-block;
    width: 30%;
  }
  .normal-label-value-item {
    line-height: 12px;
    .label-side {
      font-size: 12px;
    }
    .value-side {
      font-size: 12px;
    }
  }
  .main-label-value-item {
    .label-side {
      color: ${Color.RED};
      font-weight: bold;
    }
    .value-side {
      color: ${Color.RED};
      font-weight: bold;

      span {
        width: 15%;
        display: inline-block;
        font-weight: bold;
      }
    }
    .effective-price-item {
      color: ${Color.BLACK} !important;
    }
  }

  .normal-label-value-item:nth-last-of-type(1) {
    font-weight: bold;
  }
`;
