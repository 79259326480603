import useBootstrap from "../useBootstrap";
import { useFormState } from "../useForm";
import { useMemo } from "react";
import { FormOption } from "../../state/definitions/FormConfigState";

function isProviderVf(providers: FormOption[], providerId: number) {
  return (
    providers.findIndex(
      (v) => v.label.toLowerCase() === "vodafone" && providerId === v.value
    ) > -1
  );
}

export const useProviderAll = () => {
  const formConfig = useBootstrap().formConfig;

  const mobileProviders = formConfig["mobileProviders"];
  const iotProviders = formConfig["iotProviders"];
  const fixedProviders = formConfig["fixedProviders"];
  const tvProviders = formConfig["tvProviders"];

  const providerMobileIdState = useFormState("providerMobileId");
  const [providerMobileId] = providerMobileIdState;
  const providerIotIdState = useFormState("providerIotId");
  const [providerIotId] = providerIotIdState;
  const providerFixedIdState = useFormState("providerFixedId");
  const [providerFixedId] = providerFixedIdState;
  const providerTvIdState = useFormState("providerTvId");
  const [providerTvId] = providerTvIdState;

  // get "vodafone" position in given provider list
  const someIsVfProvider = useMemo(
    () =>
      isProviderVf(mobileProviders, providerMobileId) ||
      isProviderVf(iotProviders, providerIotId) ||
      isProviderVf(fixedProviders, providerFixedId) ||
      isProviderVf(tvProviders, providerTvId),
    [providerMobileId, providerIotId, providerFixedId, providerTvId]
  );

  return { someIsVfProvider };
};
