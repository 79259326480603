import * as React from "react";
import styled from "styled-components";
import { StyledComponentProps } from "../../../../types/default";

export interface Props extends StyledComponentProps {}

const PrintBottomLine = ({ className }: Props) => {
  return (
    <div className={className}>
      <p className={"print-page-bottomline"}>
        {"Diese Produktempfehlung ist nicht rechtsverbindlich. Es handelt sich um eine reine Produktempfehlung" +
          " seitens Vodafone basierend auf den vom Kunden angegebenen Informationen." +
          " Die Preise und Tarifdetails sind vorbehaltlich und können sich täglich ändern." +
          " Bei diesem Dokument handelt es sich um eine Produktempfehlung und nicht um eine Vertragszusammenfassung / Contract Summary."}
      </p>
    </div>
  );
};

export default styled(PrintBottomLine)`
  position: relative;
  width: 100%;
  height: 24px;
  bottom: 4px;
  background: white;
  overflow: hidden;

  .print-page-bottomline {
    position: absolute;
    display: inline-block;
    text-align: left;

    font-size: 12px;
    font-style: italic;
    left: 30px;
    width: calc(100% - 60px);
  }
`;
