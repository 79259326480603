import * as React from "react";

function SvgPlusColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="plus_color_svg___x36_d82e59e-323a-474d-be06-1abebb26ef51"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".plus_color_svg__st0{fill:none;stroke:#e30613;stroke-width:54.1732;stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <g id="plus_color_svg__cf6b150c-bc02-471f-8989-3b42b923bf67">
        <g id="plus_color_svg___x37_dc69a0c-916c-46ba-b2cd-88a893af269f">
          <path
            id="plus_color_svg__c33595e8-181e-4076-a9f0-227b8fdd48ef"
            className="plus_color_svg__st0"
            d="M250 7.5v485"
          />
          <path
            id="plus_color_svg___x35_6502c87-b986-44ba-a730-3fc53d646431"
            className="plus_color_svg__st0"
            d="M7.5 250h485"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgPlusColor;
