import * as React from "react";
import ErrorBoundary from "../error/ErrorBoundary";
import { Route } from "react-router-dom";

export interface RoutePageContainerProps {
  path: string;
}

const RoutePageContainer: React.FC<RoutePageContainerProps> = ({
  path,
  children,
}) => {
  return (
    <Route path={path} exact>
      <React.StrictMode>
        <ErrorBoundary path={path}>{children}</ErrorBoundary>
      </React.StrictMode>
    </Route>
  );
};

export default RoutePageContainer;
