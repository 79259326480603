import React from "react";
import styled from "styled-components";
import TextDisplay from "../TextDisplay";
import { LabelValuePair } from "../../../types/application";

interface CalculationDisplayContainerProps {
  className?: string;
  data?: LabelValuePair[];
}

const CalculationDisplayContainer: React.FC<CalculationDisplayContainerProps> = ({
  className,
  children,
  data,
}) => (
  <div className={className}>
    {!!data &&
      data.map((v, i) => (
        <TextDisplay key={i} label={v.label} value={v.value} />
      ))}
    {children}
  </div>
);

export default styled(CalculationDisplayContainer)`
  vertical-align: bottom;
  display: block;
  text-align: center;
  margin: 20px 0 10px 0;
`;
