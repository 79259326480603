export enum ZIndex {
  IMAGE_WITH_SHADOW = -1,
  NORMAL = 0,
  FRONT_1 = 1,
  FRONT_2 = 2,
  SLIDER_WHITE_CONTAINER = 1,
  SLIDER_BLACK_CONTAINER = 2,
  SLIDER_TEXT = 3,
  SLIDER_CONTENT = 4,
  SLIDER_SHADOW = 5,
  SLIDER_INPUT = 5,
  MODAL_BACKGROUND = 1000,
  MODAL = 1001,
  DEBUG_INTERFACE = 9999,
}
