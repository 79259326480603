import React, { useState } from "react";
import useBootstrap from "../../hooks/useBootstrap";
import JustifiedColumnsContainer from "../grid/JustifiedColumnsContainer";
import CheckboxListColumn from "../form/CheckboxListColumn";
import RadioButtonListColumn from "../form/RadioButtonListColumn";
import Modal from "../modal/Modal";
import { Color } from "../../constants/color";
import NewsPanelItemInner from "../elements/newsPanel/NewsPanelItemInner";
import { OuterLinks } from "../../data/definitions/OuterLinks";
import { ENABLE_RETRADE } from "../../constants/default";
import NewsPanelLink from "../elements/newsPanel/NewsPanelLink";

const MobilePreferencesPage = () => {
  const { formConfig } = useBootstrap();

  const [retradeIsOpen, setRetradeIsOpen] = useState(false);
  const thirdOptionHandler = (isSelected: boolean): boolean => {
    if (ENABLE_RETRADE) {
      setRetradeIsOpen(isSelected);
      return false;
    }
    return true;
  };

  return (
    <JustifiedColumnsContainer>
      <CheckboxListColumn
        colCount={3}
        valueKey={"mobilePreferencesSmartphone"}
        category={formConfig.mobilePreferenceCategories[0]}
        data={formConfig.mobilePreferencesSmartphone}
        checkboxExtraHandler={[undefined, undefined, thirdOptionHandler]}
      />
      <CheckboxListColumn
        colCount={3}
        valueKey={"mobilePreferencesDataVolume"}
        category={formConfig.mobilePreferenceCategories[1]}
        data={formConfig.mobilePreferencesDataVolume}
      />
      <RadioButtonListColumn
        colCount={3}
        valueKey={"mobilePreferencesPrice"}
        category={formConfig.mobilePreferenceCategories[2]}
        data={formConfig.mobilePreferencesPrice}
      />
      {ENABLE_RETRADE && (
        <Modal
          isOpen={retradeIsOpen}
          setIsOpen={setRetradeIsOpen}
          darkBackground
          headline={"Re-Trade / Inzahlungnahme"}
          modalColor={Color.RED}
          fontColor={Color.WHITE}
          withoutBodyPadding
        >
          <NewsPanelItemInner
            text={
              "Der Re-Trade Service bietet die Möglichkeit sein altes Smartphone an unseren Partner Recommerce zu verkaufen."
            }
          />
          <NewsPanelItemInner
            text={
              "Über den folgenden Link kann der maximale Restwert des Altgeräts ermittelt werden:"
            }
          />
          <NewsPanelLink link={OuterLinks.RETRADE} />
          <NewsPanelItemInner
            text={
              "Wichtig: Der finale und garantierte Ankaufspreis wird zu einem späteren Zeitpunkt auf der Basis eines digitalen Diagnoseprozesses ermittelt."
            }
          />
        </Modal>
      )}
    </JustifiedColumnsContainer>
  );
};

export default MobilePreferencesPage;
