import React from "react";
import styled from "styled-components";
import GigaCircle from "./GigaCircle";
import GigaValue from "./GigaValue";
import GigaDescription from "./GigaDescription";
import GigaText from "./GigaText";
import GigaPlus from "./GigaPlus";
import GigaUnlimitedValue from "./GigaUnlimitedValue";
import { LabelValuePair } from "../../../../types/application";

interface GigaCirclesProps {
  className?: string;
  circleData: LabelValuePair<string | JSX.Element>[];
  withStar?: boolean;
}

export const GIGA_KOMBI_CIRCLES_UNLIMITED_VALUE =
  "GIGA_KOMBI_CIRCLES_UNLIMITED_VALUE";

const GigaCircles: React.FC<GigaCirclesProps> = ({
  circleData = [],
  className,
  withStar = false,
}) => {
  return (
    <div className={className + " giga-circles"}>
      <div className={"giga-circles-container"}>
        {circleData.map((v, i) => {
          const isUnlimited = v.value === "GIGA_KOMBI_CIRCLES_UNLIMITED_VALUE";
          return (
            <div className={"giga-circle-unit"} key={i}>
              <GigaCircle withStar={i === circleData.length - 1 && withStar}>
                {v.value !== "" && isUnlimited && <GigaUnlimitedValue />}
                {v.value !== "" && !isUnlimited && (
                  <GigaValue value={v.value} />
                )}
                {v.value !== "" && <GigaDescription text={v.label} />}
                {v.value === "" && <GigaText text={v.label} />}
              </GigaCircle>
              {i + 1 < circleData.length && <GigaPlus />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default styled(GigaCircles)`
  position: relative;
  height: 100%;
  @media (max-width: 671px) {
    width: 265px;
    display: inline-block;
  }

  vertical-align: top;

  .giga-circles-container {
    display: inline-block;
    position: relative;
    padding: 5px;
    vertical-align: top;
    text-align: left;
    .giga-circle-unit {
      display: inline-block;
      vertical-align: middle;
    }
  }
`;
