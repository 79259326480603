import * as React from "react";
import { useEffect, useRef, useState } from "react";

export interface ProgressiveImageProps {
  preview: string;
  src: string;
  render: (src: string, style: {}) => JSX.Element;
  transitionTime?: number;
  timingFunction?: string;
  initialBlur?: number;
}

function getStyle(
  transitionTime: number,
  timingFunction: string,
  blur: number
) {
  return {
    filter: `blur(${blur}px)`,
    transition: `filter ${transitionTime}ms ${timingFunction}`,
  };
}

function fetch(src: string): Promise<string> {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = src;
    image.addEventListener("load", () => resolve(src), false);
  });
}

export const ProgressiveImage: React.FC<ProgressiveImageProps> = ({
  preview,
  src,
  render,
  transitionTime = 500,
  timingFunction = "ease",
  initialBlur = 10,
}) => {
  const ref = useRef<boolean>(true);
  const [currentSrc, setCurrentSrc] = useState(preview);
  const [currentBlur, setCurrentBlur] = useState(initialBlur);

  useEffect(() => {
    fetch(src).then((srcDataURI: string) => {
      if (ref.current) {
        setCurrentSrc(srcDataURI);
        setCurrentBlur(0);
      }
    });
    return () => {
      ref.current = false;
    };
  }, [src]);

  return render(
    currentSrc,
    getStyle(transitionTime, timingFunction, currentBlur)
  );
};

export default ProgressiveImage;
