import React from "react";
import useBootstrap from "../../hooks/useBootstrap";
import { useFormState } from "../../hooks/useForm";
import ImageTileCheckboxList from "../form/ImageTileCheckboxList";

const FixedCallDestinationPage = () => {
  const { formConfig } = useBootstrap();
  const [fixedCallDestination, setFixedCallDestination] = useFormState(
    "fixedCallDestination"
  );
  return (
    <ImageTileCheckboxList
      data={formConfig.fixedCallDestination}
      values={fixedCallDestination ? fixedCallDestination : []}
      onChange={(values) => setFixedCallDestination(values)}
    />
  );
};

export default FixedCallDestinationPage;
