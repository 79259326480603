import { AxiosResponse } from "axios";
import compareVersions from "compare-versions";
import { REACT_APP_VERSION } from "../App";
import axios from "../hooks/common/axios";
import { equalizeErrorMessages } from "./equalizeErrorMessages";

export enum LoadableResources {
  BOOTSTRAP = "BOOTSTRAP",
  RECOMMENDATION = "RECOMMENDATION",
  AVAILABILITY = "AVAILABILITY",
  AVAILABILITY_ADDRESS_SEARCH = "AVAILABILITY_ADDRESS_SEARCH",
  ERROR = "ERROR",
  UPDATE_CHECK = "UPDATE_CHECK",
  SUBMIT_OFFER = "SUBMIT_OFFER",
  CHECK_OFFER_STATUS = "CHECK_OFFER_STATUS",
  STAT_KITT_CLICK = "STAT_KITT_CLICK",
  LOGOUT = "LOGOUT",
}

enum HTTP_METHOD {
  GET = "GET",
  POST = "POST",
}

function getURLofLoadableResources(
  name: LoadableResources,
  params?: any[] | any
): [string | undefined, HTTP_METHOD] {
  let url: string = "";
  let method: HTTP_METHOD = HTTP_METHOD.GET;
  switch (name) {
    case LoadableResources.BOOTSTRAP:
      url = "/papi/des/bootstrap";
      method = HTTP_METHOD.GET;
      break;
    case LoadableResources.RECOMMENDATION:
      url = "/papi/des/recommendation";
      method = HTTP_METHOD.POST;
      break;
    case LoadableResources.AVAILABILITY:
      if (params && params[0] !== undefined) {
        url = "/papi/des/address/" + params[0] + "/availability";
        method = HTTP_METHOD.GET;
      }
      break;
    case LoadableResources.AVAILABILITY_ADDRESS_SEARCH:
      if (params && params[0] !== undefined) {
        url = "/papi/des/address/search?" + params[0];
        method = HTTP_METHOD.GET;
      }
      break;
    case LoadableResources.ERROR:
      url = "/papi/error";
      method = HTTP_METHOD.POST;
      break;
    case LoadableResources.UPDATE_CHECK:
      url = "/papi/update/check";
      method = HTTP_METHOD.GET;
      break;
    case LoadableResources.SUBMIT_OFFER:
      url = "/papi/des/offer";
      method = HTTP_METHOD.POST;
      break;
    case LoadableResources.CHECK_OFFER_STATUS:
      if (params && params[0] !== undefined) {
        url = "/papi/des/queue/" + params[0] + "?t=" + Date.now();
        method = HTTP_METHOD.GET;
      }
      break;
    case LoadableResources.STAT_KITT_CLICK:
      if (params && params[0] !== undefined) {
        url = "/papi/des/stats?event=click_kitt&vauoid=" + params[0];
        method = HTTP_METHOD.POST;
      }
      break;
    case LoadableResources.LOGOUT:
      url = "/api/web/auth/logout";
      method = HTTP_METHOD.POST;
      break;
  }
  if (url !== "") {
    return [url, method];
  }
  return [undefined, method];
}

function sendRequest<T>(
  url: string,
  method: HTTP_METHOD,
  params?: any
): Promise<any | AxiosResponse<T> | undefined> {
  let token = localStorage.getItem("lta");

  // Set jwt token as authorization header for all requests if found in localstorage
  let headers = {};
  if (token) {
    headers = {
      Authorization: token,
    };
  }

  switch (method) {
    case HTTP_METHOD.GET:
      return axios.get<T>(url, { headers });
    case HTTP_METHOD.POST:
      return axios.post<T>(url, params, { headers });
    default:
      return Promise.resolve(undefined);
  }
}

function evaluateResult<T>(
  checkedData: T,
  result: boolean,
  resolve: (value?: T | PromiseLike<T>) => void
) {
  if (result) {
    resolve(checkedData);
  } else {
    throw new Error(
      "Fehlerhafte Daten erhalten. Wenden Sie sich an ihren Administrator."
    );
  }
}

export const loadConfigData =
  <T>(
    getConfig: (unsureConfigObject: any) => [T, boolean],
    name: LoadableResources,
    reload: () => void,
    params?: any[] | any
  ) =>
  async () => {
    return new Promise<T>(async (resolve, reject) => {
      try {
        const [url, method] = getURLofLoadableResources(name, params);
        if (url === undefined) {
          return reject("False parameters for: " + name);
        }
        const unsureConfigObject = await sendRequest(url, method, params);
        // Check if page reload due to version mismatch is required
        // Check if the server explicitly asks for a minimum frontend version of the app
        const headerVersion = unsureConfigObject.headers.hasOwnProperty(
          "min-destwo-app-version"
        )
          ? unsureConfigObject.headers["min-destwo-app-version"]
          : false;
        const requiredVersion = headerVersion || REACT_APP_VERSION;
        if (
          requiredVersion &&
          REACT_APP_VERSION &&
          compareVersions.compare(requiredVersion, REACT_APP_VERSION, ">")
        ) {
          console.log(
            `server requesting app version ${requiredVersion} but interface is version ${REACT_APP_VERSION}. Requesting Reload...`
          );
          reload();
        }

        const [checkedConfig, result] = getConfig(
          unsureConfigObject?.data?.data
        );
        evaluateResult(checkedConfig, result, resolve);
      } catch (e) {
        // pass through unauthenticated
        if (e?.code && e.code === 401) {
          reject(e);
        }
        const err = new Error();
        err.name =
          "False data received. System has tried to receive " + name + "-data.";
        err.message = equalizeErrorMessages(
          typeof e === "object" ? e.message : e
        );
        reject(err);
      }
    });
  };
