import * as React from "react";
import JustifiedColumnsContainer from "../../grid/JustifiedColumnsContainer";
import GridColumn from "../../grid/GridColumn";
import IconTextDisplay, { IconTextDisplayProps } from "./IconTextDisplay";

export interface Props {
  extraClassName?: string;
  colCount?: number;
  items?: IconTextDisplayProps[];
}

const IconTextDisplayContainer: React.FC<Props> = ({
  extraClassName,
  colCount = 3,
  items = [],
  children,
}) => {
  return (
    <JustifiedColumnsContainer
      extraClassName={extraClassName}
      alignItems={"center"}
      height={"100%"}
      justifyContent={"center"}
    >
      {children}
      {items.map((itemProps, index) => {
        return (
          <GridColumn
            key={index + itemProps.label}
            colCount={colCount}
            minWidth={"220px"}
            maxWidth={"400px"}
          >
            <IconTextDisplay {...itemProps} />
          </GridColumn>
        );
      })}
    </JustifiedColumnsContainer>
  );
};

export default IconTextDisplayContainer;
