import * as React from "react";

function SvgCheckCircleColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="check_circle_svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      {...props}
    >
      <path
        d="M483.38 185.42c35.47 129.11-40.44 262.53-169.54 298C184.72 518.9 51.29 442.98 15.83 313.88-19.64 184.76 56.26 51.35 185.38 15.87c77.22-21.21 159.95-2.93 221.03 48.88m74.42 30.23l-256.7 256.7-114.07-114.1"
        fill="none"
        stroke="#e30613"
        strokeWidth={24.173}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgCheckCircleColor;
