import { AppState } from "../../state/definitions/AppState";
import testValuesForRecommendationRequest from "../dataTester/testValuesForRecommendationRequest";

export function checkAppStateForRecommendationLoading(
  state: AppState
): boolean {
  const anyChange =
    state.form.mobileHasChanged ||
    state.form.iotHasChanged ||
    state.form.fixedHasChanged ||
    state.form.tvHasChanged;
  const noData =
    !state.form.mobileHasData &&
    !state.form.iotHasData &&
    !state.form.fixedHasData &&
    !state.form.tvHasData;
  if (
    (anyChange || (noData && !state.recommendation.isLoadedOnce)) &&
    !state.recommendation.loading
  ) {
    try {
      // test current state for recommendation-request
      testValuesForRecommendationRequest(state);
      return true;
    } catch (e) {
      console.error(e);
    }
  }
  return false;
}
