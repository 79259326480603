import * as React from "react";

function SvgWorldNetGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#333" } = props;
  return (
    <svg
      id="worldNet_grey_svg___x39_4cf6c64-0fa2-4fb9-b847-53bafc3c269b"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.worldNet_grey_svg__st0{fill:none;stroke: ${color} ;stroke-width:14.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className="worldNet_grey_svg__st0"
        d="M404.2 62.9c103.4 85.2 118.1 238 32.9 341.4s-238 118.1-341.4 32.9-118.1-238-32.9-341.4C108.9 39.9 177.6 7.5 250 7.5c56.3 0 110.8 19.5 154.2 55.4"
      />
      <path
        className="worldNet_grey_svg__st0"
        d="M330.7 144.7c14.6 13.2 27.9 27.7 39.9 43.4 46.9 61.8 70.5 138.2 66.7 215.7M101.9 58c63 .1 124.9 17.3 179 49.6M217.7 407.7c5.1 29.3 14.1 57.8 26.6 84.7M285.5 148.2c-45.6 55.9-71.1 125.4-72.6 197.5M398 57.9c-24.7 12.6-47.9 28-69 45.9M337.6 123.9c45.5 0 90.7 8.6 133 25.3M119.4 206.6c44.3-39.2 98.3-65.7 156.3-76.8M26.3 343.7c12.1-33 29.2-63.9 50.6-91.7M239.6 394.2c48.1 28.4 102.3 44.8 158.1 47.9M110 256.4c19.8 38.5 46.8 72.9 79.4 101.3M69 88.6c.3 37.4 6.2 74.6 17.3 110.3"
      />
      <circle className="worldNet_grey_svg__st0" cx={97} cy={228.3} r={31.1} />
      <circle
        className="worldNet_grey_svg__st0"
        cx={213.8}
        cy={376.9}
        r={31.1}
      />
      <circle
        className="worldNet_grey_svg__st0"
        cx={306.5}
        cy={125.3}
        r={31.1}
      />
    </svg>
  );
}

export default SvgWorldNetGrey;
