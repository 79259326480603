import * as React from "react";
import PriceSummaryPrint from "../../PriceSummaryPrint";
import { Color } from "../../../../../constants/color";
import { PriceTextTile } from "../../PriceTextTile";
import PrintCurrentPriceDisplay from "../../PrintCurrentPriceDisplay";

export interface Props {
  currentPrice: number;
}

const CurrentRecommendationPrintColumn = ({ currentPrice }: Props) => {
  return (
    <>
      <PriceTextTile>
        <PrintCurrentPriceDisplay
          data={{ mainPrice: currentPrice, headline: "monatlich", subline: [] }}
        />
      </PriceTextTile>
      <PriceSummaryPrint
        mainLabel={"Dein Preis pro Monat:"}
        mainValue={currentPrice.toFixed(2) + " €"}
        accentColor={Color.BLACK}
      />
    </>
  );
};

export default CurrentRecommendationPrintColumn;
