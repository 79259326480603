import * as React from "react";
import {
  getEmptyStringIfNotExist,
  getZeroIfNotExist,
} from "../../../helper/ifNotExist";
import { isSameAdditionalTableObject } from "../../../helper/table/isSameAdditionalTableObject";
import {
  AdditionalSubProduct,
  UnknownTableDataObject,
} from "../../../types/table";
import styled from "styled-components";
import { Color } from "../../../constants/color";

export interface AFODeleteCellProps {
  className?: string;
  dataset: UnknownTableDataObject;
  dataId: number;
  additionalDataObject?: AdditionalSubProduct;
  defaultAdditionalDataObject?: UnknownTableDataObject;
  changeLabelValue: (newValue: string, objectId: number) => void;
  changeStartValue: (newValue: number, objectId: number) => void;
  changeCountId: (newCountId: number, objectId: number) => void;
  changePriceValue: (newValue: number, objectId: number) => void;
}

const AFODeleteCell: React.FC<AFODeleteCellProps> = ({
  className,
  dataset,
  dataId,
  additionalDataObject,
  defaultAdditionalDataObject,
  changeLabelValue,
  changeStartValue,
  changeCountId,
  changePriceValue,
}) => {
  if (
    additionalDataObject !== undefined &&
    defaultAdditionalDataObject !== undefined
  ) {
    let isStandard = true;
    const isNotGigaKombi = additionalDataObject?.gigaKombiId !== dataId;
    const isNotRegioDiscount = additionalDataObject?.regioDiscountId !== dataId;
    if (isNotGigaKombi && isNotRegioDiscount) {
      isStandard = isSameAdditionalTableObject(
        dataset,
        defaultAdditionalDataObject
      );
    }

    const onClick = () => {
      const newLabel = getEmptyStringIfNotExist(
        defaultAdditionalDataObject?.labels[0]
      );
      changeLabelValue(newLabel, dataId);

      if (defaultAdditionalDataObject?.starts) {
        const newStartValue = getZeroIfNotExist(
          defaultAdditionalDataObject?.starts[0]
        );
        changeStartValue(newStartValue, dataId);
      }

      const newCountId = getZeroIfNotExist(
        defaultAdditionalDataObject?.countId
      );
      changeCountId(newCountId, dataId);

      const newPrice = getZeroIfNotExist(
        defaultAdditionalDataObject?.prices[0]
      );
      changePriceValue(newPrice, dataId);
    };

    if (isStandard) {
      return (
        <td className={`cell cross-delete-row-placeholder ${className}`}>X</td>
      );
    }
    return (
      <td className={`cell cross-delete-row ${className}`} onClick={onClick}>
        X
      </td>
    );
  }
  return null;
};

export default styled(AFODeleteCell)`
  width: 5% !important;

  &.cross-delete-row {
    font-weight: bold;
    background: ${Color.RED};
    color: ${Color.WHITE};
    cursor: pointer;
  }
  &.cross-delete-row-placeholder {
    background: ${Color.GREY_LIGHT};
    color: ${Color.BLACK};
    cursor: not-allowed;
  }
`;
