import InitialInterestsPage from "../components/pages/InitialInterestsPage";
import AvailabilityCheckPage from "../components/pages/AvailabilityCheckPage";
import KombiPage from "../components/pages/KombiPage";
import ProfilePage from "../components/pages/ProfilePage";
import OfferPage from "../components/pages/OfferPage";
import ChangeGuaranteePage from "../components/pages/ChangeGuaranteePage";
import MobileCurrentConditionsPage from "../components/pages/MobileCurrentConditionsPage";
import MobilePreferencesPage from "../components/pages/MobilePreferencesPage";
import MobileDataVolumePage from "../components/pages/MobileDataVolumePage";
import MobileDevicesPage from "../components/pages/MobileDevicesPage";
import MobileContractOptimizePage from "../components/pages/MobileContractOptimizePage";
import MobilePersonalDetailPage from "../components/pages/MobilePersonalDetailPage";
import IoTPreferencesPage from "../components/pages/IoTPreferencesPage";
import IoTDevicePage from "../components/pages/IoTDevicePage";
import FixedPreferencesPage from "../components/pages/FixedPreferencesPage";
import FixedDevicesPage from "../components/pages/FixedDevicesPage";
import FixedCallDestinationPage from "../components/pages/FixedCallDestinationPage";
import FixedCurrentConditionsPage from "../components/pages/FixedCurrentConditionsPage";
import TVTechnologyPage from "../components/pages/TVTechnologyPage";
import TVPreferencesPage from "../components/pages/TVPreferencesPage";
import TVInvoicePage from "../components/pages/TVInvoicePage";
import React from "react";
import RecommendationPage from "../components/pages/RecommendationPage";
import MoreRecommendationPage from "../components/pages/MoreRecommendationPage";
import SummaryRecommendationPage from "../components/pages/SummaryRecommendationPage";
import TVCurrentConditionsPage from "../components/pages/TVCurrentConditionsPage";
import SohoPage from "../components/pages/SohoPage";

export enum SlideCategoryTypes {
  start = "start",
  availability = "availability",
  mobile = "mobile",
  iot = "iot",
  fixed = "fixed",
  tv = "tv",
  switch = "switch",
  profile = "profile",
  recommendation = "recommendation",
  offer = "offer",
  help = "help",
}

export interface SlidePage {
  id: number;
  page: JSX.Element;
  title: string;
  category: SlideCategoryTypes;
}

interface ConstSlidePagesInterface {
  initialInterest: SlidePage;
  soho: SlidePage;
  availabilityCheck: SlidePage;
  kombi: SlidePage;
  profile: SlidePage;
  recommendation: SlidePage;
  moreRecommendation: SlidePage;
  summaryRecommendation: SlidePage;
  offer: SlidePage;
  changeGuarantee: SlidePage;
}

interface VariableSlidePagesInterface {
  mobileCurrentConditions: SlidePage;
  mobilePreferences: SlidePage;
  mobileDataVolume: SlidePage;
  mobileDevices: SlidePage;
  mobileContractOptimize: SlidePage;
  mobilePersonalDetail: SlidePage;
  iotPreferences: SlidePage;
  iotDevices: SlidePage;
  fixedPreferences: SlidePage;
  fixedDevices: SlidePage;
  fixedCallDestination: SlidePage;
  fixedCurrentConditions: SlidePage;
  tvTechnology: SlidePage;
  tvPreferences: SlidePage;
  tvInvoice: SlidePage;
  tvCurrentConditions: SlidePage;
}

export interface SlidePagesInterface
  extends ConstSlidePagesInterface,
    VariableSlidePagesInterface {}

/**
 * 000 - 099 constant pages
 * 100 - 149 mobile pages
 * 150 - 199 fixed pages
 *       153 is disabled
 * 200 - 249 tv pages
 * 300 - 349 iot pages
 **/

export const ConstSlidePages: ConstSlidePagesInterface = {
  initialInterest: {
    id: 0,
    page: <InitialInterestsPage />,
    title: "Welches Produkt interessiert Dich?",
    category: SlideCategoryTypes.start,
  },
  soho: {
    id: 9,
    page: <SohoPage />,
    title: "Wie nutzt Du Deinen Vertrag?",
    category: SlideCategoryTypes.start,
  },
  availabilityCheck: {
    id: 10,
    page: <AvailabilityCheckPage />,
    title: "Wir testen Dein GigaZuhause und Deine TV-Verfügbarkeit",
    category: SlideCategoryTypes.availability,
  },
  kombi: {
    id: 20,
    page: <KombiPage />,
    title: "Kombiniere und spare mit den Vorteilen unserer GigaKombi",
    category: SlideCategoryTypes.switch,
  },
  profile: {
    id: 30,
    page: <ProfilePage />,
    title: "Dein Profil:",
    category: SlideCategoryTypes.profile,
  },
  recommendation: {
    id: 40,
    page: <RecommendationPage />,
    title: "Unsere Empfehlung für Dich",
    category: SlideCategoryTypes.recommendation,
  },
  moreRecommendation: {
    id: 41,
    page: <MoreRecommendationPage />,
    title: "Weitere Produkte für Dich",
    category: SlideCategoryTypes.recommendation,
  },
  summaryRecommendation: {
    id: 42,
    page: <SummaryRecommendationPage />,
    title: "Dein Warenkorb",
    category: SlideCategoryTypes.recommendation,
  },
  offer: {
    id: 50,
    page: <OfferPage />,
    title: "Deine Empfehlung",
    category: SlideCategoryTypes.offer,
  },
  changeGuarantee: {
    id: -10,
    page: <ChangeGuaranteePage />,
    title:
      "Dein Wechselservice: Wir schenken Dir den Basispreis, solange Dein alter Vertrag noch läuft",
    category: SlideCategoryTypes.help,
  },
};
export const ConstSlidePagesValues = Object.values(ConstSlidePages);

const VariableSlidePages: VariableSlidePagesInterface = {
  mobileCurrentConditions: {
    id: 100,
    page: <MobileCurrentConditionsPage />,
    title: "Was sind Deine aktuellen Leistungen?",
    category: SlideCategoryTypes.mobile,
  },
  mobilePreferences: {
    id: 101,
    page: <MobilePreferencesPage />,
    title: "Welche Dinge sind Dir wichtig?",
    category: SlideCategoryTypes.mobile,
  },
  mobileDataVolume: {
    id: 102,
    page: <MobileDataVolumePage />,
    title: "Wieviel Datenvolumen brauchst Du?\nUnd wofür nutzt Du es?",
    category: SlideCategoryTypes.mobile,
  },
  mobileDevices: {
    id: 103,
    page: <MobileDevicesPage />,
    title: "Welche Geräte benutzt Du unterwegs?",
    category: SlideCategoryTypes.mobile,
  },
  mobileContractOptimize: {
    id: 104,
    page: <MobileContractOptimizePage />,
    title:
      "Wie möchtest Du die Verträge in Deinem Haushalt oder die Deiner Liebsten optimieren?",
    category: SlideCategoryTypes.mobile,
  },
  mobilePersonalDetail: {
    id: 105,
    page: <MobilePersonalDetailPage />,
    title: "Bist Du …?",
    category: SlideCategoryTypes.mobile,
  },
  fixedCurrentConditions: {
    id: 150,
    page: <FixedCurrentConditionsPage />,
    title: "Was sind Deine aktuellen Leistungen?",
    category: SlideCategoryTypes.fixed,
  },
  fixedPreferences: {
    id: 151,
    page: <FixedPreferencesPage />,
    title: "Welche Dinge sind Dir wichtig?",
    category: SlideCategoryTypes.fixed,
  },
  fixedDevices: {
    id: 152,
    page: <FixedDevicesPage />,
    title: "Welche Geräte hast Du in Deinem Haushalt und wieviele?",
    category: SlideCategoryTypes.fixed,
  },
  fixedCallDestination: {
    id: 153,
    page: <FixedCallDestinationPage />,
    title: "In welche Länder telefonierst Du häufig?",
    category: SlideCategoryTypes.fixed,
  },
  tvCurrentConditions: {
    id: 200,
    page: <TVCurrentConditionsPage />,
    title: "Was sind Deine aktuellen Leistungen?",
    category: SlideCategoryTypes.tv,
  },
  tvTechnology: {
    id: 201,
    page: <TVTechnologyPage />,
    title: "Wie empfängst Du TV heute?",
    category: SlideCategoryTypes.tv,
  },
  tvPreferences: {
    id: 202,
    page: <TVPreferencesPage />,
    title: "Wie nutzt Du TV und was ist Dir wichtig?",
    category: SlideCategoryTypes.tv,
  },
  tvInvoice: {
    id: 203,
    page: <TVInvoicePage />,
    title: "Wie wird Dein Kabel-Anschluss abgerechnet?",
    category: SlideCategoryTypes.tv,
  },

  iotPreferences: {
    id: 300,
    page: <IoTPreferencesPage />,
    title: "Welche Smartprodukte verwendest Du?",
    category: SlideCategoryTypes.iot,
  },
  iotDevices: {
    id: 301,
    page: <IoTDevicePage />,
    title: "Für Deine Smartprodukte",
    category: SlideCategoryTypes.iot,
  },
};

export const FIRST_MOBILE_PAGE = VariableSlidePages.mobileCurrentConditions;
export const FIRST_FIXED_PAGE = VariableSlidePages.fixedCurrentConditions;
export const FIRST_TV_PAGE = VariableSlidePages.tvCurrentConditions;
export const FIRST_IOT_PAGE = VariableSlidePages.iotPreferences;

export const VariableSlidePagesValues = Object.values(VariableSlidePages);

export const SlidePages: SlidePagesInterface = {
  ...ConstSlidePages,
  ...VariableSlidePages,
};
