import { CountUnits, PriceUnits, UnknownTableDataObject } from "../types/table";
import { MONTH_MAX_LENGTH, MONTH_MIN_LENGTH } from "../constants/default";

export type SimpleSubProductData = [
  string,
  number,
  number,
  number,
  CountUnits,
  PriceUnits
];

export function isSameSimpleData(
  d1: SimpleSubProductData,
  d2: SimpleSubProductData
): boolean {
  return (
    d1[0] === d2[0] &&
    d1[1] === d2[1] &&
    d1[2] === d2[2] &&
    d1[3] === d2[3] &&
    d1[4] === d2[4] &&
    d1[5] === d2[5]
  );
}

export function getSubProductSimpleData(
  d: UnknownTableDataObject
): SimpleSubProductData {
  let label: string = "";
  let start: number = 0;
  let count: number = 0;
  let price: number = 0;
  const priceId = d.priceId === undefined ? d.labelId : d.priceId;

  if (
    d.labels !== undefined &&
    d.labelId !== undefined &&
    d.labels[d.labelId] !== undefined
  ) {
    label = d.labels[d.labelId];
  }
  if (
    d.starts !== undefined &&
    d.startId !== undefined &&
    d.starts[d.startId] !== undefined
  ) {
    start = d.starts[d.startId];
  }
  if (
    d.counts !== undefined &&
    d.countId !== undefined &&
    d.counts[d.countId] !== undefined
  ) {
    count = d.counts[d.countId];
  }
  if (
    d.prices !== undefined &&
    priceId !== undefined &&
    d.prices[priceId] !== undefined
  ) {
    if (
      d.counts !== undefined &&
      d.countId !== undefined &&
      d.counts[d.countId] === undefined
    ) {
      price = d.prices[priceId];
      // Calculation for use count in price
      //       price =
      //         d.countUnit === CountUnits.normal
      //           ? d.prices[priceId] * count
      //           : d.prices[priceId];
    }
    if (
      d.counts !== undefined &&
      d.countId !== undefined &&
      d.counts[d.countId] !== 0
    ) {
      price = d.prices[priceId];
    }
  }
  return [label, start, count, price, d.countUnit, d.priceUnit];
}

export function getFormattedSimpleSubProductData(d: SimpleSubProductData) {
  const [label, start, count, price, countUnit, priceType] = d;

  let priceString = "0";
  const priceTypeString = priceType ? priceType : "";
  if (price !== undefined && price !== null && price !== 0) {
    priceString = price.toFixed(2);
  }

  let countString = "";
  if (!!label && label.toLowerCase().includes("gigakombi")) {
    return {
      labelString: label,
      countString: countString,
      priceString: priceString + " " + priceTypeString,
    };
  }

  if (count > MONTH_MIN_LENGTH && count < MONTH_MAX_LENGTH) {
    const fixedCount = count.toFixed(0);
    const countUnitString = countUnit ? countUnit : "";
    countString = ` (${fixedCount} ${countUnitString})`;
  }
  if (countUnit === CountUnits.months && start > 1) {
    const startMonth = start.toFixed(0);
    const end = start + count;
    if (end < MONTH_MAX_LENGTH + 1) {
      const endMonth = end.toFixed(0);
      countString = ` (${startMonth}. - ${endMonth}. Monat)`;
    }
    if (start < MONTH_MAX_LENGTH + 1 && end > MONTH_MAX_LENGTH) {
      countString = ` (Ab dem ${startMonth}. Monat)`;
    }
    if (start > MONTH_MAX_LENGTH && end > MONTH_MAX_LENGTH) {
      countString = "";
    }
  }

  return {
    labelString: label,
    countString: countString,
    priceString: priceString + " " + priceTypeString,
  };
}
