import { useEffect, useState } from "react";
import { MonthlyPriceArray } from "../../types/application";
import getEffectivePrice from "../../helper/getEffectivePrice";

export const useCalcEffectivePrice = (
  priceArray: MonthlyPriceArray,
  oncePrice: number
): [number] => {
  const [result, setResult] = useState(0);

  useEffect(() => {
    setResult(getEffectivePrice(priceArray, oncePrice));
  }, [priceArray, oncePrice, setResult]);

  return [result];
};
