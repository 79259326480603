export function dateToString(value: Date) {
  const date = [];
  date.push(value.getFullYear());
  date.push(value.getMonth() + 1);
  date.push(value.getDate());
  date.push(value.getHours());
  date.push(value.getMinutes());
  date.push(value.getSeconds());
  return date.join("-");
}

export function stringToDate(value: string) {
  const date = new Date();
  const valueArray = value.split("-");
  if (valueArray.length === 6) {
    date.setFullYear(valueArray[0] !== undefined ? Number(valueArray[0]) : 0);
    date.setMonth(valueArray[1] !== undefined ? Number(valueArray[1]) - 1 : 0);
    date.setDate(valueArray[2] !== undefined ? Number(valueArray[2]) : 0);
    date.setHours(valueArray[3] !== undefined ? Number(valueArray[3]) : 0);
    date.setMinutes(valueArray[4] !== undefined ? Number(valueArray[4]) : 0);
    date.setSeconds(valueArray[5] !== undefined ? Number(valueArray[5]) : 0);
    date.setMilliseconds(0);
  }
  return date;
}
