import React from "react";
import styled from "styled-components";
import CheckboxList from "./CheckboxList";
import { getIcons } from "../../icons/Icons";
import BigIconTileButton from "./BigIconTileButton";
import { FormOption } from "../../state/definitions/FormConfigState";

interface BigIconTileButtonGridProps {
  className?: string;
  maxWidth?: number;
  data?: FormOption[];
  values?: number[];
  onChange?: (values: number[]) => void;
}

const BigIconTileButtonGrid: React.FC<BigIconTileButtonGridProps> = ({
  className,
  data = [],
  values,
  onChange,
}) => {
  return (
    <div className={className}>
      <CheckboxList
        data={data}
        values={values}
        onChange={onChange ? onChange : () => {}}
        renderFkt={({ value, label }, isSelected, toggleValue, i) => {
          return (
            <BigIconTileButton
              onChange={() => toggleValue(value)}
              value={isSelected}
              icons={getIcons(value)}
              key={i}
            >
              {label}
            </BigIconTileButton>
          );
        }}
      />
    </div>
  );
};

export default styled(BigIconTileButtonGrid)`
  display: block;
  text-align: center;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth + "px" : "unset")};
  margin: 20px auto;
`;
