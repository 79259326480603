import * as React from "react";

function SvgSecurityPc(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="securityPc_svg__ICON"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.securityPc_svg__st0,.securityPc_svg__st1{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round}.securityPc_svg__st0{stroke-linejoin:round}.securityPc_svg__st1{stroke-miterlimit:10}`}
      </style>
      <path
        className="securityPc_svg__st0"
        d="M77 116h67.6c6.3 0 11.4-5.4 11.4-12V48c0-6.6-5.1-12-11.4-12H47.4C41.1 36 36 41.4 36 48v46"
      />
      <path
        id="securityPc_svg__b493255f-edd2-469b-8f82-6d8f944da046_1_"
        className="securityPc_svg__st1"
        d="M92 136h24.1"
      />
      <path
        id="securityPc_svg__b493255f-edd2-469b-8f82-6d8f944da046_2_"
        className="securityPc_svg__st1"
        d="M62 156h110"
      />
      <path
        id="securityPc_svg__b493255f-edd2-469b-8f82-6d8f944da046_3_"
        className="securityPc_svg__st1"
        d="M152 118l20 38"
      />
      <path
        id="securityPc_svg___x37_49cf875-4c70-4a87-987c-5c67ab21b6e4"
        className="securityPc_svg__st0"
        d="M48 93l-28 6.3v36.1c0 3.2 2.3 6.7 5 10.3 2.5 3.2 5.9 6.5 10.3 9.8 5.5 4.1 10 7.1 12.7 8.5 2.7-1.4 7.2-4.4 12.7-8.5 4.4-3.3 7.9-6.6 10.3-9.8 2.8-3.6 5-7.1 5-10.3V99.3L48 93z"
      />
      <path className="securityPc_svg__st1" d="M48 120v16" />
      <circle className="securityPc_svg__st0" cx={48} cy={120} r={4} />
      <circle className="securityPc_svg__st0" cx={48} cy={120} r={2} />
    </svg>
  );
}

export default SvgSecurityPc;
