import { Interest } from "../../types/application";
import { AdditionalTableDataObject, SubProducts } from "../../types/table";
import { getDefaultSubProducts } from "../../state/defaults/offer/defaultSubProducts";
import { getPassesData } from "../offer/getPassesData";
import useGigaKombi from "../../hooks/useOfferHelper/useGigaKombi";
import { mapGigaKombiAdvantages } from "../gigakombi/mapGigaKombiAdvantages";
import { addSpecialAdditionalTableObject } from "../offer/addSpecialAdditionalTableObject";
import { useRegioDiscount } from "../../hooks/useRecommendationHelper/useRegioDiscount";
import { prepareColumnPrices } from "../priceCalculation/default";
import { useOfferTable } from "../../hooks/useOfferHelper/useOfferTable";
import { RecommendationConfig } from "../../state/definitions/RecommendationState";

export interface GetSubProductProps {
  config: RecommendationConfig;
  offerTableFunctions: ReturnType<typeof useOfferTable>;
  regioDiscountObject: ReturnType<typeof useRegioDiscount>;
  gigaKombiObject: ReturnType<typeof useGigaKombi>;
  type: Interest;
  gigaKombiIsEnable: boolean;
  isSelectedType: boolean;
  passesSelected: number[];
}

export const getSubProducts = ({
  config,
  offerTableFunctions,
  regioDiscountObject,
  gigaKombiObject,
  type,
  gigaKombiIsEnable,
  isSelectedType,
  passesSelected,
}: GetSubProductProps): SubProducts => {
  const {
    getAdditionalTableObject,
    getFurtherTableObject,
    getSinglePaymentTableObject,
  } = offerTableFunctions;

  const subProducts: SubProducts = getDefaultSubProducts(offerTableFunctions, {
    config,
    type,
  });
  subProducts.passes.data = getPassesData(type, config, passesSelected);

  /**
   * Add additional table entries
   */
  const additionalTableData: AdditionalTableDataObject[] = [];
  for (let i = 0; i < 5; i++) {
    additionalTableData.push(getAdditionalTableObject(i));
  }
  subProducts.additionalTable.data = additionalTableData;

  const { gigaKombiType, gigaKombiAdditionalObject, gigaAdvantages } =
    gigaKombiObject;

  if (gigaKombiIsEnable) {
    subProducts.advantages = mapGigaKombiAdvantages(
      subProducts.advantages,
      gigaAdvantages,
      type,
      gigaKombiType
    );
  }

  // // ADD GIGAKOMBI to additionalTable
  let gigaKombiIsContain = false;
  for (let i = 0; i < 5; i++) {
    if (type === gigaKombiType && gigaKombiIsEnable && !gigaKombiIsContain) {
      subProducts.additionalTable.additionalData.gigaKombiId =
        addSpecialAdditionalTableObject(
          subProducts,
          subProducts.additionalTable.standardObject,
          gigaKombiAdditionalObject,
          i
        );
      gigaKombiIsContain =
        subProducts.additionalTable.additionalData.gigaKombiId >= 0;
    }
  }

  const { regioDiscountAllowed, regioDiscountAdditionalObject } =
    regioDiscountObject;
  // ADD REGIODISCOUNT to additionalTable
  if (regioDiscountAllowed) {
    let regioDiscountIsContain = false;
    for (let i = 0; i < 5; i++) {
      if (
        !regioDiscountIsContain &&
        subProducts.additionalTable.additionalData.gigaKombiId !== i
      ) {
        subProducts.additionalTable.additionalData.regioDiscountId =
          addSpecialAdditionalTableObject(
            subProducts,
            subProducts.additionalTable.standardObject,
            regioDiscountAdditionalObject,
            i
          );
        regioDiscountIsContain =
          subProducts.additionalTable.additionalData.regioDiscountId >= 0;
      }
    }
  }

  for (let i = 0; i < 5; i++) {
    subProducts.furtherProductsTable.data.push(getFurtherTableObject(i));
  }

  for (let i = 0; i < 5; i++) {
    subProducts.singlePaymentTable.data.push(getSinglePaymentTableObject(i));
  }

  if (isSelectedType) {
    [
      subProducts.priceInformation.monthly,
      subProducts.priceInformation.list,
      subProducts.priceInformation.once,
    ] = prepareColumnPrices(
      [
        subProducts.additionalTable.data,
        subProducts.furtherProductsTable.data,
        subProducts.singlePaymentTable.data,
      ],
      subProducts.passes.data
    );
  }

  return subProducts;
};
