import * as React from "react";

function SvgDvbt2(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="dvbt2_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.dvbt2_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className="dvbt2_svg__st0"
        d="M249.6 403.13v31.91m-114.86 6.38h229.74M32.63 58.52h433.95c14.1 0 25.53 11.43 25.53 25.53v280.79c0 14.1-11.43 25.53-25.53 25.53H32.63c-14.1 0-25.53-11.43-25.53-25.53V84.05c0-14.1 11.43-25.53 25.53-25.53z"
      />
      <path
        className="dvbt2_svg__st0"
        d="M314.54 140.22c34.61 34.55 34.65 90.61.1 125.22-.03.03-.06.06-.1.09m-21.07-106.88c24.43 24.37 24.48 63.92.11 88.35-.04.04-.07.08-.11.11m-21.07-70.03c14.25 14.22 14.27 37.3.05 51.54a.12.12 0 01-.05.05m-87.73-88.45c-34.61 34.55-34.65 90.61-.1 125.22.03.03.06.06.1.09m21.07-106.88c-24.43 24.37-24.48 63.92-.11 88.35l.11.11m21.07-70.03c-14.25 14.22-14.27 37.3-.05 51.54l.05.05m-10.82 91.25l33.61-114.87 33.61 114.87-52.57-50.1m52.58 50.1L249.6 205.05l-33.61 114.87 52.55-50.14"
      />
    </svg>
  );
}

export default SvgDvbt2;
