import * as React from "react";

function SVGHookCircle(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="hook_circle_svg"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
    >
      <style>
        {`.hook_circle_svg-st0{fill:none;stroke:${color};stroke-width:44.1732;stroke-linecap:round;stroke-linejoin:round;}`}
        {`.hook_circle_svg-st1{fill:#e30613;stroke:${color};stroke-width:44.1732;stroke-linecap:round;stroke-linejoin:round;}`}
      </style>

      <g>
        <circle className="hook_circle_svg-st1" cx="250" cy="250" r="225" />
        <line
          className="hook_circle_svg-st0"
          x1="120"
          y1="300"
          x2="220"
          y2="410"
        />
        <line
          className="hook_circle_svg-st0"
          x1="220"
          y1="410"
          x2="380"
          y2="150"
        />
      </g>
    </svg>
  );
}

export default SVGHookCircle;
