import * as React from "react";

function SvgPrint(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      version="1.1"
      id="print_svg___x33_c54d754-f349-44b2-8061-d7aa617e1d45"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style type="text/css">
        {`.print-st1{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round;}`}
        {`.print-st2{fill:none;stroke: ${color} ;stroke-width:8;stroke-linejoin:round;}`}
      </style>
      <g>
        <path className="print-st1" d="M124,124H68 M124,148H68" />
        <path
          className="print-st1"
          d="M46.5,132H32c-6.6,0-12-5.4-12-12V64c0-6.6,5.4-12,12-12h128c6.6,0,12,5.4,12,12v56c0,6.6-5.4,12-12,12h-15.5"
        />
        <path
          className="print-st2"
          d="M52,51.7V33.3c0-7.7,6.3-14,14-14h60c7.7,0,14,6.3,14,14v18.3"
        />
        <path className="print-st1" d="M126,172H52v-72h88v58L126,172z" />
      </g>
    </svg>
  );
}

export default SvgPrint;
