import {
  MonthlyPriceArray,
  PriceDisplayInformation,
} from "../../types/application";

const useProductPrice = (
  productPrice?: MonthlyPriceArray
): [PriceDisplayInformation] => {
  let productPriceMonths: string[] = [];
  let productPriceValues: number[] = [];
  let productMainPrice: number = 0;
  if (!!productPrice) {
    productPriceMonths = Object.keys(productPrice).slice(1);
    productPriceValues = Object.values(productPrice).slice(1);
    productMainPrice = productPrice[0] ? productPrice[0] : 0;
  }
  let productPriceData: PriceDisplayInformation = {
    headline: "monatlich",
    mainPrice: productMainPrice,
    subline: productPriceValues.map((v, i) => {
      return (
        "Ab dem " +
        (Number(productPriceMonths[i]) + 1) +
        ". Monat " +
        v.toFixed(2) +
        " €"
      );
    }),
  };
  return [productPriceData];
};

export default useProductPrice;
