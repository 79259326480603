import React from "react";
import styled from "styled-components";
import IButton from "../form/IconButton";
import Modal from "../modal/Modal";
import LongTextDisplay from "../elements/guaranteePage/LongTextDisplay";
import JustifiedColumnsContainer from "../grid/JustifiedColumnsContainer";
import GridColumn from "../grid/GridColumn";
import GuaranteeContent from "../elements/guaranteePage/GuaranteeContent";
import { useNavigation } from "../../hooks/useNavigation";
import PaginationButton from "../pagination/PaginationButton";
import { Color } from "../../constants/color";
import { StyledComponentProps } from "../../types/default";

const ChangeGuaranteePage: React.FC<StyledComponentProps> = ({ className }) => {
  const { setIsChangeGuaranteeSlideShowing } = useNavigation();
  const openGuaranteePage = () => {
    setIsChangeGuaranteeSlideShowing(false);
  };

  return (
    <div className={className}>
      <GuaranteeContent />
      <Modal
        modalColor={Color.WHITE}
        fontColor={Color.RED}
        openButton={(onChange, value) => (
          <div className={"i-button-container"}>
            <IButton
              onChange={onChange}
              value={value}
              verticalAlign={"middle"}
            />
            <div className={"i-button-container-element-placeholder"} />
            <PaginationButton
              onClick={openGuaranteePage}
              verticalAlign={"middle"}
              direction={"next"}
              selected
            />
          </div>
        )}
      >
        <div className={"long-text-displays-container"}>
          <JustifiedColumnsContainer>
            <GridColumn colCount={2} minWidth={"350px"} maxWidth={"450px"}>
              <LongTextDisplay
                minHeight={"150px"}
                label={"12 Monate Restlaufzeitbefreiung"}
                value={
                  "Spar bis zu 12 Monate lang den \n" +
                  "Basispreis für Deinen neuen Kabel-\n" +
                  "Internet-Anschluss – solange Du den \n" +
                  "bisherigen Anschluss noch nutzt."
                }
              />
            </GridColumn>
            <GridColumn colCount={2} minWidth={"350px"} maxWidth={"450px"}>
              <LongTextDisplay
                minHeight={"150px"}
                label={"Wechselsevice"}
                value={
                  "Deine DSL-Kündigung wird erst weitergeleitet, \n" +
                  "wenn Dein Kabel-Internet-\n" +
                  "Anschluss funktioniert – ohne Zusatzkosten."
                }
              />
            </GridColumn>
            <GridColumn colCount={2} minWidth={"350px"} maxWidth={"450px"}>
              <LongTextDisplay
                minHeight={"150px"}
                label={"Kündigungsservice"}
                value={
                  "Wir leiten Deine Kündigung des DSL-Vertrags \n" +
                  "bei Rufnummernmitnahme an Deinen \n" +
                  "bisherigen Anbieter für Dich weiter. Auch \n" +
                  "danach sind wir für Dich da mit kostenlosem \n" +
                  "Installations-Service, Techniker-Hotline, \n" +
                  "Ersatz- und Reparatur-Service."
                }
              />
            </GridColumn>
            <GridColumn colCount={2} minWidth={"350px"} maxWidth={"450px"}>
              <LongTextDisplay
                minHeight={"150px"}
                label={"Rufnummern-Mitnahme"}
                value={
                  "Wir sorgen dafür, dass Du Deine bisherige \n" +
                  "Rufnummer gratis mitnehmen kannst."
                }
              />
            </GridColumn>
          </JustifiedColumnsContainer>
        </div>
      </Modal>
    </div>
  );
};

export default styled(ChangeGuaranteePage)`
  .i-button-container {
    float: right;
    margin-right: 20px;
  }
  .i-button-container-element-placeholder {
    display: inline-block;
    width: 30px;
    vertical-align: middle;
  }
  .change-guarantee-page-image {
    width: 100%;
    padding: 0 20px;
  }
  .long-text-displays-container {
    padding-bottom: 20px;
  }
`;
