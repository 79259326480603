import * as React from "react";
import { SVGComponent } from "../../types/default";

const SvgGigaGreen: SVGComponent = ({
  color = "#428600",
  color2 = "#fff",
  ...props
}) => {
  return (
    <svg
      id="4g_svg___x33_c54d754-f349-44b2-8061-d7aa617e1d45"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      transform={"scale(-1,1)"}
      {...props}
    >
      <path
        strokeWidth="0"
        d="M163.43 56.72c-2.05 45.82-40.52 82.18-86.38 81.68a85.55 85.55 0 0 1-22-3.11 85.57 85.57 0 0 1 84.57-106.35h23.79z"
        fill={color}
      />
      <path
        fill={color2}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        d="M35.73 162.3a367.58 367.58 0 0 1 92.15-98.79"
      />
    </svg>
  );
};

export default SvgGigaGreen;
