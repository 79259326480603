import * as React from "react";
import { SimpleState } from "../../../types/default";
import {
  AdditionalSubProduct,
  AdditionalTableDataObject,
  TableCellType,
} from "../../../types/table";
import AFOStartSelectionCell from "./AFOStartSelectionCell";
import AFOInputCell from "./AFOInputCell";
import { MONTH_MAX_LENGTH } from "../../../constants/default";

export interface AFOStartCellProps {
  dataset: AdditionalTableDataObject;
  dataId: number;
  openSelectionIdState: SimpleState<number>;
  additionalDataObject?: AdditionalSubProduct;

  changeStartId: (newPriceId: number, objectId: number) => void;
  changeStartValue: (newValue: number, objectId: number) => void;
}

const AFOStartCell: React.FC<AFOStartCellProps> = ({
  dataset,
  dataId,
  openSelectionIdState,
  additionalDataObject,
  changeStartId,
  changeStartValue,
}) => {
  if (additionalDataObject !== undefined) {
    const [openSelectionId, setOpenSelectionId] = openSelectionIdState;
    const startId = dataset.startId ?? 0;
    let start: number = 1;
    if (dataset.starts[startId]) {
      start = Math.abs(dataset.starts[startId]);
    }
    switch (dataset.startType) {
      case TableCellType.INPUT:
        return (
          <AFOInputCell
            changeValue={(value) => {
              changeStartValue(value, dataId);
            }}
            realValue={start}
            defaultValue={1}
            min={1}
            max={MONTH_MAX_LENGTH}
            step={1}
            colSpan={dataset.counts[dataset.countId] !== undefined ? 1 : 2}
            noFloat
          />
        );

      case TableCellType.SELECTION:
        return (
          <AFOStartSelectionCell
            value={start}
            dataId={dataId}
            dataSet={dataset}
            changeCountId={changeStartId}
            setOpenSelectionId={setOpenSelectionId}
            openSelectionId={openSelectionId}
          />
        );
      default:
        return null;
    }
  }
  return null;
};

export default AFOStartCell;
