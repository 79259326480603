import * as React from "react";

function SvgFamily(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="family_svg__cd3edd23-6ddf-4f08-ae6f-b5c893945238"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.family_svg__st0,.family_svg__st1{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round}.family_svg__st0{stroke-linejoin:round}.family_svg__st1{stroke-miterlimit:10}`}
      </style>
      <g id="family_svg__dcab5b7d-7664-49ea-bea9-2f2ed99af67c">
        <circle className="family_svg__st0" cx={164.4} cy={135.9} r={99.9} />
        <circle className="family_svg__st0" cx={378.4} cy={164.4} r={71.3} />
        <path
          className="family_svg__st1"
          d="M7.5 406.9c17.4-106.2 79.7-171 156.9-171 24-.1 47.5 6.4 68 18.8M322.4 252.9c16.5-11.2 36-17.2 56-17.2 56.2 0 101.4 45 114.1 118.6"
        />
        <g id="family_svg__bcfe6083-fc42-4bf9-8d5c-e89cde1080df">
          <circle
            id="family_svg___x39_6b01544-fe01-431a-9d51-93842175bc65"
            className="family_svg__st0"
            cx={278.5}
            cy={296.1}
            r={60.7}
          />
          <path
            id="family_svg__db645fb2-3962-4337-9a76-31cf831ac4f7"
            className="family_svg__st0"
            d="M377.1 464c-12.3-70.9-52.8-107.4-98-107.4s-86.9 36.5-99.2 107.4"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgFamily;
