import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";

interface AFOLabelInputCellProps {
  className?: string;
  value: string | number;
  changeValue: (newValue: string) => void;
  colSpan?: number;
}

const AFOLabelInputCell: React.FC<AFOLabelInputCellProps> = ({
  className,
  value,
  changeValue,
  colSpan = 1,
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeValue(e.target.value);
  };
  return (
    <td className={className + " cell row-label"} colSpan={colSpan}>
      <div className={"cell-input"}>
        <input
          className={"text-input"}
          type={"text"}
          value={value}
          onChange={onChange}
        />
      </div>
    </td>
  );
};

export default styled(AFOLabelInputCell)`
  background: ${Color.GREY_LIGHT3};
  .cell-input {
    box-shadow: inset 4px 5px 12px -8px ${Color.BLACK};
    height: 100%;

    .text-input {
      width: 100%;
      height: 100%;
      background: transparent;
      border: none;
      padding: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
      box-shadow: inset 4px 5px 12px -8px ${Color.BLACK};
    }
  }
`;
