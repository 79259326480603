import * as React from "react";

function SvgEntertainmentColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="entertainment_color_svg___x33_e9adbf0-1f8e-4cab-886d-c30d40c4a1e1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".entertainment_color_svg__st0,.entertainment_color_svg__st1{fill:none;stroke:#e60000;stroke-width:8.94;stroke-linecap:round}.entertainment_color_svg__st0{stroke-linejoin:round}.entertainment_color_svg__st1{stroke-miterlimit:10}"
        }
      </style>
      <g id="entertainment_color_svg___x31_ea19847-78d8-4688-abb5-e7911745ceef">
        <path
          className="entertainment_color_svg__st0"
          d="M68.6 109.7L80 57.2c.8-2.8 2.9-4.5 4.3-4.5.2 0 .5 0 .7.1 27 10.8 55.4 17.5 84.3 19.9.7.1 1.3.5 1.7 1.1 1 1.7 1.3 3.7.8 5.6l-10.6 45.1c-4.2 15.1-19.5 30.3-44.1 43.9-9.6 5.2-21.6 2.4-27.8-6.6-13.9-19.9-20.9-37.7-20.7-52.1"
        />
        <path
          className="entertainment_color_svg__st0"
          d="M68.6 109.7c-.8-.2-1.6-.5-2.4-.9-6.2-2.8-12.1-6.2-17.6-10.1-9.8-7-16-14.5-18.4-22.3L20.2 43c-.5-2.2.2-4.1 1.2-4.6.2-.1.3-.2.5-.2 21.9-3.2 43.3-9.6 63.3-19 .5-.2 1-.2 1.5.1 1.3.7 2.3 2 2.7 3.4l11.1 35.5M93.5 130.6s2.9 7.9 15.9 11.2 18.8-2.5 18.8-2.5"
        />
        <path
          id="entertainment_color_svg___x36_f9f83a2-9aa6-46d5-9bcb-cbdfd00e7fda"
          className="entertainment_color_svg__st1"
          d="M104.4 93.9h-8.9"
        />
        <path
          id="entertainment_color_svg___x39_cc9262b-6380-4c9e-958a-637d775cf269"
          className="entertainment_color_svg__st1"
          d="M140.3 107.6l-6.3-6.3"
        />
        <path fill="none" d="M0 0h192v192H0z" />
      </g>
    </svg>
  );
}

export default SvgEntertainmentColor;
