import * as React from "react";

function SvgMediaLibraryColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="mediaLibrary_color_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".mediaLibrary_color_svg__st0,.mediaLibrary_color_svg__st1{fill:none;stroke:#e60000;stroke-width:8}.mediaLibrary_color_svg__st0{stroke-linecap:round;stroke-linejoin:round}.mediaLibrary_color_svg__st1{stroke-miterlimit:10}"
        }
      </style>
      <path
        className="mediaLibrary_color_svg__st0"
        d="M28 36.1h136c4.4 0 8 3.6 8 8v88c0 4.4-3.6 8-8 8H28c-4.4 0-8-3.6-8-8v-88c0-4.5 3.6-8 8-8zM60 156.1h72m-36-12v10"
      />
      <path
        className="mediaLibrary_color_svg__st1"
        d="M70 52.1h12c1.1 0 2 .9 2 2v20c0 1.1-.9 2-2 2H70c-1.1 0-2-.9-2-2v-20c0-1.1.9-2 2-2zM110 52.1h12c1.1 0 2 .9 2 2v20c0 1.1-.9 2-2 2h-12c-1.1 0-2-.9-2-2v-20c0-1.1.9-2 2-2z"
      />
      <path
        className="mediaLibrary_color_svg__st0"
        d="M48 76.1l-12 12 12 12m96-24l12 12-12 12"
      />
      <path
        className="mediaLibrary_color_svg__st1"
        d="M70 100.1h12c1.1 0 2 .9 2 2v20c0 1.1-.9 2-2 2H70c-1.1 0-2-.9-2-2v-20c0-1.1.9-2 2-2zM110 100.1h12c1.1 0 2 .9 2 2v20c0 1.1-.9 2-2 2h-12c-1.1 0-2-.9-2-2v-20c0-1.1.9-2 2-2z"
      />
      <path d="M0 0h192v192H0V0z" fill="none" />
    </svg>
  );
}

export default SvgMediaLibraryColor;
