import { useContext } from "react";
import { AppContext } from "../state/AppContext";
import { defaultProductLineOfferState } from "../state/defaults/defaultProductLineOfferState";
import {
  getNewOfferEntryId,
  getRowIdFromOfferEntryId,
} from "../helper/offer/offerEntryNameFunctions";
import { InterfaceChangedPropTypes } from "../types/util";
import { getModeKeyFromInterest } from "../helper/recommendation/mapping";
import { Interest, RecommendationModeArray } from "../types/application";
import { ProductLineOfferState } from "../state/definitions/ProductLineOfferState";
import {
  createNewNewOfferState,
  NewOfferStateOptions,
} from "../helper/offer/createNewNewOfferState";
import { useInterestValue } from "./useFormHelper/useInterests";

export const useNewOffer = () => {
  const [state, setState] = useContext(AppContext);
  const { isInterestSelected } = useInterestValue();

  const createNewOfferState = (
    options: Pick<NewOfferStateOptions, "interest"> &
      Partial<NewOfferStateOptions>
  ) => {
    const newNewOfferEntry = createNewNewOfferState(
      1,
      state.recommendation.config[options.interest].productId[1].productId,
      options
    );
    setState((prev) => {
      return {
        ...prev,
        offer: {
          ...prev.offer,
          offerEntryIds: [...prev.offer.offerEntryIds, newNewOfferEntry.rowId],
        },
        newOffer: {
          ...prev.newOffer,
          [newNewOfferEntry.rowId]: newNewOfferEntry,
        },
      };
    });
  };

  const changeNewOfferState = (
    oldOfferEntryId: string,
    selectId: number = 0
  ) => {
    const rowId = getRowIdFromOfferEntryId(oldOfferEntryId);
    const oldId = state.offer.offerEntryIds.findIndex((offerEntryId) => {
      return getRowIdFromOfferEntryId(offerEntryId) === rowId;
    });

    if (oldId > -1) {
      const newOfferEntryId = getNewOfferEntryId(rowId, selectId);
      const newOfferEntryIds = Object.assign(
        [] as string[],
        state.offer.offerEntryIds
      );
      newOfferEntryIds[oldId] = newOfferEntryId;
      if (state.newOffer[newOfferEntryId] !== undefined) {
        setState((prev) => ({
          ...prev,
          offer: {
            ...prev.offer,
            offerEntryIds: newOfferEntryIds,
          },
        }));
        return newOfferEntryId;
      }

      const prevOffer = state.newOffer[state.offer.offerEntryIds[oldId]];
      const newNewOfferState = Object.assign({}, defaultProductLineOfferState);
      newNewOfferState.rowId = newOfferEntryId;
      newNewOfferState.productId =
        state.recommendation.config[prevOffer.type].productId[
          selectId
        ].productId;
      newNewOfferState.selectId = selectId;
      newNewOfferState.type = prevOffer.type;
      newNewOfferState.mainRow = prevOffer.mainRow;

      setState((prev) => ({
        ...prev,
        offer: {
          ...prev.offer,
          offerEntryIds: newOfferEntryIds,
        },
        newOffer: {
          ...prev.newOffer,
          [newOfferEntryId]: newNewOfferState,
        },
      }));
      return newOfferEntryId;
    } else {
      console.error("No other row with id ", rowId, " could be found!");
    }

    return "";
  };

  const removeNewOfferEntryId = (offerEntryId: string) => {
    setState((prev) => ({
      ...prev,
      offer: {
        ...prev.offer,
        offerEntryIds: prev.offer.offerEntryIds.filter(
          (v) => v !== offerEntryId
        ),
      },
    }));
    return true;
  };

  const isRowSelected = (offerEntryId: string) => {
    if (state.newOffer[offerEntryId] === undefined) {
      return false;
    }
    const type = state.newOffer[offerEntryId].type;
    return isInterestSelected(type);
  };

  const getCurrentMainOfferEntries = () => {
    const interestOfferState: Partial<
      InterfaceChangedPropTypes<RecommendationModeArray, ProductLineOfferState>
    > = {};

    state.offer.offerEntryIds.forEach((offerEntryId) => {
      const type = state.newOffer[offerEntryId].type;
      if (isRowSelected(offerEntryId) && state.newOffer[offerEntryId].mainRow) {
        interestOfferState[getModeKeyFromInterest(type)] =
          state.newOffer[offerEntryId];
      }
    });

    return interestOfferState;
  };

  const getCurrentOfferEntries = (): ProductLineOfferState[] => {
    return state.offer.offerEntryIds.map(
      (offerEntryId) => state.newOffer[offerEntryId]
    );
  };

  const getNewOfferState = (
    offerEntryId: string
  ): ProductLineOfferState | undefined => {
    return state.newOffer[offerEntryId];
  };

  const getNewOfferType = (offerEntryId: string): Interest => {
    return state.newOffer[offerEntryId]?.type ?? Interest.MOBILE;
  };

  const getEntryProperty = <K extends keyof ProductLineOfferState>(
    key: K,
    restrictor: (productOffer: ProductLineOfferState) => boolean = () => true,
    sortFct?: (a: ProductLineOfferState, b: ProductLineOfferState) => number
  ) => {
    let offerEntryIds = state.offer.offerEntryIds;
    if (!!sortFct) {
      offerEntryIds = state.offer.offerEntryIds.sort((a, b) => {
        return sortFct(state.newOffer[a], state.newOffer[b]);
      });
    }
    return offerEntryIds.reduce(
      (keyValues: ProductLineOfferState[K][], offerEntryId: string) => {
        if (restrictor(state.newOffer[offerEntryId])) {
          return keyValues.concat([state.newOffer[offerEntryId][key]]);
        }
        return keyValues;
      },
      []
    );
  };
  const getSelectedEntryProperty = <K extends keyof ProductLineOfferState>(
    key: K,
    restrictor: (productOffer: ProductLineOfferState) => boolean = () => true,
    sortFct?: (a: ProductLineOfferState, b: ProductLineOfferState) => number
  ) => {
    return getEntryProperty(
      key,
      (productOffer: ProductLineOfferState) => {
        return isRowSelected(productOffer.rowId) && restrictor(productOffer);
      },
      sortFct
    );
  };
  return {
    getNewOfferState,
    getNewOfferType,
    createNewOfferState,
    changeNewOfferState,
    removeNewOfferEntryId,
    getCurrentMainOfferEntries,
    getCurrentOfferEntries,
    getSelectedEntryProperty,
    getEntryProperty,
  };
};
