import * as React from "react";
import { PriceTextTile } from "../../PriceTextTile";
import { Color } from "../../../../../constants/color";
import { MonthlyPriceArray } from "../../../../../types/application";
import PriceDisplayContainer from "../../PriceDisplayContainer";

export interface Props {
  priceArray: MonthlyPriceArray;
}

const InterestingRecommendationPrintColumn = ({ priceArray }: Props) => {
  return (
    <>
      <PriceTextTile backgroundColor={Color.GREY_LIGHT}>
        <PriceDisplayContainer data={priceArray} />
      </PriceTextTile>
    </>
  );
};
export default InterestingRecommendationPrintColumn;
