import React from "react";
import styled from "styled-components";
import PanelItem from "../../panel/PanelItem";
import PanelValue from "../../panel/PanelValue";
import GreyIcons from "../../../icons/GreyIcons";
import { prettyDate, prettyMoney } from "../../../helper/getPrettyStrings";
import { MobileSituation } from "../../../types/profile";

interface PanelItemsMobileSituationProps {
  className?: string;
  situation: MobileSituation;
}

const PanelItemsMobileSituation: React.FC<PanelItemsMobileSituationProps> = ({
  situation,
}) => (
  <>
    {situation.provider !== undefined && (
      <PanelItem icon={GreyIcons.worldNet}>
        <PanelValue>{situation.provider}</PanelValue>
      </PanelItem>
    )}
    {situation.price !== undefined && situation.price > 0 && (
      <PanelItem icon={GreyIcons.price}>
        Preis: <PanelValue>{prettyMoney(situation.price)}</PanelValue>
      </PanelItem>
    )}
    {situation.dataVolume !== undefined && situation.dataVolume > 0 && (
      <PanelItem icon={GreyIcons.accelerometerHalf}>
        Datenvolumen <PanelValue>{situation.dataVolume}</PanelValue> GB
      </PanelItem>
    )}
    {situation.extraDataVolume && (
      <PanelItem icon={GreyIcons.accelerometerHalf_color}>
        Bedarf zusätzliches Datenvolumen
      </PanelItem>
    )}
    {situation.currentContractEndDate !== undefined && (
      <PanelItem icon={GreyIcons.calendar}>
        Ende Vertragslaufzeit{" "}
        <PanelValue>{prettyDate(situation.currentContractEndDate)}</PanelValue>
      </PanelItem>
    )}
    {situation.tabletCount !== undefined && situation.tabletCount > 0 && (
      <PanelItem icon={GreyIcons.tablet}>
        Tablets: <PanelValue>{situation.tabletCount}</PanelValue>
      </PanelItem>
    )}
    {situation.smartwatchCount !== undefined && situation.smartwatchCount > 0 && (
      <PanelItem icon={GreyIcons.watch}>
        Smartwatches: <PanelValue>{situation.smartwatchCount}</PanelValue>
      </PanelItem>
    )}
    {situation.iotCount !== undefined && situation.iotCount > 0 && (
      <PanelItem icon={GreyIcons.iot}>
        IOT-Produkte: <PanelValue>{situation.iotCount}</PanelValue>
      </PanelItem>
    )}
    {situation.phoneCount !== undefined && situation.phoneCount > 0 && (
      <PanelItem icon={GreyIcons.mobile}>
        Smartphones: <PanelValue>{situation.phoneCount}</PanelValue>
      </PanelItem>
    )}
    {situation.devicesCount !== undefined && situation.devicesCount > 0 && (
      <PanelItem icon={GreyIcons.devices}>
        Anzahl Geräte gesamt: <PanelValue>{situation.devicesCount}</PanelValue>
      </PanelItem>
    )}
  </>
);

export default styled(PanelItemsMobileSituation)``;
