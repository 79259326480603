import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import DisableShadowBox from "../elements/DisableShadowBox";
import { SVGComponent } from "../../types/default";

interface NavButtonProps {
  className?: string;
  fct: () => void;
  icon: SVGComponent | SVGComponent[];
  active?: boolean;
  disabled?: boolean;
  showDisabled?: boolean;
}

const NavButton: React.FC<NavButtonProps> = ({
  className,
  fct,
  icon: Icon,
  disabled = false,
  showDisabled = false,
}) => {
  const handleClick = () => {
    if (!disabled) {
      fct();
    }
  };

  return (
    <li className={className} onClick={handleClick}>
      {Array.isArray(Icon) ? (
        Icon.map((OneIcon, i) => (
          <OneIcon
            key={i}
            style={{ top: `${i * 100 + 50}%` }}
            className={`image-n-${i}`}
          />
        ))
      ) : (
        <Icon />
      )}
      <DisableShadowBox disabled={disabled} showDisabled={showDisabled} />
    </li>
  );
};

export default styled(NavButton)`
  background-color: ${(props) => (props.active ? Color.RED : Color.GREY2)};
  text-align: center;
  max-width: 66px;
  list-style: none;
  min-height: 80px;
  border-bottom: 3px solid ${Color.WHITE};
  cursor: pointer;
  position: relative;

  img,
  svg {
    padding: 12px 0;
    /* position: absolute; */
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    max-width: 75%;
    max-height: 160px;
  }

  img.image-n-1 {
    padding-top: 0;
  }
`;
