import React from "react";
import styled from "styled-components";
import { SVGComponent } from "../../../types/default";
import PanelItem from "../../panel/PanelItem";
import { TVImportants } from "../../../types/profile";

interface PanelItemsTVImportantProps {
  className?: string;
  importants: TVImportants;
  iconSet: TvImportantIcons;
}

export interface TvImportantIcons {
  tvTV: SVGComponent;
  windowedApps: SVGComponent;
  mediaLibrary: SVGComponent;
  cloudDownload: SVGComponent;
  popcorn: SVGComponent;
  privateHd: SVGComponent;
  tvRefresh: SVGComponent;
  devices: SVGComponent;
  entertainment: SVGComponent;
  privacy: SVGComponent;
}

const PanelItemsTVImportant: React.FC<PanelItemsTVImportantProps> = ({
  importants,
  iconSet,
}) => (
  <>
    {importants.classicTV && (
      <PanelItem icon={iconSet.tvTV}>Klassisches Fernsehen</PanelItem>
    )}
    {importants.streamingProvider && (
      <PanelItem icon={iconSet.windowedApps}>Streaming Anbieter</PanelItem>
    )}
    {importants.mediaLibrary && (
      <PanelItem icon={iconSet.mediaLibrary}>Mediatheken</PanelItem>
    )}
    {importants.vod && (
      <PanelItem icon={iconSet.cloudDownload}>
        Filme auf Abruf in der Videothek
      </PanelItem>
    )}
    {importants.sky && <PanelItem icon={iconSet.popcorn}>Sky</PanelItem>}
    {importants.privateHd && (
      <PanelItem icon={iconSet.privateHd}>Privatsender in HD</PanelItem>
    )}
    {importants.recordings && (
      <PanelItem icon={iconSet.tvRefresh}>Aufnahmefunktion</PanelItem>
    )}
    {importants.mobileAndTablet && (
      <PanelItem icon={iconSet.devices}>TV auf Smartphone/Tablet</PanelItem>
    )}
    {importants.currentProgram && (
      <PanelItem icon={iconSet.entertainment}>
        Aktuelle Serien & Filme
      </PanelItem>
    )}
    {importants.payTV && (
      <PanelItem icon={iconSet.privacy}>Pay TV Sender</PanelItem>
    )}
  </>
);

export default styled(PanelItemsTVImportant)``;
