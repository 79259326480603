import { FilteredOnNotType } from "../../types/util";
import { AppState } from "../../state/definitions/AppState";
import { SimpleState } from "../../types/default";
import { useContext } from "react";
import { AppContext } from "../../state/AppContext";

export type AppStatePropObjects = FilteredOnNotType<
  AppState,
  boolean | string | Array<any>
>;

/**
 * StateKey is property of AppState
 * AppState[StateKey] is type of the property of AppState
 * Key is property of AppState[StateKey]
 * AppState[StateKey][Key] is type of the property of AppState[StateKey]
 *
 * The param withChangeFct allows to change or
 * overwrite some specific values in the specific state.
 *
 * @param stateKey
 * @param key
 * @param withChangeFct
 */
export const useAppState = <
  StateKey extends keyof AppStatePropObjects,
  Key extends keyof AppStatePropObjects[StateKey]
>(
  stateKey: StateKey,
  key: Key,
  withChangeFct?: (keys: Key) => Partial<AppState[StateKey]>
): SimpleState<AppStatePropObjects[StateKey][Key]> => {
  //
  const [state, setState] = useContext(AppContext);
  const setValue = (value: AppStatePropObjects[StateKey][Key]) => {
    let changedObject = {};
    if (withChangeFct) {
      changedObject = withChangeFct(key);
    }
    setState((prev: AppState) => {
      return {
        ...prev,
        [stateKey]: {
          ...prev[stateKey],
          [key]: value,
          ...changedObject,
        },
      };
    });
  };

  return [state[stateKey][key], setValue];
};
