import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import { StateSetter } from "../../types/default";

interface TextFieldProps {
  className?: string;
  onChange?: (value: string) => void;
  setIsEditing?: StateSetter<boolean>;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
}

const MultilineTextField: React.FC<TextFieldProps> = ({
  className,
  onChange,
  value = "",
  placeholder,
  disabled,
  setIsEditing,
}) => {
  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };
  const onInputFocus = () => {
    if (setIsEditing) {
      setIsEditing(true);
    }
  };
  const onInputLeave = () => {
    if (setIsEditing) {
      setIsEditing(false);
    }
  };
  return (
    <div className={className}>
      <textarea
        value={value}
        placeholder={placeholder}
        onChange={onChangeHandler}
        disabled={disabled}
        onFocus={onInputFocus}
        onBlur={onInputLeave}
        rows={8}
      />
    </div>
  );
};

export default styled(MultilineTextField)`
  background-color: ${Color.WHITE};
  text-align: left;
  user-select: none;
  padding: 5px;
  display: inline-block;
  width: 100%;
  outline: none;

  textarea {
    color: ${Color.RED};
    resize: none;
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
  }
`;
