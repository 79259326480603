import React from "react";
import styled from "styled-components";
import Column from "./Column";

interface GridColumnProps {
  className?: string;
  colCount?: number;
  maxWidth?: string;
  minWidth?: string;
  textAlign?: string;
  withoutBottomMargin?: boolean;
}

const GridColumn: React.FC<GridColumnProps> = ({
  children,
  className,
  colCount,
  maxWidth = "initial",
  minWidth = "initial",
  withoutBottomMargin = true,
}) => {
  return (
    <Column
      className={className}
      colCount={colCount}
      maxWidth={maxWidth}
      minWidth={minWidth}
      withoutBottomMargin={withoutBottomMargin}
    >
      {children}
    </Column>
  );
};

export default styled(GridColumn)`
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "initial")};
`;
