import * as React from "react";

function SvgLaptop(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="laptop_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.laptop_svg__st1{fill:none;stroke: ${color} ;stroke-width:21.3386;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        d="M61.4 316.4L7.5 437.6h485l-53.9-121.2"
        fill="none"
        stroke={color}
        strokeWidth={21.339}
        strokeLinejoin="round"
      />
      <path
        className="laptop_svg__st1"
        d="M166.2 377.7h167.7M82.3 54.4h335.3c19.8 0 35.9 16.1 35.9 35.9v191.6c0 19.8-16.1 35.9-35.9 35.9H82.3c-19.8 0-35.9-16.1-35.9-35.9V90.3c0-19.8 16.1-35.9 35.9-35.9z"
      />
    </svg>
  );
}

export default SvgLaptop;
