import { CountUnits, PriceUnits, TableCellType } from "../../types/table";

export const getFurtherStandardTableObject = () => {
  return {
    labelId: 0,
    countId: 0,
    priceId: undefined,
    labels: ["Kein Produkt"],
    counts: [0],
    countUnit: CountUnits.normal,
    prices: [0, 0],
    priceUnit: PriceUnits.months,
    labelType: TableCellType.SELECTION,
    countType: TableCellType.SELECTION,
    priceType: TableCellType.EXTERNAL,
    dataVolumes: [0],
  };
};