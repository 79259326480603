import * as React from "react";
import { useMemo } from "react";
import styled from "styled-components";
import { StyledComponentProps } from "../../types/default";
import { useOfferState } from "../../hooks/useOffer";
import RecommendationTableContainer from "../elements/recommendationPage/RecommendationTableContainer";
import { useNewOffer } from "../../hooks/useNewOffer";
import useEndPrice from "../../hooks/useOfferHelper/useEndPrice";
import PriceSummaryContainer from "../elements/recommendationPage/PriceSummaryContainer";

export interface Props extends StyledComponentProps {}

const MoreRecommendationPage = ({ className }: Props) => {
  const [offerEntryIds] = useOfferState("offerEntryIds");
  const { getSelectedEntryProperty } = useNewOffer();

  const selectedOfferEntryIds = useMemo(() => {
    const moreOfferEntryIds = getSelectedEntryProperty("rowId", (v) => {
      return !v.mainRow;
    });
    if (moreOfferEntryIds.length >= 5) {
      return moreOfferEntryIds;
    }
    return [...moreOfferEntryIds, ""];
  }, [offerEntryIds]);

  const [wholeEndPrices, wholeOncePrice] = useEndPrice();

  return (
    <div className={className}>
      <RecommendationTableContainer offerEntryIds={selectedOfferEntryIds} />
      <PriceSummaryContainer
        monthlyPriceArray={wholeEndPrices ? wholeEndPrices : {}}
        oncePrice={wholeOncePrice ? wholeOncePrice : 0}
      />
    </div>
  );
};

export default styled(MoreRecommendationPage)`
  height: 100%;
  overflow: auto;
`;
