import { DatabaseProduct } from "../../data/definitions/DatabaseProduct";
import { SubProductInformation } from "./definitions/SubProductInformation";
import { getProductBasicPrice } from "./getProductBasicPrice";

export function getSubProductInformation(
  product: DatabaseProduct
): SubProductInformation {
  return {
    id: product.productId,
    label: product.productName, //string
    price: getProductBasicPrice(product), //number
    dataVolume: product.productDataVolumeIncluded, //number;
  };
}
