import { useInterestsValues } from "../useFormHelper/useInterests";
import { getRegioDiscountAdditionalTableObject } from "../../helper/table/getRegioDiscountAdditionalTableObject";
import { useFormState } from "../useForm";
import { Interest, RecommendationMode } from "../../types/application";
import { useOfferState } from "../useOffer";
import { useNewOfferState } from "../useOfferHelper/useNewOfferState";
import { useCurrentProducts } from "../useDatabaseProducts";
import { DatabaseProductTypes } from "../../data/definitions/DatabaseProductTypes";
import { ENABLE_REGIO_DISCOUNT } from "../../constants/default";

const regioDiscountLabels = ["Kein Rabatt", "Regio-Zuschlag mit 5€/mtl."];
const regioDiscountPrices = [0, 5];

export const useRegioDiscount = (offerEntryId: string) => {
  const { fixedMode } = useInterestsValues();
  const [withRegioDiscount] = useFormState("withRegioDiscount");

  const [type] = useNewOfferState("type", offerEntryId);
  const [productId] = useNewOfferState("productId", offerEntryId);
  const { getSelectedProducts } = useCurrentProducts();
  const currentProduct = getSelectedProducts([productId])[0];

  let regioDiscountAllowed = false;
  if (
    ENABLE_REGIO_DISCOUNT &&
    withRegioDiscount &&
    fixedMode === RecommendationMode.SELECTABLE &&
    type === Interest.FIXED &&
    currentProduct.productName.includes("16 DSL") &&
    currentProduct.productType === DatabaseProductTypes.fixed
  ) {
    regioDiscountAllowed = true;
  }

  const [regioDiscountLabelId] = useOfferState("regioDiscountLabelId");

  const regioDiscountAdditionalObject = getRegioDiscountAdditionalTableObject(
    regioDiscountLabels,
    regioDiscountPrices,
    regioDiscountLabelId
  );

  return {
    regioDiscountAllowed,
    regioDiscountAdditionalObject,
  };
};
