import React from "react";
import styled from "styled-components";
import PaginationButton from "../pagination/PaginationButton";
import { Color } from "../../constants/color";
import SVGIcon from "../svgIcon/SVGIcon";
import { SVGComponent } from "../../types/default";

interface ImageCounterProps {
  className?: string;
  image: SVGComponent;
  label?: string;
  value?: number;
  max?: number;
  next?: () => void;
  prev?: () => void;
}

const ImageCounter: React.FC<ImageCounterProps> = ({
  className,
  image,
  label,
  value = 0,
  prev,
  next,
}) => (
  <div className={className + " image-counter"}>
    <div className={"image-label-container"}>
      <SVGIcon src={image} size={80} />
      <span className={"label"}>{label}</span>
    </div>
    <div className={"value-container"}>
      <PaginationButton className={"prev"} direction={"prev"} onClick={prev} />
      <span className={"value"}>{value}</span>
      <PaginationButton className={"next"} direction={"next"} onClick={next} />
    </div>
  </div>
);

const getColor: (props: ImageCounterProps) => string = ({
  value = 0,
}): string => (value > 0 ? Color.RED : Color.GREY);

export default styled(ImageCounter)`
  width: 150px;
  display: inline-block;
  margin: 10px;

  .image-label-container {
    width: 100%;
    height: 120px;
    display: block;
    background: ${getColor};

    img,
    svg {
      display: block;
      margin: 0 auto;
      padding: 10px 10px 0 10px;
    }

    .label {
      width: 100%;

      display: block;
      position: relative;
      margin: 0 auto;
      //margin: 4px 5px;
      padding: 5px;

      color: ${Color.WHITE};
      background: ${getColor};

      font-size: 15px;
      text-align: center;
      vertical-align: middle;
    }
  }

  .value-container {
    display: block;
    position: relative;
    width: 100%;
    margin-top: 1px;

    background: ${getColor};
    text-align: center;

    .prev {
      width: 30%;
      outline: none;
      font-weight: bold;
      background-color: ${getColor};
      text-align: right;
      vertical-align: middle;
      visibility: ${(props: ImageCounterProps) =>
        props.value && props.value > 0 ? "visible" : "hidden"};
    }

    .next {
      width: 30%;
      outline: none;
      font-weight: bold;
      background-color: ${getColor};
      text-align: left;
      vertical-align: middle;
      visibility: ${(props: ImageCounterProps) =>
        props.value && props.max && props.value >= props.max
          ? "hidden"
          : "visible"};

      .right-triangle {
        margin-left: 15px;
      }
    }

    .value {
      position: relative;
      display: inline-block;
      width: 100%;
      max-width: 40%;

      color: ${Color.WHITE};
      font-weight: bold;

      text-align: center;
      vertical-align: middle;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
