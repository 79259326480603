import * as React from "react";

function SvgCurrentProgram(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="currentProgram_svg__b12d3976-2a98-46f6-a271-4f9203fd41e9"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.currentProgram_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className="currentProgram_svg__st0"
        d="M7.5 84.1h485v331.8H7.5zM7.5 135.1h485M7.5 364.9h485M96.8 84.1v51M173.4 84.1v51M250 84.1v51M326.6 84.1v51M403.2 84.1v51M96.8 364.9v51M173.4 364.9v51M250 364.9v51M326.6 364.9v51M403.2 364.9v51"
      />
      <path
        className="currentProgram_svg__st0"
        d="M313.8 250l-95.7-47.8v95.6z"
      />
    </svg>
  );
}

export default SvgCurrentProgram;
