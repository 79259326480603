import React, { ChangeEventHandler, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import SVGIcon from "../svgIcon/SVGIcon";
import SVGIconGroup from "../svgIcon/SVGIconGroup";
import { SVGComponent } from "../../types/default";
import Icons from "../../icons/Icons";

interface TextFieldIconTileButtonProps {
  className?: string;
  onChange: (value: string) => void;
  value: string;
  main?: boolean;
  icons?: SVGComponent[];
  imageSize?: number;
  shadowed?: boolean;
  color?: string;
}

const TextFieldIconTileButton: React.FC<TextFieldIconTileButtonProps> = ({
  className,
  children,
  onChange,
  value = "",
  icons = [],
  imageSize,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(value);
  const onClick = (_: React.MouseEvent) => {
    if (!!inputRef.current) {
      inputRef.current.focus();
    }
  };
  const onValueChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event.target.value);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className={className + " tile-button"} onClick={onClick}>
      {icons && (
        <div className={"tile-button-icons-container"}>
          {icons.length === 1 && (
            <SVGIcon
              src={icons[0]}
              alt={""}
              size={imageSize}
              color={Color.WHITE}
            />
          )}
          {icons.length > 1 && (
            <SVGIconGroup
              icons={icons}
              color={Color.WHITE}
              size={imageSize ? imageSize * 1.25 : undefined}
            />
          )}
        </div>
      )}
      <div className={"tile-button-content-container"}>
        <input
          ref={inputRef}
          type={"text"}
          value={inputValue}
          onChange={onValueChange}
          autoFocus
          spellCheck={false}
        />
        <SVGIcon
          className={"input-edit-icon"}
          src={Icons.edit}
          alt={"Stift"}
          size={20}
          color={Color.RED}
        />
        {children && <span className={"tile-button-content"}>{children}</span>}
      </div>
    </div>
  );
};

export default styled(TextFieldIconTileButton)`
  display: inline-flex;
  align-items: center;
  /* flex-wrap: wrap; */
  justify-content: center;
  flex-flow: column;
  box-sizing: content-box;

  height: 110px;
  width: 165px;
  margin: 10px;
  padding: 5px;

  box-shadow: ${({ shadowed }) =>
    shadowed ? "2px 2px 6px 0" + Color.GREY_DARK2 : ""};

  vertical-align: bottom;
  text-align: center;

  //font-weight: bold;
  background-color: ${(props) => (props.color ? props.color : Color.RED)};

  user-select: none;
  cursor: pointer;
  overflow: hidden;

  .icon {
    cursor: pointer;
    display: block;
    margin: 0 auto;
  }
  .tile-button-icons-container {
    //height: 75px;
  }
  .tile-button-content-container {
    position: relative;
    display: block;
    width: 100%;
    padding: 6px 2px;
    box-sizing: border-box;

    input {
      width: 100%;
      padding: 2px 30px 2px 8px;

      background: transparent;
      border: 1px solid ${Color.WHITE};
      border-radius: 5px;
      text-align: left;
      vertical-align: bottom;
      overflow: hidden;
      text-overflow: ellipsis;

      color: ${Color.WHITE};
      font-size: 20px;
      line-height: 20px;

      outline: ${Color.WHITE};
    }

    span {
      display: inline-block;
      //position: absolute;

      //left: 0;
      //bottom: 5px;
      width: 100%;
      //max-width: 400px;
      line-height: 20px;
      font-size: 20px;

      color: ${Color.WHITE};
      vertical-align: bottom;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .input-edit-icon {
      position: absolute;
      top: 6px;
      right: 2px;

      cursor: pointer;
      background: ${Color.WHITE};
      padding: 3px 3px 4px 3px;
      box-sizing: content-box;
      border: 1px solid #ffffff;
      border-radius: 0 5px 5px 0;
    }
  }
`;
