import { IdObject } from "../helper/dataHandling/definitions/IdObject";
import { SVGComponent } from "./default";

export interface InterestObject {
  mobile: boolean;
  iot: boolean;
  fixed: boolean;
  tv: boolean;
}

export type WithsObject = InterestObject;

export enum Interest {
  MOBILE = "mobile",
  FIXED = "fixed",
  TV = "tv",
  IOT = "iot",
}

export enum RecommendationMode {
  SELECTABLE = "SELECTABLE",
  CURRENT = "CURRENT",
  INTERESTING = "INTERESTING",
}

export interface RecommendationModeArray {
  mobileMode: RecommendationMode;
  fixedMode: RecommendationMode;
  tvMode: RecommendationMode;
  iotMode: RecommendationMode;
}

export enum RecommendationType {
  MOBILE = "Mobilfunk",
  IOT = "IoT",
  FIXED = "Internet & Festnetz",
  TV = "TV",
}

export enum TechnologyNames {
  cable = "Kabel",
  dsl = "DSL",
  fiber = "Glasfaser",
  mobile = "Mobilfunk",
  tv = "TV",
}

export type ContentObjectTypes = "text" | "image";
export type personOrKid = "person" | "kid";

export interface ContentObject {
  type: ContentObjectTypes;
  text: string;
}

export interface LabelOption<T> {
  label: T;
}

export interface LabelValuePair<VT = string> {
  label: string;
  value: VT;
}

export interface TabsData {
  title: string;
  value: string;
  content: JSX.Element;
}

export class ContractSummaryPerson {
  cardData: number = 0;
  cardTariff: number = 0;
}

export class ContractSummaryEgo extends ContractSummaryPerson {
  cardDataGo: number = 0;
}

export class ContractSummary extends ContractSummaryEgo {}

export interface CountObject<AllowedLabels = string> {
  labelKey: AllowedLabels;
  count: number;
  icon?: SVGComponent;
  setter?: (newCount: number) => void;
}

export interface OptimizeContractData {
  type: personOrKid;
  contracts: CountObject<keyof ContractSummary>[];
  name: string;
  nameSetter: (newValue: string) => void;
}

export interface GigaKombiEntry {
  mobile: boolean;
  iot: boolean;
  fixed: boolean;
  tv: boolean;
  circleData: LabelValuePair<string | JSX.Element>[];
}

export interface BubbleEntry {
  headline: string;
  content: string;
  icon?: SVGComponent;
}

export interface PriceDisplayInformation {
  headline: string;
  mainPrice: number;
  subline: string[];
  currency?: string;
}

export interface ProductCountArray {
  [productId: number]: number;
}

export interface MonthlyPriceArray {
  [month: number]: number;
}

export interface ProductTitleData {
  preLabel?: string;
  label?: string;
  postLabel?: string;
}

export interface ProductBodyData {
  preLabel?: string;
  label?: string;
  postLabel?: string;
}

export interface Product extends IdObject {
  title: ProductTitleData;
  body: ProductBodyData;
}

export interface ImageTileCheckbox {
  title: string;
  image: JSX.Element;
}

export type ChangeProductDataIndexType = (
  newDataId: number,
  productId: number
) => void;
