import {
  FixedFormState,
  FormState,
  FormStateArrays,
  FormStateCategoryHasChanged,
  FormStateCategoryHasData,
  IotFormState,
  MobileFormState,
  TVFormState,
} from "../state/definitions/FormState";
import { AppState } from "../state/definitions/AppState";
import { SimpleStateArray } from "../types/default";
import { useAppState } from "./useAppHelper/useAppState";
import { useAppStateMultiple } from "./useAppHelper/useAppStateMultiple";
import { useAppStateArray } from "./useAppHelper/useAppStateArray";

const MobileFormStateObjectKeys = Object.keys(MobileFormState);
const IotFormStateObjectKeys = Object.keys(IotFormState);
const FixedFormStateObjectKeys = Object.keys(FixedFormState);
const TVFormStateObjectKeys = Object.keys(TVFormState);

const setHasChanged = <K extends keyof FormState>(key: K) => {
  let hasChangedKey: undefined | keyof FormStateCategoryHasChanged = undefined;
  let hasDataKey: undefined | keyof FormStateCategoryHasData = undefined;

  const mobileHasChanged = MobileFormStateObjectKeys.some((v) => {
    if (v === "providerMobileId") {
      return false;
    }
    return v === key;
  });
  if (mobileHasChanged) {
    hasChangedKey = "mobileHasChanged";
    hasDataKey = "mobileHasData";
  }
  if (hasChangedKey === undefined) {
    const iotHasChanged = IotFormStateObjectKeys.some((v) => {
      if (v === "providerIotId") {
        return false;
      }
      return v === key;
    });
    if (iotHasChanged) {
      hasChangedKey = "iotHasChanged";
      hasDataKey = "iotHasData";
    }
  }
  if (hasChangedKey === undefined) {
    const fixedHasChanged = FixedFormStateObjectKeys.some((v) => {
      if (v === "providerFixedId") {
        return false;
      }
      return v === key;
    });
    if (fixedHasChanged) {
      hasChangedKey = "fixedHasChanged";
      hasDataKey = "fixedHasData";
    }
  }
  if (hasChangedKey === undefined) {
    const tvHasChanged = TVFormStateObjectKeys.some((v) => {
      if (v === "providerTvId") {
        return false;
      }
      return v === key;
    });
    if (tvHasChanged) {
      hasChangedKey = "tvHasChanged";
      hasDataKey = "tvHasData";
    }
  }

  let hasObject = {};

  if (hasChangedKey !== undefined && hasDataKey !== undefined) {
    hasObject = { [hasChangedKey]: true, [hasDataKey]: true };
  }
  return hasObject;
};

export const useFormState = <K extends keyof AppState["form"]>(key: K) =>
  useAppState("form", key, setHasChanged);
export const useFormStateMultiple = <
  K extends keyof AppState["form"] & string,
  KeyArray extends K[]
>(
  keys: K[]
) => useAppStateMultiple("form", keys, setHasChanged);

export const useFormStateArray = <K extends keyof FormStateArrays>(
  key: K
): SimpleStateArray<FormStateArrays[K][number]> =>
  useAppStateArray("form", key, setHasChanged);
