import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";

interface TabProps {
  className?: string;
  onClick?: () => void;
  selected?: boolean;
  title?: string;
}

const Tab: React.FC<TabProps> = ({ onClick, className, title }) => (
  <li className={className}>
    <button onClick={onClick}>{title}</button>
  </li>
);

export default styled(Tab)`
  height: 80px;
  width: 100px;
  display: table-cell;
  padding: 0 1px;
  vertical-align: middle;
  text-align: center;

  button {
    height: 100%;
    width: 100%;
    background-color: ${(props) =>
      props.selected ? Color.RED : Color.GREY_LIGHT};
    color: ${Color.WHITE};
    border: 0;
    padding: 0;
    outline: none;
  }

  button:hover {
    cursor: pointer !important;
  }
`;
