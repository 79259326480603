import { useContext } from "react";
import { AppContext } from "../state/AppContext";
import { SlideCategoryTypes } from "../constants/SlidePages";
import { getCategoryFromAllSlides } from "../helper/navigation/getCategoryFromAllSlides";
import { Interest } from "../types/application";
import { useHistory } from "react-router-dom";
import { FormStateCategoryHasChanged } from "../state/definitions/FormState";
import { getHasChangedFromInterest } from "../helper/recommendation/mapping";

export const useNavigation = () => {
  const [state, setState] = useContext(AppContext);
  const history = useHistory();

  const setNextInterestSlides = (interest: Interest | null) => {
    setState((prev) => ({
      ...prev,
      navigation: {
        ...prev.navigation,
        nextInterest: interest,
      },
    }));
  };

  /**
   * Add an interest while maintaining a certain order
   * @param interest
   */
  const toggleInitialInterest = (interest: Interest) => {
    const ordered: Interest[] = [
      Interest.MOBILE,
      Interest.IOT,
      Interest.FIXED,
      Interest.TV,
    ];
    setState((prev) => {
      let interests: Interest[];
      let isRemoved = false;
      if (prev.form.interests.includes(interest)) {
        interests = prev.form.interests.filter((i) => i !== interest);
        isRemoved = true;
      } else {
        interests = ordered.filter(
          (i) => prev.form.interests.includes(i) || i === interest
        );
      }

      //Set interest productline of 'hasChanged'
      const hasChangedObject: FormStateCategoryHasChanged = {
        mobileHasChanged: false,
        iotHasChanged: false,
        fixedHasChanged: false,
        tvHasChanged: false,
      };
      interests.forEach((v) => {
        hasChangedObject[getHasChangedFromInterest(v)] = true;
      });

      const shouldNextInterest = interests.length > 0 ? interests[0] : null;
      // Use the first interest as first slides to jump to
      let nextInterest = shouldNextInterest;
      if (
        prev.navigation.initializeComplete &&
        prev.navigation.tempNextInterest
      ) {
        if (prev.navigation.tempNextInterest === interest) {
          nextInterest = prev.navigation.tempNextInterest;
        } else {
          nextInterest = interest;
        }
      }
      if (isRemoved) {
        nextInterest = shouldNextInterest;
      }

      return {
        ...prev,
        form: {
          ...prev.form,
          ...hasChangedObject,
          interests,
        },
        navigation: {
          ...prev.navigation,
          nextInterest,
        },
      };
    });
  };

  /**
   * Set interests on true or if all are already true to false, remains the other ones
   * @param interests
   */
  const toggleArrayOfInterests = (interests: Interest[]) => {
    setState((prev) => {
      const filteredPreviousInterests: Interest[] = prev.form.interests.filter(
        (interest) => !interests.includes(interest)
      );
      let newInterests: Interest[] = Object.assign(
        [],
        filteredPreviousInterests
      );
      let remainedInterest = 0;

      interests.forEach((interest) => {
        newInterests.push(interest);
        if (prev.form.interests.includes(interest)) {
          remainedInterest += 1;
        }
      });
      if (interests.length === remainedInterest) {
        newInterests = filteredPreviousInterests;
      }

      //Set interest productline of 'hasChanged'
      const hasChangedObject: FormStateCategoryHasChanged = {
        mobileHasChanged: false,
        iotHasChanged: false,
        fixedHasChanged: false,
        tvHasChanged: false,
      };
      newInterests.forEach((v) => {
        hasChangedObject[getHasChangedFromInterest(v)] = true;
      });

      return {
        ...prev,
        form: {
          ...prev.form,
          ...hasChangedObject,
          interests: newInterests,
        },
        navigation: {
          ...prev.navigation,
          nextInterest: Interest.MOBILE,
        },
      };
    });
  };

  /**
   * Add an interest to the end of the interests or remove if already existing
   * @param interest
   */
  const toggleInterest = (interest: Interest) => {
    setState((prev) => {
      const interests = [...prev.form.interests];
      let nextInterest = null;
      if (interests.includes(interest)) {
        const index = interests.indexOf(interest);
        if (index > -1) {
          interests.splice(index, 1);
        }
      } else {
        interests.push(interest);
        // Add newly added interest as next interest slides to show
        nextInterest = interest;
      }

      return {
        ...prev,
        form: {
          ...prev.form,
          interests,
        },
        navigation: {
          ...prev.navigation,
          nextInterest,
        },
      };
    });
  };

  const pushInterestSlides = (
    interest: Interest,
    // set false, if you want only set this interest as preference. E.g. to set mode to CURRENT
    withRealInterest: boolean = true
  ) => {
    setState((prev) => {
      const interests = [...prev.form.interests];
      let nextInterest = null;
      let tempNextInterest = null;
      if (!interests.includes(interest)) {
        // Add newly added interest as next interest slides to show
        tempNextInterest = interest;
        if (withRealInterest) {
          nextInterest = interest;
          interests.push(interest);
        }
      }

      return {
        ...prev,
        form: {
          ...prev.form,
          [getHasChangedFromInterest(interest)]: true,
          interests,
        },
        navigation: {
          ...prev.navigation,
          nextInterest,
          tempNextInterest,
        },
      };
    });
  };

  const setInterests = (interests: Interest[]) => {
    setState((prev) => {
      return {
        ...prev,
        form: {
          ...prev.form,
          interests,
        },
      };
    });
  };

  const getCategory = (id?: number): SlideCategoryTypes | undefined => {
    return getCategoryFromAllSlides(id);
  };

  const getCurrentIdCategory = (): SlideCategoryTypes => {
    const currentSlide =
      state.navigation.slides[state.navigation.currentSlideIndex];
    const category = getCategory(currentSlide?.id ?? -1);
    if (category === undefined) {
      return SlideCategoryTypes.start;
    }
    return category;
  };

  const finishInitialization = () => {
    setState((prev) => {
      return {
        ...prev,
        navigation: {
          ...prev.navigation,
          initializeComplete: true,
        },
      };
    });
  };

  const setIsChangeGuaranteeSlideShowing = (newValue: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        navigation: {
          ...prev.navigation,
          isChangeGuaranteeSlideShowing: newValue,
        },
      };
    });
  };

  const setIsCancelShowing = (newValue: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        navigation: {
          ...prev.navigation,
          isCancelShowing: newValue,
        },
      };
    });
  };

  const setIsInterestShowing = (newValue: Interest | null) => {
    setState((prev) => {
      return {
        ...prev,
        navigation: {
          ...prev.navigation,
          isInterestShowing: newValue,
        },
      };
    });
  };

  const showLastSlide = () => {
    const newId = state.navigation.prevSlide;
    history.push("/slides/" + newId);
  };

  return {
    prevSlide: state.navigation.prevSlide,
    nextSlide: state.navigation.nextSlide,
    disableNext: state.navigation.disableNext,
    slideCount: state.navigation.slideCount,
    currentSlideIndex: state.navigation.currentSlideIndex,
    getCurrentIdCategory,
    getCategory,
    slides: state.navigation.slides,
    isChangeGuaranteeSlideShowing:
      state.navigation.isChangeGuaranteeSlideShowing,
    isCancelShowing: state.navigation.isCancelShowing,
    isInterestShowing: state.navigation.isInterestShowing,
    toggleInitialInterest,
    toggleArrayOfInterests,
    toggleInterest,
    pushInterestSlides,
    setInterests,
    setNextInterestSlides,
    finishInitialization,
    setIsChangeGuaranteeSlideShowing,
    setIsCancelShowing,
    setIsInterestShowing,
    showLastSlide,
  };
};
