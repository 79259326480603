import React from "react";
import styled from "styled-components";
import { Color } from "../../../../../constants/color";
import ContainerPrintSelector from "../../../../form/horizontalSelector/ContainerPrintSelector";
import { SubProducts } from "../../../../../types/table";
import {
  BubbleEntry,
  Interest,
  LabelValuePair,
  MonthlyPriceArray,
  Product,
  RecommendationMode,
} from "../../../../../types/application";
import SelectableRecommendationPrintColumn from "./SelectableRecommendationPrintColumn";
import CurrentRecommendationPrintColumn from "./CurrentRecommendationPrintColumn";
import InterestingRecommendationPrintColumn from "./InterestingRecommendationPrintColumn";
import RecommendationPrintColumnHeadline from "../RecommendationPrintColumnHeadline";

interface RecommendationPrintColumnProps {
  className?: string;
  mode: RecommendationMode;
  type: Interest;
  product: Product;
  priceArray: MonthlyPriceArray;
  discountArray?: MonthlyPriceArray;
  currentPrice: number;
  subProducts: SubProducts;
  bubbleEntries: BubbleEntry[];
  columnPriceObjects: LabelValuePair[];

  dataVolume?: number;
  extraDataVolume?: number;
}

const RecommendationPrintColumn: React.FC<RecommendationPrintColumnProps> = ({
  className,
  children,
  mode,
  type,
  product,
  priceArray,
  discountArray,
  currentPrice,
  subProducts,
  bubbleEntries,
  columnPriceObjects,

  dataVolume,
  extraDataVolume,
}) => {
  return (
    <div className={className}>
      <div className={"recommendation-column"}>
        <RecommendationPrintColumnHeadline type={type} mode={mode} />
        {product !== undefined && (
          <ContainerPrintSelector
            borderColor={getBorderColor(mode)}
            marginBottom={"2px"}
            product={product}
            dataVolume={dataVolume}
            extraDataVolume={extraDataVolume}
          />
        )}
        {mode === RecommendationMode.SELECTABLE && (
          <SelectableRecommendationPrintColumn
            type={type}
            subProducts={subProducts}
            bubbleEntries={bubbleEntries}
            columnPriceObjects={columnPriceObjects}
            priceArray={priceArray}
            discountArray={discountArray}
          />
        )}
        {mode === RecommendationMode.CURRENT && (
          <CurrentRecommendationPrintColumn currentPrice={currentPrice} />
        )}
        {mode === RecommendationMode.INTERESTING && (
          <InterestingRecommendationPrintColumn priceArray={priceArray} />
        )}
        {children}
      </div>
    </div>
  );
};

const getBorderColor = (mode: RecommendationMode) => {
  switch (mode) {
    case RecommendationMode.CURRENT:
      return Color.BLACK;
    case RecommendationMode.INTERESTING:
      return Color.GREY_LIGHT;
    case RecommendationMode.SELECTABLE:
      return Color.RED;
    default:
      return Color.WHITE;
  }
};

export default styled(RecommendationPrintColumn)`
  width: 30%;
  display: table-cell;
  vertical-align: top;
  background: ${Color.WHITE};
  .recommendation-column {
    width: 100%;
    max-width: 900px;
    display: inline-block;
  }
`;
