import React from "react";
import styled from "styled-components";
import JustifiedColumnsContainer from "../../grid/JustifiedColumnsContainer";
import TextSelectorCarousel from "./TextSelectorCarousel";
import { Color } from "../../../constants/color";
import GridColumn from "../../grid/GridColumn";
import { FormOption } from "../../../state/definitions/FormConfigState";

interface LabelledTextSelectorCarouselProps {
  className?: string;
  onChange: (value: number) => void;
  valueId?: number;
  items: FormOption[];
  label: string;
}

const LabelledTextSelectorCarousel: React.FC<LabelledTextSelectorCarouselProps> = ({
  className,
  valueId,
  items,
  onChange,
  label,
}) => {
  return (
    <div className={className}>
      <JustifiedColumnsContainer alignItems={"center"}>
        <GridColumn>
          <span className={"provider-text"}>{label}</span>
        </GridColumn>
        <GridColumn colCount={2} minWidth={"200px"} maxWidth={"200px"}>
          <TextSelectorCarousel
            items={items}
            valueId={valueId}
            onChange={onChange}
            smallVersion
          />
        </GridColumn>
      </JustifiedColumnsContainer>
    </div>
  );
};

export default styled(LabelledTextSelectorCarousel)`
  padding: 5px;
  .provider-text {
    color: ${Color.WHITE};
    font-size: 25px;
    vertical-align: middle;
  }
  .text-selector {
    background: ${Color.RED};
    button {
      background: ${Color.RED};
    }
  }
`;
