import * as React from "react";

function SvgBoosting(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;

  return (
    <svg
      id="boosting_svg__e509805b-0d43-4acf-b986-6bedd2453e20"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.boosting_svg__st0{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        id="boosting_svg__e44fd1fd-0344-4bd9-b46b-1caf26a6ffc4"
        className="boosting_svg__st0"
        d="M20 67.1C55.1 32 109.6 25.5 152 51.3"
      />
      <path
        id="boosting_svg___x39_116e85a-964c-48d2-a1cf-d8147c21d34d"
        className="boosting_svg__st0"
        d="M42 90.1c23.2-23.2 58.5-29.1 88-14.7"
      />
      <path
        id="boosting_svg___x32_293bf27-7862-4251-97cf-a2089607855b"
        className="boosting_svg__st0"
        d="M64.6 112.7c10.6-10.6 25.7-15.2 40.4-12.2"
      />
      <path fill="none" d="M0 0h192v192H0z" />
      <path
        id="boosting_svg___x35_94d1d40-70cf-410b-8159-c173b6a6b7eb"
        className="boosting_svg__st0"
        d="M102.3 145.1c-4 3.9-10.5 3.9-14.4-.1-3.9-4-3.9-10.5.1-14.4 4-3.9 10.5-3.9 14.4.1l47-46.9"
      />
    </svg>
  );
}

export default SvgBoosting;
