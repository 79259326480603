import React from "react";
import styled from "styled-components";
import { getImageSet } from "../../../images";
import JustifiedColumnsContainer from "../../grid/JustifiedColumnsContainer";
import GridColumn from "../../grid/GridColumn";
import CheckCircleText from "./CheckCircleText";
import ProgressiveImage from "../../../images/ProgressiveImage";
import { StyledComponentProps } from "../../../types/default";

const GuaranteeContent: React.FC<StyledComponentProps> = ({ className }) => {
  return (
    <div className={className}>
      <ProgressiveImage
        src={getImageSet(999).image}
        preview={getImageSet(999).preview}
        render={(src, style) => (
          <img
            className={"change-guarantee-page-image"}
            src={src}
            style={style}
            alt="Wechselservice"
          />
        )}
      />
      <JustifiedColumnsContainer>
        <GridColumn colCount={3} minWidth={"150px"}>
          <CheckCircleText label={"Wir leiten die Kündigung für Dich weiter"} />
        </GridColumn>
        <GridColumn colCount={3} minWidth={"150px"}>
          <CheckCircleText label={"Wechselservice"} />
        </GridColumn>
        <GridColumn colCount={3} minWidth={"150px"}>
          <CheckCircleText label={"Rufnummern-Mitnahme"} />
        </GridColumn>
      </JustifiedColumnsContainer>
    </div>
  );
};

export default styled(GuaranteeContent)`
  .change-guarantee-page-image {
    width: 100%;
    padding: 0 20px;
  }
`;
