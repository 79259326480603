import React from "react";
import styled from "styled-components";
import ContainerSelector, { ContainerSelectorProps } from "./ContainerSelector";
import ContainerSelectorItem from "./ContainerSelectorItem";
import ProductContainerSelectorTitle from "./ProductContainerSelectorTitle";
import ProductContainerSelectorBody from "./ProductContainerSelectorBody";
import { Product } from "../../../types/application";

interface ProductContainerSelectorProps extends ContainerSelectorProps {
  className?: string;
  currentProduct?: Product;
  dataVolume?: number;
  setDataVolume?: (value: number) => void;
  dataVolumeSteps?: number;
  dataVolumeMax?: number;
  extraDataVolume?: number;
}

const ProductContainerSelector: React.FC<ProductContainerSelectorProps> = (
  props
) => {
  const {
    currentProduct,
    dataVolume,
    setDataVolume,
    dataVolumeSteps,
    dataVolumeMax,
    extraDataVolume,
    currentIndex,
    ...restProps
  } = props;
  return (
    <ContainerSelector currentIndex={currentIndex} {...restProps}>
      {currentProduct && (
        <ContainerSelectorItem
          titleElement={
            <ProductContainerSelectorTitle title={currentProduct.title} />
          }
          bodyElement={
            <>
              {currentIndex !== 0 ? (
                <ProductContainerSelectorBody
                  body={currentProduct.body}
                  dataVolume={dataVolume}
                  setDataVolume={setDataVolume}
                  extraDataVolume={extraDataVolume}
                  dataVolumeSteps={dataVolumeSteps}
                  dataVolumeMax={dataVolumeMax}
                />
              ) : (
                <div>
                  <p />
                </div>
              )}
            </>
          }
        />
      )}
    </ContainerSelector>
  );
};

export default styled(ProductContainerSelector)``;
