import * as React from "react";
import CountDisplayButton from "./CountDisplayButton";
import { getZeroIfNotExist } from "../../../helper/ifNotExist";
import { SubProducts } from "../../../types/table";
import { ENABLE_RECOMMENDATION_PASSES } from "../../../constants/default";

export interface AFOTabButtonsProps {
  subProducts: SubProducts;
  onChange: (value: boolean, tabId: number) => void;
  value: boolean;
}

const AFOTabButtons: React.FC<AFOTabButtonsProps> = ({
  subProducts,
  onChange,
  value,
}) => {
  const pricePasses = getZeroIfNotExist(subProducts?.priceInformation.list[0]);
  const priceAdditional = getZeroIfNotExist(
    subProducts?.priceInformation.list[1]
  );
  const priceFurther = getZeroIfNotExist(subProducts?.priceInformation.list[2]);
  const priceSingle = getZeroIfNotExist(subProducts?.priceInformation.list[3]);

  return (
    <>
      <CountDisplayButton
        onChange={(newValue) => onChange(newValue, 0)}
        value={value}
        valueText={priceAdditional.toFixed(2) + " €"}
        label={subProducts?.additionalTable.title}
      />
      <CountDisplayButton
        onChange={(newValue) => onChange(newValue, 1)}
        value={value}
        valueText={priceFurther.toFixed(2) + " €"}
        label={subProducts?.furtherProductsTable.title}
      />
      <CountDisplayButton
        onChange={(newValue) => onChange(newValue, 2)}
        value={value}
        valueText={priceSingle.toFixed(2) + " €"}
        label={subProducts?.singlePaymentTable.title}
      />
      {ENABLE_RECOMMENDATION_PASSES && subProducts?.passes.title && (
        <CountDisplayButton
          onChange={(newValue) => onChange(newValue, 3)}
          value={value}
          valueText={pricePasses.toFixed(2) + " €"}
          label={subProducts?.passes.title}
        />
      )}
    </>
  );
};

export default AFOTabButtons;
