import * as React from "react";

function SvgShopping(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      version="1.1"
      id="shopping_svg___x33_c54d754-f349-44b2-8061-d7aa617e1d45"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style type="text/css">
        {`.shopping-st0{fill:none;stroke: ${color};stroke-width:8;stroke-linecap:round;stroke-linejoin:round;}`}
      </style>
      <g>
        <circle className="shopping-st0" cx="88" cy="152" r="12" />
        <circle className="shopping-st0" cx="136" cy="152" r="12" />
        <path className="shopping-st0" d="M20 28h26l29 88h74l23-72H51.75" />
      </g>
    </svg>
  );
}

export default SvgShopping;
