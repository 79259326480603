import * as React from "react";

function SvgMobileWorkingGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#4a4d4e" } = props;
  return (
    <svg
      id="mobileWorking_grey_svg__ICON"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.mobileWorking_grey_svg__st1{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        id="mobileWorking_grey_svg__b523b2c1-0724-472c-9b76-6e13cd41f067_2_"
        fill="none"
        stroke={color}
        strokeWidth={8.073}
        strokeLinejoin="round"
        d="M95 148h77.8l-16.1-37"
      />
      <path
        className="mobileWorking_grey_svg__st1"
        d="M87 130h42.8M43.6 73V46.7c0-5.7 4.7-10.4 10.4-10.4h97.1c5.7 0 10.4 4.7 10.4 10.4v55.5c0 5.7-4.7 10.4-10.4 10.4H73"
      />
      <circle
        id="mobileWorking_grey_svg___x35_e357bf8-a6e2-45a2-a486-af734ac869d7"
        className="mobileWorking_grey_svg__st1"
        cx={59.9}
        cy={92}
        r={24}
      />
      <path
        id="mobileWorking_grey_svg__b7cc56bf-b53c-41f7-a72a-48c3b2e59e4b"
        className="mobileWorking_grey_svg__st1"
        d="M100 163.7c-4.4-30.8-22.4-45.1-40.3-45.1S23.4 132.8 19 163.7"
      />
    </svg>
  );
}

export default SvgMobileWorkingGrey;
