import React, { useState } from "react";
import styled from "styled-components";
import PriceSummary from "../PriceSummary";
import { PriceTextTile } from "../PriceTextTile";
import AdvantageModal from "../../advantageModal/AdvantageModal";
import TabsModal from "../../../modal/TabsModal";
import { RecommendationTableState } from "../../../../hooks/useRecommendationHelper/useRecommendationTablesOfferState";
import { mapProductOptionToValue } from "../../../../helper/mapOptionToValue";
import AFOTabButtons from "../AFOTabButtons";
import AFOTabs from "../AFOTabs";
import { SubProducts } from "../../../../types/table";
import { Interest, MonthlyPriceArray } from "../../../../types/application";
import { ENABLE_RECOMMENDATION_PASSES } from "../../../../constants/default";
import { useOfferState } from "../../../../hooks/useOffer";
import PriceDisplayList from "../PriceDisplayList";

interface SelectableRecommendationColumnProps {
  className?: string;
  mainColumn?: boolean;
  type: Interest;
  currentProductIndex: number;
  productPrice?: MonthlyPriceArray;
  subProducts?: SubProducts;
  tableStateObject?: RecommendationTableState;

  monthlyPriceArray?: MonthlyPriceArray;
  oncePrice?: number;
}

const SelectableRecommendationColumn: React.FC<SelectableRecommendationColumnProps> =
  ({
    className,
    mainColumn = false,
    type,
    productPrice,
    subProducts,
    tableStateObject,
    currentProductIndex,
    monthlyPriceArray,
    oncePrice,
  }) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const availableTabNames: string[] = [];
    if (!!subProducts) {
      availableTabNames.push(subProducts.additionalTable.title);
      availableTabNames.push(subProducts.furtherProductsTable.title);
      availableTabNames.push(subProducts.singlePaymentTable.title);

      if (ENABLE_RECOMMENDATION_PASSES) {
        availableTabNames.push(subProducts.passes.title);
      }
    }
    const [selectedGigaKombi] = useOfferState("selectedGigaKombi");
    const [gigaKombiInterest] = useOfferState("gigaKombiInterest");
    let gigaKombiRebate = 0;
    let gigaDiscountData = undefined;
    if (
      !!subProducts &&
      !!productPrice &&
      mainColumn &&
      gigaKombiInterest === type &&
      selectedGigaKombi
    ) {
      const gigaKombiId =
        subProducts.additionalTable.additionalData.gigaKombiId;
      const gigaKombiRebateId =
        subProducts.additionalTable.data[gigaKombiId]?.labelId;
      gigaKombiRebate =
        subProducts.additionalTable.data[gigaKombiId]?.prices[
          gigaKombiRebateId
        ] ?? 0;
      gigaDiscountData = Object.assign({}, productPrice, {
        0: productPrice[0] + gigaKombiRebate,
      });
    }

    return (
      <div className={className}>
        {!!productPrice && (
          <PriceTextTile>
            <PriceDisplayList
              data={productPrice}
              discountData={gigaDiscountData}
            />
          </PriceTextTile>
        )}
        {subProducts && subProducts.advantages && (
          <AdvantageModal
            ids={
              mapProductOptionToValue(subProducts.advantages)[
                currentProductIndex
              ]
            }
          />
        )}
        {subProducts && (
          <TabsModal
            tabNames={availableTabNames}
            currentTabIndex={currentTabIndex}
            setCurrentTabIndex={setCurrentTabIndex}
            openButton={(onChange, value) => (
              <AFOTabButtons
                subProducts={subProducts}
                onChange={onChange}
                value={value}
              />
            )}
          >
            {tableStateObject && (
              <AFOTabs
                subProducts={subProducts}
                tableStateObject={tableStateObject}
                currentTabIndex={currentTabIndex}
                mainProductPrice={productPrice ? productPrice[0] : undefined}
              />
            )}
          </TabsModal>
        )}
        {(monthlyPriceArray || oncePrice) && (
          <PriceSummary
            monthlyPriceArray={monthlyPriceArray ? monthlyPriceArray : {}}
            oncePrice={oncePrice ? oncePrice : 0}
          />
        )}
      </div>
    );
  };

export default styled(SelectableRecommendationColumn)``;
