import React, { CSSProperties } from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";

export interface TileButtonProps extends React.Attributes {
  className?: string;
  onChange?: (value: boolean) => void;
  value?: boolean;
  main?: boolean;
  shadowed?: boolean;
  color?: string;
  height?: string;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  padding?: string;
  maxWidth?: string;
  marginTop?: number;
  disableOnFalseValue?: boolean;
  style?: CSSProperties;
}

const TileButton: React.FC<TileButtonProps> = ({
  className,
  children,
  onChange,
  value = false,
  disableOnFalseValue = false,
  startIcon,
  endIcon,
  style,
}) => {
  const onClick = (_: React.MouseEvent) => {
    if (
      onChange !== undefined &&
      ((disableOnFalseValue && value) || !disableOnFalseValue)
    ) {
      onChange(!value);
    }
  };

  return (
    <div className={className + " tile-button"} onClick={onClick} style={style}>
      {startIcon && <div className={"tile-button-start-icon"}>{startIcon}</div>}
      <div className={"tile-button-content-container"}>
        <span className={"tile-button-content"}>{children}</span>
      </div>
      {endIcon && <div className={"tile-button-end-icon"}>{endIcon}</div>}
    </div>
  );
};

const getBackgroundColor = (props: TileButtonProps): string => {
  if (props.color) {
    return props.color;
  }
  if (props.main) {
    return Color.GREY2;
  }
  if (props.value) {
    return Color.RED;
  }
  return Color.GREY_LIGHT;
};
const getCursor = (props: TileButtonProps): string => {
  if (props.disableOnFalseValue && !props.value) {
    return "not-allowed";
  }
  if (props.onChange) {
    return "pointer";
  }
  return "default";
};

export default styled(TileButton)`
  margin-top: ${({ marginTop }) => marginTop ?? 2}px;
  height: ${({ height }) => (height ? height : "initial")};
  max-height: 120px;
  background-color: ${getBackgroundColor};
  padding: ${({ padding }) => padding ?? "20px 5px"};
  text-align: center;
  user-select: none;
  cursor: ${getCursor};
  box-shadow: ${({ shadowed }) =>
      shadowed ? "1px 1px 3px 0" : "0px 0px 0px 0"}
    ${Color.BLACK};

  .tile-button-content-container {
    height: 100%;
    display: inline-block;
    vertical-align: middle;

    span {
      width: 100%;
      height: 100%;
      color: ${Color.WHITE};
      max-width: ${({ maxWidth }) => maxWidth ?? "400px"};
      display: inline-block;
      vertical-align: middle;
      line-height: 20px;
      font-size: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .tile-button-start-icon {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    margin-right: 5px;
  }
  .tile-button-end-icon {
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    margin-left: 5px;
  }
`;
