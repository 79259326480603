import { NewOfferState } from "../../state/definitions/NewOfferState";
import {
  ProductOption,
  RecommendationConfig,
} from "../../state/definitions/RecommendationState";
import { createNewNewOfferState } from "./createNewNewOfferState";
import {
  getInterestFromInterestObjectKey,
  getRecommendationTable,
} from "../recommendation/mapping";
import { Interest, WithsObject } from "../../types/application";

export function presetNewOfferStateFromRecommendation(
  recommendationConfig: RecommendationConfig,
  prevNewOfferState: NewOfferState,
  withsObject: WithsObject
): NewOfferState {
  let newNewOfferState = prevNewOfferState;

  const types = Object.keys(withsObject) as Array<keyof WithsObject>;
  Object.values(withsObject).forEach((withProductLine, i) => {
    if (withProductLine) {
      const type: Interest = getInterestFromInterestObjectKey(types[i]);
      const allOfferEntryIds = Object.keys(newNewOfferState);

      // delete all other offer-Entries with the same type like the new entry
      Object.values(newNewOfferState).forEach((v, i) => {
        if (v.type === type) {
          delete newNewOfferState[allOfferEntryIds[i]];
        }
      });

      const productId: ProductOption<number> | undefined =
        recommendationConfig[type].productId[
          recommendationConfig[type].selectedProduct
        ];

      //create and add the new entry
      const newNewOfferEntry = createNewNewOfferState(
        recommendationConfig[type].selectedProduct,
        productId?.productId ?? -1,
        {
          interest: type,
          mainRow: true,
          overwriteObj: {
            furtherLabelIds:
              recommendationConfig[getRecommendationTable(type)]
                .furtherProductId,
            furtherCountIds:
              recommendationConfig[getRecommendationTable(type)]
                .furtherProductCountId,
            passesSelected: recommendationConfig[type].passPriceSelected.reduce(
              (arr, v) => {
                if (v.label) {
                  arr.push(v.productId);
                }
                return arr;
              },
              [] as number[]
            ),
          },
        }
      );
      newNewOfferState[newNewOfferEntry.rowId] = newNewOfferEntry;
    }
  });

  return newNewOfferState;
}
