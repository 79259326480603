import { useState } from "react";
import { DisplayErrorMessages } from "../constants/DisplayErrorMessages";
import { LoadableResources, loadConfigData } from "../helper/loadConfigData";
import useAppReload from "./useAppHelper/useAppReload";
import { ErrorCodes } from "../constants/ErrorCodes";
import useAuth from "./useAuth";

const testSubmitOfferResponse = (
  unsureData: any
): [SubmitOfferStatusCheckResponse, boolean] => {
  return [unsureData, true];
};

export interface SubmitOfferStatusCheckRequestData {
  docId: string;
}

interface WaitResponse {
  finished: false;
  wait: number;
  timestamp: number;
  docId: string;
}

interface FinishResponse {
  finished: true;
  documentUrl: string;
  timestamp: number;
  docId: string;
}

type SubmitOfferStatusCheckResponse = WaitResponse | FinishResponse;

export const useOfferStatusCheck = () => {
  //   const [state, setState] = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [statusResponse, setStatusResponse] =
    useState<SubmitOfferStatusCheckResponse | null>(null);
  const [errorCode, setErrorCode] = useState<ErrorCodes>(ErrorCodes.nothing);
  const [errorMessage, setErrorMessage] = useState<DisplayErrorMessages>(
    DisplayErrorMessages.nothing
  );
  const [, requestAppReload] = useAppReload();
  const { logout } = useAuth();

  const checkOfferStatus = async (docId: string) => {
    try {
      if (!loading) {
        setLoading(true);

        const result = await loadConfigData(
          testSubmitOfferResponse,
          LoadableResources.CHECK_OFFER_STATUS,
          requestAppReload,
          [docId]
        )();

        // Important: Finish loading before setting result, so that based on the result another request can be
        // triggered without beeing canceled because loading is still true
        setLoading(false);
        setStatusResponse(result);
      }
    } catch (e) {
      if (e.code && e.code === 401) {
        logout();
      }
      setLoading(false);
      setErrorMessage(DisplayErrorMessages.technicalProblem);
      setErrorCode(ErrorCodes.falseServerUpdateCheck);
    }
  };

  return {
    checkOfferStatus,
    offerStatusCheckloading: loading,
    offerStatusCheckResponse: statusResponse,
    offerStatusCheckErrorMessage: errorMessage,
    offerStatusCheckErrorCode: errorCode,
  };
};
