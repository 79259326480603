import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import Icons from "../../icons/Icons";
import SVGIcon from "../svgIcon/SVGIcon";
import ExternalLink from "./ExternalLink";
import { ZIndex } from "../../constants/zIndex";
import { StyledComponentProps } from "../../types/default";

export interface IconLinkProps extends StyledComponentProps {
  to: string;
  tooltip?: string;
  backgroundColor?: string;
  linkClass?: string;
}

const IconLink: React.FC<IconLinkProps> = ({
  className,
  to,
  tooltip,
  linkClass,
}) => {
  return (
    <ExternalLink to={to} linkClass={linkClass}>
      <div className={`icon-link-container ${className}`}>
        <SVGIcon src={Icons.info} alt={"info"} size={40} />
        {tooltip && <div className={"tooltip-text"}>{tooltip}</div>}
      </div>
    </ExternalLink>
  );
};

export default styled(IconLink)`
  width: 50px;
  height: 50px;
  margin: 8px;
  line-height: 50px;
  padding: 0;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : Color.RED};

  user-select: none;
  cursor: pointer;

  img,
  svg {
    display: inline-block;
    vertical-align: top;
    margin: 5px;
  }

  .tooltip-text {
    visibility: hidden;
    background-color: ${Color.GREY_LIGHT};
    color: #fff;
    text-align: center;
    padding: 5px 8px;
    //border-radius: 6px;
    position: absolute;
    z-index: ${ZIndex.FRONT_1};
    left: 55px;
    top: 5px;
    height: 40px;
    line-height: 30px;
  }
  .tooltip-text::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border: 5px solid;
    border-color: transparent ${Color.GREY_LIGHT} transparent transparent;
  }
  &:hover .tooltip-text {
    visibility: visible;
  }
`;
