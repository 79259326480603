import * as React from "react";

function SvgAppsColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="apps_color_svg___x32_40c3efa-8667-4e3e-83fb-73ecb05dc72f"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".apps_color_svg__st0,.apps_color_svg__st1{fill:none;stroke:#e60000;stroke-width:8;stroke-linecap:round}.apps_color_svg__st0{stroke-miterlimit:10}.apps_color_svg__st1{stroke-linejoin:round}"
        }
      </style>
      <path
        id="apps_color_svg___x32_5d627b5-ca44-46b9-b8ce-14a85cf4b810"
        className="apps_color_svg__st0"
        d="M81.6 148H46.4c-2.3.1-4.4-1-5.6-2.9-1.1-2-1-4.4.1-6.3l17.6-33.3c1.4-2.9 4.9-4.2 7.8-2.9 1.3.6 2.3 1.6 2.9 2.9L87 138.8c1.2 1.9 1.2 4.3.1 6.3-1.1 1.9-3.2 3-5.5 2.9z"
      />
      <path
        id="apps_color_svg___x30_f57ca30-3d02-444c-a84b-07d79f94a928"
        className="apps_color_svg__st0"
        d="M64 84c-11 0-20-9-20-20s9-20 20-20 20 9 20 20-9 20-20 20z"
      />
      <path
        className="apps_color_svg__st1"
        d="M128 101l23.5 18.8-9.4 28.2h-28.2l-9.4-28.2z"
      />
      <path fill="none" d="M0 0h192v192H0z" />
      <path
        className="apps_color_svg__st0"
        d="M116.9 44h22.2c4.9 0 8.9 4 8.9 8.9v22.2c0 4.9-4 8.9-8.9 8.9h-22.2c-4.9 0-8.9-4-8.9-8.9V52.9c0-4.9 4-8.9 8.9-8.9z"
      />
      <path
        className="apps_color_svg__st1"
        d="M40 20h112c11 0 20 9 20 20v112c0 11-9 20-20 20H40c-11 0-20-9-20-20V40c0-11 9-20 20-20z"
      />
    </svg>
  );
}

export default SvgAppsColor;
