import {
  OfferOption,
  ProductOption,
} from "../state/definitions/RecommendationState";

export function mapOfferOptionToValue<T>(formOptions: OfferOption<T>[]): T[] {
  return formOptions.map((v, i) => v.label);
}

export function mapProductOptionToValue<T>(
  formOptions: ProductOption<T>[]
): T[] {
  return formOptions.map((v, i) => v.label);
}