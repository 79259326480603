import { MONTH_MAX_LENGTH } from "../constants/default";
import { MonthlyPriceArray } from "../types/application";

function getAbsoluteMonthsLength(value: number) {
  return value ? Math.max(0, value) : 0;
}

export default function getEffectivePrice(
  priceArray: MonthlyPriceArray,
  oncePrice: number
): number {
  const months: number[] = Object.keys(priceArray).map(Number);
  const mPrices: number[] = Object.values(priceArray);
  const mWholePrice = mPrices.reduce((whole, v, i) => {
    // this operation is need to get the length and not the point of beginning
    const absoluteCurrentMonthLength = getAbsoluteMonthsLength(months[i]);
    const absoluteNextMonthLength = getAbsoluteMonthsLength(months[i + 1]);

    // if there is a next month, use them instead of the last possible month
    const endDuration = absoluteNextMonthLength
      ? absoluteNextMonthLength
      : MONTH_MAX_LENGTH;

    const length = endDuration - absoluteCurrentMonthLength;
    return whole + v * length;
  }, 0);
  return (mWholePrice + oncePrice) / MONTH_MAX_LENGTH;
}
