import Gen5 from "./svgComponents/5G";
import wholeProfile from "./svgComponents/Accelerometer";
import profile from "./svgComponents/AccelerometerHalf";
import internet from "./svgComponents/Broadband";
import cable from "./svgComponents/Cable";
import chat from "./svgComponents/Chat";
import chatAndTalk from "./svgComponents/ChatAndTalk";
import close from "./svgComponents/Close";
import bigClose from "./svgComponents/BigClose";
import cooperation from "./svgComponents/Cooperation";
import currentProgram from "./svgComponents/CurrentProgram";
import download from "./svgComponents/Download";
import eu from "./svgComponents/Eu";
import euro from "./svgComponents/Euro";
import family from "./svgComponents/Family";
import landline from "./svgComponents/FixedLine";
import gaming from "./svgComponents/Gaming";
import germany from "./svgComponents/Germany";
import gpsWave from "./svgComponents/GpsWave";
import home from "./svgComponents/Home";
import info from "./svgComponents/Info";
import iot from "./svgComponents/Iot";
import kid from "./svgComponents/Kid";
import laptop from "./svgComponents/Laptop";
import manager from "./svgComponents/Manager";
import mediaLibrary from "./svgComponents/MediaLibrary";
import mobile from "./svgComponents/Mobile";
import mobileAndTablet from "./svgComponents/MobileAndTablet";
import person from "./svgComponents/Person";
import plus from "./svgComponents/Plus";
import privateHd from "./svgComponents/PrivateHd";
import recordings from "./svgComponents/Recordings";
import satellite from "./svgComponents/Satellite";
import sky from "./svgComponents/Sky";
import smartHome from "./svgComponents/SmartHome";
import smartwatch from "./svgComponents/Smartwatch";
import streaming from "./svgComponents/Streaming";
import support from "./svgComponents/Support";
import guarantee from "./svgComponents/Sync";
import tablet from "./svgComponents/Tablet";
import tv from "./svgComponents/Tv";
import under28 from "./svgComponents/Under28";
import unlimited from "./svgComponents/Unlimited";
import upgrade from "./svgComponents/Upgrade";
import video from "./svgComponents/Video";
import vod from "./svgComponents/Vod";
import wifiCalling from "./svgComponents/WifiCalling";
import world from "./svgComponents/World";
import social from "./svgComponents/Social";
import pc from "./svgComponents/Pc";
import dvbt2 from "./svgComponents/Dvbt2";
import ipTv from "./svgComponents/IpTv";
import payTv from "./svgComponents/PayTv";
import music from "./svgComponents/Music";
import plane from "./svgComponents/Plane";
import amazonPrime from "./svgComponents/AmazonPrime";
import ard from "./svgComponents/Ard";
import dazn from "./svgComponents/Dazn";
import netflix from "./svgComponents/Netflix";
import pro7 from "./svgComponents/Pro7";
import rtl from "./svgComponents/Rtl";
import youtube from "./svgComponents/Youtube";
import zdf from "./svgComponents/Zdf";
import expandScreen from "./svgComponents/ExpandScreen";
import chevron from "./svgComponents/Chevron";
import hook from "./svgComponents/Hook";
import print from "./svgComponents/Print";
import idea from "./svgComponents/Idea";
import shopping from "./svgComponents/Shopping";
import users from "./svgComponents/Users";
import overallConnection from "./svgComponents/OverallConnection";
import ownCallNumber from "./svgComponents/OwnCallNumber";
import premiumSmartWatch from "./svgComponents/PremiumSmartWatch";
import superWifi from "./svgComponents/SuperWifi";
import superWifi2 from "./svgComponents/SuperWifi2";

import Gen4 from "./svgComponents/4G";
import calendar30 from "./svgComponents/Calendar30";
import cableWaves from "./svgComponents/CableWaves";
import gigaTvMobile from "./svgComponents/GigaTvMobile";
import passes from "./svgComponents/Passes";
import privacy from "./svgComponents/Privacy";
import router from "./svgComponents/Router";
import securityPc from "./svgComponents/SecurityPc";
import movie from "./svgComponents/Movie";
import winner from "./svgComponents/Winner";
import tvChannels from "./svgComponents/TvChannels";
import tvLove from "./svgComponents/TvLove";
import upload from "./svgComponents/Upload";
import tvRefresh from "./svgComponents/TvRefresh";

import childLocation from "./svgComponents/ChildLocation";
import gigaTvApp from "./svgComponents/GigaTvApp";
import iotLabelSim from "./svgComponents_color/IotLabelSim";
import iotSim from "./svgComponents/IotSim";
import location from "./svgComponents/Location";
import securityCam from "./svgComponents/SecurityCam";

import mobileSecurity from "./svgComponents/MobileSecurity";
import ping from "./svgComponents/Ping";
import moreMoney from "./svgComponents/MoreMoney";
import money from "./svgComponents/Money";
import backspace from "./svgComponents/Backspace";
import windowedApps from "./svgComponents/WindowedApps";
import sim from "./svgComponents/Sim";
import boosting from "./svgComponents/Boosting";
import calculator from "./svgComponents/Calculator";
import tvTv from "./svgComponents/TvTv";
import cloudDownload from "./svgComponents/CloudDownload";
import popcorn from "./svgComponents/Popcorn";
import devices from "./svgComponents/Devices";
import entertainment from "./svgComponents/Entertainment";
import firstPrice from "./svgComponents/FirstPrice";
import buddies from "./svgComponents/Buddies";
import rightArrow from "./svgComponents/RightArrow";
import cyberSecurity from "./svgComponents/CyberSecurity";
import mobileEuroFlat from "./svgComponents/MobileEuroFlat";
import edit from "./svgComponents/Edit";
import gigaGreen from "./svgComponents/GigaGreen";

import gigaKombiOverview from "./svgComponents/GigaKombiOverview";
import gigaKombi101 from "./svgComponents/Gigakombi101";
import gigaKombi110 from "./svgComponents/Gigakombi110";
import gigaKombi011 from "./svgComponents/Gigakombi011";
import gigaKombi111 from "./svgComponents/Gigakombi111";

import ColorIcons from "./ColorIcons";
import { FormConfigLabelIds } from "../data/definitions/FormConfigLabelIds";
import { SVGComponent } from "../types/default";
import GreyIcons from "./GreyIcons";

const placeholder = guarantee;

const Icons = {
  start: home,
  profile,
  mobile,
  internet,
  landline,
  tv,
  offer: euro,
  guarantee,
  plus,
  info,
  euro,
  family,
  chat,
  video,
  gaming,
  kid,
  person,
  users,
  iot,
  tablet,
  smartwatch,
  under28,
  laptop,
  cooperation,
  upgrade,
  unlimited,
  manager,
  smartHome,
  germany,
  eu,
  world,
  home,
  cable,
  satellite,
  streaming,
  mediaLibrary,
  vod,
  currentProgram,
  privateHd,
  recordings,
  sky,
  support,
  mobileAndTablet,
  chatAndTalk,
  gpsWave,
  wifiCalling,
  Gen5,
  close,
  bigClose,
  wholeProfile,
  download,
  social,
  pc,
  dvbt2,
  ipTV: ipTv,
  payTV: payTv,
  music,
  plane,
  amazonPrime,
  dazn,
  netflix,
  youtube,
  ard,
  pro7,
  rtl,
  zdf,
  expandScreen,
  Gen4,
  calendar30,
  cableWaves,
  gigaTVMobile: gigaTvMobile,
  passes,
  privacy,
  router,
  securityPc,
  movie,
  winner,
  tvChannels,
  tvLove,
  upload,
  tvRefresh,
  placeholder,
  mobileSecurity,
  ping,
  moreMoney,
  money,
  backspace,
  accelerometerHalf: profile,
  windowedApps,
  sim,
  boosting,
  calculator,
  tvTV: tvTv,
  cloudDownload,
  popcorn,
  devices,
  entertainment,
  firstPrice,
  buddies,
  rightArrow,
  cyberSecurity,
  mobileEuroFlat,
  childLocation,
  gigaTvApp,
  iotLabelSim,
  iotSim,
  location,
  securityCam,
  chevron,
  hook,
  print,
  idea,
  shopping,
  overallConnection,
  ownCallNumber,
  premiumSmartWatch,
  superWifi,
  superWifi2,
  edit,
  gigaGreen,
  gigaKombiOverview,
  gigaKombi101,
  gigaKombi110,
  gigaKombi011,
  gigaKombi111,
};

interface IconMapInterface {
  [id: number]: SVGComponent[];
}

const IconMap: IconMapInterface = {
  [FormConfigLabelIds.MOBILE]: [Icons.mobile],
  [FormConfigLabelIds.IOT]: [Icons.iotSim],
  [FormConfigLabelIds.FIXED]: [Icons.internet],
  [FormConfigLabelIds.TV]: [Icons.tv],
  [FormConfigLabelIds.MOBILE_COOPERATED]: [Icons.cooperation],
  213: [Icons.mobile],
  214: [Icons.profile],
  215: [Icons.euro],
  209: [Icons.under28],
  210: [Icons.social],
  211: [Icons.laptop],
  2013: [Icons.laptop],
  216: [Icons.social],
  217: [Icons.music],
  218: [Icons.chat],
  219: [Icons.video],
  220: [Icons.gaming],
  221: [Icons.buddies],
  222: [Icons.kid],
  223: [Icons.tablet],
  [FormConfigLabelIds.IOT_SMARTWATCH]: [Icons.iotSim],
  [FormConfigLabelIds.IOT_TRACKER]: [Icons.gpsWave],
  [FormConfigLabelIds.IOT_CAMERA]: [Icons.securityCam],
  [FormConfigLabelIds.IOT_FOR_ME]: [Icons.person],
  [FormConfigLabelIds.IOT_FOR_OTHER]: [Icons.users],
  [FormConfigLabelIds.IOT_FOR_KID]: [Icons.kid],
  [FormConfigLabelIds.IOT_THING]: [Icons.gpsWave],
  [FormConfigLabelIds.IOT_FUTURE_PRODUCTS]: [Icons.idea],
  301: [Icons.profile],
  302: [Icons.manager],
  303: [Icons.euro],
  3004: [Icons.superWifi2],
  304: [Icons.germany],
  305: [Icons.eu],
  306: [Icons.world],
  [FormConfigLabelIds.FIXED_DEVICES_LAPTOP]: [Icons.laptop],
  [FormConfigLabelIds.FIXED_DEVICES_SMARTPHONE]: [Icons.mobile],
  [FormConfigLabelIds.FIXED_DEVICES_TABLET]: [Icons.tablet],
  [FormConfigLabelIds.FIXED_DEVICES_PC]: [Icons.pc],
  [FormConfigLabelIds.FIXED_DEVICES_PHONE]: [Icons.landline],
  [FormConfigLabelIds.FIXED_DEVICES_TV]: [Icons.tv],
  [FormConfigLabelIds.FIXED_DEVICES_SMARTHOME]: [Icons.smartHome],
  [FormConfigLabelIds.FIXED_DEVICES_CONSOLE]: [Icons.gaming],
  [FormConfigLabelIds.FIXED_DEVICES_SMARTWATCH]: [Icons.smartwatch],
  [FormConfigLabelIds.FIXED_DEVICES_IOT]: [Icons.gpsWave],
  401: [Icons.home],
  402: [Icons.person],
  403: [Icons.cable],
  404: [Icons.satellite],
  405: [Icons.dvbt2],
  406: [Icons.ipTV],
  407: [Icons.streaming],
  408: [Icons.ard, Icons.zdf, Icons.pro7, Icons.rtl],
  409: [Icons.netflix, Icons.amazonPrime, Icons.dazn, Icons.youtube],
  410: [Icons.mediaLibrary],
  411: [Icons.vod],
  412: [Icons.currentProgram],
  413: [Icons.privateHd],
  414: [Icons.payTV],
  415: [Icons.mobileAndTablet],
  416: [Icons.recordings],
  417: [Icons.sky],
  // 500- 599 other things
  [FormConfigLabelIds.ADDITIONAL_INVOICE_COSTS]: [Icons.home],
  [FormConfigLabelIds.ADDITIONAL_INVOICE_PERSONAL]: [Icons.person],
  // 1001 - 1999 ids are advantages
  [FormConfigLabelIds.ADVANTAGE_GIGASPEED]: [GreyIcons.speed], // GigaSpeed
  [FormConfigLabelIds.ADVANTAGE_002]: [Icons.passes],
  [FormConfigLabelIds.ADVANTAGE_003]: [Icons.download],
  [FormConfigLabelIds.ADVANTAGE_TELFON_SMS_FLAT]: [Icons.chatAndTalk],
  [FormConfigLabelIds.ADVANTAGE_EU_ROAMING]: [Icons.eu],
  [FormConfigLabelIds.ADVANTAGE_EUROAMING]: [Icons.plane], // EU Roaming
  [FormConfigLabelIds.ADVANTAGE_007]: [Icons.passes],
  [FormConfigLabelIds.ADVANTAGE_008]: [Icons.placeholder],
  [FormConfigLabelIds.ADVANTAGE_009]: [Icons.securityPc],
  [FormConfigLabelIds.ADVANTAGE_010]: [Icons.placeholder],
  [FormConfigLabelIds.ADVANTAGE_011]: [Icons.placeholder],
  [FormConfigLabelIds.ADVANTAGE_FLAT_25_EUROPE]: [Icons.world],
  [FormConfigLabelIds.ADVANTAGE_013]: [Icons.placeholder],
  [FormConfigLabelIds.ADVANTAGE_014]: [Icons.mobile, Icons.landline],
  [FormConfigLabelIds.ADVANTAGE_015]: [Icons.mobile, Icons.landline],
  [FormConfigLabelIds.ADVANTAGE_016]: [Icons.mobile, Icons.landline],
  [FormConfigLabelIds.ADVANTAGE_017]: [Icons.placeholder],
  [FormConfigLabelIds.ADVANTAGE_018]: [Icons.euro],
  [FormConfigLabelIds.ADVANTAGE_019]: [Icons.landline, Icons.internet],
  [FormConfigLabelIds.ADVANTAGE_VF_FLAT]: [Icons.mobile],
  [FormConfigLabelIds.ADVANTAGE_021]: [Icons.euro],
  [FormConfigLabelIds.ADVANTAGE_022]: [Icons.euro],
  [FormConfigLabelIds.ADVANTAGE_023]: [Icons.euro],
  [FormConfigLabelIds.ADVANTAGE_024]: [Icons.router],
  [FormConfigLabelIds.ADVANTAGE_025]: [Icons.landline],
  [FormConfigLabelIds.ADVANTAGE_026]: [Icons.router],
  [FormConfigLabelIds.ADVANTAGE_BASEBOX_100]: [Icons.router],
  [FormConfigLabelIds.ADVANTAGE_30_DAYS_WARRANTY]: [Icons.calendar30],
  [FormConfigLabelIds.ADVANTAGE_5_EURO_KOMBI_REBATE_MOBILE]: [Icons.euro],
  [FormConfigLabelIds.ADVANTAGE_5_EURO_REBATE_YOUNG]: [Icons.euro],
  [FormConfigLabelIds.ADVANTAGE_500_MBITS]: [Icons.wholeProfile],
  [FormConfigLabelIds.ADVANTAGE_]: [Icons.Gen5],
  [FormConfigLabelIds.ADVANTAGE_PAY_IF_PLUGIN]: [Icons.cable],
  [FormConfigLabelIds.ADVANTAGE_034]: [Icons.winner],
  [FormConfigLabelIds.ADVANTAGE_035]: [Icons.unlimited],
  [FormConfigLabelIds.ADVANTAGE_GREAT_PROGRAM]: [Icons.tvChannels],
  [FormConfigLabelIds.ADVANTAGE_FLEXIBLE_TV]: [Icons.tvRefresh],
  [FormConfigLabelIds.ADVANTAGE_PERSONAL_RECOMMENDATION]: [Icons.tvLove],
  [FormConfigLabelIds.ADVANTAGE_GIGATV]: [Icons.gigaTVMobile],
  [FormConfigLabelIds.ADVANTAGE_PERFECT_QUALITY]: [Icons.privateHd],
  [FormConfigLabelIds.ADVANTAGE_PAY_TV_11]: [Icons.privacy],
  [FormConfigLabelIds.ADVANTAGE_SCANDINAVIAN_SERIES]: [Icons.currentProgram],
  [FormConfigLabelIds.ADVANTAGE_043]: [Icons.placeholder],
  [FormConfigLabelIds.ADVANTAGE_044]: [Icons.placeholder],
  [FormConfigLabelIds.ADVANTAGE_045]: [Icons.placeholder],
  [FormConfigLabelIds.ADVANTAGE_046]: [Icons.placeholder],
  [FormConfigLabelIds.ADVANTAGE_047]: [Icons.placeholder],
  [FormConfigLabelIds.ADVANTAGE_WLAN_ROUTER]: [Icons.router],
  [FormConfigLabelIds.ADVANTAGE_UPLOAD_50_MBITS]: [Icons.upload],
  [FormConfigLabelIds.ADVANTAGE_RED_INTERNET_250_500]: [Icons.internet],
  [FormConfigLabelIds.ADVANTAGE_FRITZBOX_6591]: [Icons.router],
  [FormConfigLabelIds.ADVANTAGE_INTERNET_FLAT]: [Icons.internet],
  [FormConfigLabelIds.ADVANTAGE_LANDLINE_FLAT]: [Icons.landline],
  [FormConfigLabelIds.ADVANTAGE_FLAT_GERMAN_MOBILE]: [Icons.mobile],
  [FormConfigLabelIds.ADVANTAGE_FLAT_60_LANDLINE]: [Icons.landline],
  [FormConfigLabelIds.ADVANTAGE_FLAT_60_LANDLINE_MOBILE]: [Icons.landline],
  [FormConfigLabelIds.ADVANTAGE_057]: [Icons.firstPrice],
  [FormConfigLabelIds.ADVANTAGE_VF_PREMIUM_21_PAY_TV]: [Icons.privacy],
  [FormConfigLabelIds.ADVANTAGE_VIDEO_LIBRARY]: [Icons.cyberSecurity], //schloss
  [FormConfigLabelIds.ADVANTAGE_GREAT_MEDIA_LIBRARY]: [Icons.mediaLibrary],
  [FormConfigLabelIds.ADVANTAGE_GIGA_TV_MOBILE]: [Icons.gigaTvApp],
  [FormConfigLabelIds.ADVANTAGE_SMART_TRACKER]: [Icons.childLocation],
  [FormConfigLabelIds.ADVANTAGE_EU_ROAMING_INCL]: [Icons.eu], // eu roaming
  [FormConfigLabelIds.ADVANTAGE_NO_START_PRICE]: [Icons.euro],
  [FormConfigLabelIds.ADVANTAGE_USE_IN_SMART_DEVICES]: [Icons.iotLabelSim],
  [FormConfigLabelIds.ADVANTAGE_500_MIN_100_SMS]: [Icons.chatAndTalk],
  [FormConfigLabelIds.ADVANTAGE_OWN_CALL_NUMBER]: [Icons.iotSim],
  [FormConfigLabelIds.ADVANTAGE_SMART_CAMERA]: [Icons.securityCam],
  [FormConfigLabelIds.ADVANTAGE_EU_ROAMING_7GB]: [Icons.eu],
  [FormConfigLabelIds.ADVANTAGE_PREMIUM_WATCH]: [Icons.premiumSmartWatch], // premium
  [FormConfigLabelIds.ADVANTAGE_OWN_CALL_NUMBER_2_DEVICES]: [
    Icons.ownCallNumber,
  ], // eine rufnummer
  [FormConfigLabelIds.ADVANTAGE_EVERYTIME_CONNECTION_WITHOUT_PHONE]: [
    Icons.overallConnection,
  ], // überall
  [FormConfigLabelIds.ADVANTAGE_NETFLIX_STANDARD]: [Icons.netflix],
  [FormConfigLabelIds.ADVANTAGE_VOICE_BY_CALL]: [Icons.landline],
  [FormConfigLabelIds.ADVANTAGE_CURVE_TRACKER]: [Icons.location],
  [FormConfigLabelIds.ADVANTAGE_UNLIMITED_DATAVOLUME]: [Icons.unlimited],
  [FormConfigLabelIds.ADVANTAGE_NEO_KIDS_WATCH]: [Icons.iotSim],
  [FormConfigLabelIds.ADVANTAGE_GIGA_DEPOT]: [GreyIcons.gigaDepot], // GigaDepot
  [FormConfigLabelIds.ADVANTAGE_EU_ROAMING_SURF_MOBILE]: [Icons.plane], // EU Roaming
  [FormConfigLabelIds.ADVANTAGE_MOBILE_SMS_FLAT]: [GreyIcons.telefonFlat], // Telefon & SMS Flat
  [FormConfigLabelIds.ADVANTAGE_SUPERWLAN_EASYBOX]: [Icons.superWifi],
  [FormConfigLabelIds.ADVANTAGE_SUPERWLAN_VF]: [Icons.superWifi], // SuperWLAN
  [FormConfigLabelIds.ADVANTAGE_200_MIN_50_SMS]: [Icons.superWifi], // 200 Min + 50 SMS
  [FormConfigLabelIds.ADVANTAGE_SECURITY_AGE_SURF_2]: [Icons.superWifi], // Sicherheitseinstellungen für altersgerechtes Surfen
  [FormConfigLabelIds.ADVANTAGE_INTERNET_FLAT_2]: [Icons.internet], // Internet-Flat
  [FormConfigLabelIds.ADVANTAGE_FIXED_FLAT]: [Icons.landline], // Festnetz-Flat
  [FormConfigLabelIds.ADVANTAGE_VF_FLAT2]: [Icons.mobile], // Vodafone-Flat
  [FormConfigLabelIds.ADVANTAGE_FIXED_VF_FLAT]: [Icons.mobile], // Festnetz- und Vodafone Flat
  [FormConfigLabelIds.ADVANTAGE_CHANGE_GUARANTEE]: [Icons.accelerometerHalf], // Wechselgarantie
  [FormConfigLabelIds.ADVANTAGE_ALLNET_FLAT]: [Icons.landline], // Allnet-Flat
  [FormConfigLabelIds.ADVANTAGE_CHANGE_SERVICE]: [Icons.accelerometerHalf], // Wechselservice
  [FormConfigLabelIds.ADVANTAGE_CHANGE_GUARANTEE_SERVICE]: [
    Icons.accelerometerHalf,
  ], // Wechselgarantie und Wechselservice
  [FormConfigLabelIds.ADVANTAGE_10_EURO_REBATE_COMBINATION]: [Icons.euro], // 10 € Rabatt bei Kombination mit Deinem Mobilfunk-Vertrag
  [FormConfigLabelIds.ADVANTAGE_112]: [Icons.euro], // 10 € Rabatt für alle zwischen 18 und 27 Jahren
  [FormConfigLabelIds.ADVANTAGE_5_EURO_REBATE_YOUNG_2]: [Icons.euro], // 5 € Rabatt für alle zwischen 18 und 27 Jahren
  [FormConfigLabelIds.ADVANTAGE_LANDLINE_BY_CALL]: [Icons.landline],
  [FormConfigLabelIds.ADVANTAGE_SUPERWLAN_EASYBOX_2]: [Icons.superWifi],
  [FormConfigLabelIds.ADVANTAGE_SUPERWLAN_EASYBOX_3]: [Icons.superWifi],
  [FormConfigLabelIds.ADVANTAGE_SUPERWLAN_EASYBOX_4]: [Icons.superWifi],
  [FormConfigLabelIds.ADVANTAGE_DATA_VOLUME_100]: [Icons.sim],
  [FormConfigLabelIds.ADVANTAGE_DATA_VOLUME_200]: [Icons.sim],
  [FormConfigLabelIds.ADVANTAGE_DATA_VOLUME_UNLIMITED]: [Icons.unlimited],
  [FormConfigLabelIds.ADVANTAGE_EU_ROAMING_SURF_CALL]: [Icons.eu],
  [FormConfigLabelIds.ADVANTAGE_EACH_YEAR_NEW_PHONE]: [Icons.mobile],

  // 1900 - 1999 ids are for gigakombiAdvantages
  [FormConfigLabelIds.GIGAKOMBI]: [GreyIcons.gigaKombi],
  [FormConfigLabelIds.GIGAKOMBI_MOBILE_EURO_FLAT]: [Icons.mobileEuroFlat],
  [FormConfigLabelIds.GIGAKOMBI_CYBER_SECURITY]: [Icons.cyberSecurity],
  // 2000 - 2999 ids are passes
  [FormConfigLabelIds.PASS_CHAT]: [ColorIcons.chat2],
  [FormConfigLabelIds.PASS_SOCIAl]: [ColorIcons.social],
  [FormConfigLabelIds.PASS_VIDEO]: [ColorIcons.video2],
  [FormConfigLabelIds.PASS_MUSIC]: [ColorIcons.music2],
  [FormConfigLabelIds.PASS_GAMING]: [ColorIcons.gaming2],
  // 3000 - 3049 ids are gigakombi
  [FormConfigLabelIds.GIGAKOMBI_101]: [Icons.gigaKombi101],
  [FormConfigLabelIds.GIGAKOMBI_110]: [Icons.gigaKombi110],
  [FormConfigLabelIds.GIGAKOMBI_011]: [Icons.gigaKombi011],
  [FormConfigLabelIds.GIGAKOMBI_111]: [Icons.gigaKombi111],
};

export const getIcons = (
  id: number,
  usePlaceHolder: boolean = true
): SVGComponent[] => {
  return IconMap[id] !== undefined
    ? IconMap[id]
    : usePlaceHolder
    ? [Icons.placeholder]
    : [];
};

export const getIcon = (
  id: number,
  usePlaceHolder: boolean = true
): SVGComponent => {
  return getIcons(id, usePlaceHolder)[0];
};

export default Icons;
