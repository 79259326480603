import React from "react";
import styled from "styled-components";
import SelectionModal from "./AFOSelectionModal";
import { Color } from "../../../constants/color";
import { TableCellType, TableDataObject } from "../../../types/table";

interface AFOPriceSelectionCellProps {
  className?: string;
  value: number;
  dataId: number;
  dataSet: TableDataObject;
  changePriceId: (newLabelId: number, objectId: number) => void;
  setOpenSelectionId: (id: number) => void;
  openSelectionId: number;
  disabled?: boolean;
}

const AFOPriceSelectionCell: React.FC<AFOPriceSelectionCellProps> = ({
  className,
  dataId,
  dataSet,
  changePriceId,
  setOpenSelectionId,
  openSelectionId,
  value,
  disabled = false,
}) => (
  <td className={className + " cell row-label"}>
    {!disabled &&
      dataId * 3 + 2 === openSelectionId &&
      dataSet.priceType === TableCellType.SELECTION && (
        <SelectionModal
          data={dataSet.prices}
          selectedId={dataSet.priceId}
          changeId={(valueId: number) => {
            changePriceId(valueId, dataId);
            setOpenSelectionId(-1);
          }}
        />
      )}

    <div
      onClick={() => setOpenSelectionId(dataId * 3 + 2)}
      className={"cell-content"}
    >
      <span className={"value-container"}>{value.toFixed(2)}</span>
      <span className={"currency-symbol"}>{dataSet.priceUnit}</span>
    </div>
  </td>
);

export default styled(AFOPriceSelectionCell)`
  background: ${({ disabled }) =>
    disabled ? Color.GREY_LIGHT : Color.GREY_LIGHT6};
  cursor: ${({ dataSet, disabled }) =>
    disabled
      ? "not-allowed"
      : dataSet.priceType === TableCellType.SELECTION
      ? "pointer"
      : "default"};

  .value-container {
    display: inline-block;
    width: 60%;
    text-align: right;
    padding-right: 1px;
  }
  .currency-symbol {
    display: inline-block;
    width: 40%;
    text-align: left;
    padding-left: 1px;
  }
`;
