import * as React from "react";

function SvgCloseColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="close_color_svg___x36_d82e59e-323a-474d-be06-1abebb26ef51"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".close_color_svg__st0{fill:none;stroke:#e60000;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <g id="close_color_svg__cf6b150c-bc02-471f-8989-3b42b923bf67">
        <g id="close_color_svg___x37_dc69a0c-916c-46ba-b2cd-88a893af269f">
          <path
            id="close_color_svg__c33595e8-181e-4076-a9f0-227b8fdd48ef"
            className="close_color_svg__st0"
            d="M7.5 7.5l485 485"
          />
          <path
            id="close_color_svg___x35_6502c87-b986-44ba-a730-3fc53d646431"
            className="close_color_svg__st0"
            d="M7.5 492.5l485-485"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgCloseColor;
