import React from "react";
import styled from "styled-components";
import PanelItem from "../../panel/PanelItem";
import PanelValue from "../../panel/PanelValue";
import GreyIcons from "../../../icons/GreyIcons";
import { IOTSituation } from "../../../types/profile";
import { prettyMoney } from "../../../helper/getPrettyStrings";

interface PanelItemsIotSituationProps {
  className?: string;
  situation: IOTSituation;
}

const PanelItemsIotSituation: React.FC<PanelItemsIotSituationProps> = ({
  situation,
}) => {
  return (
    <>
      {situation.provider !== undefined && (
        <PanelItem icon={GreyIcons.iot}>
          <PanelValue>{situation.provider}</PanelValue>
        </PanelItem>
      )}
      {situation.price !== undefined && situation.price > 0 && (
        <PanelItem icon={GreyIcons.price}>
          Preis: <PanelValue>{prettyMoney(situation.price)}</PanelValue>
        </PanelItem>
      )}
      {situation.products !== undefined && situation.products.length > 0 && (
        <PanelItem icon={GreyIcons.iot}>
          Produkte:
          <PanelValue> {situation.products.join(", ")}</PanelValue>
        </PanelItem>
      )}
      {situation.ownDevices !== undefined && situation.ownDevices.length > 0 && (
        <PanelItem icon={GreyIcons.iot}>
          Eigene Hardware:
          <PanelValue> {situation.ownDevices.join(", ")}</PanelValue>
        </PanelItem>
      )}
    </>
  );
};
export default styled(PanelItemsIotSituation)``;
