import * as React from "react";
import { getHEXNumber } from "../../helper/getHEXNumber";

function SvgBroadband(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  const colorString = getHEXNumber(color);

  return (
    <svg
      id="broadband_svg___x35_8b24023-844b-441f-a7e8-bc95b1e3fc25"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.broadband_svg__st0-${colorString}{fill:none;stroke:${color};stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        id="broadband_svg__e785b65f-c83c-4f55-b899-d424a3fdb668"
        className={`broadband_svg__st0-${colorString}`}
        d="M7.5 165.3c133.8-133.9 350.8-134 484.8-.2l.2.2"
      />
      <path
        id="broadband_svg___x33_dd8f0cb-87f2-4986-891e-34efbd242d6e"
        className={`broadband_svg__st0-${colorString}`}
        d="M77.7 238.7c95.6-95.7 250.7-95.8 346.4-.1l.1.1"
      />
      <path
        id="broadband_svg___x30_9b0d63f-7f50-4c52-bffe-131dcc6144d2"
        className={`broadband_svg__st0-${colorString}`}
        d="M149.8 310.7c55.6-55.6 145.7-55.6 201.3-.1l.1.1"
      />
      <circle
        className={`broadband_svg__st0-${colorString}`}
        cx={250}
        cy={393.4}
        r={12.8}
      />
      <circle
        className={`broadband_svg__st0-${colorString}`}
        cx={250}
        cy={393.4}
        r={8}
      />
      <circle
        className={`broadband_svg__st0-${colorString}`}
        cx={250}
        cy={393.4}
        r={3.2}
      />
    </svg>
  );
}

export default SvgBroadband;
