/**
 * Extract slide id from a slide url.
 * @param pathname
 */
import { ConstSlidePages } from "../../constants/SlidePages";

export function extractId(pathname: string): number | undefined {
  const id = parseInt(pathname.split("/").pop() as string, 10);
  if (id === undefined || isNaN(id)) {
    return undefined;
  }
  return id;
}

export function extractIdSafe(pathname: string): number {
  const id = extractId(pathname);
  if (id === undefined) {
    return ConstSlidePages.initialInterest.id;
  }
  return id;
}
