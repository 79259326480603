import * as React from "react";

function SvgPrivacyColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="privacy_color_svg__b82852f5-bd95-4837-b8a2-abc140ea4a12"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".privacy_color_svg__st0{fill:none;stroke:#e60000;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <path
        id="privacy_color_svg___x37_49cf875-4c70-4a87-987c-5c67ab21b6e4"
        className="privacy_color_svg__st0"
        d="M96 20L36 33.4v77.3c0 6.9 4.9 14.3 10.8 22.1 5.2 6.9 12.7 13.9 22.1 21C80.6 162.5 90.2 169 96 172c5.8-3 15.4-9.5 27.1-18.2 9.4-7 16.8-14.1 22.1-21 5.9-7.8 10.8-15.2 10.8-22.1V33.4L96 20z"
      />
      <path
        className="privacy_color_svg__st0"
        d="M105.5 85c8.4-5.2 10.9-16.3 5.7-24.6-5.2-8.4-16.3-10.9-24.6-5.7S75.7 71 80.9 79.3c1.4 2.3 3.4 4.3 5.7 5.7l-8.4 40.2c-.2 3.5 2.4 6.5 5.9 6.8h23.1c3.5.2 6.5-2.5 6.7-6v-.7l-4.3-18.4-4.1-21.9z"
      />
      <path fill="none" d="M0 0h192v192H0z" />
    </svg>
  );
}

export default SvgPrivacyColor;
