import * as React from "react";

function SvgMusic(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <path
        d="M207.72 404.65V99.68l277.6-94.3v377.37M89.98 370.5c52.37-18.52 104.08-8.79 115.52 21.72 11.43 30.52-21.75 70.27-74.11 88.78S27.3 489.79 15.87 459.28c-11.44-30.52 21.75-70.27 74.11-88.78zm278.07-26.81c52.27-18.49 103.9-8.78 115.32 21.69 11.41 30.46-21.71 70.15-73.99 88.63-52.27 18.49-103.91 8.78-115.32-21.69-11.41-30.46 21.71-70.14 73.99-88.63z"
        fill="none"
        stroke={color}
        strokeWidth={24.173}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgMusic;
