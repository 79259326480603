import React from "react";
import styled from "styled-components";
import PaginationButton from "../../pagination/PaginationButton";
import { Color } from "../../../constants/color";
import SVGIcon from "../../svgIcon/SVGIcon";
import { SVGComponent } from "../../../types/default";

interface NumberSelectorProps {
  className?: string;
  currentValue: number;
  min?: number;
  max?: number;
  setValue: (value: number) => void;
  selected?: boolean;
  image?: SVGComponent;
  color?: string;
  smallVersion?: boolean;
}

const NumberSelector: React.FC<NumberSelectorProps> = ({
  className,
  currentValue,
  max,
  min = 0,
  setValue,
  selected,
  image,
}) => {
  const prev = () => {
    const newIndex = currentValue - 1 < min ? min : currentValue - 1;
    setValue(newIndex);
  };
  const next = () => {
    const maxValue = max !== undefined ? max : Number.MAX_SAFE_INTEGER;
    const newIndex = currentValue + 1 >= maxValue ? maxValue : currentValue + 1;
    setValue(newIndex);
  };
  return (
    <div className={className + " number-selector"}>
      {image && <SVGIcon src={image} />}
      <div className={"prev-container"}>
        {currentValue > min && (
          <SelectorButton
            direction={"prev"}
            onClick={prev}
            disabled={!selected}
          />
        )}
      </div>
      <div className={"value-field-container"}>
        <div className={"value-field"}>
          <span>{currentValue}</span>
        </div>
      </div>
      <div className={"next-container"}>
        {((max !== undefined && currentValue < max) || max === undefined) && (
          <SelectorButton
            direction={"next"}
            onClick={next}
            disabled={!selected}
          />
        )}
      </div>
    </div>
  );
};
const SelectorButton = styled(PaginationButton)`
  height: 100%;
  width: 100%;
  outline: none;
  vertical-align: top;

  .right-triangle {
    display: inline-block;
    margin-left: 8px;
    margin-right: 2px;
  }

  .left-triangle {
    display: inline-block;
    margin-right: 8px;
    margin-left: 2px;
  }
`;

export default styled(NumberSelector)`
  height: 70px;
  line-height: 35px;
  background-color: ${(props) =>
    props.color ? props.color : props.selected ? Color.RED : Color.GREY2};
  min-width: 150px;
  max-width: ${({ smallVersion }) => (smallVersion ? "300px" : "initial")};
  margin: ${({ smallVersion }) => (smallVersion ? "5px" : "1px 0")};
  text-align: center;

  img,
  svg {
    padding: 5px;
  }

  .prev-container {
    height: 100%;
    width: 30px;
    outline: none;
    vertical-align: top;
    display: inline-block;

    button {
      text-align: right;
      background-color: ${(props) =>
        props.color ? props.color : props.selected ? Color.RED : Color.GREY2};
    }
  }

  .next-container {
    height: 100%;
    width: 30px;
    outline: none;
    vertical-align: top;
    display: inline-block;

    button {
      text-align: left;
      background-color: ${(props) =>
        props.color ? props.color : props.selected ? Color.RED : Color.GREY2};
    }
  }

  .value-field-container {
    display: inline-block;
    width: 50px;
    height: 100%;
    padding: 15px 0; // distance between valueField and the outer container
    vertical-align: top;

    .value-field {
      vertical-align: top;
      width: 100%;
      display: inline-block;
      height: 100%;
      background: ${Color.WHITE};
      box-shadow: inset 5px 5px 14px -8px rgba(0, 0, 0, 0.8);
      position: relative;

      span {
        text-align: center;
        padding: 5px 0;
        margin: 0;
        display: inline-block;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
`;
