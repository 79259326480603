import * as React from "react";

function SvgIotSim(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      data-name="ICON"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <path fill="none" d="M0 0h192v192H0z" />
      <path
        d="M116 137a17.92 17.92 0 01-10 3H46a18 18 0 01-18-18V70a18 18 0 0118-18h60a18 18 0 0118 18v22M48.35 52l3.34-26.74a6 6 0 016-5.26h36.7a6 6 0 016 5.26L103.65 52"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
      />
      <path
        d="M48.35 140l3.34 26.74a6 6 0 006 5.26h36.7a6 6 0 006-5.26l3.26-26.74M116 166V98a6 6 0 016-6h26l16 16v58a6 6 0 01-6 6h-36a6 6 0 01-6-6z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
      />
      <rect
        x={132}
        y={124}
        width={16}
        height={32}
        rx={4}
        ry={4}
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
      />
    </svg>
  );
}

export default SvgIotSim;
