import advantageJson from "../../data/advantages.json";
import { TransferDatabaseAdvantage } from "./TransferDatabaseAdvantage";

// get an array with so many strings, how the highest ID is
function getLabelArray(receivedArray: TransferDatabaseAdvantage[]) {
  const filledArray = [];
  const maxLength = Number(
    receivedArray[receivedArray.length - 1].product_property_value_id
  );

  for (let i = 0; i <= maxLength; i++) {
    const foundObj = receivedArray.find(
      (a: any) => Number(a.product_property_value_id) === i
    );

    if (foundObj) {
      filledArray.push(foundObj);
    } else {
      filledArray.push(new TransferDatabaseAdvantage());
    }
  }

  return filledArray.map((a) => {
    return (a.product_property_headline + " " + a.product_property_value)
      .trim()
      .toUpperCase();
  });
}

export const AdvantageLabels = getLabelArray(advantageJson);
