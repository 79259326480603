import * as React from "react";
import styled from "styled-components";
import { StyledComponentProps } from "../../../../types/default";
import GigaKombiInterestButton from "./GigaKombiInterestButton";
import { Color } from "../../../../constants/color";
import { useGigaKombiMainAdvantages } from "../../../../hooks/useOfferHelper/useGigaKombiMainAdvantages";
import PrintIconTextDisplayContainer from "../../initialInterestPage/PrintIconTextDisplayContainer";
import "../../../grid/JustifiedColumnsContainer";
import "../../../grid/Column";
import JustifiedColumnsContainer from "../../../grid/JustifiedColumnsContainer";
import "../../../grid/GridColumn";
import GridColumn from "../../../grid/GridColumn";

export interface Props extends StyledComponentProps {}

const PrintGigaKombiMainAdvantagesContainer = ({ className }: Props) => {
  const { gigaKombiMainAdvantages, withsObject } = useGigaKombiMainAdvantages();

  return (
    <div className={className + " gigakombi-container"}>
      <JustifiedColumnsContainer>
        <GridColumn colCount={2} maxWidth={"150px"}>
          <p className={"gigakombi-label"}>Deine genutzte GigaKombi:</p>
          <div className={"gigakombi-svg-container"}>
            <GigaKombiInterestButton
              withsObject={withsObject}
              extraClassName={"gigakombi-svg"}
              invert
            />
          </div>
        </GridColumn>
        <GridColumn colCount={2}>
          <PrintIconTextDisplayContainer
            extraClassName={"advantage-container"}
            items={gigaKombiMainAdvantages}
            colCount={3}
          />
        </GridColumn>
      </JustifiedColumnsContainer>
    </div>
  );
};

export default styled(PrintGigaKombiMainAdvantagesContainer)`
  //border: 1px solid ${Color.RED};
  background: linear-gradient(135deg, ${Color.RED_DARK2}, ${Color.RED});

  .gigakombi-label {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: ${Color.WHITE};
    padding-top: 5px;
  }
  .gigakombi-svg-container {
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;

    .gigakombi-svg {
      max-width: 100px;
      display: inline-block;
      vertical-align: middle;
    }
  }
`;
