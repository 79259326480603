import * as React from "react";
import { Color } from "../../../constants/color";
import PrintLongTextDisplay from "../guaranteePage/PrintLongTextDisplay";

export interface PrintIconTextDisplayProps {
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  label: string;
  value: string;
}

const PrintIconTextDisplay = ({
  icon,
  label,
  value,
}: PrintIconTextDisplayProps) => {
  return (
    <PrintLongTextDisplay
      backgroundColor={"transparent"}
      headlineColor={Color.WHITE}
      textColor={Color.WHITE}
      iconColor={Color.WHITE}
      iconSize={30}
      icon={icon}
      label={label}
      value={value}
    />
  );
};

export default PrintIconTextDisplay;
