import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import { StyledComponentProps } from "../../types/default";

const PanelPrintItemLabel: React.FC<StyledComponentProps> = ({
  className,
  children,
}) => <span className={className}>{children}</span>;

export default styled(PanelPrintItemLabel)`
  vertical-align: middle;
  font-size: 12px;
  display: inline-block;

  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  background: ${Color.WHITE};
  max-width: 250px;
`;
