import React from "react";
import PersonDisplayList from "../form/PersonDisplayList";
import TextDisplay from "../elements/TextDisplay";
import styled from "styled-components";
import useContracts from "../../hooks/useFormHelper/useContracts";
import { StyledComponentProps } from "../../types/default";

const MobileContractOptimizePage: React.FC<StyledComponentProps> = ({
  className,
}) => {
  const { useContractData, addContractAdult } = useContracts();
  const [contractData, contractCounts] = useContractData();

  return (
    <div className={className}>
      <div className={"mobile-contract-page-content-container"}>
        <PersonDisplayList
          dataSet={contractData}
          addOptimizedContract={addContractAdult}
        />
        <div className={"person-count-display"}>
          <TextDisplay
            label={"Anzahl an Personen gesamt:"}
            value={String(contractData.length)} // +1 for the user
          />
          <TextDisplay
            label={"Hochrechnung benötigtes Datenvolumen:"}
            value={contractCounts * 4 + " GB"}
          />
        </div>
      </div>
    </div>
  );
};

export default styled(MobileContractOptimizePage)`
  .mobile-contract-page-content-container {
    margin: 0 10px;
    text-align: left;
    .person-count-display {
      vertical-align: bottom;
      display: block;
      text-align: center;
      margin: 20px 0 10px 0;
    }
  }
  .info-modal-container {
    .data-volume-share-image-container {
      img {
        max-width: 100%;
        max-height: 300px;
        display: inline-block;
      }
    }
  }
`;
