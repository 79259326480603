import React from "react";
import styled from "styled-components";
import PanelItem from "../../panel/PanelItem";
import GreyIcons from "../../../icons/GreyIcons";
import { PersonalSituation } from "../../../types/profile";

interface PanelItemsPersonalSituationProps {
  className?: string;
  importants: PersonalSituation;
}

const PanelItemsPersonalSituation: React.FC<PanelItemsPersonalSituationProps> =
  ({ importants }) => {
    const panelItems = [];
    if (importants.under28) {
      panelItems.push(
        <PanelItem key={panelItems.length} icon={GreyIcons.under28}>
          Unter 28 Jahre
        </PanelItem>
      );
    }
    if (importants.parentBetween10And17) {
      panelItems.push(
        <PanelItem key={panelItems.length} icon={GreyIcons.users}>
          Elternteil von Kindern zwischen 10-17
        </PanelItem>
      );
    }
    if (importants.independent) {
      panelItems.push(
        <PanelItem key={panelItems.length} icon={GreyIcons.mobileWorking}>
          Selbstständig
        </PanelItem>
      );
    }
    if (importants.private) {
      panelItems.push(
        <PanelItem key={panelItems.length} icon={GreyIcons.mobileWorking}>
          Privat
        </PanelItem>
      );
    }
    if (importants.cooperated) {
      panelItems.push(
        <PanelItem key={panelItems.length} icon={GreyIcons.businessCustomer}>
          Angestellt bei einem Kooperationspartner
        </PanelItem>
      );
    }
    return panelItems.length > 0 ? <>{panelItems}</> : null;
  };
export default styled(PanelItemsPersonalSituation)``;
