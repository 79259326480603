import * as React from "react";

function SvgTabletGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#4a4d4e" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192" {...props}>
      <path
        fill="none"
        stroke={color}
        strokeWidth={8}
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M108 132H84"
      />
      <path fill="none" d="M0 0h192v192H0z" />
      <path
        d="M32 44h128c6.6 0 12 5.4 12 12v80c0 6.6-5.4 12-12 12H32c-6.6 0-12-5.4-12-12V56c0-6.6 5.4-12 12-12z"
        fill="none"
        stroke={color}
        strokeWidth={8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgTabletGrey;
