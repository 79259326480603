import React from "react";
import styled from "styled-components";
import { SimpleState } from "../../../types/default";
import AFOCurrencyChanger from "./AFOCurrencyChanger";
import { AdditionalSubProduct } from "../../../types/table";
import AFOInputCell from "./AFOInputCell";
import { REBATE_MAX_VALUE, REBATE_MIN_VALUE } from "../../../constants/default";

interface AFOPriceInputCellProps {
  className?: string;
  value: number;
  unit: string;
  changeValue: (newValue: number) => void;
  showPercentValueIdState: SimpleState<number>;
  additionalDataObject?: AdditionalSubProduct;
  dataId: number;
}

const AFOPriceInputCell: React.FC<AFOPriceInputCellProps> = ({
  className,
  value,
  unit,
  changeValue,
  showPercentValueIdState,
  additionalDataObject,
  dataId,
}) => {
  const [showPercentValueId, setShowPercentValueId] = showPercentValueIdState;
  let colSpan = 1;
  if (showPercentValueId !== -1) {
    colSpan = 2;
  }
  return (
    <AFOInputCell
      extraClassName={className}
      inputClassName={
        additionalDataObject ? "with-currency-changer" : undefined
      }
      changeValue={changeValue}
      realValue={value}
      defaultValue={0}
      min={REBATE_MIN_VALUE}
      max={REBATE_MAX_VALUE}
      step={0.01}
      colSpan={colSpan}
      displayValueFormatter={(v) => v.toFixed(2)}
    >
      {!additionalDataObject && (
        <span className={"currency-symbol"}>{unit}</span>
      )}
      {additionalDataObject && (
        <AFOCurrencyChanger
          onClick={() => setShowPercentValueId(dataId)}
          content={unit}
        />
      )}
    </AFOInputCell>
  );
};

export default styled(AFOPriceInputCell)`
  .cell-input {
    .number-input {
      width: 60%;
      display: inline-block;
      padding: 5px 2px 5px 5px;
      text-align: right;

      &.with-currency-changer {
        width: 70%;
        padding: 5px;
        vertical-align: middle;
      }
    }
    .currency-symbol {
      display: inline-block;
      width: 40%;
      padding-right: 4px;
      text-align: left;
    }
  }
`;
