import * as React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import { StyledComponentProps } from "../../../types/default";

export interface NewsPanelLinkProps extends StyledComponentProps {
  link: string;
}

const NewsPanelLink: React.FC<NewsPanelLinkProps> = ({ link, className }) => {
  return (
    <div className={className}>
      <a href={link} target={"_blank"} rel={"noopener noreferrer"}>
        {link}
      </a>
    </div>
  );
};

export default styled(NewsPanelLink)`
  background: ${Color.GREY_LIGHT8};
  width: 100%;
  text-align: center;
  padding: 10px 0;
  a {
    width: 90%;
    display: inline-block;
    text-align: left;
    color: ${Color.RED};
  }
`;
