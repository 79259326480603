import * as React from "react";

function SvgZdf(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <path
        d="M332.95 291.64h-29.79v-82.39h29.79c23.43 0 31.83 10.06 31.83 41.22 0 31.16-8.39 41.17-31.83 41.17m-57.59.55v21.76H168.4v-31.58c0-26.85 12.62-42.82 43.24-42.82h17.59c13.99 0 22.24-2.82 22.24-14.68 0-12.2-8.26-15.62-22.59-15.62H169.2v-22.42h69.45c35.21 0 40.2 24.69 40.2 38.44 0 14.6-5.98 37.84-40.25 37.84h-17.05c-21.34 0-25.92 7.71-25.92 22.29v6.8h79.73zm169.48-82.95h47.27v-22.42h-56.95c-30.66 0-43.25 17.15-43.25 44.07v2.59c-5.11-31.64-23.92-46.66-58.38-46.66H275.8c-23.23-46.6-71.33-78.61-126.92-78.61C70.58 108.21 7.1 171.68 7.1 250c0 78.29 63.47 141.74 141.75 141.74 55.27 0 103.06-31.57 126.44-77.65h58.9c14.12 0 26.76-.83 39.72-11.16 9.31-7.4 15.14-17.79 17.84-31.47v42.49h27l.03-50h63.75V241.7h-63.78l.38-9.01c.01-14.61 4.4-23.45 25.71-23.45"
        fill={color}
      />
    </svg>
  );
}

export default SvgZdf;
