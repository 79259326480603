import React, { useState } from "react";
import styled from "styled-components";
import JustifiedColumnsContainer from "../grid/JustifiedColumnsContainer";
import { FormOption } from "../../state/definitions/FormConfigState";
import { useRefEffect } from "../../hooks/common/useRefHook";

interface CheckboxListProps {
  className?: string;
  data: FormOption[];
  values?: number[];
  onChange: (values: number[]) => void;
  renderFkt: (
    dataRecord: FormOption,
    isSelected: boolean,
    toggleValue: (value: number) => void,
    index: number
  ) => JSX.Element;
}

const CheckboxList: React.FC<CheckboxListProps> = ({
  className,
  data,
  values = [],
  onChange,
  renderFkt,
}) => {
  const [selectedValues, setSelectedValues] = useState(values);

  const toggleValue = (value: number) => {
    setSelectedValues((prev) => {
      if (prev.includes(value)) {
        return prev.filter((item) => item !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const selectedValuesDependency = selectedValues.join("");
  const valuesDependency = values.join("");

  useRefEffect(() => {
    if (selectedValuesDependency !== values.join("")) {
      onChange(selectedValues);
    }
  }, [valuesDependency, selectedValuesDependency]);

  return (
    <div key={-1} className={className}>
      <JustifiedColumnsContainer>
        {data.map((dataRecord: FormOption, index) => {
          const isSelected = selectedValues.includes(dataRecord.value);
          return renderFkt(dataRecord, isSelected, toggleValue, index);
        })}
      </JustifiedColumnsContainer>
    </div>
  );
};

export default styled(CheckboxList)`
  .column {
    margin-bottom: 10px;
  }
`;
