import { ContactState } from "../definitions/ContactState";

export const defaultContactState: ContactState = {
  isLoaded: false,
  name: "",
  street: "",
  addressExtra: "",
  zip: "",
  city: "",
  url: "",
};
