import { ChangeEventHandler } from "react";
import { SliderProps } from "../components/form/Slider";

export default function prepareSliderData(sliderProps: SliderProps) {
  const {
    value,
    onChange,
    min = 0,
    max,
    step = 0,
    maxString,
    list,
  } = sliderProps;

  const changeValue: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = parseInt(event.target.value, 10);
    onChange(newValue);
  };
  let valueString = value.toString();
  let maxValue = max;
  let minValue = min;
  let stepValue = step;
  if (list !== undefined && list.length > 0 && list[value]) {
    valueString = list[value].toString();
    maxValue = list.length - 1;
    minValue = 0;
    stepValue = 1;
  }
  if (value === max && maxString) {
    valueString = maxString;
  }
  return {
    valueString,
    minValue,
    maxValue,
    stepValue,
    changeValue,
  };
}
