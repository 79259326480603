import * as React from "react";

function SvgPc(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="pc_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.pc_svg__st2{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-miterlimit:10}`}
      </style>
      <g fill="none" stroke={color} strokeWidth={24.173} strokeLinejoin="round">
        <path d="M60.99 351.09L7.1 472.34h485.01l-53.9-121.25" />
        <path
          d="M81.95 27.6h335.31c19.84 0 35.93 16.09 35.93 35.93v191.6c0 19.84-16.09 35.93-35.93 35.93H81.95c-19.84 0-35.93-16.09-35.93-35.93V63.53c0-19.84 16.09-35.93 35.93-35.93zM60.99 350.94h377.22"
          strokeLinecap="round"
        />
      </g>
      <path
        className="pc_svg__st2"
        d="M252.6 294.05v53.89M335.93 413.31H168.27"
      />
    </svg>
  );
}

export default SvgPc;
