import "core-js";
import smoothscroll from "smoothscroll-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./reset.css";

import App from "./App";

import * as serviceWorker from "./serviceWorker";
import {
  BrowserRouter,
  MemoryRouter as Router,
  Switch,
} from "react-router-dom";
import PrintOfferEntryPage from "./components/pages/PrintOfferEntryPage";
import PrintProfilePage from "./components/pages/PrintProfilePage";

import { AppProvider } from "./state/AppContext";
import IconPage from "./components/pages/IconPage";
import PrintSummaryPage from "./components/pages/PrintSummaryPage";
import RoutePageContainer from "./components/navigation/RoutePageContainer";

smoothscroll.polyfill();
/**
 * Currently strict mode impedes the trigger of useEffect for state changes
 * after an async function call within an action
 * @see https://github.com/cerebral/overmind/issues/391
 */
ReactDOM.render(
  <AppProvider>
    <BrowserRouter>
      <Switch>
        <RoutePageContainer path="/isd89zwef">
          <PrintOfferEntryPage />
        </RoutePageContainer>
        <RoutePageContainer path="/98hswdcbu">
          <PrintProfilePage />
        </RoutePageContainer>
        <RoutePageContainer path="/8drfgerd9">
          <PrintSummaryPage />
        </RoutePageContainer>
        <RoutePageContainer path="/o3wui4ht/icons">
          <IconPage />
        </RoutePageContainer>
        <Router>
          <App />
        </Router>
      </Switch>
    </BrowserRouter>
  </AppProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
