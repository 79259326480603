import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import { StyledComponentProps } from "../../types/default";

const PanelPrintItem: React.FC<StyledComponentProps> = ({
  className,
  children,
}) => <li className={className}>{children}</li>;

export default styled(PanelPrintItem)`
  position: relative;
  vertical-align: middle;
  width: 100%;
  line-height: 13px;

  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :before {
    float: left; /* Let the before pseudo element start from the leftmost position of each list item */
    width: 0;
    white-space: nowrap;
    content: "..........................................................................................................";
    color: ${Color.GREY5};
    font-weight: bold;
  }
`;
