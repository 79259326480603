import { DatabaseProduct } from "../data/definitions/DatabaseProduct";
import { GigaKombiEntry, WithsObject } from "../types/application";
import {
  calculateGigaKombiValues,
  SimpleGigaKombiValues,
} from "./gigakombi/calculateGigaKombiValues";
import { getCircleDataFromGigaValues } from "./gigakombi/getCircleDataFromGigaValues";

export default (
  withsObject: WithsObject,
  products: DatabaseProduct[]
): GigaKombiEntry[] => {
  const gigaEntries: GigaKombiEntry[] = [];

  let withsCount = 0;
  withsCount += withsObject.mobile ? 1 : 0;
  // withsCount += withIot ? 1 : 0;
  withsCount += withsObject.fixed ? 1 : 0;
  withsCount += withsObject.tv ? 1 : 0;

  if (withsCount === 0) {
    gigaEntries.push(
      getGigaKombiEntryFromValues(
        { mobile: true, iot: false, fixed: false, tv: true },
        [5, 0, false],
        products
      ),
      getGigaKombiEntryFromValues(
        { mobile: false, iot: false, fixed: true, tv: true },
        [5, 0, false],
        products
      ),
      getGigaKombiEntryFromValues(
        { mobile: true, iot: false, fixed: true, tv: false },
        [10, 99, false],
        products
      ),
      getGigaKombiEntryFromValues(
        { mobile: true, iot: false, fixed: true, tv: true },
        [15, 99, true],
        products
      )
    );
  }

  const withs = Object.values(withsObject);
  const withsKeys = Object.keys(withsObject) as Array<keyof WithsObject>;
  if (withsCount > 0 && withsCount < 3) {
    for (let i = 0; i < withs.length; i++) {
      if (!withs[i]) {
        const newWithsObject: WithsObject = Object.assign({}, withsObject);
        if (withsCount === 2) {
          // once with
          newWithsObject[withsKeys[i]] = true;
          const valuesWith = calculateGigaKombiValues(newWithsObject, products);
          gigaEntries.push(
            getGigaKombiEntryFromValues(newWithsObject, valuesWith, products)
          );
          // TODO: once with and without someone not, which is with
        }
        if (withsCount === 1) {
          // once with
          newWithsObject[withsKeys[i]] = true;
          const valuesWith = calculateGigaKombiValues(newWithsObject, products);
          gigaEntries.push(
            getGigaKombiEntryFromValues(newWithsObject, valuesWith, products)
          );
        }
      }
    }
  }

  if (withsObject.mobile && !withsObject.fixed && !withsObject.tv) {
    const specificWithsObject = {
      mobile: false,
      iot: false,
      fixed: true,
      tv: true,
    };
    const valuesWith = calculateGigaKombiValues(specificWithsObject, products);
    gigaEntries.push(
      getGigaKombiEntryFromValues(specificWithsObject, valuesWith, products)
    );
  }
  if (!withsObject.mobile && !withsObject.fixed && withsObject.tv) {
    const specificWithsObject = {
      mobile: true,
      iot: false,
      fixed: true,
      tv: false,
    };
    const valuesWith = calculateGigaKombiValues(specificWithsObject, products);
    gigaEntries.push(
      getGigaKombiEntryFromValues(specificWithsObject, valuesWith, products)
    );
  }

  if (withsCount === 1 || withsCount === 3) {
    // with all
    const trueWithsObject = { mobile: true, iot: true, fixed: true, tv: true };
    const valuesWith = calculateGigaKombiValues(trueWithsObject, products);
    gigaEntries.push(
      getGigaKombiEntryFromValues(trueWithsObject, valuesWith, products)
    );
  }

  return gigaEntries.sort((a, b) => a.circleData.length - b.circleData.length);
};

function getGigaKombiEntryFromValues(
  withsObject: WithsObject,
  values: SimpleGigaKombiValues,
  products: DatabaseProduct[] = []
): GigaKombiEntry {
  const { circleData } = getCircleDataFromGigaValues(
    withsObject,
    values,
    products
  );
  const { mobile, iot, fixed, tv } = withsObject;
  return {
    mobile: mobile,
    iot: iot,
    fixed: fixed,
    tv: tv,
    circleData: circleData,
  };
}
