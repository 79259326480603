import React from "react";
import styled from "styled-components";
import useBootstrap from "../../hooks/useBootstrap";
import { useNavigation } from "../../hooks/useNavigation";
import { useRefEffect } from "../../hooks/common/useRefHook";
import { useInterests } from "../../hooks/useFormHelper/useInterests";
import { StyledComponentProps } from "../../types/default";
import GigaKombiInterestButton from "../elements/recommendationPage/gigakombi/GigaKombiInterestButton";
import { Color } from "../../constants/color";
import Column from "../grid/Column";
import JustifiedColumnsContainer from "../grid/JustifiedColumnsContainer";
import IButton from "../form/IconButton";
import Icons from "../../icons/Icons";
import { Interest } from "../../types/application";
import IconTextDisplayContainer from "../elements/initialInterestPage/IconTextDisplayContainer";
import { InitialMainAdvantages } from "../../constants/InitialMainAdvantages";
import "../form/TileButton";
import ProviderModal from "../elements/providerModal/ProviderModal";

const InitialInterestPage: React.FC<StyledComponentProps> = ({ className }) => {
  useBootstrap();
  const {
    finishInitialization,
    toggleInitialInterest,
    toggleArrayOfInterests,
  } = useNavigation();

  // set completeInitialize-flag
  useRefEffect(() => {
    return finishInitialization;
  }, []);

  const { getSimpleWithsObject } = useInterests();
  const simpleWithObject = getSimpleWithsObject();

  return (
    <div className={className}>
      <JustifiedColumnsContainer
        extraClassName={"extra-productline-container"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
      >
        <Column minWidth={"300px"} maxWidth={"300px"}>
          <div className={"interest-button-container"}>
            <GigaKombiInterestButton
              withsObject={simpleWithObject}
              onChangeInterest={(interest) => {
                if (!interest) {
                  toggleArrayOfInterests([
                    Interest.MOBILE,
                    Interest.FIXED,
                    Interest.TV,
                  ]);
                }
                if (!!interest) {
                  toggleInitialInterest(interest);
                }
              }}
            />
          </div>
        </Column>
        <Column minWidth={"initial"}>
          <JustifiedColumnsContainer
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-around"}
          >
            <Column
              minWidth={"initial"}
              maxWidth={"117px"}
              onClick={() => {
                toggleInitialInterest(Interest.IOT);
              }}
            >
              <IButton
                icon={Icons.iotSim}
                size={100}
                onChange={() => {}}
                backgroundColor={
                  simpleWithObject.iot
                    ? Color.WHITE
                    : Color.GREY_DISABLED_ICON_BACKGROUND
                }
                color={simpleWithObject.iot ? Color.RED : Color.GREY}
              />
              <p className={"iot-headline"}>IoT</p>
            </Column>
            <ProviderModal />
          </JustifiedColumnsContainer>
        </Column>
      </JustifiedColumnsContainer>
      <IconTextDisplayContainer
        extraClassName={"advantage-container"}
        items={InitialMainAdvantages}
      />
    </div>
  );
};

export default styled(InitialInterestPage)`
  position: relative;
  background: linear-gradient(135deg, ${Color.RED_DARK2}, ${Color.RED});
  color: ${Color.WHITE};

  max-width: 1000px;
  width: calc(100% - 100px);
  margin: 0 auto;
  padding: 10px;
  .iot-headline {
    font-size: 24px;
    text-align: center;
    padding: 5px 0;
  }
`;
