import React from "react";
import styled from "styled-components";
import { useNewOfferState } from "../../../../hooks/useOfferHelper/useNewOfferState";
import { Interest } from "../../../../types/application";
import RecommendationColumn, {
  RecommendationColumnProps,
} from "./RecommendationColumn";
import EmptyRecommendationColumn from "./EmptyRecommendationColumn";
import { OuterLinks } from "../../../../data/definitions/OuterLinks";

interface RecommendationColumnContainerProps
  extends Omit<RecommendationColumnProps, "informationLink" | "mainColumn"> {}

const RecommendationColumnContainer: React.FC<RecommendationColumnContainerProps> =
  ({ className, offerEntryId, children, ...restProps }) => {
    const [type] = useNewOfferState("type", offerEntryId);
    const [mainRow] = useNewOfferState("mainRow", offerEntryId);

    let informationLink = undefined;
    if (type === Interest.MOBILE) {
      informationLink = OuterLinks.HARDWARE_PRICES;
    }

    return (
      <React.Fragment key={offerEntryId}>
        {offerEntryId !== "" && (
          <RecommendationColumn
            offerEntryId={offerEntryId}
            informationLink={informationLink}
            mainColumn={mainRow}
            {...restProps}
          />
        )}
        {offerEntryId === "" && <EmptyRecommendationColumn {...restProps} />}
        {children}
      </React.Fragment>
    );
  };

export default styled(RecommendationColumnContainer)``;
