import { BootstrapState } from "../definitions/BootstrapState";
import { defaultFormConfigState } from "./defaultFormConfigState";
import { DisplayErrorMessages } from "../../constants/DisplayErrorMessages";
import { ErrorCodes } from "../../constants/ErrorCodes";

export const defaultBootstrapState: BootstrapState = {
  loading: false,
  isLoaded: false,
  formConfig: defaultFormConfigState,
  errorMessage: DisplayErrorMessages.nothing,
  errorCode: ErrorCodes.nothing,
};
