import * as React from "react";

function Svg4G(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;

  return (
    <svg
      id="4g_svg___x33_c54d754-f349-44b2-8061-d7aa617e1d45"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <path fill="none" d="M0 0h192v192H0z" />
      <path
        className="4g_svg__st1"
        d="M122 98h14v12c.1 4.4-2.8 8.3-7 9.5-8.3 2.3-25 3.7-25-23.5 0-29.5 26.6-25.9 30.5-18.5M80 72v50M88 108H54l26-36"
        fill="none"
        stroke={color}
        strokeWidth={8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={96}
        cy={96}
        r={76}
        fill="none"
        stroke={color}
        strokeWidth={8}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default Svg4G;
