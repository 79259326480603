import React from "react";
import styled from "styled-components";
import { SlidePages } from "../../constants/SlidePages";
import LinkButton from "../form/LinkButton";
import { StyledComponentProps } from "../../types/default";
import JustifiedColumnsContainer from "../grid/JustifiedColumnsContainer";
import Shopping from "../../icons/svgComponents/Shopping";

interface OfferPaginationProps extends StyledComponentProps {}

const OfferPagination: React.FC<OfferPaginationProps> = ({ className }) => {
  return (
    <JustifiedColumnsContainer
      extraClassName={className}
      justifyContent={"space-between"}
    >
      <LinkButton
        startIcon={<Shopping />}
        slideId={SlidePages.summaryRecommendation.id}
        title={"Zurück zum Warenkorb"}
        textAlign={"left"}
      />
    </JustifiedColumnsContainer>
  );
};

export default styled(OfferPagination)`
  margin: 0 5px;
`;
