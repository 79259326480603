import * as React from "react";

function SvgIdea(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      version="1.1"
      id="idea_svg___x33_c54d754-f349-44b2-8061-d7aa617e1d45"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style type="text/css">
        {`.idea-st1{fill:none;stroke:${color};stroke-width:8}`}
        {`.idea-st2{fill:none;stroke:${color};stroke-width:8}`}
      </style>
      <path
        className="idea-st1"
        d="M122.2,140c0,0,0-21.8,13.9-31.3C152.5,97.5,152,78,152,78c0-32-24.7-58-56-58S40,46,40,78
	c0,0-0.5,19.5,15.9,30.7c13.9,9.5,13.9,31.3,13.9,31.3H122.2z"
      />
      <path className="idea-st2" d="M74.2,156h43.6 M74.2,172h43.6" />
    </svg>
  );
}

export default SvgIdea;
