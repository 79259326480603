import React from "react";
import VerticalSelector from "../VerticalSelector";
import ColorIcons from "../../../icons/ColorIcons";
import GreyIcons from "../../../icons/GreyIcons";
import SVGIcon from "../../svgIcon/SVGIcon";
import { ProductBodyData } from "../../../types/application";
import { REBATE_MAX_DATAVOLUME } from "../../../constants/default";
import styled from "styled-components";
import { Color } from "../../../constants/color";

interface ProductContainerSelectorBodyProps {
  className?: string;
  body: ProductBodyData;
  setIndex?: (newValue: number) => void;
  dataVolume?: number;
  setDataVolume?: (value: number) => void;
  dataVolumeSteps?: number;
  dataVolumeMax?: number;
  extraDataVolume?: number;
}

const ProductContainerSelectorBody: React.FC<ProductContainerSelectorBodyProps> =
  ({
    className,
    body,
    dataVolume,
    setDataVolume,
    extraDataVolume = 0,
    dataVolumeSteps,
    dataVolumeMax = REBATE_MAX_DATAVOLUME,
  }) => {
    const { preLabel, label, postLabel } = body;
    const dataVolumeMaxValue: number = dataVolumeMax ? dataVolumeMax : 0;

    const displayedValue = dataVolume
      ? Math.min(dataVolume, dataVolumeMaxValue)
      : 0;
    const isMaxValue = displayedValue >= dataVolumeMaxValue;

    const displayedExtendedValue = extraDataVolume
      ? Math.min(extraDataVolume + displayedValue, dataVolumeMaxValue)
      : 0;
    const isExtendedMaxValue = displayedExtendedValue >= dataVolumeMaxValue;

    return (
      <div className={className}>
        <p>
          {preLabel && preLabel + " "}
          <span style={{ fontWeight: "bold" }}>{label}</span>
          {postLabel && " " + postLabel}
        </p>
        {dataVolume !== undefined &&
          !!setDataVolume &&
          extraDataVolume === 0 && (
            <VerticalSelector
              setValue={setDataVolume}
              value={dataVolume}
              extraValue={extraDataVolume}
              steps={dataVolumeSteps}
              max={dataVolumeMax}
              currency={"GB"}
              selected={!!extraDataVolume}
            />
          )}
        {dataVolume !== undefined && (!setDataVolume || extraDataVolume > 0) && (
          <div className={"alone-value-field"}>
            {isMaxValue && <UnlimitedGB red={!!extraDataVolume} />}
            {!isMaxValue && displayedExtendedValue && (
              <ReplacedValue
                oldValue={displayedValue}
                newValue={displayedExtendedValue}
                newIsUnlimited={isExtendedMaxValue}
              />
            )}
            {!isMaxValue && !displayedExtendedValue && (
              <p>{displayedValue} GB</p>
            )}
          </div>
        )}
      </div>
    );
  };

const ReplacedValue = ({
  oldValue,
  newValue,
  newIsUnlimited,
}: {
  oldValue: number;
  newValue: number;
  newIsUnlimited: boolean;
}) => {
  return (
    <div>
      <p className={"old-value"}>{oldValue} GB</p>
      <p className={"new-value"}>
        {newIsUnlimited ? <UnlimitedGB red={true} /> : <>{newValue} GB</>}
      </p>
    </div>
  );
};

const UnlimitedGB = ({ red }: { red: boolean }) => (
  <>
    <SVGIcon
      size={25}
      src={red ? ColorIcons.unlimited : GreyIcons.unlimited}
      style={{
        padding: "0 0 6px 0",
        verticalAlign: "middle",
      }}
    />
    {" GB"}
  </>
);

export default styled(ProductContainerSelectorBody)`
  display: inline-block;
  .alone-value-field {
    text-align: center;
    padding: 0 5px;
    margin: 0 2px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
    color: ${Color.RED};
    vertical-align: middle;
  }
  .old-value {
    color: ${Color.GREY};
    text-decoration: 2px line-through;
    margin-right: 2px;
  }
  .new-Value {
  }
`;
