import * as React from "react";
import GigaKombiInfoModal from "./GigaKombiInfoModal";
import GigaKombiErrorModal from "./GigaKombiErrorModal";
import { useInterestsValues } from "../../../../hooks/useFormHelper/useInterests";
import { useOfferState } from "../../../../hooks/useOffer";

const GigaKombiModalsContainer = () => {
  const recommendationModes = useInterestsValues();
  const gigaInfoTextState = useOfferState("gigaInfoText");
  const gigaErrorTextState = useOfferState("gigaErrorText");

  return (
    <>
      <GigaKombiInfoModal
        recommendationModes={recommendationModes}
        gigaInfoTextState={gigaInfoTextState}
      />
      <GigaKombiErrorModal gigaErrorTextState={gigaErrorTextState} />
    </>
  );
};

export default GigaKombiModalsContainer;
