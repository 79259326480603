import React from "react";
import styled from "styled-components";
import SVGIcon from "../../../../svgIcon/SVGIcon";
import ColorIcons from "../../../../../icons/ColorIcons";
import { StyledComponentProps } from "../../../../../types/default";

const PrintPlusColumn: React.FC<StyledComponentProps> = ({ className }) => {
  return (
    <div className={className}>
      <span>
        <SVGIcon src={ColorIcons.plus} />
      </span>
    </div>
  );
};

export default styled(PrintPlusColumn)`
  padding: 0 5px;
  width: 5%;
  display: table-cell;
  vertical-align: middle;
  text-align: center;

  img,
  svg {
    max-width: 100%;
  }
`;
