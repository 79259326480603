import React from "react";
import useBootstrap from "../../hooks/useBootstrap";
import { useFormState } from "../../hooks/useForm";
import ImageTileRadioButtonList from "../form/ImageTileRadioButtonList";

const TVInvoicePage = () => {
  const { formConfig } = useBootstrap();
  const [additionalCostInvoice, setAdditionalCostInvoice] = useFormState(
    "additionalCostInvoice"
  );
  return (
    <ImageTileRadioButtonList
      data={formConfig.additionalCostInvoice}
      values={additionalCostInvoice ? [additionalCostInvoice] : []}
      onChange={(values) => {
        if (additionalCostInvoice !== values[0]) {
          setAdditionalCostInvoice(values[0]);
        }
      }}
    />
  );
};

export default TVInvoicePage;
