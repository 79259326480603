import React from "react";
import styled from "styled-components";
import SelectionModal from "./AFOSelectionModal";
import { Color } from "../../../constants/color";
import { TableCellType, TableDataObject } from "../../../types/table";

interface AFOLabelSelectionCellProps {
  className?: string;
  value: string;
  dataId: number;
  dataSet: TableDataObject;
  changeLabelId: (newLabelId: number, objectId: number) => void;
  setOpenSelectionId: (id: number) => void;
  openSelectionId: number;
  colSpan?: number;
}

const AFOLabelSelectionCell: React.FC<AFOLabelSelectionCellProps> = ({
  className,
  dataId,
  dataSet,
  changeLabelId,
  setOpenSelectionId,
  openSelectionId,
  value,
  colSpan = 1,
}) => (
  <td className={className + " cell row-label"} colSpan={colSpan}>
    {dataId * 3 + 0 === openSelectionId &&
      dataSet.labelType === TableCellType.SELECTION && (
        <SelectionModal
          data={dataSet.labels}
          selectedId={dataSet.labelId}
          changeId={(valueId: number) => {
            changeLabelId(valueId, dataId);
            setOpenSelectionId(-1);
          }}
        />
      )}

    <div
      onClick={() => setOpenSelectionId(dataId * 3 + 0)}
      className={"cell-content"}
    >
      {value}
    </div>
  </td>
);

export default styled(AFOLabelSelectionCell)`
  text-align: left;
  background: ${Color.GREY_LIGHT3};
  cursor: ${({ dataSet }) =>
    dataSet.labelType === TableCellType.SELECTION ? "pointer" : "default"};
`;
