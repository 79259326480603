import * as React from "react";

function SvgSupport(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="support_svg___x38_bfafdaa-7834-4462-a075-af263c453315"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.support_svg__st0{stroke-linecap:round}.support_svg__st0,.support_svg__st1{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-miterlimit:10}`}
      </style>
      <g id="support_svg__fd3336ae-18d7-412e-8e0b-f3ab89e72954">
        <g id="support_svg___x32_fd8e03a-4254-4bb6-82d2-34064c2f29c9">
          <path
            className="support_svg__st0"
            d="M272.6 166.4L20.3 418.7c-16.6 16.6-16.7 43.5-.1 60.1l.1.1c16.6 16.6 43.5 16.7 60.1.1l.1-.1 252.3-252.3M319.7 318.1c16.6-16.6 43.5-16.6 60.1 0l100.6 100.6c16.6 16.6 16.6 43.5 0 60.1s-43.5 16.6-60.1 0L319.7 378.2c-16.6-16.6-16.6-43.5 0-60.1zM421.5 17l-55.1 57.3c-2.5 2.6-4 6.1-4 9.8v38.3c0 7.8 6.3 14.2 14.2 14.2h39.8c3.8 0 7.4-1.5 10-4.1l54.7-54.8"
          />
          <path
            className="support_svg__st1"
            d="M481.3 77.9c24.5 57.6-2.3 124.1-59.8 148.6-28.3 12.1-60.3 12.1-88.7.1M272.6 166.4c-24.5-57.6 2.3-124.1 59.9-148.6 28.3-12 60.2-12.1 88.5-.1"
          />
          <path
            className="support_svg__st0"
            d="M219.1 219.9L20.8 21.6M318.5 319.3l-39.4-39.4"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSupport;
