import * as React from "react";
import { getHEXNumber } from "../../helper/getHEXNumber";

function SvgTv(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  const colorString = getHEXNumber(color);

  return (
    <svg
      id="tv_svg___x32_b9f3c17-2e5d-42c6-8f13-121506996f00"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.tv_svg__st0-${colorString}{fill:none;stroke:${color};stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className={`tv_svg__st0-${colorString}`}
        d="M33 58.6h434c14.1 0 25.5 11.4 25.5 25.5v280.8c0 14.1-11.4 25.5-25.5 25.5H33c-14.1 0-25.5-11.4-25.5-25.5V84.1C7.5 70 18.9 58.6 33 58.6zM135.1 441.4h229.8M250 403.2v31.9"
      />
    </svg>
  );
}

export default SvgTv;
