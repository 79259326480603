import * as React from "react";

function SvgPrivateHdColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="privateHd_color_svg__cf5551d2-88b0-4d71-968a-c1f38d887bf5"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".privateHd_color_svg__st0{fill:none;stroke:red;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <path
        className="privateHd_color_svg__st0"
        d="M103.8 72.1V104s16.5 2.7 16.5-15.9-16.5-16-16.5-16zM71.8 103.9V72M87.8 103.9V72M71.8 87.9h12"
      />
      <path
        className="privateHd_color_svg__st0"
        d="M28 36.1h136c4.4 0 8 3.6 8 8v88c0 4.4-3.6 8-8 8H28c-4.4 0-8-3.6-8-8v-88c0-4.5 3.6-8 8-8zM60 156.1h72M96 144.1v10"
      />
      <path fill="none" d="M0 0h192v192H0z" />
    </svg>
  );
}

export default SvgPrivateHdColor;
