import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";

interface AFOPriceDisplayCellProps {
  className?: string;
  value: number;
  unit: string;
}

const AFOPriceDisplayCell: React.FC<AFOPriceDisplayCellProps> = ({
  className,
  unit,
  value,
}) => (
  <td className={className + " cell row-label"}>
    <div className={"cell-content"}>
      <span className={"value-container"}>{value.toFixed(2)}</span>
      <span className={"currency-symbol"}>{unit}</span>
    </div>
  </td>
);

export default styled(AFOPriceDisplayCell)`
  background: ${Color.GREY_LIGHT};
  cursor: not-allowed;

  .value-container {
    display: inline-block;
    width: 60%;
    text-align: right;
    padding-right: 1px;
  }
  .currency-symbol {
    display: inline-block;
    width: 40%;
    text-align: left;
    padding-left: 1px;
  }
`;
