import * as React from "react";

function SvgMobileAndTabletGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#333" } = props;
  return (
    <svg
      id="mobileAndTablet_grey_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 485 485"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.mobileAndTablet_grey_svg__st1{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <g id="mobileAndTablet_grey_svg___x34_77c9568-4616-4e7d-8a93-d3e81ad96e71">
        <g id="mobileAndTablet_grey_svg__d8270b65-dc5d-4366-9ca5-179c9a9ef0d9">
          <g id="mobileAndTablet_grey_svg___x34_fbc527c-d5f1-4e1b-8a37-ae73bb9be4c5">
            <path
              id="mobileAndTablet_grey_svg___x35_79aef82-b57d-41d6-b076-c0e0fc618d42"
              fill="none"
              stroke={color}
              strokeWidth={24.173}
              strokeLinecap="round"
              strokeMiterlimit={10}
              d="M254.7 336.2h-61.1"
            />
            <path
              className="mobileAndTablet_grey_svg__st1"
              d="M291.4 115.9h97.9c20.3 0 36.7 16.4 36.7 36.7v244.7c0 20.3-16.4 36.7-36.7 36.7h-97.9c-20.3 0-36.7-16.4-36.7-36.7V152.6c0-20.3 16.5-36.7 36.7-36.7zM315.9 385.1h49"
            />
            <path
              className="mobileAndTablet_grey_svg__st1"
              d="M254.7 385.1h-208c-20.3 0-36.7-16.4-36.7-36.7V103.7C10 83.4 26.4 67 46.7 67h391.6c20.3 0 36.7 16.4 36.7 36.7v244.7c0 20.3-16.4 36.7-36.7 36.7h-12.2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgMobileAndTabletGrey;
