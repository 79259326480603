import * as React from "react";

function SvgPersonGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#4a4d4e" } = props;
  return (
    <svg
      id="person_grey_svg___x36_a8da56c-64cb-4c0d-b7e3-2b80c0db2d07"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.person_grey_svg__st0{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <circle
        id="person_grey_svg___x33_ad8aabf-2066-47d6-9a2f-c027e5c19606"
        className="person_grey_svg__st0"
        cx={96}
        cy={63.9}
        r={36.1}
      />
      <path
        id="person_grey_svg___x32_43fad7b-53d4-4daa-93b6-31f40d832ead"
        className="person_grey_svg__st0"
        d="M156 164.1c-6.5-43.9-33.2-64.2-59.6-64.2S42.5 120.2 36 164.1"
      />
      <path fill="none" d="M0 0h192v192H0z" />
    </svg>
  );
}

export default SvgPersonGrey;
