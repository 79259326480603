import React from "react";
import styled from "styled-components";
import { DisplayErrorMessages } from "../../../../constants/DisplayErrorMessages";
import ErrorModal from "../../../error/ErrorModal";
import { SimpleState } from "../../../../types/default";

interface GigaKombiErrorModalProps {
  className?: string;
  gigaErrorTextState: SimpleState<DisplayErrorMessages>;
}

const GigaKombiErrorModal: React.FC<GigaKombiErrorModalProps> = ({
  gigaErrorTextState,
}) => {
  const [gigaErrorText, setGigaErrorText] = gigaErrorTextState;
  const setGigaKombiErrorText = (newValue: boolean) => {
    if (!newValue) {
      setGigaErrorText(DisplayErrorMessages.nothing);
    }
  };
  return (
    <ErrorModal
      isOpen={gigaErrorText !== DisplayErrorMessages.nothing}
      setIsOpen={setGigaKombiErrorText}
      textKey={gigaErrorText}
      headline={"Die GigaKombi ist nicht möglich!"}
    />
  );
};

export default styled(GigaKombiErrorModal)``;
