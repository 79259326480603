import { testObjectIsFromClass } from "./default/testObjectIsFromClass";
import { RecommendationDevelopmentConfig } from "../../data/definitions/RecommendationDevelopmentConfig";

export default (
  unsureRecommendationDevelopmentConfigObject: any
): [RecommendationDevelopmentConfig, boolean] => {
  let result = true;

  const testResult = testObjectIsFromClass(
    unsureRecommendationDevelopmentConfigObject,
    RecommendationDevelopmentConfig,
    true
  );
  if (testResult !== "") {
    console.error(
      "File recommendationDevelopmentConfigObject has a false structure:",
      testResult
    );
    result = false;
  }
  return [
    unsureRecommendationDevelopmentConfigObject as RecommendationDevelopmentConfig,
    result,
  ];
};
