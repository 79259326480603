export function getArrayFromArray(valueArray?: any[][], id?: number) {
  let returnValue = [];
  if (
    id !== undefined &&
    valueArray !== undefined &&
    valueArray[id] !== undefined
  ) {
    returnValue = valueArray[id];
  }
  return returnValue;
}

export function getStringFromArray(
  valueArray?: string[],
  id?: number
): string | undefined {
  let returnValue = undefined;
  if (
    id !== undefined &&
    valueArray !== undefined &&
    valueArray[id] !== undefined
  ) {
    returnValue = valueArray[id];
  }
  return returnValue;
}

export function getNumberFromArray(
  valueArray?: number[],
  id?: number,
  defaultValue?: number
) {
  let returnValue = defaultValue ?? 0;
  if (
    id !== undefined &&
    valueArray !== undefined &&
    valueArray[id] !== undefined
  ) {
    returnValue = valueArray[id];
  }
  return returnValue;
}
