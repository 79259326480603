import React from "react";
import styled from "styled-components";
import { PanelGroupTitleProps } from "./PanelGroupTitle";
import { Color } from "../../constants/color";

interface PanelGroupProps {
  className?: string;
  title?: React.ReactElement<
    PanelGroupTitleProps,
    React.FC<PanelGroupTitleProps>
  >;
  backgroundColor?: string;
  titleBackgroundColor?: string;
  fontColor?: string;
  borderColor?: string;
  margin?: string;
}

const PanelGroup: React.FC<PanelGroupProps> = ({
  className,
  children,
  title,
}) => (
  <div className={className}>
    <div className={"panel-title-container"}>{title}</div>
    <div className={"panel-group-content"}>{children}</div>
  </div>
);

export default styled(PanelGroup)`
  margin: ${({ margin }) => (margin ? margin : "0 5px")};
  border: ${({ borderColor }) =>
    borderColor ? "1px solid " + borderColor : "none"};
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : ""};
  .panel-title-container {
    background: ${({ titleBackgroundColor }) =>
      titleBackgroundColor ? titleBackgroundColor : Color.WHITE};
    color: ${({ fontColor }) => (fontColor ? fontColor : Color.GREY)};
  }
`;
