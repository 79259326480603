import React from "react";
import styled from "styled-components";
import Modal from "../../../modal/Modal";
import GigaKombi from "./GigaKombi";
import IButton from "../../../form/IconButton";
import { Color } from "../../../../constants/color";
import { GigaKombiEntry } from "../../../../types/application";

interface GigaKombiModalProps {
  data: GigaKombiEntry[];
  className?: string;
}

const GigaKombiModal: React.FC<GigaKombiModalProps> = ({ className, data }) => {
  return (
    <div className={className + " i-button-container"}>
      <Modal
        openButton={(onChange, value) => (
          <IButton
            onChange={onChange}
            value={value}
            backgroundColor={"transparent"}
          />
        )}
        modalColor={`linear-gradient(270deg, ${Color.RED}, ${Color.RED_DARK})`}
        fontColor={Color.WHITE}
      >
        <div className={"giga-kombi-modal-container"}>
          <p className={"giga-kombi-modal-headline"}>
            Das sind Deine Kombi-Vorteile jeden Monat:
          </p>
          <div className={"giga-kombi-data-container"}>
            {data.map((v, i) => {
              return (
                <GigaKombi
                  key={i}
                  selected
                  backgroundColor={"transparent"}
                  gigaKombiEntry={v}
                  withStar={true}
                  starText={i + 1 === data.length ? "tarifabhängig" : undefined}
                />
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default styled(GigaKombiModal)`
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;

  .giga-kombi-modal-container {
    margin: 0 10px;
    .giga-kombi-modal-headline {
      text-align: left;
      font-size: 20px;
      font-weight: bold;
      margin-left: 20px;
    }
    .giga-kombi-data-container {
      margin-left: 25px;
    }
  }
`;
