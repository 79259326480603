import * as React from "react";

function SvgEuro(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="euro_svg__ICON"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.euro_svg__st0{fill:none;stroke:${color};stroke-width:24.1732;stroke-linecap:round;stroke-miterlimit:10}`}
      </style>
      <path
        d="M404.2 62.9c103.4 85.2 118.1 238 32.9 341.4s-238 118.1-341.4 32.9-118.1-238-32.9-341.4C108.9 39.9 177.6 7.5 250 7.5c56.3 0 110.8 19.5 154.2 55.4"
        fill="none"
        stroke={color}
        className={`euro_svg__st0`}
        strokeWidth={24.173}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={`euro_svg__st0`}
        d="M313.8 329.8c-10.8 5.2-23.8 9.1-36.4 9.1-47 0-73.6-39.9-73.6-89.1s26.6-89.1 73.6-89.1c13.6 0 26.4 3.3 37.8 9.3M170.2 275.5h92.5M262.7 224.5H159.2"
      />
    </svg>
  );
}

export default SvgEuro;
