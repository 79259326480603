import * as React from "react";
import { StyledComponentProps } from "../../../../types/default";
import styled from "styled-components";
import { Color } from "../../../../constants/color";
import OfferEntryPriceSummary from "./OfferEntryPriceSummary";
import SVGChevron from "../../../../icons/svgComponents/Chevron";
import { MonthlyPriceArray } from "../../../../types/application";

export interface OfferEntryProps extends StyledComponentProps {
  isOpen?: boolean;
  setIsOpen?: (fct: (prev: boolean) => boolean) => void;
  title?: string;
  productTitle?: string;
  monthlyPriceArray?: MonthlyPriceArray;
  oncePrice?: number;
  main?: boolean;
  withLabel?: boolean;
}

const OfferEntry = ({
  className,
  isOpen = false,
  setIsOpen,
  title = "",
  monthlyPriceArray = {},
  oncePrice = 0,
  productTitle = "",
  withLabel,
}: OfferEntryProps) => {
  const isOpenable = Object.keys(monthlyPriceArray).length > 1;
  return (
    <div
      className={className}
      onClick={() => {
        if (isOpenable && setIsOpen) {
          setIsOpen((v: boolean) => !v);
        }
      }}
    >
      <div className={"offer-entry-main-container"}>
        <span className={"offer-entry-main-label"}>{title}</span>
        <span className={"offer-entry-main-monthly-price"}>
          {withLabel
            ? "Monatl. Preis"
            : (monthlyPriceArray[0] ?? 0).toFixed(2) + " €/mtl."}
        </span>
        <span className={"offer-entry-main-once-price"}>
          {withLabel ? "Einmalpreis" : oncePrice.toFixed(2) + " €"}
        </span>
        <span className={"offer-entry-product-title"}>{productTitle}</span>
        <span className={"offer-entry-product-price"} />
        {isOpenable && (
          <span className={"offer-entry-chevron-container"}>
            <SVGChevron color={"#999"} />
          </span>
        )}
      </div>
      {isOpenable && (
        <OfferEntryPriceSummary
          isOpen={isOpen}
          monthlyPriceArray={monthlyPriceArray}
        />
      )}
    </div>
  );
};

export default styled(OfferEntry)`
  display: block;
  min-width: 150px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;

  .offer-entry-main-container {
    display: block;
    min-height: 60px;
    padding-top: 5px;
    text-align: left;
    background-color: ${Color.WHITE};
    > span {
      display: inline-block;
      vertical-align: top;
      padding: 5px 10px;
    }

    .offer-entry-main-label {
      color: ${({ main }) => (main ? Color.RED : Color.BLACK)};
      font-size: 20px;
      font-weight: bold;
      width: 40%;
    }

    .offer-entry-main-monthly-price {
      text-align: right;
      width: 40%;

      font-size: 20px;
      font-weight: ${({ main }) => (main ? "bold" : "normal")};
    }

    .offer-entry-main-once-price {
      text-align: right;
      width: 20%;
      font-size: 20px;
      font-weight: ${({ main }) => (main ? "bold" : "normal")};
    }

    .offer-entry-product-title {
      color: ${({ main }) => (main ? Color.RED : Color.BLACK)};
      font-style: italic;
      width: 40%;
    }

    .offer-entry-product-price {
      color: ${({ main }) => (main ? Color.RED : Color.BLACK)};
      font-style: italic;
      width: 40%;
    }

    .offer-entry-chevron-container {
      text-align: right;
      width: 20%;
      > svg {
        max-width: 16px;
        transform: scaleY(${({ isOpen }) => (isOpen ? -1 : 1)});
        transition: transform 0.5s;
      }
    }
  }
`;
