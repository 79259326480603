import { useContext, useState } from "react";
import { SubmitOfferRequestData } from "./useOffer";
import { AppContext } from "../state/AppContext";
import { useRefEffect } from "./common/useRefHook";
import { useLocation } from "react-router-dom";
import useLocalstorage from "./common/useLocalStorage";
import { getModeKeyFromInterest } from "../helper/recommendation/mapping";
import { RecommendationMode } from "../types/application";
import { ContactState } from "../state/definitions/ContactState";

interface ReceivedOfferRequestData extends SubmitOfferRequestData {
  contact: Omit<ContactState, "loading">;
}

const usePrint = () => {
  const [, setState] = useContext(AppContext);
  const location = useLocation();
  const storageId: string =
    location.search !== undefined && location.search.length > 0
      ? location.search.substr(1)
      : "";

  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [, setLocalStorage] = useLocalstorage("vauoid");

  useRefEffect(() => {
    // then update the state with data from the request
    const requestDataString = window.localStorage.getItem(
      "payload-" + storageId
    );
    const requestData: ReceivedOfferRequestData | undefined =
      requestDataString !== null ? JSON.parse(requestDataString) : undefined;

    if (requestData !== undefined) {
      setState((prev) => {
        setIsConfigLoaded(true);
        setLocalStorage(requestData.vauoid);
        return {
          ...prev,
          vauoid: requestData.vauoid,
          storageId: storageId,
          offerSessionId: requestData.offerSessionId,
          bootstrap: {
            ...prev.bootstrap,
            formConfig: requestData.formConfig,
          },
          form: requestData.form,
          offer: requestData.offer,
          newOffer: requestData.newOffer,
          recommendation: {
            ...prev.recommendation,
            config: requestData.recommendationConfig,
            modes: requestData.productLineModes,
          },
          products: requestData.currentProducts,
          contact: {
            isLoaded: true,
            ...requestData.contact,
          },
        };
      });
    }
  }, [storageId]);

  const cleanOfferEntries = () => {
    setState((prev) => {
      const newNewOffer = Object.assign({}, prev.newOffer);
      const newOfferEntryIds = prev.offer.offerEntryIds.filter(
        (offerEntryId) => {
          const interest = prev.newOffer[offerEntryId].type;
          const modeKey = getModeKeyFromInterest(interest);
          const isSelected =
            prev.recommendation.modes[modeKey] ===
            RecommendationMode.SELECTABLE;
          if (!isSelected) {
            delete newNewOffer[offerEntryId];
          }
          return isSelected;
        }
      );
      return {
        ...prev,
        offer: { ...prev.offer, offerEntryIds: newOfferEntryIds },
        newOffer: newNewOffer,
      };
    });
  };

  return { isLoaded: isConfigLoaded, cleanOfferEntries };
};

export default usePrint;
