import React from "react";
import styled from "styled-components";
import { ProductTitleData } from "../../../types/application";

interface ProductContainerSelectorTitleProps {
  className?: string;
  title: ProductTitleData;
}

const ProductContainerSelectorTitle: React.FC<ProductContainerSelectorTitleProps> = ({
  title,
}) => {
  const { preLabel, label, postLabel } = title;
  return (
    <h3>
      {preLabel && preLabel + " "}
      <span style={{ fontWeight: "bold" }}>{label}</span>
      {postLabel && " " + postLabel}
    </h3>
  );
};

export default styled(ProductContainerSelectorTitle)``;
