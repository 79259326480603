import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import Icons from "../../icons/Icons";
import { useFormState } from "../../hooks/useForm";
import TextDisplay from "../elements/TextDisplay";
import NumberSelector from "../form/horizontalSelector/NumberSelector";
import Slider from "../form/Slider";
import TileButton from "../form/TileButton";
import useBootstrap from "../../hooks/useBootstrap";
import IconTileBoolButtonGrid from "../form/IconTileBoolButtonGrid";
import useContracts from "../../hooks/useFormHelper/useContracts";
import { useRefEffect } from "../../hooks/common/useRefHook";
import { StyledComponentProps } from "../../types/default";

const MobileDataVolumePage: React.FC<StyledComponentProps> = ({
  className,
}) => {
  const { formConfig } = useBootstrap();
  const [mobileDataVolume, setMobileDataVolume] =
    useFormState("mobileDataVolume");
  // preset wishDataVolume with the current performance
  const [mobileCurrentDataVolume] = useFormState("mobileCurrentDataVolume");
  useRefEffect(() => {
    if (
      mobileDataVolume === 0 &&
      mobileCurrentDataVolume !== undefined &&
      mobileCurrentDataVolume !== 0
    ) {
      setMobileDataVolume(mobileCurrentDataVolume);
    }
  }, [mobileDataVolume, mobileCurrentDataVolume]);

  const [mobileDataSocial, setMobileDataSocial] =
    useFormState("mobileDataSocial");
  const [mobileDataMusic, setMobileDataMusic] = useFormState("mobileDataMusic");
  const [mobileDataChat, setMobileDataChat] = useFormState("mobileDataChat");
  const [mobileDataVideo, setMobileDataVideo] = useFormState("mobileDataVideo");
  const [mobileDataGaming, setMobileDataGaming] =
    useFormState("mobileDataGaming");
  if (formConfig.mobileData.length !== 5) {
    console.error(
      "False form Config!  Parameter 'mobileData' needs 5 entries!",
      formConfig.mobileData
    );
  }
  const [mobileDataSharePeople] = useFormState("mobileDataSharePeople");
  const [mobileDataShareKids] = useFormState("mobileDataShareKids");
  const { setNobody, changeAdultContractCount, changeKidContractCount } =
    useContracts();

  const [shareSum, setShareSum] = useState(0);
  useEffect(() => {
    const a = mobileDataSharePeople !== undefined ? mobileDataSharePeople : 0;
    const b = mobileDataShareKids !== undefined ? mobileDataShareKids : 0;
    setShareSum(a + b);
  }, [mobileDataSharePeople, mobileDataShareKids]);

  return (
    <div className={className}>
      <div className={"page-content-container"}>
        <Slider
          min={0}
          max={50}
          value={mobileDataVolume ? mobileDataVolume : 0}
          onChange={setMobileDataVolume}
          afterValue={"GB"}
          description={"Datenvolumen"}
          step={1}
          color={Color.GREY}
          maxString={">50"}
          padding={"50px 0"}
        />
        <IconTileBoolButtonGrid
          data={formConfig.mobileData}
          values={[
            mobileDataSocial,
            mobileDataMusic,
            mobileDataChat,
            mobileDataVideo,
            mobileDataGaming,
          ]}
          onChanges={[
            setMobileDataSocial,
            setMobileDataMusic,
            setMobileDataChat,
            setMobileDataVideo,
            setMobileDataGaming,
          ]}
        />
        <p className={"person-count-question"}>
          Mit wie vielen Personen könntest Du Dein Datenvolumen im Haushalt
          teilen?
        </p>
        <div className={"person-counter-selector-container"}>
          <PeronCounter
            currentValue={mobileDataSharePeople ? mobileDataSharePeople : 0}
            max={
              mobileDataShareKids !== undefined ? 4 - mobileDataShareKids : 4
            }
            min={0}
            setValue={changeAdultContractCount}
            selected
            image={Icons.person}
            smallVersion
          />
          <PeronCounter
            currentValue={mobileDataShareKids ? mobileDataShareKids : 0}
            max={
              mobileDataSharePeople !== undefined
                ? 4 - mobileDataSharePeople
                : 4
            }
            min={0}
            setValue={changeKidContractCount}
            selected
            image={Icons.kid}
            smallVersion
          />
          {shareSum > 0 && (
            <NobodyButton
              value={shareSum === 0}
              onChange={setNobody}
              color={shareSum === 0 ? Color.RED : Color.GREY}
            >
              Niemanden
            </NobodyButton>
          )}
        </div>
        <div className={"person-count-display"}>
          <TextDisplay
            label={"Gesamtanzahl Personen:"}
            value={String(shareSum + 1)} // +1 for the user
          />
        </div>
      </div>
    </div>
  );
};
const NobodyButton = styled(TileButton)`
  display: inline-block;
  vertical-align: top;
  padding: 25px;
  margin: 5px;
`;
const PeronCounter = styled(NumberSelector)`
  display: inline-block;
  vertical-align: top;
`;
export default styled(MobileDataVolumePage)`
  .page-content-container {
    .person-count-question {
      font-size: 24px;
      //font-weight: bold;
      margin: 20px 50px;
    }
    .person-counter-selector-container {
      margin: 10px 50px;
      display: block;
      @media (min-width: 812px) {
        text-align: left;
      }
      @media (max-width: 812px) {
        text-align: center;
      }
    }
    .person-count-display {
      display: block;
      text-align: center;
      margin: 20px 0 10px 0;
    }
  }
`;
