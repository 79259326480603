export class SubProductsTableConfig {
  tables: string[] = [];
  subtitles: string[] = [];
  additionalLabels: string[] = []; //until 5
  additionalCounts: number[] = []; //until 5
  additionalCountIds: number[] = []; //until 5
  additionalPrices: number[] = []; //until 5
  additionalStarts: number[] = []; //until 5
  furtherProductId: number[] = []; //until 4
  furtherCounts: number[] = []; //until 5
  furtherProductCountId: number[] = []; //until 4
  furtherOneProductLabel?: string = undefined; //  lastLine
  furtherOneProductCount: number = 0; // lastLine
  furtherOneProductPrice: number = 0; // lastLine
  singlePaymentLabels: string[] = []; // until 5
  singlePaymentPrices: number[] = []; // until 5
}
