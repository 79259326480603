import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import { SliderProps } from "./Slider";
import prepareSliderData from "../../helper/prepareSliderData";

const IESlider: React.FC<SliderProps> = ({ className, ...props }) => {
  const { value, description, afterValue, disabled } = props;
  const {
    valueString,
    minValue,
    maxValue,
    stepValue,
    changeValue,
  } = prepareSliderData(props);
  return (
    <div className={className + " slider"}>
      <div className="slider-container">
        <div className="text-container">
          {description + ": "}
          <span className="slider-value">
            {valueString}
            {afterValue ? " " + afterValue : ""}
          </span>
        </div>
        <input
          type="range"
          value={value}
          min={minValue}
          max={maxValue}
          step={stepValue}
          onChange={changeValue}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

const getMainColor = (props: SliderProps): string =>
  props.color ? props.color : props.disabled ? Color.GREY : Color.RED;
const getCursor = (props: SliderProps): string =>
  props.disabled ? "not-allowed" : "pointer";
const getThumbCSS = (props: SliderProps): string => `
            -webkit-appearance: none;
            width: 40px;
            height: 40px;
            cursor: ${getCursor(props)};
            border: 4px solid ${Color.WHITE};
            background: ${getMainColor(props)};
            border-radius: 50%;
            position: relative;
            appearance: none;
            box-shadow: 2px 2px 4px 1px ${Color.BLACK};`;

export default styled(IESlider)`

    position: relative;
    margin: 15px ;
    width: 100%;
    max-width: calc(100% - 100px);

    input {
        -webkit-appearance: none;
        width: 100%;
        outline: none;

        // IE / Edge
        // for the slider thumb itself.
        ::-ms-thumb {
            ${getThumbCSS};
            // fix the thumb position
            margin-top: 0;
        }

        @supports (-ms-ime-align:auto) {
            // fix the thumb position
            margin-top: -10px;
        }

        //styles the sliders individual tracks.
        ::-ms-track {
            // fix the size of the thumb
            height: 50px;
        }

        //controls styling of the sliders track before the slider thumb
        ::-ms-fill-lower {
            background: ${getMainColor}

        }

        //same as above, but after the thumb
        ::-ms-fill-upper {
            background: white;
        }

        // controls styling of the tooltip of a slider
        ::-ms-tooltip {
            color: ${Color.RED}
        }

    }
}

// the whole replacement-slider
.slider-container {
    width: 100%;
    color: black;

    // this container contains the two text values
    .text-container {
        width: 100%;
        font-weight: lighter;
        word-break: break-word;
        text-overflow: ellipsis;

        display: inline;
        font-size: 24px;

        .slider-value {
            font-weight: bold;
        }
    }
`;
