export enum FormConfigLabelIds {
  MOBILE = 106,
  IOT = 107,
  FIXED = 108,
  TV = 109,
  MOBILE_COOPERATED = 212,
  IOT_SMARTWATCH = 265,
  IOT_TRACKER = 266,
  IOT_CAMERA = 267,
  IOT_FOR_ME = 268,
  IOT_FOR_OTHER = 269,
  IOT_FOR_KID = 270,
  IOT_THING = 271,
  IOT_FUTURE_PRODUCTS = 272,
  FIXED_DEVICES_LAPTOP = 309,
  FIXED_DEVICES_SMARTPHONE = 310,
  FIXED_DEVICES_TABLET = 311,
  FIXED_DEVICES_PC = 312,
  FIXED_DEVICES_PHONE = 313,
  FIXED_DEVICES_TV = 314,
  FIXED_DEVICES_SMARTHOME = 315,
  FIXED_DEVICES_CONSOLE = 316,
  FIXED_DEVICES_SMARTWATCH = 317,
  FIXED_DEVICES_IOT = 318,
  ADDITIONAL_INVOICE_COSTS = 500,
  ADDITIONAL_INVOICE_PERSONAL = 501,

  ADVANTAGE_GIGASPEED = 1001,
  ADVANTAGE_002 = 1002,
  ADVANTAGE_003 = 1003,
  ADVANTAGE_TELFON_SMS_FLAT = 1004,
  ADVANTAGE_EU_ROAMING = 1005,
  ADVANTAGE_EUROAMING = 1006,
  ADVANTAGE_007 = 1007,
  ADVANTAGE_008 = 1008,
  ADVANTAGE_009 = 1009,
  ADVANTAGE_010 = 1010,
  ADVANTAGE_011 = 1011,
  ADVANTAGE_FLAT_25_EUROPE = 1012,
  ADVANTAGE_013 = 1013,
  ADVANTAGE_014 = 1014,
  ADVANTAGE_015 = 1015,
  ADVANTAGE_016 = 1016,
  ADVANTAGE_017 = 1017,
  ADVANTAGE_018 = 1018,
  ADVANTAGE_019 = 1019,
  ADVANTAGE_VF_FLAT = 1020,
  ADVANTAGE_021 = 1021,
  ADVANTAGE_022 = 1022,
  ADVANTAGE_023 = 1023,
  ADVANTAGE_024 = 1024,
  ADVANTAGE_025 = 1025,
  ADVANTAGE_026 = 1026,
  ADVANTAGE_BASEBOX_100 = 1027,
  ADVANTAGE_30_DAYS_WARRANTY = 1028,
  ADVANTAGE_5_EURO_KOMBI_REBATE_MOBILE = 1029,
  ADVANTAGE_5_EURO_REBATE_YOUNG = 1030,
  ADVANTAGE_500_MBITS = 1031,
  ADVANTAGE_ = 1032,
  ADVANTAGE_PAY_IF_PLUGIN = 1033,
  ADVANTAGE_034 = 1034,
  ADVANTAGE_035 = 1035,
  ADVANTAGE_GREAT_PROGRAM = 1036,
  ADVANTAGE_FLEXIBLE_TV = 1037,
  ADVANTAGE_PERSONAL_RECOMMENDATION = 1038,
  ADVANTAGE_GIGATV = 1039,
  ADVANTAGE_PERFECT_QUALITY = 1040,
  ADVANTAGE_PAY_TV_11 = 1041,
  ADVANTAGE_SCANDINAVIAN_SERIES = 1042,
  ADVANTAGE_043 = 1043,
  ADVANTAGE_044 = 1044,
  ADVANTAGE_045 = 1045,
  ADVANTAGE_046 = 1046,
  ADVANTAGE_047 = 1047,
  ADVANTAGE_WLAN_ROUTER = 1048,
  ADVANTAGE_UPLOAD_50_MBITS = 1049,
  ADVANTAGE_RED_INTERNET_250_500 = 1050,
  ADVANTAGE_FRITZBOX_6591 = 1051,
  ADVANTAGE_INTERNET_FLAT = 1052,
  ADVANTAGE_LANDLINE_FLAT = 1053,
  ADVANTAGE_FLAT_GERMAN_MOBILE = 1054,
  ADVANTAGE_FLAT_60_LANDLINE = 1055,
  ADVANTAGE_FLAT_60_LANDLINE_MOBILE = 1056,
  ADVANTAGE_057 = 1057,
  ADVANTAGE_VF_PREMIUM_21_PAY_TV = 1058,
  ADVANTAGE_VIDEO_LIBRARY = 1064,
  ADVANTAGE_GREAT_MEDIA_LIBRARY = 1065,
  ADVANTAGE_GIGA_TV_MOBILE = 1069,
  ADVANTAGE_SMART_TRACKER = 1070,
  ADVANTAGE_EU_ROAMING_INCL = 1071,
  ADVANTAGE_NO_START_PRICE = 1072,
  ADVANTAGE_USE_IN_SMART_DEVICES = 1073,
  ADVANTAGE_500_MIN_100_SMS = 1074,
  ADVANTAGE_OWN_CALL_NUMBER = 1075,
  ADVANTAGE_SMART_CAMERA = 1076,
  ADVANTAGE_EU_ROAMING_7GB = 1077,
  ADVANTAGE_PREMIUM_WATCH = 1078,
  ADVANTAGE_OWN_CALL_NUMBER_2_DEVICES = 1079,
  ADVANTAGE_EVERYTIME_CONNECTION_WITHOUT_PHONE = 1080,
  ADVANTAGE_NETFLIX_STANDARD = 1081,
  ADVANTAGE_VOICE_BY_CALL = 1082,
  ADVANTAGE_CURVE_TRACKER = 1086,
  ADVANTAGE_UNLIMITED_DATAVOLUME = 1087,
  ADVANTAGE_NEO_KIDS_WATCH = 1088,
  ADVANTAGE_GIGA_DEPOT = 1096,
  ADVANTAGE_EU_ROAMING_SURF_MOBILE = 1097,
  ADVANTAGE_MOBILE_SMS_FLAT = 1098,
  ADVANTAGE_SUPERWLAN_EASYBOX = 1099,
  ADVANTAGE_SUPERWLAN_VF = 1100,
  ADVANTAGE_200_MIN_50_SMS = 1101,
  ADVANTAGE_SECURITY_AGE_SURF_2 = 1102,
  ADVANTAGE_INTERNET_FLAT_2 = 1103,
  ADVANTAGE_FIXED_FLAT = 1104,
  ADVANTAGE_VF_FLAT2 = 1105,
  ADVANTAGE_FIXED_VF_FLAT = 1106,
  ADVANTAGE_CHANGE_GUARANTEE = 1107,
  ADVANTAGE_ALLNET_FLAT = 1108,
  ADVANTAGE_CHANGE_SERVICE = 1109,
  ADVANTAGE_CHANGE_GUARANTEE_SERVICE = 1110,
  ADVANTAGE_10_EURO_REBATE_COMBINATION = 1111,
  ADVANTAGE_112 = 1112,
  ADVANTAGE_5_EURO_REBATE_YOUNG_2 = 1113,
  ADVANTAGE_LANDLINE_BY_CALL = 1114,
  ADVANTAGE_SUPERWLAN_EASYBOX_2 = 1115,
  ADVANTAGE_SUPERWLAN_EASYBOX_3 = 1116,
  ADVANTAGE_SUPERWLAN_EASYBOX_4 = 1117,
  ADVANTAGE_DATA_VOLUME_100 = 1118,
  ADVANTAGE_DATA_VOLUME_200 = 1119,
  ADVANTAGE_DATA_VOLUME_UNLIMITED = 1120,
  ADVANTAGE_EU_ROAMING_SURF_CALL = 1121,
  ADVANTAGE_EACH_YEAR_NEW_PHONE = 1122,

  ADVANTAGE_UPLOAD_50 = 1061, // Cable Power Upload
  ADVANTAGE_FOR_RED_INTERNET_PHONE_CABLE = 1062, // Cable Power Upload
  ADVANTAGE_FOR_FIRST_RED_INTERNET_PHONE_CABLE = 1063, // Cable Experten Serivce
  ADVANTAGE_FLEX_DATAVOLUME = 1066, // Red+ (All)
  ADVANTAGE_200MIN_50_SMS_EU_ROAMING = 1067, // Red+ Kids
  ADVANTAGE_SECURITY_AGE_SURF = 1068, // Red+ Kids
  ADVANTAGE_SMART_TECH_ONE_NUMBER = 1083, // Sub 10 / 15 / 20 / 25
  ADVANTAGE_SMART_TECH = 1085, // Sub 9
  ADVANTAGE_PAY_TV_20 = 1089, // Vf Premium
  ADVANTAGE_DSL_300 = 1090, // DSL FRITZBOX 7530 / 7590 AX
  ADVANTAGE_WIFI_6 = 1091, // DSL FRITZBOX 7530 / 7590 AX
  ADVANTAGE_WLAN_MESH = 1092, // DSL FRITZBOX 7530 / 7590 AX
  ADVANTAGE_LIVE_SPORT = 1093, // DAZN
  ADVANTAGE_DAZN_CHANNELS = 1094, // DAZN
  ADVANTAGE_DAZN_APP = 1095, // DAZN

  GIGAKOMBI = 1900,
  GIGAKOMBI_MOBILE_EURO_FLAT = 1998,
  GIGAKOMBI_CYBER_SECURITY = 1999,
  PASS_CHAT = 2001,
  PASS_SOCIAl = 2002,
  PASS_VIDEO = 2003,
  PASS_MUSIC = 2004,
  PASS_GAMING = 2005,
  GIGAKOMBI_101 = 3000,
  GIGAKOMBI_110 = 3001,
  GIGAKOMBI_011 = 3002,
  GIGAKOMBI_111 = 3003,
}
