import { SubProducts } from "../types/table";

export function combineDataVolumesOfProducts(
  subproductsArray: SubProducts[]
): number {
  return subproductsArray.reduce((acc, subProducts) => {
    return (
      acc +
      subProducts.furtherProductsTable.data.reduce((acc: number, d): number => {
        const dataVolume =
          d.dataVolumes[d.labelId] !== undefined ? d.dataVolumes[d.labelId] : 0;
        const count =
          d.counts[d.countId] !== undefined ? d.counts[d.countId] : 0;
        const calculatedDataVolume = count * dataVolume;
        return acc + calculatedDataVolume;
      }, 0)
    );
  }, 0);
}
