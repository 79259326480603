export function getTestResultString(
  result: boolean,
  lastTestedIndex: number,
  testedKeys: string[]
): string {
  if (result) {
    if (lastTestedIndex < testedKeys.length) {
      return testedKeys[lastTestedIndex] + " [" + lastTestedIndex + "]";
    } else {
      return (
        "Test function has a bug! (" +
        lastTestedIndex +
        " / " +
        testedKeys.length +
        ")"
      );
    }
  }
  return "";
}
