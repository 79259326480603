import * as React from "react";

function SvgWorld(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="world_svg___x31_c15e2ab-e2f5-4dc3-a00f-b554654436d8"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.world_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <g id="world_svg__caed758f-c7d2-4f55-8260-99abc0929c09">
        <path
          id="world_svg__cf4c634b-ac3b-4b45-aaba-ce2a9f094281"
          className="world_svg__st0"
          d="M269.9 8.2C340.4 71 390.4 156 390.4 250c0 92.9-51.4 179.2-120.5 241.8"
        />
        <path
          id="world_svg__a98a2ca9-0108-41a0-8df6-93015b1325f1"
          className="world_svg__st0"
          d="M230.1 8.2C159.6 71 109.6 156 109.6 250c0 92.9 51.4 179.2 120.5 241.8"
        />
        <path
          id="world_svg__e1c55de6-ed04-4287-a4d3-2f036ce0eb58"
          className="world_svg__st0"
          d="M7.5 250h485"
        />
        <path
          id="world_svg__af5fb39b-7cac-4d86-9267-178f5011bc57"
          className="world_svg__st0"
          d="M250 492.5V7.5"
        />
        <path
          id="world_svg___x34_af7847b-a5c8-4f5b-8a6a-e7548260cb20"
          className="world_svg__st0"
          d="M428.7 96.8c-53 38.1-111.9 63.8-178.7 63.8-66 0-125.5-25.9-178.7-63.8"
        />
        <path
          id="world_svg___x36_0f18741-73c8-46f5-8571-16eb3b1ece70"
          className="world_svg__st0"
          d="M71.3 403.2c53.3-38.7 111.3-63.8 178.7-63.8 65.4 0 125.8 26.6 178.7 63.8"
        />
        <circle className="world_svg__st0" cx={250} cy={250} r={242.5} />
      </g>
    </svg>
  );
}

export default SvgWorld;
