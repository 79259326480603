import * as React from "react";

function SvgCalendarGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#4a4d4e" } = props;
  return (
    <svg
      id="calendar_grey_svg__c1d44725-6ba4-4539-afd3-61acc0e00158"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.calendar_grey_svg__st1{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10}`}
      </style>
      <path fill="none" d="M0 0h192v192H0z" />
      <path
        id="calendar_grey_svg__ecc7eb7b-f269-4357-9488-c0c6fba3647a"
        className="calendar_grey_svg__st1"
        d="M68 20v24"
      />
      <path
        id="calendar_grey_svg___x36_ec71f7c-7451-40ac-844c-937cf1a0ed5e"
        className="calendar_grey_svg__st1"
        d="M124 20v24"
      />
      <path
        className="calendar_grey_svg__st1"
        d="M40 36h112c6.6 0 12 5.4 12 12v112c0 6.6-5.4 12-12 12H40c-6.6 0-12-5.4-12-12V48c0-6.6 5.4-12 12-12z"
      />
      <path
        fill="none"
        stroke={color}
        strokeWidth={8}
        strokeMiterlimit={10}
        d="M164 68H28"
      />
      <path
        className="calendar_grey_svg__st1"
        d="M68 116H44M108 116H84M148 116h-24M68 92H44M108 92H84M148 92h-24M68 140H44M108 140H84M148 140h-24"
      />
    </svg>
  );
}

export default SvgCalendarGrey;
