import React from "react";
import styled from "styled-components";
import { SVGComponent } from "../../../types/default";
import PanelItem from "../../panel/PanelItem";
import { FixedImportants } from "../../../types/profile";

interface PanelItemsFixedImportantProps {
  className?: string;
  importants: FixedImportants;
  iconSet: FixedImportantIcons;
}

export interface FixedImportantIcons {
  boosting: SVGComponent;
  calculator: SVGComponent;
  euro: SVGComponent;
  landline: SVGComponent;
  eu: SVGComponent;
  world: SVGComponent;
}

const PanelItemsFixedImportant: React.FC<PanelItemsFixedImportantProps> = ({
  iconSet,
  importants,
}) => (
  <>
    {importants.speedInternet && (
      <PanelItem icon={iconSet.boosting}>Schnelles Internet</PanelItem>
    )}
    {importants.goodService && (
      <PanelItem icon={iconSet.calculator}>Guter Service</PanelItem>
    )}
    {importants.price && <PanelItem icon={iconSet.euro}>Preis</PanelItem>}
    {importants.germanCall && (
      <PanelItem icon={iconSet.landline}>Deutschland Telefonie</PanelItem>
    )}
    {importants.euCall && <PanelItem icon={iconSet.eu}>EU Telefonie</PanelItem>}
    {importants.internationalCall && (
      <PanelItem icon={iconSet.world}>Internationale Telefonie</PanelItem>
    )}
  </>
);

export default styled(PanelItemsFixedImportant)``;
