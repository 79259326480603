import React from "react";
import useBootstrap from "../../hooks/useBootstrap";
import { useFormState } from "../../hooks/useForm";
import ImageTileCheckboxList from "../form/ImageTileCheckboxList";

const MobilePersonalDetailPage: React.FC<{}> = () => {
  const { formConfig } = useBootstrap();
  const [mobilePersonalDetails, setMobilePersonalDetails] = useFormState(
    "mobilePersonalDetails"
  );

  return (
    <ImageTileCheckboxList
      data={formConfig.mobilePersonalDetails}
      values={mobilePersonalDetails}
      onChange={setMobilePersonalDetails}
    />
  );
};

export default MobilePersonalDetailPage;
