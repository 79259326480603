import * as React from "react";

function SvgRightArrow(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192" {...props}>
      <style>
        {`.rightArrow_svg__st0{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className="rightArrow_svg__st0"
        d="M16 96h164M96 26l84 70M96 166l84-70"
      />
    </svg>
  );
}

export default SvgRightArrow;
