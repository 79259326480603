import * as React from "react";

function SvgSpeedGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="speed_grey_svg__ICON"
      x={0}
      y={0}
      viewBox="0 0 192 192"
    >
      <path
        fill="none"
        stroke={color}
        stroke-width="8"
        d="M171.83 96.43a76 76 0 1 1-76-76h76z"
      />
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="8"
        d="M146.76 80A53.79 53.79 0 0 0 44 84M44.17 84C49 65.28 65.46 51.5 85 51.5c18.2 0 33.66 11.77 39.63 28.5M124.67 80h22.09M147.79 96.24H95.98"
      />
      <circle
        cx="96.15"
        cy="96"
        r="4.47"
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="8"
      />
    </svg>
  );
}

export default SvgSpeedGrey;
