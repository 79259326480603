import React from "react";
import styled from "styled-components";
import {
  StyledComponentProps,
} from "../../types/default";
import PersonDisplay from "./PersonDisplay";
import PlusButton from "./PlusButton";
import { OptimizeContractData } from "../../types/application";

export interface PersonDisplayListProps extends StyledComponentProps {
  dataSet: OptimizeContractData[];
  addOptimizedContract: () => void;
}

const PersonDisplayList: React.FC<PersonDisplayListProps> = ({
  className,
  dataSet,
  addOptimizedContract,
}) => {
  return (
    <div className={className}>
      {dataSet.map((v, i) => (
        <PersonDisplay key={i} index={i} data={v} />
      ))}
      {dataSet.length < 5 && (
        <PlusButton
          onChange={() => {
            addOptimizedContract();
          }}
        />
      )}
    </div>
  );
};
export default styled(PersonDisplayList)`
  display: block;
  vertical-align: top;
  text-align: center;
`;
