import * as React from "react";

function SvgSimColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="sim_color_svg___x30_a0bf539-2e21-4b5b-9b86-2af6b7e1c885"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".sim_color_svg__st1{fill:none;stroke:#e60000;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <g id="sim_color_svg___x39_c726b55-247c-4666-9a04-e23c03a2e748">
        <path fill="none" d="M0 0h192v192H0z" />
        <g id="sim_color_svg__ec0f0a2c-77e4-4269-b133-a5f708714bc8">
          <path
            className="sim_color_svg__st1"
            d="M52 160V32c0-6.6 5.4-12 12-12h48l28 28v112c0 6.6-5.4 12-12 12H64c-6.6 0-12-5.4-12-12z"
          />
          <path
            className="sim_color_svg__st1"
            d="M84 84h24c4.4 0 8 3.6 8 8v48c0 4.4-3.6 8-8 8H84c-4.4 0-8-3.6-8-8V92c0-4.4 3.6-8 8-8z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSimColor;
