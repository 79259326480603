import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import { StyledComponentProps } from "../../types/default";

const PanelValue: React.FC<StyledComponentProps> = ({
  className,
  children,
}) => <span className={className}>{children}</span>;

export default styled(PanelValue)`
  color: ${Color.RED};
`;
