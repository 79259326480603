import * as React from "react";

function SvgPrivateHd(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="privateHd_svg__cf5551d2-88b0-4d71-968a-c1f38d887bf5"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.privateHd_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className="privateHd_svg__st0"
        d="M274.8 173.7v101.7s52.6 8.5 52.6-50.8-52.6-50.9-52.6-50.9zM172.7 274.9V173.3M223.9 274.9V173.3M172.7 224.1h38.4"
      />
      <path
        className="privateHd_svg__st0"
        d="M33 58.6h434c14.1 0 25.5 11.4 25.5 25.5v280.8c0 14.1-11.4 25.5-25.5 25.5H33c-14.1 0-25.5-11.4-25.5-25.5V84.1C7.5 70 18.9 58.6 33 58.6zM135.1 441.5h229.8M250 403.2v31.9"
      />
    </svg>
  );
}

export default SvgPrivateHd;
