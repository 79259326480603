import { FormState } from "../../state/definitions/FormState";
import { SlideCategoryTypes } from "../../constants/SlidePages";
import { Interest, InterestObject } from "../../types/application";

/**
 * Returns array of interests, which are more important than others.
 * It contains only selected interests.
 */

const allSlideCategories = [
  SlideCategoryTypes.switch,
  SlideCategoryTypes.profile,
  SlideCategoryTypes.recommendation,
  SlideCategoryTypes.offer,
];

export function getPrioritizedInterests(
  interests: InterestObject,
  form: FormState,
  currentCategory?: SlideCategoryTypes
) {
  let prioritizedInterests: Interest[] = [];
  const useAllSlides =
    currentCategory !== undefined
      ? allSlideCategories.includes(currentCategory)
      : false;
  if (
    (!form.mobileHasData ||
      currentCategory === SlideCategoryTypes.mobile ||
      useAllSlides) &&
    interests.mobile
  ) {
    prioritizedInterests.push(Interest.MOBILE);
  }

  if (
    (!form.fixedHasData ||
      currentCategory === SlideCategoryTypes.fixed ||
      useAllSlides) &&
    interests.fixed
  ) {
    prioritizedInterests.push(Interest.FIXED);
  }
  if (
    (!form.tvHasData ||
      currentCategory === SlideCategoryTypes.tv ||
      useAllSlides) &&
    interests.tv
  ) {
    prioritizedInterests.push(Interest.TV);
  }
  if (
    (!form.iotHasData ||
      currentCategory === SlideCategoryTypes.iot ||
      useAllSlides) &&
    interests.iot
  ) {
    prioritizedInterests.push(Interest.IOT);
  }
  // if (form.mobileHasData && interests.mobile) {
  //   prioritizedInterests.push(Interest.MOBILE);
  // }
  // if (form.iotHasData && interests.iot) {
  //   prioritizedInterests.push(Interest.IOT);
  // }
  // if (form.fixedHasData && interests.fixed) {
  //   prioritizedInterests.push(Interest.FIXED);
  // }
  // if (form.tvHasData && interests.tv) {
  //   prioritizedInterests.push(Interest.TV);
  // }
  return prioritizedInterests;
}
