import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";

interface PriceSummaryProps {
  className?: string;
  backgroundColor?: string;
  mainLabel?: string;
  onChange?: (value: number) => void;
  changeValue?: number;
}

const PriceInput: React.FC<PriceSummaryProps> = ({
  className,
  mainLabel,
  onChange,
  changeValue,
}) => {
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange && e.target && e.target.value) {
      const newValue: number = parseFloat(e.target.value);
      onChange(newValue);
    }
  };
  return (
    <div className={className + " price-summary"}>
      <ul>
        <li key={-1} className={"main-label-value-item"}>
          <div className={"label-side main-list-label"}>{mainLabel}</div>
          <div className={"value-side main-list-value"}>
            <input
              type="number"
              value={changeValue}
              onChange={onChangeHandler}
            />
            <span>€</span>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default styled(PriceInput)`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : Color.WHITE};
  min-width: 150px;
  min-height: 60px;
  text-align: center;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-top: 5px;

  @media (min-width: 1023px) {
    li {
      margin-right: 15px;
    }
    .main-list-label {
      font-size: 20px !important;
    }
    .main-list-value {
      font-size: 20px !important;
    }
  }
  @media (max-width: 1023px) {
    li {
      margin-right: 5px;
    }
    .main-list-label {
      font-size: 20px !important;
    }
  }

  .label-side {
    text-align: right;
    display: inline-block;
    width: 70%;
    border-right: 2px ${Color.GREY_LIGHT8} solid;
    padding-right: 5px;
  }
  .value-side {
    text-align: right;
    display: inline-block;
    width: 30%;
  }

  .main-label-value-item {
    .label-side {
      color: ${Color.RED};
      font-weight: bold;
    }
    .value-side {
      color: ${Color.RED};
      font-weight: bold;

      input {
        width: calc(85% - 5px);
        width: -moz-calc(85% - 5px);
        width: -webkit-calc(85% - 5px);
        width: -o-calc(85% - 5px);
        width: calc(85% - 5px);
        display: inline-block;
        text-align: right;
        outline: none;
        border: none;
        box-shadow: inset 4px 5px 12px -8px ${Color.BLACK};
        padding: 5px;
        color: ${Color.RED};

        // Chrome, Edge hide input arrows
        ::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        // Firefox hide input arrows
        [type="number"] {
          -moz-appearance: textfield;
        }
      }

      span {
        width: 15%;
        display: inline-block;
        font-weight: bold;
      }
    }
  }

  .normal-label-value-item:nth-last-of-type(1) {
    font-weight: bold;
  }
`;
