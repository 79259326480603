import * as React from "react";

export default function SvgFixedLine(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="fixed-line_svg__b227dd6d-6e67-4791-bf1a-2782ad439167"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <g id="fixed-line_svg__f9016051-ba1d-4fce-8b8e-1f357c8273f2">
        <path
          id="fixed-line_svg___x30_0c634f3-a7dd-444a-bbbb-7210f5ff74ce"
          d="M115.8 129.2l12.9-9.7c3.4-1.7 6.9-2.2 9-.9 3.2 2 30 19.9 32.2 21.5s3.3 6.1.1 10.5-17.7 21.7-23.8 21.5-31.4-1-78.6-48.5S20.1 51 20 45s17.6-20.1 22.1-23.2 9-1.9 10.6.3c1.8 2.5 19.2 29.2 21.1 32.2 1.3 2.1.8 5.6-1 8.9-1.8 3.3-9.3 16-9.3 16s5.1 8.9 22.5 26.4c8.3 8.6 29.8 23.6 29.8 23.6z"
          fill="none"
          stroke={color}
          strokeWidth={8}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path fill="none" d="M0 0h192v192H0z" />
        <path fill="none" d="M0 0h192v192H0z" />
      </g>
    </svg>
  );
}
