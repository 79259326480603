import React, { useEffect } from "react";
import styled from "styled-components";
import { useOfferState } from "../../hooks/useOffer";
import { StyledComponentProps } from "../../types/default";
import usePrint from "../../hooks/usePrint";
import PrintHeadline from "../headline/PrintHeadline";
import PrintTopLine from "../elements/recommendationPage/print/PrintTopLine";
import OfferEntriesContainer from "../elements/recommendationPage/summary/OfferEntriesContainer";
import useEndPrice from "../../hooks/useOfferHelper/useEndPrice";
import PrintOfferEntry from "../elements/recommendationPage/print/PrintOfferEntry";
import PrintBottomLine from "../elements/recommendationPage/print/PrintBottomLine";
import PrintGigaKombiMainAdvantagesContainer from "../elements/recommendationPage/gigakombi/PrintGigaKombiMainAdvantagesContainer";

const PrintOfferEntryPage: React.FC<StyledComponentProps> = ({ className }) => {
  const { isLoaded, cleanOfferEntries } = usePrint();
  useEffect(() => {
    if (isLoaded) {
      cleanOfferEntries();
    }
  }, [isLoaded]);

  const [offerEntryIds] = useOfferState("offerEntryIds");
  const [wholeEndPrices, wholeOncePrice] = useEndPrice();
  const [selectedGigaKombi] = useOfferState("selectedGigaKombi");

  return (
    <div className={className}>
      <PrintTopLine />
      <div className={"print-page-container"}>
        <PrintHeadline>Unsere Empfehlung für Dich</PrintHeadline>
        {!isLoaded && (
          <>{"Daten wurden noch nicht erhalten. Ein Fehler liegt vor!"}</>
        )}
        {isLoaded && (
          <>
            <OfferEntriesContainer offerEntryIds={offerEntryIds} isPrint />
            <PrintOfferEntry
              isOpen={true}
              title={"Gesamtpreis pro Monat"}
              monthlyPriceArray={wholeEndPrices}
              oncePrice={wholeOncePrice}
              main
            />
            {selectedGigaKombi && <PrintGigaKombiMainAdvantagesContainer />}
          </>
        )}
      </div>
      <PrintBottomLine />
    </div>
  );
};

export default styled(PrintOfferEntryPage)`
  .print-page-container {
    height: 748px;
    padding: 0 30px 20px 30px;
    overflow: hidden;
  }
`;
