import * as React from "react";

function SvgCooperation(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="cooperation_svg___x32_de66aec-37e8-485c-9e13-9a9c3a5f0138"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.cooperation_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className="cooperation_svg__st0"
        d="M278.5 7.5h57.1c23.6 0 42.8 19.2 42.8 42.8v85.6c0 23.6-19.2 42.8-42.8 42.8h-57.1c-23.6 0-42.8-19.2-42.8-42.8V50.3c0-23.7 19.2-42.8 42.8-42.8zM50.3 164.4h57.1c23.6 0 42.8 19.2 42.8 42.8v85.6c0 23.6-19.2 42.8-42.8 42.8H50.3c-23.6 0-42.8-19.2-42.8-42.8v-85.6c0-23.6 19.2-42.8 42.8-42.8zM392.6 321.3h57.1c23.6 0 42.8 19.2 42.8 42.8v85.6c0 23.6-19.2 42.8-42.8 42.8h-57.1c-23.6 0-42.8-19.2-42.8-42.8v-85.6c0-23.6 19.2-42.8 42.8-42.8zM307.1 178.7V250M150.2 250h271v71.3"
      />
    </svg>
  );
}

export default SvgCooperation;
