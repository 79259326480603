import { RecommendationProductLineConfig } from "../../definitions/RecommendationState";

export const defaultRecommendationProductLineConfig: RecommendationProductLineConfig =
  {
    productId: [],

    productPreLabel: [],
    productLabel: [],
    productPostLabel: [],

    productPreDescription: [],
    productDescription: [],
    productPostDescription: [],

    productPrices: [],

    dataVolumeMaximum: 0,
    productDataVolume: [],

    passes: [],
    passPrices: [],
    passPriceSelected: [],

    productAdvantages: [],
    selectedProduct: 0,
  };