import * as React from "react";

function SvgFirstPriceGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#333" } = props;
  return (
    <svg
      id="firstPrice_grey_svg___x30_76e4f05-2333-4cb3-ba5c-d8658b35550d"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.firstPrice_grey_svg__st1{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path fill="none" d="M0 0h192v192H0z" />
      <path
        className="firstPrice_grey_svg__st1"
        d="M164.6 78.7c10 10 9.9 26.2-.1 36.2l-.4.4c3.6 13.7-4.5 27.7-18.2 31.3-.2 0-.4.1-.6.1-3.7 13.6-17.8 21.7-31.4 18-.2-.1-.4-.1-.6-.2-10 10-26.2 9.9-36.2-.1l-.4-.4c-13.7 3.6-27.7-4.5-31.3-18.2 0-.2-.1-.4-.1-.6-13.6-3.7-21.7-17.8-18-31.4.1-.2.1-.4.2-.6-9.9-10.1-9.8-26.3.2-36.2l.4-.4C24.4 63 32.5 49 46.2 45.4c.2 0 .4-.1.5-.1 3.7-13.6 17.8-21.7 31.4-18 .2.1.4.1.6.2 10-10 26.2-9.9 36.2.1l.4.4c13.7-3.6 27.7 4.5 31.3 18.2.1.2.1.4.1.6 13.6 3.7 21.7 17.7 18 31.4 0 .1 0 .3-.1.5z"
      />
      <circle className="firstPrice_grey_svg__st1" cx={96} cy={96} r={52} />
      <path
        className="firstPrice_grey_svg__st1"
        d="M98.6 128.4V67.7l-17 11.4"
      />
    </svg>
  );
}

export default SvgFirstPriceGrey;
