import React from "react";
import styled from "styled-components";
import Modal from "../modal/Modal";
import { DisplayErrorMessages } from "../../constants/DisplayErrorMessages";
import { ErrorCodes } from "../../constants/ErrorCodes";

interface ErrorModalProps {
  className?: string;
  isOpen?: boolean;
  notCloseable?: boolean;
  setIsOpen?: (newValue: boolean) => void;
  textKey: DisplayErrorMessages;
  code?: ErrorCodes;
  headline?: string;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
  className,
  isOpen,
  setIsOpen,
  textKey,
  code,
  notCloseable,
  headline = "Fehler !",
}) => {
  return (
    <div className={className}>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        headline={headline}
        notCloseable={notCloseable}
        darkBackground
      >
        <p className={"error-description"}>{textKey}</p>
        {!!code && <p className={"error-description"}>Fehlercode: {code}</p>}
      </Modal>
    </div>
  );
};

export default styled(ErrorModal)`
  .error-description {
    font-size: 20px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
  }
`;
