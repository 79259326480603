import * as React from "react";
import { getSubTitleForRecommendationColumn } from "../ColumnHeadTile";
import TextTile from "../TextTile";
import { Interest, RecommendationMode } from "../../../../types/application";
import { Color } from "../../../../constants/color";
import styled from "styled-components";
import { StyledComponentProps } from "../../../../types/default";
import { getInterestFromType } from "../../../../helper/recommendation/mapping";

export interface Props extends StyledComponentProps {
  mode: RecommendationMode;
  type: Interest;
}

const RecommendationPrintColumnHeadline = ({
  className,
  mode,
  type,
}: Props) => {
  return (
    <TextTile
      outerClassName={className}
      backgroundColor={getBackgroundColor(mode)}
      fontColor={getFontColor(mode)}
      borderColor={getBorderColor(mode)}
      withoutMarginBottom
    >
      <p className={"recommendation-print-column-headline"}>
        {getInterestFromType(type)}
      </p>
      <p className={"recommendation-print-column-headline-sub"}>
        {getSubTitleForRecommendationColumn(mode)}
      </p>
    </TextTile>
  );
};
const getBackgroundColor = (mode: RecommendationMode) => {
  switch (mode) {
    case RecommendationMode.CURRENT:
      return Color.WHITE;
    case RecommendationMode.INTERESTING:
      return Color.GREY_LIGHT;
    case RecommendationMode.SELECTABLE:
      return Color.RED;
    default:
      return Color.WHITE;
  }
};
const getBorderColor = (mode: RecommendationMode) => {
  switch (mode) {
    case RecommendationMode.CURRENT:
      return Color.BLACK;
    case RecommendationMode.INTERESTING:
      return Color.GREY_LIGHT;
    case RecommendationMode.SELECTABLE:
      return Color.RED;
    default:
      return Color.WHITE;
  }
};
const getFontColor = (mode: RecommendationMode) => {
  switch (mode) {
    case RecommendationMode.CURRENT:
      return Color.GREY3;
    case RecommendationMode.INTERESTING:
      return Color.WHITE;
    case RecommendationMode.SELECTABLE:
      return Color.WHITE;
    default:
      return Color.WHITE;
  }
};
export default styled(RecommendationPrintColumnHeadline)`
  .recommendation-print-column-headline {
    text-align: left;
    padding: 0 10px;
    font-weight: bold;
    font-size: 14px;
  }
  .recommendation-print-column-headline-sub {
    text-align: left;
    padding: 0 10px 2px 10px;
    font-weight: bold;
    font-size: 16px;
  }
`;
