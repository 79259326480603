import React from "react";
import styled from "styled-components";
import ImageWithIcons from "../elements/ImageWithIcons";
import { getIcons } from "../../icons/Icons";
import { FormStateNumberArrays } from "../../state/definitions/FormState";
import Column from "../grid/Column";
import { useFormState } from "../../hooks/useForm";
import { getImageSet } from "../../images";
import TileRadioButtonList from "./TileRadioButtonList";
import { FormOption } from "../../state/definitions/FormConfigState";

interface RadioButtonListColumnProps {
  className?: string;
  valueKey: keyof FormStateNumberArrays;
  category: FormOption;
  data: FormOption[];
  colCount?: number;
}

const RadioButtonListColumn = ({
  valueKey,
  category,
  data,
  colCount = 1,
}: RadioButtonListColumnProps) => {
  const [values, setValues] = useFormState(valueKey);
  const changeValues = (newValues: number[]) => {
    if (values === undefined || newValues.join("") !== values.join("")) {
      setValues(newValues);
    }
  };

  return (
    <Column
      colCount={colCount}
      minWidth={"305px"}
      maxWidth={100 / colCount + "%"}
    >
      <ImageWithIcons
        imageSet={getImageSet(category.value)}
        icons={getIcons(category.value)}
      />
      <TileRadioButtonList
        title={category.label}
        data={data}
        value={values ? values[0] : -1}
        onChange={changeValues}
      />
    </Column>
  );
};

export default styled(RadioButtonListColumn)``;
