import { useContext } from "react";
import { AppContext } from "../state/AppContext";
import { DisplayErrorMessages } from "../constants/DisplayErrorMessages";
import { useRefEffect } from "./common/useRefHook";
import { processBootstrap } from "../helper/bootstrap/processBootstrap";
import { getFormConfig } from "../helper/dataHandling/getFormConfig";
import { ErrorCodes } from "../constants/ErrorCodes";

const useBootstrap = () => {
  const [state, setState] = useContext(AppContext);

  useRefEffect(() => {
    if (!state.bootstrap.isLoaded) {
      (async () => {
        setState((prev) => ({
          ...prev,
          bootstrap: {
            ...prev.bootstrap,
            loading: true,
          },
        }));

        const [formConfig, formConfigResult] = getFormConfig();
        if (formConfigResult) {
          setState((prevState) =>
            processBootstrap(prevState, { form: formConfig })
          );
        } else {
          console.error("FormConfig not found");
          setState((prev) => ({
            ...prev,
            bootstrap: {
              ...prev.bootstrap,
              errorMessage: DisplayErrorMessages.technicalProblem,
              errorCode: ErrorCodes.falseLocalFormConfig,
              loading: false,
            },
          }));
        }
      })();
    }
  }, [state.bootstrap.isLoaded, setState]);

  return {
    loading: state.bootstrap.loading,
    formConfig: state.bootstrap.formConfig,
    errorMessage: state.bootstrap.errorMessage,
    errorCode: state.bootstrap.errorCode,
  };
};

export default useBootstrap;
