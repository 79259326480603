import React, { useState } from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import JustifiedColumnsContainer from "../grid/JustifiedColumnsContainer";
import GridColumn from "../grid/GridColumn";

interface DateFieldProps {
  className?: string;
  onChange: (value: Date) => void;
  defaultValue?: Date;
  value?: Date;
  main?: boolean;
  label?: string;
  disabled?: boolean;
}

function getInputDateString(date: Date): string {
  const dateValue = date.getDate();
  const dateString = dateValue < 10 ? "0" + dateValue : dateValue.toString();
  const monthValue = date.getMonth() + 1;
  const monthString =
    monthValue < 10 ? "0" + monthValue : monthValue.toString();
  const yearValue = date.getFullYear().toString();

  return yearValue + "-" + monthString + "-" + dateString;
}

const DateField: React.FC<DateFieldProps> = ({
  className,
  onChange,
  label,
  value = new Date(),
  disabled,
}) => {
  let displayDateString = getInputDateString(value);
  if (disabled) {
    displayDateString = "";
  }
  const [isEditing, setIsEditing] = useState(false);
  const [shadowValue, setShadowValueT] = useState(displayDateString);
  const setShadowValue = (v: string) => {
    setShadowValueT(v);
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newDate = value;
    let setDate = false;

    if (e.target.value) {
      const dateStrings = e.target.value.split("-");
      newDate = new Date(0);
      if (dateStrings[0] && dateStrings[0].length === 4) {
        newDate.setFullYear(Number(dateStrings[0]));
        if (dateStrings[1] && dateStrings[1].length === 2) {
          newDate.setMonth(Number(dateStrings[1]) - 1);
          if (dateStrings[2] && dateStrings[2].length === 2) {
            newDate.setDate(Number(dateStrings[2]));
            setDate = true;
          }
        }
      }

      if (setDate) {
        onChange(newDate);
      }
    }
    if (!setDate) {
      newDate = value;
    }
    let displayDateString = getInputDateString(newDate);
    setShadowValue(displayDateString);
  };

  const onShadowValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setShadowValue(e.target.value);
    }
  };

  const onInputFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEditing(true);
  };
  const onInputLeave = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEditing(false);
    onChangeHandler(e);
  };

  const displayValue = isEditing ? shadowValue : displayDateString;

  return (
    <div className={className + " default-date-field"}>
      <JustifiedColumnsContainer alignItems={"center"}>
        {label && (
          <GridColumn colCount={2}>
            <span>{label}</span>
          </GridColumn>
        )}
        <GridColumn colCount={2} minWidth={"200px"} maxWidth={"200px"}>
          <input
            type="date"
            value={displayValue}
            onChange={onShadowValueChange}
            disabled={disabled}
            onFocus={onInputFocus}
            onBlur={onInputLeave}
          />
        </GridColumn>
      </JustifiedColumnsContainer>
    </div>
  );
};

export default styled(DateField)`
  background-color: ${Color.RED};
  max-width: 600px;
  text-align: left;
  user-select: none;
  padding: 5px;
  display: inline-block;

  span {
    color: ${Color.WHITE};
    vertical-align: middle;
    font-size: 25px;
  }

  input {
    padding: 10px;
    box-shadow: inset 4px 4px 20px -10px;
    border: none;
    font-size: 20px;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    background: ${(props) => (props.disabled ? Color.RED : Color.WHITE)};
    color: ${Color.BLACK};
    vertical-align: middle;

    ::-ms-clear {
      display: none;
    }
  }
`;
