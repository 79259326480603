import * as React from "react";

function SvgPasses(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="passes_svg__Kids"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.passes_svg__st0{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}.passes_svg__st1{fill: ${color} }`}
      </style>
      <path
        className="passes_svg__st0"
        d="M122.2 47.3L109 60.5c2.4 2.4 2.4 6.3 0 8.8s-6.3 2.4-8.8 0l-52.6 52.6c-2.4 2.4-2.4 6.3 0 8.8l39.5 39.5c2.4 2.4 6.3 2.4 8.8 0l52.6-52.6c-2.4-2.4-2.4-6.3 0-8.8s6.3-2.4 8.8 0l13.2-13.2c2.4-2.4 2.4-6.3 0-8.8L131 47.3c-2.4-2.4-6.4-2.4-8.8 0z"
      />
      <circle className="passes_svg__st1" cx={120.9} cy={80.9} r={3.7} />
      <circle className="passes_svg__st1" cx={112.8} cy={72.8} r={3.7} />
      <circle className="passes_svg__st1" cx={129} cy={89} r={3.7} />
      <circle className="passes_svg__st1" cx={137.1} cy={97.1} r={3.7} />
      <circle className="passes_svg__st1" cx={145.2} cy={105.2} r={3.7} />
      <path
        className="passes_svg__st0"
        d="M146.4 62.9l5.4-13.6c1.3-3.2-.3-6.8-3.5-8.1L96.6 20.6c-3.2-1.3-6.8.3-8.1 3.5l-6.9 17.3c3.2 1.3 4.7 4.9 3.4 8-1.3 3.2-4.9 4.7-8 3.5l-26.3 65.9"
      />
      <circle className="passes_svg__st1" cx={101.6} cy={55.4} r={3.7} />
      <circle className="passes_svg__st1" cx={91} cy={51.2} r={3.7} />
      <path
        className="passes_svg__st0"
        d="M86.3 29.3L66.7 27c-3.4-.4-6.5 2.1-6.9 5.5L57.6 51c3.4.4 5.8 3.5 5.4 6.9-.4 3.4-3.5 5.8-6.9 5.5l-6.5 56.3"
      />
    </svg>
  );
}

export default SvgPasses;
