import * as React from "react";

function SVGHook(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="hook_svg___x36_d82e59e-323a-474d-be06-1abebb26ef52"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
    >
      <style>
        {`.st0{fill:none;stroke:${color};stroke-width:44.1732;stroke-linecap:round;stroke-linejoin:round;}`}
      </style>

      <g>
        <line className="st0" x1="40" y1="280" x2="200" y2="450" />
        <line className="st0" x1="200" y1="450" x2="460" y2="80" />
      </g>
    </svg>
  );
}

export default SVGHook;
