import {
  Availability,
  Technology,
  TechnologyAvailability,
} from "../../state/definitions/AvailabilityState";
import { testObjectWithKeys } from "./default/testObjectWithKeys";

export default (unsureAvailability: any): [Availability, boolean] => {
  let result = true;
  if (
    unsureAvailability !== undefined &&
    Object.keys(unsureAvailability).length > 0
  ) {
    const testResult = testObjectWithKeys(
      unsureAvailability,
      Object.keys(Technology),
      TechnologyAvailability,
      true
    );

    if (testResult !== "") {
      console.error(
        "File availability data has a false structure:",
        testResult
      );
      result = false;
    }
  } else {
    console.error(
      "File availability data has a false structure:",
      "'No index structure.'"
    );
    result = false;
  }

  return [unsureAvailability as Availability, result];
};
