import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import { getIcons } from "../../icons/Icons";
import { getImageSet } from "../../images";
import ImageWithIcons from "../elements/ImageWithIcons";
import Column from "../grid/Column";
import TileButton from "./TileButton";
import RadioButtonList from "./RadioButtonList";
import { FormOption } from "../../state/definitions/FormConfigState";

interface ImageTileRadiobuttonListProps {
  className?: string;
  data: FormOption[];
  values?: number[];
  onChange: (values: number[]) => void;
}

const ImageTileRadiobuttonList: React.FC<ImageTileRadiobuttonListProps> = ({
  className,
  data,
  values = [],
  onChange,
}) => {
  return (
    <RadioButtonList
      className={className}
      data={data}
      values={values}
      onChange={onChange}
      renderFkt={({ value, label }, isSelected, toggleValue) => {
        return (
          <Column colCount={data.length} key={value}>
            <ImageWithIcons
              imageSet={getImageSet(value)}
              icons={getIcons(value)}
            />
            <TileButton
              onChange={() => toggleValue(value)}
              value={isSelected}
              color={isSelected ? Color.RED : Color.GREY2}
            >
              {label}
            </TileButton>
          </Column>
        );
      }}
    />
  );
};

export default styled(ImageTileRadiobuttonList)`
  .column {
    margin-bottom: 10px;
  }
`;
