import { useContext } from "react";
import useLocalstorage from "./common/useLocalStorage";
import { AppContext } from "../state/AppContext";
import { useRefEffect } from "./common/useRefHook";

type StateLikeHook = () => [string, (value: string) => void];

const useVauoid: StateLikeHook = () => {
  const [localStorageValue, setLocalStorage] = useLocalstorage("vauoid");
  const [state, setState] = useContext(AppContext);

  useRefEffect(() => {
    // console.log("initial copy from localstorage", localStorageValue);
    setState((prev) => ({
      ...prev,
      vauoid: localStorageValue,
    }));
  }, []);

  useRefEffect(() => {
    // console.log("voidchange", state.vauoid);
    setLocalStorage(state.vauoid);
  }, [state.vauoid]);

  const setVauoid = (vauoid: string) => {
    setState((prev) => ({
      ...prev,
      vauoid,
    }));
  };

  return [state.vauoid, setVauoid];
};

export default useVauoid;
