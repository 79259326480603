import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import NavItem from "./NavItem";
import { ZIndex } from "../../constants/zIndex";
import NavButton from "./NavButton";
import { useNavigationItems } from "../../hooks/useNavigationHelper/useNavigationItems";
import { StyledComponentProps } from "../../types/default";

const Navbar: React.FC<StyledComponentProps> = ({ className }) => {
  const { navItems } = useNavigationItems();
  return (
    <nav className={className}>
      <ul>
        {navItems.map((i) => {
          if (i.to !== undefined) {
            return (
              <NavItem
                key={i.id}
                to={i.to}
                fct={i.fct}
                icon={i.icon}
                disabled={i.disabled}
                showDisabled={i.showDisabled}
                active={i.active}
                finished={i.finished}
              />
            );
          }
          if (i.fct !== undefined) {
            return (
              <NavButton
                key={i.id}
                fct={i.fct}
                icon={i.icon}
                active={i.active}
                showDisabled={i.showDisabled}
                disabled={i.disabled}
              />
            );
          }
          console.error("False nav-item config! ", i);
          return <></>;
        })}
      </ul>
    </nav>
  );
};

export default styled(Navbar)`
  &::-webkit-scrollbar {
    display: none;
  }
  width: 66px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  -ms-overflow-style: none;
  z-index: ${ZIndex.FRONT_1};
  background-color: ${Color.GREY};
  // for firefox
  scrollbar-width: none;
`;
