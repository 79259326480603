import * as React from "react";

function SvgDevicesGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#333" } = props;
  return (
    <svg
      id="devices_grey_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.devices_grey_svg__st0{fill:none;stroke: ${color} ;stroke-width:14.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className="devices_grey_svg__st0"
        d="M364.9 348.7H212M33 144.2v-67C32.7 58 48 42.1 67.3 41.8h365.4c19.2.3 34.6 16.2 34.3 35.4v143.3M415.9 399.2h25.5m-344.4 0h25.5"
      />
      <path
        className="devices_grey_svg__st0"
        d="M384 220.5h89.3c10.6 0 19.1 8.6 19.1 19.1V431c0 10.6-8.6 19.1-19.1 19.1H384c-10.6 0-19.1-8.6-19.1-19.1V239.6c0-10.6 8.5-19.1 19.1-19.1zM26.6 144.2h166.2c10.6 0 19.1 8.6 19.1 19.1V431c0 10.6-8.6 19.1-19.1 19.1H26.6c-10.6 0-19.1-8.6-19.1-19.1V163.3c0-10.5 8.6-19.1 19.1-19.1zM313.3 399.2H212m38-50.5l.1 50.4"
      />
    </svg>
  );
}

export default SvgDevicesGrey;
