import { GigaKombiMainAdvantages } from "../../constants/InitialMainAdvantages";
import { REBATE_MAX_DATAVOLUME } from "../../constants/default";

export function getRebateMainGigaKombi(rebate: number) {
  return Object.assign({}, GigaKombiMainAdvantages.rebate, {
    value: `Jeden Monat ${rebate}€ Rabatt`,
  });
}

export function getDatavolumeMainGigaKombi(datavolume: number) {
  let dataVolumeValue = datavolume + " GB";
  if (datavolume >= REBATE_MAX_DATAVOLUME) {
    dataVolumeValue = "unbegrenztes";
  }
  return Object.assign({}, GigaKombiMainAdvantages.datavolume, {
    value: `${dataVolumeValue} Datenvolumen`,
  });
}

export function getFlexibilityMainGigaKombi(months: number) {
  return Object.assign({}, GigaKombiMainAdvantages.flexibility, {
    value: `Bis zu ${months} Monate kein doppelter Basispreis`,
  });
}
