import { useContext, useState } from "react";
import { AppContext } from "../state/AppContext";
import { OfferState } from "../state/definitions/OfferState";
import { RecommendationConfig } from "../state/definitions/RecommendationState";
import { FormConfigState } from "../state/definitions/FormConfigState";
import { AppState } from "../state/definitions/AppState";
import { FormState } from "../state/definitions/FormState";
import { DisplayErrorMessages } from "../constants/DisplayErrorMessages";
import { useAppState } from "./useAppHelper/useAppState";
import { useAppStateMultiple } from "./useAppHelper/useAppStateMultiple";
import { useCurrentProducts } from "./useDatabaseProducts";
import { RecommendationModeArray } from "../types/application";
import useAppReload from "./useAppHelper/useAppReload";
import { LoadableResources, loadConfigData } from "../helper/loadConfigData";
import { NewOfferState } from "../state/definitions/NewOfferState";
import { DatabaseProduct } from "../data/definitions/DatabaseProduct";
import { useNewOffer } from "./useNewOffer";
import { ErrorCodes } from "../constants/ErrorCodes";
import useAuth from "./useAuth";

const testSubmitOfferResponse = (
  unsureData: any
): [SubmitOfferResponse, boolean] => {
  return [unsureData, true];
};

export const useOfferState = <K extends keyof AppState["offer"]>(key: K) =>
  useAppState("offer", key);
export const useOfferStateMultiple = <
  K extends keyof AppState["offer"] & string,
  KeyArray extends K[]
>(
  keys: K[]
) => useAppStateMultiple("offer", keys);

export interface SubmitOfferRequestData {
  form: FormState;
  offer: OfferState;
  newOffer: NewOfferState;
  currentProducts: DatabaseProduct[];
  recommendationConfig: RecommendationConfig;
  productLineModes: RecommendationModeArray;
  formConfig: FormConfigState;
  offerSessionId: string;
  vauoid: string;
}

interface SubmitOfferResponse {
  // offerId: string;
  // documentUrl: string;
  // duration: number;
  success: boolean;
  docId: string;
  wait: number;
  timestamp: number;
}

export const useOffer = () => {
  const [state, setState] = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [offerResponse, setOfferResponse] =
    useState<SubmitOfferResponse | null>(null);
  const [errorMessage, setErrorMessage] = useState<DisplayErrorMessages>(
    DisplayErrorMessages.nothing
  );
  const [errorCode, setErrorCode] = useState<ErrorCodes>(ErrorCodes.nothing);
  const [, requestAppReload] = useAppReload();
  const { logout } = useAuth();

  const { getSelectedEntryProperty } = useNewOffer();
  const { getSelectedProducts } = useCurrentProducts();
  const getPreparedOfferRequestData = () => ({
    offerSessionId: state.offerSessionId,
    vauoid: state.vauoid,
    offer: Object.assign({}, state.offer, {
      printOfferEntryIds: state.offer.offerEntryIds,
    }),
    newOffer: state.newOffer,
    form: state.form,
    recommendationConfig: state.recommendation.config,
    productLineModes: state.recommendation.modes,
    currentProducts: getSelectedProducts(getSelectedEntryProperty("productId")),
    formConfig: state.bootstrap.formConfig,
  });

  const submitOffer = async () => {
    try {
      if (!loading) {
        const requestData: SubmitOfferRequestData =
          getPreparedOfferRequestData();
        setLoading(true);

        const result = await loadConfigData(
          testSubmitOfferResponse,
          LoadableResources.SUBMIT_OFFER,
          requestAppReload,
          requestData
        )();

        setOfferResponse(result);
        setLoading(false);
      }
    } catch (e) {
      // console.error(e);
      if (e.code && e.code === 401) {
        logout();
      }
      setLoading(false);
      setErrorMessage(DisplayErrorMessages.technicalProblem);
      setErrorCode(ErrorCodes.falseServerOffer);
    }
  };

  const setOfferState = (newOfferState: OfferState) => {
    setState((prev) => ({
      ...prev,
      offer: newOfferState,
    }));

    return {
      offerState: state.offer,
    };
  };

  return {
    submitOffer,
    setOfferState,
    loading,
    offerResponse,
    errorMessage,
    errorCode,
  };
};
