import styled from "styled-components";
import TileButton from "../../form/TileButton";
import { Color } from "../../../constants/color";

export const AdvantageButton = styled(TileButton)`
  background: ${Color.GREY};
  padding: 0;
  text-align: left;
  line-height: 16px;
  margin: 2px 0;
  max-width: unset;

  span {
    color: ${Color.WHITE} !important;
    text-align: left !important;
    padding: 5px !important;
    font-size: 14px !important;
    font-weight: normal !important;
  }
`;
