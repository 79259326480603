import { TransferDatabaseAdvantage } from "../../data/definitions/TransferDatabaseAdvantage";
import { DatabaseAdvantage } from "../../data/definitions/DatabaseAdvantage";
import { AdvantageLabels } from "../../data/definitions/AdvantageLabels";

function getTrimmedText(advantage: TransferDatabaseAdvantage) {
  return (
    advantage.product_property_headline +
    " " +
    advantage.product_property_value
  )
    .trim()
    .toUpperCase();
}

export function getDatabaseAdvantage(
  transferObject: TransferDatabaseAdvantage,
  useLabelIds: boolean = true
): DatabaseAdvantage {
  let id = 1000 + Number(transferObject.product_property_value_id);

  if (useLabelIds) {
    // get projectLabelId
    const trimmedText = getTrimmedText(transferObject);
    let labelId = AdvantageLabels.findIndex((aLabel) => aLabel === trimmedText);

    if (labelId !== -1) id = 1000 + Number(labelId);
    if (labelId === -1)
      console.error("Unknown advantage id detected!", trimmedText);
  }

  let title = transferObject.product_property_headline;
  let description = transferObject.product_property_value;

  if (transferObject.product_property_headline === "") {
    title = transferObject.product_property_value;
    description = "";
  }
  return { id, title, description };
}
