import React from "react";
import styled from "styled-components";
import { StyledComponentProps } from "../../types/default";

const PanelTitle: React.FC<StyledComponentProps> = ({
  className,
  children,
}) => <li className={className}>{children}</li>;

export default styled(PanelTitle)`
  font-weight: bold;
  margin: 5px 5px 5px 0;
`;
