import * as React from "react";

function SvgMobileEuroFlat(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192" {...props}>
      <path
        d="M84 156h24m20 16H64c-6.63 0-12-5.37-12-12V32c0-6.63 5.37-12 12-12h64c6.63 0 12 5.37 12 12v128c0 6.63-5.37 12-12 12z"
        fill="none"
        stroke={`${color}`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={8}
      />
      <path
        fill="none"
        stroke={`${color}`}
        strokeMiterlimit={10}
        strokeWidth={8}
        d="M97.346 79.2S103.708 68 111.404 68 124 74.267 124 82s-4.863 14-12.596 14c-8.165 0-14.119-11.179-14.119-11.179l-2.858-5.645s-5.126-11.175-13.823-11.175C72.871 68 68 74.267 68 82s4.871 14 12.604 14c8.812 0 13.942-11.142 13.942-11.142l2.8-5.658z"
      />
    </svg>
  );
}

export default SvgMobileEuroFlat;
