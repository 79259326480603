import { AppState } from "../../state/definitions/AppState";
import { RecommendationRequest } from "../../data/definitions/RecommendationRequest";
import { RecommendationData } from "../../data/definitions/RecommendationData";
import getRecommendationConfig from "../getRecommendationConfig";
import { presetOfferStateFromRecommendation } from "../offer/presetOfferStateFromRecommendation";
import { presetNewOfferStateFromRecommendation } from "../offer/presetNewOfferStateFromRecommendation";
import { WithsObject } from "../../types/application";
import { mftiSort } from "./mftiSort";

export function processRecommendation(
  prevState: AppState,
  recommendationRequest: RecommendationRequest,
  recommendationData: RecommendationData
): AppState {
  const {
    mobileHasChanged,
    iotHasChanged,
    fixedHasChanged,
    tvHasChanged,
    mobileHasData,
    iotHasData,
    fixedHasData,
    tvHasData,
  } = prevState.form;
  // added only the products, for product lines which has changed or have no data
  const withsObject: WithsObject = {
    mobile: !mobileHasData || mobileHasChanged,
    iot: !iotHasData || iotHasChanged,
    fixed: !fixedHasData || fixedHasChanged,
    tv: !tvHasData || tvHasChanged,
  };
  console.log({
    mobileHasData: mobileHasData,
    mobileHasChanged: mobileHasChanged,
    iotHasData: iotHasData,
    iotHasChanged: iotHasChanged,
    fixedHasData: fixedHasData,
    fixedHasChanged: fixedHasChanged,
    tvHasData: tvHasData,
    tvHasChanged: tvHasChanged,
  });

  // collect filtered and calculated data
  const [recommendationConfig, databaseProducts, result] =
    getRecommendationConfig(
      prevState.products,
      withsObject,
      recommendationData.products,
      recommendationData.advantages,
      prevState.recommendation.modes,
      recommendationRequest
    );

  if (result) {
    const adjustedNewOfferState = presetNewOfferStateFromRecommendation(
      recommendationConfig,
      prevState.newOffer,
      withsObject
    );

    const adjustedOfferEntryIds = Object.keys(adjustedNewOfferState);
    const adjustedOfferEntries = Object.values(adjustedNewOfferState);
    const oldOfferEntryIds = prevState.offer.offerEntryIds;

    const allNewEntryIds = adjustedOfferEntryIds.filter((id: string, index) => {
      const entry = adjustedOfferEntries[index];
      // use this entry if the interest has changed, because this is a new one
      if (withsObject[entry.type]) return true;
      // use this entry if the interest has not changed, but was in the offerEntryIds array,
      // which contains the currently selected entries of the multiple columns on the recommendation page
      if (oldOfferEntryIds.findIndex((oldId) => oldId === id) > -1) return true;
      return false;
    });

    const newOfferEntryIds = allNewEntryIds.sort((keyA, keyB) =>
      mftiSort(
        adjustedNewOfferState[keyA].type,
        adjustedNewOfferState[keyB].type
      )
    );

    const adjustedOfferState = presetOfferStateFromRecommendation(
      recommendationConfig,
      prevState.offer,
      withsObject,
      newOfferEntryIds
    );

    return {
      ...prevState,
      form: {
        ...prevState.form,
        mobileHasChanged: false,
        iotHasChanged: false,
        fixedHasChanged: false,
        tvHasChanged: false,
      },
      offer: adjustedOfferState,
      recommendation: {
        ...prevState.recommendation,
        loading: false,
        isLoadedOnce: true,
        config: recommendationConfig,
      },
      products: databaseProducts,
      newOffer: adjustedNewOfferState,
    };
  }
  return prevState;
}
