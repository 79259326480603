import * as React from "react";

function Svg5G(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <path
        d="M333 256.4h44.7v38.3c.3 14-8.9 26.5-22.3 30.3-26.5 7.3-79.8 11.8-79.8-75 0-94.1 84.9-82.6 97.3-59m-248 132.1c11.8 7.3 25.5 11.2 39.2 11.2 28.1 0 50.7-18.5 50.7-46.6s-16-47.9-45.3-50.1c-3.2-.3-6.4 0-9.6.3l-24.6 2.6v-66.7h73.4"
        fill="none"
        stroke={color}
        strokeWidth={24.173}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={250}
        cy={250}
        r={242.5}
        fill="none"
        stroke={color}
        strokeWidth={24.173}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default Svg5G;
