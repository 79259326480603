import * as React from "react";

function SvgRouter(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="router_svg___x34_e1f9918-6229-4c80-90f7-427711460041"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.router_svg__st0,.router_svg__st1{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}.router_svg__st1{fill: ${color} }`}
      </style>
      <path
        id="router_svg__c7dc6a8b-045b-4b23-8b3f-e874b5cb98cf"
        className="router_svg__st0"
        d="M37.1 52.5c32.4-32.6 85.1-32.7 117.7-.3l.2.2.1.1"
      />
      <path
        id="router_svg___x33_c974de0-6910-4435-bc86-77d97454d3f1"
        className="router_svg__st0"
        d="M54.2 70.4c23.2-23.3 60.8-23.3 84.1-.2l.2.2"
      />
      <path
        id="router_svg__c4ea5049-06c8-4e93-b49a-c977d0b0607a"
        className="router_svg__st0"
        d="M71.7 88c13.5-13.5 35.4-13.6 48.9-.1l.1.1"
      />
      <circle className="router_svg__st1" cx={96.1} cy={141} r={4} />
      <circle className="router_svg__st1" cx={96.1} cy={141} r={2.5} />
      <circle className="router_svg__st1" cx={96.1} cy={141} r={1} />
      <path
        className="router_svg__st0"
        d="M40.1 116h112c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12h-112c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12z"
      />
    </svg>
  );
}

export default SvgRouter;
