import * as React from "react";

function SvgAccelerometerHalfGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#333" } = props;
  return (
    <svg
      id="accelerometerHalf_grey_svg__ICON"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.accelerometerHalf_grey_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        id="accelerometerHalf_grey_svg___x32_c33339c-e9ce-4d4a-9d19-d1ccd94ddb1b"
        className="accelerometerHalf_grey_svg__st0"
        d="M271.6 359.5c-12.7 12.7-33.2 12.7-45.9 0-12.7-12.7-12.7-33.2 0-45.9 12.7-12.7 33.2-12.7 45.9 0l94.9-92.3"
      />
      <path
        id="accelerometerHalf_grey_svg___x33_4ae2b9e-5a5d-4845-a6c5-cc21215f6a91"
        className="accelerometerHalf_grey_svg__st0"
        d="M250 164.2v28.5"
      />
      <path
        id="accelerometerHalf_grey_svg___x31_f67863d-ec1e-4010-8358-f4f79bf5a708"
        className="accelerometerHalf_grey_svg__st0"
        d="M78.8 335.3h28.5"
      />
      <path
        id="accelerometerHalf_grey_svg___x36_d0f923f-ec7f-4b2c-b556-567fb48475ce"
        className="accelerometerHalf_grey_svg__st0"
        d="M128.8 214.1l21.4 21.4"
      />
      <path
        id="accelerometerHalf_grey_svg___x34_c518e8f-8910-4b5b-8166-ed967e8ec8b5"
        className="accelerometerHalf_grey_svg__st0"
        d="M392.6 335.3h28.6"
      />
      <path
        className="accelerometerHalf_grey_svg__st0"
        d="M16 399.2c-5.5-20.3-8.5-41.7-8.5-63.8 0-134 108.6-242.6 242.5-242.6s242.5 108.6 242.5 242.5c0 22.1-3 43.5-8.5 63.8"
      />
    </svg>
  );
}

export default SvgAccelerometerHalfGrey;
