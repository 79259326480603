import Axios from "axios";

const axios = Axios.create({
  //   baseURL: "http://localhost:4400/api/",
  timeout: 20000,
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          window.localStorage.removeItem("lta");
          let e = new Error("Authentifizierung fehlgeschlagen") as any;
          e.code = 401;
          return Promise.reject(e);
        case 429:
          return Promise.reject(
            new Error(
              "Von Deiner IP-Adresse wurden in einem kurzen Zeitraum zu viele Anfragen registriert. Bitte versuche es nach Minute wieder."
            )
          );
        case 400:
          return Promise.reject(
            new Error(
              `Fehler bei Bearbeitung der Anfrage: ${
                error.response ? error.response.data.message : error.message
              }`
            )
          );
        default:
          return Promise.reject(error);
      }
    } else {
      return Promise.reject(new Error(error.message));
    }
  }
);

export default axios;
