import React from "react";
import styled from "styled-components";
import { StyledComponentProps } from "../../../../types/default";

const GigaPlus: React.FC<StyledComponentProps> = ({ className }) => {
  return (
    <span className={className + " giga-plus-container"}>
      <span className={"giga-plus"}>+</span>
    </span>
  );
};

export default styled(GigaPlus)`
  display: inline-block;
  position: relative;
  box-sizing: content-box;

  padding: 25px 5px;
  vertical-align: middle;

  .giga-plus {
    display: block;
    font-size: 50px;
  }
`;
