import * as React from "react";

function SvgBackspace(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;

  return (
    <svg
      id="backspace_svg__ae57d9d9-3df7-4b16-bda7-361eb90daeba"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>{`.backspace_svg__st0,.backspace_svg__st1{fill:none}.backspace_svg__st1{stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}</style>
      <path className="backspace_svg__st0" d="M0 0h192v192H0z" />
      <path className="backspace_svg__st0" d="M0 0h192v192H0z" />
      <path
        className="backspace_svg__st1"
        d="M22.4 87.6L46.9 49c2-3.1 5.4-5 9.1-5h104.3c6.4 0 11.7 5.2 11.7 11.6v80.7c0 6.4-5.2 11.7-11.6 11.7H56c-3.7 0-7.1-1.9-9.1-5l-24.5-38.6c-3.3-5.1-3.3-11.7 0-16.8zM83.5 71.5L132 120M132.5 71.5L84 120"
      />
    </svg>
  );
}

export default SvgBackspace;
