import React from "react";
import styled from "styled-components";
import { StyledComponentProps } from "../../types/default";

const Pane: React.FC<StyledComponentProps> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

export default styled(Pane)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #e6e6e5;
  padding-bottom: 106px;
  /* display: block; */
  overflow-x: hidden;

  position: absolute;
  left: 80px;
  right: 10px;
  height: 100vh;
  width: auto;
`;
