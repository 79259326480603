class SearchQuery {
  zip: string = "";
  city: string = "";
  street: string = "";
  streetNumber: string = "";
  streetNumberExtra: string = "";
}

export class AddressResult extends SearchQuery {
  addressId: string = "";
}

export class TechnologyAvailability {
  available: boolean = false;
  upstream: number = 0;
  downstream: number = 0;
  regioTax: boolean = false;
}

export enum Technology {
  cable = "cable",
  fiber = "fiber",
  dsl = "dsl",
  tv = "tv",
  mobile = "mobile",
}

export type Availability<T = TechnologyAvailability> = {
  [key in Technology]: T;
};

export interface AvailabilityState {
  loading: boolean;
  addresses: AddressResult[];
  selectedAddress: null | AddressResult;
  errorMessage: string;
  errorCode: string;
  availability: Availability;
  someIsAvailable: boolean;
}
