import * as React from "react";

function SvgCloudDownloadColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="cloudDownload_color_svg___x37_8514ff2-83c6-4979-9195-b9b3b900469c"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".cloudDownload_color_svg__st0{fill:none;stroke:#e60000;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <g id="cloudDownload_color_svg__ea531758-bf67-4275-a57c-b126455816b6">
        <g id="cloudDownload_color_svg___x35_2e39435-eac0-4fc3-8747-febefe78d60d">
          <path
            id="cloudDownload_color_svg__c799f7ea-080d-45c2-8385-e9a05b643951"
            className="cloudDownload_color_svg__st0"
            d="M118.3 124s22.4.9 31.2-3c12.4-5.6 20.9-17.4 22.3-31 1.3-13.1-4.7-27.5-15.9-34.4-5.6-3.5-12.2-4.8-18.7-5.4-3.7-.1-7.5.1-11.2.5-5.3-16.9-20.3-29-37.9-30.5-13.6-1-26.9 4.6-35.7 15.1-4.2 5.1-7.3 11-8.9 17.5-.4 1.7-1.3 6.4-1.3 6.4-4.4 1.4-8.4 3.7-11.9 6.8-10.5 9.4-13.1 25.5-7.2 38.1 5.3 11.5 16.8 20 29.6 20h21"
          />
        </g>
        <path className="cloudDownload_color_svg__st0" d="M96 88v84" />
        <path fill="none" d="M0 0h192v192H0z" />
        <path
          className="cloudDownload_color_svg__st0"
          d="M122 144l-26 28-26-28"
        />
      </g>
    </svg>
  );
}

export default SvgCloudDownloadColor;
