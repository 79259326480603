import React from "react";
import styled from "styled-components";

interface JustifiedColumnsContainerProps {
  className?: string;
  extraClassName?: string;
  flexDirection?: "row" | "column" | "row-reverse" | "column-reverse";
  alignItems?: "center" | "stretch" | "flex-start" | "flex-end";
  flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  height?: string;
  onClick?: () => void;
}

const JustifiedColumnsContainer: React.FC<JustifiedColumnsContainerProps> = ({
  children,
  className,
  extraClassName = "",
  onClick,
}) => {
  return (
    <div
      className={className + " " + extraClassName}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {children}
    </div>
  );
};

export default styled(JustifiedColumnsContainer)`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : "row"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "stretch")};

  flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : "wrap")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
  height: ${({ height }) => (height ? height : "initial")};

  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
`;
