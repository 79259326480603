import React from "react";
import styled from "styled-components";
import HeadlineImage from "../navigation/HeadlineImage";
import { Color } from "../../constants/color";
import { useNavigation } from "../../hooks/useNavigation";
import Icons from "../../icons/Icons";
import { SlideCategoryTypes } from "../../constants/SlidePages";
import { SVGComponent } from "../../types/default";

interface HeadlineProps {
  className?: string;
  images?: SVGComponent[];
}

const Headline: React.FC<HeadlineProps> = ({ children, className, images }) => {
  const { getCurrentIdCategory, isChangeGuaranteeSlideShowing } =
    useNavigation();
  if (images === undefined) {
    if (isChangeGuaranteeSlideShowing) {
      images = [Icons.guarantee];
    } else {
      const currentCategory: SlideCategoryTypes = getCurrentIdCategory();
      switch (currentCategory) {
        case SlideCategoryTypes.start:
          images = [Icons.start];
          break;
        case SlideCategoryTypes.mobile:
          images = [Icons.mobile];
          break;
        case SlideCategoryTypes.iot:
          images = [Icons.iotSim];
          break;
        case SlideCategoryTypes.fixed:
          images = [Icons.landline, Icons.internet];
          break;
        case SlideCategoryTypes.tv:
          images = [Icons.tv];
          break;
        case SlideCategoryTypes.profile:
          images = [Icons.profile];
          break;
        case SlideCategoryTypes.recommendation:
          images = [Icons.euro];
          break;
        default:
          break;
      }
    }
  }

  return (
    <div className={className}>
      {!images && <h1>{children}</h1>}
      {!!images && (
        <>
          <h1 className={"with-images-" + images.length}>{children}</h1>
          <div>
            {images.map((image, i) => {
              return <HeadlineImage key={i} selected={true} Icons={image} />;
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default styled(Headline)`
  h1 {
    color: ${Color.RED};
    font-size: 42px;
    padding-top: 42px;
    padding-bottom: 18px;
    padding-left: 10px;
    display: inline-block;
    letter-spacing: 1px;

    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  .with-images-1 {
    padding-right: 66px;
  }
  .with-images-2 {
    padding-right: 132px;
  }

  .headline-image {
    display: inline-block;
    width: 66px;
    position: absolute;
    top: 15px;
    border: none;
    :nth-child(1) {
      right: 0;
    }
    :nth-child(2) {
      right: 66px;
    }
    :nth-child(3) {
      right: 132px;
    }
    :nth-child(4) {
      right: 198px;
    }
  }
`;
