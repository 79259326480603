import styled from "styled-components";
import React, { useState } from "react";
import Tabs from "../tabs/Tabs";
import ModalInner from "./ModalInner";
import { Color } from "../../constants/color";

interface TabsModalProps {
  className?: string;
  openButton: (
    onChange: (value: boolean, tabId: number) => void,
    value: boolean
  ) => JSX.Element;
  currentTabIndex: number;
  setCurrentTabIndex: (v: number) => void;
  tabNames?: Array<string | undefined>;
}

const TabsModal: React.FC<TabsModalProps> = ({
  className,
  openButton,
  currentTabIndex,
  setCurrentTabIndex,
  children,
  tabNames,
}) => {
  const [isOpen, setOpen] = useState(false);

  const onChange = (value: boolean, tabId: number) => {
    setCurrentTabIndex(tabId);
    setOpen(value);
  };
  return (
    <>
      {openButton(onChange, isOpen)}
      {isOpen && (
        <div className={className}>
          <ModalInner
            modalColor={Color.WHITE}
            fontColor={Color.RED}
            darkBackground
            onChange={setOpen}
          >
            <Tabs
              tabNames={tabNames}
              preferredTab={currentTabIndex}
              changeTab={setCurrentTabIndex}
            >
              {children}
            </Tabs>
          </ModalInner>
        </div>
      )}
    </>
  );
};

export default styled(TabsModal)``;
