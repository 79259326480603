import { getTestResultString } from "./getTestResultString";
import { testPropOfObject } from "./testPropOfObject";
import {
  extraCheckFunction,
  TestClassType,
  TestClassTypeInterface,
} from "./interfaces";

export function testObjectIsFromClass<T extends TestClassTypeInterface>(
  testObject: any,
  TestClass: TestClassType<T>,
  withTypeCheck: boolean = false,
  extraCheck?: extraCheckFunction<T>
): string {
  const aObject = new TestClass();
  const aObjectKeys = Object.keys(aObject);
  let lastIndex = -1;

  const result = aObjectKeys.some((v, i) => {
    lastIndex = i;
    if (withTypeCheck) {
      // TODO: Add typechecking (typeof v === typeof testObject[v])
    }
    return testPropOfObject(v, testObject, extraCheck);
  });

  return getTestResultString(result, lastIndex, aObjectKeys);
}
