import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";

export interface PanelGroupPrintTitleProps {
  className?: string;
  label: string;
  value?: string;
}

const PanelGroupPrintTitle: React.FC<PanelGroupPrintTitleProps> = ({
  className,
  value,
  label,
}) => (
  <div className={className}>
    <h4 className={"panel-print-title"}>
      <span className={"panel-print-title-label"}>{label}</span>
      <span className={"panel-print-title-value"}>{value ?? ""}</span>
      <span className={"panel-print-title-clear"} />
    </h4>
  </div>
);

export default styled(PanelGroupPrintTitle)`
  padding: 1px 2px;
  background: ${Color.GREY_LIGHT2};
  color: ${Color.BLACK};
  .panel-print-title {
    font-size: 14px;
  }
  .panel-print-title-label {
  }
  .panel-print-title-value {
    font-weight: bold;
    float: right;
  }
  .panel-print-title-clear {
    clear: both;
  }
`;
