import { FormState } from "../definitions/FormState";
import { dateToString } from "../../helper/dateStringConversion";
import { ContractSummary } from "../../types/application";

export const defaultFormState: FormState = {
  // Start
  independentPersonalDetails: [],

  providerMobileId: -1,
  providerIotId: -1,
  providerFixedId: -1,
  providerTvId: -1,
  //MOBILE
  mobileCurrentPrice: 0,
  mobileCurrentDataVolume: 0,
  mobileCurrentContractEnd: dateToString(new Date()),
  mobileWithoutCurrentContractEnd: false,
  needAdditionalDataVolume: false,
  mobilePreferencesSmartphone: [],
  mobilePreferencesDataVolume: [],
  mobilePreferencesPrice: [],
  mobileDataVolume: 0,
  mobileDataSocial: false,
  mobileDataMusic: false,
  mobileDataChat: false,
  mobileDataVideo: false,
  mobileDataGaming: false,
  mobileDataSharePeople: 0,
  mobileDataShareKids: 0,
  mobileDevicesTablet: 0,
  mobileDevicesSmartwatch: 0,
  mobileDevicesIOT: 0,
  mobileDevicesSmartphone: 0,
  mobilePersonalDetails: [],
  mobileFirstHumanProductCounts: [],
  mobileSecondHumanProductCounts: [],
  mobileThirdHumanProductCounts: [],
  mobileFourthHumanProductCounts: [],
  mobileFifthHumanProductCounts: [],
  mobileFirstHumanContractId: 0,
  mobileSecondHumanContractId: -1,
  mobileThirdHumanContractId: -1,
  mobileFourthHumanContractId: -1,
  mobileFifthHumanContractId: -1,
  mobileFirstHumanContractName: "Ich",
  mobileSecondHumanContractName: "",
  mobileThirdHumanContractName: "",
  mobileFourthHumanContractName: "",
  mobileFifthHumanContractName: "",
  // IOT
  iotInterests: [],
  iotSmartWatchLabels: [],
  iotTrackerLabels: [],
  iotSmartWatchOwnDevice: false,
  iotTrackerOwnDevice: false,
  iotCameraOwnDevice: false,
  //FIXED
  //FixedPreferences
  fixedPreferences: [],
  // FixedDevicesPage
  fixedDevicesLaptop: 0,
  fixedDevicesSmartphone: 0,
  fixedDevicesTablet: 0,
  fixedDevicesPC: 0,
  fixedDevicesPhone: 0,
  fixedDevicesTV: 0,
  fixedDevicesSmartHome: 0,
  fixedDevicesConsole: 0,
  fixedDevicesSmartwatch: 0,
  fixedDevicesIOT: 0,
  // FixedCallDestination
  fixedCallDestination: [],
  // FixedCurrentConditionsPage
  fixedCurrentPrice: 0,
  fixedCurrentBandwidth: 6,
  fixedContractCurrentEnd: dateToString(new Date()),
  fixedWithoutCurrentContractEnd: false,
  // TV
  tvCurrentPrice: 0,
  tvContractCurrentEnd: dateToString(new Date()),
  tvWithoutCurrentContractEnd: false,
  additionalCostInvoice: -1,
  //
  tvTechnology: [],
  //
  tvPreference: [],
  // IOT
  iotCurrentPrice: 0,

  //calculated
  interests: [],
  contractData: [],
  contractsCount: 0,
  contractCounts: new ContractSummary(),
  fixedDeviceCount: 0,
  deviceNeededBandwidth: 0,

  mobileHasData: false,
  iotHasData: false,
  fixedHasData: false,
  tvHasData: false,
  mobileHasChanged: false,
  iotHasChanged: false,
  fixedHasChanged: false,
  tvHasChanged: false,

  profileNotices: "",
  withRegioDiscount: false,
};
