import React, { CSSProperties } from "react";
import styled from "styled-components";
import GigaCircles from "./GigaCircles";
import { Color } from "../../../../constants/color";
import GigaKombiSVG from "./GigaKombiSVG";
import { GigaKombiEntry } from "../../../../types/application";

interface GigaKombiProps {
  className?: string;
  selected?: boolean;
  style?: CSSProperties;
  gigaKombiEntry: GigaKombiEntry;
  backgroundColor?: string;
  withStar?: boolean;
  starText?: string;
}

const GigaKombi: React.FC<GigaKombiProps> = ({
  className,
  style,
  gigaKombiEntry: { mobile, iot, fixed, tv, circleData },
  withStar = false,
  starText,
}) => {
  return (
    <div className={className} style={style}>
      <div className={"giga-icons-column"}>
        <GigaKombiSVG withsObject={{ mobile, iot, fixed, tv }} size={100} />
      </div>
      <div className={"giga-prices"}>
        <GigaCircles circleData={circleData} withStar={withStar} />
      </div>
      {starText !== undefined && (
        <p className={"giga-star-text"}>{"* " + starText}</p>
      )}
    </div>
  );
};

export default styled(GigaKombi)`
  display: block;
  min-height: 100px;
  width: 100%;

  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : Color.RED};
  color: ${Color.WHITE};

  overflow: hidden;
  text-align: left;
  @media (max-width: 895px) {
    margin-top: 10px;
  }
  @media (max-width: 1024px) {
    text-align: center;
  }

  tbody {
    width: 100%;
  }

  .giga-paragraph {
    font-size: 24px;

    p {
      padding: 10px;
    }
  }

  .giga-icons-column {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 100px;
  }

  .giga-prices {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 100%;

    @media (min-width: 896px) {
      width: calc(100% - 120px);
      margin-left: 20px;
      text-align: left;
    }

    @media (max-width: 895px) {
      width: 100%;
      text-align: center;
    }
  }
  :not(:last-of-type) {
    .giga-prices {
      @media (max-width: 895px) {
        border-bottom: 1px solid white;
      }
    }
  }

  .giga-star-text {
    font-size: 12px;
    margin: 2px 5px;
    text-align: right;
  }
`;
