import React from "react";
import styled from "styled-components";
import usePrint from "../../hooks/usePrint";
import PrintHeadline from "../headline/PrintHeadline";
import JustifiedColumnsContainer from "../grid/JustifiedColumnsContainer";
import PanelGroup from "../panel/PanelGroup";
import { Color } from "../../constants/color";
import Column from "../grid/Column";
import useProfile from "../../hooks/useFormHelper/useProfile";
import PanelItemsMobileImportant from "../elements/profile/PanelItemsMobileImportant";
import PanelItemsFixedImportant from "../elements/profile/PanelItemsFixedImportant";
import PanelItemTVImportant from "../elements/profile/PanelItemTVImportant";
import PanelItemsMobileSituation from "../elements/profile/PanelItemsMobileSituation";
import PanelItemsTVSituation from "../elements/profile/PanelItemsTVSituation";
import PanelItemsFixedSituation from "../elements/profile/PanelItemsFixedSituation";
import PrintPanel from "../panel/PrintPanel";
import PrintPanelTitle from "../panel/PrintPanelTitle";
import PanelItemsPersonalSituation from "../elements/profile/PanelItemsPersonalSituation";
import PanelItemsPersonalHomeSituation from "../elements/profile/PanelItemsPersonalHomeSituation";
import PrintPanelGroupTitle from "../panel/PrintPanelGroupTitle";
import ColorIcons from "../../icons/ColorIcons";
import MultilineTextField from "../form/MultilineTextField";
import { useFormState } from "../../hooks/useForm";
import { StyledComponentProps } from "../../types/default";
import PrintTopLine from "../elements/recommendationPage/print/PrintTopLine";
import PrintBottomLine from "../elements/recommendationPage/print/PrintBottomLine";
import PanelItemsIotSituation from "../elements/profile/PanelItemsIotSituation";
import PanelItemsPriceSituation from "../elements/profile/PanelItemsPriceSituation";

const PrintProfilePage: React.FC<StyledComponentProps> = ({ className }) => {
  const { isLoaded } = usePrint();
  const {
    data,
    thereArePriceInformation,
    thereAreHomeInformation,
    thereArePersonalInformation,
  } = useProfile();
  const [notices] = useFormState("profileNotices");

  return (
    <div className={className}>
      <PrintTopLine />
      <div className={"print-page-container"}>
        <PrintHeadline maxWidth={"unset"}>Dein Profil</PrintHeadline>
        {!isLoaded && (
          <>{"Daten wurden noch nicht erhalten. Ein Fehler liegt vor!"}</>
        )}
        {isLoaded && (
          <>
            <JustifiedColumnsContainer>
              <Column colCount={2} maxWidth={"unset"}>
                <PanelGroup
                  titleBackgroundColor={Color.GREY_LIGHT2}
                  borderColor={Color.GREY_LIGHT2}
                  title={<PrintPanelGroupTitle value={"Aktuelle Situation:"} />}
                >
                  {!!data.mobile.interesting && (
                    <>
                      <PrintPanelTitle>Mobilfunk</PrintPanelTitle>
                      <PrintPanel backgroundColor={Color.WHITE}>
                        <PanelItemsMobileSituation situation={data.mobile} />
                      </PrintPanel>
                    </>
                  )}
                  {!!data.fixed.interesting && (
                    <>
                      <PrintPanelTitle>Internet & Festnetz</PrintPanelTitle>
                      <PrintPanel backgroundColor={Color.WHITE}>
                        <PanelItemsFixedSituation situation={data.fixed} />
                      </PrintPanel>
                    </>
                  )}
                  {!!data.tv.interesting && (
                    <>
                      <PrintPanelTitle>TV</PrintPanelTitle>
                      <PrintPanel backgroundColor={Color.WHITE}>
                        <PanelItemsTVSituation situation={data.tv} />
                      </PrintPanel>
                    </>
                  )}
                  {data.iot.interesting && (
                    <>
                      <PrintPanelTitle>IoT</PrintPanelTitle>
                      <PrintPanel backgroundColor={Color.WHITE}>
                        <PanelItemsIotSituation situation={data.iot} />
                      </PrintPanel>
                    </>
                  )}
                  {thereArePriceInformation && (
                    <>
                      <PrintPanelTitle>Deine aktuellen Kosten</PrintPanelTitle>
                      <PrintPanel backgroundColor={Color.WHITE}>
                        <PanelItemsPriceSituation situation={data.price} />
                      </PrintPanel>
                    </>
                  )}
                  {thereAreHomeInformation && (
                    <>
                      <PrintPanelTitle>Dein Haushalt</PrintPanelTitle>
                      <PrintPanel backgroundColor={Color.WHITE}>
                        <PanelItemsPersonalHomeSituation
                          importants={data.personalHome}
                        />
                      </PrintPanel>
                    </>
                  )}
                  {thereArePersonalInformation && (
                    <>
                      <PrintPanelTitle>Du bist</PrintPanelTitle>
                      <PrintPanel backgroundColor={Color.WHITE}>
                        <PanelItemsPersonalSituation
                          importants={data.personal}
                        />
                      </PrintPanel>
                    </>
                  )}

                  {!data.fixed.interesting &&
                    !data.mobile.interesting &&
                    !data.tv.interesting && (
                      <>
                        <PrintPanelTitle>
                          Sie haben kein Interesse an Vodafone-Produkten!
                        </PrintPanelTitle>
                        <PrintPanel backgroundColor={Color.WHITE} />
                      </>
                    )}
                </PanelGroup>
              </Column>
              <Column colCount={2} maxWidth={"unset"}>
                <PanelGroup
                  titleBackgroundColor={Color.RED}
                  fontColor={Color.WHITE}
                  borderColor={Color.RED}
                  margin={"0 0 5px 0"}
                  title={
                    <PrintPanelGroupTitle value={"Das ist Dir wichtig:"} />
                  }
                >
                  {!!data.mobile.interesting && (
                    <>
                      <PrintPanelTitle
                        backgroundColor={Color.RED}
                        fontColor={Color.WHITE}
                      >
                        Mobilfunk
                      </PrintPanelTitle>
                      <PrintPanel backgroundColor={Color.WHITE}>
                        <PanelItemsMobileImportant
                          iconSet={ColorIcons}
                          importants={data.important.mobile}
                        />
                      </PrintPanel>
                    </>
                  )}
                  {!!data.fixed.interesting && (
                    <>
                      <PrintPanelTitle
                        backgroundColor={Color.RED}
                        fontColor={Color.WHITE}
                      >
                        Internet & Festnetz
                      </PrintPanelTitle>
                      <PrintPanel backgroundColor={Color.WHITE}>
                        <PanelItemsFixedImportant
                          iconSet={ColorIcons}
                          importants={data.important.fixed}
                        />
                      </PrintPanel>
                    </>
                  )}
                  {!!data.tv.interesting && (
                    <>
                      <PrintPanelTitle
                        backgroundColor={Color.RED}
                        fontColor={Color.WHITE}
                      >
                        TV
                      </PrintPanelTitle>
                      <PrintPanel backgroundColor={Color.WHITE}>
                        <PanelItemTVImportant
                          iconSet={ColorIcons}
                          importants={data.important.tv}
                        />
                      </PrintPanel>
                    </>
                  )}
                </PanelGroup>
                <PanelGroup
                  titleBackgroundColor={Color.GREY_LIGHT2}
                  borderColor={Color.GREY_LIGHT2}
                  margin={"0"}
                  title={
                    <PrintPanelTitle>
                      Notizen zu unserer Empfehlung{" "}
                    </PrintPanelTitle>
                  }
                >
                  <NoticePanel>
                    <MultilineTextField value={notices} />
                  </NoticePanel>
                </PanelGroup>
              </Column>
            </JustifiedColumnsContainer>
          </>
        )}
      </div>
      <PrintBottomLine />
    </div>
  );
};
const NoticePanel = styled(PrintPanel)`
  padding: 0 10px 10px 10px;
`;
export default styled(PrintProfilePage)`
  .print-page-container {
    height: 748px;
    padding: 0 30px 20px 30px;
  }
`;
