import { DatabaseProduct } from "../../data/definitions/DatabaseProduct";
import { ProductInformation } from "./definitions/ProductInformation";
import { getProductDescription } from "./getProductDescription";
import { getProductMonthlyPriceArray } from "./getProductMonthlyPriceArray";
import { RecommendationModeArray } from "../../types/application";

export function getProductInformation(
  product: DatabaseProduct,
  productLineModes?: RecommendationModeArray
): ProductInformation {
  return {
    id: product.productId, //number;
    preLabel: "", //string;
    label: product.productName, //string;
    postLabel: "", //string;
    preDescription: "", //string;
    description: getProductDescription(product), //string;
    postDescription: "", //string;
    prices: getProductMonthlyPriceArray(
      product,
      productLineModes ? Object.values(productLineModes) : []
    ), //MonthlyPriceArray;
    dataVolume: product.productDataVolumeIncluded, //number;
    advantages: [
      product.productProperty01,
      product.productProperty02,
      product.productProperty03,
      product.productProperty04,
      product.productProperty05,
    ], //number[];
  };
}
