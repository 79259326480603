import { FormConfigState } from "../definitions/FormConfigState";

export const defaultFormConfigState: FormConfigState = {
  mobilePreferenceCategories: [],
  mobilePreferencesSmartphone: [],
  mobilePreferencesDataVolume: [],
  mobilePreferencesPrice: [],
  mobileData: [],
  mobilePersonalDetails: [],
  mobileCardDataGoLabel: [],
  mobileCardDataLabel: [],
  mobileCardTariffLabel: [],
  iotInterestLabels: [],
  iotSmartWatchLabels: [],
  iotTrackerLabels: [],
  fixedPreferences: [],
  fixedCallDestination: [],
  fixedDeviceTypes: [],
  tvPreference: [],
  tvTechnology: [],
  additionalCostInvoice: [],
  mobileProviders: [],
  iotProviders: [],
  fixedProviders: [],
  tvProviders: [],
  categoryNames: [],
  independentPersonalDetails: [],
};
