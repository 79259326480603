import { DatabaseProductTypes } from "../../data/definitions/DatabaseProductTypes";

export function getProductTypeFromString(
  typeString: string
): DatabaseProductTypes {
  switch (typeString) {
    case DatabaseProductTypes.mobile:
      return DatabaseProductTypes.mobile;
    case DatabaseProductTypes.iot:
      return DatabaseProductTypes.iot;
    case DatabaseProductTypes.fixed:
      return DatabaseProductTypes.fixed;
    case DatabaseProductTypes.tv:
      return DatabaseProductTypes.tv;
    default:
      return DatabaseProductTypes.unknown;
  }
}
