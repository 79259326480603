import React from "react";
import styled from "styled-components";
import { SVGComponent } from "../../types/default";
import SVGIcon from "../svgIcon/SVGIcon";

interface PanelItemProps {
  className?: string;
  icon?: SVGComponent;
  height?: number;
  color?: string;
  color2?: string;
  withoutMarginBottom?: boolean;
}

const PanelItem: React.FC<PanelItemProps> = ({
  className,
  children,
  icon,
  color2,
  color,
}) => (
  <li className={className}>
    {!!icon && <SVGIcon src={icon} alt="" color={color} color2={color2} />}
    {!icon && <div className={"img-placeholder-div"} />}
    <span className={"item-text"}>{children}</span>
  </li>
);

export default styled(PanelItem)`
  vertical-align: middle;
  margin-bottom: ${({ withoutMarginBottom }) =>
    withoutMarginBottom ? "unset" : "5px"};
  line-height: ${({ height }) => (height ? height - 2 : 18)}px;

  img,
  svg {
    max-width: ${({ height }) => (height ? height : 20)}px;
    height: ${({ height }) => (height ? height : 20)}px!important;
    vertical-align: top;
    display: inline-block;
  }

  .img-placeholder-div {
    width: ${({ height }) => (height ? height : 20)}px;
    height: ${({ height }) => (height ? height : 20)}px;
    vertical-align: top;
    display: inline-block;
  }

  .item-text {
    margin-left: 15px;
    font-size: ${({ height }) => (height ? height - 2 : 18)}px;
    vertical-align: top;
    display: inline-block;
    width: calc(100% - ${({ height }) => (height ? height + 15 : 35)}px);
  }
`;
