import React from "react";
import styled from "styled-components";
import { StyledComponentProps } from "../../types/default";

const Content: React.FC<StyledComponentProps> = ({ children, className }) => {
  return <div className={className + " content"}>{children}</div>;
};

export default styled(Content)`
  height: 100%;
  flex: 1;
  /* overflow: auto; */
  /* padding: var(--space) var(--space) 0; */
  /* width: 100%; */
`;
