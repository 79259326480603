import * as React from "react";

function SvgChildsGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#4a4d4e" } = props;
  return (
    <svg
      id="childs_grey_svg__Kids"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.childs_grey_svg__st0,.childs_grey_svg__st1{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round}.childs_grey_svg__st0{stroke-miterlimit:10}.childs_grey_svg__st1{stroke-linejoin:round}`}
      </style>
      <path
        id="childs_grey_svg___x34_22dae04-4879-4453-8160-03a16fa68059_2_"
        className="childs_grey_svg__st0"
        d="M94.8 85.6c0-.5.5-4.9.8-6.5 2.6-14.3 10.7-27 32-27 26.9 0 32.9 20.6 32.9 38.8 0 18.2-14.8 32.9-32.9 32.9-10.2 0-19.3-4.6-25.3-11.9"
      />
      <path
        id="childs_grey_svg___x35_5e22d66-b440-4e07-a5ff-4bf1be9a4f41"
        className="childs_grey_svg__st0"
        d="M138.5 104.7c-5.5 6-14.9 6.5-20.9 1-.3-.3-.6-.5-.8-.8"
      />
      <path className="childs_grey_svg__st0" d="M98.8 75.4l72.8-8.4" />
      <circle className="childs_grey_svg__st0" cx={139.2} cy={89.3} r={2.6} />
      <circle className="childs_grey_svg__st0" cx={116.7} cy={89.3} r={2.6} />
      <path className="childs_grey_svg__st0" d="M122.9 52.8s9.6 5.9 12 17.4" />
      <path
        id="childs_grey_svg___x34_22dae04-4879-4453-8160-03a16fa68059_1_"
        className="childs_grey_svg__st0"
        d="M69 75.9c27 0 33 20.7 33 38.9 0 18.3-14.8 33-33 33s-33-14.8-33-33c-.1-18.2 5.9-38.9 33-38.9z"
      />
      <path
        id="childs_grey_svg___x35_5e22d66-b440-4e07-a5ff-4bf1be9a4f41_1_"
        className="childs_grey_svg__st0"
        d="M80 128.6c-5.5 6.1-14.9 6.5-21 1-.3-.3-.6-.5-.8-.8"
      />
      <circle className="childs_grey_svg__st0" cx={80.6} cy={113.2} r={2.6} />
      <circle className="childs_grey_svg__st0" cx={58.1} cy={113.2} r={2.6} />
      <path
        className="childs_grey_svg__st1"
        d="M41.8 89.3L80 99.8v-8.2l20.3 8.2M65.5 76l-2.9-2.7c-18.2-15.7-32.1 7.1-34.5 10.8-1.6-4.1-3.2-25 11.9-31.7 14.1-6.3 28.3 2 28.3 18.9V76"
      />
    </svg>
  );
}

export default SvgChildsGrey;
