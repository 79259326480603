import * as React from "react";
import MiniGigaKombi from "./MiniGigaKombi";
import styled from "styled-components";
import { useOfferState } from "../../../../hooks/useOffer";
import { useGigaKombiWiths } from "../../../../hooks/useOfferHelper/useGigaKombiWiths";
import { useNewGigaKombi } from "../../../../hooks/useOfferHelper/useNewGigaKombi";
import { useMarketingCircleData } from "../../../../hooks/useOfferHelper/useMarketingCircleData";
import { StyledComponentProps } from "../../../../types/default";
import { getCircleDataFromGigaValues } from "../../../../helper/gigakombi/getCircleDataFromGigaValues";
import { useNewGigaKombiUpdate } from "../../../../hooks/useOfferHelper/useNewGigaKombiUpdate";

export interface Props extends StyledComponentProps {}

const MiniGigaKombiContainer = ({ className }: Props) => {
  const { changeGigaKombi } = useNewGigaKombi();

  const { getGigaKombiWiths, currentProducts } = useGigaKombiWiths();
  const withsObject = getGigaKombiWiths();

  const [selectedGigaKombi] = useOfferState("selectedGigaKombi");

  const [gigaKombiCalculatedDiscount] = useOfferState(
    "gigaKombiCalculatedDiscount"
  );
  const [gigaKombiDataVolume] = useOfferState("gigaKombiDataVolume");
  const [gigaKombiWithSky] = useOfferState("gigaKombiWithSky");

  const { circleData, isPossible } = getCircleDataFromGigaValues(
    withsObject,
    [gigaKombiCalculatedDiscount, gigaKombiDataVolume, gigaKombiWithSky],
    currentProducts
  );

  useNewGigaKombiUpdate(isPossible);
  const marketingCircleData = useMarketingCircleData();

  return (
    <div className={className}>
      {isPossible && (
        <MiniGigaKombi
          gigaKombiEntry={{ ...withsObject, circleData }}
          selected={selectedGigaKombi}
          onChange={changeGigaKombi}
        />
      )}
      {!isPossible && (
        <MiniGigaKombi
          gigaKombiEntry={{
            mobile: true,
            iot: false,
            fixed: true,
            tv: true,
            circleData: marketingCircleData,
          }}
          selected={selectedGigaKombi}
          onChange={changeGigaKombi}
          starText={"tarifabhängig"}
        />
      )}
    </div>
  );
};

export default styled(MiniGigaKombiContainer)`
  margin: 5px 0;
  min-width: 400px;
`;
