import React from "react";
import styled from "styled-components";
import { MonthlyPriceArray } from "../../../types/application";
import PriceSummary from "./PriceSummary";
import { StyledComponentProps } from "../../../types/default";

interface PriceSummaryContainerProps extends StyledComponentProps {
  oncePrice?: number;
  monthlyPriceArray?: MonthlyPriceArray;
}

const PriceSummaryContainer: React.FC<PriceSummaryContainerProps> = ({
  className,
  oncePrice = 0,
  monthlyPriceArray = {},
  children,
}) => {
  return (
    <div className={className}>
      {children}
      <PriceSummary
        monthlyPriceArray={monthlyPriceArray}
        oncePrice={oncePrice}
      />
    </div>
  );
};

export default styled(PriceSummaryContainer)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  .price-summary {
    margin: 5px 0;
    width: unset;
    min-width: 400px;
    max-height: 144px;
    min-height: 94px;
    @media (max-width: 937px) {
      width: 424px;
    }
  }
`;
