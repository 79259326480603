import {
  getSubProductSimpleData,
  isSameSimpleData,
  SimpleSubProductData,
} from "../../helper/simpleSubProductData";
import { useMemo } from "react";
import { TableDataObject } from "../../types/table";

const useSubProductSimpleData = (
  data: TableDataObject[],
  standardData?: TableDataObject,
  dataFilter?: (d: SimpleSubProductData, index?: number) => boolean
): SimpleSubProductData[] => {
  return useMemo(() => {
    if (standardData) {
      const d2 = getSubProductSimpleData(standardData);
      return data
        .map((v) => getSubProductSimpleData(v))
        .filter((v, i) => {
          return dataFilter !== undefined
            ? dataFilter(v, i) && !isSameSimpleData(v, d2)
            : !isSameSimpleData(v, d2);
        });
    }
    return [];
  }, [data, standardData, dataFilter]);
};

export default useSubProductSimpleData;
