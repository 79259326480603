import React from "react";
import styled from "styled-components";
import PanelItem from "../../panel/PanelItem";
import PanelValue from "../../panel/PanelValue";
import { defaultFormState } from "../../../state/defaults/defaultFormState";
import GreyIcons from "../../../icons/GreyIcons";
import { prettyDate, prettyMoney } from "../../../helper/getPrettyStrings";
import { FixedSituation } from "../../../types/profile";

interface PanelItemsFixedSituationProps {
  className?: string;
  situation: FixedSituation;
}

const PanelItemsFixedSituation: React.FC<PanelItemsFixedSituationProps> = ({
  situation,
}) => (
  <>
    {situation.provider !== undefined && (
      <PanelItem icon={GreyIcons.worldNet}>
        <PanelValue>{situation.provider}</PanelValue>
      </PanelItem>
    )}
    {situation.deviceCount !== undefined && situation.deviceCount > 0 && (
      <PanelItem icon={GreyIcons.devices}>
        Anzahl Geräte im Haushalt:{" "}
        <PanelValue>{situation.deviceCount}</PanelValue>
      </PanelItem>
    )}
    {situation.price !== undefined && situation.price > 0 && (
      <PanelItem icon={GreyIcons.price}>
        Preis: <PanelValue>{prettyMoney(situation.price)}</PanelValue>
      </PanelItem>
    )}
    {situation.bandwidth !== undefined &&
      situation.bandwidth !== defaultFormState.fixedCurrentBandwidth &&
      situation.bandwidth > 0 && (
        <PanelItem icon={GreyIcons.accelerometerHalf}>
          Geschwindigkeit{" "}
          <PanelValue>{situation.bandwidth + " Mbit/s"}</PanelValue>
        </PanelItem>
      )}
    {situation.currentContractEndDate !== undefined && (
      <PanelItem icon={GreyIcons.calendar}>
        Ende Vertragslaufzeit{" "}
        <PanelValue>{prettyDate(situation.currentContractEndDate)}</PanelValue>
      </PanelItem>
    )}
  </>
);

export default styled(PanelItemsFixedSituation)``;
