export enum DisplayErrorMessages {
  maxRebates = "Hinweis: Du kannst nur maximal 5 Rabatte eintragen, " +
    "bitte lösche einen Rabatt aus der Liste.",
  noAdvantage = "Hinweis: Eine GigaKombi ist mit den ausgewählten Produkten nicht mögich. " +
    "Bitte wähle dafür andere Produkte aus.",
  noAvailabilityAddresses = "Zu Deiner Suche konnte keine Adresse gefunden werden.",
  technicalProblemInternal = "Ein technisches Problem ist aufgetreten. Bitte versuchen Sie es erneut.",
  technicalProblem = "Ein technisches Problem ist aufgetreten. Bitte kontaktieren Sie Ihren Administrator.",
  noPdf = "Die PDF Datei konnte nicht erstellt werden.",
  nothing = "",
}
