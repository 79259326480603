import {
  IndexStructureObject,
  InterfaceChangedPropTypes,
  TypeOfArrayElement,
} from "../../types/util";
import { AppState } from "../../state/definitions/AppState";
import { SimpleState } from "../../types/default";
import { useContext } from "react";
import { AppContext } from "../../state/AppContext";
import { AppStatePropObjects } from "./useAppState";

// It's a object with keys of AppState[StateKey], but as values there the value and setValue
//    {key1:[value1,setValue1], key2: ...}
// K is property of AppState[StateKey]
// AppState[StateKey][K] is type of the property of AppState[StateKey]
type AppMultipleSimpleState<
  StateKey extends keyof AppStatePropObjects,
  KeyArray extends K[],
  K extends keyof AppState[StateKey]
> = InterfaceChangedPropTypes<
  Record<TypeOfArrayElement<KeyArray>, AppState[StateKey]>,
  SimpleState<AppState[StateKey][TypeOfArrayElement<KeyArray>]>
>;

/**
 * StateKey is property of AppState
 * AppState[StateKey] is type of the property of AppState
 * Key is property of AppState[StateKey]
 * AppState[StateKey][Key] is type of the property of AppState[StateKey]
 *
 * KeyArray is an Array of Key
 *
 * The param withChangeFct allows to change or
 * overwrite some specific values in the specific state.
 * *
 * @param stateKey
 * @param keys
 * @param withChangeFct
 */
export const useAppStateMultiple = <
  StateKey extends keyof AppStatePropObjects,
  Key extends keyof AppStatePropObjects[StateKey] & string,
  KeyArray extends Key[]
>(
  stateKey: StateKey,
  keys: KeyArray,
  withChangeFct?: (keys: Key) => Partial<AppState[StateKey]>
): AppMultipleSimpleState<StateKey, KeyArray, Key> => {
  const returnObject: IndexStructureObject<
    SimpleState<AppStatePropObjects[StateKey][TypeOfArrayElement<KeyArray>]>
  > = {};

  const [state, setState] = useContext(AppContext);
  keys.forEach((key: Key) => {
    const setValue = (
      value: AppStatePropObjects[StateKey][TypeOfArrayElement<KeyArray>]
    ) => {
      let changedObject = {};
      if (withChangeFct) {
        changedObject = withChangeFct(key);
      }
      setState((prev: AppState) => {
        return {
          ...prev,
          [stateKey]: {
            ...prev[stateKey],
            [key]: value,
            ...changedObject,
          },
        };
      });
    };

    const value = state[stateKey][key];
    returnObject[key as string] = [value, setValue];
  });
  return returnObject as AppMultipleSimpleState<StateKey, KeyArray, Key>;
};
