import React from "react";
import styled from "styled-components";
import { ZIndex } from "../../constants/zIndex";
import CheckboxList from "./CheckboxList";
import Column from "../grid/Column";
import { getImageSet } from "../../images";
import { getIcons } from "../../icons/Icons";
import IconLink from "../links/IconLink";
import ImageWithIcons from "../elements/ImageWithIcons";
import TileButton from "./TileButton";
import { Color } from "../../constants/color";
import { FormOption } from "../../state/definitions/FormConfigState";
import { FormConfigLabelIds } from "../../data/definitions/FormConfigLabelIds";
import { OuterLinks } from "../../data/definitions/OuterLinks";

interface ImageTileCheckboxListProps {
  className?: string;
  data: FormOption[];
  values?: number[];
  onChange: (values: number[]) => void;
}

const ImageTileCheckboxList: React.FC<ImageTileCheckboxListProps> = ({
  className,
  data,
  values = [],
  onChange,
}) => {
  return (
    <CheckboxList
      className={className}
      data={data}
      values={values}
      onChange={onChange}
      renderFkt={({ value, label }, isSelected, toggleValue) => {
        return (
          <Column
            colCount={data.length}
            maxWidth={100 / data.length + "%"}
            key={value}
          >
            <ImageWithIcons
              imageSet={getImageSet(value)}
              icons={getIcons(value)}
            >
              {value === FormConfigLabelIds.MOBILE_COOPERATED && (
                <div className={"i-button-container"}>
                  <IconLink to={OuterLinks.MOBILE_COOPERATED} />
                </div>
              )}
            </ImageWithIcons>
            <TileButton
              onChange={() => toggleValue(value)}
              value={isSelected}
              color={isSelected ? Color.RED : Color.GREY2}
              height={"80px"}
            >
              {label}
            </TileButton>
          </Column>
        );
      }}
    />
  );
};

export default styled(ImageTileCheckboxList)`
  .i-button-container {
    z-index: ${ZIndex.FRONT_1};
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;

    svg {
      cursor: pointer;
    }

    .tile-button {
      height: unset;
      padding: 0;
    }
  }
`;
