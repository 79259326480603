import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";

interface TextFieldProps {
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
  main?: boolean;
  label?: string;
  disabled?: boolean;
  name?: string;
}

const TextField: React.FC<TextFieldProps> = ({
  className,
  onChange,
  label,
  value = "",
  placeholder,
  disabled,
  name,
}) => {
  return (
    <div className={className + " default-text-field"}>
      {label && (
        <>
          {" "}
          <span>{label}</span>{" "}
        </>
      )}
      <input
        type="text"
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        autoComplete="off"
      />
    </div>
  );
};

export default styled(TextField)`
  line-height: 60px;
  background-color: ${(props) =>
    props.disabled ? Color.GREY_LIGHT : Color.RED};
  width: 100%;
  text-align: left;
  user-select: none;
  padding: 5px 10px;
  display: inline-block;
  margin: 0 2px;

  span {
    color: ${Color.WHITE};
    vertical-align: middle;
    font-size: 25px;
    width: calc(30%);
    display: inline-block;

    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input {
    padding: 10px;
    box-shadow: inset 4px 4px 20px -10px;
    border: none;
    font-size: 18px;
    width: ${({ label }) => (label ? "calc(70% - 4px)" : "calc(100% - 4px)")};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
`;
