import React, { useEffect } from "react";
import styled from "styled-components";
import { StyledComponentProps } from "../../types/default";
import useAuth from "../../hooks/useAuth";

interface Props extends StyledComponentProps {
  text?: string;
  redirect: string;
}

const RedirectMessage = ({ text, className, redirect }: Props) => {
  const { redirectTimeout } = useAuth();
  useEffect(() => {
    setTimeout(() => {
      window.location.href = redirect;
    }, redirectTimeout);
  }, []);

  return (
    <>
      <div className={className}>
        {text && text}
        {!text && (
          <React.Fragment>
            Du wirst zur Authentifizierung weiter geleitet
          </React.Fragment>
        )}
      </div>
    </>
  );
};

export default styled(RedirectMessage)`
  margin: auto;
  text-align: center;
  width: 50%;
  padding-top: 15vh;
`;
