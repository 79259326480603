import React from "react";
import styled from "styled-components";

interface NoticePanelHideTextProps {
  className?: string;
  isHide: boolean;
}

const NoticePanelHideText: React.FC<NoticePanelHideTextProps> = (props) => (
  <p className={props.className + (props.isHide ? " hide" : "")}>
    {!props.isHide && props.children}
  </p>
);

export default styled(NoticePanelHideText)`
  font-size: 12px;
  transition: visibility 0s, opacity 1s linear;

  padding-bottom: 10px;
  visibility: visible;
  opacity: 1;

  &.hide {
    padding: 0;
    visibility: hidden;
    opacity: 0;
  }
`;
