import { RecommendationProductLineTable } from "../../definitions/RecommendationState";

export const defaultRecommendationProductLineTable: RecommendationProductLineTable =
  {
    tables: [],
    tablesSubtitles: [],
    additionalTableLabels: [],
    additionalTableCounts: [],
    additionalTableCountIds: [],
    additionalTablePrices: [],
    additionalTableStarts: [],
    furtherProductIds: [],
    furtherProductLabels: [],
    furtherProductPrices: [],
    furtherProductCounts: [],
    furtherProductDataVolumes: [],
    furtherProductId: [],
    furtherProductCountId: [],
    furtherOneProductLabel: "",
    furtherOneProductCount: 0,
    furtherOneProductPrice: 0,
    singlePaymentTableLabels: [],
    singlePaymentTablePrices: [],
  };
