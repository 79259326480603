import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { StyledComponentProps } from "../../../types/default";
import RecommendationTable from "./RecommendationTable";
import { useWindowSize } from "../../../hooks/common/useWindowSize";
import { arrayToChunks } from "../../../helper/arrayToChunks";

export interface RecommendationTableProps extends StyledComponentProps {
  offerEntryIds: string[];
}

function calculateRecommendationColumnSpace(idCount: number) {
  // one offerEntryColumn is 350px+ 2* margin of 5px
  return idCount * 370;
}

const RecommendationTableContainer: React.FC<RecommendationTableProps> = ({
  className,
  children,
  offerEntryIds,
}) => {
  const [selectorCount, setSelectorCount] = useState(0);
  const windowSize = useWindowSize();

  // 90 page-diff from navbar
  let currentPossibleWidth = (windowSize.width ?? 0) - 90;

  let chunkSize: number = useMemo(() => {
    let chunkSize = 1;
    offerEntryIds.some((_, index) => {
      const count = offerEntryIds.length - index;
      const columnWidth = calculateRecommendationColumnSpace(count);
      // substrate 110 for preview-display-min-width left and right
      if (columnWidth <= currentPossibleWidth - 110) {
        if (count >= 2) {
          chunkSize = 2;
        }
        if (count >= 4) {
          chunkSize = 4;
        }
        return true;
      }
    });
    setSelectorCount(
      Math.min(Math.round(offerEntryIds.length / chunkSize) - 1, selectorCount)
    );
    return chunkSize;
  }, [windowSize.width, offerEntryIds.length]);

  let offerEntryIdGroups: string[][] = useMemo(() => {
    let idChunks: string[][] = [];
    idChunks = arrayToChunks(offerEntryIds, chunkSize);
    return idChunks;
  }, [chunkSize, offerEntryIds.join("")]);

  return (
    <div className={className + " recommendation-table-container"}>
      {offerEntryIdGroups.map((ids, index) => {
        const position = selectorCount - index;
        const currentColumnWidth = calculateRecommendationColumnSpace(
          ids.length
        );
        const selectedColumnWidth = calculateRecommendationColumnSpace(
          offerEntryIdGroups[selectorCount]?.length ?? 0
        );
        // right from selected
        let xMove =
          (position * -1 * (currentPossibleWidth + selectedColumnWidth)) / 2;

        // the selected one
        if (position === 0) {
          xMove = (currentPossibleWidth - currentColumnWidth) / 2;
        }

        // left from selected
        if (position === 1) {
          // substrate extra currentColumnWidth because,
          // this is the "left" parameter and the value will be negative
          const xMoveValue =
            (currentPossibleWidth - selectedColumnWidth) / 2 -
            currentColumnWidth;
          xMove = position * xMoveValue;
        }
        // lefter from selected, this adjustment is needed, because selected could have less ids, then the other ones
        if (position > 1) {
          xMove = -1 * position * currentColumnWidth;
        }
        return (
          <RecommendationTable
            key={index}
            offerEntryIds={ids}
            xMove={xMove}
            orderNumber={position * -1}
            onPreviewClick={() => setSelectorCount(index)}
          />
        );
      })}
      {children}
    </div>
  );
};

export default styled(RecommendationTableContainer)`
  position: relative;
  width: 100%;

  @media (max-height: 920px) and (min-width: 937px) {
    height: calc(100% - 105px);
  }
  @media (min-height: 920px) and (min-width: 937px) {
    height: calc(100% - 140px);
  }
  @media (max-height: 920px) and (max-width: 937px) {
    height: calc(100% - 165px);
  }
  @media (min-height: 920px) and (max-width: 937px) {
    height: calc(100% - 232px);
  }

  min-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
`;
