import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";

interface PanelProps {
  className?: string;
  backgroundColor?: string;
  fontColor?: string;
  fontWeight?: string;
}

const Panel: React.FC<PanelProps> = ({ className, children }) => (
  <div className={className + " panel"}>
    <ul>{children}</ul>
  </div>
);
// e30613
export default styled(Panel)`
  margin-bottom: 5px;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : Color.GREY_LIGHT4};
  color: ${({ fontColor }) => (fontColor ? fontColor : Color.GREY)};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "initial")};
  padding: 5px 5px 5px 10px;
`;
