import * as React from "react";
import { SimpleState } from "../../../types/default";
import AFOPriceInputCell from "./AFOPriceInputCell";
import AFOPriceSelectionCell from "./AFOPriceSelectionCell";
import {
  AdditionalSubProduct,
  TableCellType,
  TableDataObject,
} from "../../../types/table";
import AFOPricePercentInputCell from "./AFOPricePercentInputCell";
import AFOPriceDisplayCell from "./AFOPriceDisplayCell";

export interface AFOPriceCellProps {
  dataset: TableDataObject;
  dataId: number;
  openSelectionIdState: SimpleState<number>;
  showPercentValueIdState: SimpleState<number>;
  mainProductPrice: number;
  additionalDataObject?: AdditionalSubProduct;

  changePriceId: (newPriceId: number, objectId: number) => void;
  changePriceValue: (newValue: number, objectId: number) => void;
}

const AFOPriceCell: React.FC<AFOPriceCellProps> = ({
  dataset,
  dataId,
  openSelectionIdState,
  showPercentValueIdState,
  mainProductPrice,
  additionalDataObject,
  changePriceId,
  changePriceValue,
}) => {
  const [openSelectionId, setOpenSelectionId] = openSelectionIdState;
  const [showPercentValueId] = showPercentValueIdState;

  const count: number = dataset.counts[dataset.countId]
    ? Math.abs(dataset.counts[dataset.countId])
    : 0;
  const priceId =
    dataset.priceId === undefined ? dataset.labelId : dataset.priceId;
  const price: number =
    dataset.prices[priceId] !== undefined ? dataset.prices[priceId] : 0;

  switch (dataset.labelType) {
    case TableCellType.INPUT:
      if (dataId === showPercentValueId) {
        return (
          <AFOPricePercentInputCell
            value={price}
            unit={dataset.priceUnit}
            mainProductPrice={mainProductPrice}
            showPercentValueIdState={showPercentValueIdState}
            changeValue={(value) => {
              changePriceValue(value, dataId);
            }}
          />
        );
      }
      return (
        <AFOPriceInputCell
          value={price}
          dataId={dataId}
          unit={dataset.priceUnit}
          showPercentValueIdState={showPercentValueIdState}
          additionalDataObject={additionalDataObject}
          changeValue={(value) => {
            changePriceValue(value, dataId);
          }}
        />
      );

    case TableCellType.SELECTION:
      return (
        <AFOPriceSelectionCell
          value={price}
          dataId={dataId}
          dataSet={dataset}
          changePriceId={changePriceId}
          setOpenSelectionId={setOpenSelectionId}
          openSelectionId={openSelectionId}
          disabled={dataset.priceId === undefined}
        />
      );
    case TableCellType.EXTERNAL:
      return (
        <AFOPriceDisplayCell value={price * count} unit={dataset.priceUnit} />
      );
    default:
      return null;
  }
};

export default AFOPriceCell;
