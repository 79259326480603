import * as React from "react";

function SvgChatColor2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="chat_color2_svg__a8ca3c22-55a8-4161-8674-5dd9953f60b6"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".chat_color2_svg__st0{fill:none;stroke:#e60000;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <path
        id="chat_color2_svg___x38_b647ed5-50b9-46ea-aeca-c922f27b9e6b"
        className="chat_color2_svg__st0"
        d="M143.2 74.6l-2.2-.7c-6.1-1.6-12.5-2.2-18.8-1.8-6.4.4-12.6 1.8-18.5 4.1-5.4 2.1-10.4 5.2-14.8 9-4 3.5-7.3 7.8-9.7 12.6-2.2 4.6-3.2 9.7-3 14.8.2 5.1 1.8 10.1 4.5 14.4 2.8 4.5 6.5 8.3 10.9 11.3 4.8 3.3 10.1 5.7 15.7 7.1 6.1 1.6 12.5 2.2 18.8 1.8h.6c2.1 1.5 4.4 2.8 6.7 3.9 2.8 1.4 5.8 2.5 8.8 3.3 3.5.9 7.1 1.5 10.8 1.6 4.2.2 8.4-.2 12.5-1 0 0-13.1-7.6-13.5-15.5 3.1-1.8 6-4 8.6-6.4 2.5-2.3 4.7-4.9 6.5-7.8 1.8-2.8 3.1-5.7 4-8.9 2.3-8.1 1-16.8-3.4-24-1.8-2.9-4.1-5.6-6.6-7.9-5.6-4.3-11.5-7.6-17.9-9.9"
      />
      <path
        id="chat_color2_svg___x37_3e43e76-3723-4c82-a239-629e1c0146f9"
        className="chat_color2_svg__st0"
        d="M141.4 74.1s.3-1.4.4-1.9c1.6-9.4-4.8-18.3-4.8-18.3-3.6-5.7-8.3-10.6-13.9-14.4-6.1-4.1-12.9-7.2-20-9-7.9-2.1-16-2.8-24.1-2.3-8.1.5-16.1 2.2-23.7 5.2-6.9 2.7-13.4 6.6-19 11.4-5.2 4.4-9.4 9.9-12.4 16-2.9 5.9-4.2 12.4-3.9 18.9.2 4.1 1.1 8.2 2.6 12 1.5 3.8 3.6 7.4 6.2 10.6 2.7 3.4 5.8 6.3 9.3 8.9 3.5 2.6 10.9 5.8 15.2 7.7-5.6 13.1-18.6 20.8-18.6 20.8 5.3.4 10.7.2 16-.6 3.7-.6 7.4-1.6 11-2.9 0 0 4.4-1.6 17-8.5l1.6-.9"
      />
      <path fill="none" d="M0 0h192v192H0z" />
    </svg>
  );
}

export default SvgChatColor2;
