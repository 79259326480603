import React, { useState } from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import MultilineTextField from "../form/MultilineTextField";
import Panel from "./Panel";
import NoticePanelHideText from "./NoticePanelHideText";

interface NoticePanelProps {
  notices?: string;
  onChange: (newValue: string) => void;
}

const NoticePanel: React.FC<NoticePanelProps> = (props) => {
  const { notices = "", onChange } = props;
  const [isEditing, setIsEditing] = useState(false);

  return (
    <NoticePanelComponent
      backgroundColor={Color.GREY_LIGHT}
      fontColor={Color.WHITE}
    >
      <NoticePanelHideText isHide={!isEditing}>
        Datenschutzhinweis: Angaben, die Du hier machst, werden zusammen mit dem
        Angebot von Vodafone gespeichert. Bitte mach Angaben zu Deiner Person,
        wie z.B. Deinen Namen, daher nur, wenn dies erforderlich ist, z.B. weil
        absehbar ist, dass Kollegen von Dir Fragen zu diesem Angebot an Dich
        haben werden.
      </NoticePanelHideText>
      <MultilineTextField
        value={notices}
        onChange={onChange}
        placeholder={"Notizen"}
        setIsEditing={setIsEditing}
      />
    </NoticePanelComponent>
  );
};

const NoticePanelComponent = styled(Panel)`
  padding: 0 15px 15px 15px;
`;

export default NoticePanel;
