import React, { useState } from "react";
import styled from "styled-components";
import { SimpleState } from "../../../types/default";
import AFOCurrencyChanger from "./AFOCurrencyChanger";
import AFOPriceDisplayCell from "./AFOPriceDisplayCell";
import AFOInputCell from "./AFOInputCell";
import { REBATE_MAX_VALUE, REBATE_MIN_VALUE } from "../../../constants/default";
import { get2FixedNumber } from "../../../helper/default";

interface AFOPricePercentInputCellProps {
  className?: string;
  value: number;
  unit: string;
  changeValue: (newValue: number) => void;
  showPercentValueIdState: SimpleState<number>;
  mainProductPrice: number;
}

const AFOPricePercentInputCell: React.FC<AFOPricePercentInputCellProps> = ({
  className,
  value,
  unit,
  changeValue,
  showPercentValueIdState,
  mainProductPrice,
}) => {
  const [, setShowPercentValueId] = showPercentValueIdState;
  const [percent, setPercentValue] = useState(() => {
    return get2FixedNumber((value / mainProductPrice) * 100);
  });
  const setPercent = (newPercent: number) => {
    setPercentValue(get2FixedNumber(newPercent));
  };

  const changePercent = (newPercent: number) => {
    const newValue: number = (newPercent / 100) * mainProductPrice;
    if (newValue < REBATE_MIN_VALUE) {
      setPercent((REBATE_MIN_VALUE / mainProductPrice) * 100);
      changeValue(REBATE_MIN_VALUE);
      return;
    }
    if (newValue > REBATE_MAX_VALUE) {
      setPercent((REBATE_MIN_VALUE / mainProductPrice) * 100);
      changeValue(REBATE_MAX_VALUE);
      return;
    }
    setPercent(newPercent);
    changeValue(newValue);
  };
  return (
    <>
      <AFOInputCell
        extraClassName={className}
        changeValue={changePercent}
        realValue={percent}
        defaultValue={0}
        step={0.4}
        displayValueFormatter={(v, isEditing) => (isEditing ? v : v.toFixed(2))}
        noEditFeature
      >
        <AFOCurrencyChanger
          onClick={() => setShowPercentValueId(-1)}
          content={"%"}
        />
      </AFOInputCell>
      <AFOPriceDisplayCell value={value} unit={unit} />
    </>
  );
};

export default styled(AFOPricePercentInputCell)`
  .cell-input {
    .number-input {
      width: 70%;
      display: inline-block;
      vertical-align: middle;
      text-align: right;
    }
  }
`;
