import React from "react";
import BigIconTileButtonGrid from "../form/BigIconTileButtonGrid";
import useBootstrap from "../../hooks/useBootstrap";
import { useFormState } from "../../hooks/useForm";

const TVTechnologyPage = () => {
  const { formConfig } = useBootstrap();
  const [tvTechnology, setTvTechnology] = useFormState("tvTechnology");

  return (
    <BigIconTileButtonGrid
      data={formConfig.tvTechnology}
      values={tvTechnology}
      onChange={setTvTechnology}
    />
  );
};

export default TVTechnologyPage;
