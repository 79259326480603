import React, { useState } from "react";
import BigIconTileButtonGrid from "../form/BigIconTileButtonGrid";
import useBootstrap from "../../hooks/useBootstrap";
import { useFormState } from "../../hooks/useForm";
import useAvailability from "../../hooks/useAvailability";
import { useRefEffect } from "../../hooks/common/useRefHook";

const TVPreferencesPage = () => {
  const { formConfig } = useBootstrap();
  const { availability } = useAvailability();
  const [tvPreference, setTvPreference] = useFormState("tvPreference");
  const [preferencesData, setPreferencesData] = useState(
    formConfig.tvPreference
  );
  useRefEffect(() => {
    if (!availability.cable.available) {
      setPreferencesData(
        formConfig.tvPreference.filter(
          (v) => v.value !== 416 && v.value !== 417
        )
      );
    }
  }, [formConfig.tvPreference]);

  return (
    <BigIconTileButtonGrid
      data={preferencesData}
      values={tvPreference}
      onChange={setTvPreference}
    />
  );
};

export default TVPreferencesPage;
