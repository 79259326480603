import * as React from "react";

function SvgMusicColor2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="music_color2_svg__eaa9f239-8ce3-473d-ba0c-a33673c84794"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".music_color2_svg__st0{fill:none;stroke:#e60000;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <ellipse
        transform="rotate(-19.999 136.227 136.844)"
        className="music_color2_svg__st0"
        cx={136.2}
        cy={136.8}
        rx={29.1}
        ry={17.4}
      />
      <ellipse
        transform="rotate(-19.999 55.96 144.847)"
        className="music_color2_svg__st0"
        cx={56}
        cy={144.8}
        rx={29.1}
        ry={17.4}
      />
      <path className="music_color2_svg__st0" d="M84 137.5V48l80.1-28v112.1" />
      <path fill="none" d="M0 0h192v192H0z" />
    </svg>
  );
}

export default SvgMusicColor2;
