import * as React from "react";

function SvgTablet(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <path
        fill="none"
        stroke={color}
        strokeWidth={24.173}
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M288.3 364.9h-76.6"
      />
      <path
        d="M45.8 84.1h408.4c21.1 0 38.3 17.1 38.3 38.3v255.3c0 21.1-17.1 38.3-38.3 38.3H45.8c-21.1 0-38.3-17.1-38.3-38.3V122.4c0-21.2 17.1-38.3 38.3-38.3z"
        fill="none"
        stroke={color}
        strokeWidth={24.173}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgTablet;
