import gigaAdvantages from "../../data/gigaAdvantages.json";
import testAdvantageJson from "../dataTester/testAdvantageJson";
import { getDatabaseAdvantage } from "./getDatabaseAdvantage";
import { DatabaseAdvantage } from "../../data/definitions/DatabaseAdvantage";

export function getGigaAdvantages(): [DatabaseAdvantage[], boolean] {
  const gigaAdvantagesArray: any = JSON.parse(JSON.stringify(gigaAdvantages));

  const [checkedGigaAdvantages, result] = testAdvantageJson(
    gigaAdvantagesArray,
    "gigaAdvantages.json"
  );
  return [
    checkedGigaAdvantages.map((v) => getDatabaseAdvantage(v, false)),
    result,
  ];
}
