import { WithsObject } from "../../types/application";

export function getStandardGigaKombiCalculation(
  withsObject: WithsObject
): [number, boolean] {
  const { mobile, iot, fixed, tv } = withsObject;

  let discountPrice: number = 0;
  let withDataVolume: boolean = false;

  if (!(mobile && fixed && tv)) {
    if (fixed && mobile) {
      discountPrice = 10;
      withDataVolume = true;
    }
    if (fixed && tv) {
      discountPrice = 5;
    }
    if (mobile && tv) {
      discountPrice = 5;
    }
  }
  if (mobile && fixed && tv) {
    discountPrice = 15;
    withDataVolume = true;
  }
  return [discountPrice, withDataVolume];
}
