import React from "react";
import styled from "styled-components";
import PaginationButton from "../../pagination/PaginationButton";
import { Color } from "../../../constants/color";
import SVGIcon from "../../svgIcon/SVGIcon";
import Icons from "../../../icons/Icons";
import { CloseButton } from "../CloseButton";

export interface ContainerSelectorProps {
  className?: string;
  currentIndex: number;
  backgroundColor?: string;
  min?: number;
  max?: number;
  next: () => void;
  prev: () => void;
  reset?: () => void;
}

const isPrevDisabled = (value: number, min = 0): boolean => value <= min;
const isNextDisabled = (value: number, max?: number): boolean =>
  max ? value >= max : true;

const ContainerSelector: React.FC<ContainerSelectorProps> = ({
  className,
  children,
  currentIndex,
  max,
  min = 0,
  next,
  prev,
  reset,
}) => {
  return (
    <div className={className}>
      {!!reset && (
        <div className={"reset-container"}>
          <CloseButton
            value={currentIndex !== min}
            onChange={reset}
            disableOnFalseValue
          >
            <SVGIcon
              src={Icons.bigClose}
              size={10}
              noPadding
              style={{ margin: "5px 0" }}
            />
          </CloseButton>
        </div>
      )}
      <div className={"prev-container"}>
        <SelectorButton
          direction={"prev"}
          onClick={prev}
          disabled={isPrevDisabled(currentIndex, min)}
        />
      </div>
      <div className={"content-table-container"}>
        <div className={"content-container"}>{children}</div>
      </div>
      <div className={"next-container"}>
        <SelectorButton
          direction={"next"}
          onClick={next}
          disabled={isNextDisabled(currentIndex, max)}
        />
      </div>
    </div>
  );
};
const SelectorButton = styled(PaginationButton)`
  height: 100%;
  width: 100%;
  outline: none;
  vertical-align: top;
  background: transparent;
`;

export default styled(ContainerSelector)`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : Color.WHITE};
  min-width: 150px;
  max-width: 900px;
  @media (min-width: 1279px) {
    min-height: 80px;
  }
  @media (min-width: 1023px) and (max-width: 1279px) {
    min-height: 95px;
  }
  @media (max-width: 1023px) {
    min-height: 110px;
  }

  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .reset-container {
    height: 30%;
    width: 30px;
    margin-right: 10px;
    outline: none;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    text-align: right;

    .tile-button {
      @media (max-width: 1023px) {
        margin-top: 10px;
      }
      @media (min-width: 1023px) {
        margin-top: 0;
      }
    }
  }

  .prev-container {
    height: 70%;
    width: 30px;
    margin-right: 10px;
    outline: none;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;

    button {
      text-align: right;

      .left-triangle {
        display: inline-block;
        margin-bottom: 44px;
        border-color: transparent
          ${({ currentIndex, min }) =>
            isPrevDisabled(currentIndex, min) ? Color.GREY_LIGHT : Color.RED}
          transparent transparent;
      }
    }
  }

  .next-container {
    height: 70%;
    width: 30px;
    margin-left: 10px;
    outline: none;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;

    button {
      text-align: left;

      .right-triangle {
        display: inline-block;
        border-color: transparent transparent transparent
          ${({ currentIndex, max }) =>
            isNextDisabled(currentIndex, max) ? Color.GREY_LIGHT : Color.RED};
        margin-left: 0;
        margin-right: 10px;
        margin-bottom: 44px;
      }
    }
  }

  .content-table-container {
    display: inline-block;
    padding: 5px 0; // distance between valueField and the outer container
    vertical-align: top;

    width: calc(100% - 80px);
    height: 100%;

    .content-container {
      display: table-cell;
      width: 100%;
      height: 100%;
    }

    .container .column {
      width: 40%;
      background-color: #aaa;
      padding: 5px;
      border: 5px solid white;
      vertical-align: top;
      height: 100%;
    }
  }
`;
