import * as React from "react";
import { SVGComponent } from "../../types/default";

const SvgAccelerometerHalfGreyColor: SVGComponent = (props) => {
  const { color = "#4a4d4e" } = props;
  const { color2 = "#e60000" } = props;
  return (
    <svg
      id="accelerometerHalf_grey_color_svg__ICON"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.accelerometerHalf_grey_color_svg__st1{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        id="accelerometerHalf_grey_color_svg___x32_c33339c-e9ce-4d4a-9d19-d1ccd94ddb1b"
        d="M102.8 127.6c-4 4-10.4 4-14.4 0s-4-10.4 0-14.4 10.4-4 14.4 0h0l29.8-28.9"
        fill="none"
        stroke={color2}
        strokeWidth={8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="accelerometerHalf_grey_color_svg___x33_4ae2b9e-5a5d-4845-a6c5-cc21215f6a91"
        className="accelerometerHalf_grey_color_svg__st1"
        d="M96 66.4v8.9"
      />
      <path
        id="accelerometerHalf_grey_color_svg___x31_f67863d-ec1e-4010-8358-f4f79bf5a708"
        className="accelerometerHalf_grey_color_svg__st1"
        d="M42.3 120h9"
      />
      <path
        id="accelerometerHalf_grey_color_svg___x36_d0f923f-ec7f-4b2c-b556-567fb48475ce"
        className="accelerometerHalf_grey_color_svg__st1"
        d="M58 82l6.7 6.7"
      />
      <path
        id="accelerometerHalf_grey_color_svg___x34_c518e8f-8910-4b5b-8166-ed967e8ec8b5"
        className="accelerometerHalf_grey_color_svg__st1"
        d="M140.7 120h8.9"
      />
      <path
        className="accelerometerHalf_grey_color_svg__st1"
        d="M22.7 140c-1.7-6.4-2.7-13.1-2.7-20 0-42 34-76 76-76s76 34 76 76c0 6.9-.9 13.6-2.7 20"
      />
    </svg>
  );
};

export default SvgAccelerometerHalfGreyColor;
