import * as React from "react";

function SvgKid(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="kid_svg__Kids"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.kid_svg__st1,.kid_svg__st2{stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round}.kid_svg__st1{fill:none;stroke-miterlimit:10}.kid_svg__st2{fill: ${color} ;stroke-linejoin:round}`}
      </style>
      <path
        className="kid_svg__st1"
        d="M22.4 172.8l474.2-43.5M186.3 23.7s69.8 43.2 87.4 126.1"
      />
      <path
        id="kid_svg___x34_22dae04-4879-4453-8160-03a16fa68059_5_"
        className="kid_svg__st1"
        d="M227.5 20.7c176.1 0 215.9 131.9 215.9 248.2s-96.8 210.5-215.9 210.5S11.6 384.9 11.6 268.9c0-116.4 38.8-248.2 215.9-248.2z"
      />
      <circle className="kid_svg__st2" cx={294.2} cy={250} r={13.5} />
      <circle className="kid_svg__st2" cx={159.3} cy={250} r={13.5} />
      <path
        className="kid_svg__st1"
        d="M159.3 344.4s27 27 67.5 27 67.5-27 67.5-27"
      />
    </svg>
  );
}

export default SvgKid;
