import * as React from "react";

function SvgRecordings(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="recordings_svg___x30_2be3e63-f72b-49eb-8c36-6008ac7bbedf"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.recordings_svg__st1{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <circle
        cx={364.9}
        cy={224.5}
        r={51.1}
        fill="none"
        stroke={color}
        strokeWidth={24.173}
        strokeMiterlimit={10}
      />
      <path
        className="recordings_svg__st1"
        d="M84.1 178.9v90.7c-.1 4.2 3.2 7.7 7.4 8 1.8.1 3.5-.5 4.9-1.6l1.1-.8 58-43.2 1.7-1.3c3.5-2.2 4.6-6.9 2.3-10.4-.6-.9-1.4-1.7-2.3-2.3l-1.7-1.2-57.9-42.9c-3-3.2-7.9-3.4-11.1-.4-1.7 1.6-2.6 3.8-2.5 6.1v.6M211.7 173.4v102.1M262.8 173.4v102.1"
      />
      <path
        className="recordings_svg__st1"
        d="M33 58.6h434c14.1 0 25.5 11.4 25.5 25.5v280.8c0 14.1-11.4 25.5-25.5 25.5H33c-14.1 0-25.5-11.4-25.5-25.5V84.1C7.5 70 18.9 58.6 33 58.6zM135.1 441.4h229.8M250 403.2v31.9"
      />
    </svg>
  );
}

export default SvgRecordings;
