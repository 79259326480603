import React from "react";

export interface ExternalLinkProps {
  to: string;
  linkClass?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const ExternalLink: React.FC<ExternalLinkProps> = ({
  to,
  children,
  linkClass,
  onClick,
}) => {
  return (
    <a
      href={to}
      rel="noopener noreferrer"
      target="_blank"
      className={linkClass}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
