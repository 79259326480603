import * as React from "react";

function SvgRtl(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="rtl_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>{`.rtl_svg__st0{fill: ${color} }`}</style>
      <path
        className="rtl_svg__st0"
        d="M301.4 222.23H196.17v13.05h30.98l-.01 42.45h43.69l.02-42.45h30.56v-13.05zm28.08 70.22H169.8V207.5h159.68v84.95zM406.63 222.22h-46.8v55.52h106.9v-12.69h-60.1v-42.83zm85.47 70.23H332.09V207.5H492.1v84.95zM118.89 222.21h-85.5l-.04 55.52h40.88v-18.16l8.37-.02 21.17 18.2h42.78l-26.93-19.25 1.15-.1c8.68.21 17.01-7.5 17-17.4-.02-13.31-9.87-18.79-18.88-18.79m-25.87 12.76l-18.77.03-.03 12.38 19.25.01c3.21-.33 5.44-2.97 5.44-6.35-.01-2.9-2.18-6.07-5.89-6.07m73.95 57.48H7.1V207.5h159.86v84.95z"
      />
    </svg>
  );
}

export default SvgRtl;
