import * as React from "react";

function SvgGpsWave(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="gpsWave_svg___x39_5eedcaf-2c32-4946-a19a-d67860715508"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 485 485"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.gpsWave_svg__st2{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round}`}
      </style>
      <circle
        id="gpsWave_svg__f7206cce-6285-42c7-95e3-b82eddaa783a"
        cx={171.3}
        cy={174.3}
        r={82}
        fill="none"
        stroke={color}
        strokeWidth={24.173}
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <path
        id="gpsWave_svg___x38_3ce694e-bda3-4b95-936f-fd7c36f6eef3"
        d="M218.3 420.3c47.5-58.3 112.9-157.6 112.9-242.8 0-91-67.4-167.5-159.9-167.5S11.6 83.8 11.6 174.8c0 127 159.9 300.2 159.9 300.2s35.5-40.8 46.8-54.7z"
        fill="none"
        stroke={color}
        strokeWidth={24.173}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="gpsWave_svg___x30_6eef4fa-0bf5-431c-ac10-5542c58c7b9e"
        className="gpsWave_svg__st2"
        d="M456.7 116.2c22.8 35.4 22.8 80.9 0 116.2"
      />
      <path
        id="gpsWave_svg__b918e17e-2236-4685-b172-2e49427e6299"
        className="gpsWave_svg__st2"
        d="M395.9 133.2c18.2 24.3 18.2 57.8 0 82"
      />
    </svg>
  );
}

export default SvgGpsWave;
