import * as React from "react";

function SvgUnder28Grey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#4a4d4e" } = props;
  return (
    <svg
      id="under28_grey_svg__ICON"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.under28_grey_svg__st0{stroke-miterlimit:10}.under28_grey_svg__st0,.under28_grey_svg__st1{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <g id="under28_grey_svg__Mobile_x5F_ic_4_">
        <path
          className="under28_grey_svg__st0"
          d="M84 156h24M52 60V32c0-6.6 5.4-12 12-12h64c6.6 0 12 5.4 12 12v128c0 6.6-5.4 12-12 12H64c-6.6 0-12-5.4-12-12v-44"
        />
      </g>
      <path
        className="under28_grey_svg__st1"
        d="M52 76L30 88l22 12M92 108H68c0-3.8 1.7-7.5 4.6-10l14-11.9c2.4-1.7 3.8-4.5 3.9-7.4 0-5.6-5.2-10.7-11.7-10.7-4-.1-7.7 1.9-9.7 5.3"
      />
      <ellipse
        className="under28_grey_svg__st1"
        cx={114}
        cy={97.9}
        rx={10.5}
        ry={10.1}
      />
      <ellipse
        className="under28_grey_svg__st1"
        cx={114.3}
        cy={77.9}
        rx={9.7}
        ry={9.9}
      />
    </svg>
  );
}

export default SvgUnder28Grey;
