import React, { CSSProperties } from "react";
import styled from "styled-components";
import PaginationButton from "../pagination/PaginationButton";
import { Color } from "../../constants/color";
import SVGIcon from "../svgIcon/SVGIcon";
import GreyIcons from "../../icons/GreyIcons";
import ColorIcons from "../../icons/ColorIcons";

interface VerticalSelectorProps {
  className?: string;
  selected?: boolean;
  setValue: (newIndex: number) => void;
  value: number;
  extraValue?: number;
  max?: number;
  min?: number;
  steps?: number;
  currency?: string;
  style?: CSSProperties;
}

const VerticalSelector: React.FC<VerticalSelectorProps> = ({
  className,
  style,
  setValue,
  value,
  extraValue,
  max = Number.MAX_SAFE_INTEGER,
  min = 0,
  steps = 1,
  currency,
  selected,
}) => {
  const extraValueNumber = extraValue ? extraValue : 0;
  const isMaxValue = value + extraValueNumber >= max;
  const displayedValue = Math.min(value + extraValueNumber, max);

  const setPrevItem = () => {
    const newValue = value - steps <= min ? min : value - steps;
    setValue(newValue);
  };
  const setNextItem = () => {
    const newValue = value + steps >= max ? max : value + steps;
    setValue(newValue);
  };

  return (
    <div className={className} style={style}>
      <div className={"value-field-container"}>
        <div className={"value-field"}>
          <p>
            {isMaxValue ? (
              <SVGIcon
                size={25}
                src={selected ? ColorIcons.unlimited : GreyIcons.unlimited}
                style={{
                  padding: "0 0 6px 0",
                  verticalAlign: "middle",
                }}
              />
            ) : (
              displayedValue
            )}
            {currency && <>{" " + currency}</>}
          </p>
        </div>
      </div>
      {/*{!isMaxValue && (*/}
      <div className={"button-container"}>
        <div className={"rotate-container first-rotate-container"}>
          <SelectorButton direction={"next"} onClick={setNextItem} />
        </div>
        <div className={"rotate-container"}>
          <SelectorButton direction={"prev"} onClick={setPrevItem} />
        </div>
      </div>
      {/*)}*/}
    </div>
  );
};
const SelectorButton = styled(PaginationButton)`
  outline: none;
  transform: rotate(270deg);
  width: 25px; // it is now the height, because the rotating
  height: 25px; // it is now the width, because the rotating
  display: block;
  color: ${Color.GREY_LIGHT6};
  background: transparent;

  .right-triangle {
    border-width: 7.5px 0 7.5px 15px;
    border-color: transparent transparent transparent ${Color.GREY_LIGHT6};
    margin-left: 5px;
  }

  .left-triangle {
    border-width: 7.5px 15px 7.5px 0;
    border-color: transparent ${Color.GREY_LIGHT6} transparent transparent;
    margin-left: 5px;
  }
`;

export default styled(VerticalSelector)`
  width: 100px;
  height: 50px;
  line-height: 25px;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  .button-container {
    width: 20%;
    height: 100%;
    display: inline-block;

    .rotate-container {
      height: 50%;
      width: 100%;
      margin-left: -5px;
    }
  }

  .value-field-container {
    display: inline-block;
    width: 80%;
    height: 40px;
    padding: 5px 5px; // distance between valueField and the outer container
    margin: 5px 0;
    vertical-align: top;
    cursor: default;

    .value-field {
      vertical-align: top;
      width: 100%;
      display: inline-block;
      height: 100%;
      background: ${Color.WHITE};
      box-shadow: inset 3px 3px 15px -7px;
      position: relative;

      p {
        text-align: center;
        padding: 2.5px 0;
        margin: 0;
        display: inline-block;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: bold;
        color: ${({ selected }) => (selected ? Color.RED : Color.GREY)};
      }
    }
  }
`;
