import * as React from "react";

function SvgCalculatorColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="calculator_color_svg___x31_1724e0d-5a87-4ec5-bcbd-276184094963"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".calculator_color_svg__st1,.calculator_color_svg__st2{fill:none;stroke:#e60000;stroke-width:8;stroke-miterlimit:10}.calculator_color_svg__st2{stroke-linecap:round}"
        }
      </style>
      <g id="calculator_color_svg__fd4f2ae8-05f6-4365-b50b-edca05df2c62">
        <path fill="none" d="M0 0h192v192H0z" />
        <path
          className="calculator_color_svg__st1"
          d="M40 28h112c6.6 0 12 5.4 12 12v112c0 6.6-5.4 12-12 12H40c-6.6 0-12-5.4-12-12V40c0-6.6 5.4-12 12-12zM96 164V28M28.5 96H164"
        />
        <path
          className="calculator_color_svg__st2"
          d="M64 52v24M52 64h24M54 122l20 20M54.5 141.5L74 122M116 64h24M116 140h24M116 124h24"
        />
      </g>
    </svg>
  );
}

export default SvgCalculatorColor;
