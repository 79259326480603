import {
  FormStateBooleans,
  FormStateNumbers,
  FormStateStrings,
} from "../../state/definitions/FormState";
import { useFormState } from "../useForm";
import { SimpleState } from "../../types/default";
import { dateToString, stringToDate } from "../../helper/dateStringConversion";

export const useProviderQuestion = (
  providerKey: keyof FormStateNumbers,
  currentContractEndKey: keyof FormStateStrings,
  withoutCurrentContractKey: keyof FormStateBooleans
): [SimpleState<number>, SimpleState<Date>, SimpleState<boolean>] => {
  // Setup provider
  const [currentProviderId, setCurrentProviderId] = useFormState(providerKey);
  const providerIdState: SimpleState<number> = [
    currentProviderId ? currentProviderId : -1,
    setCurrentProviderId,
  ];

  // Setup date
  const [currentContractEndString, setCurrentContractEndString] = useFormState(
    currentContractEndKey
  );
  const currentEnd: Date = stringToDate(
    currentContractEndString ? currentContractEndString : ""
  );
  const changeDate = (newDate: Date) => {
    setCurrentContractEndString(dateToString(newDate));
  };
  const currentContractEndDateState: SimpleState<Date> = [
    currentEnd,
    changeDate,
  ];

  // Setup without date
  const [withoutCurrentContractEnd = false, setWithoutCurrentContractEnd] =
    useFormState(withoutCurrentContractKey);
  const withoutCurrentContractEndState: SimpleState<boolean> = [
    withoutCurrentContractEnd,
    setWithoutCurrentContractEnd,
  ];

  return [
    providerIdState,
    currentContractEndDateState,
    withoutCurrentContractEndState,
  ];
};
