import { UnknownTableDataObject } from "../../types/table";

export function isSameAdditionalTableObject(
  dataObject: UnknownTableDataObject,
  dataObject2: UnknownTableDataObject
): boolean {
  if (dataObject.labelId !== dataObject2.labelId) {
    return false;
  }
  if (dataObject.startId !== dataObject2.startId) {
    return false;
  }
  if (dataObject.countId !== dataObject2.countId) {
    return false;
  }
  if (dataObject.priceId !== dataObject2.priceId) {
    return false;
  }
  if (dataObject.labels[0] !== dataObject2.labels[0]) {
    return false;
  }

  const starts1 = dataObject.starts ?? [];
  const starts2 = dataObject2.starts ?? [];

  if (starts1[0] !== starts2[0]) {
    return false;
  }
  if (dataObject.counts[0] !== dataObject2.counts[0]) {
    return false;
  }

  return dataObject.prices[0] === dataObject2.prices[0];
}
