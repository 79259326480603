import * as React from "react";
import JustifiedColumnsContainer from "../../grid/JustifiedColumnsContainer";
import GridColumn from "../../grid/GridColumn";
import { IconTextDisplayProps } from "./IconTextDisplay";
import PrintIconTextDisplay from "./PrintIconTextDisplay";

export interface Props {
  extraClassName?: string;
  colCount?: number;
  items?: IconTextDisplayProps[];
}

const PrintIconTextDisplayContainer: React.FC<Props> = ({
  extraClassName,
  colCount = 3,
  items = [],
  children,
}) => {
  return (
    <JustifiedColumnsContainer
      extraClassName={extraClassName}
      alignItems={"center"}
      height={"100%"}
    >
      {children}
      {items.map((itemProps, index) => {
        return (
          <GridColumn
            key={index + itemProps.label}
            colCount={colCount}
            minWidth={"50px"}
          >
            <PrintIconTextDisplay {...itemProps} />
          </GridColumn>
        );
      })}
    </JustifiedColumnsContainer>
  );
};

export default PrintIconTextDisplayContainer;
