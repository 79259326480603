import * as React from "react";

function SvgIotLabelSim(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192" {...props}>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        d="M160 140H32c-6.5.1-11.9-5-12-11.5V78.7l28-26.9h112c6.5-.1 11.9 5 12 11.5v65.1c-.1 6.6-5.5 11.7-12 11.6 0 0 0 0 0 0z"
      />
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        d="M60 68v56m72.2-54.9V124m-29.6 0H90.5c-8.9 0-9.5-4.9-9.5-10.9V92.5c0-6 2.4-8.5 8.6-8.5h13c6.1 0 8.4 2.5 8.4 8.5v23.3c0 6-5 8.2-8.4 8.2zm11.9-56.3H150"
      />
    </svg>
  );
}

export default SvgIotLabelSim;
