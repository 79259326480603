import React from "react";
import Slider from "../form/Slider";
import ProviderQuestionBlock from "../form/ProviderQuestionBlock";
import useBootstrap from "../../hooks/useBootstrap";
import { useFormState } from "../../hooks/useForm";
import { useProviderQuestion } from "../../hooks/useFormHelper/useProviderQuestion";
import IButton from "../form/IconButton";
import { useNavigation } from "../../hooks/useNavigation";

const FixedCurrentConditionsPage = () => {
  const { formConfig } = useBootstrap();

  // Setup slider values
  const [fixedCurrentPrice, setFixedCurrentPrice] =
    useFormState("fixedCurrentPrice");
  const fixedCurrentPriceValue = fixedCurrentPrice ? fixedCurrentPrice : 0;
  const bandwidthPossibleValues = [6, 16, 50, 100, 250, 500, 1000, 1500];

  const [fixedCurrentBandwidth, setFixedCurrentBandwidth] = useFormState(
    "fixedCurrentBandwidth"
  );
  const fixedCurrentBandwidthValue = fixedCurrentBandwidth
    ? fixedCurrentBandwidth
    : 0;

  const setFixedCurrentBandwidthValue = (newIndex: number) => {
    const newValue = bandwidthPossibleValues[newIndex]
      ? bandwidthPossibleValues[newIndex]
      : 0;
    setFixedCurrentBandwidth(newValue);
  };

  // Setup providerQuestionState
  const [
    providerIdState,
    currentContractEndDateState,
    withoutCurrentContractEndState,
  ] = useProviderQuestion(
    "providerFixedId",
    "fixedContractCurrentEnd",
    "fixedWithoutCurrentContractEnd"
  );

  const { setIsChangeGuaranteeSlideShowing } = useNavigation();

  const openGuaranteePage = () => {
    setIsChangeGuaranteeSlideShowing(true);
  };

  return (
    <>
      <Slider
        min={0}
        max={150}
        value={fixedCurrentPriceValue}
        onChange={setFixedCurrentPrice}
        afterValue={"€"}
        description={"Preis"}
        padding={"50px 0"}
      />

      <Slider
        value={bandwidthPossibleValues.indexOf(fixedCurrentBandwidthValue)}
        onChange={setFixedCurrentBandwidthValue}
        afterValue={"Mbit/s"}
        description={"Bandbreite"}
        list={bandwidthPossibleValues}
        padding={"50px 0"}
      />
      <ProviderQuestionBlock
        contractEndDateState={currentContractEndDateState}
        withoutCurrentContractEndState={withoutCurrentContractEndState}
        selectedProviderState={providerIdState}
        providers={formConfig.fixedProviders}
      >
        <IButton onChange={openGuaranteePage} value={true} />
      </ProviderQuestionBlock>
    </>
  );
};

export default FixedCurrentConditionsPage;
