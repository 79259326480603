import React from "react";
import styled from "styled-components";
import { Color } from "../../../../constants/color";

interface GigaTextProps {
  className?: string;
  text?: string;
  invertColors?: boolean;
  width?: string;
  top?: string;
  left?: string;
}

const GigaText: React.FC<GigaTextProps> = ({ className, text }) => {
  return <span className={className + " giga-text"}>{text}</span>;
};

export default styled(GigaText)`
  position: absolute;
  height: 100%;
  width: ${({ width }) => (!!width ? width : "71%")};
  top: ${({ top }) => (!!top ? top : "20px")};
  left: ${({ left }) => (!!left ? left : "14px")};

  font-size: 14px;
  text-align: center;
  color: ${({ invertColors }) => (!!invertColors ? Color.WHITE : Color.RED)};
`;
