import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import { useHistory } from "react-router-dom";
import DisableShadowBox from "../elements/DisableShadowBox";
import { SVGComponent } from "../../types/default";
import SVGHookCircle from "../../icons/svgComponents_color/HookCircle";

interface NavImageItemProps {
  to: string;
  className?: string;
  active?: boolean;
  icon: SVGComponent | SVGComponent[];
  disabled?: boolean;
  showDisabled?: boolean;
  finished?: boolean;
  fct?: () => boolean;
}

const NavItem: React.FC<NavImageItemProps> = ({
  className,
  to,
  icon: Icon,
  disabled = false,
  showDisabled = false,
  finished = false,
  fct,
}) => {
  const history = useHistory();

  const handleClick = () => {
    if (!disabled) {
      let shouldChangeRoute = true;
      if (fct) {
        shouldChangeRoute = fct();
      }
      if (shouldChangeRoute) {
        history.push(to);
      }
    }
  };

  return (
    <li className={className} onClick={handleClick}>
      {Array.isArray(Icon) ? (
        Icon.map((OneIcon, i) => (
          <OneIcon
            key={i}
            style={{ top: `${i * 100 + 50}%` }}
            className={`nav-item-icon image-n-${i}`}
          />
        ))
      ) : (
        <Icon />
      )}
      {finished && <SVGHookCircle />}
      <DisableShadowBox disabled={disabled} showDisabled={showDisabled} />
    </li>
  );
};

export default styled(NavItem)`
  background-color: ${(props) => (props.active ? Color.RED : Color.GREY2)};
  text-align: center;
  max-width: 66px;
  list-style: none;
  min-height: 80px;
  border-bottom: 3px solid ${Color.WHITE};
  cursor: pointer;
  position: relative;

  img,
  svg {
    padding: 12px 0;
    /* position: absolute; */
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    max-width: 75%;
    max-height: 160px;
  }

  #hook_circle_svg {
    position: absolute;
    width: 20px;
    padding: unset;
    top: unset;
    left: unset;
    bottom: 10px;
    right: 10px;
  }
`;
