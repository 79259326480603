import * as React from "react";

function SvgNetflix(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <path
        d="M492.1 184.43l-25.98 62.34 25.98 68.74c-7.68-1.02-15.36-2.43-23.04-3.71l-14.72-37.89-14.97 34.81c-7.43-1.28-14.6-1.66-22.02-2.69L443.72 246l-23.81-61.57h22.02l13.44 34.43 14.34-34.43h22.39zM382.28 303.48c6.53.38 13.44.77 20.1 1.54V184.43h-20.1v119.05zm-52.1-23.43c11.65.26 23.42 1.15 34.81 1.79v20.22c-18.3-1.15-36.61-2.3-55.3-2.69V184.43h20.48v95.62zm-70.4-49.03h28.03v20.48h-28.03v46.47h-20.1V184.43h57.22v20.48h-37.12v26.11zm-33.28-26.11h-21.25v94.21c-6.91 0-13.82 0-20.48.25v-94.46h-21.25v-20.48h62.98v20.48zm-112.39 27.53c7.94 0 20.1-.39 27.39-.39v20.48c-9.09 0-19.71 0-27.39.39v30.46c12.03-.77 24.07-1.79 36.23-2.18v19.71l-56.71 4.48V184.43h56.71v20.48h-36.23v27.53zm-40.32 74.62c-7.29 1.28-14.72 1.66-22.4 2.69l-23.43-68.61v71.55c-7.29.77-13.95 1.79-20.86 2.81V184.43h19.46l26.62 74.37v-74.37h20.61v122.63z"
        fill={color}
      />
    </svg>
  );
}

export default SvgNetflix;
