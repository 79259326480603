import * as React from "react";

function SvgGigaTvMobile(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="gigaTVMobile_svg__Kids"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.gigaTVMobile_svg__st0{fill: ${color} }.gigaTVMobile_svg__st1{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className="gigaTVMobile_svg__st0"
        d="M41.9 168c-2 0-3.9-.4-5.6-1.1-1.7-.7-3.1-1.7-4.4-3.1s-2.2-3-2.8-5c-.7-2-1-4.2-1-6.7 0-2.8.4-5.1 1.1-7.2.7-2 1.7-3.7 2.9-5 1.2-1.3 2.6-2.3 4.2-3 1.6-.6 3.2-1 5-1 2.7 0 4.9.5 6.6 1.5 1.7 1 3 2.3 3.8 3.8l-4.1 3.7c-.6-1-1.4-1.9-2.5-2.6-1.1-.7-2.3-1.1-3.8-1.1-1.6 0-3 .6-4.2 1.8-1.2 1.2-2 2.6-2.4 4.3-.2.9-.4 1.7-.4 2.4-.1.8-.1 1.5-.1 2.1 0 2.3.4 4.4 1.3 6.1.5 1 1 1.8 1.6 2.3.6.6 1.2 1 1.9 1.3.6.3 1.2.5 1.8.6.6.1 1.1.1 1.5.1 1.2 0 2.2-.1 3-.2.8-.1 1.2-.2 1.3-.3v-5.5h-4.8v-5.3h10.4V166c-.8.5-2.1 1-3.8 1.4-1.8.4-4 .6-6.5.6zM60.8 142.8c-1.2 0-2.1-.4-2.7-1.1-.6-.7-.9-1.5-.9-2.3 0-.8.3-1.6.9-2.3.6-.7 1.5-1.1 2.7-1.1 1.2 0 2.1.4 2.7 1.1.6.7.9 1.5.9 2.3 0 .9-.3 1.6-.9 2.3-.6.7-1.5 1.1-2.7 1.1zm-3 25.2v-23h5.9v23h-5.9zM78.4 177.4c-1 0-2-.1-2.9-.2-.9-.1-1.9-.3-2.7-.5-1.7-.4-2.8-.8-3.3-1.1l1.2-5.2c.8.4 1.8.9 3.2 1.2 1.3.4 2.7.6 4.2.6 1.7 0 3-.3 3.8-1 .8-.6 1.2-1.5 1.2-2.5v-1.3c-1.1.3-2.2.4-3.2.4-1.9 0-3.5-.3-4.9-.9-1.4-.6-2.6-1.5-3.6-2.5-1-1.1-1.7-2.3-2.2-3.8-.5-1.4-.7-3-.7-4.6 0-2.5.6-4.7 1.7-6.6 1.2-1.9 2.7-3.3 4.6-4 1.9-.8 3.9-1.2 6-1.2 1.7 0 3.2.1 4.7.3 1.5.2 2.7.3 3.5.5v22.3c0 3.3-.9 5.8-2.7 7.5-1.9 1.8-4.5 2.6-7.9 2.6zm2.1-14.6c.6 0 1-.1 1.4-.2.4-.1.7-.3 1-.4v-12.7c-.8-.1-1.6-.2-2.2-.2-1.9 0-3.4.5-4.4 1.4-.7.6-1.2 1.3-1.6 2.1-.4.8-.6 1.4-.6 2v1.3c0 2 .5 3.6 1.6 4.9 1 1.2 2.6 1.8 4.8 1.8zM100.9 168.5c-1 0-1.9-.2-2.8-.5-.9-.3-1.7-.8-2.4-1.4-.7-.6-1.3-1.4-1.7-2.3s-.6-1.9-.6-3.1c0-1.2.3-2.3.8-3.2.5-.9 1.2-1.7 2.1-2.3.8-.6 1.8-1.1 2.7-1.4 1-.3 1.9-.6 2.7-.6.4-.1 2-.3 4.8-.6v-.3c0-1.3-.3-2.2-1-2.7s-1.6-.8-2.7-.8-2.2.2-3.3.7a10 10 0 00-2.4 1.5l-2-4.2c.7-.7 1.9-1.3 3.7-1.9 1.9-.6 3.6-.9 4.9-.9 1.5 0 2.7.2 3.8.7 1.1.4 2 1.1 2.7 1.9s1.2 1.8 1.6 2.8c.3 1.1.5 2.2.5 3.4V168h-5.4l-.2-1.7c-.6.7-1.4 1.2-2.4 1.6-1.1.4-2.2.6-3.4.6zm1.4-4.9c.8 0 1.5-.2 2.2-.5.7-.3 1.3-.7 1.8-1.2v-4.7c-1.9.2-3.2.4-3.9.5-1.3.3-2.2.7-2.7 1.3-.5.6-.7 1.3-.7 2 0 .8.3 1.4.8 1.8.7.5 1.5.8 2.5.8zM122 168v-26.1h-9v-5.8h24.2v5.8h-9V168H122zM151.1 168l-11.8-31.9h6.5l8.1 24.1 7.9-24.1h6L156.7 168h-5.6z"
      />
      <path
        className="gigaTVMobile_svg__st1"
        d="M129 104h10M118 44h32c3.3 0 6 2.5 6 5.5v60.9c0 3.1-2.7 5.5-6 5.5h-32c-3.3 0-6-2.5-6-5.5V49.5c0-3 2.7-5.5 6-5.5z"
      />
      <path
        id="gigaTVMobile_svg__b493255f-edd2-469b-8f82-6d8f944da046_1_"
        fill="none"
        stroke={color}
        strokeWidth={8}
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M100 88H86"
      />
      <path
        className="gigaTVMobile_svg__st1"
        d="M112 100.1H45.1c-5 0-9-4.2-9-9.5V29.4c0-5.2 4-9.5 9-9.5H139c5 0 9 4.2 9 9.5V44"
      />
    </svg>
  );
}

export default SvgGigaTvMobile;
