import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";

interface ContainerSelectorItemProps {
  className?: string;
  titleElement?: JSX.Element;
  bodyElement?: JSX.Element;
}

const ContainerSelectorItem: React.FC<ContainerSelectorItemProps> = ({
  className,
  titleElement,
  bodyElement,
}) => {
  return (
    <div className={className}>
      <div className={"table-container"}>
        <div className={"table-cell"}>
          {titleElement}
          {bodyElement}
        </div>
      </div>
    </div>
  );
};

export default styled(ContainerSelectorItem)`
  text-align: left;
  display: inline-block;
  height: 100%;

  .table-container {
    display: table;
    height: 100%;

    .table-cell {
      display: table-cell;
      vertical-align: middle;

      h3 {
        color: ${Color.RED};
      }
      p {
        display: inline-block;
      }
      span {
        display: inline-block;
      }
    }
  }
`;
