import { InterfaceChangedPropTypes } from "../types/util";
import { FixedFormStateDeviceCounts } from "../state/definitions/FormState";
import { FormConfigLabelIds } from "../data/definitions/FormConfigLabelIds";

export const MAX_DEVICE_BANDWIDTH = 1000;
export const MAX_USEFUL_DEVICE_COUNT = 5;
export const BandwidthDeviceMap: InterfaceChangedPropTypes<
  FixedFormStateDeviceCounts,
  number
> = {
  fixedDevicesLaptop: 100,
  fixedDevicesSmartphone: 75,
  fixedDevicesTablet: 75,
  fixedDevicesPC: 100,
  fixedDevicesPhone: 50,
  fixedDevicesTV: 100,
  fixedDevicesSmartHome: 100,
  fixedDevicesConsole: 150,
  fixedDevicesSmartwatch: 50,
  fixedDevicesIOT: 50,
};

export const FormConfigLabelIdsDeviceMap: InterfaceChangedPropTypes<
  FixedFormStateDeviceCounts,
  FormConfigLabelIds
> = {
  fixedDevicesLaptop: FormConfigLabelIds.FIXED_DEVICES_LAPTOP,
  fixedDevicesSmartphone: FormConfigLabelIds.FIXED_DEVICES_SMARTPHONE,
  fixedDevicesTablet: FormConfigLabelIds.FIXED_DEVICES_TABLET,
  fixedDevicesPC: FormConfigLabelIds.FIXED_DEVICES_PC,
  fixedDevicesPhone: FormConfigLabelIds.FIXED_DEVICES_PHONE,
  fixedDevicesTV: FormConfigLabelIds.FIXED_DEVICES_TV,
  fixedDevicesSmartHome: FormConfigLabelIds.FIXED_DEVICES_SMARTHOME,
  fixedDevicesConsole: FormConfigLabelIds.FIXED_DEVICES_CONSOLE,
  fixedDevicesSmartwatch: FormConfigLabelIds.FIXED_DEVICES_SMARTWATCH,
  fixedDevicesIOT: FormConfigLabelIds.FIXED_DEVICES_IOT,
};

export const FixedDeviceKeys = Object.keys(BandwidthDeviceMap) as Array<
  keyof FixedFormStateDeviceCounts
>;
