import React from "react";
import styled from "styled-components";
import logo from "../../images/icon.png";
import { StyledComponentProps } from "../../types/default";

const PulsingLogo: React.FC<StyledComponentProps> = ({ className }) => {
  return (
    <div className={className}>
      <div className={"vodafone-logo"}>
        <img src={logo} alt="" />
        <p className={"cta"}>Start</p>
      </div>
    </div>
  );
};

export default styled(PulsingLogo)`
  width: 100%;
  text-align: center;
  .vodafone-logo {
    position: absolute;
    left: 50%;
    margin-left: -128px;
    bottom: 25%;
    animation: App-logo-pulse infinite 2s ease;
  }

  .cta {
    color: white;
    position: relative;
    top: -150px;
    font-size: 32px;
  }

  @keyframes App-logo-pulse {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.2);
    }
  }
`;
