export enum ErrorCodes {
  falseServerRecommendation = "DK64B3K1",
  falseServerAvailability = "DK64B3K2",
  falseLocalFormConfig = "DK64B3K3",
  falseServerOffer = "DK64B3K4",
  falseServerUpdateCheck = "DK64B3K5",
  notReadyForRecommendation = "IJ34D8N1",
  print = "OD92L5I1", // Error message is sent to server
  default = "XXXXXXXX",
  nothing = "",
}
