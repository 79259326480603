import React from "react";
import styled from "styled-components";

export interface PrintPanelGroupTitleProps {
  className?: string;
  value: string;
  subValue?: string;
  redBackground?: boolean;
}

const PrintPanelGroupTitle: React.FC<PrintPanelGroupTitleProps> = ({
  className,
  value,
  subValue,
}) => (
  <div className={className}>
    {subValue && <h5 className={"panel-sub-title"}>{subValue}</h5>}
    <h4 className={"panel-title"}>{value}</h4>
  </div>
);

export default styled(PrintPanelGroupTitle)`
  font-weight: bold;
  padding: 2px 5px;
  margin-bottom: 2px;

  .panel-sub-title {
    font-size: 14px;
  }

  .panel-title {
    font-size: 16px;
  }
`;
