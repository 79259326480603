import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";

interface PrintPanelProps {
  className?: string;
  backgroundColor?: string;
  fontColor?: string;
  fontWeight?: string;
  withShadow?: boolean;
}

const PrintPanel: React.FC<PrintPanelProps> = ({ className, children }) => (
  <div className={className}>
    <ul>{children}</ul>
  </div>
);
// e30613
export default styled(PrintPanel)`
  padding: 2px 0;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : Color.GREY_LIGHT4};
  color: ${({ fontColor }) => (fontColor ? fontColor : Color.GREY)};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "initial")};
  box-shadow: ${({ withShadow }) =>
    withShadow ? "inset 5px 4px 20px -4px" : "none"};
  ul {
    li {
      line-height: 12px;
      margin-bottom: 0;
      img,
      svg {
        max-width: 15px;
        height: 15px;
      }
      span {
        font-size: 12px !important;
      }
      .red-panel-value {
        color: ${Color.RED};
      }
    }
  }
`;
