import { AppState } from "../../state/definitions/AppState";
import { DisplayErrorMessages } from "../../constants/DisplayErrorMessages";
import { ErrorCodes } from "../../constants/ErrorCodes";

export function getErrorStateForRecommendationState(errorCode: ErrorCodes) {
  return (prev: AppState): AppState => {
    return {
      ...prev,
      recommendation: {
        ...prev.recommendation,
        loading: false,
        errorMessage: DisplayErrorMessages.technicalProblemInternal,
        errorCode: errorCode,
      },
    };
  };
}
