import * as React from "react";
import { v4 as uuid } from "uuid";

function SvgEdit(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  const rndClsId = uuid();

  return (
    <svg
      id="edit_svg___x36_a8da56c-64cb-4c0d-b7e3-2b80c0db2d12"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.edit_svg__st0${rndClsId}{
        fill:none;
        stroke: ${color} !important ;
        }`}
      </style>
      <path
        className={`edit_svg__st0${rndClsId}`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8"
        d="M147.38 70.11l-25.81-26.09-85.08 85.08-8.72 34.9 34.9-8.73 84.71-85.16"
      />
      <path
        className={`edit_svg__st0${rndClsId}`}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="8"
        d="M121.57 44l12.79-12.79a11 11 0 0 1 15.63 0l18 18.22-20.61 20.68"
      />
      <path
        className={`edit_svg__st0${rndClsId}`}
        strokeMiterlimit="10"
        strokeWidth="8"
        d="M39.55 126.1l26.18 26.18"
      />
    </svg>
  );
}

export default SvgEdit;
