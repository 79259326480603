import { DatabaseProduct } from "../../data/definitions/DatabaseProduct";
import { DatabaseCategoryTypes } from "../../data/definitions/DatabaseCategoryTypes";

export function getProductBasicPrice(
  product: DatabaseProduct,
  isAnyProductLineCurrent?: boolean
): number {
  if (
    product.productCategory === DatabaseCategoryTypes.tariff &&
    (isAnyProductLineCurrent === false || isAnyProductLineCurrent === undefined)
  ) {
    return product.ncRebateDuration !== 0
      ? product.productBasicPrice - product.ncRebate
      : product.productBasicPrice;
  }
  return product.productBasicPrice;
}
