import React from "react";
import styled from "styled-components";
import useProductPrice from "../../../hooks/helper/useProductPrice";
import { MonthlyPriceArray } from "../../../types/application";
import PriceDisplay from "./PriceDisplay";
import "../../grid/JustifiedColumnsContainer";
import JustifiedColumnsContainer from "../../grid/JustifiedColumnsContainer";
import "../../grid/GridColumn";
import GridColumn from "../../grid/GridColumn";

interface PriceDisplayListProps {
  className?: string;
  data: MonthlyPriceArray;
  discountData?: MonthlyPriceArray;
}

const PriceDisplayList: React.FC<PriceDisplayListProps> = ({
  className,
  data,
  discountData,
}) => {
  const [productPriceData] = useProductPrice(data);
  const [discountProductPriceData] = useProductPrice(discountData);
  return (
    <div className={className}>
      <JustifiedColumnsContainer>
        <GridColumn colCount={!!discountData ? 2 : 1} maxWidth={"40%"}>
          <PriceDisplay
            priceData={productPriceData}
            useDisabledStyle={!!discountData}
          />
        </GridColumn>
        {!!discountData && (
          <GridColumn colCount={2} maxWidth={"40%"}>
            <PriceDisplay
              priceData={Object.assign({}, discountProductPriceData, {})}
            />
          </GridColumn>
        )}
      </JustifiedColumnsContainer>
    </div>
  );
};

export default styled(PriceDisplayList)``;
