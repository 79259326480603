import styled from "styled-components";
import React from "react";
import TileButton, { TileButtonProps } from "../../form/TileButton";
import { Color } from "../../../constants/color";

export interface SearchButtonTileProps extends TileButtonProps {
  onClick?: () => void;
  disabled?: boolean;
}

const SearchButton: React.FC<SearchButtonTileProps> = ({
  className,
  onClick,
  disabled,
  ...props
}) => {
  return (
    <div className={className}>
      <TileButton
        value={false}
        onChange={() => {
          if (onClick && !disabled) {
            onClick();
          }
        }}
        {...props}
      />
    </div>
  );
};
export default styled(SearchButton)`
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 100%;
  margin-left: 2px;
  .tile-button {
    min-height: 70px;
    box-shadow: 3px 3px 5px 0 ${Color.GREY4};
    margin-top: unset;

    display: flex;
    justify-content: center;
    flex-direction: column;

    background-color: ${(props) => (props.disabled ? Color.GREY5 : Color.RED)};
  }
`;
