import React, { useMemo } from "react";
import styled from "styled-components";
import { Color } from "../../../../constants/color";
import PanelGroup from "../../../panel/PanelGroup";
import PanelGroupPrintTitle from "../../../panel/PanelGroupPrintTitle";
import PanelPrintItem from "../../../panel/PanelPrintItem";
import PanelPrintItemLabel from "../../../panel/PanelPrintItemLabel";
import PanelPrintItemValue from "../../../panel/PanelPrintItemValue";
import PanelPrint from "../../../panel/PanelPrint";
import { PassData, PriceUnits } from "../../../../types/table";

interface MobilePassPanelProps {
  className?: string;
  data: PassData[];
  mainLabel: string;
  mainValue: number;
}

const MobilePassPanel: React.FC<MobilePassPanelProps> = ({
  className,
  data,
  mainLabel,
  mainValue,
}) => {
  const filteredData = useMemo(() => {
    return data.filter((v) => v.selectedPosition >= 0);
  }, [data]);

  return (
    <div className={className}>
      <PanelGroup
        margin={"0"}
        borderColor={Color.GREY_LIGHT2}
        title={
          <PanelGroupPrintTitle
            label={mainLabel}
            value={mainValue.toFixed(2) + " " + PriceUnits.months}
          />
        }
      >
        <PanelPrint backgroundColor={Color.WHITE} fontColor={Color.BLACK}>
          {filteredData.map((d, dataId) => {
            let label: string = "Kein Pass";
            let price: string = "0 " + PriceUnits.months;
            if (d.selectedPosition >= 0) {
              label =
                d.name[0].toUpperCase() +
                d.name.substring(1).toLowerCase() +
                " Pass";
              if (d.price > 0) {
                price = d.price.toFixed(0) + " " + PriceUnits.months;
              } else {
                price = "kostenlos";
              }
            }

            return (
              <PanelPrintItem key={dataId}>
                <PanelPrintItemLabel>{label}</PanelPrintItemLabel>
                <PanelPrintItemValue>{price}</PanelPrintItemValue>
              </PanelPrintItem>
            );
          })}
        </PanelPrint>
      </PanelGroup>
    </div>
  );
};

export default styled(MobilePassPanel)`
  width: 100%;
  color: ${Color.BLACK};
  margin: 0 auto;
`;
