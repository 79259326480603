import { FormConfigState } from "../../state/definitions/FormConfigState";
import { testObjectIsFromClass } from "./default/testObjectIsFromClass";

export default (unsureFormConfig: any): [FormConfigState, boolean] => {
  let result = true;
  const testResult = testObjectIsFromClass(
    unsureFormConfig,
    FormConfigState,
    true,
    (propName, propValue) => {
      if (propName === "independentPersonalDetails") return true;
      return (
        propValue !== undefined &&
        propValue.length !== undefined &&
        propValue.length > 0
      );
    }
  );
  if (testResult !== "") {
    console.error("File formConfig has a false structure:", testResult);
    result = false;
  }
  return [unsureFormConfig as FormConfigState, result];
};
