import * as React from "react";
import {
  LabelValuePair,
  MonthlyPriceArray,
} from "../../../../types/application";
import { StyledComponentProps } from "../../../../types/default";
import styled from "styled-components";
import { Color } from "../../../../constants/color";
import getMonthlyPriceArrayStringified from "../../../../helper/getMonthlyPriceArrayStringified";

export interface Props extends StyledComponentProps {
  isOpen: boolean;
  monthlyPriceArray: MonthlyPriceArray;
}

const OfferEntryPriceSummary = ({
  className,
  isOpen,
  monthlyPriceArray,
}: Props) => {
  const items: LabelValuePair[] =
    getMonthlyPriceArrayStringified(monthlyPriceArray);
  return (
    <div className={className}>
      <div
        className={
          "offer-entry-sub-container" + (isOpen ? " show-sub-container" : "")
        }
      >
        <span className={"offer-entry-sub-monthly-price"}>
          {items.map((v) => (
            <div
              key={v.label}
              className={"offer-entry-sub-monthly-price-inner-container"}
            >
              <span className={"offer-entry-sub-monthly-price-label"}>
                {v.label}:
              </span>
              <span className={"offer-entry-sub-monthly-price-value"}>
                {v.value}
              </span>
            </div>
          ))}
        </span>
      </div>
    </div>
  );
};

export default styled(OfferEntryPriceSummary)`
  background: ${({ isOpen }) =>
    isOpen ? Color.GREY_LIGHT : Color.GREY_LIGHT3};
  color: ${Color.WHITE};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: background 0.3s, opacity 0.5s;

  .offer-entry-sub-container {
    display: block;
    @media (min-width: 720px) {
      width: 40%;
      margin-left: 40%;
    }
    text-align: left;

    height: 0;
    padding: 0;

    transition: height 0.3s, padding 0.3s;
    &.show-sub-container {
      // add 10 pixel for padding of this container
      // 20px is the font-size + 4 padding of inner container
      height: ${({ monthlyPriceArray }) =>
        Object.values(monthlyPriceArray).length * 20 + 10}px;
      padding: 5px;
    }
    .offer-entry-sub-monthly-price-inner-container {
      padding: 2px;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: space-between;
      &:first-child {
        font-weight: bold;
      }
      .offer-entry-sub-monthly-price-label {
        text-align: left;
        width: 70%;
      }
      .offer-entry-sub-monthly-price-value {
        text-align: right;
        width: 30%;
      }
    }
  }
`;
