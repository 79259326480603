import React, { useState } from "react";
import { AppState } from "./definitions/AppState";
import { defaultAppState } from "./defaults/defaultAppState";
import { StateSetter } from "../types/default";

type AppStateSetter = StateSetter<AppState>;
type ContextProps = [AppState, AppStateSetter];

const stateSetter: AppStateSetter = () => {};

const AppContext = React.createContext<ContextProps>([
  defaultAppState,
  stateSetter,
]);

const AppProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(defaultAppState);
  return (
    <AppContext.Provider value={[state, setState]}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
