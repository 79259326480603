import {
  OfferOption,
  ProductOption,
} from "../state/definitions/RecommendationState";
import { FormOption } from "../state/definitions/FormConfigState";
import { LabelOption } from "../types/application";

export function getValueFromFormOption(formOptions: FormOption[], id?: number) {
  let returnValue: number = -1;
  if (id !== undefined && formOptions[id]) {
    returnValue = formOptions[id].value;
  }
  return returnValue;
}

export function getNumberFromOption<T extends LabelOption<number>>(
  formOptions: T[],
  id?: number
): number {
  let returnValue = 0;
  if (id !== undefined && formOptions[id]) {
    returnValue = formOptions[id].label;
  }
  return returnValue;
}

export function getStringFromOption<T extends LabelOption<string>>(
  labelOptions: T[],
  id?: number
) {
  let returnValue = "";
  if (id !== undefined && labelOptions[id]) {
    returnValue = labelOptions[id].label;
  }
  return returnValue;
}

export function getStringFromOfferOption(
  offerOptions: OfferOption[],
  id?: number
) {
  return getStringFromOption(offerOptions, id);
}

export function getNumberFromOfferOption(
  offerOptions: OfferOption<number>[],
  id?: number
): number {
  return getNumberFromOption(offerOptions, id);
}

export function getStringArrayFromOfferOption(
  labelOptions: OfferOption<string[]>[],
  id?: number
) {
  let returnValue: string[] = [];
  if (id !== undefined && labelOptions[id]) {
    returnValue = labelOptions[id].label;
  }
  return returnValue;
}

export function getStringFromProductOption(
  productOptions: ProductOption[],
  id?: number
) {
  return getStringFromOption(productOptions, id);
}

export function getNumberFromProductOption(
  productOptions: ProductOption<number>[],
  id?: number
): number {
  let returnValue = 0;
  if (id !== undefined) {
    const founded = productOptions.find((v) => v.productId === id);
    if (founded !== undefined) {
      returnValue = founded.label;
    }
  }
  return returnValue;
}
