import { getIcon } from "../icons/Icons";
import { BubbleEntry } from "../types/application";
import { AdvantageInformation } from "./dataHandling/definitions/AdvantageInformation";

export function getBubbleEntriesFromAdvantageIds(
  advantageIds: number[],
  advantages: AdvantageInformation[]
): BubbleEntry[] {
  return advantageIds
    .filter((id) => id > 0)
    .map((id): BubbleEntry => {
      const icon = getIcon(id, false) ?? undefined;
      const foundAdvantage = advantages.find((w) => w.id === id);

      if (foundAdvantage === undefined) {
        return {
          headline: "",
          content: "",
          icon: icon,
        };
      }

      return {
        headline: foundAdvantage.title,
        content: foundAdvantage.description,
        icon: icon,
      };
    });
}
