import * as React from "react";

function SvgUsersGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#4a4d4e" } = props;
  return (
    <svg
      id="users_grey_svg___x35_3ddc946-930a-461f-8d17-e601cccbb1e8"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.users_grey_svg__st0{fill:none}.users_grey_svg__st1,.users_grey_svg__st2{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round}.users_grey_svg__st1{stroke-linejoin:round}.users_grey_svg__st2{stroke-miterlimit:10}`}
      </style>
      <path className="users_grey_svg__st0" d="M0 0h192v192H0z" />
      <path className="users_grey_svg__st0" d="M0 0h192v192H0z" />
      <circle className="users_grey_svg__st1" cx={72} cy={64} r={28} />
      <circle className="users_grey_svg__st1" cx={132} cy={72} r={20} />
      <path
        className="users_grey_svg__st2"
        d="M28 140c4.9-29.8 22.3-47.9 44-47.9s39.1 18.2 44 47.9"
      />
      <path
        className="users_grey_svg__st2"
        d="M104.8 110.2C110.7 98.7 120.6 92 132 92c15.8 0 28.4 12.6 32 33.3"
      />
    </svg>
  );
}

export default SvgUsersGrey;
