import * as React from "react";

function SvgVideo(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <path
        d="M378 212.5l84.6-85.6c8.2-8.2 23.7-8.4 29.9 8.8V366c-5.4 16-21.7 15-29.9 6.8L378 287.9l-.4-.6v90.4c0 21.1-17.1 38.3-38.3 38.3H45.8c-21.1 0-38.3-17.1-38.3-38.3V122.4c0-21.1 17.1-38.3 38.3-38.3h293.6c21.1 0 38.3 17.1 38.3 38.3v91.3l.3-1.2z"
        fill="none"
        stroke={color}
        strokeWidth={24.173}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgVideo;
