import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import ContainerSelectorItem from "./ContainerSelectorItem";
import ProductContainerSelectorTitle from "./ProductContainerSelectorTitle";
import ProductContainerSelectorBody from "./ProductContainerSelectorBody";
import { Product } from "../../../types/application";

export interface ContainerPrintSelectorProps {
  className?: string;
  borderColor?: string;
  marginBottom?: string;
  product: Product;
  dataVolume?: number;
  extraDataVolume?: number;
}

const ContainerPrintSelector: React.FC<ContainerPrintSelectorProps> = ({
  className,
  children,
  product,
  dataVolume,
  extraDataVolume,
}) => {
  return (
    <div className={className}>
      <div className={"prev-container"} />
      <div className={"content-table-container"}>
        <div className={"content-container"}>
          <ContainerSelectorItem
            titleElement={
              <ProductContainerSelectorTitle title={product.title} />
            }
            bodyElement={
              <ProductContainerSelectorBody
                body={product.body}
                dataVolume={dataVolume}
                extraDataVolume={extraDataVolume}
              />
            }
          />
          {children}
        </div>
      </div>
      <div className={"next-container"} />
    </div>
  );
};

export default styled(ContainerPrintSelector)`
  border-bottom: 2px solid
    ${({ borderColor }) => (borderColor ? borderColor : Color.BLACK)};
  border-left: 2px solid
    ${({ borderColor }) => (borderColor ? borderColor : Color.BLACK)};
  border-right: 2px solid
    ${({ borderColor }) => (borderColor ? borderColor : Color.BLACK)};
  min-width: 150px;
  max-width: 900px;
  min-height: 50px;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "8px")};
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .prev-container {
    height: 100%;
    outline: none;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .next-container {
    height: 100%;
    outline: none;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
  .content-table-container {
    display: inline-block;
    padding: 5px 0; // distance between valueField and the outer container
    vertical-align: top;

    width: calc(100% - 80px);
    height: 100%;
    .content-container {
      display: table-cell;
      width: 100%;
      height: 100%;
    }

    .container .column {
      width: 40%;
      background-color: #aaa;
      padding: 5px;
      border: 5px solid white;
      vertical-align: top;
      height: 100%;
    }
  }
`;
