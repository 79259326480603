import React, { CSSProperties } from "react";
import useProductPrice from "../../../hooks/helper/useProductPrice";
import { MonthlyPriceArray } from "../../../types/application";
import PriceDisplay from "./PriceDisplay";

interface PriceDisplayContainerProps {
  style?: CSSProperties;
  data: MonthlyPriceArray;
}

const PriceDisplayContainer: React.FC<PriceDisplayContainerProps> = ({
  style,
  data,
}) => {
  const [productPriceData] = useProductPrice(data);
  return <PriceDisplay style={style} priceData={productPriceData} />;
};

export default PriceDisplayContainer;
