import * as React from "react";
import TextTile from "./TextTile";
import IconLink from "../../links/IconLink";
import styled from "styled-components";
import { StyledComponentProps } from "../../../types/default";
import { Color } from "../../../constants/color";
import { useClassNames } from "../../../hooks/helper/useClassNames";
import { Interest, RecommendationMode } from "../../../types/application";
import { CloseButton } from "../../form/CloseButton";
import SVGIcon from "../../svgIcon/SVGIcon";
import Icons from "../../../icons/Icons";
import { getInterestFromType } from "../../../helper/recommendation/mapping";

export function getSubTitleForRecommendationColumn(mode?: RecommendationMode) {
  switch (mode) {
    case RecommendationMode.CURRENT:
      return "Dein aktuelles Produkt:";
    case RecommendationMode.INTERESTING:
      return "Das passt zu Dir:";
    case RecommendationMode.SELECTABLE:
      return "Dein passendes Produkt:";
    default:
      return "";
  }
}

const getBackgroundColor = (
  mode?: RecommendationMode,
  noInterest: boolean = false
) => {
  if (noInterest) {
    return Color.GREY;
  }
  switch (mode) {
    case RecommendationMode.CURRENT:
      return Color.WHITE;
    case RecommendationMode.INTERESTING:
      return Color.GREY_LIGHT;
    case RecommendationMode.SELECTABLE:
      return Color.RED;
    default:
      return Color.WHITE;
  }
};

const getFontColor = (
  mode?: RecommendationMode,
  noInterest: boolean = false
) => {
  if (noInterest) {
    return Color.WHITE;
  }
  switch (mode) {
    case RecommendationMode.CURRENT:
      return Color.GREY3;
    case RecommendationMode.INTERESTING:
      return Color.WHITE;
    case RecommendationMode.SELECTABLE:
      return Color.WHITE;
    default:
      return Color.GREY3;
  }
};

const getCloseButtonColor = (
  mode?: RecommendationMode,
  noInterest: boolean = false
) => {
  if (noInterest) {
    return Color.GREY;
  }
  switch (mode) {
    case RecommendationMode.CURRENT:
      return Color.GREY_LIGHT;
    case RecommendationMode.INTERESTING:
      return Color.GREY_LIGHT;
    case RecommendationMode.SELECTABLE:
      return Color.RED;
    default:
      return Color.GREY;
  }
};

export interface Props extends StyledComponentProps {
  mode?: RecommendationMode;
  type?: Interest;
  noInterest?: boolean;
  reset?: () => void;
  isResettable?: boolean;
  infoLink?: string;
}

const ColumnHeadTile = ({
  className,
  mode,
  type,
  noInterest = false,
  infoLink,
  reset,
  isResettable = false,
}: Props) => {
  const headlineClassNames = useClassNames([
    "recommendation-column-headline",
    infoLink ? "recommendation-column-headline-with-icon" : "",
  ]);

  return (
    <TextTile
      outerClassName={className}
      backgroundColor={getBackgroundColor(mode, noInterest)}
      fontColor={getFontColor(mode, noInterest)}
    >
      <p className={headlineClassNames}>{getInterestFromType(type)}</p>
      {infoLink && (
        <IconLink
          backgroundColor={"transparent"}
          to={infoLink}
          linkClass={"recommendation-column-icon-link"}
        />
      )}
      <p className={"recommendation-column-headline-sub"}>
        {getSubTitleForRecommendationColumn(mode)}
      </p>
      {!!reset && (
        <div className={"reset-container"}>
          <CloseButton
            value={isResettable}
            onChange={reset}
            disableOnFalseValue
            color={getCloseButtonColor(mode, noInterest)}
          >
            <SVGIcon
              src={noInterest ? Icons.rightArrow : Icons.bigClose}
              size={15}
              noPadding
              cursor={isResettable ? "pointer" : "not-allowed"}
            />
          </CloseButton>
        </div>
      )}
    </TextTile>
  );
};

const getSubHeadlineTextDecoration = ({ noInterest }: Props): string => {
  if (noInterest) {
    return `
      text-decoration: line-through;
      text-decoration-thickness: 2px;
    `;
  }

  return "";
};

export default styled(ColumnHeadTile)`
  .recommendation-column-headline {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    text-align: left;
    padding: 11px 10px 5px 10px;
    font-weight: bold;
    font-size: 16px;
  }
  .recommendation-column-headline-with-icon {
    width: 80%;
  }
  .recommendation-column-icon-link {
    display: inline-block;
    width: 20%;
    vertical-align: top;
    text-align: right;
    height: 30px;
    margin-top: 2px;

    .icon-link-container {
      width: unset;
      height: 100%;
      margin: 0;
      text-align: right;

      .icon {
        height: 100% !important;
        text-align: right;
        margin: 0;
      }
    }
  }

  .recommendation-column-headline-sub {
    display: inline-block;
    width: 80%;
    vertical-align: top;
    text-align: left;
    padding: 0 10px 10px 10px;
    font-weight: bold;
    font-size: 18px;
    ${getSubHeadlineTextDecoration}
  }

  .reset-container {
    display: inline-block;
    width: calc(20%);
    vertical-align: top;
    text-align: right;
    padding-right: 10px;
  }
`;
