import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import { SVGComponent } from "../../types/default";

interface NavImageItemProps {
  className?: string;
  selected?: boolean;
  Icons: SVGComponent | SVGComponent[];
  alt?: string;
}

const HeadlineImage: React.FC<NavImageItemProps> = ({ className, Icons }) => (
  <div className={className + " headline-image"}>
    {Array.isArray(Icons) ? (
      Icons.map((Icon, i) => <Icon key={i} className={`image-n-${i}`} />)
    ) : (
      <Icons />
    )}
  </div>
);

export default styled(HeadlineImage)`
  background-color: ${(props) => (props.selected ? Color.RED : Color.GREY2)};
  text-align: center;
  max-width: 66px;
  list-style: none;
  min-height: 80px;
  border-bottom: 3px solid ${Color.WHITE};
  position: relative;

  img,
  svg {
    padding: 12px 0;
    /* position: absolute; */
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    max-width: 75%;
    max-height: 160px;
  }

  img.image-n-1 {
    padding-top: 0;
  }
`;
