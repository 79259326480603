import React from "react";
import styled from "styled-components";
import { AddressResult } from "../../../state/definitions/AvailabilityState";
import SearchButton from "./SearchButton";
import { Color } from "../../../constants/color";

interface AddressRow {
  address: AddressResult;
  className?: string;
  onClick: (address: AddressResult) => void;
}

const AddressRow: React.FC<AddressRow> = ({ address, className, onClick }) => {
  return (
    <div className={className}>
      <div className="address">
        {`${address.street} ${address.streetNumber}${address.streetNumberExtra}, ${address.zip} ${address.city}`}
      </div>
      <div className="button">
        <SearchButton onClick={() => onClick(address)}>Auswählen</SearchButton>
      </div>
    </div>
  );
};

export default styled(AddressRow)`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
  .address {
    background: ${Color.WHITE};
    text-align: left;
    padding: 0 15px;
    flex-grow: 1;
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  .button {
    padding-left: 8px;
    width: 160px;
  }
`;
