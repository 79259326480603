import { RecommendationDefaultTableConfig } from "../../definitions/RecommendationState";

export const defaultRecommendationDefaultTableConfig: RecommendationDefaultTableConfig =
  {
    additionalTableLabel: "",
    additionalTableCount: 0,
    additionalTablePrice: 0,
    additionalTableStart: 0,

    furtherTableLabel: "",
    furtherTableCount: 0,
    furtherTablePrice: 0,
    furtherTableDataVolume: 0,
    furtherTableLastLabel: "",
    furtherTableLastCount: 0,
    furtherTableLastPrice: 0,

    singlePaymentTableLabel: "",
    singlePaymentTablePrice: 0,
  };
