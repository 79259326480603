import { DependencyList, useEffect, useMemo, useRef } from "react";

export const useRefHook = <T>(
  hook: (fct: () => T, dpd: DependencyList) => T,
  fct: () => T,
  dpd: DependencyList
): T => {
  const ref = useRef<any>(null);
  if (!ref.current || dpd !== ref.current.dpd) {
    ref.current = {
      fct: fct,
      dpd: dpd,
    };
  }
  return hook(() => {
    return ref.current.fct();
  }, [...ref.current.dpd, ref]);
};

export const useRefEffect = (fct: () => any, dpd: DependencyList) =>
  useRefHook<void>(useEffect, fct, dpd);

export const useRefMemo = <T>(fct: () => T, dpd: DependencyList) =>
  useRefHook<T>(useMemo, fct, dpd);
