import * as React from "react";

function SvgWinner(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="winner_svg__e7073fed-ee94-413e-aea0-47f2871fc557"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.winner_svg__st0{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className="winner_svg__st0"
        d="M96 132h-7.9c-26.8 0-27.6-40-27.6-40L56 28h80l-4.5 64s-.8 40-27.6 40H96M68 172h56M96 132v40"
      />
      <path
        className="winner_svg__st0"
        d="M134.9 44s26.2-1.3 25.1 20c-.9 18-28.5 28-28.5 28M57.1 44S30.9 42.7 32 64c.9 18 28.5 28 28.5 28"
      />
      <path fill="none" d="M0 0h192v192H0z" />
    </svg>
  );
}

export default SvgWinner;
