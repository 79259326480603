import React from "react";
import styled from "styled-components";
import CheckboxList from "./CheckboxList";
import { getIcons } from "../../icons/Icons";
import IconTileButton from "./IconTileButton";
import { FormOption } from "../../state/definitions/FormConfigState";

interface IconTileButtonGridProps {
  className?: string;
  maxWidth?: number;
  data?: FormOption[];
  values?: number[];
  onChange?: (values: number[]) => void;
}

const IconTileButtonGrid: React.FC<IconTileButtonGridProps> = ({
  className,
  data = [],
  values,
  onChange,
}) => {
  return (
    <div className={className}>
      <CheckboxList
        data={data}
        values={values}
        onChange={onChange ? onChange : () => {}}
        renderFkt={({ value, label }, isSelected, toggleValue, i) => {
          return (
            <IconTileButton
              onChange={() => toggleValue(value)}
              value={isSelected}
              icons={getIcons(value)}
              shadowed
              key={i}
            >
              {label}
            </IconTileButton>
          );
        }}
      />
    </div>
  );
};

export default styled(IconTileButtonGrid)`
  display: block;
  text-align: center;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth + "px" : "unset")};
  margin: 20px auto;
`;
