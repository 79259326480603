import * as React from "react";

function SvgCableWaves(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="cableWaves_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 100 100"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.cableWaves_svg__st0{fill: ${color} } .cableWaves_svg__st1{fill: ${color} }`}
      </style>
      <g id="cableWaves_svg__i-lrg_i-sml">
        <path
          className="cableWaves_svg__st0"
          d="M87.7 23.7C78.3 14.3 65.8 9.1 52.6 9.1c-13.3 0-25.7 5.2-35.1 14.6-.9.9-.8 2.3.1 3.2.9.8 2.3.8 3.2 0 8.5-8.5 19.8-13.3 31.9-13.3 12 0 23.4 4.7 31.9 13.3.9.9 2.3.9 3.2 0 .8-.9.7-2.4-.1-3.2z"
        />
        <path
          className="cableWaves_svg__st0"
          d="M78.2 33.8C71.4 27 62.3 23.2 52.7 23.2 43 23.3 34 27 27.1 33.9c-.9.9-.8 2.3.1 3.2.9.8 2.3.8 3.2 0 6-6 13.9-9.3 22.3-9.3s16.4 3.3 22.3 9.3c.4.5 1 .7 1.6.7 1.3 0 2.3-1 2.3-2.3 0-.6-.2-1.2-.7-1.7.1.1 0 0 0 0z"
        />
        <path
          className="cableWaves_svg__st0"
          d="M40.3 47.1C43.6 43.8 48 42 52.6 42c4.6 0 9 1.8 12.3 5.1.9.9 2.3.9 3.2 0 .9-.9.9-2.3 0-3.2-4.1-4.2-9.7-6.4-15.5-6.4-5.9 0-11.4 2.3-15.5 6.5-.9.9-.8 2.4.1 3.2.9.7 2.3.7 3.1-.1zM73 72.7c1.3 0 2.3-1 2.3-2.3 0-1.3-1-2.3-2.3-2.3h-6.8v-2.3c0-1.3-1-2.3-2.3-2.3H52.5c-4.9 0-9.4 2.6-11.8 6.8H13.9c-1.3 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3h28.2c.9 0 1.6-.5 2-1.3 0 0 .1-.2.4-.7 1.6-3 4.7-4.8 8-4.8h9.1v18.2h-9.1c-3.2 0-6.1-1.7-7.8-4.4-.4-.6-.6-1.1-.6-1.1-.4-.8-1.2-1.3-2.1-1.3H13.9c-1.3 0-2.3 1-2.3 2.3 0 1.3 1 2.3 2.3 2.3h26.8l.1.2c2.4 4.1 6.9 6.7 11.7 6.7h11.4c1.3 0 2.3-1 2.3-2.3v-2.3H73c1.3 0 2.3-1 2.3-2.3 0-1.3-1-2.3-2.3-2.3h-6.8v-9.1H73z"
        />
      </g>
      <g id="cableWaves_svg__i-xxsml_i-xsml">
        <path
          className="cableWaves_svg__st1"
          d="M87.7 23.7C78.3 14.3 65.8 9.1 52.6 9.1c-13.3 0-25.7 5.2-35.1 14.6-.9.9-.8 2.3.1 3.2.9.8 2.3.8 3.2 0 8.5-8.5 19.8-13.3 31.9-13.3 12 0 23.4 4.7 31.9 13.3.9.9 2.3.9 3.2 0 .8-.9.7-2.4-.1-3.2z"
        />
        <path
          className="cableWaves_svg__st1"
          d="M78.2 33.8C71.4 27 62.3 23.2 52.7 23.2 43 23.3 34 27 27.1 33.9c-.9.9-.8 2.3.1 3.2.9.8 2.3.8 3.2 0 6-6 13.9-9.3 22.3-9.3s16.4 3.3 22.3 9.3c.4.5 1 .7 1.6.7 1.3 0 2.3-1 2.3-2.3 0-.6-.2-1.2-.7-1.7.1.1 0 0 0 0z"
        />
        <path
          className="cableWaves_svg__st1"
          d="M40.3 47.1C43.6 43.8 48 42 52.6 42c4.6 0 9 1.8 12.3 5.1.9.9 2.3.9 3.2 0 .9-.9.9-2.3 0-3.2-4.1-4.2-9.7-6.4-15.5-6.4-5.9 0-11.4 2.3-15.5 6.5-.9.9-.8 2.4.1 3.2.9.7 2.3.7 3.1-.1zM73 72.7c1.3 0 2.3-1 2.3-2.3 0-1.3-1-2.3-2.3-2.3h-6.8v-2.3c0-1.3-1-2.3-2.3-2.3H52.5c-4.9 0-9.4 2.6-11.8 6.8H13.9c-1.3 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3h28.2c.9 0 1.6-.5 2-1.3 0 0 .1-.2.4-.7 1.6-3 4.7-4.8 8-4.8h9.1v18.2h-9.1c-3.2 0-6.1-1.7-7.8-4.4-.4-.6-.6-1.1-.6-1.1-.4-.8-1.2-1.3-2.1-1.3H13.9c-1.3 0-2.3 1-2.3 2.3 0 1.3 1 2.3 2.3 2.3h26.8l.1.2c2.4 4.1 6.9 6.7 11.7 6.7h11.4c1.3 0 2.3-1 2.3-2.3v-2.3H73c1.3 0 2.3-1 2.3-2.3 0-1.3-1-2.3-2.3-2.3h-6.8v-9.1H73z"
        />
      </g>
    </svg>
  );
}

export default SvgCableWaves;
