import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";

export interface PaginationButtonProps {
  className?: string;
  disabled?: boolean;
  selected?: boolean;
  direction: "prev" | "next" | "nothing";
  onClick?: () => void;
  verticalAlign?: string;
}

const PaginationButton: React.FC<PaginationButtonProps> = ({
  direction,
  onClick,
  disabled = false,
  className,
  children,
}) => (
  <button onClick={onClick} disabled={disabled} className={className}>
    {direction === "prev" && <div className={"left-triangle"} />}
    {direction === "next" && <div className={"right-triangle"} />}
    {children}
  </button>
);

export default styled(PaginationButton)`
  height: 40px;
  width: 40px;
  background-color: ${(props) => (props.selected ? Color.RED : Color.GREY2)};
  color: ${Color.WHITE};
  border: 0;
  padding: 0;
  outline: none;

  vertical-align: ${({ verticalAlign }) =>
    verticalAlign ? verticalAlign : "bottom"};

  :hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }

  .right-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 20px;
    border-color: transparent transparent transparent ${Color.WHITE};
    margin-left: 10px;
  }

  .left-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 20px 10px 0;
    border-color: transparent ${Color.WHITE} transparent transparent;
    margin-left: 10px;
  }
`;
