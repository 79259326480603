
import { Interest, InterestObject } from "../../types/application";

export function getInterestObject(interests: Interest[]): InterestObject {
  return {
    mobile: interests.includes(Interest.MOBILE),
    iot: interests.includes(Interest.IOT),
    fixed: interests.includes(Interest.FIXED),
    tv: interests.includes(Interest.TV),
  };
}
