import { useContext } from "react";
import { AppContext } from "../state/AppContext";
import { RecommendationState } from "../state/definitions/RecommendationState";

export const useRecommendation = (): RecommendationState => {
  const [state] = useContext(AppContext);
  return state.recommendation;
};

export default useRecommendation;
