import { useFormState } from "../useForm";
import { useContext } from "react";
import { AppContext } from "../../state/AppContext";
import { getValueFromFormOption } from "../../helper/getFromOption";
import { stringToDate } from "../../helper/dateStringConversion";
import { FormState } from "../../state/definitions/FormState";
import { FormConfigState } from "../../state/definitions/FormConfigState";
import { useInterests } from "./useInterests";
import { CommonProps, InterfaceChangedPropTypes } from "../../types/util";
import { ContractSummary, Interest } from "../../types/application";
import {
  FixedImportants,
  FixedSituation,
  ImportantValues,
  IOTSituation,
  MobileImportants,
  MobileSituation,
  PersonalHomeSituation,
  PersonalSituation,
  PriceSituation,
  ProfileData,
  TVImportants,
  TVSituation,
} from "../../types/profile";
import { FormConfigLabelIds } from "../../data/definitions/FormConfigLabelIds";

type FormCommons = CommonProps<FormState, FormConfigState>;

const useProfile = (): {
  data: ProfileData;
  thereArePriceInformation: boolean;
  thereAreHomeInformation: boolean;
  thereArePersonalInformation: boolean;
} => {
  const { interests } = useInterests();
  const [state] = useContext(AppContext);
  const { formConfig } = state.bootstrap;
  const {
    mobileCurrentPrice,
    mobileCurrentDataVolume,
    mobileDataVolume,
    mobileDataSharePeople,
    mobileDataShareKids,
    mobileDevicesTablet,
    mobileDevicesSmartwatch,
    mobileDevicesIOT,
    mobileDevicesSmartphone,
    fixedCurrentPrice,
    fixedCurrentBandwidth,
    tvCurrentPrice,
    iotCurrentPrice,
    iotInterests,
    iotSmartWatchOwnDevice,
    iotTrackerOwnDevice,
    iotCameraOwnDevice,
    providerMobileId = -1,
    providerIotId = -1,
    providerFixedId = -1,
    providerTvId = -1,
    mobileCurrentContractEnd,
    fixedContractCurrentEnd,
    tvContractCurrentEnd,
    needAdditionalDataVolume,
    mobileDataSocial,
    mobileDataMusic,
    mobileDataChat,
    mobileDataVideo,
    mobileDataGaming,
    mobileWithoutCurrentContractEnd,
    fixedWithoutCurrentContractEnd,
    tvWithoutCurrentContractEnd,
    fixedDeviceCount,
    contractData,
  } = state.form;

  const { mobileProviders, iotProviders, fixedProviders, tvProviders } =
    formConfig;
  let mProviderInternalId = mobileProviders.findIndex(
    (v) => v.value === providerMobileId
  );
  let iProviderInternalId = iotProviders.findIndex(
    (v) => v.value === providerIotId
  );
  let fProviderInternalId = fixedProviders.findIndex(
    (v) => v.value === providerFixedId
  );
  let tProviderInternalId = tvProviders.findIndex(
    (v) => v.value === providerTvId
  );
  const mobileProvider = mobileProviders[mProviderInternalId]?.label;
  const iotProvider = iotProviders[iProviderInternalId]?.label;
  const fixedProvider = fixedProviders[fProviderInternalId]?.label;
  const tvProvider = tvProviders[tProviderInternalId]?.label;

  const [contractCounts] = useFormState("contractCounts");

  const mobileSituation: MobileSituation = {
    interesting: interests.includes(Interest.MOBILE),
    provider: mobileProvider,
    price: mobileCurrentPrice,
    dataVolume: mobileCurrentDataVolume,
    extraDataVolume: needAdditionalDataVolume,
    currentContractEndDate: mobileWithoutCurrentContractEnd
      ? undefined
      : stringToDate(mobileCurrentContractEnd),
    tabletCount: mobileDevicesTablet,
    smartwatchCount: mobileDevicesSmartwatch,
    iotCount: mobileDevicesIOT,
    phoneCount: mobileDevicesSmartphone,
    devicesCount:
      mobileDevicesTablet +
      mobileDevicesSmartwatch +
      mobileDevicesIOT +
      mobileDevicesSmartphone,
  };
  const iotProducts: string[] = [];
  if (iotInterests.includes(FormConfigLabelIds.IOT_SMARTWATCH)) {
    iotProducts.push("SmartWatch");
  }
  if (iotInterests.includes(FormConfigLabelIds.IOT_TRACKER)) {
    iotProducts.push("Tracker");
  }
  if (iotInterests.includes(FormConfigLabelIds.IOT_CAMERA)) {
    iotProducts.push("Kamera");
  }
  const iotOwnDevices: string[] = [];
  if (iotSmartWatchOwnDevice) {
    iotOwnDevices.push("SmartWatch");
  }
  if (iotTrackerOwnDevice) {
    iotOwnDevices.push("Tracker");
  }
  if (iotCameraOwnDevice) {
    iotOwnDevices.push("Kamera");
  }
  const iotSituation: IOTSituation = {
    interesting: interests.includes(Interest.IOT),
    provider: iotProvider,
    price: iotCurrentPrice,
    products: iotProducts,
    ownDevices: iotOwnDevices,
  };

  const fixedSituation: FixedSituation = {
    interesting: interests.includes(Interest.FIXED),
    provider: fixedProvider,
    deviceCount: fixedDeviceCount,
    price: fixedCurrentPrice,
    bandwidth: fixedCurrentBandwidth,
    currentContractEndDate: fixedWithoutCurrentContractEnd
      ? undefined
      : stringToDate(fixedContractCurrentEnd),
  };
  const tvSituation: TVSituation = {
    interesting: interests.includes(Interest.TV),
    provider: tvProvider,
    price: tvCurrentPrice,
    currentContractEndDate: tvWithoutCurrentContractEnd
      ? undefined
      : stringToDate(tvContractCurrentEnd),
  };

  const adultNames: string[] = [];
  const kidNames: string[] = [];
  const contracts: InterfaceChangedPropTypes<ContractSummary, string> = {
    cardData: "",
    cardTariff: "",
    cardDataGo: "",
  };
  contractData.forEach((human) => {
    if (human.name !== "") {
      if (human.type === "person") {
        adultNames.push(human.name);
      }
      if (human.type === "kid") {
        kidNames.push(human.name);
      }
      human.contracts.forEach((contract) => {
        if (contract.count > 0 && contracts[contract.labelKey] !== "") {
          contracts[contract.labelKey] =
            contracts[contract.labelKey] + ", " + human.name;
        }
        if (contract.count > 0 && contracts[contract.labelKey] === "") {
          contracts[contract.labelKey] = human.name;
        }
      });
    }
  });

  const isValueInFormConfig = (prop: keyof FormCommons, id: number) => {
    if (Array.isArray(state.form[prop])) {
      return (state.form[prop] as any[]).includes(
        getValueFromFormOption(formConfig[prop], id)
      );
    }
    return state.form[prop] === getValueFromFormOption(formConfig[prop], 0);
  };

  const mobileImportants: MobileImportants = {
    newSmartphone: isValueInFormConfig("mobilePreferencesSmartphone", 0),
    phoneInsurance: isValueInFormConfig("mobilePreferencesSmartphone", 1),
    oldNewChange: isValueInFormConfig("mobilePreferencesSmartphone", 2),
    personalDataVolume: isValueInFormConfig("mobilePreferencesDataVolume", 0),
    personalHomeDataVolume: isValueInFormConfig(
      "mobilePreferencesDataVolume",
      1
    ),
    highOncePrice: isValueInFormConfig("mobilePreferencesPrice", 0),
    highMonthsPrice: isValueInFormConfig("mobilePreferencesPrice", 1),
    noMonthlyCosts: isValueInFormConfig("mobilePreferencesPrice", 2),
    neededDataVolume: mobileDataVolume,
    socialMedia: mobileDataSocial,
    music: mobileDataMusic,
    chat: mobileDataChat,
    video: mobileDataVideo,
    gaming: mobileDataGaming,
    cards: contractCounts,
    contractNames: contracts,
  };
  const fixedImportants: FixedImportants = {
    speedInternet: isValueInFormConfig("fixedPreferences", 0),
    goodService: isValueInFormConfig("fixedPreferences", 1),
    price: isValueInFormConfig("fixedPreferences", 2),
    germanCall: isValueInFormConfig("fixedCallDestination", 0),
    euCall: isValueInFormConfig("fixedCallDestination", 1),
    internationalCall: isValueInFormConfig("fixedCallDestination", 2),
  };
  const tvImportants: TVImportants = {
    classicTV: isValueInFormConfig("tvPreference", 0),
    streamingProvider: isValueInFormConfig("tvPreference", 1),
    mediaLibrary: isValueInFormConfig("tvPreference", 2),
    vod: isValueInFormConfig("tvPreference", 3),
    currentProgram: isValueInFormConfig("tvPreference", 4),
    privateHd: isValueInFormConfig("tvPreference", 5),
    payTV: isValueInFormConfig("tvPreference", 6),
    mobileAndTablet: isValueInFormConfig("tvPreference", 7),
    recordings: isValueInFormConfig("tvPreference", 8),
    sky: isValueInFormConfig("tvPreference", 9),
  };

  const priceSituation: PriceSituation = {
    summaryPrice:
      mobileCurrentPrice + fixedCurrentPrice + tvCurrentPrice + iotCurrentPrice,
  };

  const personalSituation: PersonalSituation = {
    under28: isValueInFormConfig("mobilePersonalDetails", 0),
    parentBetween10And17: isValueInFormConfig("mobilePersonalDetails", 1),
    cooperated: isValueInFormConfig("mobilePersonalDetails", 2),
    independent: isValueInFormConfig("independentPersonalDetails", 0),
    private: isValueInFormConfig("independentPersonalDetails", 1),
  };

  const personalHomeSituation: PersonalHomeSituation = {
    adultCount: mobileDataSharePeople,
    kidCount: mobileDataShareKids,
    personCount: mobileDataSharePeople + mobileDataShareKids,
    adultNames: adultNames.join(", "),
    kidNames: kidNames.join(", "),
  };
  const importants: ImportantValues = {
    mobile: mobileImportants,
    fixed: fixedImportants,
    tv: tvImportants,
  };
  return {
    data: {
      mobile: mobileSituation,
      iot: iotSituation,
      fixed: fixedSituation,
      tv: tvSituation,
      price: priceSituation,
      personal: personalSituation,
      personalHome: personalHomeSituation,
      important: importants,
    },
    thereArePriceInformation:
      !!priceSituation.summaryPrice && priceSituation.summaryPrice > 0,
    thereAreHomeInformation:
      !!(
        personalHomeSituation.adultCount && personalHomeSituation.adultCount > 0
      ) ||
      !!(
        personalHomeSituation.kidCount && personalHomeSituation.kidCount > 0
      ) ||
      !!(
        personalHomeSituation.personCount &&
        personalHomeSituation.personCount > 0
      ),
    thereArePersonalInformation:
      !!personalSituation.under28 ||
      !!personalSituation.parentBetween10And17 ||
      !!personalSituation.independent ||
      !!personalSituation.private ||
      !!personalSituation.cooperated,
  };
};

export default useProfile;
