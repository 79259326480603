import * as React from "react";

function SvgDevicesColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="devices_color_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".devices_color_svg__st0{fill:none;stroke:#e60000;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <path
        className="devices_color_svg__st0"
        d="M132 124.2H84.1M28 60.1v-21c-.1-6 4.7-11 10.7-11.1h114.5c6 .1 10.8 5.1 10.7 11.1V84"
      />
      <path d="M0 0h192v192H0V0z" fill="none" />
      <path
        className="devices_color_svg__st0"
        d="M148 140h8m-108 0h8M138 84h28c3.3 0 6 2.7 6 6v60c0 3.3-2.7 6-6 6h-28c-3.3 0-6-2.7-6-6V90c0-3.3 2.7-6 6-6zM26 60.1h52.1c3.3 0 6 2.7 6 6V150c0 3.3-2.7 6-6 6H26c-3.3 0-6-2.7-6-6V66.1c0-3.3 2.7-6 6-6zM115.8 140H84.1M96 124.2V140"
      />
    </svg>
  );
}

export default SvgDevicesColor;
