import React from "react";
import styled from "styled-components";
import { SlidePages } from "../../constants/SlidePages";
import LinkButton from "../form/LinkButton";
import { StyledComponentProps } from "../../types/default";
import JustifiedColumnsContainer from "../grid/JustifiedColumnsContainer";
import { AccelerometerHalf, Plus } from "../../icons/svgComponents";
import Shopping from "../../icons/svgComponents/Shopping";
import LinkButtonInner from "../form/LinkButtonInner";
import LinkButtonContainer from "../form/LinkButtonContainer";

interface RecommendationPaginationProps extends StyledComponentProps {}

const RecommendationPagination: React.FC<RecommendationPaginationProps> = ({
  className,
}) => {
  return (
    <JustifiedColumnsContainer
      extraClassName={className}
      justifyContent={"space-between"}
    >
      <LinkButton
        startIcon={<AccelerometerHalf />}
        slideId={SlidePages.profile.id}
        title={"zum Profil"}
        textAlign={"left"}
      />
      <LinkButtonContainer>
        <LinkButtonInner
          startIcon={<Plus />}
          slideId={SlidePages.moreRecommendation.id}
          title={"weitere Produkte"}
          value
          noDisplayTextOnSmallDevice
        />
        <LinkButtonInner
          endIcon={<Shopping />}
          slideId={SlidePages.summaryRecommendation.id}
          title={"zum Warenkorb"}
          value
        />
      </LinkButtonContainer>
    </JustifiedColumnsContainer>
  );
};

export default styled(RecommendationPagination)`
  margin: 0 5px;
`;
