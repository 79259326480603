export enum Color {
  RED = "#e30613",
  RED_LIGHT = "#db8084", // ?
  RED_DARK = "#aa2120",
  RED_DARK2 = "#a7201f",
  PURPLE = "#5f2750",
  BLACK = "#000000",
  GREY_DARK_TRANSPARENT = "#22222288", // box shadows 3x
  GREY_DARK2 = "#222222", // box shadows 3x
  GREY_LIGHT11 = "#3e3e3e", // box shadows 1x
  GREY_DARK = "#3f403f",
  GREY2 = "#484b4c",
  GREY3 = "#484b4d",
  GREY = "#4a4d4e",
  GREY4 = "#555555", // box-shadow 3x
  GREY5 = "#656565", // box-shadow 2x
  GREY_LIGHT0 = "#949494", // GREY_LIGHT11 (opacity: 0.5)
  GREY_LIGHT = "#9d9d9c",
  GREY_LIGHT5 = "#aaaaaa",
  GREY_DISABLED_ICON_BACKGROUND = "#cccccc",
  GREY_LIGHT6 = "#d0d0d0",
  GREY_LIGHT9 = "#dadada", // 1x
  GREY_LIGHT3 = "#e3e3e3",
  GREY_LIGHT8 = "#e6e6e5",
  GREY_LIGHT2 = "#e8e8e8", // 1x
  GREY_LIGHT7 = "#eeeeee",
  GREY_LIGHT4 = "#efefef",
  GREY_LIGHT12 = "#e1e1e1",
  GREY_LIGHT10 = "#f4f4f3",
  WHITE = "#ffffff",
  GREEN = "#428600",
}
