import * as React from "react";

function SvgExpandScreen(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="expandScreen_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.expandScreen_svg__st1{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        id="expandScreen_svg__bg"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        fillOpacity={0}
        d="M0 0h192v192H0z"
      />
      <path
        id="expandScreen_svg__Stroke-1"
        className="expandScreen_svg__st1"
        d="M96 174c-43.1 0-78-34.9-78-78s34.9-78 78-78 78 34.9 78 78-34.9 78-78 78z"
      />
      <g id="expandScreen_svg__Group" transform="translate(7 7)">
        <g id="expandScreen_svg__Group-2" transform="translate(5.25 5.25)">
          <path
            id="expandScreen_svg__Stroke-3"
            className="expandScreen_svg__st1"
            d="M123.9 96.7v27.2H96.7"
          />
          <path
            id="expandScreen_svg__Stroke-5"
            className="expandScreen_svg__st1"
            d="M89.4 89.4l31.8 31.8"
          />
        </g>
        <g id="expandScreen_svg__Group-3">
          <path
            id="expandScreen_svg__Stroke-7"
            className="expandScreen_svg__st1"
            d="M49 76.3V49h27.3"
          />
          <path
            id="expandScreen_svg__Stroke-9"
            className="expandScreen_svg__st1"
            d="M80.8 80.8L49 49"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgExpandScreen;
