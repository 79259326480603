import * as React from "react";

function SvgSatellite(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="satellite_svg___x32_f050b1b-fc67-4d30-a826-c9f2f2af4888"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.satellite_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        id="satellite_svg__fb5e3235-2ecc-485a-82b2-2c8e361ca0df"
        className="satellite_svg__st0"
        d="M475.4 328.3c.1 79.8-64.6 144.6-144.4 144.7"
      />
      <path
        id="satellite_svg___x33_4dfb43c-7309-4a79-b953-81dceae6257f"
        className="satellite_svg__st0"
        d="M422.9 332.8c0 48.5-39.3 87.9-87.8 87.9"
      />
      <path
        transform="rotate(-45.001 371.196 128.342)"
        className="satellite_svg__st0"
        d="M262 66.8h218.4V190H262z"
      />
      <path className="satellite_svg__st0" d="M295.2 206.7L449.4 52.5" />
      <path
        transform="rotate(-45.001 128.697 370.853)"
        className="satellite_svg__st0"
        d="M18.1 309.3h221.1v123.2H18.1z"
      />
      <path
        className="satellite_svg__st0"
        d="M52.7 449.2l153.9-153.9M60.9 351.6l87.1 87.1M115.4 297.1l87.1 87.1M301.5 111l87.1 87.1M356 56.4l87.1 87.2M250.4 336.1l-178-178C48.3 134 48.3 95 72.3 71c24.1-24 63-24 87.1 0l178 178-87 87.1zM293.9 379.7c-24-24-24.1-63-.1-87l.1-.1c24-24 63-24.1 87.1 0l-87.1 87.1z"
      />
    </svg>
  );
}

export default SvgSatellite;
