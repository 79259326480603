import React, { useContext } from "react";
import QRCode from "qrcode.react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import vodafone from "../../images/logo.svg";
import { AppContext } from "../../state/AppContext";
import { OuterLinks } from "../../data/definitions/OuterLinks";

interface PrintHeadlineProps {
  className?: string;
  maxWidth?: string;
}

const PrintHeadline: React.FC<PrintHeadlineProps> = ({
  children,
  className,
}) => {
  const [state] = useContext(AppContext);

  return (
    <div className={className}>
      <h1>{children}</h1>
      <QRCode
        value={state.contact.url ?? OuterLinks.DEFAULT_VODAFONE}
        className={"vodafone-qr-code"}
        renderAs={"svg"}
        level={"L"}
        fgColor={"black"}
        size={50}
      />
      <img className={"vodafone-icon"} src={vodafone} alt={"vodafone"} />
    </div>
  );
};

export default styled(PrintHeadline)`
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "unset")};
  margin: 0 auto;
  position: relative;
  padding-bottom: 5px;

  h1 {
    display: inline-block;
    vertical-align: top;

    color: ${Color.RED};
    font-size: 42px;
    letter-spacing: 1px;

    height: 50px;
  }

  .vodafone-qr-code {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    bottom: 5px;
    right: 55px;
    border: none;
  }
  .vodafone-icon {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    max-height: 50px;
    max-width: 50px;

    bottom: 5px;
    right: 0;
    border: none;
  }
`;
