import { Interest } from "../../types/application";

const interestPriorityArray = Object.values(Interest);

// MFTI
// MOBILE
// FIXED
// TV
// IOT
export function mftiSort(interestA: Interest, interestB: Interest): number {
  return (
    interestPriorityArray.indexOf(interestA) -
    interestPriorityArray.indexOf(interestB)
  );
}
