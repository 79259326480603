import { getTestResultString } from "./getTestResultString";
import { testPropOfObject } from "./testPropOfObject";
import {
  extraCheckFunction,
  TestClassType,
  TestClassTypeInterface,
} from "./interfaces";

export function testObjectWithKeys<T extends TestClassTypeInterface>(
  testObject: any,
  keyNames: string[],
  TestClass: TestClassType<T>,
  withTypeCheck: boolean = false,
  extraCheck?: extraCheckFunction<T>
): string {
  // Test following Object:
  // first:   testing:  a,b,c
  // second:  testing each object
  //                test: e,f
  // {
  // "a" : {"e":1,"f":2}
  // "b" : {"e":1,"f":2}
  // "c" : {"e":1,"f":2}
  // }

  const aObject = new TestClass();
  const aObjectKeys = Object.keys(aObject);
  let keyNamesLastIndex = -1;
  let aObjectLastIndex = -1;
  let aObjectPropLastIndex = -1;
  let keyNamesResult: boolean;
  let aObjectPropCheckResult = false;

  keyNamesResult = keyNames.some((v, i) => {
    keyNamesLastIndex = i;
    const testObjectResult = testPropOfObject(v, testObject, extraCheck);

    if (!testObjectResult) {
      const aObjectValues = Object.values(testObject);
      aObjectPropCheckResult = aObjectValues.some((oValue, valueI) => {
        aObjectLastIndex = valueI;

        return (aObjectPropCheckResult = aObjectKeys.some((oKey, keyI) => {
          aObjectPropLastIndex = keyI;
          if (withTypeCheck) {
            // TODO: Add typechecking (typeof v === typeof testObject[v])
          }
          return testPropOfObject(oKey, oValue, extraCheck);
        }));
      });
      return aObjectPropCheckResult;
    }
    return testObjectResult;
  });

  // return different strings depends on the test results
  const aObjectCheckResultString = getTestResultString(
    aObjectPropCheckResult,
    aObjectPropLastIndex,
    aObjectKeys
  );

  if (aObjectCheckResultString !== "") {
    const keyNamesResultString = getTestResultString(
      keyNamesResult,
      aObjectLastIndex,
      keyNames
    );
    return keyNamesResultString + " (" + aObjectCheckResultString + ") "; //+ " " + aObjectPropCheckResultString;
  }

  return getTestResultString(keyNamesResult, keyNamesLastIndex, keyNames);
}
