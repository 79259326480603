import * as React from "react";
import { RecommendationColumnProps } from "./RecommendationColumn";
import styled from "styled-components";
import Column from "../../../grid/Column";
import { Color } from "../../../../constants/color";
import ColumnHeadTile from "../ColumnHeadTile";
import { Interest } from "../../../../types/application";
import TileButton from "../../../form/TileButton";
import { getInterestFromType } from "../../../../helper/recommendation/mapping";
import { Plus } from "../../../../icons/svgComponents";
import { useNewOffer } from "../../../../hooks/useNewOffer";
import { useInterestValue } from "../../../../hooks/useFormHelper/useInterests";

export interface Props
  extends Omit<
    RecommendationColumnProps,
    "offerEntryId" | "mainColumn" | "informationLink"
  > {}

const EmptyRecommendationColumn: React.FC<Props> = ({
  colProps,
  className,
}) => {
  const { createNewOfferState } = useNewOffer();
  const { isInterestSelected } = useInterestValue();
  return (
    <Column {...colProps} extraClassName={className}>
      <div className={"recommendation-column"}>
        <ColumnHeadTile />
        {Object.values(Interest).map((interest: Interest) => {
          return (
            <TileButton
              key={interest}
              value={isInterestSelected(interest)}
              main={!isInterestSelected(interest)}
              disableOnFalseValue
              shadowed
              onChange={() =>
                createNewOfferState({ interest: interest, mainRow: false })
              }
              startIcon={<Plus color={"white"} />}
            >
              Neues {getInterestFromType(interest)} Produkt
            </TileButton>
          );
        })}
      </div>
    </Column>
  );
};

export default styled(EmptyRecommendationColumn)`
  background: ${Color.GREY_LIGHT0};
  margin: 5px 5px 10px 5px;

  .recommendation-column {
    width: 100%;
    height: 500px;
    max-width: 900px;
    display: inline-block;
    svg {
      max-width: 75%;
      width: 50px;
    }
    .tile-button {
      margin: 15px 10px;
    }
  }
`;
