import { TransferDatabaseAdvantage } from "../../data/definitions/TransferDatabaseAdvantage";
import { testObjectIsFromClass } from "./default/testObjectIsFromClass";

export default (
  unsureDatabaseAdvantages: any,
  fileName: string = "databaseAdvantages"
): [TransferDatabaseAdvantage[], boolean] => {
  let result = true;
  if (
    unsureDatabaseAdvantages !== undefined &&
    unsureDatabaseAdvantages.length !== undefined &&
    unsureDatabaseAdvantages.length > 0
  ) {
    unsureDatabaseAdvantages.forEach((v: any, i: number) => {
      const testResult = testObjectIsFromClass(
        v,
        TransferDatabaseAdvantage,
        true
      );
      if (testResult !== "") {
        console.error(
          "File " + fileName + " has a false structure:",
          "[",
          i,
          "]: ",
          testResult
        );
        result = false;
      }
    });
  } else {
    console.error(
      "File " + fileName + " has a false structure:",
      "'No index structure.'"
    );
    result = false;
  }

  return [unsureDatabaseAdvantages as TransferDatabaseAdvantage[], result];
};
