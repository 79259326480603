import { NavigationState } from "../definitions/NavigationState";

export const defaultNavigationState: NavigationState = {
  slides: [],
  progress: 0,
  currentLocation: {
    pathname: "/",
    search: "",
    hash: "",
    state: null,
    key: "",
  },
  prevSlide: null,
  nextSlide: null,
  disableNext: false,
  currentSlideIndex: 0,
  slideCount: 0,
  nextInterest: null,
  tempNextInterest: null,
  initializeComplete: false,
  isChangeGuaranteeSlideShowing: false,
  isCancelShowing: false,
  isInterestShowing: null,
};
