import { useContext } from "react";
import { AppContext } from "../../state/AppContext";
import { AppState } from "../../state/definitions/AppState";
import { SimpleState, UnsureSimpleState } from "../../types/default";
import { ProductLineOfferState } from "../../state/definitions/ProductLineOfferState";
import { defaultProductLineOfferState } from "../../state/defaults/defaultProductLineOfferState";

export const useNewOfferState = <K extends keyof ProductLineOfferState>(
  key: K,
  offerEntryId: string
): SimpleState<ProductLineOfferState[K]> => {
  const [state, setState] = useContext(AppContext);

  const setValue = (value: ProductLineOfferState[K]) => {
    setState((prev: AppState) => {
      return {
        ...prev,
        newOffer: {
          ...prev.newOffer,
          [offerEntryId]: {
            ...prev.newOffer[offerEntryId],
            [key]: value,
          },
        },
      };
    });
  };

  return [
    state.newOffer[offerEntryId] !== undefined &&
    state.newOffer[offerEntryId][key] !== undefined
      ? state.newOffer[offerEntryId][key]
      : defaultProductLineOfferState[key],
    setValue,
  ];
};

export const useUnsureNewOfferState = <K extends keyof ProductLineOfferState>(
  key: K,
  offerEntryId: string
): UnsureSimpleState<ProductLineOfferState[K]> => {
  const [state, setState] = useContext(AppContext);

  const setValue = (value: ProductLineOfferState[K]) => {
    setState((prev: AppState) => {
      return {
        ...prev,
        newOffer: {
          ...prev.newOffer,
          [offerEntryId]: {
            ...prev.newOffer[offerEntryId],
            [key]: value,
          },
        },
      };
    });
  };

  return [state.newOffer[offerEntryId][key], setValue];
};
