export function getEmptyStringIfNotExist(
  testString: string | undefined
): string {
  return testString === undefined ? "" : testString;
}

export function getZeroIfNotExist(testNumber: number | undefined): number {
  return testNumber === undefined ? 0 : testNumber;
}

export function getEmptyArrayIfNotExist(testArray: any[] | undefined): any[] {
  return testArray === undefined ? [] : testArray;
}