import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import GigaKombiModal from "../../elements/recommendationPage/gigakombi/GigaKombiModal";
import { GigaKombiEntry } from "../../../types/application";
import { useInterests } from "../../../hooks/useFormHelper/useInterests";
import GigaKombiInterestButton from "../../elements/recommendationPage/gigakombi/GigaKombiInterestButton";
import { DISABLE_CYBER_ADVANTAGE } from "../../../constants/default";

interface GigaKombiPageProps {
  className?: string;
  gigaKombiData: GigaKombiEntry[];
  interestingButtons?: JSX.Element[];
  image: string;
  rebatePrice?: number;
}

const subGigaKombiTexts: { headline: string; text: string }[] = [];
subGigaKombiTexts.push({
  headline: "Mehr sparen:",
  text: "Jeden Monat bis zu 15 € Rabatt",
});
subGigaKombiTexts.push({
  headline: "Mehr surfen:",
  text: "Bis zu unbegrenztem Datenvolumen",
});
if (!DISABLE_CYBER_ADVANTAGE) {
  subGigaKombiTexts.push({
    headline: "Mehr Sicherheit:",
    text: "Cyber-Versicherung für die ganze Familie",
  });
}
subGigaKombiTexts.push({
  headline: "Mehr Flexibilität:",
  text: "Bis zu 12 Monate kein doppelter Basispreis",
});

const GigaKombiPage: React.FC<GigaKombiPageProps> = ({
  className,
  gigaKombiData,
  children,
  image,
  rebatePrice,
}) => {
  const { getSimpleWithsObject } = useInterests();
  const simpleWithObject = getSimpleWithsObject();
  return (
    <div className={className}>
      <div className={"image-kombi-container"}>
        <div className={"image-container"}>
          <img src={image} alt="" />
        </div>
        <div className={"kombi-container"}>
          <GigaKombiModal data={gigaKombiData} />
          <div className={"giga-icon-container"}>
            <GigaKombiInterestButton
              withsObject={simpleWithObject}
              extraClassName={"gigakombi-svg"}
            />
          </div>
          <div className={"sub-giga-container"}>
            {subGigaKombiTexts.map((v, i) => (
              <div className={"sub-giga-container-entry"} key={i}>
                <p className={"sub-giga-headline"}>{v.headline}</p>
                <p className={"sub-giga-text"}>{v.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={"interesting-buttons-container"}>{children}</div>
    </div>
  );
};

export default styled(GigaKombiPage)`
  .image-kombi-container {
    margin: 0 50px;
    color: ${Color.WHITE};
    width: calc(100% - 100px);

    @media (min-width: 1023px) {
      display: table;
    }
    @media (max-width: 1023px) {
      display: block;
    }

    .image-container {
      @media (min-width: 1023px) {
        display: table-cell;
        vertical-align: top;
        padding-right: 5px;
        max-width: 40%;
        max-height: 400px;
      }
      @media (max-width: 1023px) and (min-width: 960px) {
        display: none;
      }
      @media (max-width: 960px) {
        display: block;
        vertical-align: top;
        max-width: 100%;
        margin-bottom: 5px;
        img {
          margin: 0 auto;
        }
      }

      img {
        display: block;
        max-width: 400px;
        max-height: 400px;
      }
    }

    .kombi-container {
      position: relative;
      background: linear-gradient(135deg, ${Color.RED_DARK2}, ${Color.RED});
      padding: 5px;
      min-width: 400px;
      @media (min-width: 1024px) {
        height: 100%;
        display: table-cell;
        vertical-align: top;
        text-align: center;
        width: 100%;
      }
      @media (max-width: 1023px) {
        display: block;
        vertical-align: top;
        max-width: 100%;
      }

      .giga-icon-container {
        display: inline-block;
        vertical-align: middle;

        @media (min-width: 1024px) {
          width: 55%;
          padding-left: 5px;

          text-align: center;
        }
        @media (max-width: 1023px) {
          width: calc(55% - 50px);
          padding-left: 10px;

          text-align: right;
        }

        .gigakombi-svg {
          max-width: 250px;
        }
      }

      .sub-giga-container {
        display: inline-block;
        text-align: left;
        vertical-align: middle;

        @media (min-width: 1024px) {
          width: 45%;
          padding: 10px 0 0 30px;
        }
        @media (max-width: 1023px) {
          width: 45%;
          padding: 10px 0 0 30px;
        }

        .sub-giga-container-entry {
          margin-bottom: 12px;
          &:first-of-type {
            @media (min-width: 1024px) {
              width: calc(100% - 55px); // this 55 comes from the i-button
            }
          }
          .sub-giga-headline {
            font-weight: bold;
          }
          .sub-giga-text {
          }
        }
      }

      @media (max-width: 612px) {
        .i-button-placeholder {
          display: none !important;
        }
      }

      .i-button-placeholder {
        display: inline-block;
        visibility: hidden;
      }
    }
  }

  .interesting-buttons-container {
    margin: 20px 50px 0 50px;
    width: calc(100% - 100px);
    text-align: center;

    .tile-button {
      display: inline-block;
      margin: 10px;
      padding: 20px;
      width: 200px;
      height: 100px;
      vertical-align: middle;
      box-shadow: 4px 4px 10px 0 ${Color.GREY5};

      .tile-button-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        vertical-align: middle;
        height: 100%;
      }
    }
  }
`;
