import * as React from "react";

function SvgCyberSecurity(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="ICON"
      viewBox="0 0 192 192"
      {...props}
    >
      <path
        fill="none"
        stroke={`${color}`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        d="M96 20L36 33.41v77.32c0 6.88 4.88 14.31 10.8 22.1 5.25 6.89 12.68 13.94 22.08 21C80.62 162.53 90.19 169 96 172c5.82-3 15.38-9.47 27.12-18.21 9.41-7 16.83-14.07 22.07-21C151.11 125 156 117.61 156 110.73V33.41z"
      />
      <path
        fill="none"
        stroke={`${color}`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        d="M105.47 85a17.88 17.88 0 10-18.94 0l-8.41 40.21a6.36 6.36 0 006.7 6.79h22.35a6.39 6.39 0 006.71-6.77l-4.35-18.36z"
      />
      <path fill="none" d="M0 0h192v192H0z" />
    </svg>
  );
}

export default SvgCyberSecurity;
