import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import SVGIcon from "../svgIcon/SVGIcon";
import SVGIconGroup from "../svgIcon/SVGIconGroup";
import { SVGComponent } from "../../types/default";

interface IconTileButtonProps {
  className?: string;
  onChange?: (value: boolean) => void;
  value?: boolean;
  main?: boolean;
  icons?: SVGComponent[];
  imageSize?: number;
  shadowed?: boolean;
  color?: string;
}

const IconTileButton: React.FC<IconTileButtonProps> = ({
  className,
  children,
  onChange,
  value = false,
  icons = [],
  imageSize,
}) => {
  const onClick = (_: React.MouseEvent) => {
    if (onChange) {
      onChange(!value);
    }
  };

  return (
    <div className={className + " tile-button"} onClick={onClick}>
      {icons && (
        <div className={"tile-button-icons-container"}>
          {icons.length === 1 && (
            <SVGIcon src={icons[0]} alt={""} size={imageSize} />
          )}
          {icons.length > 1 && (
            <SVGIconGroup
              icons={icons}
              size={imageSize ? imageSize * 1.25 : undefined}
            />
          )}
        </div>
      )}
      {children && (
        <div className={"tile-button-content-container"}>
          <span className={"tile-button-content"}>{children}</span>
        </div>
      )}
    </div>
  );
};
const calculateSVGHeight = (props: IconTileButtonProps): string => {
  const { icons, imageSize } = props;
  let returnValue: number = 50;
  if (icons && icons.length > 1) {
    if (imageSize) {
      returnValue = imageSize * 1.25;
    }
  }
  if (imageSize) {
    returnValue = imageSize;
  }
  return String(returnValue) + "px";
};
export default styled(IconTileButton)`
  display: inline-block;
  height: 200px;
  max-height: 100px;
  min-width: 165px;
  width: 165px;
  max-width: 400px;

  margin: 10px;
  padding: 5px 15px;
  box-shadow: ${({ shadowed }) =>
    shadowed ? "2px 2px 6px 0" + Color.GREY_DARK2 : ""};

  vertical-align: bottom;
  text-align: center;

  //font-weight: bold;
  background-color: ${(props) =>
    props.color
      ? props.color
      : props.main
      ? Color.GREY2
      : props.value
      ? Color.RED
      : Color.GREY_LIGHT};

  user-select: none;
  cursor: ${({ onChange }) => (onChange ? "pointer" : "default")};
  overflow: hidden;

  .icon {
    display: block;
    margin: 0 auto;
  }
  .tile-button-icons-container {
    height: 75px;
  }
  .tile-button-content-container {
    display: block;
    width: 100%;

    height: calc(100% - ${calculateSVGHeight});
    position: relative;
    bottom: 5px;

    span {
      display: inline-block;
      //position: absolute;

      //left: 0;
      //bottom: 5px;
      width: 100%;
      //max-width: 400px;
      line-height: 20px;
      font-size: 20px;

      color: ${Color.WHITE};
      vertical-align: bottom;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
