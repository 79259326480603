import * as React from "react";

function SvgIot(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="iot_svg__ICON"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.iot_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className="iot_svg__st0"
        d="M109.6 159.1v205.6M364.9 164.7v200.9M259.6 364.9h-41.5c-30.6 0-32.6-17.2-32.6-38.3v-73.4c0-21.1 8.3-29.8 29.4-29.8h44.7c21.1 0 28.7 8.7 28.7 29.8v83c0 21-17.2 28.7-28.7 28.7zM301.1 159.9h127.6"
      />
      <circle className="iot_svg__st0" cx={250} cy={250} r={242.5} />
    </svg>
  );
}

export default SvgIot;
