import React from "react";
import styled from "styled-components";
import AvailabilityItem from "./AvailabilityItem";
import JustifiedColumnsContainer from "../../grid/JustifiedColumnsContainer";
import {
  Availability,
  Technology,
  TechnologyAvailability,
} from "../../../state/definitions/AvailabilityState";

interface AvailabilityGridProps {
  className?: string;
  data: Availability;
}

const showTechnologies = ["cable", "dsl", "fiber", "tv"];

const AvailabilityGrid: React.FC<AvailabilityGridProps> = ({
  className,
  data,
}) => {
  const technologyNames: Technology[] = Object.keys(data) as Array<
    keyof Availability
  >;
  const technologyAvailabilities: TechnologyAvailability[] = Object.values(
    data
  );

  return (
    <div className={className}>
      <JustifiedColumnsContainer alignItems={"flex-end"}>
        {technologyNames.map((v, i) => {
          if (!showTechnologies.includes(v)) {
            return null;
          }
          return (
            <AvailabilityItem
              key={i}
              name={v}
              data={technologyAvailabilities[i]}
            />
          );
        })}
      </JustifiedColumnsContainer>
    </div>
  );
};

export default styled(AvailabilityGrid)`
  display: block;
  font-size: 18px;
  /* max-width: 200px; */
`;
