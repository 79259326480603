import * as React from "react";

function SvgIotGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#4a4d4e" } = props;
  return (
    <svg
      id="iot_grey_svg__ICON"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.iot_grey_svg__st0{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className="iot_grey_svg__st0"
        d="M52 67.5V132M132 69.3v62.9M99 132H86c-9.6 0-10.2-5.4-10.2-12V97c0-6.6 2.6-9.3 9.2-9.3h14c6.6 0 9 2.7 9 9.3v26c0 6.6-5.4 9-9 9zM112 67.8h40"
      />
      <circle
        cx={96}
        cy={96}
        r={76}
        fill="none"
        stroke={color}
        strokeWidth={7.948}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgIotGrey;
