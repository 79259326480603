import { AppState } from "../../state/definitions/AppState";
import { BootstrapData } from "../../data/definitions/BootstrapData";
import { FormConfigState } from "../../state/definitions/FormConfigState";

export function processBootstrap(
  prevState: AppState,
  bootstrapData: BootstrapData
): AppState {
  const extendedFormConfig: FormConfigState = Object.assign(
    {},
    { ...bootstrapData.form }
  );

  return {
    ...prevState,
    bootstrap: {
      ...prevState.bootstrap,
      loading: false,
      isLoaded: true,
      formConfig: extendedFormConfig,
    },
  };
}
