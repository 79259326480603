import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import Navbar from "./components/navigation/Navbar";
import FormPage from "./components/pages/default/FormPage";
import StartPage from "./components/pages/StartPage";
import useBootstrap from "./hooks/useBootstrap";
import { SlidePages } from "./constants/SlidePages";
import useNavigationUpdater from "./hooks/useNavigationHelper/useNavigationUpdater";
import { useNavigation } from "./hooks/useNavigation";
import useAppReload from "./hooks/useAppHelper/useAppReload";
import { LoadableResources, loadConfigData } from "./helper/loadConfigData";
import { useRefEffect } from "./hooks/common/useRefHook";
import AppModals from "./components/elements/appModals/AppModals";
import useAuth from "./hooks/useAuth";
import LoginPage from "./components/pages/LoginPage";
import { startsWith } from "lodash";

export const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

const prodUrl = "https://des.vodafone.de";

const redirectUrl: string | undefined =
  process.env.NODE_ENV === "production" &&
  startsWith(window.location.href, prodUrl)
    ? process.env.REACT_APP_REDIRECT_PROD
    : process.env.REACT_APP_REDIRECT_TEST;
export const redirectBase: string =
  redirectUrl === undefined ? "/" : redirectUrl;
export const redirect: string = `${redirectBase}/?redirect_url=${window.location.origin}/api/web/auth/sso&appid=des`;

function App() {
  const { loading } = useBootstrap();
  const { forward } = useNavigationUpdater();
  const { slides, isChangeGuaranteeSlideShowing } = useNavigation();
  const { authenticated, logout } = useAuth();

  const [, requestAppReload] = useAppReload();

  // Check reload route every 6 Minutes
  useRefEffect(() => {
    const interval = setInterval(() => {
      // console.log("check update");
      loadConfigData(
        () => [true, true],
        LoadableResources.UPDATE_CHECK,
        requestAppReload
      )().catch((e) => {
        if (e.code && e.code === 401) {
          logout();
        }
      });
    }, 6 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!authenticated) {
    return <LoginPage redirect={redirect} />;
  }

  return (
    <div className="App">
      <Switch>
        <Route path="/" exact>
          <React.StrictMode>
            <StartPage loading={loading} />
          </React.StrictMode>
        </Route>
        <Route path="/slides">
          <FormPage
            slides={slides}
            urgentSlide={
              isChangeGuaranteeSlideShowing
                ? SlidePages.changeGuarantee.id
                : undefined
            }
            navbar={
              <React.StrictMode>
                <Navbar />
              </React.StrictMode>
            }
            forward={forward}
          />
        </Route>
      </Switch>
      <React.StrictMode>
        <AppModals />
      </React.StrictMode>
    </div>
  );
}

export default App;
