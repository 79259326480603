import * as React from "react";

function SvgBuddies(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;

  return (
    <svg
      id="buddies_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 38 38"
      xmlSpace="preserve"
      {...props}
    >
      <style>{`.buddies_svg__st1{fill: ${color} }`}</style>

      <path
        className="buddies_svg__st1"
        d="M33.9 25.9c-.7-4.5-3-6.8-5.4-7.7C30 17.4 31 15.8 31 14c0-2.6-2.2-4.8-4.8-4.8-2.6 0-4.8 2.2-4.8 4.8 0 1.8 1 3.4 2.5 4.2-.9.3-1.8.9-2.5 1.6-1.5 1.4-2.5 3.5-2.9 6.1 0 .3.1.5.4.5.3 0 .5-.1.5-.4.8-5.2 3.9-7.1 6.5-7.3h.6c1.4.1 2.8.7 3.9 1.7 1.3 1.3 2.2 3.2 2.5 5.5 0 .2.2.4.5.4h.1c.2.1.4-.1.4-.4M22.4 14c0-2.1 1.7-3.8 3.8-3.8 2.1 0 3.8 1.7 3.8 3.8 0 2-1.6 3.7-3.6 3.8h-.5c-1.9-.1-3.5-1.8-3.5-3.8"
      />
      <path
        className="buddies_svg__st1"
        d="M19.4 25.9c-.7-4.5-3-6.8-5.4-7.7 1.5-.8 2.5-2.4 2.5-4.2 0-2.6-2.2-4.8-4.8-4.8C9.1 9.2 7 11.3 7 14c0 1.8 1 3.4 2.5 4.2-.9.3-1.8.9-2.5 1.6-1.5 1.4-2.5 3.5-2.9 6.1 0 .3.1.5.4.5.3 0 .5-.1.5-.4.8-5.2 3.9-7.1 6.5-7.3h.6c1.4.1 2.8.7 3.9 1.7 1.3 1.3 2.2 3.2 2.5 5.5 0 .2.2.4.5.4h.1c.2.1.4-.1.3-.4M7.9 14c0-2.1 1.7-3.8 3.8-3.8 2.1 0 3.8 1.7 3.8 3.8 0 2-1.6 3.7-3.6 3.8h-.5C9.5 17.7 7.9 16 7.9 14"
      />
    </svg>
  );
}

export default SvgBuddies;
