import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import SVGIcon from "../../svgIcon/SVGIcon";
import ColorIcons from "../../../icons/ColorIcons";

interface LongTextDisplayProps {
  className?: string;
  label: string;
}

const CheckCircleText: React.FC<LongTextDisplayProps> = ({
  className,
  label,
}) => (
  <div className={className}>
    <span className={"check-container"}>
      <SVGIcon src={ColorIcons.checkCircle} size={40} />
    </span>
    <div className={"text-container"}>
      {label && <h4 className={"label"}>{label}</h4>}
    </div>
  </div>
);

export default styled(CheckCircleText)`
  display: inline-block;
  width: 100%;
  max-width: 420px;
  padding: 10px;
  margin: 4px;
  vertical-align: top;

  .check-container {
    vertical-align: middle;
    display: inline-block;
    text-align: left;
    color: ${Color.RED};
    font-weight: bold;
  }

  .text-container {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 40px);
    padding-left: 5px;
    .label {
      position: relative;

      color: ${Color.RED};
      font-weight: bold;

      text-align: left;
      font-size: 18px;
    }
  }
`;
