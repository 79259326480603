import * as React from "react";

function SvgChat(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="chat_svg__a8ca3c22-55a8-4161-8674-5dd9953f60b6"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.chat_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        id="chat_svg___x38_b647ed5-50b9-46ea-aeca-c922f27b9e6b"
        className="chat_svg__st0"
        d="M400.5 190.3l-7.1-2.1c-19.6-5.1-39.8-7-60-5.7-20.3 1.2-40.2 5.6-59.1 13.1-17.3 6.8-33.3 16.5-47.3 28.7-12.9 11.1-23.4 24.7-30.9 40.1-7.1 14.7-10.4 31-9.6 47.3.8 16.3 5.7 32.1 14.2 45.9 8.9 14.3 20.8 26.5 34.7 36 15.2 10.4 32.1 18 49.9 22.6 19.6 5.1 39.8 7 60 5.7h1.9c6.8 4.6 14 8.8 21.4 12.4 9 4.4 18.4 7.9 28.1 10.6 11.2 3 22.7 4.7 34.3 5.2 13.4.6 26.8-.5 39.9-3.2 0 0-41.9-24.3-43.1-49.4 9.9-5.8 19.1-12.7 27.5-20.5 7.9-7.4 14.9-15.8 20.7-24.9 5.6-8.8 9.9-18.3 12.8-28.3 7.3-25.9 3.3-53.7-10.9-76.5-5.8-9.4-12.9-17.9-21.1-25.3-16.9-13.4-36-24-56.4-31.3"
      />
      <path
        id="chat_svg___x37_3e43e76-3723-4c82-a239-629e1c0146f9"
        className="chat_svg__st0"
        d="M395 188.7s.9-4.5 1.1-6c4.9-29.9-15.2-58.4-15.2-58.4-11.4-18.2-26.6-33.9-44.4-45.9-19.5-13.2-41-22.9-63.8-28.7-25.1-6.6-51.1-9.1-76.9-7.4-25.9 1.5-51.4 7.1-75.6 16.6-22.1 8.6-42.6 21-60.6 36.5-16.5 14.1-30 31.5-39.5 51C11 165.2 6.7 186 7.6 206.8c.7 13.1 3.5 26.1 8.3 38.3 4.9 12.2 11.5 23.6 19.8 33.9 8.6 10.7 18.5 20.2 29.6 28.4 11.3 8.4 34.9 18.6 48.6 24.6-17.8 41.8-59.4 66.4-59.4 66.4 17 1.4 34.2.7 51-2 11.9-2 23.7-5.1 35.1-9.2 0 0 14.1-5 54.2-27.2l5.2-2.8"
      />
    </svg>
  );
}

export default SvgChat;
