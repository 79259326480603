import React from "react";
import styled from "styled-components";
import PanelItem from "../../panel/PanelItem";
import PanelValue from "../../panel/PanelValue";
import GreyIcons from "../../../icons/GreyIcons";
import { TVSituation } from "../../../types/profile";
import { prettyDate, prettyMoney } from "../../../helper/getPrettyStrings";

interface PanelItemsTVSituationProps {
  className?: string;
  situation: TVSituation;
}

const PanelItemsTVSituation: React.FC<PanelItemsTVSituationProps> = ({
  situation,
}) => (
  <>
    {situation.provider !== undefined && (
      <PanelItem icon={GreyIcons.worldNet}>
        <PanelValue>{situation.provider}</PanelValue>
      </PanelItem>
    )}
    {situation.price !== undefined && situation.price > 0 && (
      <PanelItem icon={GreyIcons.price}>
        Preis: <PanelValue>{prettyMoney(situation.price)}</PanelValue>
      </PanelItem>
    )}
    {situation.currentContractEndDate !== undefined && (
      <PanelItem icon={GreyIcons.calendar}>
        Ende Vertragslaufzeit{" "}
        <PanelValue>{prettyDate(situation.currentContractEndDate)}</PanelValue>
      </PanelItem>
    )}
  </>
);

export default styled(PanelItemsTVSituation)``;
