import React from "react";
import Slider from "../form/Slider";
import ProviderQuestionBlock from "../form/ProviderQuestionBlock";
import useBootstrap from "../../hooks/useBootstrap";
import { useFormState } from "../../hooks/useForm";
import { useProviderQuestion } from "../../hooks/useFormHelper/useProviderQuestion";
import IButton from "../form/IconButton";
import { useNavigation } from "../../hooks/useNavigation";

const TVCurrentConditionsPage = () => {
  const { formConfig } = useBootstrap();

  // Setup slider values
  const [tvCurrentPrice, setTvCurrentPrice] = useFormState("tvCurrentPrice");
  const tvCurrentPriceValue = tvCurrentPrice ? tvCurrentPrice : 0;

  // Setup providerQuestionState
  const [
    providerIdState,
    currentContractEndDateState,
    withoutCurrentContractEndState,
  ] = useProviderQuestion(
    "providerTvId",
    "tvContractCurrentEnd",
    "tvWithoutCurrentContractEnd"
  );

  const { setIsChangeGuaranteeSlideShowing } = useNavigation();

  const openGuaranteePage = () => {
    setIsChangeGuaranteeSlideShowing(true);
  };

  return (
    <>
      <Slider
        min={0}
        max={150}
        value={tvCurrentPriceValue}
        onChange={setTvCurrentPrice}
        afterValue={"€"}
        description={"Preis"}
      />

      <ProviderQuestionBlock
        contractEndDateState={currentContractEndDateState}
        withoutCurrentContractEndState={withoutCurrentContractEndState}
        selectedProviderState={providerIdState}
        providers={formConfig.tvProviders}
      >
        <IButton onChange={openGuaranteePage} value={true} />
      </ProviderQuestionBlock>
    </>
  );
};

export default TVCurrentConditionsPage;
