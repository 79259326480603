import { DatabaseCategoryTypes } from "./DatabaseCategoryTypes";
import { DatabaseProductTypes } from "./DatabaseProductTypes";
import { DatabaseProductPriceType } from "./DatabaseProductPriceType";

export class DatabaseProduct {
  productId: number = -1; // ID
  productName: string = "";
  productCategory: DatabaseCategoryTypes = DatabaseCategoryTypes.unknown; // "tariff" || "option" || "datatariff" || "plustariff" || ?
  productType: DatabaseProductTypes = DatabaseProductTypes.unknown; // "mobile" || "iot" || "fixed"  || "tv"
  combiRebate: boolean = false;
  combiPush: boolean = false;
  productPriceId: number = -1; // ID (?)
  productBasicPrice: number = 0.0; // is a float number
  productPriceUnit: string = "€"; // "€"
  productPriceType: DatabaseProductPriceType = DatabaseProductPriceType.MONTHLY; // ID (1=monatlich, 2=einmalig)
  productProperty01: number = 0; // ID (Vorteil)
  productProperty02: number = 0; // ID (Vorteil)
  productProperty03: number = 0; // ID (Vorteil)
  productProperty04: number = 0; // ID (Vorteil)
  productProperty05: number = 0; // ID (Vorteil)
  minContractTerm: number = 0; // Mindestlaufzeit (immer 0)
  productDataVolumeIncluded: number = 0; // Datenvolumen (nur für Mobile)
  ncRebate: number = 0.0; // is a float number (Einmaliger Rabattpreis mit monatlicher Laufzeit)
  ncRebateDuration: number = 0; // (Monatliche Laufzeit für den einmaligen Rabattpreis)
  productOffered: boolean = false;

  constructor(
    productName?: string,
    combiRebate?: boolean,
    combiPush?: boolean,
    productType?: DatabaseProductTypes
  ) {
    this.productName = productName !== undefined ? productName : "";
    this.combiRebate = combiRebate !== undefined ? combiRebate : false;
    this.combiPush = combiPush !== undefined ? combiPush : false;
    if (productType !== undefined) {
      this.productCategory = DatabaseCategoryTypes.tariff;
      this.productType = productType;
    }
  }
}
