import { useContext, useMemo } from "react";
import { AppContext } from "../../state/AppContext";
import { useInterestsValues } from "../useFormHelper/useInterests";
import { combineMonthlyPriceArray } from "../../helper/priceCalculation/combineMonthlyPriceArray";
import { MonthlyPriceArray, RecommendationMode } from "../../types/application";
import { getMonthlyPriceArrayFromOfferEntry } from "../../helper/offer/getMonthlyPriceArrayFromOfferEntry";
import { useNewOffer } from "../useNewOffer";
import { getModeKeyFromInterest } from "../../helper/recommendation/mapping";

const useEndPrice = (): [MonthlyPriceArray, number] => {
  const [state] = useContext(AppContext);
  const recommendationModes = useInterestsValues();

  const modesValues = Object.values(recommendationModes).join("");
  const offerEntryIdsJoin = useMemo(
    () => state.offer.offerEntryIds.join(""),
    [state.offer.offerEntryIds]
  );
  const { getSelectedEntryProperty } = useNewOffer();

  const filteredOfferEntryIds = useMemo(() => {
    return getSelectedEntryProperty("rowId", (entry) => {
      const interest = entry.type;
      const modeKey = getModeKeyFromInterest(interest);
      return recommendationModes[modeKey] === RecommendationMode.SELECTABLE;
    });
  }, [offerEntryIdsJoin]);

  const filteredOfferEntryIdsJoin = useMemo(
    () => filteredOfferEntryIds.join(""),
    [filteredOfferEntryIds]
  );

  let wholeOncePrice = 0;

  // TODO: Find out, why the memo will execute twice,
  //  although there are no change on dpd, because possibility of performance issues
  return useMemo((): [MonthlyPriceArray, number] => {
    const priceArrays = state.offer.offerEntryIds.map((id) => {
      wholeOncePrice += state.recommendation.priceInformation[id]?.once ?? 0;
      return getMonthlyPriceArrayFromOfferEntry(
        state.newOffer[id],
        state.recommendation,
        recommendationModes
      );
    });
    return [combineMonthlyPriceArray(priceArrays), wholeOncePrice];
  }, [
    state.recommendation.loading,
    state.recommendation.priceInformation,
    modesValues,
    state.offer.selectedGigaKombi,
    state.offer.gigaKombiLabelId,
    state.offer.regioDiscountLabelId,
    filteredOfferEntryIdsJoin,
  ]);
};

export default useEndPrice;
