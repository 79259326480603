import React from "react";
import styled from "styled-components";
import { AddressResult } from "../../../state/definitions/AvailabilityState";
import AddressRow from "./AddressRow";
import { Color } from "../../../constants/color";

interface AddressListProps {
  className?: string;
  addresses: AddressResult[];
  onSelectAddress: (address: AddressResult) => void;
}

const AddressList: React.FC<AddressListProps> = ({
  addresses,
  className,
  onSelectAddress,
}) => {
  return (
    <div className={className}>
      <div className="title">Suchergebnisse:</div>
      {addresses.map((address) => (
        <AddressRow
          key={address.addressId}
          address={address}
          onClick={onSelectAddress}
        />
      ))}
    </div>
  );
};

export default styled(AddressList)`
  margin: auto;
  max-width: 800px;
  .title {
    font-size: 25px;
    padding-bottom: 8px;
    color: ${Color.RED};
    text-align: left;
  }
`;
