import * as React from "react";

function SvgGamingColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="gaming_color_svg__ICON"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".gaming_color_svg__st0{fill:none;stroke:#333;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <path
        className="gaming_color_svg__st0"
        d="M489.7 314.3c-2.1-8-66.6-214.6-66.9-215.4l-.1-.3c-8.3-20.2-75.7-22.2-103.8-22.2-28.1 0-36.9 5.1-45.2 25.4h-49.5c-8.3-20.2-16.7-25.4-44.8-25.4s-93.1 2-101.5 22.2l-.1.3c-.3.7-64.8 209.1-66.5 215.4-12.7 47.6 9.5 117.3 76.1 117.3 85.6 0 114.7-45.7 123.7-88.8h76.1c9 43.1 38.1 88.8 123.2 88.8 67.1 0 92-69.8 79.3-117.3zM147.7 177.9v101.5M198.4 228.6H96.9"
      />
      <circle className="gaming_color_svg__st0" cx={350.6} cy={266.7} r={6.3} />
      <circle className="gaming_color_svg__st0" cx={350.6} cy={266.7} r={3.2} />
      <circle className="gaming_color_svg__st0" cx={350.6} cy={190.6} r={6.3} />
      <circle className="gaming_color_svg__st0" cx={350.6} cy={190.6} r={3.2} />
      <circle className="gaming_color_svg__st0" cx={312.6} cy={228.6} r={6.3} />
      <circle className="gaming_color_svg__st0" cx={312.6} cy={228.6} r={3.2} />
      <circle className="gaming_color_svg__st0" cx={388.7} cy={228.6} r={6.3} />
      <circle className="gaming_color_svg__st0" cx={388.7} cy={228.6} r={3.2} />
    </svg>
  );
}

export default SvgGamingColor;
