import { useCurrentProducts } from "../useDatabaseProducts";
import { useOfferState } from "../useOffer";
import { useInterestsValues } from "../useFormHelper/useInterests";
import { getGigaKombiAdditionalTableObject } from "../../helper/table/getGigaKombiAdditionalTableObject";
import { useMemo } from "react";
import { Interest } from "../../types/application";
import { useGigaKombiWiths } from "./useGigaKombiWiths";
import { FormConfigLabelIds } from "../../data/definitions/FormConfigLabelIds";
import { getIsGigaKombiPossible } from "../../helper/gigakombi/getIsGigaKombiPossible";
import { getGigaSpecialValues } from "../../helper/gigakombi/getGigaSpecialValues";
import { DISABLE_CYBER_ADVANTAGE } from "../../constants/default";

// TODO: Change to a hook with function return
const useGigaKombi = (
  type: Interest,
  withDataVolumeAndAdvantages?: boolean
) => {
  const { getGigaKombiWiths, currentIds } = useGigaKombiWiths();
  const withsObject = getGigaKombiWiths();
  const { getSelectedProducts } = useCurrentProducts();
  const selectedProducts = useMemo(
    () => getSelectedProducts(currentIds),
    [currentIds]
  );

  const recommendationModes = useInterestsValues();
  const [isGigaKombiPossible, gigaKombiType] = getIsGigaKombiPossible(
    withsObject,
    recommendationModes
  );

  const [gigaKombiLabelId] = useOfferState("gigaKombiLabelId");
  const [gigaKombiDataVolume] = useOfferState("gigaKombiDataVolume");
  const [gigaKombiWithSky] = useOfferState("gigaKombiWithSky");
  const [gigaKombiCalculatedDiscount] = useOfferState(
    "gigaKombiCalculatedDiscount"
  );

  const gigaAdvantages: number[] = [];
  const discountLabels = ["Kein Rabatt"];
  const discountPrices = [0];
  let returnExtraDataVolume = 0;

  if (isGigaKombiPossible) {
    const { withFlatrate, withCyber } = getGigaSpecialValues(
      withsObject,
      [gigaKombiCalculatedDiscount, gigaKombiDataVolume, gigaKombiWithSky],
      selectedProducts
    );
    if (type === gigaKombiType) {
      if (gigaKombiCalculatedDiscount && gigaKombiCalculatedDiscount > 0) {
        discountLabels.push(
          "GigaKombi mit " + gigaKombiCalculatedDiscount + " €/mtl. Rabatt"
        );
        discountPrices.push(-gigaKombiCalculatedDiscount);
      }
      if (gigaKombiWithSky) {
        const skyDiscountPrice = Math.max(0, gigaKombiCalculatedDiscount - 5);
        discountLabels.push(
          "GigaKombi mit " + skyDiscountPrice + " €/mtl. Rabatt und Sky"
        );
        discountPrices.push(-1 * skyDiscountPrice);

        if (withDataVolumeAndAdvantages === true) {
          returnExtraDataVolume = gigaKombiDataVolume;
        }
      }
    }

    // the gigaAdvantages are not restricted on the current gigakombi type
    if (withFlatrate && withDataVolumeAndAdvantages) {
      gigaAdvantages.push(FormConfigLabelIds.GIGAKOMBI_MOBILE_EURO_FLAT);
    }
    if (withCyber && withDataVolumeAndAdvantages && !DISABLE_CYBER_ADVANTAGE) {
      gigaAdvantages.push(FormConfigLabelIds.GIGAKOMBI_CYBER_SECURITY);
    }
    gigaAdvantages.push(FormConfigLabelIds.GIGAKOMBI);
  }

  const gigaKombiAdditionalObject = getGigaKombiAdditionalTableObject(
    discountLabels,
    discountPrices,
    gigaKombiLabelId
  );

  return {
    extraGigaKombiDataVolume: returnExtraDataVolume,
    gigaKombiAdditionalObject,
    gigaKombiType,
    gigaAdvantages,
  };
};

export default useGigaKombi;
