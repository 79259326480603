import * as React from "react";

function SvgHome(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <path
        d="M441.4 237.2h51.1l-51.1-51.4V33h-76.6v76.6l-86.3-91.4c-13.7-14.5-44-14-57.1 0l-214 219h51.1V467c0 14.1 11.4 25.5 25.5 25.5h204.2V339.3h-76.6v153.2h198.2c14.1 0 31.5-14.6 31.5-28.7V237.2"
        fill="none"
        stroke={color}
        strokeWidth={24.173}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgHome;
