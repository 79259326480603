import * as React from "react";

function SvgUnder28(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="under28_svg__ICON"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.under28_svg__st0{stroke-miterlimit:10}.under28_svg__st0,.under28_svg__st1{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <g id="under28_svg__Mobile_x5F_ic_4_">
        <path
          className="under28_svg__st0"
          d="M235.8 441.4h76.6M133.7 135.1V45.8c0-21.1 17.2-38.3 38.3-38.3h204.2c21.1 0 38.3 17.2 38.3 38.3v408.4c0 21.1-17.2 38.3-38.3 38.3H172c-21.1 0-38.3-17.2-38.3-38.3V313.8"
        />
      </g>
      <path
        className="under28_svg__st1"
        d="M133.7 186.2l-70.2 38.3 70.2 38.3M261.3 288.3h-76.6c0-12.1 5.4-23.9 14.7-31.9l44.7-38c7.7-5.4 12.1-14.4 12.4-23.6 0-17.9-16.6-34.1-37.3-34.1-12.8-.3-24.6 6.1-31 16.9"
      />
      <ellipse
        className="under28_svg__st1"
        cx={331.5}
        cy={256.1}
        rx={33.5}
        ry={32.2}
      />
      <ellipse
        className="under28_svg__st1"
        cx={332.5}
        cy={192.2}
        rx={31}
        ry={31.6}
      />
    </svg>
  );
}

export default SvgUnder28;
