import React from "react";
import styled from "styled-components";
import ImageWithIcons from "../elements/ImageWithIcons";
import { getIcons } from "../../icons/Icons";
import { FormStateNumberArrays } from "../../state/definitions/FormState";
import Column from "../grid/Column";
import { getImageSet } from "../../images";
import TileCheckboxList, { CheckBoxExtraHandlerFct } from "./TileCheckboxList";
import { FormOption } from "../../state/definitions/FormConfigState";
import { useFormState } from "../../hooks/useForm";
import { ZIndex } from "../../constants/zIndex";

interface CheckboxListColumnProps {
  className?: string;
  valueKey: keyof FormStateNumberArrays;
  category: FormOption;
  data: FormOption[];
  colCount?: number;
  checkboxExtraHandler?: Array<CheckBoxExtraHandlerFct | undefined>;
}

const CheckboxListColumn = ({
  className,
  valueKey,
  category,
  data,
  colCount = 1,
  checkboxExtraHandler,
}: CheckboxListColumnProps) => {
  const [values, setValues] = useFormState(valueKey);
  return (
    <Column
      className={className}
      colCount={colCount}
      minWidth={"305px"}
      maxWidth={100 / colCount + "%"}
    >
      <ImageWithIcons
        imageSet={getImageSet(category.value)}
        icons={getIcons(category.value)}
      />
      <TileCheckboxList
        title={category.label}
        data={data}
        values={values ? values : []}
        onChange={setValues}
        checkboxExtraHandler={checkboxExtraHandler}
      />
    </Column>
  );
};

export default styled(CheckboxListColumn)`
  .i-button-container {
    z-index: ${ZIndex.FRONT_1};
    position: absolute;
    right: 0;
    bottom: 0;
    .tile-button {
      height: unset;
      padding: 0;
    }
  }
`;
