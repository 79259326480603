import { DisplayErrorMessages } from "../../constants/DisplayErrorMessages";
import { useGigaKombiWiths } from "./useGigaKombiWiths";
import { useInterestsValues } from "../useFormHelper/useInterests";
import { useOfferState } from "../useOffer";
import { useContext } from "react";
import { AppContext } from "../../state/AppContext";
import { GigaKombiInfoTexts } from "../../components/elements/recommendationPage/gigakombi/GigaKombiInfoModal";
import { Interest, RecommendationMode } from "../../types/application";
import { useNewOffer } from "../useNewOffer";
import { getModeKeyFromInterest } from "../../helper/recommendation/mapping";
import { isGigaKombiWithOfferEntriesPossible } from "../../helper/isGigaKombiWithOfferEntriesPossible";
import { getCircleDataFromGigaValues } from "../../helper/gigakombi/getCircleDataFromGigaValues";

export const useNewGigaKombi = () => {
  const [state] = useContext(AppContext);
  const { getCurrentMainOfferEntries } = useNewOffer();
  const { getGigaKombiWiths, currentProducts } = useGigaKombiWiths();
  const { mobileMode, iotMode, fixedMode, tvMode } = useInterestsValues();
  const [, setSelectedGigaKombi] = useOfferState("selectedGigaKombi");
  const [, setGigaInfoText] = useOfferState("gigaInfoText");
  const [, setGigaErrorText] = useOfferState("gigaErrorText");

  const getWithsCount = () => {
    const withsObject = getGigaKombiWiths();
    return Object.values(withsObject).reduce((res, i) => {
      return res + (i ? 1 : 0);
    }, 0);
  };
  const getGigaKombiType = () => {
    if (mobileMode === RecommendationMode.SELECTABLE) {
      return Interest.MOBILE;
    }
    if (fixedMode === RecommendationMode.SELECTABLE) {
      return Interest.FIXED;
    }
  };

  const getCircleData = () => {
    const {
      gigaKombiCalculatedDiscount,
      gigaKombiDataVolume,
      gigaKombiWithSky,
    } = state.offer;
    const withsObject = getGigaKombiWiths();
    return getCircleDataFromGigaValues(
      withsObject,
      [gigaKombiCalculatedDiscount, gigaKombiDataVolume, gigaKombiWithSky],
      currentProducts
    );
  };

  const changeGigaKombi = (newValue: boolean) => {
    const withsCount = getWithsCount();
    if (withsCount > 1) {
      let changeValueReally = false;
      const gigaKombiType = getGigaKombiType();
      const currentOfferEntries = getCurrentMainOfferEntries();

      if (gigaKombiType !== undefined) {
        const gigaKombiOfferEntry =
          currentOfferEntries[getModeKeyFromInterest(gigaKombiType)];
        if (gigaKombiOfferEntry !== undefined) {
          changeValueReally = isGigaKombiWithOfferEntriesPossible(
            state.recommendation.config,
            gigaKombiOfferEntry,
            newValue
          );
        }
      }

      const { circleData } = getCircleData();
      if (changeValueReally && circleData.length > 0) {
        setSelectedGigaKombi(newValue);
      } else {
        if (changeValueReally) {
          setGigaErrorText(DisplayErrorMessages.noAdvantage);
        }
        if (circleData.length > 0) {
          setGigaErrorText(DisplayErrorMessages.maxRebates);
        }
      }
    } else {
      if (withsCount > 0 && withsCount < 2) {
        setGigaInfoText(GigaKombiInfoTexts.tooLowInterests);
      } else {
        setGigaInfoText(GigaKombiInfoTexts.falseProduct);
      }
    }
  };
  return { changeGigaKombi };
};
