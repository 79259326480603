import { calculateWithValue } from "../../helper/gigakombi/calculateWithValue";
import { useInterestsValues } from "../useFormHelper/useInterests";
import { useCurrentProducts } from "../useDatabaseProducts";
import {
  getModeOfInterest,
  getProductOfInterest,
} from "../../helper/recommendation/mapping";
import { useMemo } from "react";
import { Interest, WithsObject } from "../../types/application";

export const useGigaKombiWiths = () => {
  const InterestValues = useInterestsValues();
  const { getCurrentIds, getSelectedProducts } = useCurrentProducts();
  const currentIds = getCurrentIds();
  const currentProducts = useMemo(
    () => getSelectedProducts(currentIds),
    [currentIds]
  );

  const getGigaKombiWiths = () => {
    const withsObject: WithsObject = {
      mobile: false,
      iot: false,
      fixed: false,
      tv: false,
    };
    Object.values(Interest).forEach((type: Interest) => {
      const product = getProductOfInterest(type, currentProducts);
      if (product && product.productId > -1) {
        const mode = getModeOfInterest(type, InterestValues);
        withsObject[type] = calculateWithValue(product, mode);
      }
    });
    return withsObject;
  };

  return {
    getGigaKombiWiths,
    currentProducts,
    currentIds,
  };
};
