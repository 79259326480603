import React from "react";
import styled from "styled-components";
import BubbleImage from "./BubbleImage";
import { Color } from "../../../constants/color";
import { SVGComponent } from "../../../types/default";

interface BubbleWithTextProps {
  className?: string;
  head: string;
  content?: string;
  icon?: SVGComponent;
}

const BubbleWithText: React.FC<BubbleWithTextProps> = ({
  className,
  head,
  content = 0,
  icon,
  children,
}) => (
  <div className={className}>
    {!!icon && <BubbleImage icon={icon} />}
    {children}
    <p className={"head"}>{head}</p>
    {!!content && <p className={"content"}>{content}</p>}
  </div>
);

export default styled(BubbleWithText)`
  width: 120px;
  display: inline-block;
  text-align: center;
  margin: 15px 50px;

  p.head {
    display: inline-block;
    color: ${Color.RED};
    font-weight: bold;
    margin-top: 5px;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p.content {
    display: inline-block;
    color: ${Color.BLACK};
    white-space: pre-wrap;
  }
`;
