import React from "react";
import styled from "styled-components";

export interface ColumnProps {
  extraClassName?: string;
  className?: string;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  flexBasis?: string;
  colCount?: number;
  withoutBottomMargin?: boolean;
  zIndex?: number | string;
  textAlign?: "center" | "left" | "right" | "initial";
  onClick?: () => void;
}

const Column: React.FC<ColumnProps> = ({
  children,
  className,
  extraClassName = "",
  onClick,
}) => {
  return (
    <div
      className={`column ${className} ${extraClassName}`}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {children}
    </div>
  );
};

export default styled(Column)`
  position: relative;
  display: block;
  width: ${({ width }) => (width ? width : "initial")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "250px")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "400px")};
  flex-basis: ${({ colCount, flexBasis }) =>
    colCount
      ? "calc(" + (100 / colCount).toFixed(0) + "% - 2px)"
      : flexBasis ?? "auto"};
  flex-grow: 1;
  margin: 1px 1px
    ${({ withoutBottomMargin }) => (withoutBottomMargin ? "1px" : "10px")} 1px;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "initial")};

  &:first-child(1) {
    margin-left: 0;
  }
  &:last-child(1) {
    margin-right: 0;
  }
`;
