import { DatabaseCategoryTypes } from "../../data/definitions/DatabaseCategoryTypes";

export function getProductCategoryFromString(
  typeString: string
): DatabaseCategoryTypes {
  switch (typeString) {
    case DatabaseCategoryTypes.tariff:
      return DatabaseCategoryTypes.tariff;
    case DatabaseCategoryTypes.option:
      return DatabaseCategoryTypes.option;
    case DatabaseCategoryTypes.pass:
      return DatabaseCategoryTypes.pass;
    case DatabaseCategoryTypes.datatariff:
      return DatabaseCategoryTypes.datatariff;
    case DatabaseCategoryTypes.plustariff:
      return DatabaseCategoryTypes.plustariff;
    default:
      return DatabaseCategoryTypes.unknown;
  }
}
