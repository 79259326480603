import profile from "./svgComponents_grey/AccelerometerHalfGrey";
import accelerometerHalf from "./svgComponents_grey/AccelerometerHalfGrey";
import cable from "./svgComponents_grey/CableGrey";
import devices from "./svgComponents_grey/DevicesGrey";
import landline from "./svgComponents_grey/FixedLineGrey";
import euro from "./svgComponents_grey/EuroGrey";
import mobile from "./svgComponents_grey/MobileGrey";
import worldNet from "./svgComponents_grey/WorldNetGrey";
import mobileAndTablet from "./svgComponents_grey/MobileAndTabletGrey";
import tv from "./svgComponents_grey/TvGrey";
import mobileWorking from "./svgComponents_grey/MobileWorkingGrey";
import person from "./svgComponents_grey/PersonGrey";
import tablet from "./svgComponents_grey/TabletGrey";
import under28 from "./svgComponents_grey/Under28Grey";
import users from "./svgComponents_grey/UsersGrey";
import watch from "./svgComponents_grey/WatchGrey";
import accelerometerHalf_color from "./svgComponents_grey/AccelerometerHalfGreyColor";
import businessCustomer from "./svgComponents_grey/BusinessCustomerGrey";
import calendar from "./svgComponents_grey/CalendarGrey";
import children from "./svgComponents_grey/ChildsGrey";
import expandScreen from "./svgComponents_grey/ExpandScreenGrey";
import family from "./svgComponents_grey/FamilyGrey";
import iot from "./svgComponents_grey/IotGrey";
import firstPrice from "./svgComponents_grey/FirstPriceGrey";
import unlimited from "./svgComponents_grey/UnlimitedGrey";
import gigaDepot from "./svgComponents_grey/GigaDepotGrey";
import gigaKombi from "./svgComponents_grey/GigaKombiGrey";
import mobileUnlimited from "./svgComponents_grey/MobileUnlimitedGrey";
import speed from "./svgComponents_grey/SpeedGrey";
import telefonFlat from "./svgComponents_grey/TelefonFlatGrey";

const GreyIcons = {
  profile,
  connectionType: cable,
  devices,
  landline,
  price: euro,
  mobile,
  worldNet,
  mobileAndTablet,
  tv,
  mobileWorking,
  person,
  tablet,
  under28,
  users,
  watch,
  accelerometerHalf,
  accelerometerHalf_color,
  businessCustomer,
  calendar,
  children,
  expandScreen,
  family,
  iot,
  firstPrice,
  unlimited,
  gigaDepot,
  gigaKombi,
  mobileUnlimited,
  speed,
  telefonFlat,
};

export default GreyIcons;
