import { useContext } from "react";
import { AppContext } from "../../state/AppContext";

type StateLikeHook = () => [boolean, () => void];

const useAppReload: StateLikeHook = () => {
  const [state, setState] = useContext(AppContext);

  const requestAppReload = () => {
    setState((prev) => ({
      ...prev,
      requestAppReload: true,
    }));
  };

  return [state.requestAppReload, requestAppReload];
};

export default useAppReload;
