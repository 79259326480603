import React from "react";
import styled from "styled-components";
import vodafone from "../../images/icon.png";
import { Color } from "../../constants/color";
import SVGIcon from "../svgIcon/SVGIcon";
import { SVGComponent } from "../../types/default";

interface PassCounterProps {
  className?: string;
  image: SVGComponent;
  label?: string;
  selected?: boolean;
  price?: number;
  onClick?: () => void;
}

const PassCounter: React.FC<PassCounterProps> = ({
  className,
  image,
  label,
  onClick,
  price,
}) => (
  <div className={className} onClick={onClick}>
    <div className={"border-container"}>
      <div className={"pass-container"}>
        <div className={"image-label-slant-container"}>
          <span className={"red-slant"} />
          <div className={"image-label-container"}>
            <div className={"label-container"}>
              <p className={"label"}>
                {label} <span className={"pass-label"}>PASS</span>
              </p>
              <img
                className={"vodafone-icon"}
                src={vodafone}
                alt={"vodafone"}
              />
            </div>
            <div className={"image-container"}>
              <SVGIcon src={image} size={50} />
            </div>
          </div>
        </div>
        <div className={"count-label-container"}>
          <div className={"count-label-placeholder"} />
          <span className={"count-label"}>1</span>
        </div>
      </div>
    </div>
    <span className={"price"}>{price === 0 ? "kostenlos" : price + " €"}</span>
  </div>
);

export default styled(PassCounter)`
  width: 200px;
  border-left: 12px;
  border-right: 12px;
  border-top: 12px;
  border-bottom: 3px;
  border-style: solid;
  border-radius: 10%;
  border-color: ${({ selected }) => (selected ? Color.RED : "transparent")};
  background: ${({ selected }) => (selected ? Color.RED : "transparent")};
  margin: 10px;
  display: inline-block;
  text-align:left;

  :hover {
    cursor: pointer;
  }
  .border-container {
    border-width: 3px;
    border-style: solid;
    border-radius: 5%;
    border-color: ${({ selected }) => (selected ? Color.WHITE : "transparent")};
    background: ${({ selected }) => (selected ? Color.WHITE : "transparent")};

    .pass-container {
      display: table;
      //padding: 3px;

      .image-label-slant-container {
        height: 100px;
        width: 100%;
        display: table-cell;
        position: relative;
        background: ${Color.WHITE};
        border-radius: 5px;

        .red-slant {
          width: 0;
          height: 0;
          position: absolute;
          background: ${Color.RED};
          border-style: solid;
          border-width: 100px 70px 0 52px;
          border-color: ${Color.RED} ${Color.WHITE} ${Color.RED} ${Color.RED};
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        .image-label-container {
          position: relative;
          display: table;
          height: 100%;
          width: 100%;

          .label-container {
            display: table-cell;
            background: transparent;
            vertical-align: top;
            text-align: left;
            width: 60%;
            height: 100px;
            padding: 5px;
            font-size: 25px;

            .vodafone-icon {
              display: inline-block;
              max-height: 25px;
              max-width: 54px;
              margin-top: 5px;
            }

            .label {
              color: ${Color.WHITE};
            }

            .pass-label {
              font-weight: bold;
              display: inline-block;
              width: 100%;
            }
          }

          .image-container {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
          }
        }
      }

      .count-label-container {
        display: table-cell;
        vertical-align: middle;
        background: ${Color.WHITE};
        color: ${Color.RED};
        font-weight: bold;
        position: relative;
        border-radius: ${({ selected }) => (selected ? "0" : "5px")};

        .count-label-placeholder {
          height: calc(${({ selected }) => (selected ? "100%" : "100% - 8px")});
          height: -moz-calc(${({ selected }) =>
            selected ? "100%" : "100% - 8px"});
          height: -webkit-calc${({ selected }) =>
            selected ? "100%" : "100% - 8px"});
          height: -o-calc(${({ selected }) =>
            selected ? "100%" : "100% - 8px"});
          width: 100%;
          background: transparent;
          position: absolute;
          top: ${({ selected }) => (selected ? "0" : "4px")};
          left: -0.5px;
          border-left: 1px ${Color.RED} dotted;
        }
        .count-label {
          color: ${({ selected }) =>
            selected ? Color.RED : Color.GREY_LIGHT2};
          padding-left: 4px;
        }
      }
    }
  }

  .price {
    width: 100%;
    display: block;
    background: ${({ selected }) => (selected ? Color.RED : Color.GREY_LIGHT6)};
    text-align: center;
    color: ${Color.WHITE};
    font-size: 25px;
    margin-top: 3px;
    border-radius: 8px;
  }
`;
