import React, { useEffect } from "react";
import styled from "styled-components";
import Modal from "../../../modal/Modal";
import { SimpleState } from "../../../../types/default";
import InterestButton from "../InterestButton";
import { Color } from "../../../../constants/color";
import {
  getInterestFromModeKey,
  getInterestFromType,
} from "../../../../helper/recommendation/mapping";
import {
  RecommendationMode,
  RecommendationModeArray,
} from "../../../../types/application";

export enum GigaKombiInfoTexts {
  tooLowInterests = "Hinweis: Eine GigaKombi ist mit den ausgewählten Produkten nicht mögich. \n" +
    "Um eine GigaKombi zu nutzen, versuchen Sie mehr Interessen auszuwählen:",
  falseProduct = "Hinweis: Eine GigaKombi ist mit den ausgewählten Produkten nicht mögich. " +
    "Bitte wähle dafür andere Produkte aus.",
  nothing = "",
}

interface GigaKombiInfoModalProps {
  className?: string;
  recommendationModes: RecommendationModeArray;
  gigaInfoTextState: SimpleState<GigaKombiInfoTexts>;
}

const GigaKombiInfoModal: React.FC<GigaKombiInfoModalProps> = ({
  className,
  recommendationModes,
  gigaInfoTextState,
}) => {
  const modes = Object.values(recommendationModes);
  const nonSelectedModes = (
    Object.keys(recommendationModes) as Array<keyof RecommendationModeArray>
  ).filter(
    (key, i) => key !== "iotMode" && modes[i] !== RecommendationMode.SELECTABLE
  );
  const [gigaInfoText, setGigaInfoText] = gigaInfoTextState;
  const setGigaKombiInfoText = (newValue: boolean) => {
    if (!newValue) {
      setGigaInfoText(GigaKombiInfoTexts.nothing);
    }
  };
  useEffect(() => {
    setGigaInfoText(GigaKombiInfoTexts.nothing);
  }, []);
  return (
    <div className={className}>
      <Modal
        isOpen={gigaInfoText !== GigaKombiInfoTexts.nothing}
        setIsOpen={setGigaKombiInfoText}
        modalColor={Color.WHITE}
        fontColor={Color.RED}
        headline={"Die GigaKombi ist nicht möglich!"}
      >
        <p className={"info-description"}>
          {nonSelectedModes.length === 0
            ? GigaKombiInfoTexts.falseProduct
            : gigaInfoText}
        </p>
        <div className={"interest-button-container"}>
          {nonSelectedModes.map((modeKey) => {
            const type = getInterestFromModeKey(modeKey);
            const interestName = getInterestFromType(type);
            return (
              <InterestButton
                key={modeKey}
                type={type}
                onChange={setGigaKombiInfoText}
              >
                Interesse an {interestName}
              </InterestButton>
            );
          })}
        </div>
      </Modal>
    </div>
  );
};

export default styled(GigaKombiInfoModal)`
  .info-description {
    color: ${Color.RED};
    font-size: 20px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .interest-button-container {
    padding: 10px;
    justify-content: center;
    display: flex;
  }
`;
