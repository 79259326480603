import React from "react";
import styled from "styled-components";
import { Color } from "../../../../constants/color";

interface GigaDescriptionProps {
  className?: string;
  text?: string;
}

const GigaDescription: React.FC<GigaDescriptionProps> = ({
  className,
  text,
}) => {
  return <span className={className + " giga-description"}>{text}</span>;
};

export default styled(GigaDescription)`
  position: absolute;
  width: calc(100% - 20px);

  top: 50px;
  left: 10px;

  font-size: 12px;
  text-align: center;
  color: ${Color.RED};
`;
