import * as React from "react";

function SvgStreaming(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="streaming_svg___x39_5ed0c3c-a7a1-4ef2-9a4a-b22393e65fbb"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.streaming_svg__st1{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        d="M375.3 313.9H124.7c-1.8 0-3.2-1.4-3.2-3.2V189.4c0-1.8 1.4-3.2 3.2-3.2h250.6c1.8 0 3.2 1.4 3.2 3.2v121.2c0 1.8-1.4 3.3-3.2 3.3z"
        fill="none"
        stroke={color}
        strokeWidth={24.173}
        strokeMiterlimit={10}
      />
      <path
        className="streaming_svg__st1"
        d="M123.1 135.2h255.4M121.5 195.6v-60.4M172.6 179.9v-44.7M223.6 179.9v-44.7M274.7 179.9v-44.7M325.7 179.9v-44.7M33 58.6h434c14.1 0 25.5 11.4 25.5 25.5v280.8c0 14.1-11.4 25.5-25.5 25.5H33c-14.1 0-25.5-11.4-25.5-25.5V84.1C7.5 70 18.9 58.6 33 58.6zM135.1 441.5h229.8M250 403.2v31.9M378.5 189.2v-54"
      />
    </svg>
  );
}

export default SvgStreaming;
