import {
  Interest,
  RecommendationMode,
  RecommendationModeArray,
  WithsObject,
} from "../../types/application";

export function getIsGigaKombiPossible(
  withsObject: WithsObject,
  recommendationModes: RecommendationModeArray
): [boolean, Interest] {
  const { mobileMode, fixedMode } = recommendationModes;
  const { mobile, fixed, tv } = withsObject;

  if (
    (mobile && fixed) ||
    (mobile && tv && mobileMode === RecommendationMode.SELECTABLE)
  ) {
    return [true, Interest.MOBILE];
  } else if (fixed && tv && fixedMode === RecommendationMode.SELECTABLE) {
    return [true, Interest.FIXED];
  }

  return [false, Interest.MOBILE];
}
