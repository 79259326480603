import * as React from "react";

function SvgPlane(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <path
        d="M32.81 416.14h434.18M237.24 205L129.93 95.97l29.9-10.41a31.846 31.846 0 0124.68 1.62L338.5 169.5m-120.69 15.88l-106.99 37.9-63.68-34.14a27.42 27.42 0 00-20.92-1.21L7.1 195.98l41.4 50.95c30.48 36.52 75.6 50.34 120.53 34.67l269.83-96.16a83.34 83.34 0 0053.24-57.73 83.31 83.31 0 00-77.5-12l-106.23 37.63"
        fill="none"
        stroke={color}
        strokeWidth={7.087}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgPlane;
