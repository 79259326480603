import { useContext } from "react";
import { AppContext } from "../../state/AppContext";
import { AppState } from "../../state/definitions/AppState";
import { SimpleStateArray } from "../../types/default";
import { FilteredOnType } from "../../types/util";
import { ProductLineOfferState } from "../../state/definitions/ProductLineOfferState";
import { defaultProductLineOfferState } from "../../state/defaults/defaultProductLineOfferState";

type ProductLineOfferStateArrayKeys = FilteredOnType<
  ProductLineOfferState,
  Array<any>
>;

export const useNewOfferStateArray = <
  K extends keyof ProductLineOfferStateArrayKeys
>(
  key: K,
  offerEntryId: string
): SimpleStateArray<ProductLineOfferStateArrayKeys[K][number]> => {
  const [state, setState] = useContext(AppContext);

  const setValue = (
    value: ProductLineOfferStateArrayKeys[K][number],
    index: number
  ) => {
    setState((prev: AppState) => {
      const arrayValue: ProductLineOfferState[K] = Object.assign(
        [],
        prev["newOffer"][offerEntryId][key]
      );
      arrayValue[index] = value;
      return {
        ...prev,
        newOffer: {
          ...prev.newOffer,
          [offerEntryId]: {
            ...prev.newOffer[offerEntryId],
            [key]: arrayValue,
          },
        },
      };
    });
  };

  return [
    state.newOffer[offerEntryId] !== undefined &&
    state.newOffer[offerEntryId][key] !== undefined
      ? state.newOffer[offerEntryId][key]
      : defaultProductLineOfferState[key],
    setValue,
  ];
};
