import {
  ConstSlidePagesValues,
  SlideCategoryTypes,
  SlidePage,
  VariableSlidePagesValues,
} from "./SlidePages";

function getCategorySlides(
  slides: SlidePage[],
  category: SlideCategoryTypes
): SlidePage[] {
  // Find all slides from a category
  return slides.filter((s) => s.category === category) ?? [];
}

export const getCategorizedSlidePages = () => {
  // constant slides
  const start = getCategorySlides(
    ConstSlidePagesValues,
    SlideCategoryTypes.start
  );
  const availability = getCategorySlides(
    ConstSlidePagesValues,
    SlideCategoryTypes.availability
  );
  const switchSlides = getCategorySlides(
    ConstSlidePagesValues,
    SlideCategoryTypes.switch
  );
  const profile = getCategorySlides(
    ConstSlidePagesValues,
    SlideCategoryTypes.profile
  );
  const recommendation = getCategorySlides(
    ConstSlidePagesValues,
    SlideCategoryTypes.recommendation
  );
  const offer = getCategorySlides(
    ConstSlidePagesValues,
    SlideCategoryTypes.offer
  );
  const help = getCategorySlides(
    ConstSlidePagesValues,
    SlideCategoryTypes.help
  );

  // variable slides
  const mobile = getCategorySlides(
    VariableSlidePagesValues,
    SlideCategoryTypes.mobile
  );
  const iot = getCategorySlides(
    VariableSlidePagesValues,
    SlideCategoryTypes.iot
  );
  const fixed = getCategorySlides(
    VariableSlidePagesValues,
    SlideCategoryTypes.fixed
  );
  const tv = getCategorySlides(VariableSlidePagesValues, SlideCategoryTypes.tv);

  return {
    start,
    availability,
    switch: switchSlides,
    profile,
    recommendation,
    offer,
    help,
    mobile,
    iot,
    fixed,
    tv,
  };
};
