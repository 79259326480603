import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";

interface PrintPanelTitleProps {
  className?: string;
  backgroundColor?: string;
  fontColor?: string;
}

const PrintPanelTitle: React.FC<PrintPanelTitleProps> = ({
  className,
  children,
}) => <div className={className}>{children}</div>;

export default styled(PrintPanelTitle)`
  font-weight: bold;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : Color.GREY_LIGHT2};
  color: ${({ fontColor }) => (fontColor ? fontColor : Color.GREY)};
  padding: 2px 5px;
  font-size: 14px;
`;
