import * as React from "react";
import { Color } from "../../../constants/color";
import LongTextDisplay from "../guaranteePage/LongTextDisplay";

export interface IconTextDisplayProps {
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  label: string;
  value: string;
}

const IconTextDisplay = ({ icon, label, value }: IconTextDisplayProps) => {
  return (
    <LongTextDisplay
      backgroundColor={"transparent"}
      headlineColor={Color.WHITE}
      textColor={Color.WHITE}
      iconColor={Color.WHITE}
      iconSize={50}
      icon={icon}
      label={label}
      value={value}
    />
  );
};

export default IconTextDisplay;
