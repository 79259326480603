import { AppState } from "../../state/definitions/AppState";
import {
  ProductLine,
  RecommendationRequest,
} from "../../data/definitions/RecommendationRequest";
import { Interest } from "../../types/application";
import { FormConfigLabelIds } from "../../data/definitions/FormConfigLabelIds";

export function getRecommendationRequestData(
  state: AppState
): RecommendationRequest {
  // activate email: 18.01.2022 Nina Thom
  const additionalCostInvoice =
    state.bootstrap.formConfig.additionalCostInvoice.find(
      (v) => v.value === state.form.additionalCostInvoice
    )?.value === FormConfigLabelIds.ADDITIONAL_INVOICE_COSTS;
  const selectedMobilePersonalDetails =
    state.bootstrap.formConfig.mobilePersonalDetails.filter((v) =>
      state.form.mobilePersonalDetails.includes(v.value)
    );
  const under28 =
    selectedMobilePersonalDetails.findIndex((v) => v.value === 209) >= 0;
  const parentBetween10And17 =
    selectedMobilePersonalDetails.findIndex((v) => v.value === 210) >= 0;

  return {
    mobileInterest: state.form.interests.includes(Interest.MOBILE),
    iotInterest: state.form.interests.includes(Interest.IOT),
    fixedInterest: state.form.interests.includes(Interest.FIXED),
    tvInterest: state.form.interests.includes(Interest.TV),
    availabilityCable: state.availability.availability.cable.downstream / 1000,
    availabilityDSL: state.availability.availability.dsl.downstream / 1000,
    additionalCostInvoice: additionalCostInvoice, // fixedInvoice
    neededBandwidth: state.form.deviceNeededBandwidth,
    neededDataVolume: state.form.mobileDataVolume, // contract count * 4 GB
    under28: under28,
    parentBetween10And17: parentBetween10And17,
    cardData: state.form.contractCounts["cardData"] ?? 0,
    cardTariff: state.form.contractCounts["cardTariff"] ?? 0,
    cardTel: 0,
    cardDataGo: state.form.contractCounts["cardDataGo"] ?? 0,
    personCount: state.form.contractData.length, // (0-5)
    mobileUseMusic: state.form.mobileDataMusic,
    mobileUseSocial: state.form.mobileDataSocial,
    mobileUseChat: state.form.mobileDataChat,
    mobileUseGaming: state.form.mobileDataGaming,
    mobileUseVideo: state.form.mobileDataVideo,
    productLine: ProductLine.ALL,
  };
}
