import * as React from "react";

function SvgPayTv(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="payTV_svg__Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 496.34 379.7"
      {...props}
    >
      <defs>
        <style>
          {`.payTV_svg__cls-1{fill:none;stroke: ${color} ;stroke-linecap:round;stroke-width:24.1732px;stroke-linejoin:round}`}
        </style>
      </defs>
      <path
        className="payTV_svg__cls-1"
        d="M250 390.72v32m-114.87 3.4h229.74M33 57.79h434a25.57 25.57 0 0125.5 25.62v281.87a25.58 25.58 0 01-25.5 25.63H33a25.58 25.58 0 01-25.5-25.63V83.41A25.57 25.57 0 0133 57.79z"
        transform="translate(-1.83 -52.12)"
      />
      <path
        className="payTV_svg__cls-1"
        d="M314.05 147.09a100.72 100.72 0 11-64.06-23 100.72 100.72 0 0164.06 23"
        transform="translate(-1.83 -52.12)"
      />
      <path
        d="M253.86 161.88h-44.3m4.71 21.72h39.59m21.84 23.08a37.27 37.27 0 01-15.59 3.88c-20.1 0-31.47-17-31.47-37.92s11.37-37.91 31.47-37.91a35.1 35.1 0 0116.18 3.94"
        strokeMiterlimit={10}
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={24.173}
      />
    </svg>
  );
}

export default SvgPayTv;
