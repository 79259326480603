import * as React from "react";
import { SimpleState } from "../../../types/default";
import AFOLabelInputCell from "./AFOLabelInputCell";
import AFOLabelSelectionCell from "./AFOLabelSelectionCell";
import { TableCellType, TableDataObject } from "../../../types/table";

export interface AFOLabelCellProps {
  dataset: TableDataObject;
  dataId: number;
  openSelectionIdState: SimpleState<number>;
  changeLabelId: (newLabelId: number, objectId: number) => void;
  changeLabelValue: (newValue: string, objectId: number) => void;
}

const AFOLabelCell: React.FC<AFOLabelCellProps> = ({
  dataset,
  dataId,
  openSelectionIdState,
  changeLabelId,
  changeLabelValue,
}) => {
  const [openSelectionId, setOpenSelectionId] = openSelectionIdState;
  let label: string = "";
  if (dataset.labels[dataset.labelId]) {
    label = dataset.labels[dataset.labelId];
  }

  switch (dataset.labelType) {
    case TableCellType.INPUT:
      return (
        <AFOLabelInputCell
          value={label}
          changeValue={(value) => {
            changeLabelValue(value, dataId);
          }}
        />
      );

    case TableCellType.SELECTION:
      return (
        <AFOLabelSelectionCell
          value={label}
          dataId={dataId}
          dataSet={dataset}
          changeLabelId={changeLabelId}
          setOpenSelectionId={setOpenSelectionId}
          openSelectionId={openSelectionId}
          colSpan={dataset.countType !== TableCellType.NONE ? 1 : 3}
        />
      );

    default:
      return null;
  }
};

export default AFOLabelCell;
