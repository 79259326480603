import styled from "styled-components";
import React from "react";
import { Color } from "../../../constants/color";
import { Interest } from "../../../types/application";
import IButton from "../../form/IconButton";
import { StyledComponentProps } from "../../../types/default";
import GridColumn from "../../grid/GridColumn";
import { useProvider } from "../../../hooks/useFormHelper/useProvider";

interface InterestBubbleProps extends StyledComponentProps {
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  type: Interest;
}

const InterestBubble: React.FC<InterestBubbleProps> = ({ icon, type }) => {
  const {
    isVodafoneProvider,
    providerIdState: [, setProviderId],
    vfProviderPosState: [vFProviderPos],
  } = useProvider(type);

  const onButtonClick = () => {
    setProviderId(isVodafoneProvider ? -1 : vFProviderPos);
  };

  return (
    <GridColumn colCount={4} textAlign={"center"}>
      <IButton
        icon={icon}
        onChange={onButtonClick}
        size={80}
        color={isVodafoneProvider ? Color.RED : Color.GREY}
        backgroundColor={
          isVodafoneProvider ? Color.WHITE : Color.GREY_DISABLED_ICON_BACKGROUND
        }
      />
    </GridColumn>
  );
};

export default styled(InterestBubble)``;
