import React, { useMemo } from "react";
import { useFormState } from "../../hooks/useForm";
import { FormConfigLabelIds } from "../../data/definitions/FormConfigLabelIds";
import styled from "styled-components";
import { StyledComponentProps } from "../../types/default";
import "../form/TileButton";
import useBootstrap from "../../hooks/useBootstrap";
import IconTileButtonGrid from "../form/IconTileButtonGrid";
import { Color } from "../../constants/color";
import LittleGreyButton from "../form/LittleGreyButton";

const IoTDevicePage: React.FC<StyledComponentProps> = ({ className }) => {
  const [iotInterests] = useFormState("iotInterests");
  const [iotSmartWatchOwnDevice, setIotSmartWatchOwnDevice] = useFormState(
    "iotSmartWatchOwnDevice"
  );
  const [iotTrackerOwnDevice, setIotTrackerOwnDevice] = useFormState(
    "iotTrackerOwnDevice"
  );
  const [iotCameraOwnDevice, setIotCameraOwnDevice] =
    useFormState("iotCameraOwnDevice");

  const [iotSmartWatchLabels, setIotSmartWatchLabels] = useFormState(
    "iotSmartWatchLabels"
  );
  const [iotTrackerLabels, setIotTrackerLabels] =
    useFormState("iotTrackerLabels");

  const { formConfig } = useBootstrap();

  const checkedObject = useMemo(() => {
    const newCheckedObject = {
      smartwatch: false,
      tracker: false,
      camera: false,
    };
    iotInterests.forEach((v) => {
      switch (v) {
        case FormConfigLabelIds.IOT_SMARTWATCH:
          newCheckedObject.smartwatch = true;
          break;
        case FormConfigLabelIds.IOT_TRACKER:
          newCheckedObject.tracker = true;
          break;
        case FormConfigLabelIds.IOT_CAMERA:
          newCheckedObject.camera = true;
          break;
      }
    });
    return newCheckedObject;
  }, [iotInterests]);

  return (
    <div className={className}>
      {checkedObject.smartwatch && (
        <div className={"iot-device-container"}>
          <h4>SmartWatch</h4>
          <p>Für wen benötigst Du die Smartwatch mit eigener Rufnummer?</p>
          <IconTileButtonGrid
            data={formConfig.iotSmartWatchLabels}
            values={iotSmartWatchLabels}
            onChange={setIotSmartWatchLabels}
          />
          <div>
            <span>Hardware vorhanden? </span>
            <LittleGreyButton
              onChange={setIotSmartWatchOwnDevice}
              value={iotSmartWatchOwnDevice}
            >
              Ja
            </LittleGreyButton>
          </div>
        </div>
      )}
      {checkedObject.tracker && (
        <div className={"iot-device-container"}>
          <h4>Tracker</h4>
          <p>Wofür benötigst Du einen Tracker?</p>
          <IconTileButtonGrid
            data={formConfig.iotTrackerLabels}
            values={iotTrackerLabels}
            onChange={setIotTrackerLabels}
          />
          <div>
            <span>Hardware vorhanden? </span>
            <LittleGreyButton
              onChange={setIotTrackerOwnDevice}
              value={iotTrackerOwnDevice}
            >
              Ja
            </LittleGreyButton>
          </div>
        </div>
      )}
      {checkedObject.camera && (
        <div className={"iot-device-container"}>
          <h4>Kamera</h4>
          <div>
            <span>Hardware vorhanden? </span>
            <LittleGreyButton
              onChange={setIotCameraOwnDevice}
              value={iotCameraOwnDevice}
            >
              Ja
            </LittleGreyButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default styled(IoTDevicePage)`
  margin: 0 15px;
  width: calc(100% - 30px);
  display: block;

  .iot-device-container {
    width: 100%;
    display: block;
    margin: 10px 0;
    padding: 12px;
    background: ${Color.WHITE};

    h4 {
      color: ${Color.RED};
      font-size: 22px;
      font-weight: bold;
    }

    .tile-button {
      max-height: 120px;
    }
  }
`;
