import React from "react";
import styled from "styled-components";
import Column from "./Column";

interface SizedGridColumnProps {
  className?: string;
  width?: string;
}

const SizedGridColumn: React.FC<SizedGridColumnProps> = ({
  children,
  className,
  width,
}) => {
  return (
    <Column
      className={className}
      minWidth={width}
      maxWidth={width}
      width={width}
      withoutBottomMargin
    >
      {children}
    </Column>
  );
};

export default styled(SizedGridColumn)``;
