import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";

interface PanelPrintProps {
  className?: string;
  backgroundColor?: string;
  fontColor?: string;
  fontWeight?: string;
}

const PanelPrint: React.FC<PanelPrintProps> = ({ className, children }) => (
  <div className={className}>
    <ul>{children}</ul>
  </div>
);
// e30613
export default styled(PanelPrint)`
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : Color.GREY_LIGHT4};
  color: ${({ fontColor }) => (fontColor ? fontColor : Color.GREY)};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "initial")};
  padding: 1px 1px 1px 2px;
`;
