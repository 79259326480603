import * as React from "react";

function SvgInfoColor2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="info_color2_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".info_color2_svg__st0{fill:none;stroke:#000;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <path
        className="info_color2_svg__st0"
        d="M250 360.1V211.7h-25.5m0 153.2h51.1"
      />
      <circle className="info_color2_svg__st0" cx={250} cy={250} r={242.5} />
      <circle className="info_color2_svg__st0" cx={243.6} cy={138.3} r={6.4} />
      <circle className="info_color2_svg__st0" cx={243.6} cy={138.3} r={3.2} />
    </svg>
  );
}

export default SvgInfoColor2;
