import React, { useMemo } from "react";
import PaginationLink from "./PaginationLink";
import Progress from "./Progress";

interface PaginationProps {
  toPrev: string;
  toNext: string | null;
  disableNext: boolean;
  totalPages: number;
  currentPage: number;
}

const Pagination: React.FC<PaginationProps> = ({
  toPrev,
  toNext,
  disableNext,
  totalPages,
  currentPage,
}) => {
  const progressValue = useMemo(() => {
    const result = currentPage / totalPages;
    if (Number.isFinite(result)) {
      return result;
    }
    return 0;
  }, [currentPage, totalPages]);
  return (
    <>
      <PaginationLink
        disabled={currentPage === 0}
        direction="prev"
        to={toPrev}
      />
      <Progress value={progressValue} />
      <PaginationLink
        disabled={disableNext}
        direction="next"
        to={toNext ? toNext : "#"}
      />
    </>
  );
};

export default Pagination;
