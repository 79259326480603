import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import { LabelValuePair } from "../../../types/application";

interface PriceSummaryPrintProps {
  className?: string;
  accentColor?: string;
  items?: LabelValuePair[];
  mainLabel?: string;
  mainValue?: string;
}

const PriceSummaryPrint: React.FC<PriceSummaryPrintProps> = ({
  className,
  items = [],
  mainLabel,
  mainValue,
}) => {
  return (
    <div className={className + " price-summary"}>
      <ul className={"price-summary-label-list"}>
        <li key={-1} className={"main-label-value-item"}>
          {mainLabel}
        </li>
        {items.map((lvPair, i) => (
          <li key={i} className={"normal-label-value-item"}>
            {lvPair.label}
          </li>
        ))}
      </ul>
      <ul className={"price-summary-price-list"}>
        <li key={-1} className={"main-label-value-item"}>
          {mainValue}
        </li>
        {items.map((lvPair, i) => (
          <li key={i} className={"normal-label-value-item"}>
            {lvPair.value}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default styled(PriceSummaryPrint)`
  width: 100%;
  display: inline-block;

  background-color: ${Color.WHITE};

  text-align: right;
  vertical-align: top;

  overflow: hidden;
  ul {
    display: inline-block;
    border: 1px solid
      ${({ accentColor }) => (accentColor ? accentColor : Color.WHITE)};
  }
  .price-summary-label-list {
    width: calc(70% - 2px);
  }
  .price-summary-price-list {
    margin-left: 2px;
    width: 30%;
  }

  li {
    padding-right: 5px;
  }
  .normal-label-value-item {
    color: ${Color.BLACK};
    font-size: 10px;
    line-height: 10px;
    :nth-last-of-type(1) {
      font-weight: bold;
      padding-bottom: 5px;
    }
  }

  .main-label-value-item {
    color: ${({ accentColor }) => (accentColor ? accentColor : Color.RED)};
    font-weight: bold;
    line-height: 30px;
    font-size: 18px !important;
  }
`;
