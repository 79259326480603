import * as React from "react";

function SvgManager(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="manager_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.manager_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className="manager_svg__st0"
        d="M463.6 492.5c-23.1-156.2-118.1-228.7-212.3-228.7S59.5 336.2 36.4 492.4"
      />
      <circle className="manager_svg__st0" cx={250} cy={135.7} r={128.2} />
      <path
        className="manager_svg__st0"
        d="M264.2 289.6h-28.5m44.9 166.9L250 492.1l-30.6-35.6 14.9-124.6c.8-8.1 7.6-14.2 15.7-14.2s14.9 6.2 15.7 14.2l14.9 124.6z"
      />
    </svg>
  );
}

export default SvgManager;
