import { extraCheckFunction } from "./interfaces";

export function testPropOfObject<T>(
  v: string,
  testObject: any,
  extraCheck?: extraCheckFunction<T>
) {
  let booleanResult = v in testObject;
  if (extraCheck !== undefined) {
    booleanResult = extraCheck(v, testObject[v]);
  }
  return !booleanResult;
}
