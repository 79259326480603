import * as React from "react";

function SvgPerson(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="person_svg___x36_a8da56c-64cb-4c0d-b7e3-2b80c0db2d07"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.person_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <circle
        id="person_svg___x33_ad8aabf-2066-47d6-9a2f-c027e5c19606"
        className="person_svg__st0"
        cx={250}
        cy={135.9}
        r={128.4}
      />
      <path
        id="person_svg___x32_43fad7b-53d4-4daa-93b6-31f40d832ead"
        className="person_svg__st0"
        d="M463.5 492.5c-23.1-156.2-118-228.6-212.2-228.6S59.6 336.3 36.5 492.4"
      />
    </svg>
  );
}

export default SvgPerson;
