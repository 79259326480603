import { useNavigation } from "../useNavigation";
import { useRefMemo } from "../common/useRefHook";
import Icons from "../../icons/Icons";
import {
  FIRST_FIXED_PAGE,
  FIRST_IOT_PAGE,
  FIRST_MOBILE_PAGE,
  FIRST_TV_PAGE,
  SlidePages,
} from "../../constants/SlidePages";
import fscreen from "fscreen";
import useAvailability from "../useAvailability";
import { useInterests } from "../useFormHelper/useInterests";
import { SVGComponent } from "../../types/default";
import { useContext } from "react";
import { AppContext } from "../../state/AppContext";
import { useLocation } from "react-router-dom";
import { extractId } from "../../helper/navigation/extractId";
import { Interest } from "../../types/application";
import { ENABLE_RECOMMENDATION_WITH_NO_INTEREST } from "../../constants/default";

interface NavItemInterface {
  id: string;
  to?: string;
  fct?: () => boolean;
  active?: boolean;
  finished?: boolean;
  disabled?: boolean;
  showDisabled?: boolean;
  icon: SVGComponent | SVGComponent[];
}

export const useNavigationItems = () => {
  const {
    isChangeGuaranteeSlideShowing,
    setIsChangeGuaranteeSlideShowing,
    setIsCancelShowing,
    setIsInterestShowing,
    getCategory,
    currentSlideIndex,
  } = useNavigation();
  const [state] = useContext(AppContext);
  const { availability } = useAvailability();
  const { interests, isInterest } = useInterests();
  const location = useLocation();

  const navItems: NavItemInterface[] = useRefMemo(() => {
    const isActive = (slideId: number) => {
      const noException = !isChangeGuaranteeSlideShowing;
      if (noException) {
        const currentId = extractId(location.pathname);
        return getCategory(slideId) === getCategory(currentId);
      }
      return false;
    };
    const showChangeGuaranteeSlideFct =
      (value: boolean = true) =>
      () => {
        setIsChangeGuaranteeSlideShowing(value);
        return true;
      };

    const clickOnTopic = (interest: Interest) => () => {
      const isDisabled = !interests.includes(interest);
      if (isDisabled) {
        setIsInterestShowing(interest);
      }
      showChangeGuaranteeSlideFct(false);
      return !isDisabled;
    };

    let disableEndSlides = false;
    if (!ENABLE_RECOMMENDATION_WITH_NO_INTEREST) {
      const eachInterestHasData =
        (state.form.mobileHasData ||
          state.form.mobileHasData === isInterest(Interest.MOBILE)) &&
        (state.form.iotHasData ||
          state.form.iotHasData === isInterest(Interest.IOT)) &&
        (state.form.fixedHasData ||
          state.form.fixedHasData === isInterest(Interest.FIXED)) &&
        (state.form.tvHasData ||
          state.form.tvHasData === isInterest(Interest.TV));
      disableEndSlides = interests.length === 0 || !eachInterestHasData;
    }

    return [
      {
        id: "home",
        to: "/",
        icon: Icons.start,
        active: isActive(SlidePages.initialInterest.id),
        finished: false,
        fct: () => {
          setIsCancelShowing(true);
          return false;
        },
      },
      {
        id: "mobile",
        to: "/slides/" + FIRST_MOBILE_PAGE.id,
        icon: Icons.mobile,
        active: isActive(FIRST_MOBILE_PAGE.id),
        finished: !isActive(FIRST_MOBILE_PAGE.id) && state.form.mobileHasData,
        showDisabled: !interests.includes(Interest.MOBILE),
        fct: clickOnTopic(Interest.MOBILE),
      },
      {
        id: "fixed",
        to: state.availability.someIsAvailable
          ? "/slides/" + FIRST_FIXED_PAGE.id
          : "/slides/" + SlidePages.availabilityCheck.id,
        icon: [Icons.internet, Icons.landline],
        active: isActive(FIRST_FIXED_PAGE.id),
        finished: !isActive(FIRST_FIXED_PAGE.id) && state.form.fixedHasData,
        showDisabled: !interests.includes(Interest.FIXED),
        fct: clickOnTopic(Interest.FIXED),
      },
      {
        id: "tv",
        to: state.availability.someIsAvailable
          ? "/slides/" + FIRST_TV_PAGE.id
          : "/slides/" + SlidePages.availabilityCheck.id,
        icon: Icons.tv,
        active: isActive(FIRST_TV_PAGE.id),
        finished: !isActive(FIRST_TV_PAGE.id) && state.form.tvHasData,
        showDisabled: !interests.includes(Interest.TV),
        fct: clickOnTopic(Interest.TV),
      },
      {
        id: "iot",
        to: "/slides/" + FIRST_IOT_PAGE.id,
        icon: Icons.iotSim,
        active: isActive(FIRST_IOT_PAGE.id),
        finished: !isActive(FIRST_IOT_PAGE.id) && state.form.iotHasData,
        showDisabled: !interests.includes(Interest.IOT),
        fct: clickOnTopic(Interest.IOT),
      },
      {
        id: "profile",
        to: "/slides/" + SlidePages.profile.id,
        icon: Icons.profile,
        active: isActive(SlidePages.profile.id),
        finished: false,
        disabled: disableEndSlides,
        fct: showChangeGuaranteeSlideFct(false),
      },
      {
        id: "recommendation",
        to: "/slides/" + SlidePages.recommendation.id,
        icon: Icons.offer,
        active: isActive(SlidePages.recommendation.id),
        finished: false,
        disabled: disableEndSlides,
        fct: showChangeGuaranteeSlideFct(false),
      },
      {
        id: "guarantee",
        to: undefined,
        icon: Icons.guarantee,
        active: isChangeGuaranteeSlideShowing,
        fct: showChangeGuaranteeSlideFct(true),
      },
      {
        id: "expandScreen",
        to: undefined,
        icon: Icons.expandScreen,
        active: false,
        fct: () => {
          const app = document.documentElement;
          if (document.fullscreenElement) {
            fscreen.exitFullscreen();
          }
          fscreen.requestFullscreen(app);
          return false;
        },
      },
    ];
  }, [
    currentSlideIndex,
    isChangeGuaranteeSlideShowing,
    availability,
    interests.join(""),
    state.form.mobileHasData,
    state.form.iotHasData,
    state.form.fixedHasData,
    state.form.tvHasData,
    state.navigation.currentSlideIndex,
    location.pathname,
  ]);
  return { navItems };
};
