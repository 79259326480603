import styled from "styled-components";
import Modal from "../../modal/Modal";
import React, { useEffect, useState } from "react";
import { AdvantageButton } from "./AdvantageButton";
import PanelGroup from "../../panel/PanelGroup";
import PanelGroupTitle from "../../panel/PanelGroupTitle";
import GridPanel from "../../panel/GridPanel";
import BubbleWithText from "./BubbleWithText";
import { Color } from "../../../constants/color";
import useRecommendation from "../../../hooks/useRecommendation";
import { getBubbleEntriesFromAdvantageIds } from "../../../helper/getBubbleEntriesFromAdvantageIds";
import GigaCircle from "../recommendationPage/gigakombi/GigaCircle";
import GigaText from "../recommendationPage/gigakombi/GigaText";
import { BubbleEntry } from "../../../types/application";

interface AdvantageModalProps {
  className?: string;
  ids?: number[];
}

const AdvantageModal: React.FC<AdvantageModalProps> = ({
  className,
  ids = [],
}) => {
  const { config } = useRecommendation();
  const [bubbleEntries, setBubbleEntries] = useState<BubbleEntry[]>([]);

  useEffect(() => {
    if (ids.length) {
      const newBubbleEntries: BubbleEntry[] = getBubbleEntriesFromAdvantageIds(
        ids,
        config.advantages
      );
      setBubbleEntries(newBubbleEntries);
    }
  }, [config.advantages, ids]);

  return (
    <div className={className}>
      <Modal
        modalColor={Color.WHITE}
        fontColor={Color.RED}
        openButton={(onChange) => (
          <AdvantageButton onChange={onChange}>Deine Vorteile</AdvantageButton>
        )}
      >
        <div className={"modal-advantage-body-inner"}>
          <PanelGroup
            titleBackgroundColor={Color.GREY_DARK}
            backgroundColor={Color.GREY_DARK}
            fontColor={Color.WHITE}
            title={<PanelGroupTitle value={"Vorteile"} />}
          >
            <GridPanel>
              {bubbleEntries &&
                bubbleEntries.map((d, i) => {
                  const textValue = d.content.replace(
                    "Cyberversicherung",
                    "Cyber-versicherung"
                  );
                  return (
                    <BubbleWithText
                      key={i}
                      head={d.headline}
                      content={d.icon !== undefined ? d.content : ""}
                      icon={d.icon}
                    >
                      {d.icon === undefined && (
                        <GigaCircle invertColors>
                          <GigaText
                            text={textValue}
                            invertColors
                            width={"78%"}
                            top={"25px"}
                            left={"12px"}
                          />
                        </GigaCircle>
                      )}
                    </BubbleWithText>
                  );
                })}
            </GridPanel>
          </PanelGroup>
        </div>
      </Modal>
    </div>
  );
};

export default styled(AdvantageModal)`
  .modal-advantage-body-inner {
    padding-bottom: 20px;
  }
`;
