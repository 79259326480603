import * as React from "react";

function SvgEuColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="eu_color_svg__ICON"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".eu_color_svg__st1{fill:none;stroke:#e60000;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10}"
        }
      </style>
      <path
        d="M172 96c0 42-34 76-76 76s-76-34-76-76 34-76 76-76 76 34 76 76"
        fill="none"
        stroke="#e60000"
        strokeWidth={8}
        strokeMiterlimit={10}
      />
      <path
        className="eu_color_svg__st1"
        d="M84 72H59.7M84 96H59.7M84 120H59.7M59.7 72v48M136 108.3s0 12.7-14 12.7-14-12.7-14-12.7M108 73v33.8M136 73v33.8"
      />
    </svg>
  );
}

export default SvgEuColor;
