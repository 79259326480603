import React from "react";
import styled from "styled-components";
import GigaCircle from "./GigaCircle";
import GigaValue from "./GigaValue";
import GigaDescription from "./GigaDescription";
import GigaText from "./GigaText";
import GigaPlus from "./GigaPlus";
import GigaUnlimitedValue from "./GigaUnlimitedValue";
import { LabelValuePair } from "../../../../types/application";

interface MiniGigaCirclesProps {
  className?: string;
  withStar?: boolean;
  circleData: LabelValuePair<string | JSX.Element>[];
}

const MiniGigaCircles: React.FC<MiniGigaCirclesProps> = ({
  withStar = false,
  circleData = [],
  className,
}) => {
  return (
    <div className={className + " giga-circles"}>
      <div className={"giga-circle-list"}>
        {circleData.map((v, i) => {
          const isUnlimited = v.value === "GIGA_KOMBI_CIRCLES_UNLIMITED_VALUE";
          const textValue = v.label.replace("Datenvolumen", "Daten-volumen");
          return (
            <div className={"giga-circle-unit"} key={i}>
              <GigaCircle withStar={i === circleData.length - 1 && withStar}>
                {v.value !== "" && isUnlimited && (
                  <GigaUnlimitedValue size={30} />
                )}
                {v.value !== "" && !isUnlimited && (
                  <GigaValue value={v.value} />
                )}
                {v.value !== "" && <GigaDescription text={textValue} />}
                {v.value === "" && <GigaText text={textValue} />}
              </GigaCircle>
              {i + 1 < circleData.length && <GigaPlus />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default styled(MiniGigaCircles)`
  position: relative;
  height: 100%;

  vertical-align: top;
  padding-left: 1px;

  .giga-circle-list {
    display: inline-block;
    position: relative;

    padding: 0 2px;
    vertical-align: top;

    @media (max-width: 1023px) {
      margin: 0 auto;
      text-align: left;
    }
    .giga-circle-unit {
      display: inline-block;
      vertical-align: middle;
    }
  }
  .giga-circle-container {
    height: 60px;
    width: unset;
    .giga-circle {
      height: 60px;
      width: 60px;
    }

    .giga-value {
      font-size: 16px;
      top: 14px;
      width: 100%;
      max-width: 60px;
      &.giga-unlimited-value {
        top: 5px;
      }
    }
    .giga-description {
      font-size: 10px;
      top: 30px;
      width: 40px;
      left: 12px;
    }
    .giga-text {
      font-size: 10px;
      top: 16px;
      width: 85%;
      left: 5px;
    }
  }

  .giga-plus-container {
    height: unset;
    width: unset;
    padding: 10px 2px;
    vertical-align: top;
  }
`;
