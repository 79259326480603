import React from "react";
import styled from "styled-components";
import AvailabilityLabel from "./AvailabilityLabel";
import GridColumn from "../../grid/GridColumn";
import {
  Technology,
  TechnologyAvailability,
} from "../../../state/definitions/AvailabilityState";
import AvailabilityValue from "./AvailabilityValue";

interface AvailabilityItemProps {
  className?: string;
  name: Technology;
  data: TechnologyAvailability;
}

const AvailabilityItem: React.FC<AvailabilityItemProps> = ({
  className,
  name,
  data,
}) => (
  <GridColumn colCount={5} textAlign={"center"} withoutBottomMargin={false}>
    <div className={className}>
      <AvailabilityValue
        data={data}
        // dataKey={name}
        withoutNumber={name === Technology.tv}
      />
      <AvailabilityLabel label={name} />
    </div>
  </GridColumn>
);

export default styled(AvailabilityItem)`
  height: 100px;
`;
