import { DatabaseProduct } from "../../data/definitions/DatabaseProduct";
import { DatabaseCategoryTypes } from "../../data/definitions/DatabaseCategoryTypes";
import { DatabaseProductTypes } from "../../data/definitions/DatabaseProductTypes";

export function getClusteredProducts<T = DatabaseProduct>(
  products: DatabaseProduct[],
  mapper: (product: DatabaseProduct) => T
): {
  mobile: T | undefined;
  iot: T | undefined;
  fixed: T | undefined;
  tv: T | undefined;
  subProducts: T[];
} {
  let mobile: T | undefined = undefined;
  let iot: T | undefined = undefined;
  let fixed: T | undefined = undefined;
  let tv: T | undefined = undefined;

  const subProducts = products
    .filter((v) => {
      const isMainProduct = v.productCategory === DatabaseCategoryTypes.tariff;
      const withRebate = v.combiRebate;
      const withPush = v.combiPush;
      if (withRebate || withPush) {
        if (isMainProduct) {
          switch (v.productType) {
            case DatabaseProductTypes.mobile:
              mobile = mapper(v);
              break;
            case DatabaseProductTypes.iot:
              iot = mapper(v);
              break;
            case DatabaseProductTypes.fixed:
              fixed = mapper(v);
              break;
            case DatabaseProductTypes.tv:
              tv = mapper(v);
              break;
          }
          return false;
        }
        return true;
      }
      return false;
    })
    .map(mapper);

  return { mobile, iot, fixed, tv, subProducts };
}
