import * as React from "react";

function SvgFamilyGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#4a4d4e" } = props;
  return (
    <svg
      id="family_grey_svg__cd3edd23-6ddf-4f08-ae6f-b5c893945238"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.family_grey_svg__st0{fill:none}.family_grey_svg__st1,.family_grey_svg__st2{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round}.family_grey_svg__st1{stroke-linejoin:round}.family_grey_svg__st2{stroke-miterlimit:10}`}
      </style>
      <g id="family_grey_svg__dcab5b7d-7664-49ea-bea9-2f2ed99af67c">
        <path className="family_grey_svg__st0" d="M0 0h192v192H0z" />
        <path className="family_grey_svg__st0" d="M0 0h192v192H0z" />
        <circle className="family_grey_svg__st1" cx={72} cy={64} r={28} />
        <circle className="family_grey_svg__st1" cx={132} cy={72} r={20} />
        <path
          className="family_grey_svg__st2"
          d="M28 140c4.9-29.8 22.3-47.9 44-47.9 6.7 0 13.3 1.8 19.1 5.3M116.3 96.8c4.6-3.2 10.1-4.8 15.7-4.8 15.8 0 28.4 12.6 32 33.3"
        />
        <g id="family_grey_svg__bcfe6083-fc42-4bf9-8d5c-e89cde1080df">
          <circle
            id="family_grey_svg___x39_6b01544-fe01-431a-9d51-93842175bc65"
            className="family_grey_svg__st1"
            cx={104}
            cy={108.9}
            r={17}
          />
          <path
            id="family_grey_svg__db645fb2-3962-4337-9a76-31cf831ac4f7"
            className="family_grey_svg__st1"
            d="M131.6 156c-3.4-19.9-14.8-30.1-27.5-30.1S79.8 136.1 76.3 156"
          />
          <path className="family_grey_svg__st0" d="M0 0h192v192H0z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgFamilyGrey;
