import * as React from "react";
import styled from "styled-components";
import useSubProducts from "../../../../hooks/useRecommendationHelper/useSubProducts";
import { combineMonthlyPriceArray } from "../../../../helper/priceCalculation/combineMonthlyPriceArray";
import { useNewOfferState } from "../../../../hooks/useOfferHelper/useNewOfferState";
import useProducts from "../../../../hooks/useRecommendationHelper/useProducts";
import { useNewOffer } from "../../../../hooks/useNewOffer";
import { useOfferState } from "../../../../hooks/useOffer";
import { getInterestFromType } from "../../../../helper/recommendation/mapping";
import { unifyMonthlyPriceArrayValues } from "../../../../helper/priceCalculation/unifyMonthlyPriceArrayValues";
import OfferEntry, { OfferEntryProps } from "./OfferEntry";
import { useCurrentProducts } from "../../../../hooks/useDatabaseProducts";
import PrintOfferEntry from "../print/PrintOfferEntry";

export interface Props {
  offerEntryId: string;
  isOpen: boolean;
  setIsOpen: (fct: (prev: boolean) => boolean) => void;
  isPrint?: boolean;
}

const OfferEntryContainer = ({
  offerEntryId,
  isOpen,
  setIsOpen,
  isPrint = false,
}: Props) => {
  const { getNewOfferType } = useNewOffer();
  const [selectedGigaKombi] = useOfferState("selectedGigaKombi");

  const type = getNewOfferType(offerEntryId);
  const [selectId] = useNewOfferState("selectId", offerEntryId);
  const [productId] = useNewOfferState("productId", offerEntryId);
  const { getSelectedProducts } = useCurrentProducts();
  const currentProduct = getSelectedProducts([productId])[0];
  const productTitle = currentProduct?.productName ?? "";

  const [, priceArrays] = useProducts(type);
  const priceArray = priceArrays[selectId] ?? {};

  const subProducts = useSubProducts(offerEntryId, selectedGigaKombi);
  const monthlyPriceArray = unifyMonthlyPriceArrayValues(
    combineMonthlyPriceArray([priceArray, subProducts.priceInformation.monthly])
  );

  const offerEntryProps: OfferEntryProps = {
    isOpen: isOpen,
    setIsOpen: setIsOpen,
    title: getInterestFromType(type),
    oncePrice: subProducts.priceInformation.once,
    monthlyPriceArray: monthlyPriceArray,
    productTitle: productTitle,
  };

  if (isPrint) {
    return <PrintOfferEntry {...offerEntryProps} />;
  }
  return <OfferEntry {...offerEntryProps} />;
};

export default styled(OfferEntryContainer)``;
