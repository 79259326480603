import { MonthlyPriceArray } from "../../types/application";

export function combineMonthlyPriceArray(
  prices: MonthlyPriceArray[]
): MonthlyPriceArray {
  const combinedPrices: MonthlyPriceArray = {};
  // Create Array to save the lastest value of each column
  const lastPrice: number[] = [];
  lastPrice.length = prices.length;
  lastPrice.fill(0);

  // Combine all months
  const allMonthsObject: MonthlyPriceArray = {};
  prices.forEach((v, i) => {
    let x: string;
    for (x in v) {
      allMonthsObject[Number(x)] = 0;
    }
  });

  Object.keys(allMonthsObject).forEach((v, i) => {
    // find month in all arrays
    prices.forEach((prices, j) => {
      const foundPrice = prices[Number(v)];
      // if found added price to sum
      if (foundPrice !== undefined) {
        if (combinedPrices[Number(v)]) {
          combinedPrices[Number(v)] += foundPrice;
        } else {
          combinedPrices[Number(v)] = foundPrice;
        }

        // save this price for further months
        lastPrice[j] = foundPrice;
      }
      // if not use the last found price
      else {
        if (combinedPrices[Number(v)]) {
          combinedPrices[Number(v)] += lastPrice[j];
        } else {
          combinedPrices[Number(v)] = lastPrice[j];
        }
      }
    });
  });
  return combinedPrices;
}
