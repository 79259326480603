import { useState } from "react";
import { useRefEffect } from "../common/useRefHook";

export const useErrorModal = (errorMessage: string) => {
  const showErrorModelState = useState(errorMessage !== "");
  const [, setShowErrorModel] = showErrorModelState;
  useRefEffect(() => {
    setShowErrorModel(errorMessage !== "");
  }, [errorMessage]);
  return showErrorModelState;
};
