import * as React from "react";

function SvgMediaLibrary(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="mediaLibrary_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.mediaLibrary_svg__st0,.mediaLibrary_svg__st1{fill:none;stroke: ${color} ;stroke-width:24.1732}.mediaLibrary_svg__st0{stroke-linecap:round;stroke-linejoin:round}.mediaLibrary_svg__st1{stroke-miterlimit:10}`}
      </style>
      <path
        className="mediaLibrary_svg__st0"
        d="M33 58.6h434c14.1 0 25.5 11.4 25.5 25.5v280.8c0 14.1-11.4 25.5-25.5 25.5H33c-14.1 0-25.5-11.4-25.5-25.5V84.1C7.5 70 18.9 58.6 33 58.6zM135.1 441.5h229.7M250 403.2v31.9"
      />
      <path
        className="mediaLibrary_svg__st1"
        d="M167 109.7h38.3c3.5 0 6.4 2.9 6.4 6.4v63.8c0 3.5-2.9 6.4-6.4 6.4H167c-3.5 0-6.4-2.9-6.4-6.4V116c.1-3.5 2.9-6.3 6.4-6.3zM294.7 109.7H333c3.5 0 6.4 2.9 6.4 6.4v63.8c0 3.5-2.9 6.4-6.4 6.4h-38.3c-3.5 0-6.4-2.9-6.4-6.4V116c0-3.5 2.8-6.3 6.4-6.3z"
      />
      <path
        className="mediaLibrary_svg__st0"
        d="M96.8 186.2l-38.3 38.3 38.3 38.3m306.4-76.6l38.3 38.3-38.3 38.3"
      />
      <path
        className="mediaLibrary_svg__st1"
        d="M167 262.8h38.3c3.5 0 6.4 2.9 6.4 6.4V333c0 3.5-2.9 6.4-6.4 6.4H167c-3.5 0-6.4-2.9-6.4-6.4v-63.8c.1-3.5 2.9-6.4 6.4-6.4zM294.7 262.8H333c3.5 0 6.4 2.9 6.4 6.4V333c0 3.5-2.9 6.4-6.4 6.4h-38.3c-3.5 0-6.4-2.9-6.4-6.4v-63.8c0-3.5 2.8-6.4 6.4-6.4z"
      />
    </svg>
  );
}

export default SvgMediaLibrary;
