import { Interest } from "../../types/application";
import {
  ProductOption,
  RecommendationConfig,
} from "../../state/definitions/RecommendationState";
import { getIcons } from "../../icons/Icons";
import { PassData } from "../../types/table";

export function getPassesData(
  type: Interest,
  config: RecommendationConfig,
  passesSelected: number[]
): PassData[] {
  const passData: PassData[] = [];
  if (type === Interest.MOBILE) {
    // PASSES
    // only for mobile in usage
    const passes: ProductOption[] = config[type].passes;
    const passPrices: ProductOption<number>[] = config[type].passPrices;
    passes.forEach((v, i) => {
      const selectedIndex: number | undefined = passesSelected.indexOf(
        v.productId
      );
      const price: number =
        passesSelected &&
        (passesSelected.length === 0 || passesSelected[0] === v.productId)
          ? 0
          : passPrices[i].label;

      passData.push({
        id: v.productId,
        name: v.label,
        selectedPosition: selectedIndex !== undefined ? selectedIndex : -1,
        image: getIcons(v.productId)[0],
        price,
      });
    });
  }
  return passData;
}
