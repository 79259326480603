import * as React from "react";

function SvgPing(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="ping_svg__ea026a33-241f-4d35-b53f-68ab020977f7"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.ping_svg__st0{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path className="ping_svg__st0" d="M117 101L72 56 36 92V20h72L72 56" />
      <path className="ping_svg__st0" d="M75 91l45 45 36-36v72H84l36-36" />
      <path fill="none" d="M0 0h192v192H0z" />
    </svg>
  );
}

export default SvgPing;
