import React from "react";
import styled from "styled-components";
import TileButton from "../form/TileButton";
import ExternalLink from "../links/ExternalLink";
import JustifiedColumnsContainer from "../grid/JustifiedColumnsContainer";
import Column from "../grid/Column";
import { OuterLinks } from "../../data/definitions/OuterLinks";
import useAppReload from "../../hooks/useAppHelper/useAppReload";
import useVauoid from "../../hooks/useVauoid";
import { LoadableResources, loadConfigData } from "../../helper/loadConfigData";
import useAuth from "../../hooks/useAuth";

interface KittButtonProps {
  className?: string;
  invisible?: boolean;
}

const KittButton: React.FunctionComponent<KittButtonProps> = ({
  className,
}) => {
  const [, reload] = useAppReload();
  const [vauoid] = useVauoid();
  const { logout } = useAuth();

  const kittClick = () => {
    loadConfigData(
      () => [true, true],
      LoadableResources.STAT_KITT_CLICK,
      reload,
      [vauoid]
    )().catch((e) => {
      if (e.code && e.code === 401) {
        logout();
      }
    });
  };

  return (
    <div className={className}>
      <div className={"page-footer-placeholder-element"}>
        <JustifiedColumnsContainer>
          <Column withoutBottomMargin minWidth={"150px"}>
            <ExternalLink to={OuterLinks.KITT} onClick={kittClick}>
              <TileButton value={true}>Weiter mit KITT</TileButton>
            </ExternalLink>
          </Column>
        </JustifiedColumnsContainer>
      </div>
      <div className={"page-footer-absolute-kitt-container"}>
        <JustifiedColumnsContainer>
          <Column withoutBottomMargin minWidth={"150px"}>
            <ExternalLink to={OuterLinks.KITT} onClick={kittClick}>
              <TileButton value={true}>Weiter mit KITT</TileButton>
            </ExternalLink>
          </Column>
        </JustifiedColumnsContainer>
      </div>
    </div>
  );
};

export default styled(KittButton)`
  position: relative;
  display: inline-block;
  vertical-align: top;
  visibility: ${({ invisible }) => (invisible ? "hidden" : "visible")};

  @media (max-width: 895px) {
    display: ${({ invisible }) => (invisible ? "none" : "inline-block")};
    margin-top: 15px;
    width: 100%;
    height: initial;
    .page-footer-absolute-kitt-container {
      top: -15px;
    }
  }
  @media (min-width: 895px) {
    margin-left: ${({ invisible }) => (invisible ? "0" : "50px")};
    margin-right: ${({ invisible }) => (invisible ? "50px" : "0")};
    height: 0;
    .page-footer-absolute-kitt-container {
      top: -10px;
    }
  }

  .page-footer-placeholder-element {
    display: inline-block;
    visibility: hidden;
    height: 0;
  }

  .page-footer-absolute-kitt-container {
    position: absolute;
    width: 100%;

    .tile-button {
      height: unset;
      padding: 15px;
      display: inline-block;
    }
  }
`;
