import * as React from "react";

function SvgTvChannels(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="tvChannels_svg___x34_e47814a-7a7b-411a-9186-d73e85a42220"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.tvChannels_svg__st0{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className="tvChannels_svg__st0"
        d="M60 73.7v28.4c0 1.3 1 2.4 2.3 2.5.6 0 1.1-.2 1.5-.5l.3-.2 18.2-13.5.5-.4c1.1-.7 1.4-2.2.7-3.3-.2-.3-.4-.5-.7-.7l-.5-.4-18-13.4c-.9-1-2.5-1.1-3.5-.1-.5.5-.8 1.2-.8 1.9v.2"
      />
      <path
        className="tvChannels_svg__st0"
        d="M123.7 140l-94.9-8.7c-4.8 0-8.6-3.9-8.6-8.7V53.3c0-4.8 3.8-8.6 8.6-8.7l94.9-8.7c4.8 0 8.6 3.9 8.6 8.7v86.7c0 4.8-3.8 8.7-8.6 8.7zM39 148l66 8M72 139v10M133.5 132h8c3.9 0 7-3.1 7-7V51.3c0-4-3.9-7.3-8-7.3h-7M151.5 52h6.2c3.3 0 6.5 2.7 6.5 6v60c.1 3.2-2.3 5.8-5.5 6h-7.2"
      />
      <path fill="none" d="M0 0h192v192H0z" />
    </svg>
  );
}

export default SvgTvChannels;
