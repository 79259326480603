import { SlidePages } from "../../constants/SlidePages";

export function getCategoryFromAllSlides(slideId?: number) {
  if (slideId === undefined) {
    return undefined;
  }
  const slide = Object.values(SlidePages).find((v) => v.id === slideId);
  if (!!slide) {
    return slide.category;
  }
  return undefined;
}
