import * as React from "react";

function SvgSync(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="sync_svg___x36_2896120-2a13-4af8-932f-440d97f0fc60"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.sync_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <g id="sync_svg___x30_421fc68-95f0-4648-9f68-82331e9d1ba8">
        <g id="sync_svg___x30_e758250-0bfa-4a59-bea1-d6a68eb5fd39">
          <path
            className="sync_svg__st0"
            d="M79.7 492.5V367.8h123.9M420.3 7.5V132H296.4"
          />
          <path
            className="sync_svg__st0"
            d="M39.3 286.2C22.2 169.9 102.6 61.7 218.9 44.6c77.2-11.4 154.5 20.5 201.4 82.9M460.6 213.9c17.1 116.3-63.2 224.5-179.6 241.6-77.3 11.4-154.6-20.4-201.4-82.9"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSync;
