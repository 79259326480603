import React from "react";
import Slider from "../form/Slider";
import LittleGreyButton from "../form/LittleGreyButton";
import { useFormState } from "../../hooks/useForm";
import useBootstrap from "../../hooks/useBootstrap";
import ProviderQuestionBlock from "../form/ProviderQuestionBlock";
import { useProviderQuestion } from "../../hooks/useFormHelper/useProviderQuestion";
import styled from "styled-components";
import { StyledComponentProps } from "../../types/default";

const MobileCurrentConditionsPage: React.FC<StyledComponentProps> = ({
  className,
}) => {
  const { formConfig } = useBootstrap();

  // Setup slider values
  const [mobileCurrentPrice, setMobileCurrentPrice] =
    useFormState("mobileCurrentPrice");
  const [mobileCurrentDataVolume, setMobileCurrentDataVolume] = useFormState(
    "mobileCurrentDataVolume"
  );

  // Setup need more dataVolume
  const [needAdditionalDataVolume, setNeedAdditionalDataVolume] = useFormState(
    "needAdditionalDataVolume"
  );
  const [
    providerIdState,
    currentContractEndDateState,
    withoutCurrentContractEndState,
  ] = useProviderQuestion(
    "providerMobileId",
    "mobileCurrentContractEnd",
    "mobileWithoutCurrentContractEnd"
  );

  return (
    <div className={className}>
      <Slider
        min={0}
        max={150}
        value={mobileCurrentPrice ? mobileCurrentPrice : 0}
        onChange={setMobileCurrentPrice}
        afterValue={"€"}
        description={"Preis"}
        padding={"50px 0"}
      />
      <Slider
        min={0}
        max={50}
        value={mobileCurrentDataVolume ? mobileCurrentDataVolume : 0}
        onChange={setMobileCurrentDataVolume}
        afterValue={"GB"}
        description={"Datenvolumen"}
        step={1}
        maxString={">50"}
        padding={"50px 0"}
      />
      <div className={"more-data-question"}>
        <span>Brauchst Du zusätzliches Datenvolumen pro Monat ? </span>
        <LittleGreyButton
          onChange={setNeedAdditionalDataVolume}
          value={needAdditionalDataVolume}
        >
          Ja
        </LittleGreyButton>
      </div>
      <ProviderQuestionBlock
        contractEndDateState={currentContractEndDateState}
        withoutCurrentContractEndState={withoutCurrentContractEndState}
        selectedProviderState={providerIdState}
        providers={formConfig.mobileProviders}
      />
    </div>
  );
};

export default styled(MobileCurrentConditionsPage)`
  .more-data-question {
    font-size: 24px;
    margin: 30px 50px;
  }
`;
