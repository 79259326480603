import React, { useState } from "react";
import styled from "styled-components";
import ModalInner, { ModalInnerProps } from "./ModalInner";

interface ModalProps extends Partial<Omit<ModalInnerProps, "onChange">> {
  openButton?: (
    changeValue: (value: boolean) => void,
    value: boolean
  ) => JSX.Element;
  isOpen?: boolean;
  setIsOpen?: (newValue: boolean) => void;
}

const Modal: React.FC<ModalProps> = (props) => {
  const { openButton, isOpen, setIsOpen, ...modalInnerProps } = props;
  const [isOpenState, setIsOpenState] = useState(false);

  const onChange = (value: boolean) => {
    setIsOpenState(value);
    if (setIsOpen) {
      setIsOpen(value);
    }
  };
  return (
    <>
      {openButton !== undefined && openButton(onChange, isOpenState)}
      {(isOpenState || isOpen) && (
        <ModalInner onChange={onChange} darkBackground {...modalInnerProps} />
      )}
    </>
  );
};

export default styled(Modal)``;
