import { LabelValuePair, WithsObject } from "../../types/application";
import { SimpleGigaKombiValues } from "./calculateGigaKombiValues";
import { DatabaseProduct } from "../../data/definitions/DatabaseProduct";
import {
  DISABLE_CYBER_ADVANTAGE,
  ENABLE_SKY_GIGA_ADVANTAGE,
} from "../../constants/default";
import { GIGA_KOMBI_CIRCLES_UNLIMITED_VALUE } from "../../components/elements/recommendationPage/gigakombi/GigaCircles";
import { DatabaseProductTypes } from "../../data/definitions/DatabaseProductTypes";

export function getCircleDataFromGigaValues(
  withsObject: WithsObject,
  values: SimpleGigaKombiValues,
  products: DatabaseProduct[] = []
): {
  circleData: LabelValuePair<string | JSX.Element>[];
  isPossible: boolean;
} {
  const circleData: LabelValuePair<string | JSX.Element>[] = [];
  const [price, data, sky] = values;

  const { mobile, iot, fixed, tv } = withsObject;
  let withsCount = 0;
  withsCount += mobile ? 1 : 0;
  // withsCount += iot ? 1 : 0;
  withsCount += fixed ? 1 : 0;
  withsCount += tv ? 1 : 0;

  if (withsCount > 1 && price > 0) {
    let label = "pro Monat";
    if (sky && ENABLE_SKY_GIGA_ADVANTAGE) {
      const skyDiscountPrice = Math.max(price - 5, 0);
      label += " oder ";
      if (skyDiscountPrice > 0) {
        label += "-" + skyDiscountPrice + " € & ";
      }
      label += "Sky";
    }
    circleData.push({
      value: "-" + price + " €",
      label: label,
    });
  }
  if (mobile && withsCount > 1 && data > 0) {
    if (data < 99) {
      circleData.push({
        value: data + " GB",
        label: "Datenvolumen",
      });
    } else {
      circleData.push({
        value: GIGA_KOMBI_CIRCLES_UNLIMITED_VALUE,
        label: "Datenvolumen",
      });
    }
  }
  if (mobile && fixed) {
    const hasBasicDSLProduct =
      products.findIndex(
        (v) =>
          v.productType === DatabaseProductTypes.fixed &&
          v.productName.toLowerCase().includes("basic")
      ) >= 0;
    if (!hasBasicDSLProduct) {
      circleData.push({
        value: "",
        label: "Mobile- & Euro-Flat fürs Festnetz",
      });
    }
  }

  if (mobile && withsCount > 1) {
    const hasGigaCubeProduct =
      products.findIndex(
        (v) =>
          v.productType === DatabaseProductTypes.fixed &&
          v.productName.toLowerCase().includes("cube")
      ) >= 0;
    if (!hasGigaCubeProduct && !DISABLE_CYBER_ADVANTAGE) {
      circleData.push({
        value: "",
        label: "Cyber-Versicherung für die Familie",
      });
    }
  }
  return { circleData, isPossible: circleData.length > 0 };
}
