import * as React from "react";

function SvgWindowedAppsColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="windowedApps_color_svg___x31_2928486-0589-472a-9d9f-4727beb60349"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".windowedApps_color_svg__st0{fill:none;stroke:#e60000;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10}"
        }
      </style>
      <path
        className="windowedApps_color_svg__st0"
        d="M115.9 44H140c4.4 0 7.9 3.6 7.9 8v16.1c0 4.4-3.6 7.9-7.9 7.9h-24.1c-4.4 0-7.9-3.6-7.9-7.9V52c0-4.4 3.6-8 7.9-8zM52 116h24.1c4.4 0 7.9 3.6 7.9 7.9V140c0 4.4-3.6 7.9-7.9 7.9H52c-4.4 0-8-3.6-8-7.9v-16c0-4.4 3.6-8 8-8zM54.5 44h19C79.3 44 84 48.7 84 54.5v35c0 5.8-4.7 10.5-10.5 10.5h-19C48.7 100 44 95.3 44 89.5v-35C44 48.7 48.7 44 54.5 44zM118.5 92h19c5.8 0 10.5 4.7 10.5 10.5v35c0 5.8-4.7 10.5-10.5 10.5h-19c-5.8 0-10.5-4.7-10.5-10.5v-35c0-5.8 4.7-10.5 10.5-10.5z"
      />
      <path
        d="M40 28h112c6.6 0 12 5.4 12 12v112c0 6.6-5.4 12-12 12H40c-6.6 0-12-5.4-12-12V40c0-6.6 5.4-12 12-12z"
        fill="none"
        stroke="#e60000"
        strokeWidth={8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path fill="none" d="M0 0h192v192H0z" />
    </svg>
  );
}

export default SvgWindowedAppsColor;
