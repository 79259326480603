import * as React from "react";
import { CSSProperties } from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import { StyledComponentProps, SVGComponent } from "../../../types/default";
import SVGIcon from "../../svgIcon/SVGIcon";

export interface NewsPanelItemInnerProps extends StyledComponentProps {
  title?: string;
  titleIcon?: SVGComponent;
  text?: string;
  items?: string[];
  useNumbers?: boolean;
  style?: CSSProperties;
  h2Style?: CSSProperties;
  background?: string;
}

const NewsPanelItemInner: React.FC<NewsPanelItemInnerProps> = ({
  title,
  text,
  items = [],
  className,
  style = {},
  h2Style = {},
  titleIcon,
}) => {
  return (
    <div className={className} style={style}>
      <div className={"news-panel-item-inner-container"}>
        {titleIcon && (
          <SVGIcon
            src={titleIcon}
            size={45}
            style={{ verticalAlign: "middle" }}
          />
        )}
        {title && <h2 style={h2Style}>{title}</h2>}
        {text && <p>{text}</p>}
        {(title || text) && items.length > 0 && <br />}
        {items.length > 0 && (
          <ul>
            {items.map((v, i) => {
              if (v.startsWith("http")) {
                return (
                  <li key={i}>
                    <a href={v} target={"_blank"} rel={"noopener noreferrer"}>
                      <span>{v}</span>
                    </a>
                  </li>
                );
              }

              return (
                <li key={i}>
                  <span>{v}</span>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default styled(NewsPanelItemInner)`
  background: ${({ background }) =>
    background ? background : Color.GREY_LIGHT8};
  color: ${Color.BLACK};
  width: 100%;
  text-align: center;
  padding: 10px 0;
  .news-panel-item-inner-container {
    width: 90%;
    display: inline-block;
    text-align: left;
    h2 {
      font-size: 30px;
      margin-bottom: 10px;
      display: inline-block;
      vertical-align: middle;
    }
    ul {
      margin-left: 10px;
      list-style: ${({ useNumbers }) => (useNumbers ? "decimal" : "disc")}
        inside;
    }
    li {
      ::marker {
        content: ${({ useNumbers }) => (useNumbers ? "unset" : "•")};
      }
      span {
        position: relative;
        left: 5px;
      }
      a {
        color: ${Color.RED};
        list-style: none;
      }
    }
  }
`;
