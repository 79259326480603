import * as React from "react";

function SvgChatColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <path
        d="M394.59 194.47s.86-4.39 1.12-5.84c4.94-28.95-15.18-56.54-15.18-56.54-11.45-17.65-26.57-32.79-44.4-44.46-19.45-12.76-41.03-22.16-63.79-27.81a251.211 251.211 0 00-76.93-7.2c-25.92 1.48-51.43 6.92-75.59 16.13-22.12 8.37-42.62 20.32-60.6 35.34-16.51 13.71-29.96 30.53-39.52 49.44-9.14 18.15-13.42 38.24-12.47 58.42.65 12.71 3.45 25.24 8.29 37.07 4.88 11.83 11.54 22.9 19.77 32.84a157.009 157.009 0 0029.56 27.46c11.29 8.16 34.86 18.05 48.58 23.82-17.8 40.47-59.39 64.32-59.39 64.32 17.03 1.37 34.17.7 51.03-1.97 11.95-1.93 23.69-4.9 35.09-8.87 0 0 14.07-4.85 54.22-26.32l5.2-2.75m200.5-161.53l-7.11-2.01a195.3 195.3 0 00-60.03-5.56c-20.26 1.16-40.2 5.44-59.07 12.67-17.3 6.6-33.3 16-47.33 27.81-12.91 10.77-23.41 23.97-30.87 38.8a92.563 92.563 0 00-9.57 45.82c.79 15.75 5.69 31.06 14.23 44.48 8.94 13.84 20.76 25.72 34.7 34.88 15.2 10.06 32.09 17.48 49.92 21.94 19.57 4.95 39.84 6.83 60.03 5.56h1.94c6.81 4.5 13.95 8.5 21.37 11.99 9.02 4.24 18.42 7.67 28.1 10.23 11.2 2.9 22.7 4.58 34.29 5.01a167.4 167.4 0 0039.9-3.09s-41.88-23.54-43.06-47.85c9.91-5.62 19.15-12.29 27.53-19.9 7.93-7.17 14.9-15.29 20.73-24.16 5.61-8.49 9.9-17.74 12.76-27.43 7.3-25.1 3.34-52.02-10.91-74.15-5.8-9.09-12.92-17.33-21.15-24.47-16.95-13.03-35.99-23.27-56.36-30.34"
        fill="none"
        stroke="#a8b400"
        strokeWidth={24.173}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgChatColor;
