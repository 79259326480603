import * as React from "react";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { StyledComponentProps } from "../../types/default";
import { useOfferState } from "../../hooks/useOffer";
import OfferEntriesContainer from "../elements/recommendationPage/summary/OfferEntriesContainer";
import useEndPrice from "../../hooks/useOfferHelper/useEndPrice";
import OfferEntry from "../elements/recommendationPage/summary/OfferEntry";
import { useNewOffer } from "../../hooks/useNewOffer";
import { useInterestsValues } from "../../hooks/useFormHelper/useInterests";
import { getModeKeyFromInterest } from "../../helper/recommendation/mapping";
import { RecommendationMode } from "../../types/application";
import { ProductLineOfferState } from "../../state/definitions/ProductLineOfferState";
import { mftiSort } from "../../helper/recommendation/mftiSort";
import GigaKombiMainAdvantagesContainer from "../elements/recommendationPage/gigakombi/GigaKombiMainAdvantagesContainer";

export interface Props extends StyledComponentProps {}

const SummaryRecommendationPage = ({ className }: Props) => {
  const [offerEntryIds] = useOfferState("offerEntryIds");
  const [wholeEndPrices, wholeOncePrice] = useEndPrice();
  const recommendationModes = useInterestsValues();
  const { getSelectedEntryProperty } = useNewOffer();

  const filteredOfferEntryIds = useMemo(() => {
    const restrictorFct = (entry: ProductLineOfferState) => {
      const interest = entry.type;
      const modeKey = getModeKeyFromInterest(interest);
      return recommendationModes[modeKey] === RecommendationMode.SELECTABLE;
    };
    const sortFct = (
      entryA: ProductLineOfferState,
      entryB: ProductLineOfferState
    ) => {
      return mftiSort(entryA.type, entryB.type);
    };
    return getSelectedEntryProperty("rowId", restrictorFct, sortFct);
  }, [offerEntryIds]);

  const [isEndPriceOpen, setIsEndPriceOpen] = useState(false);
  const [selectedGigaKombi] = useOfferState("selectedGigaKombi");

  return (
    <div className={className}>
      <OfferEntry withLabel />
      <OfferEntriesContainer offerEntryIds={filteredOfferEntryIds} />
      <OfferEntry
        isOpen={isEndPriceOpen}
        setIsOpen={setIsEndPriceOpen}
        title={"Gesamtpreis pro Monat"}
        monthlyPriceArray={wholeEndPrices}
        oncePrice={wholeOncePrice}
        main
      />
      {selectedGigaKombi && <GigaKombiMainAdvantagesContainer />}
    </div>
  );
};

export default styled(SummaryRecommendationPage)`
  width: calc(100% - 150px);
  height: 100%;
  margin: 0 75px;
  overflow: auto;
`;
