import * as React from "react";
import { SVGComponent } from "../../types/default";

const SvgMobileSecurity: SVGComponent = (props) => {
  const { color = "#fff" } = props;
  const { color2 = "#e60000" } = props;
  return (
    <svg
      id="mobileSecurity_svg___x30_1d074ad-3419-40d8-a4a2-3a686c94f98c"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.mobileSecurity_svg__st0{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <g id="mobileSecurity_svg___x33_9bbb6be-0d7a-4bfa-8252-1f40bf874658">
        <path className="mobileSecurity_svg__st0" d="M84 156h24" />
        <path fill="none" d="M0 0h192v192H0z" />
        <path
          className="mobileSecurity_svg__st0"
          d="M64 20h64c6.6 0 12 5.4 12 12v128c0 6.6-5.4 12-12 12H64c-6.6 0-12-5.4-12-12V32c0-6.6 5.4-12 12-12z"
        />
      </g>
      <path
        id="mobileSecurity_svg___x37_49cf875-4c70-4a87-987c-5c67ab21b6e4"
        d="M140 59.4l-28 6.3v36.1c0 3.2 2.3 6.7 5 10.3 2.5 3.2 5.9 6.5 10.3 9.8 5.5 4.1 10 7.1 12.7 8.5 2.7-1.4 7.2-4.4 12.7-8.5 4.4-3.3 7.9-6.6 10.3-9.8 2.8-3.6 5-7.1 5-10.3V65.6l-28-6.2z"
        fill={color2}
        stroke={color}
        strokeWidth={8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        stroke={color}
        strokeWidth={8}
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M140 86.4v16"
      />
      <circle className="mobileSecurity_svg__st0" cx={140} cy={86.4} r={4} />
      <circle className="mobileSecurity_svg__st0" cx={140} cy={86.4} r={2} />
    </svg>
  );
};

export default SvgMobileSecurity;
