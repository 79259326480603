import React, { CSSProperties } from "react";
import styled from "styled-components";
import { Color } from "../../../../constants/color";
import MiniGigaCircles from "./MiniGigaCircles";
import GigaKombiSVG from "./GigaKombiSVG";
import { GigaKombiEntry } from "../../../../types/application";

interface MiniGigaKombiProps {
  className?: string;
  selected?: boolean;
  style?: CSSProperties;
  gigaKombiEntry: GigaKombiEntry;
  starText?: string;

  backgroundColor?: string;
  withGigaKombiText?: boolean;
  onChange?: (value: boolean) => void;
}

const MiniGigaKombi: React.FC<MiniGigaKombiProps> = ({
  className,
  style,
  gigaKombiEntry: { mobile, iot, fixed, tv, circleData },
  withGigaKombiText = true,
  onChange,
  selected,
  starText,
}) => {
  const onClick = (_: React.MouseEvent) => {
    if (!!onChange) {
      onChange(!selected);
    }
  };
  return (
    <div className={className} style={style} onClick={onClick}>
      {withGigaKombiText && (
        <div className={"giga-paragraph"}>
          <p>Deine GigaKombi: </p>
        </div>
      )}
      <div className={"giga-icon-column"}>
        <GigaKombiSVG withsObject={{ mobile, iot, fixed, tv }} size={60} />
      </div>
      <div className={"giga-prices"}>
        <MiniGigaCircles circleData={circleData} withStar={false} />
      </div>
      {starText !== undefined && (
        <p className={"giga-star-text"}>{"(" + starText + ")"}</p>
      )}
    </div>
  );
};

export default styled(MiniGigaKombi)`
  position: relative;
  display: block;
  max-width: 612px;
  margin: 0 auto;
  padding-bottom: ${({ starText }) => (!!starText ? "12px" : "0")};

  background-color: ${({ selected }) => (selected ? Color.RED : Color.GREY3)};
  color: ${Color.WHITE};

  text-align: left;
  overflow: hidden;

  width: 425px;
  height: 94px;

  .giga-paragraph {
    font-size: 20px;
    display: block;
    vertical-align: top;
    margin: 2px 5px;
    width: calc(100% - 4px);
    p {
      padding: 0;
    }
  }

  .giga-icon-column {
    position: relative;
    display: inline-block;
    margin: 5px 5px 5px 10px;

    vertical-align: top;
    width: 60px;
    text-align: left;

    .icon {
      vertical-align: middle;
    }
  }

  .giga-prices {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 0 5px;
  }

  .giga-star-text {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    margin: 2px 5px;
    text-align: right;
  }
`;
