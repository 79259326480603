export function deepCopy<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}

export function get2FixedNumber(value: number) {
  return parseFloat(value.toFixed(2));
}

export function getRandomInt(
  min: number = 0,
  max: number = Number.MAX_SAFE_INTEGER
) {
  const newMin = Math.ceil(min);
  const newMax = Math.floor(max);
  return Math.floor(Math.random() * (newMax - newMin + 1)) + newMin;
}

export function defaultStringSort(a: string, b: string) {
  return a < b ? -1 : a > b ? 1 : 0;
}
