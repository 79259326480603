import React, { CSSProperties } from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";

interface CountDisplayButtonProps {
  className?: string;
  label?: string;
  valueText?: string;
  style?: CSSProperties;
  onChange: (value: boolean) => void;
  value: boolean;
}

const CountDisplayButton: React.FC<CountDisplayButtonProps> = ({
  className,
  label,
  value,
  valueText,
  onChange,
}) => {
  const onClick = (_: React.MouseEvent) => {
    onChange(!value);
  };

  return (
    <div className={className} onClick={onClick}>
      <span className={"label"}>{label}</span>
      <span className={"value"}>{valueText}</span>
    </div>
  );
};

export default styled(CountDisplayButton)`
  width: -moz-calc(50% - 4px);
  width: -webkit-calc(50% - 4px);
  width: -o-calc(50% - 4px);
  width: calc(50% - 4px);

  margin: 2px;
  min-width: 125px;
  display: inline-block;
  background: ${Color.GREY_LIGHT};
  :hover {
    cursor: pointer;
  }
  .label {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 10px 10px 5px 10px;

    background: ${Color.GREY_LIGHT};
    color: ${Color.WHITE};

    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .value {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5px 10px 10px 10px;

    color: ${Color.WHITE};

    font-size: 24px;
    text-align: right;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
