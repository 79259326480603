import React from "react";
import styled from "styled-components";
import useRecommendation from "../../../../../hooks/useRecommendation";
import getColumnPriceObjects from "../../../../../helper/getColumnPriceObjects";
import { mapProductOptionToValue } from "../../../../../helper/mapOptionToValue";
import { getBubbleEntriesFromAdvantageIds } from "../../../../../helper/getBubbleEntriesFromAdvantageIds";
import {
  BubbleEntry,
  Interest,
  LabelValuePair,
  RecommendationMode,
} from "../../../../../types/application";
import { useNewOffer } from "../../../../../hooks/useNewOffer";
import { useOfferState } from "../../../../../hooks/useOffer";
import { useNewOfferState } from "../../../../../hooks/useOfferHelper/useNewOfferState";
import { useDataVolume } from "../../../../../hooks/useOfferHelper/useDataVolume";
import { useInterestValue } from "../../../../../hooks/useFormHelper/useInterests";
import useProducts from "../../../../../hooks/useRecommendationHelper/useProducts";
import useSubProducts from "../../../../../hooks/useRecommendationHelper/useSubProducts";
import { useGigaKombiWiths } from "../../../../../hooks/useOfferHelper/useGigaKombiWiths";
import getMissingGigaKombiData from "../../../../../helper/getMissingGigaKombiData";
import MiniPrintGigaKombi from "../MiniPrintGigaKombi";
import RecommendationPrintColumn from "./RecommendationPrintColumn";
import { combineMonthlyPriceArray } from "../../../../../helper/priceCalculation/combineMonthlyPriceArray";

interface RecommendationPrintColumnContainerProps {
  className?: string;
  offerEntryId: string;
}

const RecommendationPrintColumnContainer: React.FC<RecommendationPrintColumnContainerProps> =
  ({ className, children, offerEntryId }) => {
    const { getNewOfferType } = useNewOffer();
    const type = getNewOfferType(offerEntryId);
    const [selectedGigaKombi] = useOfferState("selectedGigaKombi");
    const [gigaKombiDataVolume] = useOfferState("gigaKombiDataVolume");
    const [currentPrice] = useNewOfferState("currentPrice", offerEntryId);
    const [mainRow] = useNewOfferState("mainRow", offerEntryId);
    const gigaKombiIsEnable = selectedGigaKombi && mainRow;

    const [selectId] = useNewOfferState("selectId", offerEntryId);

    const { getRecommendationMode } = useInterestValue();
    const mode = getRecommendationMode(type);
    const [products, priceArrays] = useProducts(type);

    const product = products[selectId];
    const priceArray = priceArrays[selectId] ?? {};

    const subProducts = useSubProducts(offerEntryId, selectedGigaKombi);

    const { dataVolumeValue, subProductDataVolume } = useDataVolume(
      offerEntryId,
      subProducts
    );

    const columnPrices = combineMonthlyPriceArray([
      priceArray,
      subProducts.priceInformation.monthly,
    ]);
    const columnPriceObjects: LabelValuePair[] = getColumnPriceObjects(
      columnPrices,
      subProducts.priceInformation.once
    );

    let bubbleEntries: BubbleEntry[] = [];
    const { config } = useRecommendation();

    const advantageIds: number[] = subProducts
      ? mapProductOptionToValue(subProducts.advantages)[selectId]
      : [];
    if (advantageIds !== undefined) {
      bubbleEntries = getBubbleEntriesFromAdvantageIds(
        advantageIds,
        config.advantages
      );
    }

    const { getGigaKombiWiths, currentProducts } = useGigaKombiWiths();
    const withsObject = getGigaKombiWiths();

    const gigaKombiData = getMissingGigaKombiData(withsObject, currentProducts);
    const gigaKombis = !selectedGigaKombi
      ? gigaKombiData.map((v, i) => {
          if (v.circleData.length < 1) {
            return undefined;
          }
          return (
            <MiniPrintGigaKombi
              key={i}
              gigaKombiText={i === 0 ? "Mögliche GigaKombi-Vorteile*:" : ""}
              backgroundColor={"transparent"}
              gigaKombiEntry={v}
              starText={
                i + 1 === gigaKombiData.length ? "tarifabhängig" : undefined
              }
            />
          );
        })
      : [];

    const gigaKombiId = subProducts.additionalTable.additionalData.gigaKombiId;
    let gigaKombiRebate = 0;
    let gigaDiscountData = undefined;
    if (
      !!subProducts &&
      !!priceArray &&
      gigaKombiId >= 0 &&
      selectedGigaKombi
    ) {
      const gigaKombiRebateId =
        subProducts.additionalTable.data[gigaKombiId]?.labelId;
      gigaKombiRebate =
        subProducts.additionalTable.data[gigaKombiId]?.prices[
          gigaKombiRebateId
        ] ?? 0;
      gigaDiscountData = Object.assign({}, priceArray, {
        0: priceArray[0] + gigaKombiRebate,
      });
    }

    return (
      <RecommendationPrintColumn
        mode={mode}
        type={type}
        product={product}
        priceArray={priceArray}
        discountArray={gigaDiscountData}
        currentPrice={currentPrice}
        subProducts={subProducts}
        bubbleEntries={bubbleEntries}
        columnPriceObjects={columnPriceObjects}
        dataVolume={dataVolumeValue}
        extraDataVolume={
          gigaKombiIsEnable
            ? subProductDataVolume + gigaKombiDataVolume
            : subProductDataVolume
        }
      >
        {mode === RecommendationMode.INTERESTING && type !== Interest.IOT && (
          <> {gigaKombis}</>
        )}
      </RecommendationPrintColumn>
    );
  };

export default styled(RecommendationPrintColumnContainer)``;
