import {
  Availability,
  Technology,
} from "../../state/definitions/AvailabilityState";
import { IndexStructureObject } from "../../types/util";

export default (data: Availability): Availability => {
  const keyNames = Object.keys(Technology);
  const dataKeys = Object.keys(data);
  const dataValues = Object.values(data);

  const sortedData: Availability | IndexStructureObject = {};

  keyNames.forEach((v) => {
    const dataKeyIndex = dataKeys.findIndex((dkV) => dkV === v);
    sortedData[v as Technology] = dataValues[dataKeyIndex];
  });
  return sortedData as Availability;
};
