import * as React from "react";

function SvgWifiCalling(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="wifiCalling_svg__ICON"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.wifiCalling_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        id="wifiCalling_svg___x36_3d7d15b-97ae-412c-b275-7b44fe9c4894"
        className="wifiCalling_svg__st0"
        d="M279.8 275.3c-16.5-16.4-43-16.4-59.5 0"
      />
      <path
        id="wifiCalling_svg___x39_a49d324-136b-4c27-a44e-7eb98dce18d6"
        className="wifiCalling_svg__st0"
        d="M309.5 237.2c-32.9-32.9-86.3-32.9-119.2 0"
      />
      <path
        id="wifiCalling_svg___x34_32c97e9-7f2a-4443-a4c1-cde9e28cf26b"
        className="wifiCalling_svg__st0"
        d="M339.3 197.2C290 147.9 210 147.9 160.6 197"
      />
      <path
        className="wifiCalling_svg__st0"
        d="M211.7 441.4h76.6M147.9 7.5h204.2c21.1 0 38.3 17.1 38.3 38.3v408.4c0 21.1-17.1 38.3-38.3 38.3H147.9c-21.1 0-38.3-17.1-38.3-38.3V45.8c0-21.2 17.1-38.3 38.3-38.3z"
      />
    </svg>
  );
}

export default SvgWifiCalling;
