import React from "react";
import styled from "styled-components";
import { useOfferState } from "../../hooks/useOffer";
import { StyledComponentProps } from "../../types/default";
import usePrint from "../../hooks/usePrint";
import PrintHeadline from "../headline/PrintHeadline";
import RecommendationPrintTable from "../elements/recommendationPage/print/RecommendationPrintTable";
import PrintTopLine from "../elements/recommendationPage/print/PrintTopLine";
import PrintBottomLine from "../elements/recommendationPage/print/PrintBottomLine";

const PrintOfferEntryPage: React.FC<StyledComponentProps> = ({ className }) => {
  const { isLoaded } = usePrint();
  const [printOfferEntryIds] = useOfferState("printOfferEntryIds");
  return (
    <div className={className}>
      <PrintTopLine />
      <div className={"print-page-container"}>
        <PrintHeadline>Unsere Empfehlung für Dich</PrintHeadline>
        {!isLoaded && (
          <>{"Daten wurden noch nicht erhalten. Ein Fehler liegt vor!"}</>
        )}
        {isLoaded && (
          <RecommendationPrintTable offerEntryIds={printOfferEntryIds} />
        )}
      </div>
      <PrintBottomLine />
    </div>
  );
};

export default styled(PrintOfferEntryPage)`
  .print-page-container {
    height: 748px;
    overflow: hidden;
    padding: 0 30px 20px 30px;
  }
`;
