import React from "react";
import { useDevicesCount } from "../../hooks/useFormHelper/useDevicesCount";
import CalculationDisplayContainer from "../elements/fixedDevicesPage/CalculationDisplayContainer";
import ImageCounterGrid from "../form/ImageCounterGrid";
import {
  FixedDeviceKeys,
  FormConfigLabelIdsDeviceMap,
} from "../../constants/Devices";
import { CountObject } from "../../types/application";

const FixedDevicesPage = () => {
  const { getDeviceCount, deviceCount, deviceNeededBandwidth } =
    useDevicesCount();

  const displayData: CountObject[] = FixedDeviceKeys.map((v) =>
    getDeviceCount(v, FormConfigLabelIdsDeviceMap[v])
  );

  return (
    <>
      <ImageCounterGrid maxWidth={1000} data={displayData} />
      <CalculationDisplayContainer
        data={[
          {
            label: "Empfohlene Geschwindigkeit:",
            value: String(deviceNeededBandwidth) + " Mbit/s",
          },
          {
            label: "Anzahl der Geräte insgesamt:",
            value: String(deviceCount),
          },
        ]}
      />
    </>
  );
};

export default FixedDevicesPage;
