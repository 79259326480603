import * as React from "react";
import TabPass from "./TabPass";
import AFOTableContainer from "../afoTable/AFOTableContainer";
import { RecommendationTableState } from "../../../hooks/useRecommendationHelper/useRecommendationTablesOfferState";
import { SubProducts } from "../../../types/table";
import {
  AdditionalTableHeadlines,
  FurtherTableHeadlines,
  OnceTableHeadlines,
} from "../../../constants/AFOTableHeadlines";
import { ENABLE_RECOMMENDATION_PASSES } from "../../../constants/default";

export interface AFOTabsProps {
  mainProductPrice?: number;
  subProducts: SubProducts;
  tableStateObject: RecommendationTableState;
  currentTabIndex: number;
}

const AFOTabs: React.FC<AFOTabsProps> = ({
  currentTabIndex,
  mainProductPrice,
  subProducts,
  tableStateObject,
}) => {
  return (
    <>
      {currentTabIndex === 0 && (
        <AFOTableContainer
          headers={AdditionalTableHeadlines}
          data={subProducts.additionalTable.data}
          additionalDataObject={subProducts.additionalTable.additionalData}
          defaultDataObject={subProducts.additionalTable.standardObject}
          mainProductPrice={mainProductPrice}
          changeLabelValue={tableStateObject.additional.labels.setter}
          changeCountId={tableStateObject.additional.countIds.setter}
          changePriceValue={tableStateObject.additional.prices.setter}
          changeLabelId={(value: number, index: number) => {
            if (
              index ===
              subProducts.additionalTable.additionalData.regioDiscountId
            ) {
              tableStateObject.additional.regioDiscountLabelId.setter(value);
            }
            if (
              index === subProducts.additionalTable.additionalData.gigaKombiId
            ) {
              tableStateObject.additional.gigaKombiLabelId.setter(value);
            }
          }}
          changeStartValue={tableStateObject.additional.starts.setter}
        />
      )}
      {currentTabIndex === 1 && (
        <AFOTableContainer
          headers={FurtherTableHeadlines}
          data={subProducts.furtherProductsTable.data}
          mainProductPrice={mainProductPrice}
          changeLabelId={tableStateObject.further.labelIds.setter}
          changeLabelValue={tableStateObject.further.lastLabel.setter}
          changeCountId={tableStateObject.further.countIds.setter}
          changeCountValue={tableStateObject.further.lastCount.setter}
          changePriceValue={tableStateObject.further.lastPrice.setter}
        />
      )}
      {currentTabIndex === 2 && (
        <AFOTableContainer
          headers={OnceTableHeadlines}
          data={subProducts.singlePaymentTable.data}
          mainProductPrice={mainProductPrice}
          changeLabelValue={tableStateObject.singlePayment.labels.setter}
          changePriceValue={tableStateObject.singlePayment.prices.setter}
        />
      )}
      {ENABLE_RECOMMENDATION_PASSES &&
        currentTabIndex === 3 &&
        subProducts.passes.title && (
          <TabPass
            data={subProducts.passes.data}
            togglePass={tableStateObject.passes.selected.setter}
          />
        )}
    </>
  );
};

export default AFOTabs;
