import { StyledComponentProps } from "../../types/default";
import SVGIcon, { SVGIconProps } from "./SVGIcon";
import styled from "styled-components";
import React, { useState } from "react";
import { hexToRGB, rgbToHex } from "../../helper/getHEXNumber";
import { getRandomInt } from "../../helper/default";

interface SVGIconContainerDEVProps extends StyledComponentProps, SVGIconProps {
  name?: string;
}

function calcColor(startColor: string) {
  const thisColor = hexToRGB(startColor);
  // if(thisColor.r>0 && thisColor.g === 0 && thisColor.b === 0){
  //   thisColor.r = thisColor.r -1;
  // }
  const v = 30;
  if (thisColor.r <= 0 && thisColor.b <= 0 && thisColor.g <= 0)
    return getRandomRGB();
  if (thisColor.r >= 255 && thisColor.b <= 0)
    thisColor.g = Math.min(thisColor.g + v, 255);
  if (thisColor.g >= 255 && thisColor.b <= 0)
    thisColor.r = Math.max(thisColor.r - v, 0);
  if (thisColor.g >= 255 && thisColor.r <= 0)
    thisColor.b = Math.min(thisColor.b + v, 255);
  if (thisColor.b >= 255 && thisColor.r <= 0)
    thisColor.g = Math.max(thisColor.g - v, 0);
  if (thisColor.b >= 255 && thisColor.g <= 0)
    thisColor.r = Math.min(thisColor.r + v, 255);
  if (thisColor.r >= 255 && thisColor.g <= 0)
    thisColor.b = Math.max(thisColor.b - v, 0);
  const newColor = rgbToHex(thisColor.r, thisColor.g, thisColor.b);
  // console.log(thisColor, newColor);
  return newColor;
}

function getRandomColor() {
  const r = getRandomInt(0, 255);
  const g = getRandomInt(0, 255);
  const b = getRandomInt(0, 255);
  return rgbToHex(r, g, b);
}

function getRandomRGB() {
  const r = getRandomInt(0, 1);
  const g = getRandomInt(0, 1);
  const b = getRandomInt(0, 1);
  return rgbToHex(r ? 0 : 255, g ? 0 : 255, b ? 0 : 255);
}

export const SVGIconContainerDEV = styled(
  ({ className, src, name }: SVGIconContainerDEVProps) => {
    const [currentColor, setCurrentColor] = useState(getRandomColor);
    // const [currentColor, setCurrentColor] = useState("#000");
    const [currentColor2, setCurrentColor2] = useState(getRandomColor);
    // const [currentColor2, setCurrentColor2] = useState("#fff");

    return (
      <div className={className}>
        <SVGIcon src={src} color={currentColor} color2={currentColor2} />
        <span>{name}</span>
      </div>
    );
  }
)`
  padding: 5px;
  border: 2px white solid;
  display: inline-block;
  svg {
    display: block;
  }
  span {
    display: block;
  }
`;
