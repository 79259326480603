import React from "react";
import styled from "styled-components";
import ImageCounter from "./ImageCounter";
import Icons from "../../icons/Icons";
import { CountObject } from "../../types/application";

interface ImageCounterGridProps {
  className?: string;
  maxWidth?: number;
  data?: CountObject[];
}

const ImageCounterGrid: React.FC<ImageCounterGridProps> = ({
  className,
  data,
}) => {
  const maxCount = 10;
  const minCount = 0;

  const increaseCountOfObject = (d: CountObject) => {
    if (d.setter) {
      const newCount = d.count + 1 > maxCount ? maxCount : d.count + 1;
      d.setter(newCount);
    }
  };
  const decreaseCountOfObject = (d: CountObject) => {
    if (d.setter) {
      const newCount = d.count - 1 < minCount ? minCount : d.count - 1;
      d.setter(newCount);
    }
  };
  return (
    <div className={className}>
      {data &&
        data.map((d, i) => {
          return (
            <ImageCounter
              key={i}
              value={d.count}
              label={d.labelKey}
              image={d.icon ? d.icon : Icons.placeholder}
              prev={() => decreaseCountOfObject(d)}
              next={() => increaseCountOfObject(d)}
              max={maxCount}
            />
          );
        })}
    </div>
  );
};

export default styled(ImageCounterGrid)`
  margin: 0 auto;

  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth + "px" : "unset")};
  text-align: center;
`;
