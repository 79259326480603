import * as React from "react";

function SvgTelefonFlatGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="telefon_flat_grey_svg__ICON"
      x={0}
      y={0}
      viewBox="0 0 192 192"
    >
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="8"
        d="M154.27 36.11h-36.54M154.27 51.89h-36.54"
      />
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="7.8"
        d="M171.61 32.25V20.77H160"
      />
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="8"
        d="M160 20.77h-50.71a8.91 8.91 0 0 0-8.9 8.91v29.67a8.9 8.9 0 0 0 8.9 8.9h33.22l17.23 16.26V68.25h3a8.9 8.9 0 0 0 8.9-8.9v-27.1"
      />
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-width="8"
        d="M104.94 144.77c19.67 7 37.89 14.07 59.3 12.19M48.23 88.73c-7-19.67-14.07-37.9-12.19-59.31"
      />
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-width="8"
        d="M63.59 40.57q4.74 13.2 9.46 26.4l.24.68a3 3 0 0 1-1 3.4 35.32 35.32 0 0 1-10.15 5.34A63.17 63.17 0 0 1 48.7 79h0a2.92 2.92 0 0 0-2.49 3.78 98.26 98.26 0 0 0 24.17 39.76 99 99 0 0 0 40 24.06 2.92 2.92 0 0 0 3.76-2.48v0a61.94 61.94 0 0 1 2.66-13.35 34.8 34.8 0 0 1 5.36-10.09 3 3 0 0 1 3.43-1l.68.24 26.54 9.41c6.31 2.24 14.53 4.59 18.93 6.73.06 0 0 .13 0 .2.06.63.08 1.26.08 1.9a27.83 27.83 0 0 1-1.22 8.16c-3 9.74-11.1 20.2-20.66 23-24.12 7.19-49.54-.18-72-11.45a97 97 0 0 1-43.06-42.84C23.52 92.69 16.12 67.4 23.34 43.41c2.86-9.5 13.38-17.57 23.17-20.55l7.59-2a2.82 2.82 0 0 1 3.39 1.82z"
      />
    </svg>
  );
}

export default SvgTelefonFlatGrey;
