import { WithsObject } from "../../types/application";
import { DatabaseProduct } from "../../data/definitions/DatabaseProduct";
import { getStandardGigaKombiCalculation } from "./getStandardGigaKombiCalculation";
import { getClusteredProducts } from "./getClusteredProducts";
import { REBATE_MAX_DATAVOLUME } from "../../constants/default";

export type SimpleGigaKombiValues = [number, number, boolean]; //price,data,sky

export function calculateGigaKombiValues(
  withsObject: WithsObject,
  products: DatabaseProduct[]
): SimpleGigaKombiValues {
  const { mobile, iot, fixed, tv } = withsObject;
  let [discountPrice, withDataVolume] =
    getStandardGigaKombiCalculation(withsObject);

  const {
    mobile: mobileProductName = "",
    iot: iotProductName = "",
    fixed: fixedProductName = "",
    tv: tvProductName = "",
    // subProductNames,
  } = getClusteredProducts(products, (v) => v.productName);

  let discountDataVolume = 0;
  let withSky: boolean = false; // only on !m && (f && t) && (only cable-products)

  if (
    mobile &&
    withDataVolume &&
    !fixedProductName.toLowerCase().includes("gigacube")
  ) {
    if (mobileProductName.toLowerCase().includes("young m")) {
      discountDataVolume = 5;
    }
    if (mobileProductName.toLowerCase().includes("young l")) {
      discountDataVolume = 5;
    }
    if (mobileProductName.toLowerCase().includes("gigamobil xs")) {
      discountDataVolume = 2.5;
    }
    if (mobileProductName.toLowerCase().includes("gigamobil s")) {
      discountDataVolume = 6;
    }
    if (mobileProductName.toLowerCase().includes("gigamobil m")) {
      discountDataVolume = REBATE_MAX_DATAVOLUME;
    }
    if (mobileProductName.toLowerCase().includes("gigamobil l")) {
      discountDataVolume = REBATE_MAX_DATAVOLUME;
    }
  }

  // const fixedIsCable = fixedProductName.toLowerCase().includes("cable");
  // const tvIsCable = tvProductName.toLowerCase().includes("cable");

  const fixedIsCable =
    !fixedProductName ||
    fixedProductName.toLowerCase().includes("cable") ||
    fixedProductName.toLowerCase().includes("kabel");
  const tvIsCable =
    !tvProductName ||
    tvProductName.toLowerCase().includes("cable") ||
    tvProductName.toLowerCase().includes("kabel");
  const withFixedTV = fixed && tv;
  const bothIsCable = fixedIsCable && tvIsCable;

  // without tv
  if (mobile && fixed && !tv) {
    discountPrice = 10;
    withSky = false;
  }

  // without fixed
  if (mobile && !fixed && tv) {
    discountPrice = 5;
    withSky = false;
  }

  // without mobile
  if (!mobile && withFixedTV && !bothIsCable) {
    discountPrice = 0;
    withSky = false;
  }
  if (!mobile && withFixedTV && bothIsCable) {
    discountPrice = 5;
    withSky = true;
  }

  // with all
  if (mobile && withFixedTV) {
    discountPrice = 15;
    withSky = false;
  }
  return [discountPrice, discountDataVolume, withSky];
}
