import React from "react";
import styled from "styled-components";
import InterestingRecommendationColumn from "./InterestingRecommendationColumn";
import CurrentRecommendationColumn from "./CurrentRecommendationColumn";
import SelectableRecommendationColumn from "./SelectableRecommendationColumn";
import { Color } from "../../../../constants/color";
import ProductContainerSelector from "../../../form/horizontalSelector/ProductContainerSelector";
import ColumnHeadTile from "../ColumnHeadTile";
import { useNewOfferState } from "../../../../hooks/useOfferHelper/useNewOfferState";
import { useNewOffer } from "../../../../hooks/useNewOffer";
import { useInterestValue } from "../../../../hooks/useFormHelper/useInterests";
import useProducts from "../../../../hooks/useRecommendationHelper/useProducts";
import { useOfferState } from "../../../../hooks/useOffer";
import { combineMonthlyPriceArray } from "../../../../helper/priceCalculation/combineMonthlyPriceArray";
import { RecommendationMode } from "../../../../types/application";
import { useDataVolume } from "../../../../hooks/useOfferHelper/useDataVolume";
import useSubProducts from "../../../../hooks/useRecommendationHelper/useSubProducts";
import useRecommendationTablesOfferState from "../../../../hooks/useRecommendationHelper/useRecommendationTablesOfferState";
import Column, { ColumnProps } from "../../../grid/Column";
import { StyledComponentProps } from "../../../../types/default";
import { getTempIdKey } from "../../../../helper/recommendation/mapping";

export interface RecommendationColumnProps extends StyledComponentProps {
  offerEntryId: string;
  mainColumn?: boolean;
  informationLink?: string;
  colProps?: Partial<ColumnProps>;
}

const RecommendationColumn: React.FC<RecommendationColumnProps> = ({
  className,
  offerEntryId,
  mainColumn = true,
  informationLink,
  colProps,
}) => {
  const { getNewOfferType, changeNewOfferState, removeNewOfferEntryId } =
    useNewOffer();
  const type = getNewOfferType(offerEntryId);
  const [selectedGigaKombi] = useOfferState("selectedGigaKombi");
  const gigaKombiIsEnable = selectedGigaKombi && mainColumn;
  const [gigaKombiDataVolume] = useOfferState("gigaKombiDataVolume");
  const currentPriceState = useNewOfferState("currentPrice", offerEntryId);

  const [selectId] = useNewOfferState("selectId", offerEntryId);
  const [tempSelectId, setTempSelectId] = useOfferState(getTempIdKey(type));

  const setSelectId = (newIndex: number) => {
    changeNewOfferState(offerEntryId, newIndex);
  };

  const { getRecommendationMode } = useInterestValue();
  const mode = getRecommendationMode(type);
  const [products, priceArrays] = useProducts(type);
  const product = products[selectId];

  const priceArray = priceArrays[selectId] ?? {};

  const setPrevItem = () => {
    const newIndex = selectId - 1 < 0 ? 0 : selectId - 1;
    setSelectId(newIndex);
  };
  const setNextItem = () => {
    const newIndex =
      selectId + 1 >= products.length - 1 ? products.length - 1 : selectId + 1;
    setSelectId(newIndex);
  };
  const setToNullItem = () => {
    if (mainColumn && selectId !== 0) {
      setTempSelectId(selectId);
      setSelectId(0);
      return;
    }
    if (mainColumn && selectId === 0) {
      setSelectId(tempSelectId);
      return;
    }
    if (!mainColumn) {
      removeNewOfferEntryId(offerEntryId);
      return;
    }
  };

  const recommendationTableState =
    useRecommendationTablesOfferState(offerEntryId);
  const subProducts = useSubProducts(offerEntryId, selectedGigaKombi);

  const {
    dataVolumeValue,
    subProductDataVolume,
    dataVolumeMaximum,
    dataVolumeSteps,
    setDataVolumeValue,
  } = useDataVolume(offerEntryId, subProducts);

  const columnPrices = combineMonthlyPriceArray([
    priceArray,
    subProducts.priceInformation.monthly,
  ]);

  return (
    <Column {...colProps} extraClassName={className}>
      <div className={"recommendation-column"}>
        <ColumnHeadTile
          mode={mode}
          type={type}
          noInterest={selectId === 0}
          infoLink={informationLink}
          reset={setToNullItem}
          isResettable={!gigaKombiIsEnable}
        />
        <ProductContainerSelector
          prev={setPrevItem}
          next={setNextItem}
          currentProduct={product}
          currentIndex={selectId}
          max={gigaKombiIsEnable ? selectId : products.length - 1}
          min={gigaKombiIsEnable ? selectId : 0}
          backgroundColor={Color.GREY_LIGHT10}
          dataVolume={dataVolumeValue}
          setDataVolume={setDataVolumeValue}
          dataVolumeMax={dataVolumeMaximum}
          dataVolumeSteps={dataVolumeSteps}
          extraDataVolume={
            gigaKombiIsEnable
              ? subProductDataVolume + gigaKombiDataVolume
              : subProductDataVolume
          }
        />
        {selectId > 0 && mode === RecommendationMode.SELECTABLE && (
          <SelectableRecommendationColumn
            type={type}
            mainColumn={mainColumn}
            currentProductIndex={selectId}
            productPrice={priceArray}
            monthlyPriceArray={columnPrices}
            subProducts={subProducts}
            tableStateObject={recommendationTableState}
            oncePrice={subProducts.priceInformation.once}
          />
        )}
        {selectId > 0 && mode === RecommendationMode.CURRENT && (
          <CurrentRecommendationColumn
            type={type}
            currentPriceState={currentPriceState}
          />
        )}
        {selectId > 0 && mode === RecommendationMode.INTERESTING && (
          <InterestingRecommendationColumn
            productPrice={priceArray}
            type={type}
          />
        )}
      </div>
    </Column>
  );
};

export default styled(RecommendationColumn)`
  background: ${Color.WHITE};
  margin: 5px 5px 10px 5px;
  .recommendation-column {
    width: 100%;
    max-width: 900px;
    display: inline-block;
  }
`;
