import {
  OfferOption,
  ProductOption,
} from "../../state/definitions/RecommendationState";
import { IdObject } from "./definitions/IdObject";
import {
  getValueAsOfferOptionArray,
  getValueAsProductOptionArray,
} from "./getValueAsOptionArray";
import { getValueAsArray } from "../getValueAsArray";

export function getValuesFromInformation<Q extends IdObject, T extends keyof Q>(
  key: T,
  infos: Q[]
): Q[T][] {
  return getValueAsArray((v) => v[key], infos);
}

export function getProductOptionFromInformation<
  Q extends IdObject,
  T extends keyof Q
>(key: T, infos: Q[]): ProductOption<Q[T]>[] {
  return getValueAsProductOptionArray(
    (v) => ({ productId: v.id, label: v[key] }),
    infos
  );
}

export function getOfferOptionFromInformation<
  Q extends IdObject,
  T extends keyof Q
>(key: T, infos: Q[]): OfferOption<Q[T]>[] {
  return getValueAsOfferOptionArray(
    (v) => ({ id: v.id, label: v[key] }),
    infos
  );
}
