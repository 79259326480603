import React, { CSSProperties } from "react";
import styled from "styled-components";
import { SVGComponent } from "../../types/default";

export interface SVGIconProps {
  className?: string;
  src: SVGComponent;
  alt?: string;
  size?: number;
  color?: string;
  color2?: string;
  useMaxSizes?: boolean;
  noPadding?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
  cursor?: "pointer" | "not-allowed" | "default";
}

const SVGIcon: React.FC<SVGIconProps> = ({
  className,
  src: SrcComponent,
  style,
  onClick,
  color,
  color2,
}) => (
  <SrcComponent
    className={className + " icon"}
    style={style}
    onClick={onClick}
    color={color}
    color2={color2}
  />
);

function getPx(size?: number) {
  return size ? size + "px" : "66px";
}

function getSizes({ size, useMaxSizes }: SVGIconProps) {
  if (!!useMaxSizes) {
    return `
     max-width: ${getPx(size)} !important;
     max-height: ${getPx(size)} !important;
  `;
  }
  return `
     width: ${getPx(size)} !important;
     height: ${getPx(size)} !important;
  `;
}

function getPadding({ noPadding }: SVGIconProps) {
  if (!noPadding) {
    return `padding: 2px;`;
  }
  return "";
}

export default styled(SVGIcon)`
  display: inline-block;
  ${getSizes};
  ${getPadding};

  cursor: ${({ onClick, cursor }) =>
    !!cursor ? cursor : !!onClick ? "pointer" : "default"};
`;
