import * as React from "react";

function SvgChatAndTalk(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="chatAndTalk_svg__fa6a80b0-cf34-4436-9ed9-ead4d0165ee9"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.chatAndTalk_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <g id="chatAndTalk_svg__b822e782-c304-4fe1-9683-f422b7b39e48">
        <path
          id="chatAndTalk_svg__bba25800-6780-40a9-8c70-f05860f9cb3e"
          className="chatAndTalk_svg__st0"
          d="M189.6 349.3l37.8-15.4c9.4-2 18.2-.8 22.5 3.8 6.5 6.9 60.1 68.4 64.6 73.8 4.5 5.4 4.1 17.1-6.6 25.8-10.6 8.7-57.5 41.7-72.1 37.1-14.7-4.6-76-22.9-160.1-170.5S7.5 94.8 11 79.9s56.2-37.8 69.2-42.3 23.3 1.3 25.6 7.7c2.7 7.2 27.8 84.2 30.4 92.9 1.8 5.9-1.7 14.2-8.4 21.1s-33.3 33.3-33.3 33.3 6.7 25 37.8 79.5c6.9 12.1 57.3 77.2 57.3 77.2z"
        />
        <path
          id="chatAndTalk_svg___x31_7c3105b-f5c1-46c6-bd89-e1682d78474f"
          className="chatAndTalk_svg__st0"
          d="M435.7 124.9l-4.4-1.3c-12.1-3.2-24.6-4.4-37.1-3.5-12.5.7-24.8 3.5-36.5 8.1-10.7 4.2-20.6 10.2-29.3 17.7-8 6.8-14.6 15.2-19.3 24.6-4.4 9.1-6.4 19.1-5.9 29.2.5 10 3.6 19.8 8.9 28.3 5.5 8.8 12.8 16.4 21.4 22.2 9.4 6.4 19.8 11.1 30.9 14 12.1 3.2 24.6 4.4 37.1 3.5h1.2c4.2 2.9 8.6 5.4 13.2 7.6 5.6 2.7 11.4 4.9 17.4 6.5 6.9 1.9 14 3 21.2 3.3 8.3.3 16.6-.3 24.7-2 0 0-25.9-15-26.6-30.5 6.1-3.6 11.8-7.8 17-12.7 4.9-4.6 9.2-9.7 12.8-15.4 3.4-5.4 6.1-11.3 7.8-17.5 4.5-16 2.1-33.2-6.7-47.3-3.6-5.8-8-11-13.1-15.6-10.4-8.2-22.2-14.7-34.7-19.2"
        />
        <path
          id="chatAndTalk_svg___x35_6148b9c-5b4a-48ca-88f9-468887540241"
          d="M301.5 223.2c-24.9 13.8-33.8 16.9-33.8 16.9-7.1 2.6-14.4 4.5-21.8 5.7-10.5 1.7-21.2 2.1-31.8 1.2 0 0 25.8-15.3 36.9-41.4-8.5-3.7-23.2-10.1-30.2-15.3-6.9-5.1-13-11.1-18.4-17.7-5.1-6.4-9.2-13.5-12.3-21.1-3-7.5-4.8-15.5-5.3-23.6-.6-13 2.1-25.9 7.8-37.6 5.9-12.1 14.3-23 24.6-31.8 11.2-9.7 23.9-17.3 37.6-22.7 15-5.9 30.9-9.4 47-10.4 16-1 32.2.5 47.7 4.6 14.2 3.6 27.6 9.7 39.7 18 11.1 7.5 20.5 17.2 27.6 28.6 0 0 12.1 17.7 9.4 36.3"
          fill="none"
          stroke=" ${color} "
          strokeWidth={24.173}
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SvgChatAndTalk;
