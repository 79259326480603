import React, { useState } from "react";
import styled from "styled-components";
import PaginationButton from "../../pagination/PaginationButton";
import { FormOption } from "../../../state/definitions/FormConfigState";
import { useRefEffect } from "../../../hooks/common/useRefHook";

interface TextSelectorCarouselProps {
  className?: string;
  onChange: (value: number) => void;
  valueId?: number;
  items: FormOption[];
  selected?: boolean;
  disabled?: boolean;
  smallVersion?: boolean;
}

const TextSelectorCarousel: React.FC<TextSelectorCarouselProps> = ({
  className,
  valueId,
  items,
  onChange,
  selected = true,
  disabled = false,
}) => {
  const [currentValue, setCurrentValue] = useState("");

  const itemsDependencies = JSON.stringify(items);
  useRefEffect(() => {
    const currentIndex = items.findIndex((v) => v.value === valueId);
    if (items[currentIndex]) {
      setCurrentValue(items[currentIndex].label);
    }
  }, [valueId, itemsDependencies]);

  const prev = () => {
    const currentIndex = items.findIndex((v) => v.value === valueId);
    const prevIndex = (currentIndex - 1 + items.length) % items.length;
    onChange(items[prevIndex].value);
  };
  const next = () => {
    const currentIndex = items.findIndex((v) => v.value === valueId);
    const nextIndex = (currentIndex + 1) % items.length;
    onChange(items[nextIndex].value);
  };

  return (
    <div className={className + " text-selector"}>
      <SelectorButton
        direction={"prev"}
        onClick={prev}
        selected={selected}
        disabled={disabled}
      />
      <div className={"value-field-container"}>
        <div className={"value-field"}>
          {currentValue !== "" ? <p>{currentValue}</p> : <p>Bitte auswählen</p>}
        </div>
      </div>
      <SelectorButton
        direction={"next"}
        onClick={next}
        selected={selected}
        disabled={disabled}
      />
    </div>
  );
};
const SelectorButton = styled(PaginationButton)`
  height: 100%;
  width: 10%;
  outline: none;
  vertical-align: top;

  .right-triangle {
    margin-left: -5px;
  }

  .left-triangle {
    margin-left: 5px;
  }
`;

export default styled(TextSelectorCarousel)`
  height: ${({ smallVersion }) => (smallVersion ? "40px" : "70px")};
  line-height: 12px;
  background-color: ${(props) => (props.selected ? "#e30613" : "#484b4c")};
  max-width: 235px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;

  .value-field-container {
    display: inline-block;
    width: 80%;
    height: 100%;
    padding: ${({ smallVersion }) =>
      smallVersion
        ? "0px 10px"
        : "15px 10px"}; // distance between valueField and the outer container
    vertical-align: top;

    .value-field {
      vertical-align: top;
      width: 100%;
      display: inline-block;
      height: 100%;
      background: white;
      box-shadow: inset 5px 5px 14px -8px rgba(0, 0, 0, 0.8);
      position: relative;

      p {
        text-align: center;
        padding: 14px 0;
        margin: 0;
        display: inline-block;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: bold;
      }

      img {
        margin: auto 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 75%;
      }
    }
  }
`;
