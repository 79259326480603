import * as React from "react";

function SvgTvRefreshColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="tvRefresh_color_svg__d8602505-9584-428e-b075-5bdcec8736fc"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".tvRefresh_color_svg__st0{fill:none;stroke:#e60000;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <path
        className="tvRefresh_color_svg__st0"
        d="M83 101l12.8-13V68M48.6 87l11 11 11-11"
      />
      <path
        className="tvRefresh_color_svg__st0"
        d="M70 113.5c14.1 14.1 36.8 14.1 50.9 0s14.1-36.8 0-50.9-36.8-14.1-50.9 0c-7.1 7.1-11 16.9-10.5 27"
      />
      <path
        className="tvRefresh_color_svg__st0"
        d="M28 36h136c4.4 0 8 3.6 8 8v88c0 4.4-3.6 8-8 8H28c-4.4 0-8-3.6-8-8V44c0-4.4 3.6-8 8-8zM60 156h72M96 144v10"
      />
      <path fill="none" d="M0 0h192v192H0z" />
    </svg>
  );
}

export default SvgTvRefreshColor;
