import * as React from "react";

function SvgGamingColor2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="gaming_color2_svg__ICON"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".gaming_color2_svg__st0{fill:none;stroke:#e60000;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <path
        className="gaming_color2_svg__st0"
        d="M171.9 119c-.7-2.5-21-67.7-21.1-67.9V51c-2.6-6.4-23.9-7-32.7-7s-11.6 1.6-14.3 8H88.1c-2.6-6.4-5.3-8-14.1-8s-29.4.6-32 7v.1c-.1.2-20.5 65.9-21 67.9-4 15 3 37 24 37 27 0 36.2-14.4 39-28h24c2.8 13.6 12 28 38.9 28 21.1 0 29-22 25-37zM64 76v32M80 92H48"
      />
      <circle className="gaming_color2_svg__st0" cx={128} cy={104} r={2} />
      <circle className="gaming_color2_svg__st0" cx={128} cy={104} r={1} />
      <circle className="gaming_color2_svg__st0" cx={128} cy={80} r={2} />
      <circle className="gaming_color2_svg__st0" cx={128} cy={80} r={1} />
      <circle className="gaming_color2_svg__st0" cx={116} cy={92} r={2} />
      <circle className="gaming_color2_svg__st0" cx={116} cy={92} r={1} />
      <circle className="gaming_color2_svg__st0" cx={140} cy={92} r={2} />
      <circle className="gaming_color2_svg__st0" cx={140} cy={92} r={1} />
    </svg>
  );
}

export default SvgGamingColor2;
