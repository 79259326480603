import styled from "styled-components";
import TileButton from "./TileButton";

export const CloseButton = styled(TileButton)`
  width: 20px;
  height: 20px;
  border: none;
  padding: 0;
  display: inline-block;
  margin-top: 4px;
`;
