enum ErrorMessages {
  ERROR_500 = "Request failed with status code 500",
}

enum EqualizeErrorMessages {
  ERROR_500 = "Der Server hat zur Zeit einen Fehler. Wenn das Problem wiederholt auftritt kontaktieren Sie Ihren Administrator.",
}

export function equalizeErrorMessages(e: string) {
  switch (e) {
    case ErrorMessages.ERROR_500:
      return EqualizeErrorMessages.ERROR_500;
  }
  return e;
}
