import React, { useState } from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";

interface VauoidFormProps {
  className?: string;
  vauoid: string;
  onSave: (vauoid: string) => void;
}

const VauoidForm: React.FC<VauoidFormProps> = ({
  className,
  onSave,
  vauoid,
}) => {
  const [value, setValue] = useState(vauoid);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const validate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let message = null;

    if (value.length !== 8) {
      message = "Länge stimmt nicht";
    } else if (!/^[0-9]{8}$/.test(value)) {
      message = "Nur Ziffern erlaubt";
    }

    setErrorMessage(message);
    if (!message) {
      onSave(value);
    }
  };

  return (
    <div className={className}>
      <div className="headline">Void setzen</div>
      <form onSubmit={validate}>
        <div>Gib hier Deine VOID ein</div>
        <input type="text" maxLength={8} value={value} onChange={onChange} />
        {errorMessage && <div className="error">{errorMessage}</div>}
        <div>
          <button type="submit">Speichern</button>
        </div>
      </form>
    </div>
  );
};

export default styled(VauoidForm)`
  position: absolute;
  width: 300px;
  left: 50%;
  bottom: 25%;
  margin-left: -150px;
  background-color: ${Color.WHITE};
  padding: 20px;
  border-radius: 5px;
  font-size: 16px;

  .headline {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
  }

  input {
    font-size: 36px;
    letter-spacing: 4px;
    width: 100%;
    margin: 10px 0;
    padding: 5px;
    text-align: center;

    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    background-color: ${Color.GREY_LIGHT2};
    border-radius: 5px;
  }

  .error {
    color: red;
  }

  button {
    border: none;
    border-radius: 5px;
    background-color: ${Color.PURPLE};
    margin: 20px 0 0 0;
    padding: 10px;
    color: ${Color.WHITE};
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }
`;
