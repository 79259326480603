
import { DatabaseProduct } from "../../data/definitions/DatabaseProduct";
import { getProductBasicPrice } from "./getProductBasicPrice";
import { MonthlyPriceArray, RecommendationMode } from "../../types/application";

export function getProductMonthlyPriceArray(
  product: DatabaseProduct,
  productLineModes: RecommendationMode[] = []
): MonthlyPriceArray {
  const isAnyProductLineCurrent =
    productLineModes.findIndex((v) => v === RecommendationMode.CURRENT) !== -1;
  const basicPrice = getProductBasicPrice(product, isAnyProductLineCurrent);
  const priceArray: MonthlyPriceArray = { 0: basicPrice };
  if (product.ncRebateDuration > 0) {
    if (!isAnyProductLineCurrent) {
      // add the welcome-rebate only if no product line is "current"
      priceArray[product.ncRebateDuration] = product.productBasicPrice;
    }
  }
  return priceArray;
}
