import * as React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";

export interface AFOCurrencyChangerProps {
  className?: string;
  onClick: () => void;
  content: string;
}

const AFOCurrencyChanger = (props: AFOCurrencyChangerProps) => {
  return (
    <>
      <div className={props.className} onClick={props.onClick}>
        <span className={"currency-symbol"}>{props.content}</span>
      </div>
    </>
  );
};

export default styled(AFOCurrencyChanger)`
  display: inline-block;
  width: 30%;
  padding-right: 4px;
  vertical-align: middle;
  background: ${Color.RED};
  color: ${Color.WHITE};
  line-height: 33px;
  border-left: 1px solid ${Color.BLACK};
  cursor: pointer;

  .currency-symbol {
    text-align: left;
  }
`;
