import { InformationForRecommendationConfig } from "./definitions/InformationForRecommendationConfig";
import { RecommendationConfig } from "../../state/definitions/RecommendationState";
import { getProductLineConfig } from "./getProductLineConfig";
import { getProductLineTable } from "./getProductLineTable";

export function combineConfigToRecommendation(
  info: InformationForRecommendationConfig
): RecommendationConfig {
  return {
    mobile: getProductLineConfig(info.mobile),
    iot: getProductLineConfig(info.iot),
    fixed: getProductLineConfig(info.fixed),
    tv: getProductLineConfig(info.tv),
    mobileTable: getProductLineTable(info.mobile),
    iotTable: getProductLineTable(info.iot),
    fixedTable: getProductLineTable(info.fixed),
    tvTable: getProductLineTable(info.tv),
    //AdvantageInformation
    advantages: info.advantageInformation,

    // SubProductConfig
    standardDiscountLabel: info.devConfig.standardDiscountLabel,
    standardDiscountCounts: info.devConfig.standardDiscountCounts,

    defaultTable: {
      additionalTableLabel: info.devConfig.defaultAdditionalTableLabel,
      additionalTableCount: info.devConfig.defaultAdditionalTableCount,
      additionalTablePrice: info.devConfig.defaultAdditionalTablePrice,
      additionalTableStart: info.devConfig.defaultAdditionalTableStart,
      furtherTableLabel: info.devConfig.defaultFurtherTableLabel,
      furtherTableCount: info.devConfig.defaultFurtherTableCount,
      furtherTablePrice: info.devConfig.defaultFurtherTablePrice,
      furtherTableDataVolume: info.devConfig.defaultFurtherTableDataVolume,
      furtherTableLastLabel: info.devConfig.defaultFurtherTableLastLabel,
      furtherTableLastCount: info.devConfig.defaultFurtherTableLastCount,
      furtherTableLastPrice: info.devConfig.defaultFurtherTableLastPrice,
      singlePaymentTableLabel: info.devConfig.defaultSinglePaymentTableLabel,
      singlePaymentTablePrice: info.devConfig.defaultSinglePaymentTablePrice,
    },
  };
}
