import { OfferState } from "../definitions/OfferState";
import { GigaKombiInfoTexts } from "../../components/elements/recommendationPage/gigakombi/GigaKombiInfoModal";
import { DisplayErrorMessages } from "../../constants/DisplayErrorMessages";
import { Interest } from "../../types/application";

export const defaultOfferState: OfferState = {
  offerEntryIds: [],
  printOfferEntryIds: [],

  selectedGigaKombi: false,
  gigaInfoText: GigaKombiInfoTexts.nothing,
  gigaErrorText: DisplayErrorMessages.nothing,

  gigaKombiInterest: Interest.MOBILE,
  gigaKombiWithSky: false,
  gigaKombiLabelId: 1,
  gigaKombiDataVolume: 0,
  gigaKombiCalculatedDiscount: 0,

  regioDiscountLabelId: 1,

  mobileTempId: 0,
  fixedTempId: 0,
  tvTempId: 0,
  iotTempId: 0,
};
