import { DatabaseProduct } from "../../data/definitions/DatabaseProduct";
import { DatabaseProductPriceType } from "../../data/definitions/DatabaseProductPriceType";
import { DatabaseCategoryTypes } from "../../data/definitions/DatabaseCategoryTypes";

export function filterDatabaseProduct(
  d: DatabaseProduct
): DatabaseProduct | undefined {
  const restrictionB1 = d.productCategory === DatabaseCategoryTypes.tariff;
  const restrictionB2a = d.productCategory !== DatabaseCategoryTypes.tariff;
  const restrictionB2b =
    d.productPriceType === DatabaseProductPriceType.MONTHLY;
  const restrictionB = restrictionB1 || (restrictionB2a && restrictionB2b);

  if (restrictionB) {
    return d;
  }

  return undefined;
}
