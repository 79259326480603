import {
  PassData,
  TableCellType,
  TableDataObject,
  UnknownTableDataObject,
} from "../../types/table";
import { sumTablePrices } from "./sumTablePrices";
import { MonthlyPriceArray } from "../../types/application";

export function getLastUsedMonth(
  monthArray: MonthlyPriceArray,
  maxBorder?: number
): number {
  const months = Object.keys(monthArray);
  let filteredMonths = months;
  if (maxBorder !== undefined) {
    filteredMonths = months.filter((v) => Number(v) < maxBorder);
  }
  if (filteredMonths.length === 0) {
    return 0;
  }

  return Number(filteredMonths.sort((a, b) => Number(b) - Number(a))[0]);
}

export function getPriceFromTableCell(d: TableDataObject): number {
  const priceId =
    d.priceType === TableCellType.EXTERNAL ? d.labelId : d.priceId;
  return priceId !== undefined && d.prices[priceId] !== undefined
    ? d.prices[priceId]
    : 0;
}

export function sumPassPrices(passData: PassData[]): number {
  return passData.reduce((acc, d) => {
    if (d.selectedPosition === 0) {
      return acc;
    }
    return d.selectedPosition > 0 ? acc + d.price : acc;
  }, 0);
}

export function prepareColumnPrices(
  tableData: UnknownTableDataObject[][],
  passData: PassData[]
): [MonthlyPriceArray, number[], number] {
  const passPrice = sumPassPrices(passData);
  // TODO: Change to use SubProducts properties instead of array
  // Collect prices from each tab table
  // leave a gap for the pass price
  const [priceArray, prices, oncePrice] = sumTablePrices([[], ...tableData], {
    0: passPrice,
  });
  // Add prices from the pass tab
  prices[0] += passPrice;
  return [priceArray, prices, oncePrice];
}
