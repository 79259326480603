import React from "react";
import useBootstrap from "../../hooks/useBootstrap";
import { useFormState } from "../../hooks/useForm";
import ImageTileCheckboxList from "../form/ImageTileCheckboxList";
import Slider from "../form/Slider";
import styled from "styled-components";
import { SimpleState, StyledComponentProps } from "../../types/default";
import ProviderQuestionBlock from "../form/ProviderQuestionBlock";

const IoTPreferencesPage: React.FC<StyledComponentProps> = ({ className }) => {
  const { formConfig } = useBootstrap();

  const [iotInterests, setIotInterests] = useFormState("iotInterests");

  // Setup slider values
  const [iotCurrentPrice, setIotCurrentPrice] = useFormState("iotCurrentPrice");
  const iotCurrentPriceValue = iotCurrentPrice ? iotCurrentPrice : 0;

  const [currentProviderId, setCurrentProviderId] =
    useFormState("providerIotId");
  const providerIdState: SimpleState<number> = [
    currentProviderId ? currentProviderId : -1,
    setCurrentProviderId,
  ];
  return (
    <div className={className}>
      <ImageTileCheckboxList
        data={formConfig.iotInterestLabels}
        values={iotInterests}
        onChange={setIotInterests}
      />
      <p className={"price-question"}>Was zahlst Du aktuell?</p>
      <Slider
        min={0}
        max={150}
        value={iotCurrentPriceValue}
        onChange={setIotCurrentPrice}
        afterValue={"€"}
        description={"Preis"}
        padding={"10px 0 60px 0"}
      />
      <ProviderQuestionBlock
        selectedProviderState={providerIdState}
        providers={formConfig.iotProviders}
      />
    </div>
  );
};

export default styled(IoTPreferencesPage)`
  .price-question {
    font-size: 24px;
    //font-weight: bold;
    margin: 20px 50px;
  }
`;
