import React from "react";
import useBootstrap from "../../hooks/useBootstrap";
import { useFormState } from "../../hooks/useForm";
import ImageTileCheckboxList from "../form/ImageTileCheckboxList";

const FixedPreferencesPage = () => {
  const { formConfig } = useBootstrap();
  const [fixedPreferences, setFixedPreferences] = useFormState(
    "fixedPreferences"
  );

  return (
    <ImageTileCheckboxList
      data={formConfig.fixedPreferences}
      values={fixedPreferences}
      onChange={setFixedPreferences}
    />
  );
};

export default FixedPreferencesPage;
