import * as React from "react";
import { Link } from "react-router-dom";
import TileButton, { TileButtonProps } from "./TileButton";
import styled from "styled-components";
import { StyledComponentProps } from "../../types/default";

export interface LinkButtonInnerProps
  extends StyledComponentProps,
    Omit<TileButtonProps, "padding" | "shadowed" | "className"> {
  slideId?: number;
  title?: string;
  noDisplayTextOnSmallDevice?: boolean;
}

const LinkButtonInner = ({
  slideId,
  title,
  className,
  onChange,
  ...restProps
}: LinkButtonInnerProps) => {
  const tileButtonProps = {
    shadowed: true,
    padding: "10px",
    maxWidth: "unset",
    onChange: onChange,
    ...restProps,
  };
  return (
    <>
      {slideId && (
        <Link
          className={className + " link-button-link"}
          to={"/slides/" + slideId}
        >
          <TileButton {...tileButtonProps}>{title}</TileButton>
        </Link>
      )}
      {!slideId && !!onChange && (
        <div className={className + " link-button-link"}>
          <TileButton {...tileButtonProps}>{title}</TileButton>
        </div>
      )}
    </>
  );
};

export default styled(LinkButtonInner)`
  display: inline-block;
  margin: 0 2px;

  .tile-button {
    max-height: 42px;
    .tile-button-content-container {
      @media (max-width: 647px) {
        display: ${({ noDisplayTextOnSmallDevice }) =>
          noDisplayTextOnSmallDevice ? "none" : "inline-block"};
      }
    }
    .tile-button-start-icon {
      @media (max-width: 647px) {
        margin-right: ${({ noDisplayTextOnSmallDevice }) =>
          noDisplayTextOnSmallDevice ? "0" : "5px"};
      }
    }
    .tile-button-end-icon {
      @media (max-width: 647px) {
        margin-left: ${({ noDisplayTextOnSmallDevice }) =>
          noDisplayTextOnSmallDevice ? "0" : "5px"};
      }
    }
    svg {
      width: 25px;
    }
  }
`;
