import React from "react";
import styled from "styled-components";
import Icons from "../../icons/Icons";
import { Color } from "../../constants/color";
import SVGIcon from "../svgIcon/SVGIcon";
import { StyledComponentProps } from "../../types/default";

export interface PlusButtonProps extends StyledComponentProps {
  onChange: (value: boolean) => void;
  value?: boolean;
}

const PlusButton: React.FC<PlusButtonProps> = ({
  className,
  onChange,
  value,
}) => {
  const onClick = (_: React.MouseEvent) => {
    onChange(!value);
  };
  return (
    <div className={className} onClick={onClick}>
      <SVGIcon src={Icons.plus} alt={"info"} size={40} />
    </div>
  );
};
export default styled(PlusButton)`
  display: inline-block;
  min-width: 175px;
  height: 200px;
  max-height: 100px;
  width: 175px;
  max-width: 400px;

  margin: 10px;
  padding: 5px 15px;

  vertical-align: top;
  text-align: center;

  font-weight: bold;
  background-color: ${Color.RED};

  user-select: none;
  cursor: pointer;

  img,
  svg {
    display: inline-block;
    margin: 25px;
    vertical-align: top;
  }
`;
