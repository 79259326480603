import React from "react";
import styled from "styled-components";
import SelectionModal from "./AFOSelectionModal";
import { Color } from "../../../constants/color";
import { TableCellType, TableDataObject } from "../../../types/table";

interface AFOStartSelectionCellProps {
  className?: string;
  value: number;
  dataId: number;
  dataSet: TableDataObject;
  changeCountId: (newLabelId: number, objectId: number) => void;
  setOpenSelectionId: (id: number) => void;
  openSelectionId: number;
}

const AFOStartSelectionCell: React.FC<AFOStartSelectionCellProps> = ({
  className,
  dataId,
  dataSet,
  changeCountId,
  setOpenSelectionId,
  openSelectionId,
  value,
}) => {
  return (
    <td className={className + " cell row-label"}>
      {dataId * 3 + 1 === openSelectionId &&
        dataSet.countType === TableCellType.SELECTION && (
          <SelectionModal
            data={dataSet.counts}
            countUnit={dataSet.countUnit}
            selectedId={dataSet.countId}
            changeId={(valueId: number) => {
              changeCountId(valueId, dataId);
              setOpenSelectionId(-1);
            }}
          />
        )}

      <div
        onClick={() => setOpenSelectionId(dataId * 3 + 1)}
        className={"cell-content"}
      >
        {!!dataSet.countUnit && <> {value + " " + dataSet.countUnit} </>}
        {!dataSet.countUnit && value}
      </div>
    </td>
  );
};
export default styled(AFOStartSelectionCell)`
  text-align: center;
  background: ${Color.GREY_LIGHT3};
  cursor: ${({ dataSet }) =>
    dataSet.countType === TableCellType.SELECTION ? "pointer" : "default"};
`;
