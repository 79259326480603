import { AppState } from "../../state/definitions/AppState";

export default (state: AppState) => {
  let error: string = "";

  // check availability
  if (
    state.availability?.availability?.cable?.downstream === undefined ||
    state.availability?.availability?.dsl?.downstream === undefined
  ) {
    error = "Param availability is undefined.";
  }

  // check fixedInvoice
  if (error === "") {
    if (
      state.bootstrap.formConfig.additionalCostInvoice === undefined ||
      state.form.additionalCostInvoice === undefined
    ) {
      error = "Param additionalCostInvoice is undefined.";
    }
    if (state.form.additionalCostInvoice !== -1) {
      const foundedId =
        state.bootstrap.formConfig.additionalCostInvoice.findIndex(
          (v) => v.value === state.form.additionalCostInvoice
        );
      if (foundedId === -1) {
        error = "additionalCostInvoiceId not found";
      }
    }
  }

  // check mobilePersonalDetails
  if (error === "") {
    let containsUnder28 = false;
    let parentBetween10And17 = false;
    state.bootstrap.formConfig.mobilePersonalDetails.forEach((v) => {
      switch (v.label) {
        case "Unter 28":
          containsUnder28 = true;
          break;
        case "Elternteil eines Kindes von 10-17 Jahren":
          parentBetween10And17 = true;
          break;
      }
    });
    if (!(containsUnder28 && parentBetween10And17)) {
      error = "not all mobilePersonalDetails";
    }
  }

  // check contractCounts
  if (error === "") {
    const productLabels = [
      ...state.bootstrap.formConfig.mobileCardDataLabel,
      ...state.bootstrap.formConfig.mobileCardTariffLabel,
      ...state.bootstrap.formConfig.mobileCardDataGoLabel,
    ];

    if (productLabels.length === 0) {
      error = "productLabels";
    }
    let containsCardData: boolean = false;
    let containsCardTariff: boolean = false;
    let containsCardDataGo: boolean = false;

    productLabels.forEach((v) => {
      switch (v.label) {
        case "Red+ Data":
          containsCardData = true;
          break;
        case "FamilyCard":
          containsCardTariff = true;
          break;
        case "DataGo":
          containsCardDataGo = true;
          break;
      }
    });
    if (!(containsCardData && containsCardTariff && containsCardDataGo)) {
      error = "not all productLabels";
    }
  }
  if (error !== "") {
    throw new Error(error);
  }
};
