import { getZeroIfNotExist } from "../ifNotExist";
import { MonthlyPriceArray } from "../../types/application";

export function addValueToMonthlyPriceArray(
  newPriceArray: MonthlyPriceArray,
  value: number,
  startMonth: number,
  endMonth: number
) {
  const usedMonths = Object.keys(newPriceArray);

  // added the calculated price to all existed months (month zero is always included)
  for (
    let monthsCounter = 0;
    monthsCounter < usedMonths.length;
    monthsCounter++
  ) {
    const usedMonth = Number(usedMonths[monthsCounter]);
    /**
     *  For calculation debugging:
     *     console.log("[" + monthsCounter + "/" + usedMonth + "] addMonthlyArray",usedMonths,newPriceArray,startMonth,endMonth);
     */
    // Important: Add the monthPrice not to the endMonth!
    if (startMonth <= usedMonth && usedMonth < endMonth) {
      newPriceArray[usedMonth] =
        newPriceArray[getZeroIfNotExist(usedMonth)] + value;
    }
  }
}
