import React, { CSSProperties } from "react";
import styled from "styled-components";
import { PriceDisplayInformation } from "../../../types/application";
import { StyledComponentProps } from "../../../types/default";
import { Color } from "../../../constants/color";

interface PriceDisplayProps extends StyledComponentProps {
  extraClassName?: string;
  style?: CSSProperties;
  useDisabledStyle?: boolean;
  priceData: PriceDisplayInformation;
}

const PriceDisplay: React.FC<PriceDisplayProps> = ({
  className = "",
  extraClassName = "",
  style,
  priceData,
  useDisabledStyle = false,
}) => {
  const { headline, mainPrice, subline, currency = "€" } = priceData;
  const prices = mainPrice?.toFixed(2).split(".") ?? [];
  return (
    <p className={className + " price-display " + extraClassName} style={style}>
      <span className={"price-headline"}>{headline}</span>
      <ProductPriceContainer prices={prices} currency={currency} />
      {subline &&
        subline.map((v, i) => {
          return (
            <span key={i} className={"price-subline"}>
              {v}
            </span>
          );
        })}
      <span
        className={
          useDisabledStyle
            ? "strike-through-container"
            : "strike-through-container not-visible"
        }
      />
    </p>
  );
};

const ProductPriceContainer = ({
  prices,
  currency,
}: {
  prices?: string[];
  currency: string;
}) => {
  return (
    <span className={"price-container"}>
      <span className={"price-pre-point"}>
        {prices && prices.length > 0 && prices[0]}
      </span>
      <span className={"price-post-point"}>
        {prices && prices.length > 1 && prices[1]}
      </span>
      <span className={"price-currency"}>&nbsp;{currency}</span>
    </span>
  );
};

export default styled(PriceDisplay)`
  position: relative;
  text-align: center;
  padding: 10px;
  color: ${({ useDisabledStyle }) =>
    useDisabledStyle ? Color.GREY_LIGHT : Color.WHITE};

  transition: color 0.3s;

  span {
    display: inline-block;
  }

  .price-headline {
    width: 100%;
    font-size: 12px;
  }

  .price-container {
    font-weight: bold;
    width: 100%;

    .price-pre-point {
      font-size: 32px;
    }

    .price-post-point {
      font-size: 16px;
      position: absolute;
    }

    .price-currency {
      font-size: 16px;
    }
  }

  .price-subline {
    width: 100%;
    font-size: 12px;
  }

  .strike-through-container {
    width: 66%;
    height: 0;
    border-top: 3px solid ${Color.GREY_LIGHT};
    position: absolute;
    left: 15%;
    bottom: 45%;
    transform: rotate(331deg);
    opacity: 100%;
    transition: opacity 0.5s;

    &.not-visible {
      opacity: 0;
    }
  }
`;
