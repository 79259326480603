import { AddressResult } from "../../state/definitions/AvailabilityState";
import { testObjectIsFromClass } from "./default/testObjectIsFromClass";

export default (unsureAvailabilitySearch: any): [AddressResult[], boolean] => {
  let result = true;
  if (
    unsureAvailabilitySearch !== undefined &&
    unsureAvailabilitySearch.length !== undefined
  ) {
    unsureAvailabilitySearch.forEach((v: any, i: number) => {
      const testResult = testObjectIsFromClass(v, AddressResult, true);
      if (testResult !== "") {
        console.error(
          "File addressResult has a false structure:",
          "[",
          i,
          "]: ",
          testResult
        );
        result = false;
      }
    });
  } else {
    console.error(
      "File addressResult-data has a false structure:",
      "'No index structure.'"
    );
    result = false;
  }

  return [unsureAvailabilitySearch as AddressResult[], result];
};
