import * as React from "react";

function SvgYoutube(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <path
        d="M201.24 323.86V176.07l126.24 73.9-126.24 73.89zm280.85-192.44c-5.6-21.11-21.97-37.76-42.75-43.44-37.94-10.56-189.76-10.56-189.76-10.56s-151.81 0-189.76 10.15c-20.38 5.68-37.15 22.74-42.75 43.85-9.99 38.57-9.99 118.55-9.99 118.55s0 80.38 9.99 118.54c5.6 21.12 21.97 37.76 42.75 43.44 38.35 10.56 189.76 10.56 189.76 10.56s151.81 0 189.76-10.16c20.77-5.68 37.15-22.33 42.75-43.44 9.99-38.58 9.99-118.55 9.99-118.55s.4-80.38-9.99-118.94"
        fill={color}
      />
    </svg>
  );
}

export default SvgYoutube;
