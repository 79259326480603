import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import { useClassNames } from "../../../hooks/helper/useClassNames";

interface TextTileProps {
  className?: string;
  outerClassName?: string;
  backgroundColor?: string;
  fontColor?: string;
  withoutMarginBottom?: boolean;
  borderColor?: string;
}

const TextTile: React.FC<TextTileProps> = ({
  className,
  outerClassName,
  children,
}) => {
  const classNames = useClassNames([className, outerClassName]);
  return <div className={classNames}>{children}</div>;
};

export default styled(TextTile)`
  border: ${({ borderColor }) =>
    borderColor ? "2px solid " + borderColor : "none"};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : Color.WHITE};
  color: ${({ fontColor }) => (fontColor ? fontColor : Color.BLACK)};
  min-width: 150px;
  max-width: 900px;
  text-align: center;
  display: block;
  overflow: hidden;
  margin-bottom: ${({ withoutMarginBottom }) =>
    withoutMarginBottom ? "unset" : "2px"};
`;
