import {
  useFormState,
  useFormStateArray,
  useFormStateMultiple,
} from "../useForm";
import { useRefEffect } from "../common/useRefHook";
import { getZeroIfNotExist } from "../../helper/ifNotExist";
import {
  ContractSummary,
  ContractSummaryEgo,
  ContractSummaryPerson,
  OptimizeContractData,
  personOrKid,
} from "../../types/application";
import { HumanContractKeys } from "../../constants/HumanContracts";

const contractEgoKeys: Array<keyof ContractSummaryEgo> = Object.keys(
  new ContractSummaryEgo()
) as Array<keyof ContractSummaryEgo>;
const contractPersonKeys: Array<keyof ContractSummaryPerson> = Object.keys(
  new ContractSummaryPerson()
) as Array<keyof ContractSummaryPerson>;

const useContracts = () => {
  const [contractData, setContractData] = useFormState("contractData");
  const [contractsCount, setContractsCount] = useFormState("contractsCount");
  const [contractCounts, setContractCounts] = useFormState("contractCounts");

  const contractsIdObject = useFormStateMultiple([
    "mobileFirstHumanContractId",
    "mobileSecondHumanContractId",
    "mobileThirdHumanContractId",
    "mobileFourthHumanContractId",
    "mobileFifthHumanContractId",
  ]);

  const humanNameStates = useFormStateMultiple([
    "mobileFirstHumanContractName",
    "mobileSecondHumanContractName",
    "mobileThirdHumanContractName",
    "mobileFourthHumanContractName",
    "mobileFifthHumanContractName",
  ]);

  // Get values from store
  const [mobileDataSharePeople, setMobileDataSharePeople] = useFormState(
    "mobileDataSharePeople"
  );
  const [mobileDataShareKids, setMobileDataShareKids] = useFormState(
    "mobileDataShareKids"
  );

  const mobileFirstHumanProductCounts = useFormStateArray(
    "mobileFirstHumanProductCounts"
  );
  const mobileSecondHumanProductCounts = useFormStateArray(
    "mobileSecondHumanProductCounts"
  );
  const mobileThirdHumanProductCounts = useFormStateArray(
    "mobileThirdHumanProductCounts"
  );
  const mobileFourthHumanProductCounts = useFormStateArray(
    "mobileFourthHumanProductCounts"
  );
  const mobileFifthHumanProductCounts = useFormStateArray(
    "mobileFifthHumanProductCounts"
  );

  // combine values and setters in an object
  const humanContracts = {
    mobileFirstHumanContractId: mobileFirstHumanProductCounts,
    mobileSecondHumanContractId: mobileSecondHumanProductCounts,
    mobileThirdHumanContractId: mobileThirdHumanProductCounts,
    mobileFourthHumanContractId: mobileFourthHumanProductCounts,
    mobileFifthHumanContractId: mobileFifthHumanProductCounts,
  };
  const humanNames = {
    mobileFirstHumanContractId: humanNameStates.mobileFirstHumanContractName,
    mobileSecondHumanContractId: humanNameStates.mobileSecondHumanContractName,
    mobileThirdHumanContractId: humanNameStates.mobileThirdHumanContractName,
    mobileFourthHumanContractId: humanNameStates.mobileFourthHumanContractName,
    mobileFifthHumanContractId: humanNameStates.mobileFifthHumanContractName,
  };

  const contractNamesToObjects =
    (contractCountsId: number) =>
    (
      contractKey: keyof ContractSummaryPerson | keyof ContractSummaryEgo,
      contractIndex: number
    ) => {
      const [count, setter] =
        humanContracts[HumanContractKeys[contractCountsId]];
      return {
        labelKey: contractKey,
        count: getZeroIfNotExist(count[contractIndex]),
        setter: (newCount: number) => setter(newCount, contractIndex),
      };
    };

  const useContractData = (): [
    OptimizeContractData[],
    number,
    ContractSummary
  ] => {
    // create dependency variables
    const mobileFirstHumanProductCountsString =
      mobileFirstHumanProductCounts.join();
    const mobileSecondHumanProductCountsString =
      mobileSecondHumanProductCounts.join();
    const mobileThirdHumanProductCountsString =
      mobileThirdHumanProductCounts.join();
    const mobileFourthHumanProductCountsString =
      mobileFourthHumanProductCounts.join();
    const mobileFifthHumanProductCountsString =
      mobileFifthHumanProductCounts.join();

    useRefEffect(() => {
      const personCount = getZeroIfNotExist(mobileDataSharePeople);
      const kidCount = getZeroIfNotExist(mobileDataShareKids);

      const newContractData: OptimizeContractData[] = [];
      for (
        let humanIndex = 0;
        humanIndex <= personCount + kidCount;
        humanIndex++
      ) {
        const [contractCountsId] =
          contractsIdObject[HumanContractKeys[humanIndex]];
        let type: personOrKid = "person";

        if (humanIndex === 0) {
          newContractData.push({
            type: type,
            name: humanNames[HumanContractKeys[contractCountsId]][0],
            nameSetter: humanNames[HumanContractKeys[contractCountsId]][1],
            contracts: contractEgoKeys.map(
              contractNamesToObjects(contractCountsId)
            ),
          });
        }
        if (humanIndex > 0) {
          if (humanIndex > personCount) {
            type = "kid";
          }

          newContractData.push({
            type: type,
            name: humanNames[HumanContractKeys[contractCountsId]][0],
            nameSetter: humanNames[HumanContractKeys[contractCountsId]][1],
            contracts: contractPersonKeys.map(
              contractNamesToObjects(contractCountsId)
            ),
          });
        }
      }

      setContractData(newContractData);
    }, [
      mobileFirstHumanProductCountsString,
      mobileSecondHumanProductCountsString,
      mobileThirdHumanProductCountsString,
      mobileFourthHumanProductCountsString,
      mobileFifthHumanProductCountsString,
      mobileDataSharePeople,
      mobileDataShareKids,
      humanNameStates.mobileFirstHumanContractName[0],
      humanNameStates.mobileSecondHumanContractName[0],
      humanNameStates.mobileThirdHumanContractName[0],
      humanNameStates.mobileFourthHumanContractName[0],
      humanNameStates.mobileFifthHumanContractName[0],
    ]);
    useRefEffect(() => {
      const newContractCounts = new ContractSummary();
      const newCount = contractData.reduce(
        (acc: number, d: OptimizeContractData) => {
          const sum = d.contracts.reduce((acc2, v) => {
            if (newContractCounts[v.labelKey] === undefined) {
              newContractCounts[v.labelKey] = v.count;
            } else {
              newContractCounts[v.labelKey] += v.count;
            }
            return acc2 + v.count;
          }, 0);
          return acc + sum;
        },
        0
      );
      setContractCounts(newContractCounts);
      setContractsCount(newCount);
    }, [contractData]);
    return [contractData, contractsCount, contractCounts];
  };

  const getHighestContractId = (): number => {
    return Math.max(
      contractsIdObject.mobileFirstHumanContractId[0],
      contractsIdObject.mobileSecondHumanContractId[0],
      contractsIdObject.mobileThirdHumanContractId[0],
      contractsIdObject.mobileFourthHumanContractId[0],
      contractsIdObject.mobileFifthHumanContractId[0]
    );
  };
  const getContractValue = (id: number) => {
    return contractsIdObject[HumanContractKeys[id]][0];
  };
  const getContractSetter = (id: number) => {
    return contractsIdObject[HumanContractKeys[id]][1];
  };

  const moveIds = (
    firstId: number,
    lastId: number,
    forward: boolean = true,
    valueChanger: (value: number) => number = (value) => value
  ) => {
    if (forward) {
      const startValue = Math.max(firstId, 1);
      const maxValue = Math.min(lastId, 3);

      getContractSetter(startValue)(-1);
      for (let i = startValue; i <= maxValue; i++) {
        const setter = getContractSetter(i + 1);
        const value = getContractValue(i);
        setter(valueChanger(value));
      }
    } else {
      const startValue = Math.max(firstId, 1);
      const maxValue = Math.min(lastId, 3);
      const lastValue = Math.min(lastId, 4);

      getContractSetter(lastValue)(-1);
      for (let i = startValue; i <= maxValue; i++) {
        const setter = getContractSetter(i);
        const value = getContractValue(i + 1);
        setter(valueChanger(value));
      }
    }
  };
  const addToIds = (
    condition: (value: number) => boolean,
    valueToAdd: number
  ) => {
    for (let i = 1; i <= 4; i++) {
      if (condition(getContractValue(i))) {
        getContractSetter(i)(getContractValue(i) + valueToAdd);
      }
    }
  };
  const getLatestContractId = (): number => {
    return mobileDataSharePeople + mobileDataShareKids;
  };
  const countStates = useFormStateMultiple([
    "mobileFirstHumanProductCounts",
    "mobileSecondHumanProductCounts",
    "mobileThirdHumanProductCounts",
    "mobileFourthHumanProductCounts",
    "mobileFifthHumanProductCounts",
  ]);

  const resetAllContractIds = () => {
    countStates.mobileFirstHumanProductCounts[1]([]);
    countStates.mobileSecondHumanProductCounts[1]([]);
    countStates.mobileThirdHumanProductCounts[1]([]);
    countStates.mobileFourthHumanProductCounts[1]([]);
    countStates.mobileFifthHumanProductCounts[1]([]);

    humanNameStates.mobileFirstHumanContractName[1]("Ich");
    humanNameStates.mobileSecondHumanContractName[1]("");
    humanNameStates.mobileThirdHumanContractName[1]("");
    humanNameStates.mobileFourthHumanContractName[1]("");
    humanNameStates.mobileFifthHumanContractName[1]("");
  };
  const setNobody = () => {
    resetAllContractIds();
    setMobileDataSharePeople(0);
    setMobileDataShareKids(0);
    getContractSetter(1)(-1);
    getContractSetter(2)(-1);
    getContractSetter(3)(-1);
    getContractSetter(4)(-1);
  };

  const errorIdMovement = (values?: number[]) => {
    console.error(
      "There is an error on moving contract ids!",
      values ? values.join(",") : ""
    );
  };

  const addContractAdult = () => {
    // newPosition =  adultcount + 1
    const newPosition = mobileDataSharePeople + 1;
    // get highest Index Value
    const newValue: number = getHighestContractId() + 1;
    if (newPosition <= 4 && newValue <= 4) {
      if (mobileDataShareKids > 0) {
        // move forward indexes from id: newPosition ... newPosition + childrenCount
        moveIds(newPosition, newPosition + mobileDataShareKids, true);
      }
      // sethighest index on newPosition
      getContractSetter(newPosition)(newValue);
      // humanNames[HumanContractKeys[newPosition + mobileDataShareKids]][1](
      //   "Erwachsener " + (mobileDataSharePeople + 1)
      // );
      setMobileDataSharePeople(mobileDataSharePeople + 1);
    } else {
      errorIdMovement([newPosition, newValue]);
    }
  };

  const addContractKid = () => {
    // get latest Index
    const latestIndexValue: number = getLatestContractId();
    // get highest Index Value
    const highestIndexValue: number = getHighestContractId();
    // const newPosition = latestIndex + 1
    const newPosition = latestIndexValue + 1;
    // const newValue = highest index value + 1
    const newValue = highestIndexValue + 1;
    if (newPosition <= 4 && newValue <= 4) {
      // set newValue on newPosition
      getContractSetter(newPosition)(newValue);
      // humanNames[HumanContractKeys[newPosition]][1](
      //   "Kind " + (mobileDataShareKids + 1)
      // );
      setMobileDataShareKids(mobileDataShareKids + 1);
    } else {
      errorIdMovement([newPosition, newValue]);
    }
  };

  const removeContractAdult = () => {
    // get position of last adult
    const oldPosition = mobileDataSharePeople;
    // get value of last adult
    const currentOldValue = getContractValue(oldPosition);
    if (oldPosition <= 4 && currentOldValue <= 4) {
      if (mobileDataShareKids > 0) {
        // move backward indexes from id: oldPosition+1 ... adultcount + childrenCount
        moveIds(
          oldPosition,
          mobileDataSharePeople + mobileDataShareKids,
          false,
          // if values bigger than the deleted value, decrease it
          (value) => (value > currentOldValue ? value - 1 : value)
        );
      }
      // remove the last count
      getContractSetter(mobileDataSharePeople + mobileDataShareKids)(-1);
      // decrease adult count
      setMobileDataSharePeople(mobileDataSharePeople - 1);
      // set All Data on []
      resetAllContractIds();
    } else {
      errorIdMovement([oldPosition, currentOldValue]);
    }
  };

  const removeContractKid = () => {
    // get position of last kid
    const oldPosition = mobileDataSharePeople + mobileDataShareKids;
    // get value of last adult
    const currentOldValue = getContractValue(oldPosition);
    if (oldPosition <= 4 && currentOldValue <= 4) {
      // set Value of position oldPosition to -1
      getContractSetter(oldPosition)(-1);

      // foreach value if < currentValue => otherNewValue = otherOldValue - 1
      addToIds((value) => value > currentOldValue, -1);
      // decrease kid count
      setMobileDataShareKids(mobileDataShareKids - 1);
      resetAllContractIds();
    } else {
      errorIdMovement([oldPosition, currentOldValue]);
    }
  };

  const changeAdultContractCount = (newValue: number) => {
    if (newValue > mobileDataSharePeople) {
      addContractAdult();
    }
    if (newValue < mobileDataSharePeople) {
      removeContractAdult();
    }
  };
  const changeKidContractCount = (newValue: number) => {
    if (newValue > mobileDataShareKids) {
      addContractKid();
    }
    if (newValue < mobileDataShareKids) {
      removeContractKid();
    }
  };

  return {
    useContractData,
    addContractAdult,
    addContractKid,
    removeContractAdult,
    removeContractKid,
    changeAdultContractCount,
    changeKidContractCount,
    setNobody,
  };
};

export default useContracts;
