import React from "react";
import styled from "styled-components";
import AFOTable, { AFOTableBaseProps, AFOTableChanger } from "./AFOTable";

interface AFOTableContainerProps
  extends AFOTableBaseProps,
    Partial<AFOTableChanger> {}

const defaultChangeNumberWrapper = (newValue: number, rowId: number) => {};
const defaultChangeStringWrapper = (newValue: string, rowId: number) => {};

const AFOTableContainer: React.FC<AFOTableContainerProps> = ({
  className,
  changePriceId,
  changePriceValue,
  changeLabelId,
  changeLabelValue,
  changeCountId,
  changeCountValue,
  changeStartId,
  changeStartValue,
  ...restProps
}) => {
  const changePriceIdWrapper = changePriceId ?? defaultChangeNumberWrapper;
  const changePriceValueWrapper =
    changePriceValue ?? defaultChangeNumberWrapper;

  const changeLabelIdWrapper = changeLabelId ?? defaultChangeNumberWrapper;
  const changeLabelValueWrapper =
    changeLabelValue ?? defaultChangeStringWrapper;

  const changeCountIdWrapper = changeCountId ?? defaultChangeNumberWrapper;
  const changeCountValueWrapper =
    changeCountValue ?? defaultChangeNumberWrapper;

  const changeStartIdWrapper = changeStartId ?? defaultChangeNumberWrapper;
  const changeStartValueWrapper =
    changeStartValue ?? defaultChangeNumberWrapper;

  return (
    <div className={className}>
      <div className={"afo-table-container"}>
        <AFOTable
          changeLabelId={changeLabelIdWrapper}
          changeCountId={changeCountIdWrapper}
          changePriceId={changePriceIdWrapper}
          changeLabelValue={changeLabelValueWrapper}
          changeCountValue={changeCountValueWrapper}
          changePriceValue={changePriceValueWrapper}
          changeStartId={changeStartIdWrapper}
          changeStartValue={changeStartValueWrapper}
          {...restProps}
        />
      </div>
    </div>
  );
};

export default styled(AFOTableContainer)`
  @media (min-width: 1024px) {
    padding: 0 105px;
  }
  @media (max-width: 1024px) {
    padding: 0;
  }
  text-align: center;

  .afo-table-container {
    padding: 5px;
    text-align: center;
    display: inline-block;
    width: 100%;
  }
`;
