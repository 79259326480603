import * as React from "react";

function SvgSky(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <path
        d="M185.8 100.2c-9.8 0-16.8 4.9-16.8 16.9v211.7c0 4.7 3.1 6.7 7.1 6.7h24.7c9.9 0 16.8-4.9 16.8-16.9V106.9c0-4.7-3.1-6.7-7.1-6.7h-24.7zM57.3 172.4C19.7 172.4 0 194.1 0 221.9c0 24.7 15.2 39.7 45.5 46.4l48.4 10.8c9.8 2.2 12.9 5 12.9 10 0 3.8-3.1 6.9-11.9 6.9h-75C7.9 296 3 303 3 312.8v15.7c0 3.9 1.9 7.1 6.7 7.1H92c44.4 0 62.4-21.8 62.4-47.5 0-26.6-15.4-41.6-45.5-48.4l-48.4-10.8c-9.8-2.2-12.9-5-12.9-10 0-3.8 3.1-6.9 11.9-6.9h72c12 0 16.8-7 16.8-16.8v-15.6c0-3.9-2-7.1-6.7-7.1H57.3zm241.7 0c-7.4 0-11.4 2-15.8 6.7l-62.8 67.4 56.3 82.1c3.3 4.7 6 7 10.9 7h33.6c5 0 7.9-2 7.9-6.2 0-2.6-1.3-4.3-2.9-6.7l-49.5-73.2 37.6-40.5 74.1 109.6-32.8 69.3c-.9 2-2 4-2 6 0 4 2.9 5.9 6.9 5.9h29.7c6.1 0 8.7-2.1 10.9-7L498 184.3c1-2.1 2-4 2-6 0-4-2.9-6-6.9-6h-30c-6 0-8.7 2.1-10.9 7l-42.3 93.9-59-92.9c-3.6-5.8-7.5-8-16.2-8H299z"
        fill={color}
      />
    </svg>
  );
}

export default SvgSky;
