import { ProductOption } from "../state/definitions/RecommendationState";
import { SVGComponent } from "./default";
import { MonthlyPriceArray } from "./application";

export interface SummarizedPriceInformation {
  monthly: MonthlyPriceArray;
  once: number;
  list: number[];
}

export interface PassData {
  id: number;
  name: string;
  selectedPosition: number;
  image: SVGComponent;
  price: number;
}

export enum CountUnits {
  months = "Monate",
  nothing = "",
  normal = "mal",
}

export enum PriceUnits {
  euro = "€",
  months = "€/mtl.",
  nothing = "",
}

export interface SubProduct<T = TableDataObject> {
  title: string;
  subtitle?: string;
  data: T[];
  standardObject: T;
}

export type PassSubProduct = Omit<SubProduct<PassData>, "standardObject">;

export enum TableCellType {
  SELECTION = "SELECTION",
  INPUT = "INPUT",
  EXTERNAL = "EXTERNAL",
  NONE = "NONE",
}

export interface TableDataObject {
  labelId: number;
  labels: string[];
  labelType: TableCellType;
  countId: number;
  counts: number[];
  countType: TableCellType;
  countUnit: CountUnits;
  priceId?: number;
  prices: number[];
  priceType: TableCellType;
  priceUnit: PriceUnits;
}

export interface AdditionalTableDataObject extends TableDataObject {
  startId?: number;
  starts: number[];
  startType: TableCellType;
}

export interface FurtherTableDataObject extends TableDataObject {
  dataVolumes: number[];
}

export type UnknownTableDataObject = TableDataObject &
  Partial<AdditionalTableDataObject> &
  Partial<FurtherTableDataObject>;

export interface AdditionalSubProduct {
  gigaKombiId: number;
  regioDiscountId: number;
}

export interface AdditionalProduct
  extends SubProduct<AdditionalTableDataObject> {
  additionalData: AdditionalSubProduct;
}

export interface SubProducts {
  passes: PassSubProduct;
  additionalTable: AdditionalProduct;
  furtherProductsTable: SubProduct<FurtherTableDataObject>;
  singlePaymentTable: SubProduct;
  priceInformation: SummarizedPriceInformation;
  advantages: ProductOption<number[]>[];
}
