import {
  OfferOption,
  ProductOption,
} from "../../state/definitions/RecommendationState";

export function getValueAsProductOptionArray<T, R>(
  mapFkt: (o: T) => { label: R; productId: number },
  arr: T[]
): ProductOption<R>[] {
  return arr.map(mapFkt);
}

export function getValueAsOfferOptionArray<T, R>(
  mapFkt: (o: T) => { label: R; id: number },
  arr: T[]
): OfferOption<R>[] {
  return arr.map(mapFkt);
}
