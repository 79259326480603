import React from "react";
import styled from "styled-components";
import { SVGComponent } from "../../../types/default";
import PanelItem from "../../panel/PanelItem";
import RedPanelValue from "../../panel/RedPanelValue";
import { MobileImportants } from "../../../types/profile";
import useBootstrap from "../../../hooks/useBootstrap";
import { getMobileCardLabel } from "../../../helper/recommendation/mapping";
import { ContractSummary } from "../../../types/application";

interface PanelItemsMobileImportantProps {
  className?: string;
  iconSet: MobileImportantIcons;
  importants: MobileImportants;
}

export interface MobileImportantIcons {
  mobile: SVGComponent;
  mobileSecurity: SVGComponent;
  ping: SVGComponent;
  moreMoney: SVGComponent;
  money: SVGComponent;
  backspace: SVGComponent;
  accelerometerHalf: SVGComponent;
  windowedApps: SVGComponent;
  music: SVGComponent;
  chat: SVGComponent;
  video: SVGComponent;
  gaming: SVGComponent;
  sim: SVGComponent;
}

const PanelItemsMobileImportant: React.FC<PanelItemsMobileImportantProps> = ({
  iconSet,
  importants,
}) => {
  const { formConfig } = useBootstrap();

  const cardNames: string[] = importants.cards
    ? Object.keys(importants.cards).map((v) =>
        getMobileCardLabel(v as keyof ContractSummary, formConfig)
      )
    : [];
  const cardCounts: number[] = importants.cards
    ? Object.values(importants.cards)
    : [];

  return (
    <>
      {importants.newSmartphone && (
        <PanelItem icon={iconSet.mobile}>Neuestes Smartphone</PanelItem>
      )}
      {importants.phoneInsurance && (
        <PanelItem icon={iconSet.mobileSecurity}>Handyversicherung</PanelItem>
      )}
      {importants.oldNewChange && (
        <PanelItem icon={iconSet.ping}>Alt gegen Neu</PanelItem>
      )}
      {importants.personalDataVolume && (
        <PanelItem icon={iconSet.accelerometerHalf}>
          Datenvolumen für mich
        </PanelItem>
      )}
      {importants.personalHomeDataVolume && (
        <PanelItem icon={iconSet.accelerometerHalf}>
          Datenvolumen im Haushalt
        </PanelItem>
      )}
      {importants.highOncePrice && (
        <PanelItem icon={iconSet.moreMoney}>
          Hoher 1x Preis/geringer monatl. Preis
        </PanelItem>
      )}
      {importants.highMonthsPrice && (
        <PanelItem icon={iconSet.money}>
          Geringer 1x Preis/Hoher monatl. Preis
        </PanelItem>
      )}
      {importants.noMonthlyCosts && (
        <PanelItem icon={iconSet.backspace}>Keine Fixkosten</PanelItem>
      )}
      {importants.neededDataVolume !== undefined &&
        importants.neededDataVolume > 0 && (
          <PanelItem icon={iconSet.accelerometerHalf}>
            Bedarf Datenvolumen{" "}
            <RedPanelValue>{importants.neededDataVolume}</RedPanelValue> GB
          </PanelItem>
        )}
      {importants.socialMedia && (
        <PanelItem icon={iconSet.windowedApps}>Social Media Apps</PanelItem>
      )}
      {importants.music && (
        <PanelItem icon={iconSet.music}>Music Apps</PanelItem>
      )}
      {importants.chat && <PanelItem icon={iconSet.chat}>Chat Apps</PanelItem>}
      {importants.video && (
        <PanelItem icon={iconSet.video}>Video Apps</PanelItem>
      )}
      {importants.gaming && (
        <PanelItem icon={iconSet.gaming}>Gaming Apps</PanelItem>
      )}
      {cardNames.length > 0 && cardCounts.length > 0 && (
        <>
          {cardNames.map((v, i) => {
            return (
              <React.Fragment key={i}>
                {cardCounts[i] > 0 && (
                  <PanelItem icon={iconSet.sim}>
                    {v + ": "}
                    <RedPanelValue>{cardCounts[i]}</RedPanelValue>
                    {" ("}
                    {!!importants.cards &&
                      !!importants.contractNames &&
                      importants.contractNames[
                        Object.keys(importants.cards)[
                          i
                        ] as keyof ContractSummary
                      ]}
                    {")"}
                  </PanelItem>
                )}
              </React.Fragment>
            );
          })}
        </>
      )}
    </>
  );
};
export default styled(PanelItemsMobileImportant)``;
