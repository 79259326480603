import * as React from "react";
import styled from "styled-components";
import { StyledComponentProps } from "../../types/default";
import Column from "../grid/Column";

export interface LinkButtonContainerProps extends StyledComponentProps {
  textAlign?: "left" | "right" | "center";
}

const LinkButtonContainer: React.FC<LinkButtonContainerProps> = ({
  className,
  children,
}) => {
  return (
    <Column
      minWidth={"initial"}
      maxWidth={"unset"}
      withoutBottomMargin
      extraClassName={className}
    >
      {children}
    </Column>
  );
};

export default styled(LinkButtonContainer)`
  text-align: ${({ textAlign }) => textAlign ?? "right"};
`;
