import { getProviderKeyOfType, getProvidersOfType } from "../../helper/recommendation/mapping";
import useBootstrap from "../useBootstrap";
import { useFormState } from "../useForm";
import { useEffect, useState } from "react";
import { Interest } from "../../types/application";

export const useProvider = ( type: Interest)=>{
  const providerIdKey = getProviderKeyOfType(type);
  const providersKey = getProvidersOfType(type);
  const providers = useBootstrap().formConfig[providersKey];
  const providerIdState = useFormState(providerIdKey);
  const [providerId]  = providerIdState;
  const vfProviderPosState = useState(-1);
  const [vfProviderPos, setVfProviderPos]= vfProviderPosState;
  let isVodafoneProvider = providerId === vfProviderPos;

  // get "vodafone" position in given provider list
  useEffect(() => {
    const foundedVf = providers.find(
      (v) => v.label.toLowerCase() === "vodafone"
    );
    if (foundedVf !== undefined) {
      setVfProviderPos(foundedVf.value);
    }
  }, [providers]);

  return {
    isVodafoneProvider,
    vfProviderPosState,
    providerIdState
  }
}