import { useContext } from "react";
import { AppContext } from "../state/AppContext";
import { v4 as uuid } from "uuid";
import { defaultFormState } from "../state/defaults/defaultFormState";
import { defaultNavigationState } from "../state/defaults/defaultNavigationState";
import { defaultOfferState } from "../state/defaults/defaultOfferState";
import { defaultAvailabilityState } from "../state/defaults/defaultAvailabilityState";
import { defaultProductsState } from "../state/defaults/defaultProductsState";
import { defaultRecommendationState } from "../state/defaults/defaultRecommendationState";
import { defaultNewOfferState } from "../state/defaults/defaultNewOfferState";

const useApp = () => {
  const [, setState] = useContext(AppContext);

  const resetApp = () => {
    const offerSessionId = uuid();
    setState((prev) => ({
      ...prev,
      offerSessionId: offerSessionId,
      // ↓ bugfix, the state of 'mobileFirstHumanProductCounts' is not resetting else
      form: JSON.parse(JSON.stringify(defaultFormState)),
      navigation: defaultNavigationState,
      offer: defaultOfferState,
      newOffer: defaultNewOfferState,
      availability: defaultAvailabilityState,
      products: defaultProductsState,
      recommendation: defaultRecommendationState,
      // Explicitly no resetting following values:
      // vauoid,
      // globalInformation: defaultGlobalInformationState,
      // bootstrap: defaultBootstrapState,
    }));
  };

  return { resetApp };
};

export default useApp;
