import { DISABLE_EXTRA_FOOTER_HEIGHT } from "../constants/default";

const MIN_LINE_COUNT_FOR_EXTRA_HEIGHT = 2;

export default function calcFooterHeight(lineCounts: number = 0): number {
  if (DISABLE_EXTRA_FOOTER_HEIGHT) {
    return 75;
  }

  if (lineCounts < MIN_LINE_COUNT_FOR_EXTRA_HEIGHT) {
    return 75;
  }
  if (lineCounts === MIN_LINE_COUNT_FOR_EXTRA_HEIGHT) {
    return 80;
  }
  return 80 + (lineCounts - MIN_LINE_COUNT_FOR_EXTRA_HEIGHT) * 13;
}
