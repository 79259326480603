import React, { useState } from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import { AddressResult } from "../../state/definitions/AvailabilityState";
import useAvailability, { useAddressSearch } from "../../hooks/useAvailability";
import AddressList from "../elements/availabilityPage/AddressList";
import AvailabilityGrid from "../elements/availabilityPage/AvailabilityGrid";
import SearchButton from "../elements/availabilityPage/SearchButton";
import ErrorMessage from "../error/ErrorMessage";
import TextField from "../form/TextField";
import GridColumn from "../grid/GridColumn";
import JustifiedColumnsContainer from "../grid/JustifiedColumnsContainer";
import SpinningLogo from "../logo/SpinningLogo";
import { StyledComponentProps } from "../../types/default";

export function formatAddress(selectedAddress: AddressResult | null): string {
  if (selectedAddress !== null) {
    return `${selectedAddress.street}
  ${selectedAddress.streetNumber}${selectedAddress.streetNumberExtra}, ${selectedAddress.zip}
  ${selectedAddress.city}`;
  }

  return "";
}

const AvailabilityCheckPage: React.FC<StyledComponentProps> = ({
  className,
}) => {
  const { availability, selectedAddress, checkAvailability, reset } =
    useAvailability();
  const { search, loading, addresses, errorMessage } = useAddressSearch();

  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [streetNumberExtra, setstreetNumberExtra] = useState("");

  const onTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    switch (name) {
      case "zip":
        setZip(value);
        break;
      case "city":
        setCity(value);
        break;
      case "street":
        setStreet(value);
        break;
      case "streetNumber":
        setStreetNumber(value);
        break;
      case "streetNumberExtra":
        setstreetNumberExtra(value);
        break;
      default:
        break;
    }
  };

  const onSelectAddress = (address: AddressResult) => {
    checkAvailability(address);
  };

  const onSearch = () => {
    search({
      zip,
      city,
      street,
      streetNumber,
      streetNumberExtra,
    });
  };

  let mode = -1;
  if (addresses.length > 0) {
    mode = 1;
  }
  if (selectedAddress !== null) {
    mode = 0;
  }

  return (
    <div className={className}>
      <div className={"availability-input-fields-container"}>
        <JustifiedColumnsContainer>
          <GridColumn colCount={3} minWidth={"315px"} maxWidth={"415px"}>
            <TextField
              label={"Straße"}
              onChange={onTextInputChange}
              value={street}
              name="street"
            />
          </GridColumn>
          <GridColumn colCount={3} minWidth={"315px"} maxWidth={"415px"}>
            <TextField
              label={"Nummer"}
              onChange={onTextInputChange}
              value={streetNumber}
              name="streetNumber"
            />
          </GridColumn>
          <GridColumn colCount={3} minWidth={"315px"} maxWidth={"415px"}>
            <TextField
              label={"Zusatz"}
              onChange={onTextInputChange}
              value={streetNumberExtra}
              name="streetNumberExtra"
            />
          </GridColumn>
          <GridColumn colCount={3} minWidth={"315px"} maxWidth={"415px"}>
            <TextField
              label={"Stadt"}
              onChange={onTextInputChange}
              value={city}
              name="city"
            />
          </GridColumn>
          <GridColumn colCount={3} minWidth={"315px"} maxWidth={"415px"}>
            <TextField
              label={"PLZ"}
              name="zip"
              onChange={onTextInputChange}
              value={zip}
            />
          </GridColumn>
          <GridColumn
            colCount={3}
            minWidth={"315px"}
            maxWidth={"415px"}
            textAlign={"right"}
          >
            <SearchButton
              onClick={() => {
                reset();
                onSearch();
              }}
              disabled={loading}
            >
              Neue Suche
            </SearchButton>
          </GridColumn>
        </JustifiedColumnsContainer>
      </div>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {loading && <SpinningLogo />}
      {!loading && (
        <div className={"availability-result-container-del"}>
          {mode === 0 && (
            <>
              <div className="address">{formatAddress(selectedAddress)}</div>
              <div className="subheader">Deine Möglichkeiten:</div>
              <AvailabilityGrid data={availability} />
            </>
          )}
          {mode === 1 && (
            <AddressList
              addresses={addresses}
              onSelectAddress={onSelectAddress}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default styled(AvailabilityCheckPage)`
  text-align: center;
  padding: 20px;

  .address {
    background: ${Color.WHITE};
    text-align: left;
    padding: 15px;
    flex-grow: 1;
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  .subheader {
    text-align: left;
    font-size: 24px;
    padding: 15px 0;
  }

  .availability-input-fields-container {
    text-align: left;
    margin-bottom: 20px;
  }
  .availability-result-container {
    display: inline-block;
    .availability-result-placeholder-container {
      display: block;
      margin-bottom: 15px;
    }
    .availability-result-buttons {
      display: block;
      margin-top: 50px;

      > div {
        margin: 0 18px;
      }
      div:first-child {
        margin-left: 0;
      }
      div:last-child {
        margin-right: 0;
      }
    }
  }

  img {
    margin: auto;
    margin-top: 30px;
    animation: App-logo-spin infinite 1s linear;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;
