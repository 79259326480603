import React, { CSSProperties } from "react";
import styled from "styled-components";
import { Color } from "../../../../constants/color";
import MiniGigaCircles from "../gigakombi/MiniGigaCircles";
import GigaKombiSVG from "../gigakombi/GigaKombiSVG";
import { GigaKombiEntry } from "../../../../types/application";

interface MiniPrintGigaKombiProps {
  className?: string;
  selected?: boolean;
  style?: CSSProperties;
  gigaKombiEntry: GigaKombiEntry;
  starText?: string;
  withStar?: boolean;

  backgroundColor?: string;
  gigaKombiText?: string;
  onChange?: (value: boolean) => void;
}

const MiniPrintGigaKombi: React.FC<MiniPrintGigaKombiProps> = ({
  className,
  style,
  gigaKombiEntry: { mobile, iot, fixed, tv, circleData },
  gigaKombiText,
  onChange,
  selected,
  starText,
  withStar,
}) => {
  const onClick = (_: React.MouseEvent) => {
    if (!!onChange) {
      onChange(!selected);
    }
  };
  return (
    <div className={className} style={style} onClick={onClick}>
      {!!gigaKombiText && (
        <div className={"giga-paragraph"}>
          <p>{gigaKombiText}</p>
        </div>
      )}
      <div className={"giga-icons-column"}>
        <GigaKombiSVG withsObject={{ mobile, iot, fixed, tv }} size={60} />
      </div>
      <div className={"giga-prices"}>
        <MiniPrintGigaCircles
          circleData={circleData.slice(0, 2)}
          withStar={!!withStar}
        />
      </div>
      {starText !== undefined && (
        <p className={"giga-star-text"}>{"* " + starText}</p>
      )}
    </div>
  );
};

const MiniPrintGigaCircles = styled(MiniGigaCircles)`
  .giga-circle-container {
    .giga-value {
      &.giga-unlimited-value {
        top: 4px;
      }
    }
    .giga-description {
      left: 10px;
    }
  }
`;

export default styled(MiniPrintGigaKombi)`
  position: relative;
  display: block;
  margin: 0 auto 0 auto;
  max-width: 612px;
  padding-bottom: ${({ starText }) => (!!starText ? "12px" : "0")};

  background-color: ${({ selected }) => (selected ? Color.GREY3 : Color.RED)};
  color: ${Color.WHITE};

  text-align: left;
  overflow: hidden;

  .giga-paragraph {
    font-size: 15px;
    margin: 10px 0 0 5px;

    p {
      padding: 0;
    }
  }

  .giga-icons-column {
    position: relative;
    width: calc(45% - 4px);
    display: inline-block;
    margin: 2px 0 0 2px;
    vertical-align: top;
    text-align: center;
    .icon {
      margin-top: 3px;
    }
  }

  .giga-prices {
    position: relative;
    vertical-align: top;
    width: 55%;
    display: inline-block;
  }

  .giga-text {
    top: 13px;
    width: calc(100% - 26px);
    left: 13px;
  }

  .giga-star-text {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 12px;
    margin: 2px 5px;
    text-align: right;
  }
`;
