import React, { useState } from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import { useRefEffect } from "../../hooks/common/useRefHook";
import { useOffer } from "../../hooks/useOffer";
import { useOfferStatusCheck } from "../../hooks/useOfferStatusCheck";
import Icons from "../../icons/Icons";
import { StyledComponentProps } from "../../types/default";
import ErrorModal from "../error/ErrorModal";
import SpinningLogo from "../logo/SpinningLogo";
import SVGIcon from "../svgIcon/SVGIcon";
import axios from "../../hooks/common/axios";

const OfferPage: React.FC<StyledComponentProps> = ({ className }) => {
  const { submitOffer, loading, offerResponse, errorMessage, errorCode } =
    useOffer();

  const {
    checkOfferStatus,
    offerStatusCheckloading,
    offerStatusCheckResponse,
    offerStatusCheckErrorMessage,
    offerStatusCheckErrorCode,
  } = useOfferStatusCheck();

  /**
   * Trigger initial status check after enqueuing pdf creation
   */
  useRefEffect(() => {
    let t: undefined | NodeJS.Timeout = undefined;
    if (offerResponse?.wait) {
      t = setTimeout(() => {
        checkOfferStatus(offerResponse.docId);
      }, Math.min(offerResponse.wait * 1000, 30000));
    }

    return () => {
      if (t !== undefined) {
        clearTimeout(t);
      }
    };
  }, [offerResponse?.timestamp]);

  /**
   * Reenqueue status check after status check returned returned not yet finished
   */
  useRefEffect(() => {
    let t: undefined | NodeJS.Timeout = undefined;
    if (offerStatusCheckResponse && !offerStatusCheckResponse?.finished) {
      t = setTimeout(() => {
        checkOfferStatus(offerStatusCheckResponse.docId);
      }, Math.min(offerStatusCheckResponse.wait * 1000, 30000));
    }

    return () => {
      if (t !== undefined) {
        clearTimeout(t);
      }
    };
  }, [offerStatusCheckResponse?.timestamp]);

  // Submit offerstate to server on component load
  useRefEffect(() => {
    submitOffer();
  }, []);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  useRefEffect(() => {
    setShowErrorMessage(
      errorMessage !== "" || offerStatusCheckErrorMessage !== ""
    );
  }, [errorMessage, offerStatusCheckErrorMessage]);



  /**
   * Download the pdf in the packground including auth, then prepare an Object 
   * URL and attach it to a link which is automatically clicked in the background
   * @param documentUrl 
   * @param docId 
   * @returns 
   */
  const openPDF = (documentUrl: string, docId: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    let token = localStorage.getItem("lta");

    // Set jwt token as authorization header
    let headers = {};
    if (token) {
      headers = {
        Authorization: token,
      };
    }
    axios.get(documentUrl, { headers, responseType: 'blob' }).then((res) => {
      const file = new Blob([res.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a')
      link.href = fileURL;
      link.setAttribute('download', `${docId}.pdf`);
      document.body.appendChild(link);
      link.click();
      // window.open(fileURL);
    });
  };

  if (offerStatusCheckResponse?.finished) {
    return (
      <div className={className}>
        <div className="box">
          <div className="content">
            Gib den folgenden Code im Backend-System an Deinem Beraterplatz ein
            und sende Deinem Kunden die Produktempfehlung per E-Mail zu:
          </div>
          <div className="offer-id">{offerStatusCheckResponse.docId}</div>
          <div className="content">
            Oder lade hier die Empfehlung runter und drucke sie aus
          </div>
          <div className="offer" >
            <div className="download-link" onClick={openPDF(offerStatusCheckResponse.documentUrl, offerStatusCheckResponse.docId)}>
              <SVGIcon src={Icons.download} alt="" />
            </div>
            {/* <a
              target="_blank"
              rel="noopener noreferrer"
              href={offerStatusCheckResponse.documentUrl}
            >
            </a> */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {!loading &&
        !offerStatusCheckloading &&
        (!!errorMessage || !!offerStatusCheckErrorMessage) && (
          <ErrorModal
            isOpen={showErrorMessage}
            setIsOpen={setShowErrorMessage}
            textKey={errorMessage || offerStatusCheckErrorMessage}
            code={!!errorMessage ? errorCode : offerStatusCheckErrorCode}
          />
        )}
      <div className={className}>
        <div className="box">
          <div className="content centered">
            Die Erstellung Deiner Empfehlung ist in Arbeit.
          </div>
          <SpinningLogo />
          {loading && (
            <div className="content centered">
              Die Erstellung Deiner Empfehlung wird grade beim Server
              beauftragt.
            </div>
          )}
          {offerResponse?.success && (
            <>
              {offerResponse.wait < 60 && (
                <div className="content centered">
                  Es dauert voraussichtlich einige Sekunden bis zur
                  Fertigstellung.
                </div>
              )}
              {offerResponse.wait >= 60 && (
                <div className="content centered">
                  Es ist grade viel los auf den Servern. Es dauert
                  voraussichtlich etwas mehr als{" "}
                  {Math.floor(offerResponse.wait / 60)} Minute(n) bis zur
                  Fertigstellung.
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default styled(OfferPage)`
  margin: auto;
  text-align: center;

  .box {
    max-width: 560px;
    margin: auto;
  }

  .content {
    text-align: left;
    font-size: 24px;
    padding: 15px 0;
  }

  .centered {
    text-align: center;
  }

  .offer-id {
    background-color: ${Color.RED};
    color: ${Color.WHITE};
    font-size: 48px;
    letter-spacing: 10px;
    padding: 20px;
  }

  .offer {
    font-size: 36px;
    padding: 25px 10px;

    .download-link {
      display: inline-flex;
      background-color: ${Color.RED};
      border-radius: 10px;
      padding: 20px;
      cursor: pointer;
    }

    img,
    svg {
      height: 96px;
      margin: 0;
      cursor: pointer;
    }
  }

  img {
    margin: auto;
    margin-top: 30px;
  }

  .spin {
    animation: App-logo-spin infinite 1s linear;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;
