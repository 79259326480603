import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import { get2FixedNumber } from "../../../helper/default";

interface AFOInputCellProps {
  className?: string;
  inputClassName?: string;
  extraClassName?: string;
  changeValue: (newValue: number) => void;
  realValue: number;
  defaultValue: number;
  min?: number;
  max?: number;
  step?: number;
  colSpan?: number;
  displayValueFormatter?: (
    value: number,
    isEditing?: boolean
  ) => number | string;
  noEditFeature?: boolean;
  noFloat?: boolean;
}

const AFOInputCell: React.FC<AFOInputCellProps> = ({
  className,
  inputClassName,
  extraClassName,
  changeValue,
  realValue,
  defaultValue = 0,
  min = 0,
  max,
  colSpan = 1,
  step = 1,
  children,
  displayValueFormatter,
  noEditFeature = false,
  noFloat = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(0);

  useEffect(() => {
    if (realValue !== defaultValue) {
      setEditValue(realValue);
    } else {
      setEditValue(0);
    }
  }, [realValue, defaultValue]);

  const inputEl = useRef<HTMLInputElement>(null);
  const setInputFocus = () => {
    if (inputEl !== null && inputEl.current !== null) {
      inputEl.current.focus();
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let numberedValue = Number(e.target.value);
    if (noFloat) {
      numberedValue = parseFloat(e.target.value);
    }
    const newValue: number = get2FixedNumber(numberedValue);

    const newValueChecked = isNaN(newValue) ? 0 : newValue;
    if (noEditFeature) {
      changeValue(newValueChecked);
    } else {
      setEditValue(newValueChecked);
    }
  };
  const onInputFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEditing(true);
  };
  const onInputLeave = (e: React.ChangeEvent<HTMLInputElement>) => {
    // reset
    setIsEditing(false);

    if (noEditFeature) {
      return;
    }

    // set real value
    if (editValue < min) {
      changeValue(min);
      setEditValue(0);
      return;
    }
    if (max !== undefined && editValue > max) {
      changeValue(max);
      setEditValue(max);
      return;
    }
    changeValue(editValue);
  };

  const displayValue = isEditing ? editValue : realValue;
  const isPlaceholdered =
    (!isEditing && displayValue === defaultValue) ||
    (isEditing && displayValue === 0);

  let formattedValue: number | string = displayValue;
  let formattedPlaceholder: number | string = defaultValue;
  if (!isEditing && displayValueFormatter) {
    formattedValue = displayValueFormatter(displayValue, isEditing);
    formattedPlaceholder = displayValueFormatter(defaultValue, isEditing);
  }

  return (
    <td
      className={`${className} cell row-label ${extraClassName}`}
      colSpan={colSpan}
      onClick={setInputFocus}
    >
      <div className={`cell-input`}>
        <input
          className={`number-input ${inputClassName}`}
          type={"number"}
          value={isPlaceholdered ? "" : formattedValue}
          placeholder={isPlaceholdered ? String(formattedPlaceholder) : ""}
          onChange={onChange}
          ref={inputEl}
          step={step}
          onFocus={onInputFocus}
          onBlur={onInputLeave}
        />
        {children}
      </div>
    </td>
  );
};

export default styled(AFOInputCell)`
  background: ${Color.GREY_LIGHT6};
  cursor: text;

  .cell-input {
    box-shadow: inset 4px 5px 12px -8px ${Color.BLACK};
    height: 100%;

    .number-input {
      width: 100%;
      padding: 5px;

      height: 100%;
      font-size: 16px;
      background: transparent;
      border: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;

      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    }
  }
`;
