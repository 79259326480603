import * as React from "react";
import { SubmitOfferRequestData } from "../../hooks/useOffer";
import { LoadableResources, loadConfigData } from "../../helper/loadConfigData";
import { DisplayErrorMessages } from "../../constants/DisplayErrorMessages";
import ErrorModal from "./ErrorModal";
import { ErrorCodes } from "../../constants/ErrorCodes";

export interface ErrorBoundaryProps {
  path: string;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string;
  errorCode: string;
}

const defaultState: ErrorBoundaryState = {
  hasError: false,
  errorMessage: "",
  errorCode: "",
};

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = defaultState;
  }

  async componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    try {
      const storageId: string | undefined =
        document.location.search !== undefined &&
        document.location.search.length > 0
          ? document.location.search.substr(1)
          : undefined;
      const requestDataString = window.localStorage.getItem(
        "payload-" + storageId
      );
      const requestData: SubmitOfferRequestData | undefined =
        requestDataString !== null ? JSON.parse(requestDataString) : undefined;
      if (requestData !== undefined) {
        await loadConfigData(
          (v) => v,
          LoadableResources.ERROR,
          () => {}, // Empty reloader, since the boundary is only used for internal print calls
          {
            name: "Print page error",
            componentStack: errorInfo.componentStack,
            data: requestData,
            path: this.props.path,
          }
        )();
      }
    } catch (e) {
      this.setState((prev) => {
        return {
          ...prev,
          hasError: true,
          errorMessage: DisplayErrorMessages.technicalProblem,
          errorCode: ErrorCodes.default,
        };
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorModal
          isOpen={this.state.hasError}
          textKey={DisplayErrorMessages.technicalProblem}
          code={ErrorCodes.default}
          notCloseable
        />
      );
    }
    return this.props.children;
  }
}
