import useRecommendation from "../useRecommendation";
import { useNewOfferState } from "./useNewOfferState";
import { getNumberFromProductOption } from "../../helper/getFromOption";
import { useNewOffer } from "../useNewOffer";
import { Interest } from "../../types/application";
import { SubProducts } from "../../types/table";
import { combineDataVolumesOfProducts } from "../../helper/combineDataVolumesOfProducts";

export const useDataVolume = (
  offerEntryId: string,
  subProducts: SubProducts
) => {
  const { getNewOfferType } = useNewOffer();
  const type = getNewOfferType(offerEntryId);
  const [productId] = useNewOfferState("productId", offerEntryId);

  const {
    config: {
      [type]: { dataVolumeMaximum, productDataVolume: productDataVolumeArray },
    },
  } = useRecommendation();
  const [dataVolume, setDataVolume] = useNewOfferState(
    "dataVolume",
    offerEntryId
  );
  const productDataVolume = getNumberFromProductOption(
    productDataVolumeArray,
    productId
  );

  let dataVolumeValue = undefined;
  if (type === Interest.MOBILE) {
    dataVolumeValue = Math.min(
      dataVolumeMaximum,
      dataVolume + productDataVolume
    );
  }
  const setDataVolumeValue = (newValue: number) => {
    const newDataVolumes = Math.max(0, newValue - productDataVolume);
    setDataVolume(newDataVolumes);
  };

  const subProductDataVolume = combineDataVolumesOfProducts([subProducts]);

  let dataVolumeSteps = 5;
  if (!!dataVolumeValue && dataVolumeValue < 25) {
    dataVolumeSteps = 1;
  }

  if (!!dataVolumeValue && dataVolumeValue >= dataVolumeMaximum) {
    dataVolumeSteps = 1;
  }

  return {
    dataVolumeValue,
    subProductDataVolume,
    dataVolumeMaximum,
    setDataVolumeValue,
    dataVolumeSteps,
  };
};
