export function prettyMoney(value: number): string {
  return value.toFixed(2).replace(".", ".") + " €";
}

export function prettyDate(value: Date): string {
  const year: string = String(value.getFullYear());
  const monthValue: number = value.getMonth() + 1;
  const month: string = monthValue < 10 ? "0" + monthValue : String(monthValue);
  const dayValue: number = value.getDate();
  const day: string = dayValue < 10 ? "0" + dayValue : String(dayValue);
  return day + "." + month + "." + year;
}
