import React, { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { parse as parseQueryString } from "qs";
import RedirectMessage from "../error/RedirectMessage";

interface Props {
  redirect: string;
}

const LoginPage: React.FC<Props> = ({ redirect }) => {
  const { restoreAuthFromLocalStorage, initialized } = useAuth();

  useEffect(() => {
    const query = parseQueryString(window.location.search, {
      ignoreQueryPrefix: true,
    });
    if (query.hasOwnProperty("jwt")) {
      restoreAuthFromLocalStorage(query.jwt as string);
    } else {
      restoreAuthFromLocalStorage();
    }
  }, []);

  if (initialized) {
    return (
      <RedirectMessage
        redirect={redirect}
        text="Authentifizierung nicht möglich oder abgelaufen. Du wirst in wenigen Sekunden zur Authentifizierung weiter geleitet."
      />
    );
  }

  return (
    <div style={{ margin: "auto" }}>
      <h1>Initialisiere Anwendung</h1>
    </div>
  );
};

export default LoginPage;
