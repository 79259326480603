export interface FormOption<T = string> {
  value: number;
  label: T;
}

export class FormConfigStateProviders {
  mobileProviders: FormOption[] = [];
  iotProviders: FormOption[] = [];
  fixedProviders: FormOption[] = [];
  tvProviders: FormOption[] = [];
}

export class FormConfigState extends FormConfigStateProviders {
  mobilePreferenceCategories: FormOption[] = [];
  mobilePreferencesSmartphone: FormOption[] = [];
  mobilePreferencesDataVolume: FormOption[] = [];
  mobilePreferencesPrice: FormOption[] = [];
  mobileData: FormOption[] = [];
  mobilePersonalDetails: FormOption[] = [];
  mobileCardDataLabel: FormOption[] = [];
  mobileCardTariffLabel: FormOption[] = [];
  mobileCardDataGoLabel: FormOption[] = [];

  iotInterestLabels: FormOption[] = [];
  iotSmartWatchLabels: FormOption[] = [];
  iotTrackerLabels: FormOption[] = [];

  fixedPreferences: FormOption[] = [];
  fixedCallDestination: FormOption[] = [];
  fixedDeviceTypes: FormOption[] = [];

  tvTechnology: FormOption[] = [];
  tvPreference: FormOption[] = [];
  categoryNames: FormOption[] = [];

  additionalCostInvoice: FormOption[] = [];
  independentPersonalDetails: FormOption[] = [];
}
