import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import TileButton from "./TileButton";

interface LittleGreyButtonProps {
  className?: string;
  onChange: (value: boolean) => void;
  value?: boolean;
}

const LittleGreyButton: React.FC<LittleGreyButtonProps> = ({
  className,
  children,
  onChange,
  value = false,
}) => {
  return (
    <TileButton
      className={className}
      onChange={onChange}
      value={value}
      shadowed={true}
    >
      {children}
    </TileButton>
  );
};

export default styled(LittleGreyButton)`
  background-color: ${({ value }) =>
    value ? Color.RED : Color.GREY_LIGHT} !important;
  border: 1px solid ${({ value }) => (value ? Color.WHITE : Color.GREY_LIGHT)} !important;

  margin: 5px 10px;
  display: inline-block;
  width: 60px;
  padding: 5px;
  box-shadow: 3px 3px 7px 0 ${Color.GREY_LIGHT11};
  text-align: center;
`;
