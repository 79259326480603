import * as React from "react";

function SvgWatchGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#4a4d4e" } = props;
  return (
    <svg
      id="watch_grey_svg___x36_bf2158e-eb94-421e-8807-bf64a95f0eb7"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.watch_grey_svg__st1{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path fill="none" d="M0 0h192v192H0z" />
      <path
        className="watch_grey_svg__st1"
        d="M72 140l3.3 26.7c.4 3 3 5.3 6 5.3h29.4c3 0 5.6-2.2 6-5.3L120 140M70 52h52c9.9 0 18 8.1 18 18v52c0 9.9-8.1 18-18 18H70c-9.9 0-18-8.1-18-18V70c0-9.9 8.1-18 18-18z"
      />
      <path
        className="watch_grey_svg__st1"
        d="M96 68v32h24M72 52l3.3-26.7c.4-3 3-5.3 6-5.3h29.4c3 0 5.6 2.2 6 5.3L120 52"
      />
    </svg>
  );
}

export default SvgWatchGrey;
