import * as React from "react";
import { useState } from "react";
import { StyledComponentProps } from "../../../../types/default";
import styled from "styled-components";
import OfferEntryContainer from "./OfferEntryContainer";

export interface Props extends StyledComponentProps {
  offerEntryIds: string[];
  isPrint?: boolean;
}

const OfferEntriesContainer = ({ offerEntryIds, isPrint }: Props) => {
  const [openId, setOpenId] = useState("");

  return (
    <>
      {offerEntryIds.map((offerEntryId) => {
        return (
          <OfferEntryContainer
            key={offerEntryId}
            offerEntryId={offerEntryId}
            isOpen={openId === offerEntryId}
            setIsOpen={(fct) => {
              if (fct(openId === offerEntryId)) {
                setOpenId(offerEntryId);
              } else {
                setOpenId("");
              }
            }}
            isPrint={isPrint}
          />
        );
      })}
    </>
  );
};

export default styled(OfferEntriesContainer)``;
