import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import start from "../../images/start.png";
import styled from "styled-components";
import { useNavigation } from "../../hooks/useNavigation";
import useVauoid from "../../hooks/useVauoid";
import VauoidStatus from "../elements/vauoid/VauoidStatus";
import VauoidForm from "../elements/vauoid/VauoidForm";
import { useRefEffect } from "../../hooks/common/useRefHook";
import ErrorModal from "../error/ErrorModal";
import SpinningLogo from "../logo/SpinningLogo";
import PulsingLogo from "../logo/PulsingLogo";
import NewsPanel from "../elements/newsPanel/NewsPanel";
import useApp from "../../hooks/useApp";
import { SlidePages } from "../../constants/SlidePages";
import useBootstrap from "../../hooks/useBootstrap";

interface StartPageProps {
  className?: string;
  loading: boolean;
}

const StartPage: React.FC<StartPageProps> = ({ className }) => {
  const { loading, errorMessage, errorCode } = useBootstrap();
  const { nextSlide } = useNavigation();
  const [vauoid, setVauoid] = useVauoid();
  const [showForm, setShowform] = useState(!vauoid);

  const { resetApp } = useApp();
  useRefEffect(() => {
    resetApp();
  }, []);

  const onShowFormClick = () => {
    setShowform(true);
  };

  const onVauoidSave = (value: string) => {
    setVauoid(value);
    setShowform(false);
  };
  const [showErrorModal, setShowErrorModal] = useState(errorMessage !== "");
  useEffect(() => {
    setShowErrorModal(errorMessage !== "");
  }, [setShowErrorModal, errorMessage]);
  return (
    <div className={className}>
      {showErrorModal && (
        <ErrorModal
          isOpen={showErrorModal}
          setIsOpen={setShowErrorModal}
          textKey={errorMessage}
          code={errorCode}
          notCloseable
        />
      )}
      {!showErrorModal && loading && <SpinningLogo />}
      {!showErrorModal && !loading && (
        <React.Fragment>
          {showForm ? (
            <VauoidForm vauoid={vauoid} onSave={onVauoidSave} />
          ) : (
            <>
              <Link
                to={
                  nextSlide !== null
                    ? "/slides/" + nextSlide
                    : "/slides/" + SlidePages.initialInterest.id
                }
              >
                <PulsingLogo />
              </Link>
              <NewsPanel />
            </>
          )}
          <VauoidStatus onClick={onShowFormClick} />
        </React.Fragment>
      )}
    </div>
  );
};

export default styled(StartPage)`
  background-image: url(${start});
  background-size: cover;
  background-repeat: no-repeat;
  filter: ${(props) => (props.loading ? "blur(4px)" : "none")};
  transition: filter 0.5s;
  background-position-x: center;
  background-position-y: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
`;
