import * as React from "react";

function SvgIpTv(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="ipTV_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.ipTV_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className="ipTV_svg__st0"
        d="M249.6 403.13v31.91m-114.86 6.38h229.74M32.63 58.52h433.95c14.1 0 25.53 11.43 25.53 25.53v280.79c0 14.1-11.43 25.53-25.53 25.53H32.63c-14.1 0-25.53-11.43-25.53-25.53V84.05c0-14.1 11.43-25.53 25.53-25.53z"
      />
      <path
        className="ipTV_svg__st0"
        d="M257.28 285.45c0 4.05-3.29 7.34-7.34 7.34s-7.34-3.29-7.34-7.34 3.29-7.34 7.34-7.34c4.05.01 7.34 3.29 7.34 7.34zm3.67 0c0 6.08-4.93 11.01-11.01 11.01s-11.01-4.93-11.01-11.01 4.93-11.01 11.01-11.01 11.01 4.93 11.01 11.01zm84.65-99.49c-52.9-53.02-138.76-53.11-191.78-.21l-.21.21m163.75 32.26c-37.35-37.42-97.95-37.48-135.38-.14l-.14.14m107.28 32.29c-21.78-21.83-57.13-21.88-78.97-.1-.04.03-.07.07-.1.1"
      />
    </svg>
  );
}

export default SvgIpTv;
