import { UnknownTableDataObject } from "../../types/table";
import { getLastUsedMonth } from "./default";
import { getZeroIfNotExist } from "../ifNotExist";
import { MonthlyPriceArray } from "../../types/application";

export function calculateMonthsPrices(
  d: UnknownTableDataObject,
  price: number,
  priceArray: MonthlyPriceArray,
  startMonth: number,
  endMonth: number
) {
  const monthsCount = d.counts[d.countId] ?? 0;

  let monthlyAddedPrice = 0;
  if (monthsCount !== 0) {
    monthlyAddedPrice = price;
  }

  // For the priceArray (should only summarized #86 (5.) https://gitlab.m-dwh.de/erfurtht/destwo/-/issues/86#note_2332 )
  // So that it shows the highest possible discount, which can be achieved at all.
  // It does not matter that later months there are fewer discounts
  // Only if the count is zero, no value should be added (#167)

  // if the count is -1 - the count-entry is a not countable thing e.g. gigakombi
  const countToAdd = monthsCount !== 0 ? 1 : 0;
  let priceToAdd = price * countToAdd;

  let newStartMonth = startMonth;
  let newEndMonth = endMonth;
  const newPriceArray = Object.assign({}, priceArray);

  let start = 1;
  if (
    d.starts !== undefined &&
    d.startId !== undefined &&
    d.starts[d.startId]
  ) {
    start = d.starts[d.startId] ?? 1;
  }

  if (monthsCount > 0) {
    // copy the old price on the point, where the months ends

    // time borders (start -> end)
    newStartMonth = start - 1;
    newEndMonth = newStartMonth + monthsCount;

    const oldStartValue = priceArray[newStartMonth] ?? undefined;
    const oldEndValue = priceArray[newEndMonth] ?? undefined;

    /**
     * If the last value on the current position (newStartMonth or newEndMonth) not defined, use the lase defined value.
     * Else you have to use the value on this position (oldStartValue, oldEndValue
     */
    if (oldStartValue === undefined) {
      // last used value previous the start border
      const lastUsedPreStartMonth = getLastUsedMonth(priceArray, newStartMonth);
      newPriceArray[newStartMonth] = getZeroIfNotExist(
        priceArray[lastUsedPreStartMonth]
      );
    } else {
      newPriceArray[newStartMonth] = oldStartValue;
    }
    if (oldEndValue === undefined) {
      // last used value previous the end border
      const lastUsedPreEndMonth = getLastUsedMonth(priceArray, newEndMonth);
      newPriceArray[newEndMonth] = getZeroIfNotExist(
        priceArray[lastUsedPreEndMonth]
      );
    } else {
      newPriceArray[newEndMonth] = oldEndValue;
    }

    /**
     *  For calculation debugging:
     *     console.log(lastUsedPreStartMonth + "/" + lastUsedPreEndMonth,lastUsedPreStartValue + "/" + lastUsedPreEndValue,priceArray);
     */
  }

  return {
    monthlyAddedPrice,
    priceToAdd,
    newStartMonth,
    newEndMonth,
    priceArray: newPriceArray,
  };
}
