import * as React from "react";

function SvgBusinessCustomerGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#4a4d4e" } = props;
  return (
    <svg
      id="businessCustomer_grey_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.businessCustomer_grey_svg__st0{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className="businessCustomer_grey_svg__st0"
        d="M156 164.1c-6.5-43.9-33.2-64.2-59.6-64.2S42.5 120.2 36 164.1"
      />
      <path d="M0 0h192v192H0V0z" fill="none" />
      <circle
        className="businessCustomer_grey_svg__st0"
        cx={96}
        cy={63.9}
        r={36}
      />
      <path
        className="businessCustomer_grey_svg__st0"
        d="M100 107.1h-8m12.6 46.9L96 164l-8.6-10 4.2-35c.2-2.3 2.1-4 4.4-4 2.3 0 4.2 1.7 4.4 4l4.2 35z"
      />
    </svg>
  );
}

export default SvgBusinessCustomerGrey;
