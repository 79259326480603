import React from "react";
import styled from "styled-components";
import { SlidePages } from "../../constants/SlidePages";
import LinkButton from "../form/LinkButton";
import { StyledComponentProps } from "../../types/default";
import JustifiedColumnsContainer from "../grid/JustifiedColumnsContainer";
import { Euro } from "../../icons/svgComponents";
import Shopping from "../../icons/svgComponents/Shopping";

interface MoreRecommendationPaginationProps extends StyledComponentProps {}

const MoreRecommendationPagination: React.FC<MoreRecommendationPaginationProps> =
  ({ className }) => {
    return (
      <JustifiedColumnsContainer
        extraClassName={className}
        justifyContent={"space-between"}
      >
        <LinkButton
          startIcon={<Euro />}
          slideId={SlidePages.recommendation.id}
          title={"zur Produktempfehlung"}
          textAlign={"left"}
        />
        <LinkButton
          endIcon={<Shopping />}
          slideId={SlidePages.summaryRecommendation.id}
          title={"zum Warenkorb"}
          value
        />
      </JustifiedColumnsContainer>
    );
  };

export default styled(MoreRecommendationPagination)`
  margin: 0 5px;
`;
