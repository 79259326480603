import { useContext } from "react";
import { decode } from "jsonwebtoken";
import { AppContext } from "../state/AppContext";
import { defaultAuthState } from "../state/defaults/defaultAuthState";
import { User } from "../state/definitions/AuthState";

type Decoded = object | string | null;
type DecodedDmz = { token: string };
type DecodedInt = { exp: number };

const LOCAL_STORAGE_KEY = "lta";

const useAuth = () => {
  const [state, setState] = useContext(AppContext);

  const login = (token: string) => {
    try {
      const payloadDmz: Decoded = decode(token);
      if (typeof payloadDmz === "object") {
        const payloadInt: Decoded = decode((payloadDmz as DecodedDmz).token);
        if (typeof payloadInt === "object") {
          if ((payloadInt as DecodedInt).exp * 1000 >= Date.now()) {
            localStorage.setItem(LOCAL_STORAGE_KEY, token);
            setState((prevState) => ({
              ...prevState,
              auth: {
                ...prevState.auth,
                token,
                user: payloadInt as User,
              },
            }));
          } else {
            throw new Error("Token timed out");
          }
        }
      }
    } catch (e) {
      setState((prevState) => ({
        ...prevState,
        auth: {
          ...defaultAuthState,
          initialized: true,
        },
      }));
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  };

  const logout = (redirectTimeout: number = 5000) => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    // Clear jwt search param when logging out
    window.history.pushState({}, document.title, window.location.pathname);
    setState((prevState) => ({
      ...prevState,
      auth: {
        ...defaultAuthState,
        redirectTimeout,
        initialized: true,
      },
    }));
  };

  const restoreAuthFromLocalStorage = (overrideToken: string = "") => {
    let token = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (overrideToken) {
      token = overrideToken;
    }
    if (token) {
      login(token);
    }
    setState((prevState) => ({
      ...prevState,
      auth: {
        ...prevState.auth,
        initialized: true,
      },
    }));
  };

  return {
    login,
    logout,
    token: state.auth.token,
    authenticated: !!state.auth.token,
    initialized: state.auth.initialized,
    user: state.auth.user,
    restoreAuthFromLocalStorage,
    redirectTimeout: state.auth.redirectTimeout,
  };
};

export default useAuth;
