import React from "react";
import styled from "styled-components";
import JustifiedColumnsContainer from "../grid/JustifiedColumnsContainer";
import { Color } from "../../constants/color";
import { SimpleState } from "../../types/default";
import LittleGreyButton from "./LittleGreyButton";
import DateField from "./DateField";
import LabelledTextSelectorCarousel from "./horizontalSelector/LabelledTextSelectorCarousel";
import GridColumn from "../grid/GridColumn";
import SizedGridColumn from "../grid/SizedGridColumn";
import { FormOption } from "../../state/definitions/FormConfigState";

interface ProviderQuestionBlockProps {
  className?: string;
  contractEndDateState?: SimpleState<Date>;
  withoutCurrentContractEndState?: SimpleState<boolean>;
  selectedProviderState: SimpleState<number>;
  providers: FormOption[];
}

const ProviderQuestionBlock: React.FC<ProviderQuestionBlockProps> = ({
  className,
  children,
  contractEndDateState,
  withoutCurrentContractEndState,
  selectedProviderState,
  providers,
}) => {
  const [provider, setProvider] = selectedProviderState;

  return (
    <div className={className}>
      <JustifiedColumnsContainer>
        <GridColumn colCount={1.5}>
          {!!contractEndDateState && !!withoutCurrentContractEndState && (
            <ContractEndDateCol
              contractEndDateState={contractEndDateState}
              withoutCurrentContractEndState={withoutCurrentContractEndState}
            />
          )}
        </GridColumn>
        <GridColumn colCount={3} minWidth={"271px"}>
          <JustifiedColumnsContainer alignItems={"center"}>
            {children && (
              <SizedGridColumn width={"55px"}>{children}</SizedGridColumn>
            )}
            <GridColumn colCount={2}>
              <LabelledTextSelectorCarousel
                label={"Anbieter:"}
                valueId={provider}
                items={providers}
                onChange={setProvider}
              />
            </GridColumn>
          </JustifiedColumnsContainer>
        </GridColumn>
      </JustifiedColumnsContainer>
    </div>
  );
};

const ContractEndDateCol = ({
  contractEndDateState,
  withoutCurrentContractEndState,
}: {
  contractEndDateState: SimpleState<Date>;
  withoutCurrentContractEndState: SimpleState<boolean>;
}) => {
  const [contractEndDate, setContractEndDate] = contractEndDateState;
  const [withoutCurrentContractEnd, setWithoutCurrentContractEnd] =
    withoutCurrentContractEndState;
  const onWithoutProviderData = (value: boolean) => {
    setWithoutCurrentContractEnd(value);
  };
  const onContractEndDateChange = (newDate: Date) => {
    setContractEndDate(newDate);
  };

  return (
    <JustifiedColumnsContainer alignItems={"center"} height={"100%"}>
      <SizedGridColumn width={"80px"}>
        <LittleGreyButton
          onChange={onWithoutProviderData}
          value={withoutCurrentContractEnd}
        >
          Ohne
        </LittleGreyButton>
      </SizedGridColumn>
      <GridColumn colCount={2}>
        <DateField
          value={contractEndDate}
          onChange={onContractEndDateChange}
          defaultValue={new Date()}
          label={"Vertragslaufzeit bis Ende:"}
          disabled={withoutCurrentContractEnd}
        />
      </GridColumn>
    </JustifiedColumnsContainer>
  );
};

export default styled(ProviderQuestionBlock)`
  margin: 20px 50px 0 50px;
  background: ${Color.RED};
  .column {
    @media(min-width: 1017px){
    text-align: center;
    }
    @media(max-width: 1017px){
    text-align: right;
    }
  }
  .default-date-field {
    text-align: center;
    input {
      height: 40px;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .contract-end-date-selector-container {
    display: inline-block;
    width: 43%;
    vertical-align: top;
    text-align: left;
  }

  .guarantee-info-button-container {
    display: inline-block;
    width: 5%;
    vertical-align: top;
    text-align: center;
  }
  .provider-selector-container {
    .i-button-container {
      display: inline-block;
      text-align: center;
      float: none;
      vertical-align: middle;
      }
    }
  }
`;
