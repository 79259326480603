import useRecommendation from "../useRecommendation";
import { useCurrentProducts } from "../useDatabaseProducts";
import { useContext, useMemo } from "react";
import { useOfferState } from "../useOffer";
import { DatabaseProduct } from "../../data/definitions/DatabaseProduct";
import { AppContext } from "../../state/AppContext";
import useAvailability from "../useAvailability";
import { useInterestsValues } from "../useFormHelper/useInterests";
import { useRefEffect } from "../common/useRefHook";
import { useGigaKombiWiths } from "./useGigaKombiWiths";
import { Interest, WithsObject } from "../../types/application";
import { calculateGigaKombiValues } from "../../helper/gigakombi/calculateGigaKombiValues";

export type SimpleGigaKombiValues = [number, number, boolean]; //price,data,sky

function getGigaKombiValues(
  selectedProducts: DatabaseProduct[],
  withsObject: WithsObject
): SimpleGigaKombiValues {
  if (selectedProducts.length === 0) {
    return [0, 0, false];
  }

  let [discountPrice, discountDataVolume, withSky] = calculateGigaKombiValues(
    withsObject,
    selectedProducts
  );

  return [discountPrice, discountDataVolume, withSky];
}

export const useNewGigaKombiUpdate = (isPossible: boolean) => {
  const [state] = useContext(AppContext);
  const { availability } = useAvailability();

  const { getCurrentIds, getSelectedProducts } = useCurrentProducts();
  const currentIds = getCurrentIds();
  const currentProducts = useMemo(
    () => getSelectedProducts(currentIds),
    [currentIds]
  );

  const [, setSelectGigaKombi] = useOfferState("selectedGigaKombi");
  const [, setGigaKombiWithSky] = useOfferState("gigaKombiWithSky");
  const [, setGigaKombiDataVolume] = useOfferState("gigaKombiDataVolume");
  const [, setGigaKombiCalculatedDiscount] = useOfferState(
    "gigaKombiCalculatedDiscount"
  );
  const [, setGigaKombiInterest] = useOfferState("gigaKombiInterest");

  const selectedProductIdsString = currentIds.join("");
  const InterestValues = useInterestsValues();
  const { loading: recommendationLoading } = useRecommendation();
  const { getGigaKombiWiths } = useGigaKombiWiths();

  useRefEffect(() => {
    const gigaKombiWiths = getGigaKombiWiths();
    const [newCalculatedDiscount, newCalculatedDataVolume, newWithSky] =
      getGigaKombiValues(currentProducts, gigaKombiWiths);

    setGigaKombiCalculatedDiscount(newCalculatedDiscount);
    setGigaKombiDataVolume(newCalculatedDataVolume);
    setGigaKombiWithSky(newWithSky && availability.cable.available);
    if (!isPossible) {
      setSelectGigaKombi(false);
    }

    if (gigaKombiWiths.mobile) {
      setGigaKombiInterest(Interest.MOBILE);
    }
    if (!gigaKombiWiths.mobile && gigaKombiWiths.fixed) {
      setGigaKombiInterest(Interest.FIXED);
    }
    if (!gigaKombiWiths.mobile && !gigaKombiWiths.fixed && gigaKombiWiths.tv) {
      setGigaKombiInterest(Interest.TV);
    }
  }, [
    selectedProductIdsString,
    InterestValues.mobileMode,
    InterestValues.iotMode,
    InterestValues.fixedMode,
    InterestValues.tvMode,
    recommendationLoading,
    state.form.mobileHasChanged,
    state.form.iotHasChanged,
    state.form.fixedHasChanged,
    state.form.tvHasChanged,
    isPossible,
  ]);
};
