import { ProductLineInfoForRecommendationConfig } from "./definitions/InformationForRecommendationConfig";
import { RecommendationProductLineTable } from "../../state/definitions/RecommendationState";
import { arrayMultiplier } from "../arrayMultiplier";
import { getValuesFromInformation } from "./getValuesFromInformation";
import { getValueAsArray } from "../getValueAsArray";

export function getProductLineTable(
  info: ProductLineInfoForRecommendationConfig
): RecommendationProductLineTable {
  return {
    tables: info.subProductTableConfig.tables,
    tablesSubtitles: info.subProductTableConfig.subtitles,

    additionalTableLabels: info.subProductTableConfig.additionalLabels,
    additionalTableCounts: arrayMultiplier(
      info.subProductTableConfig.additionalCounts,
      5
    ),
    additionalTableCountIds: info.subProductTableConfig.additionalCountIds,
    additionalTablePrices: info.subProductTableConfig.additionalPrices,
    additionalTableStarts: info.subProductTableConfig.additionalStarts,

    furtherProductIds: getValuesFromInformation(
      "id",
      info.subProductInformation
    ),
    furtherProductLabels: arrayMultiplier(
      getValuesFromInformation("label", info.subProductInformation),
      4
    ),
    furtherProductPrices: arrayMultiplier(
      getValueAsArray((v) => v["price"], info.subProductInformation),
      4
    ),
    furtherProductDataVolumes: arrayMultiplier(
      getValueAsArray((v) => v["dataVolume"], info.subProductInformation),
      4
    ),
    furtherProductCounts: arrayMultiplier(
      info.subProductTableConfig.furtherCounts,
      4
    ),
    furtherProductId: info.subProductTableConfig.furtherProductId,
    furtherProductCountId: info.subProductTableConfig.furtherProductCountId,
    furtherOneProductLabel: info.subProductTableConfig.furtherOneProductLabel,
    furtherOneProductCount: info.subProductTableConfig.furtherOneProductCount,
    furtherOneProductPrice: info.subProductTableConfig.furtherOneProductPrice,

    singlePaymentTableLabels: info.subProductTableConfig.singlePaymentLabels,
    singlePaymentTablePrices: info.subProductTableConfig.singlePaymentPrices,
  };
}
