import { RecommendationConfig } from "../state/definitions/RecommendationState";
import { TransferDatabaseProduct } from "../data/definitions/TransferDatabaseProduct";
import { TransferDatabaseAdvantage } from "../data/definitions/TransferDatabaseAdvantage";
import { DatabaseProduct } from "../data/definitions/DatabaseProduct";
import { ProductInformation } from "./dataHandling/definitions/ProductInformation";
import { SubProductInformation } from "./dataHandling/definitions/SubProductInformation";
import { PassInformation } from "./dataHandling/definitions/PassInformation";
import { DatabaseProductTypes } from "../data/definitions/DatabaseProductTypes";
import { DatabaseCategoryTypes } from "../data/definitions/DatabaseCategoryTypes";
import { AdvantageInformation } from "./dataHandling/definitions/AdvantageInformation";
import { getDatabaseProduct } from "./dataHandling/getDatabaseProduct";
import { filterDatabaseProduct } from "./dataHandling/filterDatabaseProduct";
import { getProductInformation } from "./dataHandling/getProductInformation";
import { getSubProductInformation } from "./dataHandling/getSubProductInformation";
import { getPassInformation } from "./dataHandling/getPassInformation";
import { getDatabaseAdvantage } from "./dataHandling/getDatabaseAdvantage";
import { getStandardSubProductsTableConfig } from "./dataHandling/getStandardSubProductsTableConfig";
import { combineConfigToRecommendation } from "./dataHandling/combineConfigToRecommendation";
import { getRecommendationDevelopmentConfig } from "./dataHandling/getRecommendationDevelopmentConfig";
import { ProductSelectionConfig } from "./dataHandling/definitions/ProductSelectionConfig";
import { getGigaAdvantages } from "./dataHandling/getGigaAdvantages";
import { RecommendationRequest } from "../data/definitions/RecommendationRequest";
import {
  ProductCountArray,
  RecommendationModeArray,
  WithsObject,
} from "../types/application";
import {
  DISABLE_CYBER_ADVANTAGE,
  ENABLE_RECOMMENDATION_PASSES,
} from "../constants/default";

export default (
  oldProducts: DatabaseProduct[],
  withsObject: WithsObject,
  transferProducts: TransferDatabaseProduct[],
  advantages: TransferDatabaseAdvantage[],
  productLineModes: RecommendationModeArray,
  requestData: RecommendationRequest
): [RecommendationConfig, DatabaseProduct[], boolean] => {
  // TODO: Unify process for each product line

  // must map from products
  const mobileProductInformation: ProductInformation[] = [];
  const mobileSubProductInformation: SubProductInformation[] = [];
  const iotProductInformation: ProductInformation[] = [];
  const iotSubProductInformation: SubProductInformation[] = [];
  const fixedProductInformation: ProductInformation[] = [];
  const fixedSubProductInformation: SubProductInformation[] = [];
  const tvProductInformation: ProductInformation[] = [];
  const tvSubProductInformation: SubProductInformation[] = [];
  const productSelectionConfig: ProductSelectionConfig =
    new ProductSelectionConfig();
  const mobileSubproductsSelected: ProductCountArray = {};
  const iotSubproductsSelected: ProductCountArray = {};
  const fixedSubproductsSelected: ProductCountArray = {};
  const tvSubproductsSelected: ProductCountArray = {};

  //must map from products, but is a sub-category
  const mobilePassInformation: PassInformation[] = [];

  const newTransferProducts = Object.assign([], transferProducts);
  newTransferProducts.unshift(
    new TransferDatabaseProduct(DatabaseProductTypes.mobile)
  );
  newTransferProducts.unshift(
    new TransferDatabaseProduct(DatabaseProductTypes.iot)
  );
  newTransferProducts.unshift(
    new TransferDatabaseProduct(DatabaseProductTypes.fixed)
  );
  newTransferProducts.unshift(
    new TransferDatabaseProduct(DatabaseProductTypes.tv)
  );

  const newProducts: Array<DatabaseProduct | undefined> =
    newTransferProducts.map((v): DatabaseProduct | undefined => {
      const product = filterDatabaseProduct(getDatabaseProduct(v));
      if (product !== undefined) {
        const productInformation = getProductInformation(
          product,
          productLineModes
        );
        const subProductInformation = getSubProductInformation(product);
        switch (product.productType) {
          case DatabaseProductTypes.mobile:
            switch (product.productCategory) {
              case DatabaseCategoryTypes.tariff:
                mobileProductInformation.push(productInformation);
                if (
                  product.productOffered &&
                  productSelectionConfig.mobileId === -1
                ) {
                  productSelectionConfig.mobileId =
                    mobileProductInformation.length - 1;
                }
                break;
              case DatabaseCategoryTypes.pass:
                if (ENABLE_RECOMMENDATION_PASSES) {
                  const passInformation = getPassInformation(product);
                  mobilePassInformation.push(passInformation);
                }
                break;
              case DatabaseCategoryTypes.option:
              case DatabaseCategoryTypes.datatariff:
              case DatabaseCategoryTypes.plustariff:
                mobileSubProductInformation.push(subProductInformation);
                if (product.productOffered) {
                  let productCount = 1;
                  const search = subProductInformation.label.toLowerCase();
                  if (search.includes("red+ data"))
                    productCount = requestData.cardData;
                  if (search.includes("familycard"))
                    productCount = requestData.cardTariff;
                  if (search.includes("datago"))
                    productCount = requestData.cardDataGo;
                  mobileSubproductsSelected[
                    mobileSubProductInformation.length - 1
                  ] = productCount;
                }
                break;
              case DatabaseCategoryTypes.unknown:
                console.error(
                  "Unknown " + product.productType + "-subproduct detected!"
                );
                console.error(product);
                break;
            }
            break;
          case DatabaseProductTypes.iot:
            switch (product.productCategory) {
              case DatabaseCategoryTypes.tariff:
                iotProductInformation.push(productInformation);
                if (
                  product.productOffered &&
                  productSelectionConfig.iotId === -1
                ) {
                  productSelectionConfig.iotId =
                    iotProductInformation.length - 1;
                }
                break;
              case DatabaseCategoryTypes.option:
              case DatabaseCategoryTypes.datatariff:
              case DatabaseCategoryTypes.plustariff:
                iotSubProductInformation.push(subProductInformation);
                if (product.productOffered) {
                  iotSubproductsSelected[
                    iotSubProductInformation.length - 1
                  ] = 1;
                }
                break;
              case DatabaseCategoryTypes.unknown:
                console.error(
                  "Unknown " + product.productType + "-subproduct detected!"
                );
                console.error(product);
                break;
            }
            break;
          case DatabaseProductTypes.fixed:
            switch (product.productCategory) {
              case DatabaseCategoryTypes.tariff:
                fixedProductInformation.push(productInformation);
                if (
                  product.productOffered &&
                  productSelectionConfig.fixedId === -1
                ) {
                  productSelectionConfig.fixedId =
                    fixedProductInformation.length - 1;
                }
                break;
              case DatabaseCategoryTypes.option:
              case DatabaseCategoryTypes.datatariff:
              case DatabaseCategoryTypes.plustariff:
                fixedSubProductInformation.push(subProductInformation);
                if (product.productOffered) {
                  fixedSubproductsSelected[
                    fixedSubProductInformation.length - 1
                  ] = 1;
                }
                break;
              case DatabaseCategoryTypes.unknown:
                console.error(
                  "Unknown " + product.productType + "-subproduct detected!"
                );
                console.error(product);
                break;
            }
            break;
          case DatabaseProductTypes.tv:
            switch (product.productCategory) {
              case DatabaseCategoryTypes.tariff:
                tvProductInformation.push(productInformation);
                if (
                  product.productOffered &&
                  productSelectionConfig.tvId === -1
                ) {
                  productSelectionConfig.tvId = tvProductInformation.length - 1;
                }
                break;
              case DatabaseCategoryTypes.option:
              case DatabaseCategoryTypes.datatariff:
              case DatabaseCategoryTypes.plustariff:
                tvSubProductInformation.push(subProductInformation);
                if (product.productOffered) {
                  tvSubproductsSelected[tvSubProductInformation.length - 1] = 1;
                }
                break;
              case DatabaseCategoryTypes.unknown:
                console.error(
                  "Unknown " + product.productType + "-subproduct detected!"
                );
                console.error(product);
                break;
            }
            break;
          case DatabaseProductTypes.unknown:
            console.error("Unknown product detected!");
            console.error(product);
            break;
        }
        return product;
      }
      return undefined;
    });

  // filter undefined products and use only the new from changed productlines
  const {
    mobile: withMobile,
    iot: withIot,
    fixed: withFixed,
    tv: withTv,
  } = withsObject;

  const productFilterFunction =
    (withProductLineChanged: boolean) =>
    (v: DatabaseProduct | undefined): boolean => {
      if (v !== undefined) {
        const associatedProductLineHasChanged =
          (v.productType === DatabaseProductTypes.mobile && withMobile) ||
          (v.productType === DatabaseProductTypes.iot && withIot) ||
          (v.productType === DatabaseProductTypes.fixed && withFixed) ||
          (v.productType === DatabaseProductTypes.tv && withTv);
        if (withProductLineChanged) {
          return associatedProductLineHasChanged;
        } else {
          return !associatedProductLineHasChanged;
        }
      }

      return false;
    };

  const updatedProducts: DatabaseProduct[] = [
    ...oldProducts.filter(productFilterFunction(false)),
    ...(newProducts.filter(productFilterFunction(true)) as DatabaseProduct[]),
  ];

  // to map from advantages
  const [gigaAdvantages, gigaAdvantagesResult] = getGigaAdvantages();
  const filteredGigaAdvantages = gigaAdvantages.filter((advantage) => {
    return !(advantage.id === 1999 && DISABLE_CYBER_ADVANTAGE);
  });

  const advantageInformation: AdvantageInformation[] = advantages
    .map((v) => getDatabaseAdvantage(v))
    .concat(filteredGigaAdvantages);
  // to be configured once
  const [mobileSubProductTableConfig, mobileSubProductsResult] =
    getStandardSubProductsTableConfig(mobileSubproductsSelected);
  mobileSubProductTableConfig.tables[0] = "Zusätzliche Pässe";

  const [iotSubProductTableConfig, iotSubProductsResult] =
    getStandardSubProductsTableConfig(iotSubproductsSelected);
  const [fixedSubProductTableConfig, fixedSubProductsResult] =
    getStandardSubProductsTableConfig(fixedSubproductsSelected);
  const [tvSubProductTableConfig, tvSubProductsResult] =
    getStandardSubProductsTableConfig(tvSubproductsSelected);

  // use config values for some settings
  const [devConfig, devConfigResult] = getRecommendationDevelopmentConfig();

  const result =
    gigaAdvantagesResult &&
    mobileSubProductsResult &&
    iotSubProductsResult &&
    fixedSubProductsResult &&
    tvSubProductsResult &&
    devConfigResult;

  productSelectionConfig.mobileId =
    productSelectionConfig.mobileId === -1
      ? 0
      : productSelectionConfig.mobileId;
  productSelectionConfig.iotId =
    productSelectionConfig.iotId === -1 ? 0 : productSelectionConfig.iotId;
  productSelectionConfig.fixedId =
    productSelectionConfig.fixedId === -1 ? 0 : productSelectionConfig.fixedId;
  productSelectionConfig.tvId =
    productSelectionConfig.tvId === -1 ? 0 : productSelectionConfig.tvId;

  const recommendationConfig = combineConfigToRecommendation({
    mobile: {
      selectedId: productSelectionConfig.mobileId,
      passInformation: mobilePassInformation,
      productInformation: mobileProductInformation,
      subProductInformation: mobileSubProductInformation,
      subProductTableConfig: mobileSubProductTableConfig,
      dataVolumeMaximum: devConfig.dataVolumeMaximum,
    },
    iot: {
      selectedId: productSelectionConfig.iotId,
      passInformation: [],
      productInformation: iotProductInformation,
      subProductInformation: iotSubProductInformation,
      subProductTableConfig: iotSubProductTableConfig,
      dataVolumeMaximum: devConfig.dataVolumeMaximum,
    },
    fixed: {
      selectedId: productSelectionConfig.fixedId,
      passInformation: [],
      productInformation: fixedProductInformation,
      subProductInformation: fixedSubProductInformation,
      subProductTableConfig: fixedSubProductTableConfig,
      dataVolumeMaximum: devConfig.dataVolumeMaximum,
    },
    tv: {
      selectedId: productSelectionConfig.tvId,
      passInformation: [],
      productInformation: tvProductInformation,
      subProductInformation: tvSubProductInformation,
      subProductTableConfig: tvSubProductTableConfig,
      dataVolumeMaximum: devConfig.dataVolumeMaximum,
    },
    advantageInformation: advantageInformation,
    devConfig: devConfig,
  });

  return [recommendationConfig, updatedProducts, result];
};
