import * as React from "react";

function SvgEuroColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="euro_color_svg__ICON"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".euro_color_svg__st1{fill:none;stroke:#e60000;stroke-width:7.8396;stroke-linecap:round;stroke-miterlimit:10}"
        }
      </style>
      <path
        d="M144.3 37.4c32.4 26.7 37 74.6 10.3 107s-74.6 37-107 10.3-37-74.6-10.3-107C51.8 30.1 73.3 20 96 20c17.6 0 34.7 6.1 48.3 17.4"
        fill="none"
        stroke="#e60000"
        strokeWidth={8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="euro_color_svg__st1"
        d="M116 121c-3.4 1.6-7.5 2.9-11.4 2.9-14.7 0-23.1-12.5-23.1-27.9s8.4-28 23.1-28c4.3 0 8.3 1 11.9 2.9M71 104h29M100 88H67.5"
      />
    </svg>
  );
}

export default SvgEuroColor;
