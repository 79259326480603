import React from "react";
import styled from "styled-components";
import image from "../../images/application/19_1.png";
import TileButton from "../form/TileButton";
import { Color } from "../../constants/color";
import GigaKombiPage from "./default/GigaKombiPage";
import { useHistory } from "react-router-dom";
import getMissingGigaKombiData from "../../helper/getMissingGigaKombiData";
import { DatabaseProduct } from "../../data/definitions/DatabaseProduct";
import { useInterests } from "../../hooks/useFormHelper/useInterests";
import { DatabaseProductTypes } from "../../data/definitions/DatabaseProductTypes";
import { SlidePages } from "../../constants/SlidePages";
import { useNavigation } from "../../hooks/useNavigation";
import {
  Interest,
  RecommendationMode,
  RecommendationType,
} from "../../types/application";
import { useGigaKombiWiths } from "../../hooks/useOfferHelper/useGigaKombiWiths";

// this are example product names, it is necessary to use these, because the sky option
const exampleProducts: DatabaseProduct[] = [
  new DatabaseProduct("red l", true, true, DatabaseProductTypes.mobile),
  new DatabaseProduct("Unknown Cable", true, true, DatabaseProductTypes.fixed),
  new DatabaseProduct("Giga TV Cable", true, true, DatabaseProductTypes.tv),
];

const KombiPage = () => {
  const { mobileMode, fixedMode, tvMode, iotMode } = useInterests();
  const { getGigaKombiWiths } = useGigaKombiWiths();
  const withsObject = getGigaKombiWiths();
  const gigaKombiData = getMissingGigaKombiData(withsObject, exampleProducts);

  let history = useHistory();
  const { toggleInterest } = useNavigation();
  const onButtonClick = (typeToToggle: RecommendationType) => () => {
    switch (typeToToggle) {
      case RecommendationType.MOBILE:
        toggleInterest(Interest.MOBILE);
        history.push("/slides/" + SlidePages.initialInterest.id);
        break;
      case RecommendationType.FIXED:
        toggleInterest(Interest.FIXED);
        history.push("/slides/" + SlidePages.initialInterest.id);
        break;
      case RecommendationType.TV:
        toggleInterest(Interest.TV);
        history.push("/slides/" + SlidePages.initialInterest.id);
        break;
      case RecommendationType.IOT:
        toggleInterest(Interest.IOT);
        history.push("/slides/" + SlidePages.initialInterest.id);
        break;
    }
  };

  return (
    <GigaKombiPage gigaKombiData={gigaKombiData} image={image} rebatePrice={15}>
      {mobileMode !== RecommendationMode.SELECTABLE && (
        <TileButton
          key={1}
          onChange={onButtonClick(RecommendationType.MOBILE)}
          value={true}
          color={Color.GREY3}
        >
          Interesse an {RecommendationType.MOBILE}
        </TileButton>
      )}
      {fixedMode !== RecommendationMode.SELECTABLE && (
        <TileButton
          key={2}
          onChange={onButtonClick(RecommendationType.FIXED)}
          value={true}
          color={Color.GREY3}
        >
          Interesse an {RecommendationType.FIXED}
        </TileButton>
      )}
      {tvMode !== RecommendationMode.SELECTABLE && (
        <TileButton
          key={3}
          onChange={onButtonClick(RecommendationType.TV)}
          value={true}
          color={Color.GREY3}
        >
          Interesse an {RecommendationType.TV}
        </TileButton>
      )}
      {iotMode !== RecommendationMode.SELECTABLE && (
        <TileButton
          key={4}
          onChange={onButtonClick(RecommendationType.IOT)}
          value={true}
          color={Color.GREY3}
        >
          Interesse an {RecommendationType.IOT}
        </TileButton>
      )}
      <TileButton
        key={0}
        onChange={() => history.push("/slides/" + SlidePages.recommendation.id)}
        value={true}
        color={Color.GREY_LIGHT}
      >
        Direkt zum Angebot
      </TileButton>
    </GigaKombiPage>
  );
};

export default styled(KombiPage)``;
