import React from "react";
import styled from "styled-components";
import { SVGComponent, SVGComponentProps } from "../../types/default";

interface SVGIconGroup extends SVGComponentProps {
  className?: string;
  icons: SVGComponent[];
  size?: number;
}

const SVGIconGroup: React.FC<SVGIconGroup> = ({
  className,
  icons,
  size,
  ...svgProps
}) => {
  return (
    <div className={className + " icon"}>
      {icons.map((SrcComponent, i) => {
        return <SrcComponent key={i} {...svgProps} />;
      })}
    </div>
  );
};

export default styled(SVGIconGroup)`
  display: inline-block;
  width: ${({ size }) => (size ? size + "px" : "66px")} !important;
  height: ${({ size }) => (size ? size + "px" : "66px")} !important;
  padding: 2px;
  img,
  svg {
    display: inline-block;
    margin: 2px;

    width: ${({ size, icons }) =>
      size && icons
        ? (size / icons.length) * 2 - 10 + "px"
        : "66px"} !important;
    height: ${({ size, icons }) =>
      size && icons
        ? (size / icons.length) * 2 - 10 + "px"
        : "66px"} !important;
  }
`;
