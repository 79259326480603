import * as React from "react";

function SvgSmartwatch(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="smartwatch_svg__ac8745a2-a6e7-4e06-9818-b8101ae2f1e3"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.smartwatch_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className="smartwatch_svg__st0"
        d="M313.8 380.8c-9.5 6.3-20.6 9.6-31.9 9.6H90.5C58.7 390.4 33 364.7 33 333V167c0-31.7 25.7-57.4 57.4-57.4h191.4c31.7 0 57.4 25.7 57.4 57.4v70.2M98 109.6l10.7-85.3c1.2-9.6 9.4-16.9 19.1-16.8h117.1c9.7-.1 17.9 7.1 19.1 16.8l10.4 85.3"
      />
      <path
        className="smartwatch_svg__st0"
        d="M98 390.4l10.7 85.3c1.2 9.6 9.4 16.9 19.1 16.8h117.1c9.7.1 17.9-7.1 19.1-16.8l10.4-85.3M313.8 473.4v-217c0-10.6 8.6-19.1 19.1-19.1h83l51.1 51.1v185.1c0 10.6-8.6 19.1-19.1 19.1H333c-10.6-.1-19.2-8.7-19.2-19.2z"
      />
      <path
        className="smartwatch_svg__st0"
        d="M377.6 339.3h25.5c7 0 12.8 5.7 12.8 12.8v76.6c0 7-5.7 12.8-12.8 12.8h-25.5c-7 0-12.8-5.7-12.8-12.8v-76.6c.1-7 5.8-12.8 12.8-12.8z"
      />
    </svg>
  );
}

export default SvgSmartwatch;
