import React from "react";
import useBootstrap from "../../hooks/useBootstrap";
import { useFormState } from "../../hooks/useForm";
import ImageTileRadioButtonList from "../form/ImageTileRadioButtonList";
import styled from "styled-components";
import { StyledComponentProps } from "../../types/default";

const SohoPage: React.FC<StyledComponentProps> = ({ className }) => {
  const { formConfig } = useBootstrap();
  const [independentPersonalDetails, setIndependentPersonalDetails] =
    useFormState("independentPersonalDetails");

  return (
    <div className={className}>
      <p className={"description"}>
        Gehst Du einer selbstständigen/geschäftlichen Tätigkeit nach oder nutzt
        Du Deinen Vertrag ausschließlich privat?
      </p>
      <ImageTileRadioButtonList
        data={formConfig.independentPersonalDetails}
        values={independentPersonalDetails}
        onChange={setIndependentPersonalDetails}
      />
    </div>
  );
};

export default styled(SohoPage)`
  .description {
    font-size: 24px;
    margin: 30px 50px;
  }
`;
