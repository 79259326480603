import { useRefEffect } from "../common/useRefHook";
import useAvailability from "../useAvailability";
import { useMemo, useState } from "react";
import { useCurrentProducts } from "../useDatabaseProducts";
import { DatabaseProductTypes } from "../../data/definitions/DatabaseProductTypes";
import { calculateGigaKombiValues } from "../../helper/gigakombi/calculateGigaKombiValues";
import { getCircleDataFromGigaValues } from "../../helper/gigakombi/getCircleDataFromGigaValues";

const trueWithsObject = { mobile: true, iot: true, fixed: true, tv: true };
const falseWithsObject = { mobile: false, iot: false, fixed: false, tv: false };
const { circleData: defaultMarketingCircleData } = getCircleDataFromGigaValues(
  trueWithsObject,
  [15, 99, true]
);

export const useMarketingCircleData = () => {
  const { getCurrentIds, getSelectedProducts } = useCurrentProducts();
  const currentIds = getCurrentIds();
  const currentProducts = useMemo(
    () => getSelectedProducts(currentIds),
    [currentIds]
  );

  const { availability } = useAvailability();
  const [marketingCircleData, setMarketingCircleData] = useState(
    defaultMarketingCircleData
  );
  const marketingWithsObject = Object.assign({}, falseWithsObject);
  currentProducts.forEach((product) => {
    const newValue = product.combiRebate || product.combiPush;
    if (product.productType !== DatabaseProductTypes.unknown) {
      marketingWithsObject[product.productType] = newValue;
    }
  });

  useRefEffect(() => {
    if (!availability.cable.available) {
      const { circleData: newMarketingCircleDataState } =
        getCircleDataFromGigaValues(
          marketingWithsObject,
          calculateGigaKombiValues(marketingWithsObject, currentProducts),
          currentProducts
        );
      if (newMarketingCircleDataState.length > 0) {
        setMarketingCircleData(newMarketingCircleDataState);
      }
    }
  }, [
    availability.cable.available,
    // TODO: Check ob es funktioneirt
    currentIds[0],
    currentIds[1],
    currentIds[2],
    currentIds[3],
  ]);
  return marketingCircleData;
};
