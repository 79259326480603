import { RecommendationState } from "../definitions/RecommendationState";
import { DisplayErrorMessages } from "../../constants/DisplayErrorMessages";
import { RecommendationMode } from "../../types/application";
import { defaultRecommendationProductLineTable } from "./recommendation/defaultRecommendationProductLineTable";
import { defaultRecommendationProductLineConfig } from "./recommendation/defaultRecommendationProductLineConfig";
import { defaultRecommendationDefaultTableConfig } from "./recommendation/defaultRecommendationDefaultTableConfig";
import { ErrorCodes } from "../../constants/ErrorCodes";

export const defaultRecommendationState: RecommendationState = {
  loading: false,
  isLoadedOnce: false,
  errorMessage: DisplayErrorMessages.nothing,
  errorCode: ErrorCodes.nothing,
  modes: {
    mobileMode: RecommendationMode.INTERESTING,
    fixedMode: RecommendationMode.INTERESTING,
    tvMode: RecommendationMode.INTERESTING,
    iotMode: RecommendationMode.INTERESTING,
  },
  config: {
    standardDiscountLabel: "",
    standardDiscountCounts: [],

    advantages: [],

    defaultTable: Object.assign({}, defaultRecommendationDefaultTableConfig),

    mobile: Object.assign({}, defaultRecommendationProductLineConfig),
    fixed: Object.assign({}, defaultRecommendationProductLineConfig),
    tv: Object.assign({}, defaultRecommendationProductLineConfig),
    iot: Object.assign({}, defaultRecommendationProductLineConfig),

    mobileTable: Object.assign({}, defaultRecommendationProductLineTable),
    fixedTable: Object.assign({}, defaultRecommendationProductLineTable),
    tvTable: Object.assign({}, defaultRecommendationProductLineTable),
    iotTable: Object.assign({}, defaultRecommendationProductLineTable),
  },
  priceInformation: {},
};
