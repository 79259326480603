import * as React from "react";
import { AdvantageButton } from "../../../advantageModal/AdvantageButton";
import PanelGroup from "../../../../panel/PanelGroup";
import { Color } from "../../../../../constants/color";
import PanelPrint from "../../../../panel/PanelPrint";
import PanelItem from "../../../../panel/PanelItem";
import SubProductPanel from "../SubProductPanel";
import {
  getEmptyArrayIfNotExist,
  getEmptyStringIfNotExist,
  getZeroIfNotExist,
} from "../../../../../helper/ifNotExist";
import { PriceUnits, SubProducts } from "../../../../../types/table";
import {
  BubbleEntry,
  Interest,
  LabelValuePair,
  MonthlyPriceArray,
} from "../../../../../types/application";
import MobilePassPanel from "../MobilePassPanel";
import PriceSummaryPrint from "../../PriceSummaryPrint";
import { StyledComponentProps } from "../../../../../types/default";
import { PriceTextTile } from "../../PriceTextTile";
import { ENABLE_RECOMMENDATION_PASSES } from "../../../../../constants/default";
import PriceDisplayList from "../../PriceDisplayList";

export interface SelectableRecommendationPrintColumnProps
  extends StyledComponentProps {
  type: Interest;
  subProducts: SubProducts;
  bubbleEntries: BubbleEntry[];
  columnPriceObjects: LabelValuePair[];
  priceArray: MonthlyPriceArray;
  discountArray?: MonthlyPriceArray;
}

const SelectableRecommendationPrintColumn: React.FC<SelectableRecommendationPrintColumnProps> =
  ({
    type,
    subProducts,
    bubbleEntries,
    columnPriceObjects,
    priceArray,
    discountArray,
  }) => {
    const pricePasses = getZeroIfNotExist(
      subProducts?.priceInformation.list[0]
    );
    const priceFurther = getZeroIfNotExist(
      subProducts?.priceInformation.list[2]
    );
    const priceSingle = getZeroIfNotExist(
      subProducts?.priceInformation.list[3]
    );
    return (
      <>
        <PriceTextTile>
          <PriceDisplayList data={priceArray} discountData={discountArray} />
        </PriceTextTile>
        {bubbleEntries && bubbleEntries.length > 0 && (
          <>
            <AdvantageButton>Deine Vorteile</AdvantageButton>
            <PanelGroup borderColor={Color.GREY2} margin={"0 0 2px 0"}>
              <PanelPrint>
                {bubbleEntries.map((d, i) => {
                  return (
                    <PanelItem
                      key={i}
                      icon={d.icon}
                      withoutMarginBottom
                      height={12}
                      color={Color.RED}
                      color2={Color.GREY}
                    >
                      {d.headline.toLowerCase() === "gigakombi"
                        ? d.headline + ": " + d.content
                        : d.headline}
                    </PanelItem>
                  );
                })}
              </PanelPrint>
            </PanelGroup>
          </>
        )}

        <SubProductPanel
          mainLabel={getEmptyStringIfNotExist(
            subProducts?.additionalTable.title
          )}
          data={getEmptyArrayIfNotExist(subProducts?.additionalTable.data)}
          standardData={subProducts?.additionalTable.standardObject}
        />
        <SubProductPanel
          mainLabel={getEmptyStringIfNotExist(
            subProducts?.furtherProductsTable.title
          )}
          mainValue={priceFurther}
          mainPriceUnit={PriceUnits.months}
          data={getEmptyArrayIfNotExist(subProducts?.furtherProductsTable.data)}
          standardData={subProducts?.furtherProductsTable.standardObject}
          dataFilter={(d) => d[3] !== 0}
        />
        <SubProductPanel
          mainLabel={getEmptyStringIfNotExist(
            subProducts?.singlePaymentTable.title
          )}
          mainValue={priceSingle}
          data={getEmptyArrayIfNotExist(subProducts?.singlePaymentTable.data)}
          standardData={subProducts?.singlePaymentTable.standardObject}
          dataFilter={(d) => !!d[3]}
        />
        {ENABLE_RECOMMENDATION_PASSES && type === Interest.MOBILE && (
          <MobilePassPanel
            mainLabel={getEmptyStringIfNotExist(subProducts?.passes.title)}
            mainValue={pricePasses}
            data={getEmptyArrayIfNotExist(subProducts?.passes.data)}
          />
        )}
        {columnPriceObjects && columnPriceObjects[0] && (
          <PriceSummaryPrint
            mainLabel={columnPriceObjects[0].label}
            mainValue={columnPriceObjects[0].value}
            items={columnPriceObjects.slice(1)}
            accentColor={Color.BLACK}
          />
        )}
      </>
    );
  };

export default SelectableRecommendationPrintColumn;
