import React, { CSSProperties } from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import { PriceDisplayInformation } from "../../../types/application";

interface PrintCurrentPriceDisplayProps {
  className?: string;
  style?: CSSProperties;
  data: PriceDisplayInformation;
}

const PrintCurrentPriceDisplay: React.FC<PrintCurrentPriceDisplayProps> = ({
  className,
  style,
  data,
}) => {
  const { headline, mainPrice, currency = "€" } = data;
  const displayPrice = mainPrice?.toFixed(2);
  return (
    <div className={className + " price-display"} style={style}>
      <div
        className={
          "price-description-container price-description-container-placeholder "
        }
      >
        <span className={"price-headline"}>{headline}</span>
        <span className={"price-currency"}>&nbsp;{currency}</span>
      </div>
      <div className={"price-value-container"}>{displayPrice}</div>
      <div className={"price-description-container"}>
        <span className={"price-headline"}>{headline}</span>
        <span className={"price-currency"}>&nbsp;{currency}</span>
      </div>
    </div>
  );
};

export default styled(PrintCurrentPriceDisplay)`
  text-align: center;
  padding: 10px;

  div {
    display: inline-block;
    vertical-align: top;
  }
  span {
    display: inline-block;
  }

  .price-value-container {
    width: 40%;
    height: 100%;
    line-height: 32px;
    background: white;
    color: ${Color.RED};
    padding: 12px;
    box-shadow: inset 4px 5px 20px -5px ${Color.BLACK};
    font-size: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .price-description-container {
    width: calc(22% - 4px);
    padding-left: 4px;
    .price-headline {
      text-align: left;
      width: 100%;
      font-size: 12px;
    }
    .price-currency {
      text-align: left;
      font-weight: bold;
      width: calc(100% - 6px);
      font-size: 36px;
      padding-left: 6px;
    }
  }

  .price-description-container-placeholder {
    padding-left: 0;
    padding-right: 4px;
    visibility: hidden;
  }
`;
