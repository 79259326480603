import React from "react";
import styled from "styled-components";
import { StyledComponentProps } from "../../types/default";

const ErrorMessage: React.FC<StyledComponentProps> = ({
  children,
  className,
}) => {
  return <div className={className}>{children}</div>;
};

export default styled(ErrorMessage)`
  background-color: pink;
  padding: 15px;
  border: 1px dashed red;
  font-size: 20px;
  text-align: left;
`;
