import React from "react";
import styled from "styled-components";
import { Color } from "../../../../constants/color";
import ColorIcons from "../../../../icons/ColorIcons";
import SVGIcon from "../../../svgIcon/SVGIcon";

interface GigaUnlimitedValueProps {
  className?: string;
  size?: number;
}

const GigaUnlimitedValue: React.FC<GigaUnlimitedValueProps> = ({
  className,
  size = 50,
}) => {
  return (
    <span className={className + " giga-value giga-unlimited-value"}>
      <SVGIcon
        size={size}
        src={ColorIcons.unlimited}
        className={"giga-unlimited-value-icon"}
      />
    </span>
  );
};

export default styled(GigaUnlimitedValue)`
  position: absolute;
  width: 100%;

  top: 10px;
  left: 0;

  font-size: 22px;
  text-align: center;
  color: ${Color.RED};
  .giga-unlimited-value-icon {
    padding: 2px 2px 6px 2px;
    vertical-align: middle;
  }
`;
