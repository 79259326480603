import * as React from "react";

function SvgChildLocation(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 192 192"} {...props}>
      <g fill="none">
        <path
          d="M95.28 44.5C122 44.5 128 65 128 83.09a32.74 32.74 0 01-65.49 0C62.54 65 68.39 44.5 95.28 44.5zM106.16 96.75a14.72 14.72 0 01-21.61.15M66.63 67.7l72.41-8.4"
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={8}
        />
        <circle
          cx={106.82}
          cy={81.45}
          r={2.59}
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={8}
        />
        <circle
          cx={84.49}
          cy={81.45}
          r={2.59}
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={8}
        />
        <path
          d="M90.61 45.24s9.55 5.9 11.94 17.26"
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={8}
        />
        <path d="M0 0h192v192H0z" />
        <path
          d="M156 80c0 40-60 92-60 92s-60-52-60-92a60 60 0 01120 0z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={8}
        />
      </g>
    </svg>
  );
}

export default SvgChildLocation;
