import React from "react";
import styled from "styled-components";
import { Color } from "../../../../constants/color";

interface GigaCircleProps {
  className?: string;
  withStar?: boolean;
  invertColors?: boolean;
}

const GigaCircle: React.FC<GigaCircleProps> = ({
  className,
  children,
  withStar = false,
}) => {
  return (
    <>
      <div className={className + " giga-circle-container"}>
        <span className={"giga-circle"} />
        {children}
        {withStar && <span className={"giga-circle-star"}>*</span>}
      </div>
    </>
  );
};

export default styled(GigaCircle)`
  display: inline-block;
  position: relative;
  height: 90px;
  width: 90px;

  padding: 5px 0;
  vertical-align: top;
  box-sizing: content-box;

  .giga-circle {
    display: inline-block;
    height: 90px;
    width: 90px;

    background: ${({ invertColors }) =>
      !!invertColors ? Color.RED : Color.WHITE};
    color: ${({ invertColors }) => (!!invertColors ? Color.WHITE : Color.RED)};
    border: 0 solid;
    border-radius: 50%;
  }

  .giga-circle-star {
    position: absolute;
    right: 3px;
    top: 3px;
  }
`;
