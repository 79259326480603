import * as React from "react";

function SvgPro7(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="pro7_svg__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>{`.pro7_svg__st0{fill: ${color} }`}</style>
      <path
        className="pro7_svg__st0"
        d="M7.1 2.44h485v136.37H7.1zM492.1 164.94V497.5H7.1s59.8-161.5 215.93-257c148.18-90.69 269.07-75.56 269.07-75.56"
      />
    </svg>
  );
}

export default SvgPro7;
