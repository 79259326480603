import React from "react";
import styled from "styled-components";
import CheckboxList from "./CheckboxList";
import { getIcons } from "../../icons/Icons";
import IconTileButton from "./IconTileButton";
import { FormOption } from "../../state/definitions/FormConfigState";

interface IconTileBoolButtonGridProps {
  className?: string;
  maxWidth?: number;
  data: FormOption[];
  values: Array<boolean | undefined>;
  onChanges: Array<(values: boolean) => void>;
}

const IconTileBoolButtonGrid: React.FC<IconTileBoolButtonGridProps> = ({
  className,
  data = [],
  values,
  onChanges,
}) => {
  const ids = data.map((v) => v.value);
  const filteredIds = ids.filter((v, i) => !!values[i]);
  const onChangeHandler = (values: number[]) => {
    ids.forEach((id, i) => {
      const foundedValue = values.find((value) => id === value);
      onChanges[i](!!foundedValue);
    });
  };
  return (
    <div className={className}>
      <CheckboxList
        data={data}
        values={filteredIds}
        onChange={onChangeHandler}
        renderFkt={({ value, label }, isSelected, toggleValue, i) => {
          return (
            <IconTileButton
              onChange={() => toggleValue(value)}
              value={isSelected}
              icons={getIcons(value)}
              shadowed
              key={i}
            >
              {label}
            </IconTileButton>
          );
        }}
      />
    </div>
  );
};

export default styled(IconTileBoolButtonGrid)`
  display: block;
  text-align: center;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth + "px" : "unset")};
  margin: 20px auto;
`;
