import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import { Link } from "react-router-dom";

interface PaginationLinkProps {
  className?: string;
  to: string;
  disabled?: boolean;
  direction: "prev" | "next";
}

const PaginationLink: React.FC<PaginationLinkProps> = ({
  direction,
  to,
  disabled = false,
  className,
}) => {
  if (disabled) {
    return (
      <div className={className}>
        {direction === "prev" && <div className={"left-triangle"} />}
        {direction === "next" && <div className={"right-triangle"} />}
      </div>
    );
  }

  return (
    <Link to={to}>
      <div className={className}>
        {direction === "prev" && <div className={"left-triangle"} />}
        {direction === "next" && <div className={"right-triangle"} />}
      </div>
    </Link>
  );
};

export default styled(PaginationLink)`
  height: 40px;
  width: 40px;
  background-color: ${(props) => (props.disabled ? Color.GREY2 : Color.RED)};
  color: ${Color.WHITE};
  border: 0;
  padding: 10px 0 0 0;
  display: inline-block;

  :hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }

  .right-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 20px;
    border-color: transparent transparent transparent ${Color.WHITE};
    margin-left: 10px;
  }

  .left-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 20px 10px 0;
    border-color: transparent ${Color.WHITE} transparent transparent;
    margin-left: 10px;
  }
`;
