import { AvailabilityState } from "../definitions/AvailabilityState";
import { DisplayErrorMessages } from "../../constants/DisplayErrorMessages";
import { ErrorCodes } from "../../constants/ErrorCodes";

export const defaultAvailabilityState: AvailabilityState = {
  loading: false,
  errorMessage: DisplayErrorMessages.nothing,
  errorCode: ErrorCodes.nothing,
  addresses: [],
  selectedAddress: null,
  someIsAvailable: false,
  availability: {
    cable: {
      available: false,
      upstream: 0,
      downstream: 0,
      regioTax: false,
    },
    dsl: {
      available: false,
      upstream: 0,
      downstream: 0,
      regioTax: false,
    },
    fiber: {
      available: false,
      upstream: 0,
      downstream: 0,
      regioTax: false,
    },
    mobile: {
      available: false,
      upstream: 0,
      downstream: 0,
      regioTax: false,
    },
    tv: {
      available: false,
      upstream: 0,
      downstream: 0,
      regioTax: false,
    },
  },
};
