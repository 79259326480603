import {
  AdditionalTableDataObject,
  CountUnits,
  FurtherTableDataObject,
  PriceUnits,
  TableCellType,
  TableDataObject,
} from "../../types/table";
import { useNewOfferState } from "./useNewOfferState";
import { getRecommendationTable } from "../../helper/recommendation/mapping";
import { useNewOffer } from "../useNewOffer";
import useRecommendation from "../useRecommendation";
import { getNumberFromArray } from "../../helper/getFromArray";
import { getFurtherStandardTableObject } from "../../helper/table/getFurtherStandardTableObject";

export interface AdditionalTableObjectGetterOptions {
  additionalTableCounts: number[][];
}

export interface FurtherTableObjectGetterOptions {
  furtherProductLabels: string[][];
  furtherProductPrices: number[][];
  furtherProductCounts: number[][];
  furtherProductDataVolumes: number[][];
}

export interface SinglePaymentTableObjectGetterOptions {}

export const useOfferTable = (offerEntryId: string) => {
  const { config } = useRecommendation();
  const { getNewOfferType } = useNewOffer();
  const type = getNewOfferType(offerEntryId);
  const tableName = getRecommendationTable(type);

  const [additionalLabels] = useNewOfferState("additionalLabels", offerEntryId);
  const [additionalCountIds] = useNewOfferState(
    "additionalCountIds",
    offerEntryId
  );
  const [additionalPrices] = useNewOfferState("additionalPrices", offerEntryId);
  const [additionalStarts] = useNewOfferState("additionalStarts", offerEntryId);

  const getAdditionalTableObject = (
    index: number,
    options: Partial<AdditionalTableObjectGetterOptions> = {}
  ): AdditionalTableDataObject => {
    let additionalLabel = config.defaultTable.additionalTableLabel;
    if (additionalLabels[index] !== undefined) {
      additionalLabel = additionalLabels[index];
    }

    const aCountId = additionalCountIds[index] ?? 0;
    // TODO: Unify params from defaultTable and recommendationConfig
    const aCounts = [config.defaultTable.additionalTableCount];
    const { additionalTableCounts = config[tableName].additionalTableCounts } =
      options;
    aCounts.push(...(additionalTableCounts[index] ?? []));

    let aPrices = config.defaultTable.additionalTablePrice;
    if (additionalPrices[index] !== undefined) {
      aPrices = additionalPrices[index];
    }

    let aStart = config.defaultTable.additionalTableStart;
    if (additionalStarts[index] !== undefined) {
      aStart = additionalStarts[index];
    }

    return {
      labelId: 0,
      countId: aCountId,
      priceId: 0,
      startId: 0,
      labels: [additionalLabel],
      counts: aCounts,
      countUnit: CountUnits.months,
      prices: [aPrices],
      starts: [aStart],
      priceUnit: PriceUnits.euro,
      labelType: TableCellType.INPUT,
      countType: TableCellType.SELECTION,
      priceType: TableCellType.INPUT,
      startType: TableCellType.INPUT,
    };
  };

  const [furtherLastLabel] = useNewOfferState("furtherLastLabel", offerEntryId);
  const [furtherLastCount] = useNewOfferState("furtherLastCount", offerEntryId);
  const [furtherLastPrice] = useNewOfferState("furtherLastPrice", offerEntryId);

  const getFurtherLastLineTableObject = () => {
    const lastLabel =
      furtherLastLabel !== ""
        ? furtherLastLabel
        : config.defaultTable.furtherTableLastLabel;
    const lastCount =
      furtherLastCount ?? config.defaultTable.furtherTableLastCount;
    const lastPrice =
      furtherLastPrice ?? config.defaultTable.furtherTableLastPrice;
    return {
      labelId: 0,
      countId: 0,
      priceId: 0,
      labels: [lastLabel],
      counts: [lastCount],
      countUnit: CountUnits.months,
      prices: [lastPrice],
      priceUnit: PriceUnits.months,
      labelType: TableCellType.INPUT,
      countType: TableCellType.INPUT,
      priceType: TableCellType.INPUT,
      dataVolumes: [0],
    };
  };

  const [furtherLabelIds] = useNewOfferState("furtherLabelIds", offerEntryId);
  const [furtherCountIds] = useNewOfferState("furtherCountIds", offerEntryId);

  const getFurtherTableObject = (
    index: number,
    options: Partial<FurtherTableObjectGetterOptions> = {}
  ): FurtherTableDataObject => {
    let useStandardObject = false;

    const fLabels = [config.defaultTable.furtherTableLabel];
    const { furtherProductLabels = config[tableName].furtherProductLabels } =
      options;
    fLabels.push(...(furtherProductLabels[index] ?? []));

    const fPrices = [config.defaultTable.furtherTablePrice];
    const { furtherProductPrices = config[tableName].furtherProductPrices } =
      options;
    fPrices.push(...(furtherProductPrices[index] ?? []));

    const fCounts = [config.defaultTable.furtherTableCount];
    const { furtherProductCounts = config[tableName].furtherProductCounts } =
      options;
    fCounts.push(...(furtherProductCounts[index] ?? []));

    const fDataVolumes = [config.defaultTable.furtherTableDataVolume];
    const {
      furtherProductDataVolumes = config[tableName].furtherProductDataVolumes,
    } = options;
    fDataVolumes.push(...(furtherProductDataVolumes[index] ?? []));

    if (fLabels.length !== fPrices.length) {
      useStandardObject = true;
      console.error(
        "Different label and price counts. The entered data are incorrect!"
      );
    }

    const fLabelId = getNumberFromArray(furtherLabelIds, index);
    const fCountId = getNumberFromArray(furtherCountIds, index);

    let furtherTableDataObject: FurtherTableDataObject | undefined =
      getFurtherStandardTableObject();
    if (index === 4) {
      return getFurtherLastLineTableObject();
    } else if (!useStandardObject) {
      furtherTableDataObject = {
        labelId: fLabelId,
        countId: fCountId,
        priceId: undefined,
        labels: [...fLabels],
        counts: fCounts,
        countUnit: CountUnits.normal,
        prices: fPrices,
        priceUnit: PriceUnits.months,
        labelType: TableCellType.SELECTION,
        countType: TableCellType.SELECTION,
        priceType: TableCellType.EXTERNAL,
        dataVolumes: fDataVolumes,
      };
    }
    return furtherTableDataObject;
  };

  const [singlePaymentLabels] = useNewOfferState(
    "singlePaymentLabels",
    offerEntryId
  );
  const [singlePaymentPrices] = useNewOfferState(
    "singlePaymentPrices",
    offerEntryId
  );

  const getSinglePaymentTableObject = (
    index: number,
    options: Partial<SinglePaymentTableObjectGetterOptions> = {}
  ): TableDataObject => {
    let sLabel: string = config.defaultTable.singlePaymentTableLabel;
    if (singlePaymentLabels[index] !== undefined) {
      sLabel = singlePaymentLabels[index];
    }

    let sPrice = config.defaultTable.singlePaymentTablePrice;
    if (singlePaymentPrices[index] !== undefined) {
      sPrice = singlePaymentPrices[index];
    }
    return {
      labelId: 0,
      countId: 0,
      priceId: 0,
      labels: [sLabel],
      counts: [],
      countUnit: CountUnits.nothing,
      prices: [sPrice],
      priceUnit: PriceUnits.euro,
      labelType: TableCellType.INPUT,
      countType: TableCellType.NONE,
      priceType: TableCellType.INPUT,
    };
  };

  return {
    getAdditionalTableObject,
    getFurtherTableObject,
    getSinglePaymentTableObject,
  };
};
