import React from "react";
import styled from "styled-components";
import PaginationButton from "../pagination/PaginationButton";
import { Color } from "../../constants/color";

interface CountDisplayWithButtonsProps {
  className?: string;
  label: string;
  value?: number;
  max?: number;
  next?: () => void;
  prev?: () => void;
}

const CountDisplayWithButtons: React.FC<CountDisplayWithButtonsProps> = ({
  className,
  label,
  value = 0,
  prev,
  next,
}) => (
  <div className={className}>
    <span className={"label"}>{label}</span>
    <div className={"value-outer-container"}>
      <div className={"value-inner-container"}>
        <PaginationButton
          className={"prev"}
          direction={"prev"}
          onClick={prev}
        />
        <span className={"value"}>{value}</span>
        <PaginationButton
          className={"next"}
          direction={"next"}
          onClick={next}
        />
      </div>
    </div>
  </div>
);

const getColor: (props: CountDisplayWithButtonsProps) => string = ({
  value = 0,
}): string => (value > 0 ? Color.RED : Color.GREY);

export default styled(CountDisplayWithButtons)`
  display: table;
  min-width: 200px;
  width: 100%;
  max-width: 240px;
  height: 35px;
  background: ${getColor};
  margin: 10px auto;

  .label {
    display: table-cell;
    position: relative;
    min-width: 160px;
    margin: 4px 5px;
    padding: 5px;

    border: 3px ${getColor} solid;
    background: ${Color.WHITE};
    color: ${getColor};

    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .value-outer-container {
    display: table-cell;
    position: relative;
    width: 100%;
    vertical-align: middle;

    .value-inner-container {
      display: table;

      .prev {
        display: table-cell;
        height: 20px;
        width: 22px;
        padding: 2.5px 0;

        outline: none;
        vertical-align: middle;
        font-weight: bold;
        background-color: ${getColor};
        visibility: ${(props: CountDisplayWithButtonsProps) =>
          props.value && props.value > 0 ? "visible" : "hidden"};
        .left-triangle {
          margin-left: 0;
        }
      }

      .next {
        display: table-cell;
        height: 20px;
        width: 22px;
        padding: 2.5px 0;

        outline: none;
        vertical-align: middle;
        font-weight: bold;
        background-color: ${getColor};
        visibility: ${(props: CountDisplayWithButtonsProps) =>
          props.value && props.max && props.value >= props.max
            ? "hidden"
            : "visible"};

        .right-triangle {
          margin-left: 0;
        }
      }

      .value {
        display: table-cell;
        position: relative;

        width: 100%;
        max-width: 40%;
        padding: 5px;

        color: ${Color.WHITE};
        font-weight: bold;

        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;
