import React, { useState } from "react";
import styled from "styled-components";
import JustifiedColumnsContainer from "../grid/JustifiedColumnsContainer";
import { FormOption } from "../../state/definitions/FormConfigState";
import { useRefEffect } from "../../hooks/common/useRefHook";

interface CheckboxListProps {
  className?: string;
  data: FormOption[];
  values?: number[];
  onChange: (values: number[]) => void;
  renderFkt: (
    dataRecord: FormOption,
    isSelected: boolean,
    toggleValue: (value: number) => void
  ) => JSX.Element;
}

const RadioButtonList: React.FC<CheckboxListProps> = ({
  className,
  data,
  values = [],
  onChange,
  renderFkt,
}) => {
  const [selectedValues, setSelectedValues] = useState(values);

  const toggleValue = (value: number) => {
    setSelectedValues((prev) => {
      return prev.includes(value) ? prev.filter((v) => v !== value) : [value];
    });
  };

  useRefEffect(() => {
    onChange(selectedValues);
  }, [selectedValues]);

  return (
    <div key={-1} className={className}>
      <JustifiedColumnsContainer>
        {data.map((dataRecord: FormOption) => {
          const isSelected = selectedValues.includes(dataRecord.value);
          return renderFkt(dataRecord, isSelected, toggleValue);
        })}
      </JustifiedColumnsContainer>
    </div>
  );
};

export default styled(RadioButtonList)`
  .column {
    margin-bottom: 10px;
  }
`;
