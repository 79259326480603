import { useGigaKombiWiths } from "./useGigaKombiWiths";
import { IconTextDisplayProps } from "../../components/elements/initialInterestPage/IconTextDisplay";
import { useOfferState } from "../useOffer";
import {
  getDatavolumeMainGigaKombi,
  getFlexibilityMainGigaKombi,
  getRebateMainGigaKombi,
} from "../../helper/gigakombi/getMainGigaKombiAdvantages";
import { GigaKombiMainAdvantages } from "../../constants/InitialMainAdvantages";
import { DatabaseProductTypes } from "../../data/definitions/DatabaseProductTypes";
import { DISABLE_CYBER_ADVANTAGE } from "../../constants/default";

export const useGigaKombiMainAdvantages = () => {
  const { getGigaKombiWiths, currentProducts } = useGigaKombiWiths();
  const withsObject = getGigaKombiWiths();

  const gigaKombiMainAdvantages: IconTextDisplayProps[] = [];

  const [gigaKombiCalculatedDiscount] = useOfferState(
    "gigaKombiCalculatedDiscount"
  );
  if (gigaKombiCalculatedDiscount > 0) {
    gigaKombiMainAdvantages.push(
      getRebateMainGigaKombi(gigaKombiCalculatedDiscount)
    );
  }

  const [gigaKombiDataVolume] = useOfferState("gigaKombiDataVolume");
  if (gigaKombiDataVolume > 0) {
    gigaKombiMainAdvantages.push(
      getDatavolumeMainGigaKombi(gigaKombiDataVolume)
    );
  }

  const hasBasicDSLProduct = withsObject.fixed
    ? currentProducts.findIndex(
        (v) =>
          v.productType === DatabaseProductTypes.fixed &&
          v.productName.toLowerCase().includes("basic")
      ) >= 0
    : false;

  if (withsObject.mobile && withsObject.fixed) {
    if (!hasBasicDSLProduct) {
      gigaKombiMainAdvantages.push(GigaKombiMainAdvantages.euroFlat);
    }
    gigaKombiMainAdvantages.push(getFlexibilityMainGigaKombi(12));
    gigaKombiMainAdvantages.push(GigaKombiMainAdvantages.service);
    if (!DISABLE_CYBER_ADVANTAGE) {
      gigaKombiMainAdvantages.push(GigaKombiMainAdvantages.cyber);
    }
  }

  if (withsObject.mobile && withsObject.tv && !withsObject.fixed) {
    gigaKombiMainAdvantages.push(getFlexibilityMainGigaKombi(6));
    if (!DISABLE_CYBER_ADVANTAGE) {
      gigaKombiMainAdvantages.push(GigaKombiMainAdvantages.cyber);
    }
  }
  if (withsObject.fixed && withsObject.tv && !withsObject.mobile) {
    gigaKombiMainAdvantages.push(getFlexibilityMainGigaKombi(12));
  }
  // const { withFlatrate, withCyber } = getGigaSpecialValues(
  //   withsObject,
  //   [gigaKombiCalculatedDiscount, gigaKombiDataVolume, false],
  //   currentProducts
  // );

  // if (withFlatrate) {
  //   gigaKombiMainAdvantages.push(GigaKombiMainAdvantages.euroFlat);
  // }
  // gigaKombiMainAdvantages.push(GigaKombiMainAdvantages.flexibility);
  // if (withCyber) {
  //   gigaKombiMainAdvantages.push(GigaKombiMainAdvantages.cyber);
  // }
  // if (withsObject.fixed) {
  //   gigaKombiMainAdvantages.push(GigaKombiMainAdvantages.service);
  // }

  return { gigaKombiMainAdvantages, withsObject };
};
