import styled from "styled-components";
import React from "react";
import PassCounter from "../../form/PassCounter";
import { Color } from "../../../constants/color";
import { PassData } from "../../../types/table";

interface TabPassProps {
  className?: string;
  data?: PassData[];
  togglePass?: (value: number) => void;
}

const TabPass: React.FC<TabPassProps> = ({
  className,
  data = [],
  togglePass,
}) => {
  const changePassSelection = (passId: number) => {
    if (togglePass) {
      togglePass(passId);
    }
  };
  return (
    <div className={className}>
      <h1>1 Vodafone Pass inklusive</h1>
      <div className={"pass-counter-container"}>
        {data.length === 0 && (
          <p className={"no-data-available"}>Keine Pässe zurzeit verfügbar!</p>
        )}
        {data.map((d, i) => {
          return (
            <PassCounter
              key={i}
              label={d.name}
              price={d.price}
              selected={d.selectedPosition >= 0}
              image={d.image}
              onClick={() => changePassSelection(d.id)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default styled(TabPass)`
  text-align: center;
  padding: 10px 0 0 0;
  h1 {
    font-weight: bold;
    font-size: 30px;
    text-align: left;
    color: ${Color.BLACK};
    margin-left: 22px;
  }
  .pass-counter-container {
    text-align: left;

    p.no-data-available {
      margin: 15px 0 0 25px;
    }
  }
`;
