import { Interest } from "../../types/application";
import { getNewOfferEntryId } from "./offerEntryNameFunctions";
import { defaultProductLineOfferState } from "../../state/defaults/defaultProductLineOfferState";
import { v4 as uuid } from "uuid";
import { ProductLineOfferState } from "../../state/definitions/ProductLineOfferState";

export interface NewOfferStateOptions {
  rowId: string;
  interest: Interest;
  mainRow: boolean;
  overwriteObj: Partial<ProductLineOfferState>;
}

export function createNewNewOfferState(
  selectId: number = 0,
  productId: number = 0,
  options: Partial<NewOfferStateOptions> = {}
) {
  const {
    rowId = uuid(),
    interest,
    mainRow = false,
    overwriteObj = {},
  } = options;

  const offerEntryId = getNewOfferEntryId(rowId, selectId);
  const newNewOfferEntry = Object.assign(
    {},
    defaultProductLineOfferState,
    overwriteObj
  );
  newNewOfferEntry.rowId = offerEntryId;
  newNewOfferEntry.selectId = selectId;
  newNewOfferEntry.productId = productId;
  newNewOfferEntry.mainRow = mainRow;
  if (interest) {
    newNewOfferEntry.type = interest;
  }

  return newNewOfferEntry;
}
