import {
  SlideCategoryTypes,
  SlidePage,
  SlidePages,
} from "../../constants/SlidePages";

function calculatePrev(
  slides: SlidePage[],
  currentSlideIndex: number,
  someIsAvailable: boolean
): number {
  let prev = SlidePages.initialInterest.id;
  if (currentSlideIndex - 1 >= 0) {
    const prevPage = slides[currentSlideIndex - 1];
    prev = prevPage.id;
    if (
      prevPage.category === SlideCategoryTypes.availability &&
      someIsAvailable
    ) {
      // skip availability slides, if there is some available
      prev = calculatePrev(slides, currentSlideIndex - 1, someIsAvailable);
    }
  }
  return prev;
}

function calculateNext(
  slides: SlidePage[],
  currentSlideIndex: number,
  someIsAvailable: boolean
): number | null {
  let next = null;
  if (currentSlideIndex < slides.length - 1) {
    const nextPage = slides[currentSlideIndex + 1];
    next = nextPage.id;
    if (
      nextPage.category === SlideCategoryTypes.availability &&
      someIsAvailable
    ) {
      // skip availability slides, if there is some available
      next = calculateNext(slides, currentSlideIndex + 1, someIsAvailable);
    }
  }
  return next;
}

export interface NavigationIds {
  prev: number;
  next: number | null;
}

export function calculateNavigationIds(config: {
  slides: SlidePage[];
  someIsAvailable: boolean;
  currentSlideIndex: number;
}): NavigationIds {
  const { slides, currentSlideIndex, someIsAvailable } = config;
  // Calculate prev/next links on new slides array
  const prev = calculatePrev(slides, currentSlideIndex, someIsAvailable);
  const next = calculateNext(slides, currentSlideIndex, someIsAvailable);

  return {
    prev,
    next,
  };
}
