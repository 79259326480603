import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import SVGIcon from "../../svgIcon/SVGIcon";
import ColorIcons from "../../../icons/ColorIcons";
import { StyledComponentProps } from "../../../types/default";

export interface LongTextDisplayProps extends StyledComponentProps {
  extraClassName?: string;
  label: string;
  value?: string;
  minHeight?: string;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  headlineColor?: string;
  textColor?: string;
  backgroundColor?: string;
  iconColor?: string;
  iconSize?: number;
}

const LongTextDisplay: React.FC<LongTextDisplayProps> = ({
  extraClassName,
  className,
  label,
  value,
  icon = ColorIcons.checkCircle,
  headlineColor = Color.GREY_LIGHT3,
  textColor = Color.RED,
  backgroundColor = Color.BLACK,
  iconColor = Color.RED,
  iconSize = 40,
}) => (
  <div className={className + " " + extraClassName}>
    <span className={"check-container"}>
      <SVGIcon src={icon} size={iconSize} color={iconColor} />
    </span>
    <div className={"text-container"}>
      {label && <h4 className={"label"}>{label}</h4>}
      {value && <p className={"value"}>{value}</p>}
    </div>
  </div>
);

export default styled(LongTextDisplay)`
  display: inline-block;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "initial")};

  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : Color.GREY_LIGHT3};
  padding: 10px;
  margin: 4px;
  vertical-align: top;

  .check-container {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    color: ${({ textColor }) => (textColor ? textColor : Color.RED)};
    font-weight: bold;
  }
  .text-container {
    display: inline-block;
    width: calc(100% - ${({ iconSize }) => (iconSize ? iconSize + 10 : 50)}px);
    margin-left: 10px;
    .label {
      position: relative;

      color: ${({ textColor }) => (textColor ? textColor : Color.RED)};
      font-weight: bold;

      text-align: left;
      vertical-align: middle;
      font-size: 18px;
    }

    .value {
      position: relative;
      padding: 5px 0;
      color: ${({ headlineColor }) =>
        headlineColor ? headlineColor : Color.BLACK};
      text-align: left;
      vertical-align: top;
    }
  }
`;
