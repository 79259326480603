import { AppState } from "../../state/definitions/AppState";

export function getLoadingStateForRecommendationState(
  prev: AppState
): AppState {
  return {
    ...prev,
    recommendation: {
      ...prev.recommendation,
      loading: true,
    },
  };
}
