import { AppState } from "../definitions/AppState";
import { defaultAvailabilityState } from "./defaultAvailabilityState";
import { defaultNavigationState } from "./defaultNavigationState";
import { defaultFormState } from "./defaultFormState";
import { defaultRecommendationState } from "./defaultRecommendationState";
import { defaultOfferState } from "./defaultOfferState";
import { defaultProductsState } from "./defaultProductsState";
import { defaultBootstrapState } from "./defaultBootstrapState";
import { defaultNewOfferState } from "./defaultNewOfferState";
import { defaultContactState } from "./defaultContactState";
import { defaultAuthState } from "./defaultAuthState";

let vauoid = "";
try {
  let item = window.localStorage.getItem("vauoid");
  if (!!item) {
    vauoid = JSON.parse(item);
  }
} catch (e) {
  console.error("Problem fetching vauoid", e);
}
export const defaultAppState: AppState = {
  vauoid,
  storageId: "",
  offerSessionId: "",
  requestAppReload: false,
  bootstrap: defaultBootstrapState,
  availability: defaultAvailabilityState,
  navigation: defaultNavigationState,
  form: defaultFormState,
  recommendation: defaultRecommendationState,
  offer: defaultOfferState,
  newOffer: defaultNewOfferState,
  products: defaultProductsState,
  contact: defaultContactState,
  auth: defaultAuthState,
};
