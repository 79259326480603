import * as React from "react";

function SvgMoreMoney(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="moreMoney_svg___x33_efd5ddd-dfab-4c40-ab30-abde8d8a66a7"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.moreMoney_svg__st0,.moreMoney_svg__st1{fill:none;stroke: ${color} ;stroke-width:8}.moreMoney_svg__st0{stroke-miterlimit:10}.moreMoney_svg__st1{stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <g id="moreMoney_svg___x30_12705f3-d8dc-4075-b325-edea6733e893">
        <path
          className="moreMoney_svg__st0"
          d="M131.9 104.7l-.3.8c-2.6 6-25.5 10.6-43.6 10.6-19.9 0-44-5.6-44-12.5 0-4.3 6.3-8.1 15.9-10.4h.1"
        />
        <path
          className="moreMoney_svg__st1"
          d="M132 104.7v26.6c-4.7 5.5-27 8.4-44 8.4s-39.3-4-44-9.5v-26.6"
        />
        <path className="moreMoney_svg__st0" d="M132 120.2c5 2.2 8 4.9 8 7.9" />
        <path
          className="moreMoney_svg__st1"
          d="M140 128.1v27.6c-4.7 5.5-27 8.4-44 8.4s-39.3-4-44-9.5v-20"
        />
        <path
          className="moreMoney_svg__st0"
          d="M140 47.8c0 6.9-24.1 12.5-44 12.5s-44-5.6-44-12.5 24.1-12.5 44-12.5 44 5.6 44 12.5z"
        />
        <path
          className="moreMoney_svg__st1"
          d="M140 47.8v27.6c-4.7 5.5-27 8.4-44 8.4s-39.3-4-44-9.5V47.8"
        />
        <path className="moreMoney_svg__st0" d="M140 67.2c5 2.2 8 4.9 8 7.9" />
        <path
          className="moreMoney_svg__st1"
          d="M148 75.1v23.6c-4.7 5.5-27 8.4-44 8.4s-39.3-4-44-9.5V78.7"
        />
      </g>
      <path fill="none" d="M0 0h192v192H0z" />
    </svg>
  );
}

export default SvgMoreMoney;
