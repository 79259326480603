import React from "react";
import Icons from "../../icons/Icons";
import { StyledComponentProps, SVGComponent } from "../../types/default";
import styled from "styled-components";
import Headline from "../headline/Headline";
import GreyIcons from "../../icons/GreyIcons";
import ColorIcons from "../../icons/ColorIcons";
import { SVGIconContainerDEV } from "../svgIcon/SVGIconContainerDEV";
import { defaultStringSort } from "../../helper/default";

type IconList = [
  string,
  ((props: React.SVGProps<SVGSVGElement>) => JSX.Element) | SVGComponent
][];

interface IconPage extends StyledComponentProps {}

const IconPage = ({ className }: IconPage) => {
  const iconList: IconList = [];
  const IconKeys = Object.keys(Icons);
  const GreyIconsKeys = Object.keys(GreyIcons);
  const ColorIconsKeys = Object.keys(ColorIcons);

  IconKeys.forEach((key) => {
    iconList.push([key, Icons[key as keyof typeof Icons]]);
  });
  GreyIconsKeys.forEach((key) => {
    iconList.push([key, GreyIcons[key as keyof typeof GreyIcons]]);
  });
  ColorIconsKeys.forEach((key) => {
    iconList.push([key, ColorIcons[key as keyof typeof ColorIcons]]);
  });
  iconList.sort((a, b) => defaultStringSort(a[0], b[0]));
  return (
    <div className={className}>
      <Headline images={[]}>DES ICONS</Headline>
      {iconList.map(([key, icon], i) => (
        <SVGIconContainerDEV key={key + i} src={icon} name={key} />
      ))}
    </div>
  );
};

export default styled(IconPage)`
  background: #159a20;
`;
