import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import SVGIcon from "../../svgIcon/SVGIcon";
import { SVGComponent } from "../../../types/default";

interface BubbleImage {
  className?: string;
  icon: SVGComponent;
}

const BubbleImage: React.FC<BubbleImage> = ({ className, icon }) => (
  <div className={className}>
    <div className={"image-container"}>
      <SVGIcon src={icon} size={60} />
    </div>
  </div>
);

export default styled(BubbleImage)`
  display: block;
  width: 100%;
  margin: 0 !important;
  text-align: center;

  .image-container {
    margin: 0 !important;
    display: inline-block;
    position: relative;
    background: ${Color.RED};
    color: ${Color.WHITE};
    border-radius: 50%;
    padding: 10px;
    width: 80px;
    height: 80px;
    max-width: 100%;
    vertical-align: middle;

    img,
    svg {
      display: inline-block;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
