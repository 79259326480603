import { useContext, useState } from "react";
import { AppContext } from "../../state/AppContext";
import { useRefEffect, useRefMemo } from "../common/useRefHook";
import { extractIdSafe } from "../../helper/navigation/extractId";
import { useLocation } from "react-router-dom";
import { calculateNavigation } from "../../helper/navigation/calculateNavigation";
import { calculateNavigationIds } from "../../helper/navigation/calculateNavigationIds";

/**
 * Update current location in app state when location is changing
 */
const useNavigationUpdater = () => {
  const [state, setState] = useContext(AppContext);
  const [forward, setForward] = useState(true);
  const location = useLocation();

  const hasAnyFormData =
    state.form.mobileHasData ||
    state.form.iotHasData ||
    state.form.fixedHasData ||
    state.form.tvHasData;
  const dpd = [
    state.form.interests.join(""),
    location.pathname,
    /** add all form values, which change slide appearances */
    state.form.iotInterests,
    state.form.fixedDevicesPhone,
    state.availability.someIsAvailable,
    state.availability.availability.cable.available,
    state.form.mobileHasData,
    state.form.iotHasData,
    state.form.fixedHasData,
    state.form.tvHasData,
  ];

  const calculatedNavigation = useRefMemo(
    () =>
      calculateNavigation({
        interests: state.form.interests,
        hasAnyFormData: hasAnyFormData,
        location: location,
        oldSlideIndex: state.navigation.currentSlideIndex,
        oldSlides: state.navigation.slides,
        availability: state.availability,
        form: state.form,
      }),
    dpd
  );
  const navigationIds = useRefMemo(
    () =>
      calculateNavigationIds({
        slides: calculatedNavigation.pageArray,
        currentSlideIndex: calculatedNavigation.currentSlideIndex,
        someIsAvailable: state.availability.someIsAvailable,
      }),
    [...dpd, calculatedNavigation.idArrayString]
  );
  useRefEffect(() => {
    setState((prev) => ({
      ...prev,
      navigation: {
        ...prev.navigation,
        slides: calculatedNavigation.pageArray,
        slideCount: calculatedNavigation.count,
        currentSlideIndex: calculatedNavigation.currentSlideIndex,
        disableNext: calculatedNavigation.disableNext,
        prevSlide: navigationIds.prev,
        nextSlide: navigationIds.next,
      },
    }));
  }, [calculatedNavigation, navigationIds]);

  /**
   * Try to anticipate a direction when navigating in order to determine the
   * correct animation.
   */
  const [prevLocation, setPrevLocation] = useState("/0");
  const getSlideIndex = (id: number) =>
    state.navigation.slides.findIndex((v) => v.id === id);
  useRefEffect(() => {
    if (
      getSlideIndex(extractIdSafe(prevLocation)) <
      getSlideIndex(extractIdSafe(location.pathname))
    ) {
      setForward(true);
      setPrevLocation(location.pathname);
    } else if (
      getSlideIndex(extractIdSafe(prevLocation)) >
      getSlideIndex(extractIdSafe(location.pathname))
    ) {
      setForward(false);
      setPrevLocation(location.pathname);
    }
  }, [location]);

  return { forward };
};

export default useNavigationUpdater;
