import React from "react";
import styled from "styled-components";
import { Color } from "../../../../constants/color";

interface GigaValueProps {
  className?: string;
  value?: string | JSX.Element;
}

const GigaValue: React.FC<GigaValueProps> = ({ className, value }) => {
  return <span className={className + " giga-value"}>{value}</span>;
};

export default styled(GigaValue)`
  position: absolute;
  width: 100%;

  top: 24px;
  left: 0;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: ${Color.RED};
`;
