import { Interest } from "../../types/application";
import { ProductLineOfferState } from "../definitions/ProductLineOfferState";

export const defaultProductLineOfferState: ProductLineOfferState = {
  rowId: "",
  type: Interest.MOBILE,
  productId: 0,
  selectId: 0,
  mainRow: false,

  passesSelected: [],
  dataVolume: 0,
  currentPrice: 0,

  additionalLabels: [],
  additionalCountIds: [],
  additionalPrices: [],
  additionalStarts: [],
  furtherLabelIds: [],
  furtherCountIds: [],
  furtherLastLabel: "",
  furtherLastCount: 0,
  furtherLastPrice: 0,
  singlePaymentLabels: [],
  singlePaymentPrices: [],
};
