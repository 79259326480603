import useRecommendation from "../useRecommendation";
import { useInterestsValues } from "../useFormHelper/useInterests";
import { SubProducts, SummarizedPriceInformation } from "../../types/table";
import { RecommendationMode } from "../../types/application";
import { getModeKeyFromInterest } from "../../helper/recommendation/mapping";
import { useNewOffer } from "../useNewOffer";
import { useNewOfferState } from "../useOfferHelper/useNewOfferState";
import { useOfferTable } from "../useOfferHelper/useOfferTable";
import { getSubProducts } from "../../helper/table/getSubProducts";
import { useRegioDiscount } from "./useRegioDiscount";
import useGigaKombi from "../useOfferHelper/useGigaKombi";
import { useContext } from "react";
import { AppContext } from "../../state/AppContext";
import { useRefEffect } from "../common/useRefHook";
import { defaultPriceInformation } from "../../state/defaults/offer/defaultPriceInformation";

const useSubProducts = (
  offerEntryId: string,
  withGigaKombi: boolean = false
): SubProducts => {
  const [, setState] = useContext(AppContext);

  const { getNewOfferType } = useNewOffer();
  const { config } = useRecommendation();
  const recommendationModes = useInterestsValues();

  const offerTableFunctions: ReturnType<typeof useOfferTable> =
    useOfferTable(offerEntryId);
  const [mainRow] = useNewOfferState("mainRow", offerEntryId);
  const [passesSelected] = useNewOfferState("passesSelected", offerEntryId);
  const type = getNewOfferType(offerEntryId);
  const regioDiscountObject: ReturnType<typeof useRegioDiscount> =
    useRegioDiscount(offerEntryId);

  const gigaKombiIsEnable = withGigaKombi && mainRow;
  const gigaKombiObject: ReturnType<typeof useGigaKombi> = useGigaKombi(
    type,
    gigaKombiIsEnable
  );

  const mode: RecommendationMode =
    recommendationModes[getModeKeyFromInterest(type)];
  let isSelectedType = mode === RecommendationMode.SELECTABLE;

  const subProducts = getSubProducts({
    config,
    offerTableFunctions,
    regioDiscountObject,
    gigaKombiObject,
    type,
    gigaKombiIsEnable,
    isSelectedType,
    passesSelected,
  });

  useRefEffect(() => {
    setState((prev) => {
      let oldEntryPriceInformation: SummarizedPriceInformation = Object.assign(
        {},
        defaultPriceInformation
      );
      if (prev.recommendation.priceInformation[offerEntryId] !== undefined) {
        oldEntryPriceInformation =
          prev.recommendation.priceInformation[offerEntryId];
      }
      return {
        ...prev,
        recommendation: {
          ...prev.recommendation,
          priceInformation: {
            ...prev.recommendation.priceInformation,
            [offerEntryId]: {
              ...oldEntryPriceInformation,
              monthly: subProducts.priceInformation.monthly,
              once: subProducts.priceInformation.once,
              list: subProducts.priceInformation.list,
            },
          },
        },
      };
    });
  }, [
    Object.keys(subProducts.priceInformation.monthly).join(","),
    Object.values(subProducts.priceInformation.monthly).join(","),
    subProducts.priceInformation.list.join(","),
    subProducts.priceInformation.once,
  ]);

  return subProducts;
};

export default useSubProducts;
