import React from "react";
import styled from "styled-components";
import PanelItem from "../../panel/PanelItem";
import PanelValue from "../../panel/PanelValue";
import GreyIcons from "../../../icons/GreyIcons";
import { PriceSituation } from "../../../types/profile";
import { prettyMoney } from "../../../helper/getPrettyStrings";

interface PanelItemsPriceSituationProps {
  className?: string;
  situation: PriceSituation;
}

const PanelItemsPriceSituation: React.FC<PanelItemsPriceSituationProps> = ({
  situation,
}) => (
  <>
    {situation.summaryPrice !== undefined && situation.summaryPrice > 0 && (
      <PanelItem icon={GreyIcons.price}>
        Preis: <PanelValue>{prettyMoney(situation.summaryPrice)}</PanelValue>
      </PanelItem>
    )}
  </>
);

export default styled(PanelItemsPriceSituation)``;
