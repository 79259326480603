import * as React from "react";
import Icons from "../../../icons/Icons";
import NewsPanelItem from "./NewsPanelItem";
import { Color } from "../../../constants/color";
import TileButton from "../../form/TileButton";
import SVGIcon from "../../svgIcon/SVGIcon";
import Modal from "../../modal/Modal";
import ExternalLink from "../../links/ExternalLink";
import { SVGComponent } from "../../../types/default";

export interface NewsPanelItemContainerProps {
  title: string;
  mainIcon?: SVGComponent;
  modalColor?: string;
  fontColor?: string;
  link?: string;
  linkText?: string;
}

const NewsPanelItemContainer: React.FC<NewsPanelItemContainerProps> = ({
  title,
  mainIcon = Icons.info,
  modalColor = Color.RED,
  fontColor = Color.WHITE,
  link,
  linkText,
  children,
}) => {
  return (
    <Modal
      headlineIcon={mainIcon}
      darkBackground
      headline={title}
      leftMargin={0}
      modalColor={modalColor}
      fontColor={fontColor}
      withoutBodyPadding
      openButton={(onChange) => (
        <NewsPanelItem
          color={modalColor}
          icon={mainIcon}
          text={title}
          onClick={() => onChange(true)}
        />
      )}
    >
      {children}
      {linkText && link && (
        <ExternalLink to={link}>
          <TileButton
            maxWidth={"unset"}
            padding={"10px 5px"}
            marginTop={0}
            endIcon={<SVGIcon src={Icons.rightArrow} size={40} />}
          >
            {linkText}
          </TileButton>
        </ExternalLink>
      )}
    </Modal>
  );
};

export default NewsPanelItemContainer;
