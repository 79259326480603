import * as React from "react";

function SvgMovie(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="movie_svg__b12d3976-2a98-46f6-a271-4f9203fd41e9"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.movie_svg__st0{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path
        className="movie_svg__st0"
        d="M20 44h152v104H20zM20 60h152M20 132h152M48 44v16M72 44v16M96 44v16M120 44v16M144 44v16M48 132v16M72 132v16M96 132v16M120 132v16M144 132v16"
      />
      <path className="movie_svg__st0" d="M116 96L86 81v30z" />
    </svg>
  );
}

export default SvgMovie;
