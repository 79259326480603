import * as React from "react";

function SvgMusicColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <path
        d="M207.71 406.74V101.77l277.6-94.3v377.37M89.97 372.58c52.37-18.52 104.08-8.79 115.52 21.72s-21.75 70.27-74.11 88.78-104.08 8.79-115.52-21.72c-11.43-30.51 21.75-70.26 74.11-88.78zm278.07-26.8c52.27-18.49 103.9-8.78 115.32 21.69 11.41 30.46-21.71 70.15-73.99 88.63-52.27 18.49-103.91 8.78-115.32-21.69-11.41-30.47 21.71-70.14 73.99-88.63z"
        fill="none"
        stroke="#eb9700"
        strokeWidth={24.173}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgMusicColor;
