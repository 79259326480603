import { stringify } from "qs";
import { useContext } from "react";
import { AppContext } from "../state/AppContext";
import { defaultAvailabilityState } from "../state/defaults/defaultAvailabilityState";
import testAvailability from "../helper/dataTester/testAvailability";
import sortAvailabilityData from "../helper/dataHandling/sortAvailabilityData";
import testAvailabilitySearch from "../helper/dataTester/testAvailabilitySearch";
import { Interest } from "../types/application";
import { DisplayErrorMessages } from "../constants/DisplayErrorMessages";
import { LoadableResources, loadConfigData } from "../helper/loadConfigData";
import { AddressResult } from "../state/definitions/AvailabilityState";
import useAppReload from "./useAppHelper/useAppReload";
import { ErrorCodes } from "../constants/ErrorCodes";
import useAuth from "./useAuth";

type AddressSearch = (address: {
  zip: string;
  city: string;
  street: string;
  streetNumber: string;
  streetNumberExtra: string;
}) => void;

export const useAddressSearch = () => {
  const [state, setState] = useContext(AppContext);
  const [, reload] = useAppReload();
  const { logout } = useAuth();

  const setLoading = (loading: boolean) => {
    setState((prev) => ({
      ...prev,
      availability: {
        ...prev.availability,
        loading,
      },
    }));
  };

  const setErrorMessage = (message: DisplayErrorMessages, code: ErrorCodes) => {
    setState((prev) => ({
      ...prev,
      availability: {
        ...prev.availability,
        errorMessage: message,
        errorCode: code,
      },
    }));
  };

  const search: AddressSearch = async (address) => {
    try {
      setLoading(true);
      setErrorMessage(DisplayErrorMessages.nothing, ErrorCodes.nothing);

      const availabilitySearch = await loadConfigData(
        testAvailabilitySearch,
        LoadableResources.AVAILABILITY_ADDRESS_SEARCH,
        reload,
        [stringify(address)]
      )();

      setState((prev) => ({
        ...prev,
        availability: {
          ...prev.availability,
          addresses: availabilitySearch,
        },
      }));

      if (availabilitySearch.length < 1) {
        setErrorMessage(
          DisplayErrorMessages.noAvailabilityAddresses,
          ErrorCodes.default
        );
      }

      setLoading(false);
    } catch (e) {
      console.error(e);
      if (e.code && e.code === 401) {
        logout();
      }
      setErrorMessage(
        DisplayErrorMessages.technicalProblem,
        ErrorCodes.falseServerAvailability
      );
      setLoading(false);
    }
  };

  return {
    search,
    addresses: state.availability.addresses,
    loading: state.availability.loading,
    errorMessage: state.availability.errorMessage,
  };
};

const useAvailability = () => {
  const [state, setState] = useContext(AppContext);
  const [, reload] = useAppReload();
  const { logout } = useAuth();

  const setLoading = (loading: boolean) => {
    setState((prev) => ({
      ...prev,
      availability: {
        ...prev.availability,
        loading,
      },
    }));
  };

  const setErrorMessage = (message: DisplayErrorMessages) => {
    setState((prev) => ({
      ...prev,
      availability: {
        ...prev.availability,
        errorMessage: message,
      },
    }));
  };

  const reset = () => {
    setState((prev) => ({
      ...prev,
      availability: defaultAvailabilityState,
    }));
  };

  const checkAvailability = async (address: AddressResult) => {
    try {
      setLoading(true);
      setErrorMessage(DisplayErrorMessages.nothing);
      const availability = await loadConfigData(
        testAvailability,
        LoadableResources.AVAILABILITY,
        reload,
        [address.addressId]
      )();
      const sortedAvailability = sortAvailabilityData(availability);
      setState((prev) => {
        const fixedIsAvailable =
          prev.form.interests.includes(Interest.FIXED) &&
          (availability.dsl.available ||
            availability.cable.available ||
            availability.fiber.available);
        const tvIsAvailable =
          prev.form.interests.includes(Interest.TV) &&
          availability.tv.available;

        return {
          ...prev,
          availability: {
            ...prev.availability,
            selectedAddress: address,
            availability: sortedAvailability,
            someIsAvailable: fixedIsAvailable || tvIsAvailable,
          },
          form: {
            ...prev.form,
            withRegioDiscount: sortedAvailability.dsl.regioTax,
          },
        };
      });
      setLoading(false);
    } catch (e) {
      if (e.code && e.code === 401) {
        logout();
      }

      setErrorMessage(e.message);
      setLoading(false);
    }
  };

  return {
    checkAvailability,
    reset,
    selectedAddress: state.availability.selectedAddress,
    availability: state.availability.availability,
    someIsAvailable: state.availability.someIsAvailable,
    loading: state.availability.loading,
  };
};

export default useAvailability;
