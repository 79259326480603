import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import TileButton from "../form/TileButton";
import { Color } from "../../constants/color";
import { ZIndex } from "../../constants/zIndex";
import SVGIcon from "../svgIcon/SVGIcon";
import Icons from "../../icons/Icons";
import { SVGComponent } from "../../types/default";

export interface ModalInnerProps {
  className?: string;
  headline?: string;
  headlineIcon?: SVGComponent;
  onChange: (value: boolean) => void;
  darkBackground?: boolean;
  notCloseable?: boolean;
  leftMargin?: number;
  withoutBodyPadding?: boolean;

  modalColor?: string;
  fontColor?: string;
}

const ModalInner: React.FC<ModalInnerProps> = ({
  className,
  children,
  onChange,
  headline,
  notCloseable,
  headlineIcon,
  darkBackground = false,
}) => {
  const escClick = useCallback((ev: KeyboardEvent) => {
    if (ev.key == "Escape") onChange(false);
  }, []);

  useEffect(() => {
    window.addEventListener("keyup", escClick);
    return () => window.removeEventListener("keyup", escClick);
  }, []);

  const backgroundClick = () => {
    if (darkBackground) {
      onChange(false);
    }
  };
  const modalClick = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ev.stopPropagation();
  };

  return (
    <div className={className}>
      <div className={"modal-background"} onClick={backgroundClick} />
      <div className={"modal-container"} onClick={backgroundClick}>
        <div className={"modal"} onClick={modalClick}>
          <div className={"modal-header"}>
            <div className={"modal-headline"}>
              {headlineIcon && <SVGIcon src={headlineIcon} size={45} />}
              <p>{headline}</p>
            </div>
            {!notCloseable && (
              <ModalCloseButton value={true} onChange={onChange}>
                <SVGIcon src={Icons.close} size={40} />
              </ModalCloseButton>
            )}
          </div>
          <div className={"modal-body"}>{children}</div>
        </div>
      </div>
    </div>
  );
};

const ModalCloseButton = styled(TileButton)`
  width: 50px;
  height: 50px;
  margin: 8px;
  float: right;
  clear: right;
  padding: 5px;
  background: ${Color.GREY_LIGHT};
`;

export default styled(ModalInner)`
  position: absolute;
  height: 0;
  width: 0;

  .modal-background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: ${({ darkBackground }) =>
      darkBackground ? "rgba(50, 50, 50, 0.5)" : "transparent"};
    z-index: ${ZIndex.MODAL_BACKGROUND};
  }

  .modal-container {
    position: fixed;
    left: ${({ leftMargin }) => leftMargin ?? 80}px;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: ${ZIndex.MODAL};
    height: 100%;
    overflow: auto;

    .modal {
      @media (min-width: 1279px) {
        margin: 20px 100px;
        width: calc(100% - 200px);
      }
      @media (min-width: 1023px) and (max-width: 1279px) {
        margin: 20px 60px;
        width: calc(100% - 120px);
      }
      @media (min-width: 799px) and (max-width: 1023px) {
        margin: 20px 40px;
        width: calc(100% - 80px);
      }
      @media (max-width: 799px) {
        margin: 0 10px 0
          ${({ leftMargin = -1 }) => (leftMargin === 0 ? "10px" : "auto")};
        width: calc(
          100% -
            ${({ leftMargin = -1 }) => (leftMargin === 0 ? "20px" : "10px")}
        );
      }

      position: relative;
      background: ${(props) => props.modalColor ?? Color.WHITE};
      box-shadow: 4px 4px 7px -3px ${Color.BLACK};
      max-height: calc(100% - 150px);
      //height: 100%;
      overflow: hidden;

      display: flex;
      flex-direction: column;

      .modal-header {
        .modal-headline {
          //text-align: center;
          color: ${(props) => props.fontColor ?? Color.RED};
          display: inline-block;
          width: calc(100% - 101px);
          margin: 12px 0 0 25px;
          @media (min-width: 800px) {
            font-size: 45px;
          }
          @media (max-width: 799px) {
            font-size: 30px !important;
          }

          img,
          svg {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
          }
          p {
            display: inline-block;
            vertical-align: middle;
            letter-spacing: 1px;
          }
        }
      }

      .modal-body {
        max-height: calc(100% - 90px);
        padding: ${({ withoutBodyPadding }) =>
          withoutBodyPadding ? "" : "15px 30px 0 30px"};

        width: auto;
        color: ${(props) => props.fontColor ?? Color.RED};
        overflow: auto;
        overflow-x: hidden;
      }
    }
  }
`;
