import React from "react";
import styled from "styled-components";
import { StyledComponentProps } from "../../types/default";
import { OptimizeContractData } from "../../types/application";
import useBootstrap from "../../hooks/useBootstrap";
import Icons from "../../icons/Icons";

import CountDisplayWithButtons from "./CountDisplayWithButtons";
import { getMobileCardLabel } from "../../helper/recommendation/mapping";
import TextFieldIconTileButton from "./TextFieldIconTileButton";

export interface PersonDisplayProps extends StyledComponentProps {
  index: number;
  data: OptimizeContractData;
}

const PersonDisplay: React.FC<PersonDisplayProps> = ({
  className,
  index,
  data,
}) => {
  const { formConfig } = useBootstrap();
  const icon = data.type === "person" ? Icons.person : Icons.kid;

  const changeToNextValue = (contractId: number) => {
    const dataObject = data.contracts[contractId];

    if (dataObject && dataObject.setter) {
      const newCount =
        data.contracts[contractId].count + 1 <= 5
          ? data.contracts[contractId].count + 1
          : 5;
      dataObject.setter(newCount);
    }
  };

  const changeToPrevValue = (contractId: number) => {
    const dataObject = data.contracts[contractId];
    if (dataObject && dataObject.setter) {
      const newCount =
        data.contracts[contractId].count - 1 >= 0
          ? data.contracts[contractId].count - 1
          : 0;
      dataObject.setter(newCount);
    }
  };

  return (
    <div className={className + " person-display-container"} key={index}>
      <div className={"person-display-container-inner"}>
        <TextFieldIconTileButton
          value={data.name}
          onChange={(newValue) => {
            data.nameSetter(newValue);
          }}
          icons={[icon]}
          imageSize={66}
        />
        <div className={"contract-container"}>
          {data.contracts &&
            data.contracts.map((c, j) => {
              return (
                <CountDisplayWithButtons
                  key={j}
                  label={getMobileCardLabel(c.labelKey, formConfig)}
                  value={c.count}
                  max={5}
                  next={() => {
                    changeToNextValue(j);
                  }}
                  prev={() => {
                    changeToPrevValue(j);
                  }}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default styled(PersonDisplay)`
  display: inline-block;
  vertical-align: top;
  .person-display-container-inner {
    display: inline-block;
    text-align: center;

    .contract-container {
      margin: 5px;
      display: block;
      vertical-align: top;
    }
  }
`;
