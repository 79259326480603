import * as React from "react";

function SvgUpgrade(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="upgrade_svg___x37_fe0e893-843d-4e61-935c-9f3233d59817"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.upgrade_svg__st0{fill:none;stroke: ${color} ;stroke-width:24.1732;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <g id="upgrade_svg___x39_56f7f12-ba84-4cfd-a840-e3bef5a62d1c">
        <path
          className="upgrade_svg__st0"
          d="M194.3 139.8l55.8-55.7 55.6 55.7M250 262.8V84.1"
        />
        <g id="upgrade_svg___x37_275ab41-04fd-46d6-b324-77b03aa68b0c">
          <path
            className="upgrade_svg__st0"
            d="M211.7 441.4h76.6M147.9 7.5h204.2c21.1 0 38.3 17.1 38.3 38.3v408.4c0 21.1-17.1 38.3-38.3 38.3H147.9c-21.1 0-38.3-17.1-38.3-38.3V45.8c0-21.2 17.1-38.3 38.3-38.3z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgUpgrade;
