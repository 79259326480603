import * as React from "react";
import { CSSProperties } from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import TileButton from "../../form/TileButton";
import Modal from "../../modal/Modal";

export interface CancelModalProps {
  className?: string;
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  headlineText?: string;
  description?: string;
  yesAction?: () => void;
  noAction?: () => void;
  yesText?: string;
  noText?: string;
  noCloseButton?: boolean;
  buttonStyle?: CSSProperties;
}

const CancelModal: React.FC<CancelModalProps> = (props) => {
  const yesHandler = () => {
    if (props.yesAction) {
      props.yesAction();
    }
    props.setIsOpen(false);
  };
  const noHandler = () => {
    if (props.noAction) {
      props.noAction();
    }
    props.setIsOpen(false);
  };

  return (
    <div className={props.className}>
      <Modal
        notCloseable={props.noCloseButton}
        isOpen={props.isOpen}
        setIsOpen={props.setIsOpen}
        modalColor={Color.WHITE}
        fontColor={Color.RED}
        headline={
          props.headlineText ?? "Möchtest Du den Vorgang wirklich abbrechen?"
        }
      >
        {!!props.description ? (
          <p className={"cancel-modal-description"}>{props.description}</p>
        ) : null}
        <div className={"cancel-modal-container"}>
          <TileButton
            style={props.buttonStyle}
            value={true}
            onChange={yesHandler}
          >
            {props.yesText}
          </TileButton>
          {!!props.noText && props.noText !== "" && (
            <TileButton
              style={props.buttonStyle}
              value={true}
              onChange={noHandler}
            >
              {props.noText}
            </TileButton>
          )}
        </div>
        {!!props.children && (
          <div className={"cancel-modal-container children-container"}>
            {props.children}
          </div>
        )}
      </Modal>
    </div>
  );
};

CancelModal.defaultProps = {
  yesText: "Ja",
  noCloseButton: false,
};

export default styled(CancelModal)`
  .cancel-modal-description {
    font-size: 25px;
    text-align: center;
  }
  .children-container {
    margin: 10px 0 5px 0 !important;
  }
  .cancel-modal-container {
    margin: 20px 0 5px 0;
    width: 100%;
    text-align: center;

    .tile-button {
      display: inline-block;
      margin: 10px;
      padding: 20px;
      width: 200px;
      height: 60px;
      vertical-align: middle;
      box-shadow: 4px 4px 10px 0 ${Color.GREY5};

      .tile-button-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        vertical-align: middle;
        height: 100%;
      }
    }
  }
`;
