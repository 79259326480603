export class RecommendationDevelopmentConfig {
  standardDiscountLabel: string = "";
  standardDiscountCounts: number[] = [];
  defaultAdditionalTableLabel: string = "";
  defaultAdditionalTableCount: number = 0;
  defaultAdditionalTablePrice: number = 0;
  defaultAdditionalTableStart: number = 0;
  defaultFurtherTableLabel: string = "";
  defaultFurtherTableCount: number = 0;
  defaultFurtherTablePrice: number = 0;
  defaultFurtherTableDataVolume: number = 0;
  defaultFurtherTableLastLabel: string = "";
  defaultFurtherTableLastCount: number = 0;
  defaultFurtherTableLastPrice: number = 0;
  defaultSinglePaymentTableLabel: string = "";
  defaultSinglePaymentTablePrice: number = 0;

  dataVolumeMaximum: number = 0;
}
