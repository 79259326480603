import React from "react";
import styled from "styled-components";
import TileButton from "./TileButton";
import { FormOption } from "../../state/definitions/FormConfigState";

interface TileRadioButtonListProps {
  className?: string;
  title: string;
  data: FormOption[];
  value: number;
  onChange: (value: number[]) => void;
}

const TileRadioButtonList: React.FC<TileRadioButtonListProps> = ({
  className,
  title,
  data,
  value,
  onChange,
}) => {
  const changeHandler = (item: FormOption) => () => {
    if (value === item.value) {
      onChange([]);
    } else {
      onChange([item.value]);
    }
  };
  return (
    <div key={-1} className={className}>
      <TileButton key={-1} main={true} value={false} onChange={() => {}}>
        {title}
      </TileButton>
      {data.map((item) => {
        return (
          <TileButton
            key={item.value}
            value={value === item.value}
            onChange={changeHandler(item)}
          >
            {item.label}
          </TileButton>
        );
      })}
    </div>
  );
};

export default styled(TileRadioButtonList)``;
