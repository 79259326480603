import useRecommendation from "../useRecommendation";
import { RecommendationProductLineConfig } from "../../state/definitions/RecommendationState";
import {
  getNumberFromProductOption,
  getStringFromProductOption,
} from "../../helper/getFromOption";
import { mapProductOptionToValue } from "../../helper/mapOptionToValue";
import { Interest, MonthlyPriceArray, Product } from "../../types/application";

const mergeProductOptionsToProducts = (
  productLineConfig: RecommendationProductLineConfig
): Product[] => {
  const {
    productId,
    productPreLabel,
    productLabel,
    productPostLabel,
    productPreDescription,
    productDescription,
    productPostDescription,
  } = productLineConfig;
  const products: Product[] = [];
  for (let i = 0; i < productLabel.length; i++) {
    products.push({
      id: getNumberFromProductOption(productId, i),
      title: {
        preLabel: getStringFromProductOption(productPreLabel, i),
        label: getStringFromProductOption(productLabel, i),
        postLabel: getStringFromProductOption(productPostLabel, i),
      },
      body: {
        preLabel: getStringFromProductOption(productPreDescription, i),
        label: getStringFromProductOption(productDescription, i),
        postLabel: getStringFromProductOption(productPostDescription, i),
      },
    });
  }
  return products;
};

const useProducts = (type: Interest): [Product[], MonthlyPriceArray[]] => {
  const { config } = useRecommendation();

  let products: Product[] = mergeProductOptionsToProducts(config[type]);
  let prices: MonthlyPriceArray[] = mapProductOptionToValue(
    config[type].productPrices
  );

  return [products, prices];
};

export default useProducts;
