import {
  DatabaseProductTypes,
  DatabaseProductTypesFalseIds,
} from "./DatabaseProductTypes";
import { DatabaseCategoryTypes } from "./DatabaseCategoryTypes";
import { DatabaseProductPriceType } from "./DatabaseProductPriceType";

export class TransferDatabaseProduct {
  product_id: string = "-1";
  product_name: string = "";
  product_category: string = "";
  product_type: string = "";
  combi_rebate: number = -1;
  combi_push: number = -1;
  product_price_id: number = -1;
  product_basic_price: number = 0;
  product_price_unit: string = "";
  product_price_type: number = -1;

  product_property_01: string = "";
  product_property_02: string = "";
  product_property_03: string = "";
  product_property_04: string = "";
  product_property_05: string = "";
  min_contract_term: string = "";
  datavol_incl: string = "";
  product_rebate: number = 0;
  product_rebate_duration: number = 0;
  product_rebate_type: number = 0;
  product_rebate_unit: string = "";
  product_offered: number = 0;

  constructor(productType: DatabaseProductTypes, productName?: string) {
    this.product_type = productType;
    this.product_id = DatabaseProductTypesFalseIds[productType];
    this.product_name =
      productName !== undefined ? productName : "Kein Interesse";
    this.product_category = DatabaseCategoryTypes.tariff;
    this.product_price_type = DatabaseProductPriceType.MONTHLY;
  }
}
