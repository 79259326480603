import * as React from "react";

function SvgVideoColor2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192" {...props}>
      <g fill="none">
        <path d="M0 0h192v192H0z" />
        <path
          d="M136.1 84.3l26.5-26.8c2.6-2.6 7.4-2.6 9.4 2.8v72.1c-1.7 5-6.8 4.7-9.4 2.1l-26.5-26.6-.1-.2V136c0 6.6-5.4 12-12 12H32c-6.6 0-12-5.4-12-12V56c0-6.6 5.4-12 12-12h92c6.6 0 12 5.4 12 12v28.6l.1-.3z"
          stroke="#e60000"
          strokeWidth={8}
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SvgVideoColor2;
