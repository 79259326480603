import * as React from "react";
import styled from "styled-components";
import { StyledComponentProps } from "../../types/default";

export interface Props extends StyledComponentProps {
  disabled?: boolean;
  showDisabled?: boolean;
}

const DisableShadowBox = ({
  className,
  disabled = false,
  showDisabled = false,
}: Props) => {
  if (disabled || showDisabled) {
    return <div className={className + " disable-shadow-box"} />;
  }
  return null;
};

export default styled(DisableShadowBox)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(50, 50, 50, 0.5);

  :hover {
    cursor: ${(props) =>
      props.showDisabled && !props.disabled ? "pointer" : "not-allowed"};
  }
`;
