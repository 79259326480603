import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import { ZIndex } from "../../../constants/zIndex";
import { CountUnits } from "../../../types/table";

interface AFOSelectionModalProps {
  className?: string;
  data: Array<string | number>;
  selectedId?: number;
  changeId: (valueId: number) => void;
  countUnit?: CountUnits;
}

function calcScrollValue(selectedId: number, dataLength: number): number {
  if (selectedId < 3) {
    return 0;
  }
  if (selectedId > 2 && selectedId < dataLength - 3) {
    return (selectedId - 2) * 30;
  }
  if (selectedId > 2 && selectedId < dataLength - 4) {
    return (selectedId - 3) * 30;
  }
  if (selectedId > 2 && selectedId < dataLength - 5) {
    return (selectedId - 4) * 30;
  }
  return (dataLength - 5) * 30;
}

function calcMoveValue(selectedId: number, dataLength: number): number {
  if (selectedId < 3) {
    return selectedId * -30;
  }
  if (selectedId === dataLength - 2) {
    return -90;
  }
  if (selectedId === dataLength - 1) {
    if (dataLength < 5) {
      return -90;
    }
    return -120;
  }
  return -60;
}

const AFOSelectionModal: React.FC<AFOSelectionModalProps> = ({
  className,
  data,
  selectedId,
  changeId,
  countUnit,
}) => {
  const selectedEl = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (selectedEl && selectedEl.current) {
      const value = selectedId ? selectedId : 0;
      selectedEl.current.scrollBy(0, calcScrollValue(value, data.length));
    }
    // code to run on component mount
  }, [selectedEl, selectedId, data.length]);
  return (
    <div className={className}>
      <div className={"selection-modal"} ref={selectedEl}>
        {data.map((v: string | number, i) => {
          return (
            <div
              key={i}
              className={`col-cell ${selectedId === i ? "selected-cell" : ""}`}
              onClick={() => changeId(i)}
            >
              {!!countUnit && <span>{v + " " + countUnit}</span>}
              {!countUnit && <span>{v}</span>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default styled(AFOSelectionModal)`
  display: block;
  position: relative;
  cursor: pointer;
  z-index: ${ZIndex.FRONT_1};

  .selection-modal {
    width: 100%;
    max-width: 600px;
    display: block;
    position: absolute;
    top: ${({ selectedId, data }) =>
      selectedId && data[selectedId] !== undefined
        ? calcMoveValue(selectedId, data.length) + "px"
        : "0"};
    box-shadow: 10px 7.5px 7.5px 0 ${Color.GREY4};
    max-height: 150px;
    overflow-y: scroll;
    margin-bottom: 25px;
    .col-cell {
      height: 30px;
      display: block;
      padding: 5px;
      border-top: 0;
      border-left: 1px;
      border-right: 1px;
      border-bottom: 1px;
      border-color: ${Color.WHITE};
      border-style: solid;
      background: ${Color.GREY_LIGHT6};
      color: ${Color.WHITE};
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .col-cell:first-child {
      border-top: 1px ${Color.WHITE} solid;
    }
    .selected-cell {
      background: ${Color.RED};
    }
    .col-cell:hover {
      background: ${Color.RED_LIGHT}; // TODO: get lighter color from Vodafone
    }
  }
`;
