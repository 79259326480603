import {
  MonthlyPriceArray,
  RecommendationMode,
  RecommendationModeArray,
} from "../../types/application";
import { ProductLineOfferState } from "../../state/definitions/ProductLineOfferState";
import { RecommendationState } from "../../state/definitions/RecommendationState";
import { getModeKeyFromInterest } from "../recommendation/mapping";
import { combineMonthlyPriceArray } from "../priceCalculation/combineMonthlyPriceArray";

export function getMonthlyPriceArrayFromOfferEntry(
  offerEntry: ProductLineOfferState,
  recommendation: RecommendationState,
  recommendationModes: RecommendationModeArray
): MonthlyPriceArray {
  switch (recommendationModes[getModeKeyFromInterest(offerEntry.type)]) {
    case RecommendationMode.SELECTABLE:
      return combineMonthlyPriceArray([
        recommendation.config[offerEntry.type].productPrices[
          offerEntry.selectId
        ].label,
        recommendation.priceInformation[offerEntry.rowId]?.monthly ?? { 0: 0 },
      ]);

    case RecommendationMode.CURRENT:
      return { 0: offerEntry.currentPrice };

    case RecommendationMode.INTERESTING:
  }
  return { 0: 0 };
}
