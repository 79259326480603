import { isSameAdditionalTableObject } from "../table/isSameAdditionalTableObject";
import { AdditionalTableDataObject, SubProducts } from "../../types/table";

export function addSpecialAdditionalTableObject(
  subProducts: SubProducts,
  defaultSpecialAdditionalObject: AdditionalTableDataObject,
  specialAdditionalObject: AdditionalTableDataObject,
  index: number
) {
  const isStandard = isSameAdditionalTableObject(
    subProducts.additionalTable.data[index],
    defaultSpecialAdditionalObject
  );
  if (isStandard) {
    subProducts.additionalTable.data[index] = specialAdditionalObject;
    return index;
  }
  return -1;
}
