import { PassData, SubProducts } from "../../../types/table";
import {
  ProductOption,
  RecommendationConfig,
  RecommendationProductLineConfig,
  RecommendationProductLineTable,
} from "../../definitions/RecommendationState";
import { getRecommendationTable } from "../../../helper/recommendation/mapping";
import { Interest } from "../../../types/application";
import { useOfferTable } from "../../../hooks/useOfferHelper/useOfferTable";
import { defaultPriceInformation } from "./defaultPriceInformation";

export interface DefaultSubProductsGetterOptions {
  config: RecommendationConfig;
  type: Interest;
}

export const getDefaultSubProducts = (
  offerTableFunctions: ReturnType<typeof useOfferTable>,
  options?: DefaultSubProductsGetterOptions
): SubProducts => {
  let defaultPassesTitle = "";
  let defaultPassesSubtitle = "";
  let defaultAdditionalTableTitle = "";
  let defaultAdditionalTableSubtitle = "";
  let defaultFurtherProductsTableTitle = "";
  let defaultFurtherProductsTableSubtitle = "";
  let defaultSinglePaymentTableTitle = "";
  let defaultSinglePaymentTableSubtitle = "";
  let defaultAdvantages: ProductOption<number[]>[] = [];
  let defaultPassData: PassData[] = [];

  if (options !== undefined) {
    const { type, config } = options;
    const tableName = getRecommendationTable(type);
    const lineConfig: RecommendationProductLineConfig | undefined =
      config[options?.type];
    const table: RecommendationProductLineTable | undefined = config[tableName];

    defaultPassesTitle = table.tables[0];
    defaultPassesSubtitle = table.tablesSubtitles[0];
    defaultAdditionalTableTitle = table.tables[1];
    defaultAdditionalTableSubtitle = table.tablesSubtitles[1];
    defaultFurtherProductsTableTitle = table.tables[2];
    defaultFurtherProductsTableSubtitle = table.tablesSubtitles[2];
    defaultSinglePaymentTableTitle = table.tables[3];
    defaultSinglePaymentTableSubtitle = table.tablesSubtitles[3];
    defaultAdvantages = lineConfig.productAdvantages;
  }

  return {
    passes: {
      title: defaultPassesTitle,
      subtitle: defaultPassesSubtitle,
      data: defaultPassData,
    },
    additionalTable: {
      title: defaultAdditionalTableTitle,
      subtitle: defaultAdditionalTableSubtitle,
      standardObject: offerTableFunctions.getAdditionalTableObject(-1),
      data: [],
      additionalData: {
        gigaKombiId: -1,
        regioDiscountId: -1,
      },
    },
    furtherProductsTable: {
      title: defaultFurtherProductsTableTitle,
      subtitle: defaultFurtherProductsTableSubtitle,
      standardObject: offerTableFunctions.getFurtherTableObject(-1),
      data: [],
    },
    singlePaymentTable: {
      title: defaultSinglePaymentTableTitle,
      subtitle: defaultSinglePaymentTableSubtitle,
      standardObject: offerTableFunctions.getSinglePaymentTableObject(-1),
      data: [],
    },
    priceInformation: Object.assign({}, defaultPriceInformation),
    advantages: defaultAdvantages,
  };
};
