import React from "react";
import styled from "styled-components";
import TextTile from "../TextTile";
import { Color } from "../../../../constants/color";
import { PriceTextTile } from "../PriceTextTile";
import InterestButton from "../InterestButton";
import { Interest, MonthlyPriceArray } from "../../../../types/application";
import { getInterestFromType } from "../../../../helper/recommendation/mapping";
import PriceDisplayContainer from "../PriceDisplayContainer";

interface InterestingRecommendationColumnProps {
  className?: string;
  productPrice?: MonthlyPriceArray;
  type: Interest;
}

const InterestingRecommendationColumn: React.FC<InterestingRecommendationColumnProps> =
  ({ className, productPrice, type }) => {
    return (
      <div className={className}>
        {!!productPrice && (
          <PriceTextTile backgroundColor={Color.GREY_LIGHT}>
            <PriceDisplayContainer data={productPrice} />
          </PriceTextTile>
        )}
        <TextTile backgroundColor={Color.WHITE}>
          {type !== undefined && (
            <div className={"interest-button-container"}>
              <InterestButton type={type} providerShouldSet noInterest>
                Habe schon ein Vodafone- {getInterestFromType(type)}- Produkt
              </InterestButton>
              <InterestButton type={type}>
                Interesse an {getInterestFromType(type)}
              </InterestButton>
            </div>
          )}
        </TextTile>
      </div>
    );
  };

export default styled(InterestingRecommendationColumn)`
  .price-display {
    color: ${Color.WHITE};
  }
  .recommendation-info-image {
    @media (min-width: 1023px) {
      width: 50%;
    }
    @media (max-width: 1023px) {
      width: 100%;
    }
    display: inline-block;
  }
  .recommendation-info-list {
    @media (min-width: 1023px) {
      width: 50%;
    }
    @media (max-width: 1023px) {
      width: 100%;
    }
    vertical-align: top;
    display: inline-block;
    list-style-type: disc;
    list-style-position: outside;
    text-align: left;
    padding: 0 2px 0 20px;
    .li {
      padding: 10px 0 10px 0;
    }
  }

  .interest-button-container {
    padding: 5px;
    justify-content: center;
    display: flex;
  }
`;
