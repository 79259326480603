import * as React from "react";

function SvgWorldColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="world_color_svg___x31_c15e2ab-e2f5-4dc3-a00f-b554654436d8"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".world_color_svg__st0{fill:none;stroke:#e60000;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <g id="world_color_svg__caed758f-c7d2-4f55-8260-99abc0929c09">
        <path
          id="world_color_svg__cf4c634b-ac3b-4b45-aaba-ce2a9f094281"
          className="world_color_svg__st0"
          d="M102.2 20.2C124.3 39.9 140 66.5 140 96c0 29.1-16.1 56.2-37.8 75.8"
        />
        <path
          id="world_color_svg__a98a2ca9-0108-41a0-8df6-93015b1325f1"
          className="world_color_svg__st0"
          d="M89.8 20.2C67.7 39.9 52 66.5 52 96c0 29.1 16.1 56.2 37.8 75.8"
        />
        <path
          id="world_color_svg__e1c55de6-ed04-4287-a4d3-2f036ce0eb58"
          className="world_color_svg__st0"
          d="M20 96h152"
        />
        <path
          id="world_color_svg__af5fb39b-7cac-4d86-9267-178f5011bc57"
          className="world_color_svg__st0"
          d="M96 172V20"
        />
        <path
          id="world_color_svg___x34_af7847b-a5c8-4f5b-8a6a-e7548260cb20"
          className="world_color_svg__st0"
          d="M152 48c-16.6 11.9-35.1 20-56 20-20.7 0-39.3-8.1-56-20"
        />
        <path
          id="world_color_svg___x36_0f18741-73c8-46f5-8571-16eb3b1ece70"
          className="world_color_svg__st0"
          d="M40 144c16.7-12.1 34.9-20 56-20 20.5 0 39.4 8.3 56 20"
        />
        <circle className="world_color_svg__st0" cx={96} cy={96} r={76} />
        <path fill="none" d="M0 0h192v192H0z" />
      </g>
    </svg>
  );
}

export default SvgWorldColor;
