import recommendationDevelopmentConfig from "../../data/recommendationDevelopmentConfig.json";
import { RecommendationDevelopmentConfig } from "../../data/definitions/RecommendationDevelopmentConfig";
import testRecommendationDevelopmentConfigJson from "../dataTester/testRecommendationDevelopmentConfigJson";

export function getRecommendationDevelopmentConfig(): [
  RecommendationDevelopmentConfig,
  boolean
] {
  const recommendationDevelopmentConfigObject: any = Object.assign(
    {},
    recommendationDevelopmentConfig
  );
  return testRecommendationDevelopmentConfigJson(
    recommendationDevelopmentConfigObject
  );
}
