import { testObjectIsFromClass } from "./default/testObjectIsFromClass";
import { SubProductsTableConfig } from "../dataHandling/definitions/SubProductsTableConfig";

export default (
  unsureSubProductsTableConfig: any
): [SubProductsTableConfig, boolean] => {
  let result = true;

  const testResult = testObjectIsFromClass(
    unsureSubProductsTableConfig,
    SubProductsTableConfig,
    true
  );
  if (testResult !== "") {
    console.error(
      "File subProductsTableConfig has a false structure:",
      testResult
    );
    result = false;
  }
  return [unsureSubProductsTableConfig as SubProductsTableConfig, result];
};
