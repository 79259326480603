import React from "react";
import styled from "styled-components";
import Tab from "./Tab";
import { Color } from "../../constants/color";

interface PaginationProps {
  className?: string;
  preferredTab?: number;
  tabNames?: Array<string | undefined>;
  changeTab: (newTabId: number) => void;
}

const Tabs: React.FC<PaginationProps> = ({
  className,
  preferredTab = 0,
  tabNames = [],
  changeTab,
  children,
}) => {
  return (
    <div className={className}>
      <ul className={"tabs-item-container"}>
        {tabNames.map((name, id) => {
          if (name === "") {
            return null;
          }
          return (
            <Tab
              key={id}
              selected={id === preferredTab}
              title={name}
              onClick={() => {
                changeTab(id);
              }}
            />
          );
        })}
      </ul>
      <div className={"tabs-content"}>{children}</div>
    </div>
  );
};

export default styled(Tabs)`
  display: block;
  height: 100%;
  width: 100%;
  padding: 5px;

  .tabs-item-container {
    width: 100%;
    display: table;
  }

  .tabs-content {
    background: ${Color.GREY_LIGHT2};
    @media (min-width: 1000px) {
      padding: 15px 120px;
    }
    @media (max-width: 1080px) {
      padding: 15px 60px;
    }
    margin: 5px;
    text-align: center;
    // overflow: auto; // not working at the top of container
  }
`;
