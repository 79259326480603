import styled from "styled-components";
import React from "react";
import Modal from "../../modal/Modal";
import { Color } from "../../../constants/color";
import { Interest } from "../../../types/application";
import Icons from "../../../icons/Icons";
import { StyledComponentProps } from "../../../types/default";
import InterestBubble from "./InterestBubble";
import JustifiedColumnsContainer from "../../grid/JustifiedColumnsContainer";
import IButton from "../../form/IconButton";
import Column from "../../grid/Column";
import { useProviderAll } from "../../../hooks/useFormHelper/useProviderAll";

interface ProviderModalProps extends StyledComponentProps {}

const ProviderModal: React.FC<ProviderModalProps> = ({ className }) => {
  const { someIsVfProvider } = useProviderAll();
  return (
    <div className={className}>
      <Modal
        darkBackground
        headline={"Welches Vodafone Produkt hast Du schon?"}
        withoutBodyPadding
        modalColor={`linear-gradient(270deg, ${Color.RED}, ${Color.RED_DARK})`}
        fontColor={Color.WHITE}
        openButton={(changeValue: (value: boolean) => void) => (
          <Column
            minWidth={"initial"}
            maxWidth={"150px"}
            textAlign={"center"}
            onClick={() => changeValue(true)}
          >
            <IButton
              icon={Icons.gigaKombiOverview}
              size={100}
              onChange={() => {}}
              backgroundColor={
                someIsVfProvider
                  ? Color.WHITE
                  : Color.GREY_DISABLED_ICON_BACKGROUND
              }
              color={someIsVfProvider ? Color.RED : Color.GREY}
            />
            <p className={"iot-headline"}>Bestandskunde</p>
          </Column>
        )}
      >
        <JustifiedColumnsContainer
          extraClassName={"provider-modal-content"}
          justifyContent={"space-evenly"}
          alignItems={"stretch"}
        >
          <InterestBubble icon={Icons.mobile} type={Interest.MOBILE} />
          <InterestBubble icon={Icons.internet} type={Interest.FIXED} />
          <InterestBubble icon={Icons.tv} type={Interest.TV} />
          <InterestBubble icon={Icons.iotSim} type={Interest.IOT} />
        </JustifiedColumnsContainer>
      </Modal>
    </div>
  );
};

export default styled(ProviderModal)`
  .provider-modal-content {
    padding: 30px 70px;
  }
`;
