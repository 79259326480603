import React from "react";
import styled from "styled-components";
import { StyledComponentProps } from "../../../types/default";
import JustifiedColumnsContainer from "../../grid/JustifiedColumnsContainer";
import { ZIndex } from "../../../constants/zIndex";
import RecommendationColumnContainer from "./column/RecommendationColumnContainer";
import { Color } from "../../../constants/color";
import SVGChevron from "../../../icons/svgComponents/Chevron";

export interface RecommendationTableProps extends StyledComponentProps {
  offerEntryIds: string[];
  xMove?: number;
  orderNumber?: number;
  onPreviewClick?: () => void;
}

const RecommendationTable: React.FC<RecommendationTableProps> = ({
  className,
  children,
  offerEntryIds,
  orderNumber = 0,
  onPreviewClick,
}) => {
  return (
    <div className={className}>
      <div
        className={
          "recommendation-table-preview-layer" +
          (orderNumber !== 0 ? " grey-foreground" : "")
        }
        onClick={() => {
          if (orderNumber !== 0 && onPreviewClick) {
            onPreviewClick();
          }
        }}
      >
        <div
          className={
            "chevron-container " +
            (orderNumber > 0
              ? "right-chevron-direction"
              : "left-chevron-direction")
          }
        >
          <SVGChevron />
        </div>
      </div>
      <JustifiedColumnsContainer justifyContent={"center"} flexWrap={"nowrap"}>
        {offerEntryIds.map((offerEntryId) => {
          return (
            <RecommendationColumnContainer
              key={offerEntryId}
              offerEntryId={offerEntryId}
              colProps={{
                colCount: 2,
                width: "360px",
                maxWidth: "360px",
                minWidth: "360px",
              }}
            />
          );
        })}
        {children}
      </JustifiedColumnsContainer>
    </div>
  );
};

export default styled(RecommendationTable)`
  position: absolute;
  top: 0;
  left: ${({ xMove }) => xMove ?? 0}px;
  padding-top: 0;
  padding-bottom: 0;
  transition: padding-left 500ms, padding-right 500ms, left 1000ms, right 1000ms,
    opacity 500ms;

  .recommendation-table-preview-layer {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // margin from RecommendationColumn
    margin: 5px 5px 10px 5px;

    position: absolute;
    z-index: ${ZIndex.NORMAL};
    background: ${Color.GREY_DARK_TRANSPARENT};
    opacity: 0;
    transition: opacity 500ms;

    &.grey-foreground {
      cursor: ${({ onPreviewClick }) =>
        onPreviewClick ? "pointer" : "default"};
      opacity: 1;

      z-index: ${ZIndex.FRONT_1};
    }

    .chevron-container {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 50%;
      &.left-chevron-direction {
        right: 5px;
        transform: rotate(90deg);
      }
      &.right-chevron-direction {
        left: 5px;
        transform: rotate(270deg);
      }
    }
  }
`;
