import React from "react";
import styled from "styled-components";
import { Color } from "../../constants/color";
import { StyledComponentProps } from "../../types/default";

const GridPanel: React.FC<StyledComponentProps> = ({ className, children }) => (
  <div className={className}>
    <div className={"grid-panel-content"}>{children}</div>
  </div>
);

export default styled(GridPanel)`
  background: ${Color.GREY_LIGHT4};
  color: ${Color.WHITE};
  padding: 5px 5px 5px 10px;

  .grid-panel-content {
    display: block;
    position: relative;
    padding: 30px 0;
    text-align: center;

    div {
      vertical-align: top;
    }
  }
`;
