import React, { useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled from "styled-components";
import Footer from "../../grid/Footer";
import Pane from "../../grid/Pane";
import Slide from "./Slide";
import calcFooterHeight from "../../../helper/calcFooterHeight";
import { useRefEffect } from "../../../hooks/common/useRefHook";
import { SlideCategoryTypes, SlidePage } from "../../../constants/SlidePages";

interface FormPageProps {
  className?: string;
  slides: SlidePage[];
  urgentSlide?: number;
  navbar: JSX.Element;
  forward: boolean;
}

const FormPage: React.FC<FormPageProps> = ({
  className,
  slides,
  urgentSlide,
  navbar,
}) => {
  const location = useLocation();

  const [urgentSlidePosition, setUrgentSlidePosition] = useState(-1);
  useRefEffect(() => {
    if (urgentSlide) {
      const newUrgentPosition = slides.findIndex((v) => v.id === urgentSlide);
      setUrgentSlidePosition(newUrgentPosition);
    } else {
      setUrgentSlidePosition(-1);
    }
  }, [slides, urgentSlide]);

  return (
    <div className={className}>
      {navbar}
      <div className={"page-content"}>
        <Pane>
          <TransitionGroup className={"page-content-inner-container"}>
            <CSSTransition key={location.key} className="swipe" timeout={1000}>
              <div>
                <React.StrictMode>
                  {urgentSlidePosition !== -1 && (
                    <Slide
                      slide={slides[urgentSlidePosition]}
                      footerHeight={calcFooterHeight()}
                    />
                  )}
                  {urgentSlidePosition === -1 && (
                    <Switch location={location}>
                      {slides.map((slide) => {
                        return (
                          <Route
                            key={slide.id}
                            path={`/slides/${slide.id.toString()}`}
                          >
                            <Slide
                              slide={slide}
                              fullHeight={
                                slide.category ===
                                SlideCategoryTypes.recommendation
                              }
                              footerHeight={calcFooterHeight()}
                            />
                          </Route>
                        );
                      })}
                    </Switch>
                  )}
                </React.StrictMode>
              </div>
            </CSSTransition>
          </TransitionGroup>
          <React.StrictMode>
            {urgentSlidePosition === -1 && <Footer />}
          </React.StrictMode>
        </Pane>
      </div>
    </div>
  );
};

export default styled(FormPage)`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .swipe.enter {
    opacity: 0;
    transform: ${(props) =>
      props.forward ? "translateX(100%)" : "translateX(-100%)"};
  }
  .swipe.enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: transform 1000ms, opacity 1000ms;
  }
  .swipe.exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .swipe.exit-active {
    opacity: 0;
    transform: ${(props) =>
      props.forward ? "translateX(-100%)" : "translateX(100%)"};
    transition: transform 1000ms, opacity 1000ms;
  }
  .page-content-inner-container {
    position: relative;
    display: block;
    height: 100%;
  }
`;
