import React from "react";
import styled from "styled-components";
import logo from "../../images/icon.png";
import { StyledComponentProps } from "../../types/default";

const SpinningLogo: React.FC<StyledComponentProps> = ({ className }) => {
  return (
    <p className={className}>
      <img className={"vodafone-logo"} src={logo} alt="" />
    </p>
  );
};

export default styled(SpinningLogo)`
  width: 100%;
  text-align: center;
  img {
    display: inline-block;
    margin: 30px auto auto;
    animation: App-logo-spin infinite 1s linear;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;
