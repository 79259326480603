import * as React from "react";

function SvgPopcornColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="popcorn_color_svg__a07d6236-ad35-45d0-96b4-aceff9e8d5e9"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".popcorn_color_svg__st0{fill:none;stroke:#e60000;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10}"
        }
      </style>
      <path
        className="popcorn_color_svg__st0"
        d="M59.8 163.8l-16-80M80 163.2l-5-32.4M96 163.2v-32.4M112 163.2l5-32.4M131.8 163.8l16-80M59.8 163.8h72M43.8 83.8h103M71 108.1l-3-24.2M96 108.1V83.9M121 108.1l3-24.2M73 61c0-7.7-3.3-14-11-14-7.6 0-13.8 6-14 13.6-5.8 1.7-11.5 7.2-10 13.4 1.8 7.5 3.2 9.8 9 10M145 84h1.1c7.7 0 9.9-6.3 9.9-14 0-6-3.9-11.4-9.6-13.3 3.2-5.2 1.6-11.9-3.6-15.1s-11.9-1.6-15.1 3.6c-.3.5-.6 1.1-.9 1.7-7.3-2.7-15.3 1.1-18 8.3-.6 1.5-.9 3.2-.9 4.8"
      />
      <path
        className="popcorn_color_svg__st0"
        d="M126 43c-5.5-4.7-10.6-6.6-18.3-7-3.4-7-11.7-10-18.7-6.7-6.3 3-9.5 10.2-7.4 16.9C75.4 52.6 72 61.1 72 70c0 5 1.1 6.7 3 11"
      />
      <path fill="none" d="M0 0h192v192H0z" />
    </svg>
  );
}

export default SvgPopcornColor;
