import { Interest } from "../../types/application";
import { ProductOption } from "../../state/definitions/RecommendationState";
import { FormConfigLabelIds } from "../../data/definitions/FormConfigLabelIds";

// TODO: Überarbeiten

export function mapGigaKombiAdvantages(
  oldSubProductsAdvantages: ProductOption<number[]>[],
  gigaAdvantages: number[],
  type: Interest,
  gigaKombiType: Interest
): ProductOption<number[]>[] {
  let newSubProductsAdvantages: ProductOption<number[]>[] =
    oldSubProductsAdvantages;
  // Add all gigakombiAdvantages to the gigatype-column, except the flatrate
  if (type === gigaKombiType && gigaAdvantages.length > 0) {
    newSubProductsAdvantages = newSubProductsAdvantages.map((v) => {
      const newProductOption = Object.assign({}, v);
      const newLabel = [...newProductOption.label];
      gigaAdvantages.forEach((advantageNumber) => {
        if (advantageNumber !== FormConfigLabelIds.GIGAKOMBI_MOBILE_EURO_FLAT) {
          newLabel.push(advantageNumber);
        }
      });
      newProductOption.label = newLabel;
      return newProductOption;
    });
  }
  // the flatrate gigakombiAdvantage will add to the internet column
  if (type === Interest.FIXED && gigaAdvantages.length > 0) {
    const flatrateAdvantageId = gigaAdvantages.findIndex(
      (v) => v === FormConfigLabelIds.GIGAKOMBI_MOBILE_EURO_FLAT
    );
    if (flatrateAdvantageId > -1) {
      newSubProductsAdvantages = newSubProductsAdvantages.map((v) => {
        const newProductOption = Object.assign({}, v);
        const newLabel = [...newProductOption.label];
        newLabel.push(FormConfigLabelIds.GIGAKOMBI_MOBILE_EURO_FLAT);
        newProductOption.label = newLabel;
        return newProductOption;
      });
    }
  }

  return newSubProductsAdvantages;
}
