import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import AFOLabelCell from "./AFOLabelCell";
import AFOCountCell from "./AFOCountCell";
import AFOPriceCell from "./AFOPriceCell";
import AFODeleteCell from "./AFODeleteCell";
import {
  AdditionalSubProduct,
  AdditionalTableDataObject,
  UnknownTableDataObject,
} from "../../../types/table";
import AFOStartCell from "./AFOStartCell";

export interface AFOTableBaseProps<
  TableEntry extends UnknownTableDataObject = UnknownTableDataObject
> {
  className?: string;
  headers?: string[];
  data?: TableEntry[];
  additionalDataObject?: AdditionalSubProduct;
  defaultDataObject?: TableEntry;
  mainProductPrice?: number;
}

export interface AFOTableChanger {
  changeLabelId: (newLabelId: number, objectId: number) => void;
  changeLabelValue: (newValue: string, objectId: number) => void;

  changeStartId: (newStartId: number, objectId: number) => void;
  changeStartValue: (newValue: number, objectId: number) => void;

  changeCountId: (newCountId: number, objectId: number) => void;
  changeCountValue: (newValue: number, objectId: number) => void;

  changePriceId: (newPriceId: number, objectId: number) => void;
  changePriceValue: (newValue: number, objectId: number) => void;
}

interface AFOTableProps extends AFOTableBaseProps, AFOTableChanger {}

const AFOTable: React.FC<AFOTableProps> = ({
  className,
  headers = [],
  data = [],
  additionalDataObject,
  defaultDataObject,
  mainProductPrice = 0,
  changeLabelId,
  changeCountId,
  changePriceId,
  changeLabelValue,
  changeCountValue,
  changePriceValue,
  changeStartId,
  changeStartValue,
}) => {
  const openSelectionIdState = useState(-1);
  const showPercentValueIdState = useState(-1);
  const [showPercentValueId] = showPercentValueIdState;
  const [tableHeaders, setTableHeaders] = useState(headers);

  useEffect(() => {
    if (additionalDataObject !== undefined) {
      const newTableHeaders = Object.assign([], headers);
      if (showPercentValueId !== -1) {
        newTableHeaders.splice(-2, 0, "Prozente");
      }
      setTableHeaders(newTableHeaders);
    }
  }, [showPercentValueId, headers, additionalDataObject]);

  return (
    <table className={className}>
      <thead>
        <tr key={-1} className={"row"}>
          {tableHeaders.length > 0 &&
            tableHeaders.map((v, i) => <th key={i}>{v}</th>)}
        </tr>
      </thead>
      <tbody>
        {data.map((d, dataId) => {
          return (
            <tr key={dataId} className={`row row${tableHeaders.length}cells`}>
              <AFOLabelCell
                dataset={d}
                dataId={dataId}
                openSelectionIdState={openSelectionIdState}
                changeLabelId={changeLabelId}
                changeLabelValue={changeLabelValue}
              />
              <AFOStartCell
                additionalDataObject={additionalDataObject}
                dataset={d as AdditionalTableDataObject}
                dataId={dataId}
                openSelectionIdState={openSelectionIdState}
                changeStartId={changeStartId}
                changeStartValue={changeStartValue}
              />
              <AFOCountCell
                dataset={d}
                dataId={dataId}
                openSelectionIdState={openSelectionIdState}
                changeCountId={changeCountId}
                changeCountValue={changeCountValue}
              />
              <AFOPriceCell
                dataset={d}
                dataId={dataId}
                mainProductPrice={mainProductPrice}
                showPercentValueIdState={showPercentValueIdState}
                openSelectionIdState={openSelectionIdState}
                changePriceId={changePriceId}
                changePriceValue={changePriceValue}
                additionalDataObject={additionalDataObject}
              />
              <AFODeleteCell
                additionalDataObject={additionalDataObject}
                defaultAdditionalDataObject={defaultDataObject}
                dataset={d}
                dataId={dataId}
                changeLabelValue={changeLabelValue}
                changeStartValue={changeStartValue}
                changeCountId={changeCountId}
                changePriceValue={changePriceValue}
              />
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default styled(AFOTable)`
  width: 100%;
  display: table;
  border-spacing: 2px;
  border-collapse: separate;
  color: ${Color.BLACK};
  margin: 0 auto;

  .row {
    width: 100%;
    display: table-row;

    th {
      :first-of-type {
        text-align: left;
      }
      background: ${Color.GREY};
      padding: 5px;
      color: ${Color.WHITE};
    }

    .cell {
      display: table-cell;
      border: 1px ${Color.BLACK} solid;
      vertical-align: middle;
      height: 35px;
      .cell-content {
        padding: 5px;
      }
    }

    &.row2cells {
      .cell {
        width: 50%;
      }
    }
    &.row3cells {
      .cell {
        width: calc(100% / 3);
      }
    }
    &.row4cells {
      .cell {
        width: calc(95% / 4);
      }
    }
    &.row5cells {
      .cell {
        width: calc(95% / 5);
      }
    }
    &.row6cells {
      .cell {
        width: calc(95% / 6);
      }
    }
  }
`;
