import {
  ContractSummary,
  Interest,
  OptimizeContractData,
} from "../../types/application";

export class MobileHumanContracts {
  mobileFirstHumanContractId: number = 0;
  mobileSecondHumanContractId: number = 0;
  mobileThirdHumanContractId: number = 0;
  mobileFourthHumanContractId: number = 0;
  mobileFifthHumanContractId: number = 0;
}

class MobileFormStateNumbers extends MobileHumanContracts {
  providerMobileId: number = 0;
  mobileCurrentPrice: number = 0;
  mobileCurrentDataVolume: number = 0;
  mobileDataVolume: number = 0;
  mobileDataSharePeople: number = 0;
  mobileDataShareKids: number = 0;
  mobileDevicesTablet: number = 0;
  mobileDevicesSmartwatch: number = 0;
  mobileDevicesIOT: number = 0;
  mobileDevicesSmartphone: number = 0;
}

class IotFormStateNumbers {
  providerIotId: number = 0;
  iotCurrentPrice: number = 0;
}

export class FixedFormStateDeviceCounts {
  fixedDevicesLaptop: number = 0;
  fixedDevicesSmartphone: number = 0;
  fixedDevicesTablet: number = 0;
  fixedDevicesPC: number = 0;
  fixedDevicesPhone: number = 0;
  fixedDevicesTV: number = 0;
  fixedDevicesSmartHome: number = 0;
  fixedDevicesConsole: number = 0;
  fixedDevicesSmartwatch: number = 0;
  fixedDevicesIOT: number = 0;
}

class FixedFormStateNumbers extends FixedFormStateDeviceCounts {
  providerFixedId: number = 0;
  fixedCurrentPrice: number = 0;
  fixedCurrentBandwidth: number = 0;
}

class TVFormStateNumbers {
  providerTvId: number = 0;
  tvCurrentPrice: number = 0;
  additionalCostInvoice: number = 0;
}

export interface FormStateNumbers
  extends MobileFormStateNumbers,
    IotFormStateNumbers,
    FixedFormStateNumbers,
    TVFormStateNumbers {}

export class MobileHumanNames {
  mobileFirstHumanContractName: string = "Ich";
  mobileSecondHumanContractName: string = "";
  mobileThirdHumanContractName: string = "";
  mobileFourthHumanContractName: string = "";
  mobileFifthHumanContractName: string = "";
}

class MobileFormStateStrings extends MobileHumanNames {
  mobileCurrentContractEnd: string = "";
}

class FixedFormStateStrings {
  fixedContractCurrentEnd: string = "";
}

class TVFormStateStrings {
  tvContractCurrentEnd: string = "";
}

export interface FormStateStrings
  extends MobileFormStateStrings,
    FixedFormStateStrings,
    TVFormStateStrings {
  // profile page
  profileNotices: string;
}

class MobileFormStateBooleans {
  needAdditionalDataVolume: boolean = false;
  mobileDataSocial: boolean = false;
  mobileDataMusic: boolean = false;
  mobileDataChat: boolean = false;
  mobileDataVideo: boolean = false;
  mobileDataGaming: boolean = false;
  mobileWithoutCurrentContractEnd: boolean = false;
}

class FixedFormStateBooleans {
  fixedWithoutCurrentContractEnd: boolean = false;
}

class TVFormStateBooleans {
  tvWithoutCurrentContractEnd: boolean = false;
}

class IOTFormStateBooleans {
  iotSmartWatchOwnDevice: boolean = false;
  iotTrackerOwnDevice: boolean = false;
  iotCameraOwnDevice: boolean = false;
}

export interface FormStateBooleans
  extends MobileFormStateBooleans,
    FixedFormStateBooleans,
    TVFormStateBooleans,
    IOTFormStateBooleans {
  withRegioDiscount: boolean;
}

class MobileFormStateNumberArrays {
  mobilePersonalDetails: number[] = [];
  mobilePreferencesSmartphone: number[] = [];
  mobilePreferencesDataVolume: number[] = [];
  mobilePreferencesPrice: number[] = [];
  mobileFirstHumanProductCounts: number[] = [];
  mobileSecondHumanProductCounts: number[] = [];
  mobileThirdHumanProductCounts: number[] = [];
  mobileFourthHumanProductCounts: number[] = [];
  mobileFifthHumanProductCounts: number[] = [];
}

class IotFormStateNumberArrays {
  iotInterests: number[] = [];
  iotSmartWatchLabels: number[] = [];
  iotTrackerLabels: number[] = [];
}

class FixedFormStateNumberArrays {
  fixedPreferences: number[] = [];
  fixedCallDestination: number[] = [];
}

class TVFormStateNumberArrays {
  tvTechnology: number[] = [];
  tvPreference: number[] = [];
}

class StateNumberArrays {
  independentPersonalDetails: number[] = [];
}

export interface FormStateNumberArrays
  extends MobileFormStateNumberArrays,
    IotFormStateNumberArrays,
    FixedFormStateNumberArrays,
    TVFormStateNumberArrays,
    StateNumberArrays {}

export interface FormStateArrays extends FormStateNumberArrays {}

export interface FormStateCalculatedValues
  extends FormStateCategoryHasData,
    FormStateCategoryHasChanged {
  interests: Interest[];
  contractData: OptimizeContractData[];
  contractsCount: number;
  contractCounts: ContractSummary;
  fixedDeviceCount: number;
  deviceNeededBandwidth: number;
}

export interface FormStateCategoryHasData {
  mobileHasData: boolean;
  iotHasData: boolean;
  fixedHasData: boolean;
  tvHasData: boolean;
}

export interface FormStateCategoryHasChanged {
  mobileHasChanged: boolean;
  iotHasChanged: boolean;
  fixedHasChanged: boolean;
  tvHasChanged: boolean;
}

export type FormState = FormStateStrings &
  FormStateNumbers &
  FormStateBooleans &
  FormStateArrays &
  FormStateCalculatedValues;

type MobileFormStateType = MobileFormStateNumbers &
  MobileFormStateStrings &
  MobileFormStateBooleans &
  MobileFormStateNumberArrays;

export const MobileFormState: MobileFormStateType = Object.assign(
  {},
  new MobileFormStateNumbers(),
  new MobileFormStateStrings(),
  new MobileFormStateBooleans(),
  new MobileFormStateNumberArrays()
);

type IotFormStateType = IOTFormStateBooleans &
  IotFormStateNumbers &
  IotFormStateNumberArrays;

export const IotFormState: IotFormStateType = Object.assign(
  {},
  new IOTFormStateBooleans(),
  new IotFormStateNumbers(),
  new IotFormStateNumberArrays()
);

type FixedFormStateType = FixedFormStateNumbers &
  FixedFormStateStrings &
  FixedFormStateBooleans &
  FixedFormStateNumberArrays;

export const FixedFormState: FixedFormStateType = Object.assign(
  {},
  new FixedFormStateNumbers(),
  new FixedFormStateStrings(),
  new FixedFormStateBooleans(),
  new FixedFormStateNumberArrays()
);

type TVFormStateType = TVFormStateNumbers &
  TVFormStateStrings &
  TVFormStateNumberArrays;

export const TVFormState: TVFormStateType = Object.assign(
  {},
  new TVFormStateNumbers(),
  new TVFormStateStrings(),
  new TVFormStateBooleans(),
  new TVFormStateNumberArrays()
);
