import React from "react";
import styled from "styled-components";
import { StyledComponentProps } from "../../../../types/default";
import PrintPlusColumn from "./column/PrintPlusColumn";
import RecommendationPrintColumnContainer from "./column/RecommendationPrintColumnContainer";

export interface RecommendationPrintTableProps extends StyledComponentProps {
  offerEntryIds: string[];
  useEndPlusColumn?: boolean;
}

const RecommendationPrintTable: React.FC<RecommendationPrintTableProps> = ({
  className,
  offerEntryIds,
  children,
  useEndPlusColumn = false,
}) => {
  return (
    <div className={className}>
      {offerEntryIds.map((offerEntryId, index) => {
        return (
          <React.Fragment key={offerEntryId}>
            <RecommendationPrintColumnContainer offerEntryId={offerEntryId} />
            {(index + 1 < offerEntryIds.length || useEndPlusColumn) && (
              <PrintPlusColumn />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default styled(RecommendationPrintTable)`
  display: table;
  width: 100%;
`;
