import React, { useState } from "react";
import styled from "styled-components";
import TileButton from "./TileButton";
import { FormOption } from "../../state/definitions/FormConfigState";
import { useRefEffect } from "../../hooks/common/useRefHook";

export type CheckBoxExtraHandlerFct = (newValue: boolean) => boolean;

interface TileCheckboxListProps {
  className?: string;
  title: string;
  data: FormOption[];
  values: number[];
  onChange: (values: number[]) => void;
  checkboxExtraHandler?: Array<CheckBoxExtraHandlerFct | undefined>;
}

const TileCheckboxList: React.FC<TileCheckboxListProps> = ({
  className,
  title,
  data,
  values,
  onChange,
  checkboxExtraHandler = [],
}) => {
  const [selectedValues, setSelectedValues] = useState(values);

  const toggleValue = (value: number, index: number) => {
    setSelectedValues((prev) => {
      const isUnselected = prev.includes(value);
      let willBeChanged = isUnselected;
      const extraHandler = checkboxExtraHandler[index];
      if (extraHandler !== undefined) {
        willBeChanged = !extraHandler(!isUnselected);
      }

      if (willBeChanged) {
        return prev.filter((item) => item !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const selectedValuesDependency = selectedValues.join("");
  const valuesDependency = values.join("");

  useRefEffect(() => {
    if (selectedValuesDependency !== values.join("")) {
      onChange(selectedValues);
    }
  }, [valuesDependency, selectedValuesDependency]);

  return (
    <div key={-1} className={className}>
      <TileButton key={-1} main={true} value={false} onChange={() => {}}>
        {title}
      </TileButton>
      {data.map(({ value, label }, index) => {
        return (
          <TileButton
            key={value}
            value={selectedValues.includes(value)}
            onChange={() => toggleValue(value, index)}
          >
            {label}
          </TileButton>
        );
      })}
    </div>
  );
};

export default styled(TileCheckboxList)``;
