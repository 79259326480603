import { FixedFormStateDeviceCounts } from "../../state/definitions/FormState";
import { getIcons } from "../../icons/Icons";
import { useFormState, useFormStateMultiple } from "../useForm";
import useBootstrap from "../useBootstrap";
import { FormOption } from "../../state/definitions/FormConfigState";
import { useRefEffect } from "../common/useRefHook";
import {
  BandwidthDeviceMap,
  FixedDeviceKeys,
  MAX_DEVICE_BANDWIDTH,
  MAX_USEFUL_DEVICE_COUNT,
} from "../../constants/Devices";
import { CountObject } from "../../types/application";

export const useDevicesCount = () => {
  const { formConfig } = useBootstrap();
  const countStates = useFormStateMultiple(FixedDeviceKeys);

  function getDeviceCount(
    key: keyof FixedFormStateDeviceCounts,
    labelId: number
  ): CountObject {
    const [count, setCount] = countStates[key];
    const fixedDevice: FormOption | undefined =
      formConfig.fixedDeviceTypes.find((v) => v.value === labelId);
    const icons = getIcons(fixedDevice ? fixedDevice.value : -1);
    return {
      labelKey: fixedDevice ? fixedDevice.label : "",
      count: count ? count : 0,
      icon: icons[0],
      setter: setCount,
    };
  }

  const countValues = Object.values(countStates);
  const [deviceCount = 0, setDeviceCount] = useFormState("fixedDeviceCount");
  const [deviceNeededBandwidth = 0, setDeviceNeededBandwidth] = useFormState(
    "deviceNeededBandwidth"
  );

  useRefEffect(() => {
    let newDeviceCount = 0;
    let newDeviceNeededBandwidth = 0;
    FixedDeviceKeys.forEach((key, i) => {
      const [keyCount] = countValues[i];
      if (keyCount) {
        newDeviceCount += keyCount;
        newDeviceNeededBandwidth += keyCount * BandwidthDeviceMap[key];
      }
    });
    setDeviceCount(newDeviceCount);
    if (
      newDeviceCount < MAX_USEFUL_DEVICE_COUNT &&
      newDeviceNeededBandwidth < MAX_DEVICE_BANDWIDTH
    ) {
      setDeviceNeededBandwidth(newDeviceNeededBandwidth);
    } else {
      setDeviceNeededBandwidth(MAX_DEVICE_BANDWIDTH);
    }
  }, [...countValues.map((v) => v[0])]);

  return {
    getDeviceCount,
    deviceCount,
    deviceNeededBandwidth,
  };
};
