import * as React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import SVGIcon from "../../svgIcon/SVGIcon";
import { SVGComponent } from "../../../types/default";

export interface NewsPanelItemProps {
  color?: string;
  text?: string;
  icon?: SVGComponent;
  className?: string;
  onClick?: () => void;
}

const NewsPanelItem: React.FC<NewsPanelItemProps> = (props) => {
  return (
    <div className={props.className} onClick={props.onClick}>
      {props.icon && <SVGIcon src={props.icon} size={40} />}
      {props.text && <p>{props.text}</p>}
    </div>
  );
};

export default styled(NewsPanelItem)`
  margin-top: 30px;
  background: ${({ color }) => (color ? color : Color.GREY)};
  height: 50px;
  line-height: 50px;
  img, svg {
    vertical-align: middle;
    margin-left:5px;
  }
  p {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 25px;
    margin-left: 5px;
    color: ${Color.WHITE}
`;
