import * as React from "react";

function SvgUnlimitedGrey(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="unlimited_grey_svg__ICON"
      x={0}
      y={0}
      viewBox="0 0 192 192"
    >
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="8"
        d="M84 156.25h24"
      />
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="1.25"
        stroke-width="8"
        d="M68 20h72v136c0 8.844-7.156 16-16 16H68c-8.844 0-16-7.156-16-16V36c0-8.844 7.156-16 16-16z"
      />
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="1.25"
        stroke-width="8"
        d="M95.75 82.344c2.344-2.406 3.625-3.625 3.625-3.625 3.75-3.625 7-6.469 11.938-6.469 6.781 0 12.062 4.688 12.062 10.469s-5.281 10.469-12.062 10.469c-7.001 0-11.188-5.344-15.563-10.844M95.75 82.344c-4.375 5.5-8.562 10.844-15.562 10.844-6.781 0-12.062-4.688-12.062-10.469s5.28-10.469 12.062-10.469c4.938 0 8.188 2.844 11.938 6.469-.001 0 1.28 1.219 3.624 3.625"
      />
    </svg>
  );
}

export default SvgUnlimitedGrey;
