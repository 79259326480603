import React from "react";
import styled from "styled-components";
import { Color } from "../../../../constants/color";
import PanelGroup from "../../../panel/PanelGroup";
import PanelGroupPrintTitle from "../../../panel/PanelGroupPrintTitle";
import PanelPrintItem from "../../../panel/PanelPrintItem";
import PanelPrintItemLabel from "../../../panel/PanelPrintItemLabel";
import PanelPrintItemValue from "../../../panel/PanelPrintItemValue";
import PanelPrint from "../../../panel/PanelPrint";
import { PriceUnits, TableDataObject } from "../../../../types/table";
import {
  getFormattedSimpleSubProductData,
  SimpleSubProductData,
} from "../../../../helper/simpleSubProductData";
import useSubProductSimpleData from "../../../../hooks/helper/useSubProductSimpleData";

interface SubProductPanelProps {
  className?: string;
  data: TableDataObject[];
  standardData?: TableDataObject;
  mainLabel: string;
  mainValue?: number;
  mainPriceUnit?: PriceUnits;
  dataFilter?: (d: SimpleSubProductData, i?: number) => boolean;
}

const SubProductPanel: React.FC<SubProductPanelProps> = ({
  className,
  data,
  mainLabel,
  mainValue,
  standardData,
  mainPriceUnit = PriceUnits.euro,
  dataFilter,
}) => {
  const filteredData = useSubProductSimpleData(data, standardData, dataFilter);

  return (
    <div className={className}>
      <PanelGroup
        margin={"0"}
        borderColor={Color.GREY_LIGHT2}
        title={
          <PanelGroupPrintTitle
            label={mainLabel}
            value={
              mainValue !== undefined
                ? mainValue.toFixed(2) + " " + mainPriceUnit
                : undefined
            }
          />
        }
      >
        <PanelPrint backgroundColor={Color.WHITE} fontColor={Color.BLACK}>
          {filteredData.map((d, dataId) => {
            const formatted = getFormattedSimpleSubProductData(d);
            return (
              <PanelPrintItem key={dataId}>
                <PanelPrintItemLabel>
                  {formatted.labelString}
                  {formatted.countString}
                </PanelPrintItemLabel>
                <PanelPrintItemValue>
                  {formatted.priceString}
                </PanelPrintItemValue>
              </PanelPrintItem>
            );
          })}
        </PanelPrint>
      </PanelGroup>
    </div>
  );
};

export default styled(SubProductPanel)`
  width: 100%;
  color: ${Color.BLACK};
  margin: 0 auto;
`;
