import React from "react";
import styled from "styled-components";
import PanelItem from "../../panel/PanelItem";
import PanelValue from "../../panel/PanelValue";
import GreyIcons from "../../../icons/GreyIcons";
import { PersonalHomeSituation } from "../../../types/profile";

interface PanelItemsPersonalHomeSituationProps {
  className?: string;
  importants: PersonalHomeSituation;
}

const PanelItemsPersonalHomeSituation: React.FC<PanelItemsPersonalHomeSituationProps> =
  ({ importants }) => (
    <>
      {importants.adultCount !== undefined && importants.adultCount > 0 && (
        <PanelItem icon={GreyIcons.person}>
          Erwachsene: <PanelValue>{"1 +" + importants.adultCount}</PanelValue>
          {importants.adultNames ? " (" + importants.adultNames + ")" : ""}
        </PanelItem>
      )}
      {importants.kidCount !== undefined && importants.kidCount > 0 && (
        <PanelItem icon={GreyIcons.children}>
          Kinder: <PanelValue>{importants.kidCount}</PanelValue>
          {importants.kidNames ? " (" + importants.kidNames + ")" : ""}
        </PanelItem>
      )}
      {importants.personCount !== undefined && importants.personCount > 0 && (
        <PanelItem icon={GreyIcons.family}>
          Anzahl Personen Gesamt:{" "}
          <PanelValue>{importants.personCount + 1}</PanelValue>
        </PanelItem>
      )}
    </>
  );

export default styled(PanelItemsPersonalHomeSituation)``;
