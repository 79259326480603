import React from "react";
import JustifiedColumnsContainer from "../grid/JustifiedColumnsContainer";
import Column from "../grid/Column";
import ImageWithIcons from "../elements/ImageWithIcons";
import TileButton from "../form/TileButton";
import NumberSelector from "../form/horizontalSelector/NumberSelector";

import { Color } from "../../constants/color";
import Icons from "../../icons/Icons";
import { getImageSet } from "../../images";
import { useFormState } from "../../hooks/useForm";

const MobileDevicesPage = () => {
  const [mobileDevicesTablet, setMobileDevicesTablet] = useFormState(
    "mobileDevicesTablet"
  );
  const [mobileDevicesSmartwatch, setMobileDevicesSmartwatch] = useFormState(
    "mobileDevicesSmartwatch"
  );
  const [mobileDevicesIOT, setMobileDevicesIOT] = useFormState(
    "mobileDevicesIOT"
  );
  const [mobileDevicesSmartphone, setMobileDevicesSmartphone] = useFormState(
    "mobileDevicesSmartphone"
  );

  const getColor = (count: number | undefined) => {
    if (!count) return Color.GREY2;
    return count > 0 ? Color.RED : Color.GREY2;
  };

  return (
    <JustifiedColumnsContainer>
      <Column colCount={4} minWidth={"200px"} maxWidth={"25%"}>
        <ImageWithIcons imageSet={getImageSet(246)} icons={[Icons.tablet]} />
        <TileButton
          color={getColor(mobileDevicesTablet)}
          value={!!mobileDevicesTablet}
          onChange={() => {}}
        >
          Tablet
        </TileButton>
        <NumberSelector
          currentValue={mobileDevicesTablet ? mobileDevicesTablet : 0}
          max={5}
          min={0}
          setValue={setMobileDevicesTablet}
          selected
          color={getColor(mobileDevicesTablet)}
        />
      </Column>
      <Column colCount={4} minWidth={"200px"} maxWidth={"25%"}>
        <ImageWithIcons
          imageSet={getImageSet(247)}
          icons={[Icons.smartwatch]}
        />
        <TileButton
          color={getColor(mobileDevicesSmartwatch)}
          value={!!mobileDevicesSmartwatch}
          onChange={() => {}}
        >
          Smartwatch
        </TileButton>
        <NumberSelector
          currentValue={mobileDevicesSmartwatch ? mobileDevicesSmartwatch : 0}
          max={5}
          min={0}
          setValue={setMobileDevicesSmartwatch}
          selected
          color={getColor(mobileDevicesSmartwatch)}
        />
      </Column>
      <Column colCount={4} minWidth={"200px"} maxWidth={"25%"}>
        <ImageWithIcons imageSet={getImageSet(248)} icons={[Icons.iot]} />
        <TileButton
          color={getColor(mobileDevicesIOT)}
          value={!!mobileDevicesIOT}
          onChange={() => {}}
        >
          IoT (Internet of Things)
        </TileButton>
        <NumberSelector
          currentValue={mobileDevicesIOT ? mobileDevicesIOT : 0}
          max={5}
          min={0}
          setValue={setMobileDevicesIOT}
          selected
          color={getColor(mobileDevicesIOT)}
        />
      </Column>
      <Column colCount={4} minWidth={"200px"} maxWidth={"25%"}>
        <ImageWithIcons imageSet={getImageSet(249)} icons={[Icons.mobile]} />
        <TileButton
          color={getColor(mobileDevicesSmartphone)}
          value={!!mobileDevicesSmartphone}
          onChange={() => {}}
        >
          Smartphone
        </TileButton>
        <NumberSelector
          currentValue={mobileDevicesSmartphone ? mobileDevicesSmartphone : 0}
          max={5}
          min={0}
          setValue={setMobileDevicesSmartphone}
          selected
          color={getColor(mobileDevicesSmartphone)}
        />
      </Column>
    </JustifiedColumnsContainer>
  );
};

export default MobileDevicesPage;
