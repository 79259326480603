import React from "react";
import styled from "styled-components";
import JustifiedColumnsContainer from "../grid/JustifiedColumnsContainer";
import PanelGroup from "../panel/PanelGroup";
import PanelGroupTitle from "../panel/PanelGroupTitle";
import Panel from "../panel/Panel";
import PanelTitle from "../panel/PanelTitle";
import { Color } from "../../constants/color";
import Column from "../grid/Column";
import useProfile from "../../hooks/useFormHelper/useProfile";
import PanelItemsMobileImportant from "../elements/profile/PanelItemsMobileImportant";
import PanelItemsFixedImportant from "../elements/profile/PanelItemsFixedImportant";
import PanelItemTVImportant from "../elements/profile/PanelItemTVImportant";
import PanelItemsMobileSituation from "../elements/profile/PanelItemsMobileSituation";
import PanelItemsTVSituation from "../elements/profile/PanelItemsTVSituation";
import PanelItemsFixedSituation from "../elements/profile/PanelItemsFixedSituation";
import PanelItemsPersonalSituation from "../elements/profile/PanelItemsPersonalSituation";
import PanelItemsPersonalHomeSituation from "../elements/profile/PanelItemsPersonalHomeSituation";
import Icons from "../../icons/Icons";
import { useFormState } from "../../hooks/useForm";
import NoticePanel from "../panel/NoticePanel";
import { StyledComponentProps } from "../../types/default";
import PanelItemsIotSituation from "../elements/profile/PanelItemsIotSituation";
import PanelItemsPriceSituation from "../elements/profile/PanelItemsPriceSituation";

const ProfilePage: React.FC<StyledComponentProps> = ({ className }) => {
  const {
    data,
    thereArePriceInformation,
    thereAreHomeInformation,
    thereArePersonalInformation,
  } = useProfile();
  const [notices, setNotices] = useFormState("profileNotices");
  const onChange = (newValue: string) => {
    setNotices(newValue);
  };

  return (
    <div className={className}>
      <JustifiedColumnsContainer>
        <Column colCount={2}>
          <PanelGroup title={<PanelGroupTitle value={"Aktuelle Situation:"} />}>
            {data.mobile.interesting && (
              <Panel>
                <PanelTitle>Mobilfunk</PanelTitle>
                <PanelItemsMobileSituation situation={data.mobile} />
              </Panel>
            )}
            {data.fixed.interesting && (
              <Panel>
                <PanelTitle>Internet & Festnetz</PanelTitle>
                <PanelItemsFixedSituation situation={data.fixed} />
              </Panel>
            )}
            {data.tv.interesting && (
              <Panel>
                <PanelTitle>TV</PanelTitle>
                <PanelItemsTVSituation situation={data.tv} />
              </Panel>
            )}
            {data.iot.interesting && (
              <Panel>
                <PanelTitle>IoT</PanelTitle>
                <PanelItemsIotSituation situation={data.iot} />
              </Panel>
            )}
            {thereArePriceInformation && (
              <Panel>
                <PanelTitle>Deine aktuellen Kosten</PanelTitle>
                <PanelItemsPriceSituation situation={data.price} />
              </Panel>
            )}
            {thereAreHomeInformation && (
              <Panel>
                <PanelTitle>Dein Haushalt</PanelTitle>
                <PanelItemsPersonalHomeSituation
                  importants={data.personalHome}
                />
              </Panel>
            )}
            {thereArePersonalInformation && (
              <Panel>
                <PanelTitle>Du bist</PanelTitle>
                <PanelItemsPersonalSituation importants={data.personal} />
              </Panel>
            )}

            {!data.fixed.interesting &&
              !data.mobile.interesting &&
              !data.tv.interesting &&
              !data.iot.interesting &&
              !thereAreHomeInformation &&
              !thereArePersonalInformation && (
                <Panel>
                  <PanelTitle>
                    Sie haben kein Interesse an Vodafone-Produkten!
                  </PanelTitle>
                </Panel>
              )}
          </PanelGroup>
        </Column>
        <Column colCount={2}>
          <PanelGroup
            titleBackgroundColor={Color.RED}
            fontColor={Color.WHITE}
            title={<PanelGroupTitle value={"Das ist Dir wichtig:"} />}
          >
            <Panel
              backgroundColor={Color.RED}
              fontColor={Color.WHITE}
              fontWeight={"bold"}
            >
              <PanelItemsMobileImportant
                iconSet={Icons}
                importants={data.important.mobile}
              />
              <PanelItemsFixedImportant
                iconSet={Icons}
                importants={data.important.fixed}
              />
              <PanelItemTVImportant
                iconSet={Icons}
                importants={data.important.tv}
              />
            </Panel>
          </PanelGroup>
          <PanelGroup
            titleBackgroundColor={Color.GREY_LIGHT}
            backgroundColor={Color.GREY_LIGHT}
            fontColor={Color.WHITE}
            title={
              <PanelGroupTitle
                value={"Notizen zu unserer Empfehlung"}
                subValue={""}
              />
            }
          >
            <NoticePanel notices={notices} onChange={onChange} />
          </PanelGroup>
        </Column>
      </JustifiedColumnsContainer>
    </div>
  );
};

export default styled(ProfilePage)``;
