import plus from "./svgComponents_color/PlusColor";
import social from "./svgComponents_color/SocialColor";

import chat from "./svgComponents_color/ChatColor2";
import chat2 from "./svgComponents_color/ChatColor";
import gaming from "./svgComponents_color/GamingColor2";
import gaming2 from "./svgComponents_color/GamingColor";
import video from "./svgComponents_color/VideoColor2";
import video2 from "./svgComponents_color/VideoColor";
import music from "./svgComponents_color/MusicColor2";
import music2 from "./svgComponents_color/MusicColor";

import checkCircle from "./svgComponents_color/CheckCircleColor";
import moreMoney from "./svgComponents_color/MoreMoneyColor";
import person from "./svgComponents_color/PersonColor";
import ping from "./svgComponents_color/PingColor";
import popcorn from "./svgComponents_color/PopcornColor";
import privacy from "./svgComponents_color/PrivacyColor";
import privateHd from "./svgComponents_color/PrivateHdColor";
import sim from "./svgComponents_color/SimColor";
import tvRefresh from "./svgComponents_color/TvRefreshColor";
import tvTv from "./svgComponents_color/TvTvColor";
import windowedApps from "./svgComponents_color/WindowedAppsColor";
import world from "./svgComponents_color/WorldColor";
import accelerometerHalf from "./svgComponents_color/AccelerometerHalfColor";
import apps from "./svgComponents_color/AppsColor";
import backspace from "./svgComponents_color/BackspaceColor";
import boosting from "./svgComponents_color/BoostingColor";
import calculator from "./svgComponents_color/CalculatorColor";
import landline from "./svgComponents_color/FixedLineColor";
import cloudDownload from "./svgComponents_color/CloudDownloadColor";
import devices from "./svgComponents_color/DevicesColor";
import entertainment from "./svgComponents_color/EntertainmentColor";
import eu from "./svgComponents_color/EuColor";
import euro from "./svgComponents_color/EuroColor";
import mediaLibrary from "./svgComponents_color/MediaLibraryColor";
import mobileSecurity from "./svgComponents_color/MobileSecurityColor";
import money from "./svgComponents_color/MoneyColor";
import mobile from "./svgComponents_color/MobileColor";
import unlimited from "./svgComponents_color/UnlimitedColor";
import info from "./svgComponents_color/InfoColor";
import info2 from "./svgComponents_color/InfoColor2";
import close from "./svgComponents_color/CloseColor";
import hookCircle from "./svgComponents_color/HookCircle";

const ColorIcons = {
  chat,
  chat2,
  social,
  video,
  video2,
  music,
  music2,
  gaming,
  gaming2,

  plus: plus,
  checkCircle,
  moreMoney,
  person,
  ping,
  popcorn,
  privacy,
  privateHd,
  sim,
  tvRefresh,
  tvTV: tvTv,
  windowedApps,
  world,
  accelerometerHalf,
  apps,
  backspace,
  boosting,
  calculator,
  landline: landline,
  cloudDownload,
  devices,
  entertainment,
  eu,
  euro,
  mediaLibrary,
  mobileSecurity,
  money,
  mobile,
  unlimited,
  info,
  info2,
  close,
  hookCircle,
};

export default ColorIcons;
