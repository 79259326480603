import React from "react";
import styled from "styled-components";
import { TableDataObject } from "../../../types/table";
import AFOInputCell from "./AFOInputCell";
import { MONTH_MAX_LENGTH } from "../../../constants/default";

interface AFOCountTextInputProps {
  className?: string;
  value: number;
  dataSet: TableDataObject;
  changeValue: (newValue: number) => void;
}

const AFOCountInputCell: React.FC<AFOCountTextInputProps> = ({
  className,
  dataSet,
  value,
  changeValue,
}) => {
  return (
    <AFOInputCell
      extraClassName={className}
      changeValue={changeValue}
      realValue={value}
      defaultValue={0}
      min={0}
      max={MONTH_MAX_LENGTH}
      step={1}
      colSpan={dataSet.counts[dataSet.countId] !== undefined ? 1 : 2}
    >
      <span className={"currency-symbol"}>{dataSet.countUnit}</span>
    </AFOInputCell>
  );
};

export default styled(AFOCountInputCell)`
  .cell-input {
    .number-input {
      width: calc(50% - 7px);
      padding: 5px 2px 5px 5px;

      text-align: right;
    }
    .currency-symbol {
      display: inline-block;
      width: calc(50% + 7px);
      text-align: left;
    }
  }
`;
