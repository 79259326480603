import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import NewsPanelItemContainer from "./NewsPanelItemContainer";
import NewsPanelItemInner from "./NewsPanelItemInner";
import { StyledComponentProps } from "../../../types/default";
import { OuterLinks } from "../../../data/definitions/OuterLinks";

export interface NewsPanelProps extends StyledComponentProps {}

const NewsPanel = (props: NewsPanelProps) => {
  const [myTime] = useState(() => new Date().getTime());

  // 12.12.2022
  if (myTime > 1670799600000) {
    return null;
  }

  return (
    <div className={props.className}>
      <NewsPanelItemContainer
        title={"News"}
        link={OuterLinks.DEFAULT_NEWS}
        linkText={"Detaillierte Infos findest Du in der Salesworld"}
      >
        <NewsPanelItemInner
          title={"Wusstest Du schon?..."}
          text={"Im DES 2.0 gibt es neue Features:"}
          // useNumbers
          items={[
            "Frage nach selbstständiger/geschäftlicher oder privater Nutzung am Anfang der Bedarfsanalyse",
            "Auswahl von Bestandskund:innen auf der Interessensseite",
            "Integration von SuperWLAN in der Festnetz-Bedarfsanalyse",
            "Optimierung einiger Klickpfade",
            "Integration von Re-Trade inkl. Verlinkung",
            "Recyclinghinweis",
          ]}
        />
      </NewsPanelItemContainer>
    </div>
  );
};

export default styled(NewsPanel)`
  right: 0;
  display: block;
  position: absolute;
  min-width: 150px;
  max-width: 300px;
  margin-top: 20px;
`;
