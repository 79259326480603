import React from "react";
import styled from "styled-components";
import Icons from "../../icons/Icons";
import { Color } from "../../constants/color";
import SVGIcon from "../svgIcon/SVGIcon";
import { StyledComponentProps } from "../../types/default";

export interface IButtonProps extends StyledComponentProps {
  onChange: (value: boolean) => void;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  size?: number;
  alt?: string;
  color?: string;
  value?: boolean;
  backgroundColor?: string;
  verticalAlign?: string;
}

const IconButton: React.FC<IButtonProps> = ({
  className,
  onChange,
  value,
  icon = Icons.info,
  size = 40,
  alt = "info",
  color = Color.WHITE,
}) => {
  const onClick = (_: React.MouseEvent) => {
    onChange(!value);
  };
  return (
    <div className={className} onClick={onClick}>
      <SVGIcon
        src={icon}
        alt={alt}
        size={size}
        color={color}
        onClick={() => {}}
      />
    </div>
  );
};
export default styled(IconButton)`
  width: ${({ size }) => (size ? size + 16 : 50)}px;
  height: ${({ size }) => (size ? size + 16 : 50)}px;
  margin: 2.5px;
  line-height: ${({ size }) => (size ? size + 16 : 50)}px;
  padding: 0;
  border-radius: 50%;
  display: inline-block;
  vertical-align: ${({ verticalAlign }) =>
    verticalAlign ? verticalAlign : "bottom"};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : Color.RED};

  user-select: none;
  cursor: pointer;
  img,
  svg {
    display: inline-block;
    vertical-align: top;
    margin: 8px;
  }
`;
