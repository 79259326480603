import Icons from "../icons/Icons";
import { IconTextDisplayProps } from "../components/elements/initialInterestPage/IconTextDisplay";
import { DISABLE_CYBER_ADVANTAGE } from "./default";

interface GigaKombiMainAdvantagesInterface {
  rebate: IconTextDisplayProps;
  datavolume: IconTextDisplayProps;
  euroFlat: IconTextDisplayProps;
  flexibility: IconTextDisplayProps;
  cyber: IconTextDisplayProps;
  service: IconTextDisplayProps;
}

export const GigaKombiMainAdvantages: GigaKombiMainAdvantagesInterface = {
  rebate: {
    icon: Icons.euro,
    label: "Mehr sparen",
    value: "Jeden Monat bis zu 15€ Rabatt",
  },
  datavolume: {
    icon: Icons.internet,
    label: "Mehr surfen",
    value: "Bis zu unbegrenztem Datenvolumen",
  },
  euroFlat: {
    icon: Icons.mobileEuroFlat,
    label: "Mehr telefonieren",
    value: "Mobile- & Euro-Flat für Dein Festnetz",
  },
  flexibility: {
    icon: Icons.euro,
    label: "Mehr Flexibilität",
    value: "Bis zu 12 Monate kein doppelter Basispreis",
  },
  cyber: {
    icon: Icons.cyberSecurity,
    label: "Mehr Sicherheit",
    value: "Cyber-Versicherung für die ganze Familie",
  },
  service: {
    icon: Icons.cloudDownload,
    label: "Mehr Service",
    value: "Bei Festnetz-Wechsel sofort online",
  },
};

export const InitialMainAdvantages: IconTextDisplayProps[] = [];
InitialMainAdvantages.push(GigaKombiMainAdvantages.rebate);
InitialMainAdvantages.push(GigaKombiMainAdvantages.datavolume);
InitialMainAdvantages.push(GigaKombiMainAdvantages.euroFlat);
InitialMainAdvantages.push(GigaKombiMainAdvantages.flexibility);
if (!DISABLE_CYBER_ADVANTAGE) {
  InitialMainAdvantages.push(GigaKombiMainAdvantages.cyber);
}
InitialMainAdvantages.push(GigaKombiMainAdvantages.service);
