import * as React from "react";
import { SimpleState } from "../../../types/default";
import AFOCountInputCell from "./AFOCountInputCell";
import AFOCountSelectionCell from "./AFOCountSelectionCell";
import { TableCellType, TableDataObject } from "../../../types/table";

export interface AFOCountCellProps {
  dataset: TableDataObject;
  dataId: number;
  openSelectionIdState: SimpleState<number>;
  changeCountId: (newCountId: number, objectId: number) => void;
  changeCountValue: (newValue: number, objectId: number) => void;
}

const AFOCountCell: React.FC<AFOCountCellProps> = ({
  dataset,
  dataId,
  openSelectionIdState,
  changeCountId,
  changeCountValue,
}) => {
  const [openSelectionId, setOpenSelectionId] = openSelectionIdState;
  let count: number = 0;
  if (dataset.counts[dataset.countId]) {
    count = Math.abs(dataset.counts[dataset.countId]);
  }

  switch (dataset.countType) {
    case TableCellType.INPUT:
      return (
        <AFOCountInputCell
          value={count}
          dataSet={dataset}
          changeValue={(value) => {
            changeCountValue(value, dataId);
          }}
        />
      );

    case TableCellType.SELECTION:
      return (
        <AFOCountSelectionCell
          value={count}
          dataId={dataId}
          dataSet={dataset}
          changeCountId={changeCountId}
          setOpenSelectionId={setOpenSelectionId}
          openSelectionId={openSelectionId}
        />
      );

    default:
      return null;
  }
};

export default AFOCountCell;
