import * as React from "react";

function SvgTvRefresh(props: React.SVGProps<SVGSVGElement>) {
  const { color = "#fff" } = props;
  return (
    <svg
      id="tvRefresh_svg___x39_409ec00-7291-472c-a9ee-6538af8dad1b"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 192 192"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {`.tvRefresh_svg__st1{fill:none;stroke: ${color} ;stroke-width:8;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <path className="tvRefresh_svg__st1" d="M48.6 87.1l11 11 11-11" />
      <path
        className="tvRefresh_svg__st1"
        d="M70 113.5c14.1 14.1 36.8 14.1 50.9 0s14.1-36.8 0-50.9-36.8-14.1-50.9 0c-7.1 7.1-11 16.9-10.5 27"
      />
      <path
        className="tvRefresh_svg__st1"
        d="M28 36.1h136c4.4 0 8 3.6 8 8v88c0 4.4-3.6 8-8 8H28c-4.4 0-8-3.6-8-8v-88c0-4.5 3.6-8 8-8zM60 156.1h72M96 144.1v10"
      />
      <path fill="none" d="M0 0h192v192H0z" />
    </svg>
  );
}

export default SvgTvRefresh;
