import { RecommendationConfig } from "../../state/definitions/RecommendationState";
import { OfferState } from "../../state/definitions/OfferState";
import { defaultOfferState } from "../../state/defaults/defaultOfferState";
import { WithsObject } from "../../types/application";

export function presetOfferStateFromRecommendation(
  recommendationConfig: RecommendationConfig,
  currentOfferState: OfferState,
  withsObject: WithsObject,
  newOfferEntryIds: string[] = []
): OfferState {
  const { mobile, iot, fixed, tv } = withsObject;
  if (!(mobile || iot || fixed || tv)) {
    return currentOfferState;
  }

  const acquirableProps: Partial<OfferState> = {
    offerEntryIds: newOfferEntryIds,
  };

  return Object.assign({}, defaultOfferState, acquirableProps);
}
